@import "./_variable";
@import "./common";
.blog_banner {
	margin: 70px 0 0;
	@media (max-width:$sm){
		margin: 50px 0 0;
	}
}
.latest_blogWrapper {
	border-radius: 40px;
    background: #FAECDE;
	height: 422px;
}
.latest_caption {
	padding: 12px 30px;
    background-color: #f0dbc6;
    border-radius: 30px;
    display: inline-block;
    margin: 0 0 26px;
    color: #A58565;
    font-size: 16px;
    font-weight: 600;
    line-height: 125%;
    text-transform: uppercase;
	@media (max-width:$sm){
		padding: 5px 10px;
		margin: 0 0 8px;
	}
	img {
		vertical-align: text-bottom;
	}
}
.blog_Title {
	font-size: 48px;
	font-style: normal;
	font-weight: 700;
	line-height: 110%;
	letter-spacing: -0.46px;
	margin-bottom: 26px;
	@media (max-width:$sm){
		font-size: 30px;
		line-height: 40px;
		margin-bottom: 20px;
	}
}
.latest_blogContent {
	@media (max-width:$sm){
		padding: 0 0 20px;
	}
	.short_description{
		height: 100px;
		@media (max-width:$sm){
			height: unset;
		}
	}
}
.short_description {
	padding: 14px 0;
}
.blog_latestImg {
	img {
		position: relative;
		transform: rotate(5.5deg);
		mix-blend-mode: darken;
	}
}
.read_now {
	letter-spacing: -0.32px;
	font-weight: 600;
	display: inline-flex;
	transition-duration: .3s;
	transition-property: transform;
	img {
		vertical-align: bottom;
	}
	&:hover{
		transform: scale(0.9);
	}
}
.see_more {
	color: $blue;
	font-weight: 600;
	float: right;
	img {
		vertical-align: bottom;
	}
	
}
.blog-category {
	font-size: 14px;
	text-transform: uppercase;
	font-weight: 600;
	padding: 0 0 10px;
}
.blog-title {
	font-size: 22px;
	line-height: 28px;
}
.webinar-title {
	font-size: 22px;
	line-height: 28px;
	padding: 15px 0 10px;
	height: 120px;
	@media (max-width:$sm){
		height: unset;
	}
}
.resources-title {
	font-size: 18px;
	line-height: 24px;
	padding: 10px 0;
	height: 65px;
}
.blog_heading {
	border-top: 1px solid #D7DDDD;
	padding: 40px 0 0;
}
.blog_wrapper {
	padding: 40px 0;
	@media (max-width:$sm){
		padding: 0 0 40px;
	}
}
#recent_blogs {
	.blog-box {
		padding: 0 50px 0 0;
		@media (max-width:$sm){
			padding: 20px 0 40px;
		}
	}
	.blog-title{
		height: 60px;
	}
	.short_description {
		height: 90px;
	}
}
#all_blogs {	  
	  .search_div {
		padding: 60px 0 0;
		@media (max-width:$sm){
			padding: 20px 0 0;
		}
		.search-container{
			button {
				background: #fff;
				font-size: 0;
				border: none;
				cursor: pointer;
				position: absolute;
				right: 2px;
				bottom: 6px;
			}
			input[type=text] {
				padding: 8px 6px;
				margin-top: 8px;
				font-size: 17px;
			}
		}
	  }

	.blog-box {
		padding: 0 10px;
		height: 405px;
		@media (max-width:$sm){
			padding: 20px 0;
			height: unset;
		}
	}
	.blog_Img {
		img {
			height: 213px;
			transition-duration: .3s;
	        transition-property: transform;
			&:hover{
				transform: translateY(-8px);
			}
			@media (max-width:$sm){
				height: 206px;
			}
			
		}
	}
}
#webinars {
	.webinar-box {
		padding: 0 10px;
		height: 375px;
		@media (max-width:$sm){
			padding: 20px 0;
			height: unset;
		}
	}
}
.blog_Img {
	img {
		border-radius: 8px;
		margin: 0 0 10px;
	}
}
#free-resources {
	padding: 0 0 50px;
	@media (max-width:$sm){
		padding: 0;
	}
}
.resources-box {
	padding: 0 10px;
	@media (max-width:$sm){
		padding: 15px 0;
	}
	.resources_Img{
		img{
			transition-duration: .3s;
			transition-property: transform;
			&:hover{
				transform: scale(0.9);
			}
		}
	}
}
.csinner-box {
	padding: 0 20px 20px 0px;
	@media (max-width:$sm){
		padding: 15px 0;
		height: unset;
	}
	.cs_Img {
		img {
			height: 206px;
			transition-duration: .3s;
			transition-property: transform;
			&:hover{
				transform: scale(0.9);
			}
		}
	}
}
.resources_Img {
	img {
		border-radius: 8px;
	}
}
.cs_Img {
	img {
		border-radius: 8px;
	}
}
.webinar_Img {
	img {
		border-radius: 8px;
		height: 210px;
		transition-duration: .3s;
	    transition-property: transform;
		&:hover{
			transform: translateY(-8px);
		}
	}
}
.click_to_connect {
	background-color: $bg-light;
	padding: 46px;
	border-radius: 8px;
	@media (max-width:$sm){
		padding: 0;
		.sec-padd{
			padding: 20px;
		}
	}
	.click-title{
		font-size: 40px;
		line-height: 50px;
		font-weight: 300;
        letter-spacing: -1.9px;
		@media (max-width:$sm){
			font-size: 22px;
		    line-height: 32px;
		}
		span{
			color: green;
			font-weight: 600;
		}
	}
}
.cs_wrapper {
	padding: 20px 0;
}
.cs-category {
	color: $blue;
	font-size: 14px;
	line-height: 20px;
	font-weight: 600;
	text-transform: uppercase;
	padding: 10px 0;
}
.csinner_Title {
	font-size: 20px;
	line-height: 28px;
	font-weight: 600;
	height:100px;
	@media (max-width:$sm){
		height: unset;
	}
}
.cs_Title {
	font-size: 28px;
	line-height: 38px;
	font-weight: 600;
	@media (max-width:$sm){
		font-size: 20px;
		line-height: 28px;
	}
}
.CS_blogContent {
	padding: 40px 0 0 40px;
	@media (max-width:$sm){
		padding: 0;
	}
}
.cs_author {
	font-size: 18px;
	line-height: 22px;
	color: $grey;
	font-weight: 500;
	text-transform: capitalize;
	padding: 8px 0 0;
	.userimg {
		width: 36px;
		height: 36px;
		border-radius: 100%;
		position: relative;
		top: 10px;
		left: -5px;
	}
}
.girl {
	position: absolute;
	bottom: 0;
	right: 0;
	@media (max-width:$sm){
		position: relative;
	}
}
.category_slider {
	li {
		a {
			color: $white;
			font-weight: 600;
			background-color: $bg-blue;
			padding: 8px 12px;
			border-radius: 30px;
			margin: 0 3px;
			font-size: 14px;
			&:hover{
				background-color: unset;
				color: $black;
			}
		}
	}
	li.slick-current.slick-active {
		a {
			background-color: $bg-white;
			border: 1px solid $blue;
			color: $blue;
		}
	}
	.slick-track {
		padding: 40px 0;
	}
}
.see_all {
	color: $white;
	background-color: $bg-blue;
}
#blog-details{
	.latest_blogContent{
		padding: 0;
	  }
	@media (max-width:$sm){
		margin: 30px 0 0;
	}
	.singlepost{
		background: #FAECDE;
		@media (max-width:$sm){
			padding: 20px 10px;
		}
	}
	.backblogs{
		@media (max-width:$sm){
		  border: 1px solid #E9EDED;
		}
		.backtoblogs{
			color: $blue;
			font-weight: 600;
			padding: 30px 0;
			display: block;
			@media (max-width:$sm){
				padding: 15px 0;
			}
		}
	}
	
	.latest_blogWrapper{
		border-radius: 8px;
		background: #FAECDE;
	    height: 422px;
		@media (max-width:$sm){
			height: unset;
		}
		.latest_blogContent{
			.blog_Title{
				font-size: 40px;
				line-height: 50px;
				@media (max-width:$sm){
					font-size: 22px;
					line-height: 32px;
				}
			}
		}
		.publish_date{
			padding: 25px 0;
			@media (max-width:$sm){
				padding: 0;
				font-size: 14px;
			}
			span{
				img{
					vertical-align: text-bottom;
				}
				.userimg{
					width: 36px;
					height: 36px;
					border-radius: 100%;
					position: relative;
					top: 7px;
					left: -5px;
				}
			}
		}
		.latest_blogImg{
			width: 562px;
			margin: 0 auto;
			.blog_Bigimage{
				@media (max-width:$sm){
					padding: 20px 0 10px;
				}
				img{
					mix-blend-mode: darken;
					@media (max-width:$sm){
						border-radius: 8px;
					}
				}
			}
		}
	}
	
}
.main_wrapper{
	border-top: 1px solid #D7DDDD;
	.detail_wrapper{
		display: flex;
		@media (max-width:$sm){
			display: block;
		}
	}
	.navbar-fixed {
		position: fixed;
		top: 100px;
		width: 315px;
		@media (max-width:$sm){
			width: 100%;
			position: unset;
		}
	}
	.demodiv-fixed {
		position: fixed;
		top: 120px;
		width: 210px;
		@media (max-width:$sm){
			width: 100%;
			position: unset;
		}
	}
	
	.stickySidebar{
		background-color: #fff;
		padding: 20px 8px;
		border-radius: 8px;
		box-shadow: 2px 2px 4px #80808040;
		@media (max-width:$sm){
			margin: 0 0 20px;
		}
		.table_content {
			padding: 20px 4px 20px 20px;
			li{
				.sublist{
					padding: 5px 0 5px 14px;
					li {
						list-style-type: disc;
						a{
							color: #000;
							font-size: 14px;
							line-height: 20px;
							font-weight: 500;
							&.active{
								color: #60ACAC;
							}
						}
					}
				}
				a{
					color: #000;
					font-size: 16px;
					line-height: 28px;
					font-weight: 500;
					&.active{
						color: #60ACAC;
					}
				}
				
			}
		}
		
	}
	.blogdetails_wrapper{
		padding:0 35px;
		font-size: 18px;
		line-height: 26px;
		text-align: justify;
		@media (max-width:$sm){
			padding: 0;
		}
		a{
			color: $blue;
			text-align: justify;
		}
		h2 {
			font-size: 36px;
			line-height: 44px;
			padding: 24px 0;
			text-transform: capitalize;
			letter-spacing: -0.7px;		
			text-align: left;
			@media (max-width:$sm){
				font-size: 22px;
				line-height: 32px;
				padding: 10px 0 15px;
			}
		}
		h3 {
			font-size: 28px;
			line-height: 36px;
			padding: 18px 0;
			text-transform: capitalize;
			letter-spacing: -0.56px;
			text-align: left;
			@media (max-width:$sm){
				font-size: 20px;
				line-height: 30px;
				padding: 10px 0 15px;
			}
		}
		h4 {
			font-size: 20px !important;
			line-height: 30px !important;
			padding: 18px 0;
			text-transform: capitalize;
			letter-spacing: -0.56px;
			text-align: left;
			@media (max-width:$sm){
				font-size: 18px !important;
				line-height: 28px !important;
				padding: 10px 0 15px;
			}
		}
		p {
			font-size: 18px;
			line-height: 26px;
			padding: 10px 0;
			text-align: justify;
			@media (max-width:$sm){
				padding: 0 0 10px;
			}
		}
		.quotes {
			background-color: #fff;
			box-shadow: 1px 3px 8px 2px #80808029;
			padding: 20px;
			margin: 10px 0 20px;
			text-align: justify;
		}
		blockquote {
			background-color: #fff;
			box-shadow: 1px 3px 8px 2px #80808029;
			padding: 20px;
			margin: 10px 0 20px;
			text-align: justify;
		}
		img{
			margin: 15px 0;
			border-radius: 8px;
			width: 100%;
			box-shadow: 1px 1px 1px 8px #e4e4e49c;
			// box-shadow: 2px 4px 4px #ebebeb; 
			
		}
		iframe {
			margin: 25px 0 15px;
			border-radius: 8px;
			display: block;
			width: 100%;
			box-shadow: 1px 1px 1px 8px hsla(0, 0%, 89%, .6117647059);
			height: 400px;
			@media (max-width:$sm){
			  height:280px;
			}
		  }
		ul{
			padding-left: 20px;
			li{
				font-size: 18px;
			    line-height: 26px;
				padding: 8px 0;
				img{
					margin: 30px 0 20px;
				  }
				ul{
					padding: 5px 0 0 15px;
					li{
						font-size: 18px;
			            line-height: 26px;
						list-style-type: disc;
						font-weight: 500;
					}
				}
			}
		}
		ol{
			padding-left: 20px;
			li{
				font-size: 18px;
			    line-height: 26px;
				padding: 8px 0;
				img{
					margin: 30px 0 20px;
				  }
				ul{
					padding: 5px 0 0 15px;
					li{
						font-size: 18px;
			            line-height: 26px;
						list-style-type: disc;
						font-weight: 500;
					}
				}
			}
		}
		.author_wrapper{
				background-color: #F7F8F8;
				padding: 20px;
				border-radius: 8px;
				margin: 20px 0;
				@media (max-width:$sm){
					margin: 20px 0 40px;
				}
			.author-bio img {
				width: 150px;
				height: 100%;
				border-radius: 100%;
				@media (max-width:$sm){
					width: 90px;
				}
			}
			.author_content{
				padding: 20px;
				@media (max-width:$sm){
					padding: 0;
				}
				.user_name{
					font-size: 20px;
					line-height: 30px;
					font-weight: 600;
					padding: 0 0 10px;
				}
			}
		}
	}
	.rightSidebar{
		padding: 20px 0 0;
		.social_links {
			padding: 20px 0 0;
			a{
				img{
					width: 25px;
				}
			}
		}
		.demo_div{
			background-color: #F4F3FF;
			border-radius: 12px;
			padding: 20px 15px;
			margin: 30px 0 0;
			.demo_title{
				font-size: 19px;
				line-height: 28px;
				font-weight: 600;
				@media (max-width:$sm){
					font-size: 22px;
					line-height: 32px;
				}
				span{
					color: blue;
				}
			}
			p{
				font-size: 14px;
				line-height: 20px;
				padding: 10px 0;
			}
			.theme-btn, .white-btn{
				display: block;
				margin: 0 0 10px;
			}
		}
	}
}

#recentBlogs{
	.blogBox{
		.blog-box-wrapper{
			.blog-box{
				padding: 0 15px;
				@media (max-width:$sm){
					padding: 15px 0;
				}
				.blog_Img{
					img{
						height: 213px;
					}
				}
			}
		}
	}
}
#subscribe{
	.subscribe_wrapper{
		padding: 48px 50px;
		height: 390px;
		border-radius: 8px;
		.sec-padd{
			max-width: 500px;
		}
		@media (max-width:$sm){
			padding: 0;
			height: unset;
			.sec-title {
				font-size: 24px;
				line-height: 34px;
			   }
			.sec-padd{
				padding: 20px;
			}
		}
		.subscribe{
			position: absolute;
			right: 0;
			bottom: 0;
			height: 390px;
			@media (max-width:$sm){
				position: relative;
				height: unset;
			}
		}
	}
}

#lastsection{
    background-color: $bg-blue;
    height: 600px;
    @media (max-width: $sm){
        height: 300px;
        .sheet{
            width: 98px;
        }
    }
    .tagline_wraper{
        position: absolute;
        top:50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        @media (max-width: $sm){
            position: relative;
            top:unset;
            transform: unset;
            left: unset;
        }
		.sec-title{
			font-size: 50px;
			line-height: 60px;
			letter-spacing: 0;
			padding-bottom: 20px;
			@media (max-width: $sm){
				font-size: 24px;
			    line-height: 34px;
			}
		}
        .free-trial{
            font-size: 20px;
            margin: 20px 0 50px;
            @media (max-width: $sm){
                font-size: 14px;
                line-height: 20px;
                margin: 0px 0 35px;
            }
        }
        .theme-btn{
            background-color: $white;
            color: $blue;
        }
    }
}

.pagination-links {
    text-align: center;
    margin: 20px 0;
	a{
		margin: 0 5px;
		padding: 10px 15px;
		border: 1px solid #ddd;
		text-decoration: none;
		background-color: #f4f4f4;
		color: $blue;
		transition: background-color 0.3s ease, color 0.3s ease;
		&:hover{
			background-color: $blue;
			color: $white;
			border-color: $blue;
		}
	}
	.current {
		font-weight: bold;
        pointer-events: none; 
		background-color: $blue;
		color: $white;
		border-color: $blue;
	}
	.page-number {
		cursor: pointer;
	}
	.prev_pagination, .next_pagination, .last-page, .first-page {
		background-color: #ebebeba8;
		padding: 11px;
		border-radius: 4px;
	}
}
