@import "./_variable";
* {
  -webkit-tap-highlight-color: transparent !important;
}
.areaFont{
  font-family: "area-variable", sans-serif !important;
  font-variation-settings: "slnt" 0, "INKT" 70;
}
.areaFont0{
  font-family: "area-variable", sans-serif !important;
  font-variation-settings: "slnt" 0, "INKT" 0;
}
html,
body {
  padding: 0;
  margin: 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  font-display: swap;
  font-family: 'Basier Circle';
  overflow-x: hidden;
}
a,
img,
button,
input[type="submit"] {
  text-decoration: none;
  outline: none;
  border: 0;
  /*transition: 0.2s ease;*/
}
*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
::selection{
  background-color: #33c1c0;
  color: $white;
}
a:focus,
a:hover {
  text-decoration: none !important;
}
p,
ul,
ol,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
dt,
dd {
  padding: 0;
  margin: 0;
}
input,
textarea,
select {
    width: 100%;
    display: block;
    padding: 10px 12px;
    font-size: 14px;
    line-height: 20px;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    outline: none;

}

.selldof .btn{
  width: unset;
}

p{
  font-size: 16px;
  line-height: 26px;
}
.container-fluid {
  width: 100%;
  margin: 0 auto;
  padding: 0 0px;
}
.container {
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 20px;
}

.container.small {
  max-width: 850px;
  padding: 0 20px;
}
.container.medium {
  max-width: 1000px;
  padding: 0 20px;
}
.pull-left {
  float: left !important;
}
.pull-right {
  float: right !important;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-justify {
  text-align: justify;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-lowercase {
  text-transform: lowercase;
}
.text-capitalize {
  text-transform: capitalize;
}
.text-italic {
  font-style: italic;
}
.text-medium {
  font-weight: 400;
}
.text-bold {
  font-weight: 500;
}
.text-bolder {
  font-weight: 700;
}
.text-black {
    color: $black;
}
.text-pink{
  color: #DD2590;
}
.text-dark {
  color: #182020;
}
.text-light{
  color: $light;
}
.text-white {
  color: #fff;
}
.text-blue {
  color: $blue;
}
.text-lightblue{
  color: #01697e;
}
.text-green{
  color: #065151;
}
.text-greyish{
  color: #C1C8CC;
}
.text-grey{
  color: $color1;
}

.text-red{
  color: red;
}
.titledark{
  color: $titledark;
}
.text-yellow{
  color: $yellow;
}
.color1{
  color: $color1;
}
.color2{
  color: $color2;
}
.color3{
  color: $color3;
}
.primary_color{
  color: $primary_color;
}
.bg-light{
  background-color: $bg-light;
}
.bg-grey{
  background-color: #EFF0F0;
}
.img-responsive {
  max-width: 100%;
  height: auto;
}
.img-full {
  width: 100%;
  height: auto;
}
.center-block {
  margin: 0 auto;
}
.bg-white {
  background-color: $white;
}
.bg-blue {
  background-color: $blue;
}
.bg-pink {
  background-color: $bg-pink;
}
.bg-colorpink {
  background-color: $bg-colorpink;
}
.bg-green{
  background-color: $bg-green;
}
.bgBlue {
  background-color: $bgBlue;
}
.basierMedium{
  font-family: $basierMedium;
}
.basierSemibold{
  font-family: $basierSemibold;
}

.bg-cover {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .col-1 {
    width: 8.333333333333333%;
  }
  .col-2 {
    width: 16.66666666666667%;
  }
  .col-3 {
    width: 25%;
  }
  .col-4 {
    width: 33.33333333333333%;
  }
  .col-5 {
    width: 41.66666666666667%;
  }
  .col-6 {
    width: 50%;
  }
  .col-7 {
    width: 58.33333333333333%;
  }
  .col-8 {
    width: 66.66666666666667%;
  }
  .col-9 {
    width: 75%;
  }
  .col-10 {
    width: 83.33333333333333%;
  }
  .col-11 {
    width: 91.66666666666667%;
  }
  .col-12 {
    width: 100%;
  }
  .col-12-5 {
    width: 20%;
  }

  .margintop-20 {
    margin-top: 20px;
  }
  .margintop-30 {
    margin-top: 30px;
  }

  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12,
  .col-12-5,
  .w30,
  .w70 {
    float: left;
  }

  section {
    float: left;
    width: 100%;
  }
  .padding {
    padding: 70px 0px;
  }
  .padding-top {
    padding: 120px 0 70px 0;
  }
  .padd-10{
    padding-top: 10px;
  }
  .padd-20{
    padding-top: 20px;
  }
  .padd-30{
    padding-top: 30px;
  }
  .pd-bt-15{
    padding-bottom: 15px;
  }
  .pd-bt-10{
    padding-bottom: 10px;
  }
  .pd-bt-20{
    padding-bottom: 20px;
  }
  .pd-bt-30{
    padding-bottom: 30px;
  }
  .pd-tp-15{
    padding-top: 15px;
  }
  .pd-tp-10{
    padding-top: 10px;
  }
  .pd-tp-20{
    padding-top: 20px;
  }
  .pd-tp-30{
    padding-top: 30px;
  }
  .pd-tp-40{
    padding-top: 40px;
  }
  .pd-tp-50{
    padding-top: 50px;
  }
  .padd-top{
    padding: 10px 0;
  }
  .mg-bt-30{
    margin-bottom: 30px;
  }
  .mg-bt-10{
    margin-bottom: 10px;
  }
  .pd-tp-0{padding-top: 0 !important;}
  .pd-bt-0{padding-bottom: 0 !important;}
  .pos-rel {
    position: relative;
  }
  .pos-abs {
    position: absolute;
  }
  .pos-fix {
    position: fixed;
  }
  .bottom {
    bottom: 0;
  }
  .top {
    top: 0;
  }
  .left {
    left: 0;
  }
  .right {
    right: 0;
  }
  .visible-xs {
    display: none ;
  }
  .visible-sm {
    display: none;
  }
  .hidden-xs {
    display: block ;
  }
  .hidden-portrait {
    display: block !important;
  }
  .visible-portrait {
    display: none !important;
  }
  .displayblock{
    display: block;
  }
  
  .displayinline{
    display: inline-block;
  }
  .row:before,
  .row:after {
    display: table;
    content: " ";
  }
  .row:after,
  .clearfix {
    clear: both;
  }
  .parallaxbg {
    background-attachment: fixed;
  }
  .disptable {
    display: table;
  }
  .table-cell {
    display: table-cell;
    vertical-align: middle;
  }
  .visible-xs {
    display: none;
  }
  .hidden-lg {
    display: none;
  }
  .hidden-portrait {
    display: block !important;
  }
  .visible-portrait {
    display: none !important;
  }
  .vert-middle {
    vertical-align: middle;
  }
  .disp-block {
    display: block;
  }
  .disp-flex {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 15px;
    margin: 35px 0;
}

  .center-block {
      float: none !important;
      display: block;
      margin: 10px auto 0;
  }

  .sticky_header {
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: $blue;
    padding: 6px 15px;
    border-radius: 30px;
    z-index: 2;
}

header {
    position: fixed;
    width: 100%;
    background-color: #fff;
    width: 100%;
    height: 70px;
    padding: 10px 0;
    border-bottom: 1px solid #e9eded;
    z-index: 9999;
    transition: all .3s linear;
    // width: 1300px;
    // top: 48px;
    // border-radius: 12px;
    // transform: translateX(-50%);
    // left: 50%;
    .center-header{
      gap: 18px;
      padding: 0 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 auto !important;
      max-width: 1304px;
    }
    .theme-btn, .white-btn{
      font-size: 14px !important;
      padding: 10px 12px;
    }
}
.brand-logo {
  padding: 10px 0;
}
.border{
  margin: 4px 0 0;
}
a:hover, button:hover, input[type='button']:hover, input[type='submit']:hover{
    cursor: pointer;
}

.nav-links {
    list-style-type: none;
    display: inline-block;
    li {
      display: inline-block;
    }
    .custom-dropdown {
      float: left;
      overflow: hidden;
      height: 80px;
      &:hover{
        .dropdown-menu-parent{
          display: block;
        }
      }
      .dropbtn {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        span{
            font-size: 14px;
            color: #374040;
            line-height: 20px;
            font-family: $basierMedium;
            cursor: pointer;
            position: relative;
            transition: all .2s linear;
            margin: 12px 10px 12px 22px;
            display: inline-block;
            a{
              font-size: 14px;
              color: #374040;
              font-family: $basierMedium;
              line-height: 20px;
              cursor: pointer;
              position: relative;
          }
        }
    }
    .dropdown-menu-parent {
      left: 0;
      top: 60px;
      width: 100%;
      z-index: 10;
      display: none;
      position: absolute;
      backdrop-filter: blur(22px);
      height: calc(100dvh - 130px);
      display: none;
      .custom-dropdown-menu{
        overflow-y: auto;
        padding: 32px 25px;
        background: #fff;
        max-height: calc(100dvh - 130px);
        box-shadow: 4px 0 4px rgba(0, 0, 0, .04);
        .dropdown-content {
          margin: 0 auto;
          max-width: 1366px;
          .section-header {
            margin: 0 0 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .section-title {
              margin: 0;
              font-size: 14px;
              line-height: 20px;
              width: fit-content;
              color: transparent;
              letter-spacing: .1em;
              text-transform: uppercase;
              font-family: $basierSemibold;
              background-clip: text;
              background-image: linear-gradient(97.27deg, #ff7b34 9.71%, #8d63fe 102.01%);
            }
          }
          
          .section-1 {
            .section-body {
              display: flex;
              grid-gap: 16px 54px;
              gap: 16px 0px;
              flex-wrap: wrap;
              align-items: stretch;
              justify-content: flex-start;
              .menu-item {
                grid-gap: 16px;
                gap: 16px;
                width: 390px;
                padding: 12px;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                border-radius: 8px;
                  
                &:hover{
                  background: #f4f9f9;
                  .icon-div {
                    background: #0d9298;
                    svg{
                      path{
                        stroke: #fff;
                      }
                    }
                  }
                }
                
                .icon-div {
                  width: 44px;
                  height: 44px;
                  border-radius: 8px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  background: #f4f9f9;
                  svg{
                    path{
                      stroke: #0d9298;
                    }
                  }
                }     
                         
                .content-div {
                  max-width: calc(100% - 76px);
                  h6{
                    margin: 0 0 2px;
                    font-size: 16px;
                    color: $color1;
                    line-height: 22px;
                    font-weight: 600;
                  }
                  p{
                    margin: 0;
                    font-size: 14px;
                    color: #747c7c;
                    line-height: 21px;
                    letter-spacing: -.02em;
                  }
                }
              }
            }
          }
          .h_img{
            width: 200px;
            height: 132px;
            border-radius: 10px;
          }
          .section-2{
            margin-bottom: 32px;
            .section-body {
              display: flex;
              grid-gap: 32px;
              gap: 32px;
              flex-wrap: wrap;
              align-items: stretch;
              justify-content: flex-start;
              .menu-item {
                grid-gap: 20px;
                gap: 20px;
                width: 528px;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                .content-div {
                  max-width: calc(100% - 220px);
                  h6 {
                    margin: 0;
                    font-size: 16px;
                    color: $color1;
                    line-height: 22px;
                  }
                  p {
                    font-size: 14px;
                    color: #747c7c;
                    margin: 4px 0 8px;
                    line-height: 21px;
                    letter-spacing: -.02em;
                 }
                 span {
                  font-size: 16px;
                  line-height: 22px;
                  color: #0d9298 !important;
                  text-decoration: underline !important;
                  font-weight: 500;
                }
               }
             }
            }
          }
          .section-3{
            .section-body {
              display: flex;
              grid-gap: 16px 32px;
              gap: 16px 32px;
              flex-wrap: wrap;
              align-items: stretch;
              justify-content: flex-start;
              .menu-item {
                width: 315px;
                cursor: pointer;
                img {
                  border-radius: 8px;
                  height: 150px;
                }
                h6 {
                  font-size: 16px;
                  margin: 20px 0 0;
                  color: $color1;
                  line-height: 22px;
                  font-family: $basierSemibold;
                }
                p {
                  font-size: 14px;
                  color: #747c7c;
                  margin: 4px 0 8px;
                  line-height: 21px;
                  letter-spacing: -.02em;
                  font-family: $basier;
                }
                span {
                  font-size: 16px;
                  line-height: 22px;
                  color: #0d9298 !important;
                  font-family: $basierMedium;
                  text-decoration: underline !important;
                  font-weight: 500;
               }
             }
           }
          }
        }
      }
    }
  }
    
}

.call-us img{
    vertical-align: middle;
        background-color: #11274C;
    width: 37px;
    height: 37px;
    padding: 9px;
    border-radius: 50px;
}

a.enquire-toggle-button.extLink.active:before {
    content: unset;
}

.logo-div img {
    padding: 10px 0 0;
}

.burger-menu {
    display: none;
    padding: 7px 8px;
    margin-top: 25px;
}

.bar1,
.bar2,
.bar3 {
    width: 20px;
    height: 3px;
    background:$bg-blue;
    margin: 5px 0;
    transition: 0.4s;
}

.change .bar1 {
    -webkit-transform: rotate(-45deg) translate(-5px, 5px) !important;
    transform: rotate(-45deg) translate(-5px, 5px) !important;
}

.change .bar2 {
    opacity: 0;
}

.change .bar3 {
    -webkit-transform: rotate(45deg) translate(-6px, -6px);
    transform: rotate(45deg) translate(-6px, -6px);
    width: 20px;
    left: 1px;
    margin: unset;
}

.sticky-btn {
  position: fixed;
  top: 165px;
  right: 0;
  -webkit-transform: rotate(-90deg);
  -webkit-transform-origin: bottom right;
  transform-origin: bottom right;
  width: 270px;
  z-index: 999;
  display: inline-block;
  text-align: center;
  text-decoration: none;
}

.visible-xs {
    display: none !important;
}

.col-12.menu a:last-child {
    padding-right: 0;
}

.btn_wrapper{
  margin: 30px 0;
}
.wpcf7-not-valid-tip {
  position: absolute;
  bottom: -21px;
  font-size: 10px;
}

.wpcf7 form .wpcf7-response-output{
  display: none;
}
.theme-btn {
    padding: 14px 25px;
    font-size: 16px !important;
    line-height: 20px;
    display: inline-block;
    background: $blue !important;
    text-align: center;
    border-radius: 6px;
    color: $white;
    font-family: $basierMedium;
    box-shadow: 0 1px 0 0 rgba(0,0,0,.05);
    &:hover{
      background-color: $light !important;
    }
    @media (max-width: $sm){
      font-size: 14px !important;
    }
}
.black-btn {
  padding: 14px 25px;
  font-size: 16px !important;
  line-height: 20px;
  display: inline-block;
  background: $color3;
  text-align: center;
  border-radius: 8px;
  font-family: $basierMedium;
  color: $white;
  &:hover{
    background-color: $white !important;
    color: $color3;
  }
  @media (max-width: $sm){
    font-size: 14px !important;
  }
}
.white-btn {
  padding: 14px 25px;
  font-size: 16px !important;
  line-height: 20px;
  display: inline-block;
  background: white;
  text-align: center;
  border-radius: 8px;
  font-family: $basierMedium;
  color: $color3;
  border: 1px solid $color3;
  &:hover{
    background-color: $light !important;
    color: $white !important;
  }
  @media (max-width: $sm){
    font-size: 14px !important;
  }
}
.blue-btn {
  padding: 14px 25px;
  font-size: 16px !important;
  line-height: 20px;
  display: inline-block;
  background: white;
  text-align: center;
  font-family: $basierMedium;
  border-radius: 6px;
  color: $blue;
  border: 1px solid $blue;
  @media (max-width: $sm){
    font-size: 14px !important;
  }
}
.yellow-btn {
  padding: 14px 25px;
  font-size: 16px !important;
  line-height: 20px;
  display: inline-block;
  background: $yellow;
  text-align: center;
  font-family: $basierMedium;
  border-radius: 6px;
  color: #013030;
  border: 1px solid $yellow;
  @media (max-width: $sm){
    font-size: 14px !important;
  }
}
.grey-btn {
  padding: 10px 30px;
  font-size: 16px !important;
  line-height: 20px;
  display: inline-block;
  color: #1A424F;
  background: #EEEEEE;
  border-radius: 8px;
  text-align: center;
  font-family: $basierMedium;
  &:hover{
    background-color: $blue !important;
    color: $white !important;
    svg {
      path {
        fill: #fff;
      }
    }
  }
  svg {
    vertical-align: text-top;
  }
  @media (max-width: $sm){
    font-size: 14px !important;
  }
}
.red-btn img{
    float: right;
    margin-top: 7px;
  }
.light-btn{
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #171717;
  background-color: #EFF0F0;
  border-radius: 50px;
  padding: 8px 20px;
}
.button-3 {
  border: 2px solid #3c73ff;
  background-color: #3c73ff;
  border-radius: 20px;
  color: #fff;
  transition: .3s;
}
.button-3:hover {
  box-shadow: 8px 8px #99bdff;
  transition: .3s;
}
.learnmore{
  position: relative;
  display: inline-block;
  &::after {
    content: "";
    position: absolute;
    top: 1px; // Offset shadow downwards
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 6px 6px $color3;
    transition: top 0.3s ease-in-out, left 0.3s ease-in-out;
    border-radius: 8px;
  }
  .theme-newbtn {
    padding: 11px 16px 8px;
    font-size: 16px;
    line-height: 20px;
    display: inline-block;
    background: $blue;
    text-align: center;
    border-radius: 8px;
    color: $white;
    transition: transform 0.3s ease-in-out;
    position: relative;
    left: 6px;
    top: 7px;
    z-index: 2;
  }
  &:hover {
    .theme-newbtn{
      transform: translate(-5px, -5px); 
    }
  }
}
.theme-newbtn {
  padding: 11px 16px;
  font-size: 16px !important;
  line-height: 20px;
  display: inline-block;
  background: $blue;
  text-align: center;
  border-radius: 8px;
  color: $white;
  &:hover {
    box-shadow: 6px 6px $black;
    transition: .3s;
  }
  @media (max-width: $sm){
    font-size: 14px !important;
  }
}
.ctahover{
  position: relative;
  display: inline-block;
  &::after {
    content: "";
    position: absolute;
    top: 1px; // Offset shadow downwards
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 6px 6px #63faff;
    transition: top 0.3s ease-in-out, left 0.3s ease-in-out;
    border-radius: 8px;
  }
  .black-newbtn {
    padding: 11px 16px;
    font-size: 16px !important;
    line-height: 20px;
    display: inline-block;
    background: $color3;
    text-align: center;
    border-radius: 8px;
    font-family: $basierMedium;
    color: $white;
    transition: transform 0.3s ease-in-out;
    position: relative;
    left: 6px;
    top: 7px;
    z-index: 2;
  }
  &:hover {
    .black-newbtn{
      transform: translate(-5px, -5px); 
    }
  }
}
.ctahoverw{
  position: relative;
  display: inline-block;
  &::after {
    content: "";
    position: absolute;
    top: 1px; // Offset shadow downwards
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 6px 6px $color3;
    transition: top 0.3s ease-in-out, left 0.3s ease-in-out;
    border-radius: 8px;
  }
  .white-newbtn {
    padding: 11px 16px;
    font-size: 16px !important;
    line-height: 20px;
    display: inline-block;
    background: $white;
    text-align: center;
    border-radius: 8px;
    font-family: $basierMedium;
    color: $color3;
    transition: transform 0.3s ease-in-out;
    position: relative;
    left: 6px;
    top: 7px;
    z-index: 2;
  }
  &:hover {
    .white-newbtn{
      transform: translate(-5px, -5px); 
    }
  }
}

// .black-newbtn {
//   padding: 11px 16px;
//   font-size: 16px !important;
//   line-height: 20px;
//   display: inline-block;
//   background: $color3;
//   text-align: center;
//   border-radius: 8px;
//   font-family: $basierMedium;
//   color: $white;
//   &:hover {
//     box-shadow: 6px 6px #63faff;
//     transition: .3s;
//   }
//   @media (max-width: $sm){
//     font-size: 14px !important;
//   }
// }
.white-newbtn {
  padding: 11px 16px;
  font-size: 16px !important;
  line-height: 20px;
  display: inline-block;
  background: white;
  text-align: center;
  border-radius: 8px;
  font-family: $basierMedium;
  color: $color3;
  &:hover {
    box-shadow: 6px 6px $black;
    transition: .3s;
  }
  @media (max-width: $sm){
    font-size: 14px !important;
  }
}
.col-12.header-upper {
    text-align: right;
}

.footer-call-enquire {
    display: none;
}

.checkbox {
    color: #222 !important;
    margin: 0 !important;
}

a {
    cursor: pointer;
}

.footer-div{
    padding: 40px 0;
    float: left;
    width: 100%;
    padding-bottom: 0;
}

.footer-top p, .footer-top a{
    font-size: 16px;
    line-height: 24px;
    color: #182020;
}
#jp-relatedposts{
	display:none !important;
}
	.footer-top{padding:0 0 40px;}
	.footer-top {
    input.theme-btn {
      margin: 20px 0;
	    width:unset;
   }
  }
.footer-title{
  padding: 20px 0 40px;
}
.footer_desc{
  font-size: 14px;
}
.footer-top ul li{
    font-size: 16px;
    line-height: 28px;
    color: #182020;
    list-style-type: none;
}
.footer-bottom {
  padding: 10px;
  font-size: 16px;
  color: #616d6d;
  line-height: 21px;
  text-align: center;
  background: #f5f5f5;
  font-family: "DM Sans", sans-serif;
}

.social-links a img {
  padding: 8px 4px;
}

.vh{
    visibility: hidden;
}
.footer-top h4 {
    padding: 0px 0 20px;
    color: #182020;
}
.thankyou {
    text-align: center;
    font-size: 18px;
    left: 22px;
}

.sec-subtitle {
  font-size: 22px;
  line-height: 32px;
  font-weight: 700;
  padding: 0 0 10px;
}
.sec_title{
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: 64px; 
  letter-spacing: -0.56px;
  font-family: $basierMedium;
  @media (max-width: $sm){
    font-size: 28px;
    line-height: 32px;
  }
}
.sec-title {
  font-size: 40px;
	font-style: normal;
	font-weight: 600;
	line-height: 48px;
	letter-spacing: -0.8px;
}

.sec-light {
  font-size: 25px;
  line-height: 36px;
  font-weight: 400;
  padding: 0 0 20px;
}
.sec-small{
  font-size: 13px;
  line-height: 24px;
  font-weight: 400;
}
.sec-heading {
  font-size: 20px;
  line-height: 30px;
  @media (max-width: $sm){
    font-size: 14px;
    line-height: 22px;
  }
}
.sec-heading2{
  font-size: 36px;
  line-height: 50px;
}
.sec-desc {
  font-size: 18px;
	line-height: 24px;
	padding: 10px 0;
}
.sec-text{
  font-size: 16px;
  line-height: 24px;
}
/*Animation Start*/

.revealOnScroll {
	opacity: 0;
	transition: all 0.4s ease-in-out;
}

.animated {
	-moz-animation-duration: 1.2s;
	-o-animation-duration: 1.2s;
	-webkit-animation-duration: 1.2s;
	-ms-animation-duration: 1.2s;
	animation-duration: 1.2s;
	-moz-animation-fill-mode: both;
	-o-animation-fill-mode: both;
	-webkit-animation-fill-mode: both;
	-ms-animation-fill-mode: both;
	animation-fill-mode: both
}

@keyframes fadeInLeft {
	from {
		opacity: 0;
		-webkit-transform: translate3d(-100%, 0, 0);
		-o-transform: translate3d(-100%, 0, 0);
		-moz-transform: translate3d(-100%, 0, 0);
		-ms-transform: translate3d(-100%, 0, 0);
		transform: translate3d(-100%, 0, 0)
	}
	to {
		opacity: 1;
		-webkit-transform: none;
		-ms-transform: none;
		-moz-transform: none;
		-o-transform: none;
		transform: none
	}
}

.fadeInLeft {
	-webkit-animation-name: fadeInLeft;
	-o-animation-name: fadeInLeft;
	-ms-animation-name: fadeInLeft;
	-moz-animation-name: fadeInLeft;
	animation-name: fadeInLeft
}

@keyframes fadeInRight {
	from {
		opacity: 0;
		-webkit-transform: translate3d(100%, 0, 0);
		-o-transform: translate3d(100%, 0, 0);
		-ms-transform: translate3d(100%, 0, 0);
		-moz-transform: translate3d(100%, 0, 0);
		transform: translate3d(100%, 0, 0)
	}
	to {
		opacity: 1;
		-webkit-transform: none;
		-o-transform: none;
		-moz-transform: none;
		-ms-transform: none;
		transform: none
	}
}

.fadeInRight {
	-webkit-animation-name: fadeInRight;
	-o-animation-name: fadeInRight;
	-ms-animation-name: fadeInRight;
	-moz-animation-name: fadeInRight;
	animation-name: fadeInRight
}

@keyframes fadeInUp {
	from {
		opacity: 0;
		-webkit-transform: translate3d(0, 100%, 0);
		-o-transform: translate3d(0, 100%, 0);
		-ms-transform: translate3d(0, 100%, 0);
		-moz-transform: translate3d(0, 100%, 0);
		transform: translate3d(0, 100%, 0)
	}
	to {
		opacity: 1;
		-webkit-transform: none;
		-o-transform: none;
		-ms-transform: none;
		-moz-transform: none;
		transform: none
	}
}

.fadeInUp {
	-webkit-animation-name: fadeInUp;
	-o-animation-name: fadeInUp;
	-ms-animation-name: fadeInUp;
	-moz-animation-name: fadeInUp;
	animation-name: fadeInUp
}

@keyframes fadeInDown {
	from {
		opacity: 0;
		-webkit-transform: translate3d(0, -100%, 0);
		-o-transform: translate3d(0, -100%, 0);
		-moz-transform: translate3d(0, -100%, 0);
		-ms-transform: translate3d(0, -100%, 0);
		transform: translate3d(0, -100%, 0)
	}
	to {
		opacity: 1;
		-webkit-transform: none;
		-o-transform: none;
		-moz-transform: none;
		-ms-transform: none;
		transform: none
	}
}

.fadeInDown {
	-webkit-animation-name: fadeInDown;
	-o-animation-name: fadeInDown;
	-ms-animation-name: fadeInDown;
	-moz-animation-name: fadeInDown;
	animation-name: fadeInDown
}

@keyframes fadeIn {
	0% {
		opacity: 0
	}
	to {
		opacity: 1
	}
}

.fadeIn {
	animation-name: fadeIn;
}
.slick-arrow{
  cursor: pointer;
}
.imgbox img {
    border-radius: 25px;
}
/******************* Single Job **************/
.awsm-job-container{
  padding: 50px;
}
.awsm-job-container h1{
  margin-bottom: 20px;
}
.awsm-job-container h4{
  margin-bottom: 10px;
  margin-top: 20px;
}
.awsm-job-container ul{
  padding-left: 30px;
}
.awsm-job-container ul li{
  padding-bottom: 10px;
  line-height: 26px;
  font-size: 16px;
}
.awsm-job-container ul li strong{
  font-weight: 600;
}
.awsm-job-specification-item{
  margin: 10px 0;
}
.awsm-job-post-title{
  color: #171717;
  font-weight: 600;
}
.awsm-job-more{
  color: $blue;
}

.menu{
  display: none;
}
@media only screen and (max-width: 1024px) {
  .col-md-1{width: 8.333333333333333%;}
	.col-md-2{width: 16.66666666666667%;}
	.col-md-3{width: 25%;}
	.col-md-4{width: 33.33333333333333%;}
	.col-md-5{width: 41.66666666666667%;}
	.col-md-6{width: 50%;}
	.col-md-7{width: 58.33333333333333%;}
	.col-md-8{width: 66.66666666666667%;}
	.col-md-9{width: 75%;}
	.col-md-10{width: 83.33333333333333%;}
	.col-md-11{width: 91.66666666666667%;}
	.col-md-12{width: 100%;}
	.col-md-12-5{width: 20%;}
	.hidden-md{display: none !important;}
	.visible-md{display: block !important;}
}
@media only screen and (max-width: 992px) {
  .text-center-xs{
    text-align: center;
  }
  .visible-xs {
    display: block !important;
  }
  .hidden-xs {
    display: none !important;
  }
  .sidePadd{
    padding: 0 20px;
  }
  .padding{
    padding: 40px 0px;
  }
  .col-xs-6{
    width: 50%;
  }
  .col-xs-12{
    width: 100%;
  }
  header{
    height: 85px;
    width: 100%;
    border-radius: unset;
    margin: 0;
    box-shadow: none;
  }
  .burger-menu {
    display: block;
    padding: 0;
    margin-top: 0;
  }
.sidelogo{
  width: 130px;
}
  .menu {
    padding: 70px 0 0;
    height: 100%;
    overflow: auto;
    display: none;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: -1;
    border-bottom: 1px solid #ccc;
    padding: 0px;
    background-color: $white;
    transform: unset;
  }
  .project-mobile-menu {
    height: auto;
    display: none;
    position: absolute;
    top: 50px;
    width: 100%;
    left: 0px;
    right: 0px;
    z-index: -1;
    border-bottom: 1px solid #ccc;
    padding: 0px;
    background-color: #000;
    transform: unset;
    z-index: 99;
  }
  .menu a, .project-mobile-menu a {
    text-align: left;
    font-size: 14px;
    padding: 6px 0px;
    border-right: 0px;
    margin: 0px;
    color: $white;
    display: block;
  }
  .menu a.active, .project-mobile-menu a.active {
    position: relative;
    font-weight: 600;
    color: $white;
  }
  .menu a.enquire-toggle-button.header-enquire , .menu a.call-us , #home a.home-enquire  {
    display: none;
  }
  .menu li, .project-mobile-menu li {
    display: block;
    margin: 0 auto;
}
.nav-links, .project-mobile-menu{
  display: none;
}
.mobile_tabs {
  h3 {
    padding: 10px 0px;
    &.active{
      svg{
        transform: rotate(0deg);
      }
    }
    svg{
      width: 20px;
      float: right;
      position: relative;
      transform: rotate(270deg);
      transition: all .5s;
    }
  }
}
.menu li a {
    color: $black;
    margin: 0 auto;
    padding: 10px;
    font-size: 16px;
    line-height: 17px;
}
.menu .sub-menu {
    position: unset;
    margin-top: 0;
    display: none;
}
.menu .expand{
  visibility: visible;
  opacity: 1;
  display: block;
  width: 100%;
}
.parent-menu > a:after {
    border-top: 5px solid $white;
    right: unset;
    top: 18px;
    margin-left: 10px;

}
  .footer-call-enquire {
    display: block;
    position: fixed;
    bottom: 0;
    text-align: center;
    background-color: $white;
    z-index: 2;
    border-top: 1px solid #ebebeb;
  }
  .footer-call-enquire a{
    font-size: 16px;
    font-weight: 600;
  }
  .enquire-toggle-button.mobile {
    cursor: pointer;
    letter-spacing: 1px;
    font-size: 16px;
    border: none;
    padding: 0 !important;
    float: unset;
    line-height: 25px;
    margin-left: 0;
    text-transform: uppercase;
    font-weight: 500;
  }
  .floating-enquire-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  #popup{
    padding: 30px 10px;
  }
header .col-12 {
  padding: 8px 4px;
}

.brand-logo{
  padding: 0;
  border-right:unset;
}


.enquire-close-mobile {
  text-align: right;
  font-weight: bold;
  margin-bottom: 10px;
}

  .form-control {
    padding: 5px 12px;
  }
  .form_wrapper {
    padding: 20px;
}
.form-group {
    padding: 0;
}
input#submit_form{
  margin: 0;
  width: 100%;
}
.footer-top h4 {
    padding: 20px 0;
}
.footer-bottom{
  padding:10px 0;
  
}

.footer-top .col-4 {
  padding: 0 0px 20px 0;
}
.rera p, .rera p a, .marketed p a, .rera-text span, .rera-text p{line-height: 23px;font-size: 14px;}
.enq {
 padding: 8px 0;
}

.rera, .rera a, .marketed a, .marketed p{
  font-size: 14px;
  line-height: 26px;
}
.slick-prev, .slick-next{
  display: none !important;
}
.slider-drag-btn-wrapper{
  width: 285px;
}

.footer-mob{
  padding-bottom: 50px;
}

header .form-container {
  position: absolute;
  width: 100%;
  right: 0;
  top: 0;
  height: 100vh;
  background-color: #ffffff;
  padding: 10px;
  display: none;
  box-shadow: 0px 16px 20px 0px rgb(0 0 0 / 28%);
  padding-top: 30px!important;
}
.sell_do_virtual_numbers{
  color: $white;
}

}

@media only screen and (max-width: 700px){
  header{
    height: 70px;
  }
  .menu{
    top: 70px;
    padding: 25px 20px 90px;
  }
}

@media only screen and (max-width: 1440px){
 
}


@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  
.menu{
  top: 0px;
  padding: 70px 0 0;
  li {
    a{
      padding: 15px 3px;
      font-size: 13px;
    }
  }
} 
}

@media only screen and (min-width: 1024px) and (max-width: 1200px)  { 
  .menu {
    a {
      padding: 0px 3px;
      font-size: 12px;
    }
  }
  .footer-bottom {
    padding: 0 25px 15px;
  }
  .footer-top {
    padding: 40px 30px 0px;
  }
}

@media(min-width:1441px) and (max-width:1600px) {
 
}
@media(min-width:1601px) and (max-width:1920px) {

}
/********* Homepage **************/
@keyframes homepage_spin_words {
  35% {
    transform: translateY(-100%);
}

70% {
    transform: translateY(-200%);
}
100% {
    transform: translateY(-300%);
}
}

.homepage_spin_words {
  animation-name: homepage_spin_words;
  animation-duration: 6s;
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
}

.fold1_wrapper{
  .reeloBrand_slider{ 
    padding: 40px 0;
    .brandSlide{
      height: 120px;
      padding: 0 27px;
      display: flex !important;
      align-items: center;
      justify-content: center;
      padding: 30px 24px;
      @media (max-width:$sm){
        padding: 10px 24px;
        height: 80px;
        width: 120px !important;
      }
      img{
        width: 100px;
        height: 100px;
        object-fit: contain;
        @media (max-width:$sm){
          width: 80px;
          height: 80px;
        }
      }
    }
  }
}
.homepage{
  .sec_subtitle{
    font-size: 24px;
    color: #697777;
    line-height: 28px;
    text-align: center;
    margin-bottom: 8px;
    letter-spacing: -.96px;
    font-family: $basierMedium;
    @media (max-width:$sm){
      font-size: 16px;
      line-height: 24px;   
    }
  }
  .sec_title{  
    font-size: 58px;
    line-height: 64px;
    letter-spacing: -2.32px;
    font-family: $basierMedium;
    font-weight: 500;
    @media (max-width:$sm){
      font-size: 24px;
      line-height: 30px;
      letter-spacing: -0.96px;
    }
  }
  .banner{
    height: 100vh;
    .banner_wrapper{
      .banner-container{
        position: absolute;
        top:55%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        padding: 0 15px;
        .rating{
          margin-bottom: 24px;
          @media (max-width:$sm){
            margin-bottom: 0;
          }
          p{
            font-size: 16px;
            line-height: 22px;
            font-family: $basierMedium;
            color: #697777;
            @media (max-width:$sm){
              font-size: 14px;
              line-height: 20px;
            }
          }
        }
        .homepage_title_wrapper{
          font-size: 72px;
          color: #192121;
          line-height: 75px;
          text-align: center;
          letter-spacing: -2.88px;
          font-family: $basierSemibold;
          margin: 12px auto 24px;
          gap: 0 16px;
          flex-wrap: wrap;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 990px;
          @media (max-width:$sm){
            font-size: 34px;
            line-height: 42px;
            letter-spacing: -1.36px;
            width: 100%;
            margin: 10px 0;
          }
          // .homepage_animated_text {
          //   height: 75px;
          //   overflow: hidden;
          //   b{
          //     display: block;
          //     font-size: 72px;
          //     color: #0d9298;
          //     line-height: 75px;
          //     text-align: end;
          //     letter-spacing: -2.88px;
          //     animation: homepage_spin_words 6s infinite;
          //   }
          // }
        }
        .banner-text{
          font-size: 20px;
          font-weight: 400;
          line-height: 28px;
          color: #697777;
          text-align: center;
          letter-spacing: -.2px;
          width: 815px;
          margin: 0 auto;
          @media (max-width:$sm){
            font-size: 14px;
            line-height: 20px;
            width: 100%;
          }
        }
        .btn_wrapper{
          .theme-btn{
            font-size: 18px !important;
            width: 249px;
            margin: 20px auto 8px;
            padding: 15px 25px;
            font-family: $basierMedium;
            @media (max-width:$sm){
              margin: 0 auto 8px;
              height: unset;
            }
          }
          .text-small{
            color: #697777;
            font-size: 12px;
            line-height: 16px;
            text-align: center;
            font-family: $basierMedium;
          }
        }
      }
    }
  }
  .fold1{
    .sec-title{
      font-size: 28px;
      color: #0e1b1b;
      line-height: 34px;
      text-align: center;
      margin: 0px 0 30px;
      letter-spacing: -.28px;
      font-family: $basierMedium;
      font-weight: 500;
      @media (max-width:$sm){
        font-size: 24px;
        line-height: 30px;
        margin: 0 auto 12px;
        letter-spacing: -.96px;
      }
      span{
        color: #0d9298;
      }
    }
    
  }
  .fold3{
    .marrows {
      margin: 30px 0 35px;
      svg {
        width: 48px;
        height: 48px;
        background-color: #f3f5f5;
        border-radius: 100%;
        padding: 10px;
        margin: 0 10px;
        &:hover{
          background-color: #0d9298;
          path{
            stroke: $white;
          }
        }
      }  
      #cnext{
        position: relative;
        transform: rotate(180deg);
      }
    }
    .reviewCarousel_parent{
      .reviewCarousel{
        gap: 48px;
        padding: 24px;
        height: 381px;
        margin: 0 4px;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        border-radius: 24px;
        align-items: stretch;
        background: #e0eaff;
        @media (max-width:$sm){
            gap: 12px;
            padding: 16px;
            margin: 0 4px;
            border-radius: 8px;
            flex-direction: column;
            height: unset;
        }
        .reviewCarousel_left{
          max-width: 665px;
          overflow: hidden;
          width: calc(50% - 24px);
          @media (max-width:$sm){
            max-width: 100%;
            width: 100%;
            overflow: unset;
          }
          iframe{
            width: 100%;
            height: 100%;
            border: none;
            outline: none;
            border-radius: 12px;
          }
        }
        .reviewCarousel_right{
          gap: 24px;
          padding: 10px 0 0;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          flex-direction: column;
          max-width: -moz-calc(50% - 24px);
          max-width: calc(50% - 24px);
          @media (max-width:$sm){
            max-width: 100%;
            min-width: 100%;
            padding: 15px 0 0;
          }
          h3{
            font-size: 32px;
            line-height: 40px;
            letter-spacing: -1.28px;
            font-family: $basierMedium;
            font-weight: 500;
            @media (max-width:$sm){
              margin: 0 0 8px;
              font-size: 16px;
              line-height: 20px;
              letter-spacing: -.24px;
            }
          }
          .reviewCarousel_review_detail{
            gap: 16px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .reviewImgs{
              border-radius: 50%;
              object-fit: contain;
              background-color: #fff;
            }
            .reviewer_name{
              font-size: 22px;
              line-height: 26px;
              font-family: $basierMedium;
              padding: 0 0 6px;
              @media (max-width:$sm){
                font-size: 16px;
                line-height: 22px;
              }
            }
            .reviewer_post{
              font-size: 14px;
              color: #374040;
              line-height: 20px;
              letter-spacing: -.14px;
              @media (max-width:$sm){
                font-size: 12px;
                line-height: 16px;
              }
            }
          }
        }
      }
    }
  }
  .fold8{
    .reviewCarousel_case_studies{
      gap: 24px;
      margin: 60px 0 0;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      @media (max-width:$sm){
        gap: 18px;
        overflow-x: auto;
        margin: 24px 0 0;
        -moz-box-pack: start;
        justify-content: flex-start;
      }
      .reviewCarousel_case_study{
        width: calc(50% - 12px);
        display: block;
        @media (max-width:$sm){
          min-width: 254px;
          width: calc(50% - 9px);
        }
        .reviewCarousel_link{
          h3{
            font-size: 28px;
            margin: 20px 0 12px;
            line-height: 36px;
            letter-spacing: -1.12px;
            color: #0e1b1b;
            @media (max-width:$sm){
              font-size: 14px;
              line-height: 20px;
              margin: 10px 0;
              letter-spacing: -.14px;      
            }
          }
          p{
            text-decoration: underline;
            font-family: $basierMedium;
            color: #0e1b1b;
            @media (max-width:$sm){
              margin: 0 0 12px;
              font-size: 14px;
              line-height: 16px;    
            }
          }
        }
      }
    }
  }
  .fold2{
    .fold2_wrapper{
      width: 100%;
      height: 565px;
      max-width: 1024px;
      margin: 40px auto 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background-size: contain;
      background-position: 50%;
      background-repeat: no-repeat;
      @media (max-width:$sm){
        height: unset;
        max-width: 100%;
        width: 100%;
        display: block;
      }
      .quotes_Img {
        width: 760px;
        @media (max-width:$sm){
          width: 100%;
        }
        .video-thumbnail {
          .play-icon-wrapper {
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            left: 50%;
            cursor: pointer;
          }
        }
        iframe{
          height: 403px;
          border: none;
          outline:none;
          border-radius: 24px;
        }
      }
    }
  }
  .fold4{
    .sec-subtitle{
      font-size: 24px;
      color: #697777;
      line-height: 28px;
      text-align: center;
      margin-bottom: 8px;
      letter-spacing: -.96px;
      @media (max-width:$sm){
        font-size: 16px;
        line-height: 20px;
      }
    }
    .homepage_features{
      margin: 60px 0 0;
      gap: 64px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      @media (max-width:$sm){
        margin: 30px 0 0;
        gap: 32px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
      }
      .homepage_feature_card{
        gap: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width:$sm){
          gap: 16px;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          flex-direction: column;
        }
        .homepage_left{
          max-width: 453px;
          @media (max-width:$sm){
            max-width: 100%;
          }
        }
        .homepage_right{
          max-width: 582px;
          @media (max-width:$sm){
            max-width: 100%;
          }
          h3{
            font-size: 32px;
            margin: 0 0 14px;
            color: #0e1b1b;
            line-height: 38px;
            letter-spacing: -.96px;
            font-family: $basierMedium;
            font-weight: 500;
            @media (max-width:$sm){
              font-size: 16px;
              line-height: 22px;
              letter-spacing: -.14px;
            }
          }
          p{
            font-size: 18px;
            margin: 0 0 28px;
            color: #697777;
            line-height: 24px;
            letter-spacing: -.18px;
            @media (max-width:$sm){
              font-size: 14px;
              margin: 0 0 16px;
              line-height: 20px;
              letter-spacing: -.14px;
            }
          }
          .homepage_btn_row{
            gap: 28px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            @media (max-width:$sm){
              gap:16px;
              .theme-btn{
                padding: 7px 10px;
                width: 125px;
                font-size: 12px !important;
              }
            }
            .custom-btn{
              color: #0E1B1B;
              background: transparent;
              border-radius: 8px;
              text-decoration: underline;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
  .fold5{
    .homepage_more_features_cards{
      margin: 60px 0 0;
      gap: 32px 36px;
      flex-wrap: wrap;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width:$sm){
        gap: 28px;
      }
      .homepage_more_feature_card{
        border-radius: 12px;
        padding: 32px 40px 0;
        width: calc(50% - 18px);
        background-color: #f7f8f8;
        height: 608px;
        @media (max-width:$sm){
          width: 100%;
          border-radius: 8px;
          padding: 20px 20px 0;
          background-color: #f7f8f8;
          height: auto;
        }
        h3{
          font-size: 24px;
          color: #0e1b1b;
          line-height: 30px;
          letter-spacing: -.24px;
          font-family: $basierMedium;
          font-weight: 500;
          @media (max-width:$sm){
            font-size: 16px;
            line-height: 22px;
            letter-spacing: -.14px;
          }
        }
        p{
          margin: 8px 0;
          font-size: 20px;
          color: #374040;
          line-height: 26px;
          letter-spacing: -.2px;
          @media (max-width:$sm){
            margin: 4px 0;
            font-size: 14px;
            line-height: 20px;
          }
        }
        a{
          color: #0d9298 !important;
          font-family: $basierMedium;
          text-decoration: underline !important;
          @media (max-width:$sm){
            font-size: 12px;
          }
        }
        .homepage_card_img{
          width: 100%;
          height: 410px;
          cursor: pointer;
          margin: 20px 0 0;
          position: relative;
          @media (max-width:$sm){
            height: 260px;
          }
          img{
            height: 410px;
            @media (max-width:$sm){
              height: 260px;
            }
          }
        }
      }
    }
  }
  .fold6{
     padding: 40px 0;
     @media (max-width:$sm){
      padding: 30px 0 0;
     }
    .homepage_start_free_trial{
      padding: 34px 0;
      cursor: pointer;
      transform: skewY(-1.47deg);
      background: linear-gradient(93deg, #168489 -2.4%, #20c3c3 94.01%);
      overflow: hidden;
      white-space: nowrap;
      @media (max-width:$sm){
        padding: 18px 0;
      }
      .marquee {
        display: flex;
        animation: marquee 20s linear infinite;
        .marquee-content {
          display: flex;
          white-space: nowrap;
          .homepage_free_trial_text{
            padding: 0 48px;
            width: 100%;
            display: inline-flex !important;
            align-items: center;
            margin-right: 2rem;
            p{
              font-size: 28px;
              color: $white;
              line-height: 34px;
              letter-spacing: -.28px;
              font-family: $basierMedium;
              @media (max-width:$sm){
                font-size: 18px;
              }
            }
          }
       }
     }
    }
  }
  @keyframes marquee {
    from { transform: translateX(-50%); }
    to { transform: translateX(0); }
}
  .fold7{
    .homepage_quality_cards{
      margin: 60px 0 0;
      gap: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      align-items: stretch;
      @media (max-width:$sm){
        gap: 16px;
        padding: 0 16px;
        overflow-x: auto;
        justify-content: flex-start;
        margin: 20px 0 0;
      }
      .homepage_quality_card{
        padding: 40px;
        border-radius: 12px;
        background: #0e1b1b;
        width: -moz-calc(33% - 14px);
        width: calc(33% - 14px);
        @media (max-width:$sm){
          padding: 20px;
          min-width: 226px;
          border-radius: 8px;
          width: calc(33% - 14px);
        }
        svg{
          display: block;
          margin: 0 auto 8px;
          @media (max-width:$sm){
              width: 24px;
              height: 24px;
          }
        }
        h3{
          font-size: 24px;
          color: #ebbd07;
          margin: 0 0 32px;
          line-height: 30px;
          text-align: center;
          letter-spacing: -.24px;
          font-family: $basierMedium;
          font-weight: 500;
          height: 30px;
          @media (max-width:$sm){
            font-size: 14px;
            margin: 0 0 5px;
            line-height: 20px;
            letter-spacing: -.14px;
          }
        }
        .homepage_number{
          margin: 0 0 10px;
          font-size: 56px;
          line-height: 84px;
          color: #fff;
          text-align: center;
          letter-spacing: -.56px;
          font-family: $basierSemibold;
          @media (max-width:$sm){
            margin: 0 0 8px;
            font-size: 28px;
            line-height: 34px;
            letter-spacing: -.28px;
          }
        }
        .homepage_subtitle{
          font-size: 20px;
          text-align: center;
          font-style: italic;
          line-height: 26px;
          letter-spacing: -.2px;
          color: hsla(0, 0%, 100%, .78);
          @media (max-width:$sm){
            font-size: 12px;
            line-height: 16px;
          }
        }
      }
    }
  }
  .fold9{
    background-color: #f7f8f8;
    padding: 70px 0 220px;
    @media (max-width:$sm){
      padding: 40px 0 100px;
    }
    .sec_desc{
      font-size: 20px;
      color: #697777;
      line-height: 28px;
      text-align: center;
      margin: 16px 0 32px;
      letter-spacing: -.2px;
    }
    .templateCarousel_templates_carousel{
      .templateCarousel_channel{
        gap: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto 166px;
        @media (max-width:$sm){
          margin: 0 auto 160px;
        }
        .templateCarousel_tab{
          cursor: pointer;
          padding: 8px 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 80px;
          background: #fff;
          border: 1px solid #e9eded;
          span{
            margin: 0;
            font-size: 18px;
            color: #697777;
            line-height: 24px;
            letter-spacing: -.18px;
            font-family: $basierMedium;
          }
          &.active_channel{
            background: #0d9298;
            border: 1px solid #0d9298;
            span{
              color: $white;
            }
          }
        }
      }
      .templateCarousel_slick_parent{
        .customer-logos{
          .templateCarousel_card_parent{
            .templateCarousel_card{
              // width: 270px;
              height: 377px;
              position: relative;
              border-radius: 18px;
              transition: .1s linear;
              @media (max-width:$sm){
                //width: 120px;
                height: 160px;
              }
              &:before{
                top: 0;
                left: 0;
                content: "";
                width: 100%;
                height: 100%;
                position: absolute;
                border-radius: 18px;
                background: linear-gradient(180deg, rgba(0, 0, 0, .3) 0, hsla(0, 0%, 100%, 0) 80%);
                z-index: 1;
              }
              .templateCarousel_cardImg{
                img{
                  // width: 270px;
                  height: 377px;
                  position: relative;
                  border-radius: 18px;
                  transition: .1s linear;
                  object-fit: cover;
                  border-radius: 18px;
                  vertical-align: middle;
                  @media (max-width:$sm){
                  //   width: 120px;
                      height: 160px;
                  }
                }
              }
              .templateCarousel_template_title{
                width: 100%;
                left: 0;
                top: 31px;
                z-index: 2;
                height: 60%;
                color: $white;
                font-size: 24px;
                line-height: 29px;
                position: absolute;
                word-wrap: break-word;
                padding: 0 8px 0 20px;
                font-family: $basierSemibold;
                @media (max-width:$sm){
                  font-size: 12px;
                  line-height: 16px;
                  padding: 0 8px 0 10px;
                  top:10px;
                }
              }
            }
          }
        }
      }
    }
  }
  .fold10{
    .homepage_integration{
      margin: 40px 0 0;
      @media (max-width:$sm){
        margin: 20px 0 0;
      }
        .logoSlider{
          padding: 30px 0;
          display: flex;
          white-space: nowrap;
          @media (max-width:$sm){
            padding: 10px 0;
          }
          .homepage_logo_parent{
            height: 114px;
            margin: 0 24px;
            position: relative;
            border-radius: 12px;
            background: #f7f8f8;
            width: 267px !important;
            @media (max-width:$sm){
              height: 58px;
              padding: 0 8px;
              margin: 0 12px;
              border-radius: 8px;
              width: 100px !important;
            }
            img{
              min-width: 100%;
              max-width: 100%;
              min-height: 100%;
              max-height: 100%;
              object-fit: scale-down;
              filter: none;
              background-size: cover;
              background-image: none;
              padding: 20px 35px;
              @media (max-width:$sm){
                padding: 9px 10px;
              }
            }
          }
        }
    }
  }
}
@keyframes marquee1 {
  from { transform: translateX(-50%); }
  to { transform: translateX(0); }
}

.homepagenew{
  .sec_title{
    font-size: 57px;
    font-weight: 800;
    line-height: 120%;
    @media (max-width:$sm){
      font-size: 28px;
    }
  }
  .sec_desc{
    font-size: 24px;
    font-weight: 400;
    line-height: 150%;
    padding: 10px 0 40px;
    @media (max-width:$sm){
      font-size: 16px;
    }
  }
  .banner{
    background-image: url('../images/home-bg.webp');
    background-position: top;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 1500px;
    @media (max-width:$sm){
      height: 858px;
      background-image: url('../images/home-bg-mob.webp');
    }
    .banner_wrapper{
      padding: 120px 0 0;
      .banner_container{
        .rating{
          font-size: 13px;
          font-weight: 400;
          line-height: 150%;
          img{
            vertical-align: bottom;
            margin-right: 10px;
            font-size: 8px;
          }
        }
        .banner_title{
          font-size: 76px;
          font-weight: 800;
          line-height: 110%;
          letter-spacing: -0.76px;
          padding: 20px 0 24px;
          @media (max-width:$sm){
            font-size: 32px;
          }
        }
        .banner_desc{
          font-size: 24px;
          font-weight: 500;
          line-height: 150%; /* 36px */
          @media (max-width:$sm){
            font-size: 15px;
          }
        }
        .banner_img{
          padding:40px 0 0;
          @media (max-width:$sm){
           padding: 20px 0 0;
          }
        }
      }
    }
  }
  .fold1{
    .fold1_wrapper{
      .logoSlider{
        padding: 20px 0;
        display: flex;
        white-space: nowrap;
        .homepage_logo_parent {
          display: flex !important;
          align-items: center;
          justify-content: center;
          width: auto !important;
          img {
            object-fit: contain;
            height: 85px;
            @media (max-width:$sm){
              height: 60px;
            }
          }
        }
      }
    }
  }
  .fold2{
    .card_wrapper{
      .cards-section{
        .card{
          top:0;
          left: 0;
          margin:0 0 30px;
          width: 100%;
          height: 440px;
          border-radius: 30px 30px 0px 0px;
         //background: #FFF9F0;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: transform 0.5s ease, z-index 0.5s ease;
          //box-shadow: 0px -4px 15px 0px rgba(95, 52, 0, 0.10);
          @media (max-width:$sm){
            display: block;
            height: unset;
            margin: 0;
          }
          .cardFlex{
            display: flex;
            align-items: flex-start;
            gap: 20px;
            @media (max-width:$sm){
              display: block;
            }
            .flex1{
              border-radius: 30px;
              width: 463px;
              height: 450px;
              display: flex;
              align-items: center;
              padding: 20px;
              @media (max-width:$sm){
                width: 100%;
                height: unset;
                display: block;
                padding: 20px;
                margin: 0 0 20px;
                border-radius: 14px;
              }
              .challenge{
                font-size: 16px;
                font-weight: 700;
                line-height: 150%; /* 27px */
                text-transform: uppercase;
                padding-bottom: 8px;
                @media (max-width:$sm){
                 font-size: 14px;
                }
              }
              .c1{
                color: #FFBBCC;
              }
              .c2{
                color: #C9FFE5;
              }
              .c3{
                color: #FFE7B6;
              }
              .c4{
                color: #D1E6FF;
              }
              .c_title{
                font-size: 36px;
                font-weight: 700;
                line-height: 120%; /* 43.2px */
                @media (max-width:$sm){
                  font-size: 24px;
                 }
              }
              .flex-list{
                padding: 30px 0 0 18px;
                li{
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 150%; /* 24px */
                  @media (max-width:$sm){
                    font-size: 14px;
                   }
                }
              }
              .fl1{
                li{
                  color: #FFF9F0;
                }
              }
              .fl2{
                li{
                  color: $white;
                }
              }
            }
            .f1{
              background: #990024;
            }
            .f2{
              background: #15B79E;
            }
            .f3{
              background: #E29600;
            }
            .f4{
              background: #044A9E;
            }
            .flexkomal{
              padding: 0px 100px 0 74px !important;
              @media (max-width:$sm){
                padding: 0 !important;
              }
            }
            .flex2{
              display: flex;
              width: 657px;
              height: 450px;
              padding: 0px 74px 0 74px;
              justify-content: center;
              align-items: flex-end;
              border-radius: 30px;
              @media (max-width:$sm){
                width: 100%;
                height: unset;
                padding: 10px 20px 0;
                margin: 0 0 20px;
                border-radius: 14px;
              }
              .flimg{
                width: 596.749px;
                @media (max-width:$sm){
                  width: 100%;
                }
              }
              .komal{
                width: 630.387px;
                border-radius: 0 0 0 30px;
                @media (max-width:$sm){
                  width: 100%;
                  border-radius: 0 0 0 14px;
                }
              }
            }
            .fc1{
              background: #FDE0E7;
              align-items: center;
            }
            .fc2{
              background: #CAFFE5;
            }
            .fc3{
              background: #FFE7B6;
            }
            .fc4{
              background: #D1E6FF;
            }
            .flex3{
              display: flex;
              width: 349px;
              flex-direction: column;
              align-items: flex-start;
              gap: 50px;
              flex-shrink: 0;
              .list{
                ul{
                  padding: 0 0 0 17px;
                  li{
                    color:  #1A1A1A;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 150%; /* 24px */
                  }
                }
              }
              .quote{
                font-size: 24px;
                font-weight: 500;
                line-height: 150%;
              }
              .cardquote1{
                color: #4B001A;
              }
              .cardquote2{
                color: #6C5501;
              }
              .cardquote3{
                color: #6E3E00;
              }
              .cardquote4{
                color: #004B26;
              }
              .quote_div{
                font-feature-settings: 'liga' off, 'clig' off;
                font-size: 18px;
                font-weight: 700;
                line-height: 20px;
                display: flex;
                align-items: center;
                gap: 10px;
                padding: 20px 0 0;
              }
              .cardtext1{
                color: #B62256;
              }
              .cardtext2{
                color: #AA8500;
              }
              .cardtext3{
                color: #EC921C;
              }
              .cardtext4{
                color: #254A38;
              }
            }
          }
        }
      }
    }
  }
  .fold3{
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 100px 0 96px;
    @media (max-width:$sm){
      padding: 30px 0;
      height: unset;
      background-image: url('../images/video-frame-mob.webp') !important;
    }
    .sec_title{
      @media (max-width:$sm){
        font-size: 20px;
      }
    }
    .sec_desc {
      @media (max-width:$sm){
        font-size: 10px;
        padding: 10px 0;
      }
    }
    .video_wrapper{
      @media (max-width:$sm){
        padding: 0 20px;
      }
      .quotes_Img{
        .video-thumbnail{
          .play-icon-wrapper {
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            left: 50%;
            cursor: pointer;
          }
        }
      }
      iframe{
        height: 563px;
        border-radius: 12px;
        border: none;
        border-radius: 8px;
        @media (max-width: $sm){
          height: 265px;
        }
      }
    }
  }
  .fold4{
    .tooltabs{
      padding: 15px 0 72px;
      @media (max-width:$sm){
        display: flex;
        overflow-x: auto;
        width: 100%;
        white-space: nowrap;
        scrollbar-width: thin;
        padding: 15px 0 30px;
        margin: 0 0 10px;
      }
      a{
        color: #023342;
        font-size: 16px;
        font-weight: 600;
        line-height: 150%;
        border-radius: 48px;
        border: 1px solid #023342;
        padding: 10px 24px 6px;
        margin: 12px 12px 0 0;
        display: inline-block;
        @media (max-width:$sm){
          font-size: 14px;
          padding: 8px 16px 6px;
          margin: 12px 6px 0 0;
        }
        &.active{
          color: $white;
          background: var(--colour-primary-teal-dark, #023342);
        }
      }
    }
    .toolFold{
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 72px;
      @media (max-width:$sm){
        flex-direction: column-reverse;
        gap: 30px;
      }
      .toolLeft{
        max-width: 530px;
        @media (max-width:$sm){
          max-width: 100%;
        }
        .sec_title{
          color: #023342;
          font-size: 43px;
          font-weight: 600;
          line-height: 120%;
          padding-bottom: 20px;
          @media (max-width:$sm){
            font-size: 24px;
          }
        }
        ul{
          padding: 0 0 40px 22px;
          li{
            color: #1A1A1A;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 27px */
            @media (max-width:$sm){
              font-size: 16px;
            }
          }
        }
      }
      .toolRight{
        max-width: 560px;
        @media (max-width:$sm){
          max-width: 100%;
        }
      }
    }
  }
  .fold5{
    background: #FFF1DD;
    //background: linear-gradient(180deg, #FFF 0%, #FFF9F0 6.28%, #FFFCF9 87.17%, #FFF 100%);
    .static_wrapper{
      padding: 40px 0 0;
      border-radius: 12px;
      .fold1{
        background: var(--secondary-shades-teal03-3t300, #1A424F);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 30px;
        height: 400px;
        border-radius: 12px 0 0 12px;
        @media (max-width:$sm){
          border-radius: 12px 12px 0 0;
          height: 350px;
        }
        .fold1_desc{
          .title{
            font-size: 32px;
            font-weight: 800;
            line-height: 130%;
          }
          .desc{
            font-size: 14px;
            font-weight: 400;
            line-height: 130%;
            padding: 8px 0 0;
          }
          .cta-btn{
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%; /* 19.6px */
            text-transform: uppercase;
            color: #04BEBD;
          }
        }
      }
      .fold2{
        .fold2_wrapper{
          .foldLeft{
            background-color: #D16309;
            height: 270px;
            padding: 165px 30px 30px 30px;
            @media (max-width:$sm){
              padding: 80px 30px 30px;
              height: unset;
            }
            .cs_count{
              .number{
                font-size: 40px;
                font-style: normal;
                font-weight: 800;
                line-height: 130%;
              }
              .desc{
                font-size: 14px;
                font-weight: 400;
                line-height: 130%;
                padding: 8px 0 0;
              }
            }
          }
          .foldRight{
            height: 270px;
            @media (max-width:$sm){
              height: unset;
            }
            img{
              border-radius: 0 12px 12px 0;
              @media (max-width:$sm){
               border-radius: 0;
              }
            }
          }
        }
        .foldBottom{
          background: #F9CE78;
          border-radius: 0 0 12px 0;
          @media (max-width:$sm){
            border-radius: 0 0 12px 12px;
            margin: -5px 0 0;
           }
          .flexBox{
            display: inline-flex;
            padding: 26px 90px 26px 30.258px;
            align-items: flex-start;
            gap: 20.314px;
            @media (max-width:$sm){
              padding: 20px;
              gap: 10px;
              flex-wrap: wrap;
             }
            p{
              color: #1A424F;
              font-size: 20px;
              font-style: normal;
              font-weight: 600;
              line-height: 130%;
              @media (max-width:$sm){
               font-size: 16px;
              }
            }
          }
        }
      }
    }
    .reelo_cs{
      padding: 24px 0 0;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      @media (max-width:$sm){
        display: block;
      }
      .blogBox{
        width: 404px;
        @media (max-width:$sm){
          width: 100%;
        }
        .csinner-box-wrapper{
          border-radius: 12px;
          background: var(--colour-monochrome-white, #FFF);
          box-shadow: 3px 4px 12.2px 0px rgba(2, 51, 66, 0.12);
          padding: 20px;
          a{
            .cs-box{
              .cs_Img{
                padding: 0 0 12px;
              }
              .title{
                color:  #1A424F;
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                padding: 12px 0 0;
                @media (max-width:$sm){
                  font-size: 20px;
                }
              }
              .desc{
                color: #666;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 130%;
                padding: 14px 0;
              }
              .cta-btn{
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 140%; /* 19.6px */
                text-transform: uppercase;
                color: #04BEBD;
              }
            }
          }
        }
      }
    }
  }
  .fold6{
    .integrate_wrapper{
      padding-top: 50px;
      @media (max-width:$sm){
        padding-top: 20px;
      }
      .flexDiv{
        padding-top: 8px;
        display: flex;
        align-items: center;
        align-content: center;
        gap: 8px;
        flex-wrap: wrap;
        @media (max-width:$sm){
          padding-top: 40px;
          align-items: center;
          align-content: center;
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 6px;
          text-align: center;
        }
        .integrate{
          img{
            height: 60px;
            @media (max-width:$sm){
              height: 30px;
            }
          }
        }
      }
    }
  }
  .fold7{
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    @media (max-width:$sm){
      background-image: url('../images/intergration-bg-mob.webp') !important;
    }
    .lovereelo_wrapper{
      padding-top: 60px;
      display: flex;
      align-items: flex-start;
      gap: 20px;
      justify-content: center;
      @media (max-width:$sm){
        flex-wrap: wrap;
        gap: 40px;
        padding-top: 40px;
      }
      .lovereelo_inner{
        .lovereelo_title{
          font-size: 43px;
          font-weight: 600;
          line-height: 120%; 
          @media (max-width:$sm){
            font-size: 30px;
          }
        }
        .t1{
          color: #FAC8D4;
        }
        .t2{
          color: #B6EB9A;
        }
        .t3{
          color: #FFE7B6;
        }
        .lovereelo_img{
          padding: 20px 0;
        }
        .lovereelo_desc{
          display: flex;
          align-items: flex-start;
          gap: 14px;
          justify-content: center;
          span{
            font-size: 48px;
            font-weight: 900;
            line-height: 120%;
            @media (max-width:$sm){
              font-size: 39px;
            }
          }
          p{
            font-size: 16px;
            font-weight: 600;
            line-height: 150%;
            text-align: left;
            @media (max-width:$sm){
              font-size: 13px;
            }
          }
        }
      }
    }
  }
  .fold8{
    margin: -2px 0 0;
    background: #023342;
    .faq_wrapper{
      padding: 40px 0 0;
    }
    .faqs-list {
      padding: 0 20px 0 0;
      min-height: 400px;
      @media (max-width:$sm){
        padding: 0;
        min-height: unset;
      }
      .book-demo{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        align-self: stretch;
        margin: 60px 0 0;
        @media (max-width:$sm){
          flex-wrap: wrap;
          gap: 20px;
          text-align: center;
        }
        p{
          color: #04BEBD;
          font-size: 24px;
          font-weight: 600;
          line-height: 100%; /* 24px */
          @media (max-width:$sm){
            font-size: 18px;
          }
        }
      }
    }
    .set {
      position: relative;
      width: 100%;
      height: auto;
      margin:auto;
    }
    .set a {
        display: block;
        padding: 16px;
        text-decoration: none;
        transition: all 0.2s linear;
        margin: 8px 0 0;
        font-size: 20px;
        color: #1A424F;
        line-height: 130%;
        border-radius: 12px;
        background: var(--colour-monochrome-white, #FFF);
        font-weight: 600;
        position: relative;
        height: auto;
        @media (max-width:$sm){
          font-size: 16px;
        }
    }
    
    .set a i {
      position: absolute;
      right: 25px;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 100%;
      transition: transform 0.2s ease-in-out;
      @media (max-width: $sm){
        right: 18px;
      }
    }
    
    .set a .icon-minus:after {
      width: 20px;
      height: 20px;
      background-image: url('../images/minus.webp');
      background-repeat: no-repeat;
      top: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
      content: '';
      text-align: center;
      transition: transform 0.2s ease-in-out;
      left: 50%;
    }
    .set a .icon-plus:before{
          position: absolute;
          content: '';
          top: 50%;
          transform: translate(-50%,-50%);
          text-align: center;
          left: 50%;
          background-image: url('../images/plus.webp');
          background-repeat: no-repeat;
          width: 20px;
          height: 20px;
          transition: transform 0.2s ease-in-out;
    }
    .set .faqs_content {
      background: var(--colour-monochrome-white, #FFF);
      padding: 20px;
      margin: -10px 0 0;
      border-radius: 0 0 12px 12px;
      div{
        color: #141415;
        font-size: 14px;
        font-weight: 400;
        line-height: 130%; 
        a{
          display: inline-block;
          padding: 0;
          margin: 0;
        }
        ul{
          padding: 10px 20px;
          li{
            padding: 0 0 6px;
            a{
              display: inline-block;
              padding: 0;
            }
          }
        }
        ol{
          padding: 10px 20px;
          li{
            padding: 0 0 6px;
            a{
              display: inline-block;
              padding: 0;
            }
          }
        }
      }
    }
  }
  .fold9{
    margin: -2px 0 0;
    &:before {
      position: absolute;
      content: '';
      background: #023342;
      left: 0;
      top: 0;
      height: 50%;
      width: 100%;
      z-index: -1;
      @media (max-width:$sm){
        height: 33%;
      }
    }
    .try_wrapper{
      display: inline-flex;
      align-items: center;
      justify-content: center;
        .big-arrow{
          position: relative;
          border-radius: 100%;
          left: -6px;
          &:hover{
            box-shadow: 4px 6px $black;
            transition: .4s;
            transform: rotate(-45deg);
          }
          @media (max-width:$sm){
            left: -2px;
            width: 60px;
          }
      }
      .ctabtn_big{
        font-size: 80px;
        font-weight: 800;
        line-height: 130%;
        text-transform: capitalize;
        border-radius: 500px;
        background: #0E8085;
        padding: 101.545px 83px 96.455px 83px;
        @media (max-width:$sm){
          font-size: 18px;
          padding: 18px 50px 15px;
        }
        span{
          color: #63FAFF;
        }        
      }
    }
  }
  .fold10{
    .testimonial1{
      margin: 70px 0 0;
      border-radius: 12px;
      background: #DAF9DE;
      display: flex;
      align-items: flex-end;
      gap: 65px;
      @media (max-width:$sm){
        gap: 40px;
        flex-direction: column-reverse;
        margin: 50px 0 0;
      }
      .q1 {
        position: absolute;
        top: -40px;
        left: 20px;
      }
      .youngman{
        border-radius: 0 0 0 12px;
      }
      .quote1{
        display: flex;
        width: 601px;
        flex-direction: column;
        align-items: flex-start;
        gap: 40px;
        padding: 40px 0;
        @media (max-width:$sm){
          width: 100%;
          gap: 20px;
          padding: 68px 20px 0;
        }
        p{
          color: #0D793D;
          font-size: 43px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%; /* 48px */
          @media (max-width:$sm){
            font-size: 20px;
          }
        }
        .q2{
          position: absolute;
          right: 10px;
          bottom: 10px;
          @media (max-width:$sm){
            bottom: -12px;
          }
        }
        .quoteFlex{
          padding: 40px 0 0;
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          align-self: stretch;
          border-top: 1px solid #A0DCA8;
          @media (max-width:$sm){
            padding: 20px 0 0;
            flex-wrap: wrap;
            gap: 20px;
          }
          .name{
            color: #122E37;
            font-size: 32px;
            font-weight: 600;
            line-height: 120%; /* 38.4px */
            @media (max-width:$sm){
              font-size: 18px;
            }
          }
          .desig{
            color: #038584;
            font-size: 24px;
            font-weight: 400;
            line-height: 150%; /* 36px */
            @media (max-width:$sm){
              font-size: 14px;
            }
          }
        }
      }
    }
    .testimonial2{
      .quote2{
        border-radius: 12px;
        padding: 24px 20px;
        gap: 20px;
        .moblogo {
          position: absolute;
          right: 20px;
          top: 10px;
          width: 52px;
        }
        .quoteFlex {
          display: flex;
          justify-content: space-between;
          align-items: center;
          @media (max-width:$sm){
            display: block;
          }
          .quote_div{
            display: flex;
            gap:18px;
            align-items: center;
            .client{
              img{
                width: 78px;
                height: 78px;
                border-radius: 100%;
              }
            }
            .name{
              color: #023342;
              font-size: 20px;
              font-weight: 600;
              line-height: 130%; 
              @media (max-width:$sm){
                font-size: 18px;
              }
            }
            .desig{
              color: #333;
              font-size: 16px;
              font-weight: 400;
              line-height: 130%; 
              letter-spacing: 0.104px;
              @media (max-width:$sm){
                font-size: 14px;
              }
            }
          }
        }
        p{
          font-size: 23px;
          font-weight: 400;
          line-height: 150%;
          padding: 20px 0 0;
          min-height: 260px;
          @media (max-width:$sm){
            font-size: 18px;
          }
        }
        .textclr1{
          color: #8A3B08;
        }
        .textclr2{
          color: #232D2D;
        }
      }
      .clr1{
        background: #FED6BB;
      }
      .clr2{
        background: #E3CDFF;
      }
    }
    .padd1{
      padding: 15px 8px 0 0;
      @media (max-width:$sm){
        padding: 15px 0 0;
      }
    }
    .padd2{
      padding: 15px 0 0 8px;
      @media (max-width:$sm){
        padding: 15px 0 0;
      }
    }
  }
}

.webinars {
  .latest_fr{
    margin: 60px 0 0;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    height: 572px;
    @media (max-width: $sm){
      flex-wrap: wrap;
      margin: 30px 0;
      height: unset;
      gap: 0;
    }
    .latest_frContent{
      max-width: 600px;
      margin: 0 auto 0 40px;
      @media (max-width: $sm){
        max-width: 100%;
        padding: 20px;
        text-align: center;
        margin: 0;
      }
      .latest_caption{
        color: #5F4D07;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        background: #EBBD07;
        padding: 8px 20px;
        border-radius: 90px;
        @media (max-width: $sm){
          font-size: 14px;
          padding: 6px 15px;
        }
      }
      .sec_title{
        @media (max-width: $sm){
          font-size:24px;
          line-height: 28px;
          letter-spacing: 0-0.48px;
        }
        .highlight {
          color: $yellow;
        }
      }
      .frshort_description{
        padding: 20px 0;
      }
    }
    .latest_frImg{
      .fr_latestImg{
        img {
          min-height: 494px;
          position: relative;
          top: 2.5px;
          border-top-right-radius: 40px;
          border-bottom-right-radius: 40px;
          @media (max-width:$sm){
            border-top-right-radius: 0px;
            border-bottom-left-radius: 24px;
            min-height: unset;
            top: 6.5px;
          }
        }
      }
      margin: 0;
      
      @media (max-width: $sm){
        max-width: 100%;
      }
    }
  }
  .blog_Title {
    color: #354545;
    font-weight: 600;
    font-size: 56px;
    line-height: 110%;
    letter-spacing: -.01em;
    font-family: $basierMedium;
    @media (max-width:$sm){
      font-size: 24px;
      line-height: 32px;
    }
}
.blog_wrapper{
  .sec-title{
    @media (max-width:$sm){
      font-size: 28px;
      line-height: 32px;
    }
  }
}
	.webinar-box {
		padding: 0 10px 30px !important;
    height:unset;
    // transition-duration: .3s !important;
    // &:hover{
    //   .webinar_Img {
    //     iframe {
    //       transform: scale(0.9);
    //     }
    //   }
    // }
		@media (max-width:$sm){
			padding: 20px 0 !important;
		}
    .webinar-title{
      font-size: 28px;
      line-height: 34px;
      color: #0e1b1b;
      @media (max-width:$sm){
        font-size: 20px;
        line-height:24px;
      }
    }
    .webitext{
      font-size: 20px;
      line-height: 28px;
      color: #0e1b1b;
      @media (max-width:$sm){
        font-size: 16px;
        line-height: 24px;
      }
    }
    .webinar_Img {
      iframe {
          border: none;
      }
    }
	}
}
.webinar_content{
	.profileImg{
		img{
			width: 75px;
			height: 75px;
			border-radius: 100%;
		}
	}
	.profile_desc{
    padding: 8px 0 8px 8px;
		.speaker_name{
			font-size: 20px;
			color: $color1;
			line-height: 26px;
			letter-spacing: -.2px;
      font-weight: 600;
      padding: 0 0 6px;
		}
		.speaker_bio{
			font-size: 16px;
			color: $color2;
			line-height: 22px;
		}
	}
}

.webinar_Img {
	iframe {
		border-radius: 8px;
		height: 225px;
	}
  .video-thumbnail{
    .videoimg{
        height: 225px;
    }
  }
}
.webitext{
  padding: 20px 0;
  @media (max-width:$sm){
    height: unset;
  }
}
.webinar-title {
	font-size: 22px;
	line-height: 28px;
	padding: 10px 0;
	height: 120px;
}

.blog_banner {
	margin: 70px 0 0;
	@media (max-width:$sm){
		margin: 50px 0 0;
	}
}
.latest_blogWrapper {
	border-radius: 40px;
  background: #FAECDE;
	height: 422px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 26px;
  @media (max-width:$sm){
    flex-wrap: wrap;
  }
}
.latest_caption {
	  padding: 12px 30px;
    background-color: #f0dbc6;
    border-radius: 30px;
    display: inline-block;
    margin: 0 0 26px;
    color: #A58565;
    font-size: 16px;
    font-weight: 600;
    line-height: 125%;
    text-transform: uppercase;
    @media (max-width:$sm){
      padding: 5px 10px;
      margin: 0 0 8px;
    }
	img {
		vertical-align: text-bottom;
	}
}
.blog_Title {
	font-size: 48px;
	font-style: normal;
	font-weight: 700;
	line-height: 110%;
	letter-spacing: -0.46px;
  margin-bottom: 26px;
	@media (max-width:$sm){
		font-size: 30px;
		line-height: 40px;
    margin-bottom: 20px;
	}
}
.latest_blogContent {
  max-width: 600px;
  padding: 0 0 0 40px;
	@media (max-width:$sm){
		padding: 0 0 20px;
	}
	.short_description{
		height: 85px !important;
		@media (max-width:$sm){
			height: unset !important;
		}
	}
}
.short_description {
	padding: 14px 0;
}
.latest_blogImg{
  width: 540px;
  margin: 0 auto;
}
.blog_latestImg {
  img{
    position: relative;
		transform: rotate(5.5deg);
		mix-blend-mode: darken;
    height: 287px;
  }
	// .img-bg {
  //   background-repeat: no-repeat;
  //   background-position: center;
  //   background-size: 100% 100%;
  //   min-height: 300px;
  //   @media (max-width:$sm){
  //     min-height: 180px;
  //   }
	// }
}
.read_now {
	letter-spacing: -0.32px;
	font-weight: 600;
	display: inline-flex;
	transition-duration: .3s;
	transition-property: transform;
	img {
		vertical-align: bottom;
	}
	&:hover{
		transform: scale(0.9);
	}
}
.see_more {
	color: $blue;
	font-weight: 600;
	float: right;
  @media (max-width:$sm){
    float: left !important;
  }
	img {
		vertical-align: bottom;
	}
	
}
.blog-category {
	font-size: 14px;
	text-transform: uppercase;
	font-weight: 600;
	padding: 0 0 10px;
}
.blog-title {
	font-size: 22px;
	line-height: 28px;
}

.resources-title {
	font-size: 18px;
	line-height: 24px;
	padding: 10px 0;
	height: 65px;
}
.blog_heading {
	border-top: 1px solid #D7DDDD;
	padding: 40px 0 0;
}
.blog_wrapper {
	padding: 40px 0;
	@media (max-width:$sm){
		padding: 0 0 40px;
	}
}
#recent_blogs {
	.blog-box {
		padding: 0 50px 0 0;
		@media (max-width:$sm){
			padding: 20px 0 40px;
		}
	}
	.blog-title{
		height: 85px !important;
	}
	.short_description {
		height: 90px;
	}
}
#all_blogs {	  
	  .search_div {
		padding: 0 !important;
		@media (max-width:$sm){
			padding: 0 !important;
		}
		.search-container{
			button {
				background: #fff;
				font-size: 0;
				border: none;
				cursor: pointer;
				position: absolute;
				right: 2px;
				bottom: 6px;
			}
			input[type=text] {
				padding: 8px 6px;
				margin-top: 8px;
				font-size: 17px;
			}
		}
	}

	.blog-box {
		padding: 0 10px;
		height: 405px;
		transition-duration: .3s;
	    transition-property: transform;
		&:hover{
			.blog_Img {
				img {
					transform: translateY(-8px);
					}
			}
		}
		.blog_Img {
			img {
				height: 213px;
				@media (max-width:$sm){
					height: 185px;
				}
				
			}
		}
		@media (max-width:$sm){
			padding: 20px 0;
			height: unset;
		}
	}

}


.blog_Img {
	img {
		border-radius: 8px;
		margin: 0 0 10px;
	}
}
#free-resources {
	padding: 0 0 50px;
	@media (max-width:$sm){
		padding: 0;
	}
}
.resources-box {
	padding: 0 10px;
  transition-duration: .3s;
	transition-property: transform;
  &:hover{
    .resources_Img{
      img{
          transform: scale(0.9);
      }
    }
  }
	@media (max-width:$sm){
		padding: 15px 0;
	}
}
.csinner-box {
	padding: 0 20px 20px 0px;
  transition-duration: .3s;
  transition-property: transform;
  &:hover{
    .cs_Img {
      img {
          transform: scale(0.9);
      }
    }
  }
	@media (max-width:$sm){
		padding: 15px 0;
		height: unset;
	}
	.cs_Img {
		img {
			height: 206px;
		}
	}
}
.resources_Img {
	img {
		border-radius: 8px;
	}
}
.cs_Img {
	img {
		border-radius: 8px;
	}
}


.click_to_connect {
	background-color: $bg-light;
	padding: 46px;
	border-radius: 8px;
	@media (max-width:$sm){
		padding: 0;
		.sec-padd{
			padding: 20px;
		}
	}
	.click-title{
		font-size: 40px;
		line-height: 50px;
		font-weight: 300;
        letter-spacing: -1.9px;
		@media (max-width:$sm){
			font-size: 22px;
		    line-height: 32px;
		}
		span{
			color: green;
			font-weight: 600;
		}
	}
}
.cs_wrapper {
	padding: 20px 0;
}
.cs-category {
	color: $blue;
	font-size: 14px;
	line-height: 20px;
	font-weight: 600;
	text-transform: uppercase;
	padding: 10px 0;
}
.csinner_Title {
	font-size: 20px;
	line-height: 28px;
	font-weight: 600;
	height:100px;
  @media (max-width:$sm){
		height: unset;
    padding: 0 0 15px;
	}
}
.cs_Title {
	font-size: 28px;
	line-height: 38px;
	font-weight: 600;
	@media (max-width:$sm){
		font-size: 20px;
		line-height: 28px;
	}
}
.CS_blogContent {
	padding: 40px 0 0 40px;
	@media (max-width:$sm){
		padding: 0;
	}
}
.cs_author {
	font-size: 18px;
	line-height: 22px;
	color: $grey;
	font-weight: 500;
	text-transform: capitalize;
	padding: 8px 0 0;
	.userimg {
		width: 36px;
		height: 36px;
		border-radius: 100%;
		position: relative;
		top: 10px;
		left: -5px;
	}
}
.girl {
	position: absolute;
	bottom: 0;
	right: 0;
	@media (max-width:$sm){
		position: relative;
	}
}
.category_slider{
  padding: 40px 0 20px;
  @media (max-width:$sm){
    display: flex;
    overflow-x: auto;
    width: 100%;
    white-space: nowrap;
    scrollbar-width: thin;
    padding: 30px 0;
  }
}
.category_slider, .category_frslider {
	li {
      list-style: none;
      display: inline-block;
		a {
			color: $white;
			font-weight: 600;
			background-color: $bg-blue;
			padding: 8px 12px;
			border-radius: 30px;
			margin: 0 3px;
			font-size: 14px;
			transition-duration: .3s;
			&:hover{
				background-color: unset;
				color: $black;
			}
      &.active{
        background-color: $bg-white;
        border: 1px solid $blue;
        color: $blue;
      }
		}
	}
	li.slick-current.slick-active {
		a {
			background-color: $bg-white;
			border: 1px solid $blue;
			color: $blue;
		}
	}
	.slick-track {
		padding: 40px 0;
	}
}
.see_all {
	color: $white;
	background-color: $bg-blue;
}
#blog-details{
  .latest_blogContent{
    padding: 0;
  }
	@media (max-width:$sm){
		margin: 30px 0 0;
	}
	.singlepost{
    background: #FAECDE;
		@media (max-width:$sm){
			padding: 20px 10px;
		}
	}
	.backblogs{
		@media (max-width:$sm){
		  border: 1px solid #E9EDED;
		}
		.backtoblogs{
			color: $blue;
			font-weight: 600;
			padding: 30px 0;
			display: block;
			@media (max-width:$sm){
				padding: 15px 0;
			}
		}
	}
	
	.latest_blogWrapper{
		border-radius: 8px;
    background: #FAECDE;
    height: 422px;
		@media (max-width:$sm){
			height: unset;
			padding: 0;
      gap:0;
		}
		.latest_blogContent{
			.blog_Title{
				font-size: 40px;
				line-height: 50px;
				@media (max-width:$sm){
					font-size: 22px;
					line-height: 32px;
				}
			}
		}
		.publish_date{
			padding: 25px 0;
			@media (max-width:$sm){
				padding: 0;
        font-size: 14px;
			}
			span{
				img{
					vertical-align: text-bottom;
				}
				.userimg{
					width: 36px;
					height: 36px;
					border-radius: 100%;
					position: relative;
					top: 7px;
					left: -5px;
				}
			}
		}
		.latest_blogImg{
      width: 562px;
			margin: 0 auto;
			.blog_Bigimage{
				@media (max-width:$sm){
					padding: 20px 0 10px;
				}
        img{
          
          mix-blend-mode: darken;
					@media (max-width:$sm){
            
						border-radius: 8px;
					}
				}
				// .img-bg {
        //   background-repeat: no-repeat;
        //   background-position: center;
        //   background-size: 100% 100%;
        //   min-height: 370px;
				// 	@media (max-width:$sm){
				// 		min-height: 180px;
				// 		border-radius: 8px;
				// 	}
				// }
			}
		}
	}
	
}
.main_wrapper{
	border-top: 1px solid #D7DDDD;
	.detail_wrapper{
		display: flex;
		@media (max-width:$sm){
			display: block;
		}
	}
	.navbar-fixed {
		position: fixed;
		top: 100px;
		width: 315px;
		@media (max-width:$sm){
			width: 100%;
			position: unset;
		}
	}
  .csbar-fixed {
		position: fixed;
		top: 107px;
		width: 200px;
		@media (max-width:$sm){
			width: 100%;
			position: unset;
		}
	}
	.demodiv-fixed {
		position: fixed;
		top: 120px;
		width: 210px;
		@media (max-width:$sm){
			width: 100%;
			position: unset;
		}
	}

  .csdemodiv-fixed {
		position: fixed;
		top: 107px;
		width: 315px;
		@media (max-width:$sm){
			width: 100%;
			position: unset;
		}
	}
	
	.stickySidebar{
		background-color: #fff;
		padding: 20px 8px;
		border-radius: 8px;
		box-shadow: 2px 2px 4px #80808040;
		@media (max-width:$sm){
			margin: 0 0 20px;
		}
		.table_content {
			padding: 20px 4px 20px 20px;
			li{
				.sublist{
					padding: 5px 0 5px 14px;
					li {
						list-style-type: disc;
						a{
							color: #000;
							font-size: 14px;
							line-height: 20px;
							font-weight: 500;
							&.active{
								color: #60ACAC;
							}
						}
					}
				}
				a{
					color: #000;
					font-size: 16px;
					line-height: 28px;
					font-weight: 500;
					&.active{
						color: #60ACAC;
					}
				}
				
			}
		}
		
	}

  .cs_stickySidebar{
		background-color: #fff;
		padding: 20px 15px;
		border-radius: 8px;
		box-shadow: 2px 2px 4px #80808040;
    width: 200px;
		@media (max-width:$sm){
			margin: 0 0 20px;
      width: 100%;
		}
			li{
        list-style: none;
				a{
					color: #000;
					font-size: 16px;
					line-height: 28px;
					font-weight: 500;
					&.active{
						color: #60ACAC;
					}
				}
				
			}		
	}
	.blogdetails_wrapper{
		padding:0 35px;
    font-size: 18px;
		line-height: 26px;
    text-align: justify;
		@media (max-width:$sm){
			padding: 0;
		}
		a{
			color: $blue;
			text-align: justify;
		}
		h2 {
			font-size: 30px !important;
			line-height: 38px !important;
			padding: 24px 0;
			text-transform: capitalize;
			letter-spacing: -0.7px;		
      text-align: left;
			@media (max-width:$sm){
				font-size: 22px !important;
				line-height: 32px !important; 
				padding: 10px 0 15px;
			}
		}
		h3 {
			font-size: 26px !important;
			line-height: 34px !important;
			padding: 18px 0;
			text-transform: capitalize;
			letter-spacing: -0.56px;
      text-align: left;
			@media (max-width:$sm){
				font-size: 20px !important;
				line-height: 30px !important;
				padding: 10px 0 15px;
			}
		}
    h4 {
			font-size: 20px !important;
			line-height: 30px !important;
			padding: 18px 0;
			text-transform: capitalize;
      text-align: left;
			letter-spacing: -0.56px;
			@media (max-width:$sm){
				font-size: 18px !important;
				line-height: 28px !important;
				padding: 10px 0 15px;
			}
		}
		p {
			font-size: 18px;
			line-height: 26px;
			padding: 10px 0;
			text-align: justify;
			@media (max-width:$sm){
				padding: 0 0 10px;
			}
		}
		.quotes {
			background-color: #fff;
			box-shadow: 1px 3px 8px 2px #80808029;
			padding: 20px;
			margin: 10px 0 20px;
			text-align: justify;
		}
		blockquote {
			background-color: #fff;
			box-shadow: 1px 3px 8px 2px #80808029;
			padding: 20px;
			margin: 10px 0 20px;
			text-align: justify;
		}
		img{
			margin: 15px 0;
			border-radius: 8px;
			width: 100%;
      box-shadow: 1px 1px 1px 8px #e4e4e49c;
		}
    iframe {
      margin: 25px 0 15px;
      border-radius: 8px;
      display: block;
      width: 100%;
      box-shadow: 1px 1px 1px 8px hsla(0, 0%, 89%, .6117647059);
      height: 400px;
      @media (max-width:$sm){
        height:280px;
      }
    }
		ul{
			padding-left: 20px;
			li{
				font-size: 18px;
			  line-height: 26px;
        text-align: justify;
				padding: 8px 0;
        img{
          margin: 30px 0 20px;
        }
				ul{
					padding: 5px 0 0 15px;
					li{
						font-size: 18px;
			      line-height: 26px;
						list-style-type: disc;
						font-weight: 500;
            text-align: justify;
					}
				}
			}
		}
		ol{
			padding-left: 20px;
			li{
				font-size: 18px;
			  line-height: 26px;
        text-align: justify;
				padding: 8px 0;
        img{
          margin: 30px 0 20px;
        }
				ul{
					padding: 5px 0 0 15px;
					li{
						font-size: 18px;
			      line-height: 26px;
						list-style-type: disc;
						font-weight: 500;
            text-align: justify;
					}
				}
			}
		}
		.author_wrapper{
				background-color: #F7F8F8;
				padding: 20px;
				border-radius: 8px;
				margin: 20px 0;
				@media (max-width:$sm){
					margin: 20px 0 40px;
				}
			.author-bio img {
				width: 150px;
				height: 100%;
				border-radius: 100%;
				@media (max-width:$sm){
					width: 90px;
				}
			}
			.author_content{
				padding: 20px;
				@media (max-width:$sm){
					padding: 0;
				}
				.user_name{
					font-size: 20px;
					line-height: 30px;
					font-weight: 600;
					padding: 0 0 10px;
				}
			}
		}
	}
	.rightSidebar{
		padding: 20px 0 0;
		.social_links {
			padding: 20px 0 0;
			a{
				img{
					width: 25px;
				}
			}
		}
		.demo_div{
			background-color: #F4F3FF;
			border-radius: 12px;
			padding: 20px 15px;
			margin: 30px 0 0;
			.demo_title{
				font-size: 19px;
				line-height: 28px;
				font-weight: 600;
				@media (max-width:$sm){
					font-size: 22px;
					line-height: 32px;
				}
				span{
					color: blue;
				}
			}
			p{
				font-size: 14px;
				line-height: 20px;
				padding: 10px 0;
			}
			.theme-btn, .white-btn{
				display: block;
        padding: 14px 18px;
				margin: 0 0 10px;
        font-size: 14px !important;
			}
		}
	}
  .cs_rightSidebar{
		padding: 20px 0 0;
		.social_links {
			padding: 20px 0 0;
			a{
				img{
					width: 25px;
				}
			}
		}
		.demo_div{
			background-color: #F4F3FF;
			border-radius: 12px;
			padding: 20px 15px;
			margin: 30px 0 0;
			.demo_title{
				font-size: 19px;
				line-height: 28px;
				font-weight: 600;
				@media (max-width:$sm){
					font-size: 22px;
					line-height: 32px;
				}
				span{
					color: blue;
				}
			}
			p{
				font-size: 14px;
				line-height: 20px;
				padding: 10px 0;
			}
			.theme-btn, .white-btn{
				display: block;
				margin: 0 0 10px;
			}
		}
	}
}

#recentBlogs{
	.blogBox{
		.blog-box-wrapper{
			.blog-box{
				padding: 0 15px;
				@media (max-width:$sm){
					padding: 15px 0;
				}
				.blog_Img{
					img{
						height: 213px;
					}
				}
			}
		}
	}
}
#subscribe{
	.subscribe_wrapper{
		padding: 48px 50px;
    height: 390px;
    border-radius: 8px;
    .sec-padd{
      max-width: 500px;
    }
		@media (max-width:$sm){
			padding: 0;
      height: unset;
      .sec-title {
        font-size: 24px;
        line-height: 34px;
       }
			.sec-padd{
				padding: 20px;
			}
		}
		.subscribe{
			position: absolute;
			right: 0;
			bottom: 0;
      height: 390px;
			@media (max-width:$sm){
				position: relative;
        height: unset;
			}
		}
	}
}

#lastsection{
    background-color: $bg-blue;
    height: 600px;
    @media (max-width: $sm){
        .sheet{
            width: 98px;
        }
    }
    .tagline_wraper{
        position: absolute;
        top:50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        @media (max-width: $sm){
            position: relative;
            top:unset;
            transform: unset;
            left: unset;
        }
		.sec-title{
			font-size: 60px !important;
			line-height: 70px !important;
			letter-spacing: -1.2px;
			padding-bottom: 20px;
			@media (max-width: $sm){
				font-size: 24px !important;
			    line-height: 34px !important;
			}
		}
        .free-trial{
            font-size: 20px;
            margin: 20px 0 50px;
            @media (max-width: $sm){
                font-size: 14px;
                line-height: 20px;
                margin: 0px 0 35px;
            }
        }
        .theme-btn{
            background-color: $white;
            color: $blue;
        }
    }
}

.pagination-links {
    text-align: center;
    margin: 20px 0;
	a{
		margin: 0 5px;
		padding: 10px 15px;
		border: 1px solid #ddd;
		text-decoration: none;
		background-color: #f4f4f4;
		color: $blue;
		transition: background-color 0.3s ease, color 0.3s ease;
		&:hover{
			background-color: $blue;
			color: $white;
			border-color: $blue;
		}
	}
	.current {
		font-weight: bold;
        pointer-events: none; 
		background-color: $blue;
		color: $white;
		border-color: $blue;
	}
	.page-number {
		cursor: pointer;
	}
	.prev_pagination, .next_pagination, .last-page, .first-page {
		background-color: #ebebeba8;
		padding: 11px;
		border-radius: 4px;
	}
}
.cs_flex{
  display: flex;
  gap: 40px;
  @media (max-width: $sm){
    display: block;
  }
}
.single_cs{
  .cs_left{
    padding-left: 40px;
    @media (max-width: $sm){
      padding-left: 0;
    }
    .case_Content{
       font-size: 18px;
        line-height: 26px;
        padding: 10px 0;
        color: #1e1e1e;
        font-family: $basier;
        a{
          color: #33c1c0;
          text-decoration: underline;
        }
      h2{
        font-size: 30px;
        line-height: 38px;
        padding: 24px 0;
        color: #0e1b1b;
        text-transform: capitalize;
        letter-spacing: -.7px;
        font-family: $basierSemibold;
        @media (max-width: $sm){
          font-size: 22px;
          line-height: 32px;
          padding: 18px 0;
        }
      }
      h3{
        font-size: 26px;
        line-height: 34px;
        margin: 14px 0;
        @media (max-width: $sm){
          font-size: 20px;
          line-height:30px;
        }
      }
      p{
        font-size: 18px;
        line-height: 26px;
        padding: 10px 0;
        color: #1e1e1e;
        font-family: $basier;
        a{
          color: #33c1c0;
          text-decoration: underline;
        }
      }
      iframe {
        margin: 25px 0 15px;
        border-radius: 8px;
        display: block;
        width: 100%;
        box-shadow: 1px 1px 1px 8px hsla(0, 0%, 89%, .6117647059);
        height: 400px;
        @media (max-width:$sm){
          height:280px;
        }
      }
      ul{
        padding-left: 20px;
        li{
          font-size: 18px;
          line-height: 26px;
          text-align: justify;
          padding: 8px 0;
          img{
            margin: 30px 0 20px;
          }
          ul{
            padding: 5px 0 0 15px;
            li{
              font-size: 18px;
              line-height: 26px;
              list-style-type: disc;
              font-weight: 500;
              text-align: justify;
            }
          }
        }
      }
      ol{
        padding-left: 20px;
        li{
          font-size: 18px;
          line-height: 26px;
          text-align: justify;
          padding: 8px 0;
          img{
            margin: 30px 0 20px;
          }
          ul{
            padding: 5px 0 0 15px;
            li{
              font-size: 18px;
              line-height: 26px;
              list-style-type: disc;
              font-weight: 500;
              text-align: justify;
            }
          }
        }
      }
      // ul{
      //   padding: 0 0 0 18px;
      //   li{
      //     margin: 8px 0;
      //     font-size: 16px;
      //     color: #1e1e1e;
      //     line-height: 28px;
      //     font-family: $basier;
      //     letter-spacing: .015em;
      // }
      // }
      img{
        margin: 25px 0 15px;
        border-radius: 8px;
        width: 100%;
        box-shadow: 1px 1px 1px 8px hsla(0, 0%, 89%, .61176)
      }
      blockquote {
        background: #f9f9f9;
        border-left: 10px solid #ccc;
        margin: 1.5em 0;
        padding: 15px;
        quotes: "“" "”" "‘" "’";
      }
    }
    
    
    .arrow-left{
      margin: 0;
      border-radius: 0;
      vertical-align: bottom;
    }
  }
  .caseTitle{
    margin: 8px 0;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    letter-spacing: -.46px;
    margin-bottom: 26px;
    color: #0e1b1b;
    font-family: $basierSemibold;
    @media (max-width: $sm){
      font-size: 24px;
      line-height: 34px;
    }
  }
  .cs_category, .cs_author{
      font-size: 12px;
      color: #038c8b;
      line-height: 18px;
      margin-bottom: 20px;
  }
  
  
  iframe{
    height: 400px;
    border-radius: 12px;
    margin: 15px 0;
    @media (max-width: $sm){
      height: 190px;
    }
  }

}
.terms{
  p{
    text-align: justify;
  }
  ol{
    padding: 10px;
    li{
      color: $color2;
      font-size: 16px;
      line-height: 24px;
      padding: 8px 0;
      text-align: justify;
      ol{
        padding: 8px 16px;
      }
    }
    p{
      padding: 10px 0;
      text-align: justify;
    }
  }
}
.contact_wrapper{
  padding: 20px 0 0;
  .info_title{
    font-size: 24px;
    line-height: 31px;
    color: $light;
    margin: 20px 0;
}

.info_div{
  margin: 20px 0;
  padding: 20px 0;
  &::before{
    position: absolute;
    content: '';
    background-color: #dee2e6;
    width: 60%;
    left: 0;
    top:0;
    height: 1px;
    @media (max-width: $sm){
      width: 100%;
    }
  }
  &::after{
    position: absolute;
    content: '';
    background-color: #dee2e6;
    width: 60%;
    left: 0;
    bottom:0;
    height: 1px;
    @media (max-width: $sm){
      width: 100%;
    }
  }
  h3{
    font-size: 18px;
    line-height: 23px;
    color: $light;
  }
  p{
    font-size: 14px;
    line-height: 20px;
    color: $light;
    padding: 10px 0 0;
    a{
      font-size: 14px;
      line-height: 20px;
      color: $light;
    }
  }
}
.contactus{
  .form-control{
    background-color: #f5f8fa;
    border: 1px solid #cbd6e2;
    border-radius: 3px;
  }
  .fname{
    padding-right: 20px;
    @media (max-width: $sm){
      padding-right: 0;
    }
  }
}
}
.display_table{
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  @media (max-width: $sm){
    display: block;
  }
}
.products{
  .btn_wrapper{
    a{
      @media (max-width: $sm){
        display: block;
      }
    }
  }
  .banner{
    height: 100vh;
    padding: 0 175px;
    @media (max-width: $sm){
      height: unset;
      padding: 90px 0 0;
    }
  }
  .common_subtitle{
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 17px;
    font-family: $basier;
  }
  .common_title{
    font-size: 36px;
    line-height: 43px;
    @media (max-width: $sm){
      font-size: 24px;
      line-height: 34px;
    }
  }
  .common_desc{
    font-size: 16px;
    line-height: 24px;
    margin: 25px 0 60px;
    font-weight: 400;
    font-family: $basier;
    @media (max-width: $sm){
      margin: 25px 0;
    }
  }
  .feed_div {
    height: 350px;
    @media (max-width: $sm){
      height: unset;
      padding: 20px 0;
    }
    img{
      height: 180px;
    }
    .feedback_title{
      font-size: 18px;
      line-height: 28px;
      font-weight: 600;
      padding: 8px 0 0;
    }
  }
 .feed_div1{
     height: 420px;
     @media (max-width: $sm){
      height: unset;
      padding: 20px 0;
     }
 }
  .feedback-card-root {
    .feedback-card {
      width: 100%;
      max-width: 410px;
      padding: 15px 24px;
      position: relative;
      border-radius: 12px;
      margin: 10px auto 20px;
      background: #dce8ec;
      .feedback-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .card-logo-div {
          width: 104px;
          height: 104px;
          border-radius: 50%;
          .card-logo-image {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: contain;
            background: #fff;
         }
       }
       
       h4 {
        font-size: 14px;
        color: $light;
        line-height: 18px;
        max-width: 208px;
        padding-left: 10px;
        font-family: $basier;
      }
    }
    .arrow-div {
      .feedback-test-text {
        margin: 0;
        top: 230px;
        right: -210px;
        position: absolute;
        font-size: 14px;
        line-height: 18px;
        color: $light;
        text-align: center;
        max-width: 186px;
        font-family: $basier;
        }
        .feedback-test-img {
          top: 260px;
          right: -140px;
          z-index: 1;
          position: absolute;
          @media (max-width: $sm){
            right: -65px;
          }
      }
    }
    .feedback-details {
      display: -moz-box;
      display: flex;
      -moz-box-align: center;
      align-items: center;
      -moz-box-pack: justify;
      justify-content: space-between;
      margin: 17px 0 18px;
      h5 {
        margin: 0;
        color: $light;
        font-size: 10px;
        line-height: 13px;
        span{
          font-family: $basier;
        }
      }
    }
     .bonus-card {
      padding: 5px 8px;
      margin-bottom: 16px;
      display: -moz-box;
      display: flex;
      -moz-box-align: center;
      align-items: center;
      -moz-box-pack: center;
      justify-content: center;
      border-radius: 12px;
      background: #fff;
      h3 {
        font-size: 12px;
        color: $light;
        margin: 0 0 0 5px;
        line-height: 16px;
    }
    }
    .rating-card {
      min-width: 430px;
      padding: 18px 24px;
      border-radius: 12px;
      background: #fff;
      -webkit-transform: translateX(-35px);
      -moz-transform: translateX(-35px);
      transform: translateX(-35px);
      -webkit-box-shadow: 2px 3px 10px #c5d1d5;
      box-shadow: 2px 3px 10px #c5d1d5;
      @media (max-width: $sm){
        min-width: 100%;
      }
      h4 {
        font-size: 14px;
        color: $light;
        line-height: 18px;
        margin-bottom: 25px;
        font-family: $basier;
      }
      .rating-row {
        display: -moz-box;
        display: flex;
        -moz-box-align: center;
        align-items: center;
        -moz-box-pack: justify;
        justify-content: space-between;
        .emojis {
          cursor: pointer;
          -webkit-transition: all .3s cubic-bezier(.175,.885,.18,1.3) 0s;
          -moz-transition: all .3s cubic-bezier(.175, .885, .18, 1.3) 0s;
          transition: all .3s cubic-bezier(.175,.885,.18,1.3) 0s;
          &:hover{
            transform: scale(1.4);
          }
       }
      }
     }
   }
  }
}
.campaign_padd {
    gap: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  .campaign_content {
    padding: 90px 0 0 90px;
    @media (max-width: $sm){
      padding: 20px 0;
    }
  }
  .tr{
    text-align: right;
    @media (max-width: $sm){
      text-align: center;
    }
  }
  .campaign_div{
    width: 250px;
    padding: 35px 24px;
    border-radius: 10px;
    @media (max-width: $sm){
      width: 100%;
    }
    .campaign_title{
      font-size: 18px;
      line-height: 28px;
      margin: 20px 0 0;
      font-family: $basier;
      font-weight: 500;
    }
  }
  .campaign1{
    background-color: #E6F5F0;
  }
  .campaign2{
    background-color: #FFF9D9;
  }
  .campaign3{
    background-color: #EAF0FF;
  }
  .campaign4{
    background-color: #EBF9FF;
  }
  @media (max-width: $sm){
    padding: 0;
    text-align: center;
  }
}

.more-reelo{
  background: linear-gradient(180deg, #f8fdfd, rgba(248, 249, 255, 0));
  .morereelo_div{
    padding: 40px 20px 0;
    text-align: center;
    @media (max-width: $sm){
      padding: 30px 0 0;
    }
    h3{
      font-size: 32px;
      color: #092d2c;
      line-height: 42px;
      margin: 24px 0 8px;
      letter-spacing: -.02em;
      font-family: $basierSemibold;
      @media (max-width: $sm){
        font-size: 24px;
        line-height: 34px;
      }
    }
    p{
      font-size: 17px;
      color: #404444;
      margin: 0 0 16px;
      line-height: 26px;
      font-family: $basier;
      height: 105px;
      @media (max-width: $sm){
        height: 110px;
      }
    }
    .read_now{
      font-size: 20px;
      color: #0d9298;
      line-height: 28px;
      font-family: $basierMedium;
      font-weight: 400;
    }
  }
}
.slick-dots {
  display: flex;
    margin: 0;
    list-style-type: none;
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    button {
      display: block;
      width: 6px;
      height: 6px;
      padding: 0;
      background-color: grey;
      text-indent: -9999px;
      cursor: pointer;
      border-radius: 30px;
      border: unset;
      margin: 0 3px;
    }
  }
  li.slick-active {
    button {
      background-color: $black;
    }
  }
.loyalty{
  .loyalty_first_fold{
    gap: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 45px 0 0;
    @media (max-width: $sm){
      flex-wrap: wrap;
    }
    .loyalty_left_side{
      max-width: 620px;
    }
    .loyalty_right_side{
      width: 100%;
      max-width: 571px;
    }
  }
  .display_table{
    flex-direction: unset;
  }
  .banner{
    height: 100vh;
    @media (max-width: $sm){
      height: unset;
      padding: 90px 0 0;
      text-align: center;
      .common_title{
        font-size: 28px;
        line-height: 32px;
      }
      .common_desc{
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
  .sec_title{
    font-size: 42px;
    margin: 0 0 16px;
    line-height: 51px;
    letter-spacing: -.02em;
    font-weight: 500;
    font-family: $basierMedium;
    @media (max-width: $sm){
      font-size: 28px;
      line-height: 32px;
    }
   }
   .sec_desc{
    font-size: 20px;
    margin: 0 0 24px;
    line-height: 30px;
    font-family: $basier;
   }
  .common_title{
    font-size: 56px;
    margin: 0 0 16px;
    line-height: 62px;
    letter-spacing: -.01em;
    font-family: $basierMedium;
  }
  .common_desc{
    font-size: 20px;
    margin: 0 0 40px;
    line-height: 30px;
    font-family: $basier;
  }
  .loyaly_div{
    overflow: hidden;
    border-radius: 24px;
    background: #fef6ee;
    padding: 40px 0 0;
    @media (max-width: $sm){
      padding:20px 0 0;
    }
    .loyalty_img_parent{
      width: 100%;
      height: 100%;
      position: relative;
      .loyaly_slider {
        bottom: 0;
        top: auto;
        width: 100%;
        height: 523px;
        @media (max-width: $sm){
          height: 332px;
        }
        .slider_loyaltyimg{
          top: auto;
          bottom: 0;
          margin: 0 auto !important;
          display: block !important;
          width: calc(100% - 96px) !important;
          @media (max-width: $sm){
            width: calc(100% - 34px) !important;
          }
        }
      }
    }
    @media (max-width: $sm){
      width: 100%;
      margin: 0;
    }
    
  }
  .loyalty_tabs_wrapper{
    border-bottom: 1px solid #eeee;
    padding: 60px 80px 0;
    .loyalty_tab{
      display: inline-block;
      width: 240px;
      height: 140px;
      cursor: pointer;
      &:before{
        position: absolute;
        content: "";
        width: 95%;
        height: 4px;
        bottom: 0;
        background-color: #eee;
        transform: translateX(-50%);
        left: 50%;
      }
      span{
        width: 40px;
        height: 40px;
        border-radius: 100%;
        background-color: #f3f4f6;
        font-size: 24px;
        font-family: $basierSemibold;
        padding: 10px 19px;
      }
      p{
        font-size: 18px;
        color: #959595;
        line-height: 26px;
        text-align: center;
        font-family: $basierMedium;
        padding: 30px 0 0;
      }
    }
    .loyalty_tab.active {
      &:before{
        background-color: #6172f3;
      }
      p {
        color: #182020;
      }
    }
  }
  
.loyalty_slider2{
  .slick-dots {
    display: flex;
      margin: 0;
      list-style-type: none;
      position: absolute;
      bottom: -20px;
      left: 50%;
      transform: translateX(-50%);
      button {
        display: block;
        width: 6px;
        height: 6px;
        padding: 0;
        background-color: grey;
        text-indent: -9999px;
        cursor: pointer;
        border-radius: 30px;
        border: unset;
        margin: 0 3px;
      }
    }
    li.slick-active {
      button {
        background-color: $black;
      }
    }
    .loyalty_Slide{
      padding: 64px 0 64px 64px;
      background: #f5f8ff;
      margin: 40px auto 0;
      border-radius: 24px;
      @media (max-width: $sm){
        padding: 20px;
        height: unset;
        margin: 0 0 50px;
      }
      .loyalty_img{
        img{
          @media (max-width: $sm){
            width: 200px ;
            margin: 0 auto;
          }
        }
      }
      .loyalty_content{
        padding: 0 0 30px;
        span{
          width: 64px;
          height: 64px;
          font-size: 34px;
          color: $white;
          line-height: 42px;
          border-radius: 50%;
          display: -moz-box;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #6172f3;
          font-family: $basierSemibold;
          @media (max-width: $sm){
            width: 34px;
            height: 34px;
            font-size: 20px;
            line-height: 28px;
          }
        }
        h3{
          font-size: 36px;
          color: #171e59;
          line-height: 50px;
          margin: 32px 0 12px;
          letter-spacing: -.02em;
          font-family: $basierSemibold;
          @media (max-width: $sm){
            font-size: 28px;
            line-height: 32px;
          }
        }
        p{
          font-size: 28px;
          color: #171e59;
          line-height: 40px;
          letter-spacing: -.02em;
          font-family: $basierMedium;
          @media (max-width: $sm){
            font-size: 16px;
            line-height: 26px;
          }
        }
      }
    }
  }
  .benifits_section{
    .bene_div{
      padding: 32px 30px;
      border-radius: 16px;
      width: 31%;
      margin: 40px 14px 0;
      @media (max-width: $sm){
        width: 100%;
        margin: 30px 0 0;
      }
      .bene_inner{
        padding: 36px 22px;
        background: #fff;
        border-radius: 16.7273px;
        p{
          font-size: 20px;
          color: #171e59;
          line-height: 28px;
          text-align: center;
          letter-spacing: -.02em;
          font-family: $basierMedium;
        }
        span{
          font-size: 48px;
          line-height: 62px;
          text-align: center;
          letter-spacing: -.02em;
          font-family:$basierSemibold;
          display: block;
        }
        .green{
          color: #16b364;
        }
        .red{
          color: #ef6820;
        }
        .blue{
          color: #1570ef;
        }
      }
    }
    .b1{
      background: #edfcf2;
    }
    .b2{
      background: #fef6ee;
    }
    .b3{
      background: #f5f8ff;
    }
  }
  .inspiration{
    border-radius: 16px;
    padding: 48px 40px 0;
    background: #fef6ee;
    @media (max-width: $sm){
      padding: 40px 20px 0;
    }
  }
  .quotes_section{
    p{
      font-size: 24px;
      color: #092d2c;
      line-height: 32px;
      text-align: center;
      font-family: $basier;
      @media (max-width: $sm){
        font-size: 18px;
        line-height: 26px;
      }
    }
    .quote_flex{
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
      img{
        border-right: 1px solid $black;
        padding-right: 10px;
      }
      p{
        font-size: 18px;
        color: #092d2c;
        line-height: 28px;
        text-align: left;
      }
    }
  }
  
  .lastsection{
    border-radius: 24px;
    padding: 40px 20px;
    @media (max-width: $sm){
      margin: 0 20px;
    }
  }
}
.marrows {
  margin: 30px 0 35px;
  svg {
    width: 48px;
    height: 48px;
    background-color: #f3f5f5;
    border-radius: 100%;
    padding: 10px;
    margin: 0 10px;
    &:hover{
      background-color: #0d9298;
      path{
        stroke: $white;
      }
    }
  }  
  #snext, #lnext{
    position: relative;
    transform: rotate(180deg);
  }
}
.campaigns{
  .display_table{
    flex-direction: unset;
  }
  .banner{
    height: 100vh;
    @media (max-width: $sm){
      height: unset;
      padding: 90px 0 0;
      text-align: center;
      .common_title{
        font-size: 28px;
        line-height: 32px;
      }
      .common_desc{
        font-size: 14px;
        line-height: 22px;
      }
    }
    .common_subtitle{
      font-size: 16px;
      line-height: 21px;
      letter-spacing: .18em;
      text-transform: uppercase;
      font-family: $basierSemibold;
      padding: 0 0 20px;
    }
  }
  .sec_title{
    font-size: 42px;
    margin: 0 0 16px;
    line-height: 51px;
    letter-spacing: -.02em;
    font-weight: 500;
    font-family: $basierMedium;
    @media (max-width: $sm){
      font-size: 28px;
      line-height: 32px;
    }
   }
   .sec_desc{
    font-size: 20px;
    margin: 0 0 24px;
    line-height: 30px;
    font-family: $basier;
   }
  .common_title{
    font-size: 56px;
    margin: 0 0 16px;
    line-height: 62px;
    font-family: $basierMedium;
  }
  .common_desc{
    font-size: 20px;
    margin: 0 0 40px;
    line-height: 30px;
    font-family: $basier;
  }
  .loyaly_div{
    width: 557px;
    margin: 70px auto 0;
    @media (max-width: $sm){
      width: 100%;
      margin: 0 0 40px;
    }
    .loyaly_slider {
      border-radius: 24px;
  }
  }

 .pre-built{
  .common_title{
    @media (max-width: $sm){
      font-size: 28px;
      line-height: 32px;
    }
  }
  .common_desc {
    @media (max-width: $sm){
      font-size: 18px;
    }
  }
   .campaigns_feature_card_root{
    gap: 24px;
    margin: 0 auto;
    padding: 0 16px;
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: $sm){
      display: block;
      border-radius: 16px;
      padding: 0;
    }
    .flx{
      align-items: center !important;
    }
    .campaigns_feature_card{
      height: 395px;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      padding: 0 24px 0 0;
      border-radius: 16px;
      background: #f3f1ee;
      width: calc(50% - 12px);
      @media (max-width: $sm){
        display: block;
        padding: 0;
        height: unset;
        width: 100%;
        margin: 0 0 30px;
      }
      .campaigns_left_side{
        padding: 174px 8px 48px 28px;
        @media (max-width: $sm){
          padding: 34px 8px 34px 28px;
        }
        .campaigns_feature_number{
          padding: 4px 9px;
          width: 28px;
          height: 28px;
          font-size: 16px;
          color: $white;
          line-height: 22px;
          border-radius: 8px;
          background: #065151;
          font-family: $basierSemibold;
        }
        h3{
          margin: 8px 0;
          font-size: 25px;
          color: $color1;
          line-height: 26px;
          letter-spacing: -.02em;
          font-family: $basierSemibold;
          @media (max-width: $sm){
            font-size: 20px;
            line-height: 24px;
          }
        }
        p{
          font-size: 14px;
          color: #232d2d;
          line-height: 20px;
        }
      }
      .campaigns_right_side{
        width: 100%;
        max-width: 312px;
        position: relative;
        height: -moz-calc(100% - 48px);
        @media (max-width: $sm){
          max-width:250px;
         margin: 0 auto;
        }
      }
    }
    .last_card{
      width: 100%;
      height: auto;
    }
   }
 }

 .how_to_send_cards{
  .common_subtitle{
    font-size: 16px;
    line-height: 21px;
    letter-spacing: .18em;
    text-transform: uppercase;
    font-family: $basierSemibold;
    padding: 0 0 20px;
  }
 
  .campaigns_how_to_send_cards_parent{  
    .campaigns_how_to_send_card{
      gap: 24px;
      height: 402px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 16px;
      background: #f5f8ff;
      @media (max-width: $sm){
        height: unset;
        display: block;
      }
      .campaigns_left_side{
        flex-grow: 1;
        padding: 48px 64px;
        @media (max-width: $sm){
          padding: 20px;
        }
        .campaigns_card_number{
          width: 64px;
          height: 64px;
          font-size: 34px;
          color: $white;
          line-height: 42px;
          border-radius: 50%;
          display: -moz-box;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #6172f3;
          font-family: $basierSemibold;
          @media (max-width: $sm){
            width: 34px;
            height: 34px;
            font-size: 20px;
            line-height: 28px;
          }
        }
        h3{
          font-size: 36px;
          color: #171e59;
          line-height: 50px;
          margin: 32px 0 14px;
          letter-spacing: -.02em;
          font-family: $basierSemibold;
          @media (max-width: $sm){
            font-size: 28px;
            line-height: 32px;
          }
        }
        p{
          font-size: 18px;
          color: #101e6f;
          line-height: 28px;   
          @media (max-width: $sm){
            font-size: 16px;
            line-height: 26px;
          }   
        }
      }
      .campaigns_right_side{
        img{
          padding: 20px 64px 20px 0;
          @media (max-width: $sm){
            padding: 20px 20px 0;
          }
        }
      }
    }
  }
 }
 .benifits_section{
  .bene_div{
    padding: 32px 30px;
    border-radius: 16px;
    width: 31%;
    margin: 40px 14px 0;
    height: 450px;
    @media (max-width: $sm){
      width: 100%;
      margin: 30px 0 0;
    }
    p{
      font-size: 18px;
      color: #0e1b1b;
      line-height: 28px;
      letter-spacing: -.02em;
      font-family: $basier;
    }
    .bene_inner{
      padding: 36px 22px;
      background: #fff;
      border-radius: 16.7273px;
      height: 190px;
      margin: 0 0 30px;
      p{
        font-size: 20px;
        color: #171e59;
        line-height: 28px;
        text-align: center;
        letter-spacing: -.02em;
        font-family: $basierMedium;
      }
      span{
        font-size: 48px;
        line-height: 62px;
        text-align: center;
        letter-spacing: -.02em;
        font-family:$basierSemibold;
        display: block;
      }
      .green{
        color: #16b364;
      }
      .red{
        color: #ef6820;
      }
      .blue{
        color: #1570ef;
      }
    }
  }
  .b1{
    background: #edfcf2;
  }
  .b2{
    background: #fef6ee;
  }
  .b3{
    background: #f5f8ff;
  }
}
  .quotes_section{
    .navneet{
      font-size: 24px;
      color: #092d2c;
      line-height: 32px;
      font-family: $basierMedium;
      padding: 0 0 20px;
      @media (max-width: $sm){
        font-size: 18px;
        line-height: 26px;
        padding: 20px 0 20px;
      }
    }
    p{
      font-size: 16px;
      color: #374040;
      line-height: 24px;
      letter-spacing: -.03em;
      font-family: $basierMedium;
    }
    .quotes-box{
      .quotes_Img{
        .video-thumbnail{
          img{
            border-radius: 8px;
          }
          .play-icon-wrapper {
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            left: 50%;
            cursor: pointer;
          }
        }
      }
      iframe{
        height: 295px;
        border: none;
        border-radius: 8px;
        @media (max-width: $sm){
          height: 265px;
        }
      }
    }
    .quote_right{
      padding: 0 0 0 40px;
      @media (max-width: $sm){
        padding: 0;
      }
    }
  }
  .feedback-card-root {
    .feedback-card {
      width: 100%;
      max-width: 410px;
      padding: 15px 24px;
      position: relative;
      border-radius: 12px;
      margin: 10px auto 20px;
      background: #dce8ec;
      .feedback-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .card-logo-div {
          width: 104px;
          height: 104px;
          border-radius: 50%;
          .card-logo-image {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: contain;
            background: #fff;
         }
       }
       
       h4 {
        font-size: 14px;
        color: $light;
        line-height: 18px;
        max-width: 208px;
        padding-left: 10px;
        font-family: $basier;
      }
    }
    .arrow-div {
      .feedback-test-text {
        margin: 0;
        top: 230px;
        right: -210px;
        position: absolute;
        font-size: 14px;
        line-height: 18px;
        color: $light;
        text-align: center;
        max-width: 186px;
        font-family: $basier;
        }
        .feedback-test-img {
          top: 260px;
          right: -140px;
          z-index: 1;
          position: absolute;
          @media (max-width: $sm){
            right: -65px;
          }
      }
    }
    .feedback-details {
      display: -moz-box;
      display: flex;
      -moz-box-align: center;
      align-items: center;
      -moz-box-pack: justify;
      justify-content: space-between;
      margin: 17px 0 18px;
      h5 {
        margin: 0;
        color: $light;
        font-size: 10px;
        line-height: 13px;
        span{
          font-family: $basier;
        }
      }
    }
     .bonus-card {
      padding: 5px 8px;
      margin-bottom: 16px;
      display: -moz-box;
      display: flex;
      -moz-box-align: center;
      align-items: center;
      -moz-box-pack: center;
      justify-content: center;
      border-radius: 12px;
      background: #fff;
      h3 {
        font-size: 12px;
        color: $light;
        margin: 0 0 0 5px;
        line-height: 16px;
    }
    }
    .rating-card {
      min-width: 430px;
      padding: 18px 24px;
      border-radius: 12px;
      background: #fff;
      -webkit-transform: translateX(-35px);
      -moz-transform: translateX(-35px);
      transform: translateX(-35px);
      -webkit-box-shadow: 2px 3px 10px #c5d1d5;
      box-shadow: 2px 3px 10px #c5d1d5;
      @media (max-width: $sm){
        min-width: 100%;
      }
      h4 {
        font-size: 14px;
        color: $light;
        line-height: 18px;
        margin-bottom: 25px;
        font-family: $basier;
      }
      .rating-row {
        display: -moz-box;
        display: flex;
        -moz-box-align: center;
        align-items: center;
        -moz-box-pack: justify;
        justify-content: space-between;
        .emojis {
          cursor: pointer;
          -webkit-transition: all .3s cubic-bezier(.175,.885,.18,1.3) 0s;
          -moz-transition: all .3s cubic-bezier(.175, .885, .18, 1.3) 0s;
          transition: all .3s cubic-bezier(.175,.885,.18,1.3) 0s;
          &:hover{
            transform: scale(1.4);
          }
       }
      }
     }
   }
  }

  
  .lastsection{
    border-radius: 24px;
    padding: 40px 20px;
    @media (max-width: $sm){
      margin: 0 20px;
    }
  }
  #lastsection{
    @media (max-width: $sm){
    height:350px;
    }
  }
}
.online_reviews{
  .loyalty_tabs_wrapper{
    padding: 0 80px;
    .loyalty_tab{
      display: inline-block;
      padding: 50px 10px;
      cursor: pointer;
      p{
        font-size: 18px;
        cursor: pointer;
        color: #374040;
        line-height: 28px;
        padding: 16px 24px;
        border-radius: 48px;
        border: 1px solid #bdc9c9;
        font-family: $basierMedium;    
      }
    }
    .loyalty_tab.active {
      p {
        color: #fff;
        background: #0d9298;
        border: 1px solid #0d9298;
      }
    }
  }
  
.loyalty_slider2{
  .slick-dots {
    display: flex;
      margin: 0;
      list-style-type: none;
      position: absolute;
      bottom: -20px;
      left: 50%;
      transform: translateX(-50%);
      button {
        display: block;
        width: 6px;
        height: 6px;
        padding: 0;
        background-color: grey;
        text-indent: -9999px;
        cursor: pointer;
        border-radius: 30px;
        border: unset;
        margin: 0 3px;
      }
    }
    li.slick-active {
      button {
        background-color: $black;
      }
    }
    .loyalty_Slide{
      width: 100%;
      height: 450px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 64px;
      border-radius: 16px;
      background: #f5f8ff;
      @media (max-width: $sm){
        padding: 20px;
        height: 600px;
        margin: 0 0 50px;
        display: block;
        img{
          padding: 20px 0 0;
        }
      }
        h3{
          font-size: 36px;
          color: #171e59;
          line-height: 50px;
          letter-spacing: -.02em;
          font-family: $basierSemibold;
          max-width: 534px;
          @media (max-width: $sm){
            font-size: 18px;
            line-height: 26px;
          }
        }
    }
  }
}
.qr_code{
  .display_table{
    flex-direction: unset;
  }
  .banner{
    height: 100vh;
    @media (max-width: $sm){
      height: unset;
      padding: 90px 0 0;
      text-align: center;
      .common_title{
        font-size: 28px;
        line-height: 32px;
      }
      .common_desc{
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
  .sec_title{
    font-size: 42px;
    margin: 0 0 16px;
    line-height: 51px;
    letter-spacing: -.02em;
    font-weight: 500;
    font-family: $basierMedium;
    @media (max-width: $sm){
      font-size: 28px;
      line-height: 32px;
    }
   }
   .sec_desc{
    font-size: 20px;
    margin: 0 0 24px;
    line-height: 30px;
    font-family: $basier;
   }
  .common_title{
    font-size: 56px;
    margin: 0 0 16px;
    line-height: 62px;
    letter-spacing: -.01em;
    font-family: $basierMedium;
  }
  .common_desc{
    font-size: 20px;
    margin: 0 0 40px;
    line-height: 30px;
    font-family: $basier;
  }
  .loyaly_div{
    width: 557px;
    margin: 70px auto 0;
    @media (max-width: $sm){
      width: 100%;
      margin: 0;
    }
    .loyaly_slider {
      border-radius: 24px;
      background: #fef6ee;
      padding: 30px 30px 0;
  }
  }
  .loyalty_tabs_wrapper{
    border-bottom: 1px solid #eeee;
    padding: 0 80px;
    .loyalty_tab{
      display: inline-block;
      padding: 50px 30px;
      cursor: pointer;
      &:before{
        position: absolute;
        content: "";
        width: 95%;
        height: 4px;
        bottom: 0;
        background-color: #eee;
        transform: translateX(-50%);
        left: 50%;
      }
      span{
        width: 40px;
        height: 40px;
        border-radius: 100%;
        background-color: #f3f4f6;
        font-size: 24px;
        font-family: $basierSemibold;
        padding: 10px 19px;
      }
      p{
        font-size: 18px;
        color: #959595;
        line-height: 26px;
        text-align: center;
        font-family: $basierMedium;
        padding: 40px 0 0;
      }
    }
    .loyalty_tab.active {
      &:before{
        background-color: #01999f;
      }
      p {
        color: #182020;
      }
    }
  }
  
.loyalty_slider2{
  margin: 50px 0 0;
  @media (max-width: $sm){
    margin: 0;
  }
  .slick-dots {
    display: flex;
      margin: 0;
      list-style-type: none;
      position: absolute;
      bottom: -20px;
      left: 50%;
      transform: translateX(-50%);
      button {
        display: block;
        width: 6px;
        height: 6px;
        padding: 0;
        background-color: grey;
        text-indent: -9999px;
        cursor: pointer;
        border-radius: 30px;
        border: unset;
        margin: 0 3px;
      }
    }
    li.slick-active {
      button {
        background-color: $black;
      }
    }
    .loyalty_Slide{
      width: 100%;
      height: 490px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 64px;
      border-radius: 16px;
      background: #f5f8ff;
      @media (max-width: $sm){
        padding: 30px 20px;
        height: unset;
        margin: 0 0 50px;
        display: block;
      }
      .loyalty_img{
        img{
          @media (max-width: $sm){
            width: 200px ;
            margin: 0 auto;
          }
        }
      }
      .loyalty_content{
        padding: 0 0 30px;
        max-width: 60%;
        @media (max-width: $sm){
          max-width: 100%;
        }
        span{
          width: 64px;
          height: 64px;
          font-size: 34px;
          color: $white;
          line-height: 42px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #01999f;
          font-family: $basierSemibold;
          @media (max-width: $sm){
            width: 34px;
            height: 34px;
            font-size: 20px;
            line-height: 28px;
          }
        }
        h3{
          font-size: 36px;
          color: #171e59;
          line-height: 50px;
          margin: 32px 0 12px;
          letter-spacing: -.02em;
          font-family: $basierSemibold;
          @media (max-width: $sm){
            font-size: 28px;
            line-height: 32px;
          }
        }
        p{
          font-size: 28px;
          color: #171e59;
          line-height: 40px;
          letter-spacing: -.02em;
          font-family: $basierMedium;
          @media (max-width: $sm){
            font-size: 16px;
            line-height: 26px;
          }
        }
      }
    }
  }
  .qrcode_reviews{
    padding: 70px 0 0;
    @media (max-width: $sm){
      padding: 40px 0;
    }
    .qr_wrapper{
      padding-right: 60px;
      @media (max-width: $sm){
        padding-right: 0;
      }
    .loyalty_tabs{
      cursor: pointer;
      margin: 0 0 16px;
      padding: 16px 24px;
      border-radius: 16px;
      transition: all .5s;
      background: #f2f2f2;
      h3{
        font-size: 16px;
        color: #182020;
        line-height: 22px;
      }
      p {
        display: none;
        margin: 8px 0 0;
        font-size: 16px;
        color: $white;
        line-height: 22px;
        text-align: left;
      }
    }
    .loyalty_tabs{
      &.active{
        background-color: #01999f;
        h3{
          color: $white;
        }
        p{
          display: block;
        }
      }
    }
    }
    .qr_slider{
      .slick-dots {
        display: flex;
          margin: 0;
          list-style-type: none;
          position: absolute;
          bottom: -20px;
          left: 50%;
          transform: translateX(-50%);
          button {
            display: block;
            width: 6px;
            height: 6px;
            padding: 0;
            background-color: grey;
            text-indent: -9999px;
            cursor: pointer;
            border-radius: 30px;
            border: unset;
            margin: 0 3px;
          }
        }
        li.slick-active {
          button {
            background-color: $black;
          }
        }
      img{
        border-radius: 16px;
      }
      .qr_desc{
        h3{
          font-size: 18px;
          color: #0e1b1b;
          line-height: 24px;
          text-align: center;
          margin: 32px auto 8px;      
        }
        p{
          font-size: 14px;
          color: #5a6565;
          max-width: 400px;
          line-height: 20px;
          margin: 0 auto 32px;
        }
      }
    }
  }
  .benifits_section{
    background: linear-gradient(0deg, rgba(86, 186, 153, .12) -71.36%, rgba(86, 186, 153, 0));
    .bene_div{
      padding: 32px 30px;
      border-radius: 16px;
      margin: 40px 0 0;
      @media (max-width: $sm){
        width: 100%;
        margin: 30px 0 0;
        padding: 0;
      }
      .bene_inner{
        p{
          font-size: 16px;
          color: #092d2c;
          line-height: 21px;
          letter-spacing: -.05em;
          font-family: $basierMedium;
          @media (max-width: $sm){
            font-size: 14px;
            line-height: 20px;
          }
        }
        span{
          display: block;
          font-size: 52px;
          color: #56ba99;
          line-height: 68px;
          letter-spacing: -.05em;
          font-weight: 600;
          @media (max-width: $sm){
            font-size: 32px;
            line-height: 42px;
          }
        }
      }
    }
  }
  .inspiration{
    border-radius: 16px;
    padding: 48px 40px 0;
    background: #fef6ee;
    @media (max-width: $sm){
      padding: 40px 20px 0;
    }
  }
  .quotes_section{
    p{
      font-size: 24px;
      color: #092d2c;
      line-height: 32px;
      text-align: center;
      font-family: $basier;
      @media (max-width: $sm){
        font-size: 18px;
        line-height: 26px;
      }
    }
    .quote_flex{
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
      img{
        border-right: 1px solid $black;
        padding-right: 10px;
      }
      p{
        font-size: 18px;
        color: #092d2c;
        line-height: 28px;
        text-align: left;
      }
    }
  }

  
  .lastsection{
    border-radius: 24px;
    padding: 40px 20px;
    @media (max-width: $sm){
      margin: 0 20px;
    }
  }
}
.whatsapp-marketing{
  .display_table{
    flex-direction: unset;
  }
  .banner{
    @media (max-width: $sm){
      padding: 60px 0 0;
      text-align: center;
      .common_title{
        font-size: 28px;
        line-height: 32px;
      }
      .common_desc{
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
  .sec-light{
    @media (max-width:$sm){
      letter-spacing: -.02em;
      font-weight: 500;
      font-family:$basierMedium;
      font-size: 24px;
        line-height: 28px;
    }
  }
  .sec-title{
    @media (max-width: $sm){
      font-size: 24px;
      line-height: 28px;
    }
  }
  .sec_title{
    font-size: 42px;
    line-height: 51px;
    letter-spacing: -.02em;
    font-weight: 500;
    font-family: $basierMedium;
    @media (max-width: $sm){
      font-size: 24px;
      line-height: 28px;
    }
   }
   .sec_desc{
    font-size: 20px;
    margin: 0 0 24px;
    line-height: 30px;
    font-family: $basier;
   }
  .common_title{
    font-size: 56px;
    margin: 0 0 16px;
    line-height: 62px;
    letter-spacing: -.01em;
    font-family: $basierMedium;
  }
  .common_desc{
    font-size: 20px;
    margin: 0 0 40px;
    line-height: 30px;
    font-family: $basier;
  }
  .loyaly_div{
    width: 557px;
    margin: 70px auto 0;
    @media (max-width: $sm){
      width: 100%;
      margin: 0;
    }
  }
 
  .pre-built{
    .common_title{
      @media (max-width: $sm){
        font-size: 28px;
        line-height: 32px;
      }
    }
    .common_desc {
      @media (max-width: $sm){
        font-size: 18px;
      }
    }
     .campaigns_feature_card_root{
      gap: 24px;
      margin: 0 auto;
      padding: 0 16px;
      flex-wrap: wrap;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: $sm){
        display: block;
        border-radius: 16px;
        padding: 0;
        .slick-dots {
          display: flex;
            margin: 0;
            list-style-type: none;
            position: absolute;
            bottom: -9px;
            left: 50%;
            transform: translateX(-50%);
            button {
              display: block;
              width: 12px;
              height: 12px;
              padding: 0;
              background-color: #c6c6c6;
              text-indent: -9999px;
              cursor: pointer;
              border-radius: 30px;
              border: unset;
              margin: 0 3px;
            }
          }
          li.slick-active {
            button {
              background-color: $white;
            }
        }
      }
      .campaigns_feature_card{
        height: 300px;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        padding: 0 24px 0 0;
        border-radius: 16px;
        background: #f3f1ee;
        width: calc(50% - 12px);
        @media (max-width: $sm){
          display: block;
          padding: 0;
          height: 540px;
          width: 100%;
          margin: 0 0 30px;
        }
        .campaigns_left_side{
          padding: 174px 4px 48px 28px;
          @media (max-width: $sm){
            padding: 34px 4px 34px 28px;
          }
          .campaigns_feature_number{
            padding: 4px 9px;
            width: 28px;
            height: 28px;
            font-size: 16px;
            color: $white;
            line-height: 22px;
            border-radius: 8px;
            background: #065151;
            font-family: $basierSemibold;
          }
          h3{
            margin: 8px 0;
            font-size: 28px;
            color: $color1;
            line-height: 34px;
            letter-spacing: -.02em;
            font-family: $basierSemibold;
            @media (max-width: $sm){
              font-size: 20px;
              line-height: 24px;
            }
            
          }
          p{
            font-size: 14px;
            color: #232d2d;
            line-height: 20px;
          }
        }
        .campaigns_right_side{
          width: 100%;
          position: relative;
          bottom: -6px;
          @media (max-width: $sm){
            text-align: center;
            padding: 0 15px;
            max-width: 100%;
            bottom: 0;
            img{
              height: 300px;
            }
          }
        }
      }
      .last_card{
        width: 100%;
        height: auto;
      }
     }
   }
  .qrcode_reviews{
    .qr_wrapper{
      padding-right: 60px;
      @media (max-width: $sm){
        padding-right: 0;
      }
    .loyalty_tabs{
      cursor: pointer;
      margin: 0 0 16px;
      padding: 16px 24px;
      border-radius: 16px;
      transition: all .5s;
      background: #f2f2f2;
      h3{
        font-size: 20px;
        color: #182020;
        line-height: 26px;
      }
      p {
        display: none;
        margin: 8px 0 0;
        font-size: 16px;
        color: $white;
        line-height: 22px;
        text-align: left;
      }
      ul {
        display: none;
        padding: 10px 0 0 15px;
        li{
          font-size: 16px;
          color: $white;
          line-height: 22px;
          text-align: left;
          padding: 0 0 5px 5px;
          position: relative;
          list-style: none;
          &:before {
            position: absolute;
            content: '✔';
            left: -13px;
          }
        }
      }
    }
    .loyalty_tabs{
      &.active{
        background-color: #01999f;
        h3{
          color: $white;
        }
        p, ul{
          display: block;
        }
      }
    }
    }
    .qr_slider{
      .slick-dots {
        display: flex;
          margin: 0;
          list-style-type: none;
          position: absolute;
          bottom: -9px;
          left: 50%;
          transform: translateX(-50%);
          button {
            display: block;
            width: 6px;
            height: 6px;
            padding: 0;
            background-color: grey;
            text-indent: -9999px;
            cursor: pointer;
            border-radius: 30px;
            border: unset;
            margin: 0 3px;
          }
        }
        li.slick-active {
          button {
            background-color: $black;
          }
      }
      img{
        border-radius: 16px;
      }
      .qr_desc{
        @media (max-width:$sm){
          text-align: left;
        }
        h3{
          font-size: 18px;
          color: #0e1b1b;
          line-height: 24px;
          margin: 32px auto 8px;      
        }
        p{
          font-size: 14px;
          color: #5a6565;
          max-width: 400px;
          line-height: 20px;
          margin: 0 auto 8px;
        }
        ul {
          padding: 10px 14px 20px;
          li{
            font-size: 14px;
            color: #5a6565;
            max-width: 400px;
            line-height: 20px;
            list-style: none;
            padding-bottom: 5px;
            position: relative;
            &:before {
              position: absolute;
              content: '✔';
              margin-left: -14px;
            }
          }
        }
      }
    }
  }

  .fold3{
    .marrows {
      margin: 30px 0 35px;
      svg {
        width: 48px;
        height: 48px;
        background-color: #f3f5f5;
        border-radius: 100%;
        padding: 10px;
        margin: 0 10px;
        &:hover{
          background-color: #0d9298;
          path{
            stroke: $white;
          }
        }
      }  
      #cnext{
        position: relative;
        transform: rotate(180deg);
      }
    }
    .reviewCarousel_parent{
      .reviewCarousel{
        gap: 48px;
        padding: 24px;
        height: 381px;
        margin: 0 4px;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        border-radius: 24px;
        align-items: stretch;
        background: #e0eaff;
        @media (max-width:$sm){
            gap: 12px;
            padding: 16px;
            margin: 0 4px;
            border-radius: 8px;
            flex-direction: column;
            height: unset;
        }
        .reviewCarousel_left{
          max-width: 665px;
          overflow: hidden;
          width: calc(50% - 24px);
          @media (max-width:$sm){
            max-width: 100%;
            width: 100%;
            overflow: unset;
          }
          iframe{
            width: 100%;
            height: 100%;
            border: none;
            outline: none;
            border-radius: 12px;
          }
        }
        .reviewCarousel_right{
          gap: 24px;
          padding: 10px 0 0;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          flex-direction: column;
          max-width: -moz-calc(50% - 24px);
          max-width: calc(50% - 24px);
          @media (max-width:$sm){
            max-width: 100%;
            min-width: 100%;
            padding: 15px 0 0;
          }
          h3{
            font-size: 32px;
            line-height: 40px;
            letter-spacing: -1.28px;
            font-family: $basierMedium;
            font-weight: 500;
            @media (max-width:$sm){
              margin: 0 0 8px;
              font-size: 16px;
              line-height: 20px;
              letter-spacing: -.24px;
            }
          }
          .reviewCarousel_review_detail{
            gap: 16px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .reviewImgs{
              border-radius: 50%;
              object-fit: contain;
              background-color: #fff;
            }
            .reviewer_name{
              font-size: 22px;
              line-height: 26px;
              font-family: $basierMedium;
              padding: 0 0 6px;
              @media (max-width:$sm){
                font-size: 16px;
                line-height: 22px;
              }
            }
            .reviewer_post{
              font-size: 14px;
              color: #374040;
              line-height: 20px;
              letter-spacing: -.14px;
              @media (max-width:$sm){
                font-size: 12px;
                line-height: 16px;
              }
            }
          }
        }
      }
    }
  } 
  .what-reelo-offers{
    .whatreelo_offers{
      display: flex;
      justify-content: center;
      gap: 30px;
      padding: 15px;
      @media (max-width: $sm){
        padding: 0;
        gap: 10px;
        margin: 5px 0;
      }
      .whatreelo_offers_div{
        width: 343px;
        height: 196px;
        padding: 32px 22px;
        border-radius: 17px;
        @media (max-width: $sm){
          height: 130px;
          padding: 15px;
          img{
            width: 35px;
          }
        }
        p{
          color: #171E59;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%;  
          padding: 15px 0 0;
          @media (max-width: $sm){
            font-size: 14px;
          }
        }
      }
      .offer_1{
        background: #EDFCF2;
      }
      .offer_2{
        background: #FEF6EE;
      }
      .offer_3{
        background: #F5F8FF;
      }
      .offer_4{
        background: #FFE5EB;
      }
      .offer_5{
        background: #DDFEFF;
      }
      .offer_6{
        background: #FFF1D4;
      }
    }
  }
  .lastsection{
    border-radius: 24px;
    padding: 40px 20px;
    @media (max-width: $sm){
      margin: 0 20px;
    }
  }
}
.referral_section{
  .display_table{
    flex-direction: unset;
  }
  .banner{
    height: 100vh;
    @media (max-width: $sm){
      height: unset;
      padding: 90px 0 0;
      text-align: center;
      .common_title{
        font-size: 28px;
        line-height: 32px;
      }
      .common_desc{
        font-size: 14px;
        line-height: 22px;
      }
    }
    .common_subtitle{
      font-size: 16px;
      line-height: 21px;
      letter-spacing: .18em;
      text-transform: uppercase;
      font-family: $basierSemibold;
      padding: 0 0 20px;
    }
  }
  .sec_title{
    font-size: 42px;
    margin: 0 0 16px;
    line-height: 51px;
    letter-spacing: -.02em;
    font-weight: 500;
    font-family: $basierMedium;
    @media (max-width: $sm){
      font-size: 28px;
      line-height: 32px;
    }
   }
   .sec_desc{
    font-size: 20px;
    margin: 0 0 24px;
    line-height: 30px;
    font-family: $basier;
   }
  .common_title{
    font-size: 56px;
    margin: 0 0 16px;
    line-height: 62px;
    font-family: $basierMedium;
  }
  .common_desc{
    font-size: 20px;
    margin: 0 0 40px;
    line-height: 30px;
    font-family: $basier;
  }
  .loyaly_div{
    @media (max-width: $sm){
      width: 100%;
      margin: 0;
    }
   .ref_video{
    width: 557px;
    height: 545px;
    overflow: hidden;
    border-radius: 24px;
    background: #d8ecee;
    @media (max-width: $sm){
      width: 100%;
      height: 100%;
    }
   }
  }

 .pre-built{
  .common_title{
    @media (max-width: $sm){
      font-size: 28px;
      line-height: 32px;
    }
  }
  .common_desc {
    @media (max-width: $sm){
      font-size: 18px;
    }
  }
   .campaigns_feature_card_root{
    gap: 24px;
    margin: 0 auto;
    padding: 0 16px;
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: $sm){
      display: block;
      border-radius: 16px;
      padding: 0;
    }
    .campaigns_feature_card{
      height: 360px;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      padding: 0 24px 0 0;
      border-radius: 16px;
      background: #f3f1ee;
      width: calc(50% - 12px);
      @media (max-width: $sm){
        display: block;
        padding: 0;
        height: unset;
        width: 100%;
        margin: 0 0 30px;
      }
      .campaigns_left_side{
        padding: 174px 8px 48px 28px;
        @media (max-width: $sm){
          padding: 34px 8px 34px 28px;
        }
        .campaigns_feature_number{
          padding: 4px 9px;
          width: 28px;
          height: 28px;
          font-size: 16px;
          color: $white;
          line-height: 22px;
          border-radius: 8px;
          background: #065151;
          font-family: $basierSemibold;
        }
        h3{
          margin: 8px 0;
          font-size: 28px;
          color: $color1;
          line-height: 34px;
          letter-spacing: -.02em;
          font-family: $basierSemibold;
          @media (max-width: $sm){
            font-size: 20px;
            line-height: 24px;
          }
          
        }
        p{
          font-size: 14px;
          color: #232d2d;
          line-height: 20px;
        }
      }
      .campaigns_right_side{
        width: 100%;
        max-width: 312px;
        position: relative;
        height: -moz-calc(100% - 48px);
        // height: calc(100% - 48px);
      }
    }
    .last_card{
      width: 100%;
      height: auto;
    }
   }
 }
.referral_programs{
  .referral_slider{
    margin: 45px 0;
    img{
      display: inline-block !important;
    }
    .slick-dots {
      display: flex;
        margin: 0;
        list-style-type: none;
        position: absolute;
        bottom: -35px;
        left: 50%;
        transform: translateX(-50%);
        button {
          display: block;
          width: 10px;
          height: 10px;
          padding: 0;
          background-color: #038c8b;
          text-indent: -9999px;
          cursor: pointer;
          border-radius: 30px;
          border: unset;
          margin: 0 5px;
          opacity: .25;
        }
      }
      li.slick-active {
        button {
          opacity: 1;
        }
      }
    .ref_slide{
      p{
        font-size: 16px;
        margin: 24px 0 0;
        color: #374040;
        line-height: 24px;
        text-align: center;
        font-family: $basierMedium;
      }
    }
  }
}
 .how_to_send_cards{
  .common_subtitle{
    font-size: 16px;
    line-height: 21px;
    letter-spacing: .18em;
    text-transform: uppercase;
    font-family: $basierSemibold;
    padding: 0 0 20px;
  }
  .marrows {
    margin: 30px 0 35px;
    svg {
      width: 48px;
      height: 48px;
      background-color: #f3f5f5;
      border-radius: 100%;
      padding: 10px;
      margin: 0 10px;
      &:hover{
        background-color: #0d9298;
        path{
          stroke: $white;
        }
      }
    }  
    #snext{
      position: relative;
      transform: rotate(180deg);
    }
  }
  .campaigns_how_to_send_cards_parent{  
    .campaigns_how_to_send_card{
      gap: 24px;
      height: 580px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 16px;
      background: #f5f8ff;
      @media (max-width: $sm){
        height: unset;
        display: block;
      }
      .campaigns_left_side{
        flex-grow: 1;
        padding: 48px 64px;
        @media (max-width: $sm){
          padding: 20px;
        }
        .campaigns_card_number{
          width: 64px;
          height: 64px;
          font-size: 34px;
          color: $white;
          line-height: 42px;
          border-radius: 50%;
          display: -moz-box;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #6172f3;
          font-family: $basierSemibold;
          @media (max-width: $sm){
            width: 34px;
            height: 34px;
            font-size: 20px;
            line-height: 28px;
          }
        }
        h3{
          font-size: 36px;
          margin: 32px 0 0;
          color: #171e59;
          line-height: 50px;
          letter-spacing: -.02em;
          font-family: $basierSemibold;
          @media (max-width: $sm){
            font-size: 28px;
            line-height: 32px;
          }
        }
        p{
          margin: 40px 0 0;
          color: rgba(23, 30, 89, .2);
          font-size: 36px;
          line-height: 50px;
          letter-spacing: -.02em;
          font-family: $basierSemibold;
          @media (max-width: $sm){
            font-size: 16px;
            line-height: 26px;
            display: none;
          }   
        }
      }
      .campaigns_right_side{
        img{
          padding: 20px 64px 20px 0;
          @media (max-width: $sm){
            padding: 20px 20px 0;
          }
        }
      }
    }
  }
 }
 .miss_out{
  .bene_div{
    padding: 32px 30px;
    border-radius: 16px;
    @media (max-width: $sm){
      width: 100%;
      margin: 30px 0 0;
      padding: 0;
    }
    .bene_inner{
      p{
        font-size: 24px;
        color: #374040;
        line-height: 34px;
        letter-spacing: -.02em;
        font-family: $basierMedium;
        @media (max-width: $sm){
          font-size: 14px;
          line-height: 20px;
        }
      }
      span{
        display: block;
        font-size: 64px;
        color: #15b79e;
        line-height: 78px;
        letter-spacing: -.02em;
        font-weight: 600;
        @media (max-width: $sm){
          font-size: 32px;
          line-height: 42px;
        }
      }
    }
  }
}
 .benifits_section{
  .bene_div{
    padding: 32px 30px;
    border-radius: 16px;
    width: 47%;
    margin: 40px 14px 0;
    @media (max-width: $sm){
      width: 100%;
      margin: 30px 0 0;
    }
    h3{
      font-size: 24px;
      color: #171e59;
      line-height: 32px;
      margin: 40px auto 16px;
      letter-spacing: -.02em;
      font-family: $basierMedium;
    }
    p{
      font-size: 18px;
      color: #171e59;
      line-height: 28px;
      font-family: $basier;
    }
    .bene_inner{
      padding: 36px 22px;
      background: #fff;
      border-radius: 16.7273px;
      margin: 0 0 30px;
      p{
        font-size: 20px;
        color: #171e59;
        line-height: 28px;
        text-align: center;
        letter-spacing: -.02em;
        font-family: $basierMedium;
      }
      span{
        font-size: 48px;
        line-height: 62px;
        text-align: center;
        letter-spacing: -.02em;
        font-family:$basierSemibold;
        display: block;
      }
      .green{
        color: #16b364;
      }
      .red{
        color: #ef6820;
      }
      .blue{
        color: #1570ef;
      }
    }
  }
  .b1{
    background: #edfcf2;
  }
  .b2{
    background: #fef6ee;
  }
  .b3{
    background: #f5f8ff;
  }
}
.quotes_section{
  p{
    font-size: 24px;
    color: #092d2c;
    line-height: 32px;
    text-align: center;
    font-family: $basier;
    @media (max-width: $sm){
      font-size: 18px;
      line-height: 26px;
    }
  }
  .quote_flex{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    img{
      border-right: 1px solid $black;
      padding-right: 10px;
    }
    p{
      font-size: 18px;
      color: #092d2c;
      line-height: 28px;
      text-align: left;
    }
  }
}


  .lastsection{
    border-radius: 24px;
    padding: 40px 20px;
    @media (max-width: $sm){
      margin: 0 20px;
    }
  }
}
.about-us{
  .about_banner{
    display: flex;
    align-items: center;
    gap: 45px;
    padding: 0 0 60px;
    @media (max-width: $sm){
      flex-direction: column;
      gap: 20px;
      padding: 20px 0;
    }
  }
  .common_title{
    color: #354545;
    font-weight: 600;
    margin: 70px 0;
    font-size: 56px;
    line-height: 62px;
    letter-spacing: -.01em;
    font-family: $basierMedium;
    @media (max-width: $sm){
      margin: 40px auto 20px;
      font-size: 32px;
      line-height: 42px;
    }
  }
  .sec_title{
    font-size: 36px;
    line-height: 43px;
    font-weight: 600;
    color: #354545;
  }
  .about_content{
    h2{
      font-size: 24px;
      line-height: 36px;
      color: #354545;
      text-align: center;
      margin: 80px 0;
      @media (max-width: $sm){
        margin: 20px 0;
      }
      .selected_text{
        background-color: #33c1c0;
        color: $white;
        font-weight: 400;
      }
    }
  }
  .guiding{
    background-color: #f6ffff;
    .common_desc{
      font-size: 24px;
      line-height: 34px;
      color: #354545;
      max-width: 440px;
      font-weight: 600;
      margin: 10px 0 45px;
    }
    .about_banner{
      justify-content: space-between;
      .principles{
        h3{
          font-size: 24px;
          line-height: 34px;
          color: #33c1c0;
          font-weight: 600;
          padding: 0 0 8px;
        }
        p{
          font-size: 16px;
          line-height: 24px;
          color: #354545;
        }
      }
    }
  }
  .our_story{
    .about_banner{
      gap:30px;
      @media (max-width: $sm){
        flex-direction: column;
        gap: 20px;
      }
    }
  }
  .our_team{
    background-color: #fff9e9;
    .aboutus_custom-row{
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 50px;
      @media (max-width: $sm){
        flex-direction: column;
        gap: 0px;
        justify-content: center;
      }
      .aboutus_left{
        img{
          margin: 0 0 30px auto;
          display: block;
          @media (max-width: $sm){
            width: 100%;
          }
        }
        .aboutus_text-box{
          h2{
            font-size: 36px;
            line-height: 43px;
            color: #354545;
            font-weight: 600;
          }
          p{
            font-size: 16px;
            line-height: 24px;
            color: #354545;
            padding: 15px 0;
          }
        }
      }
      .aboutus_right{
        @media (max-width: $sm){
          width: 100%;
        }
        .theme-btn{
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 45px;
          border: none;
          color: #ffffff;
          background: #33c1c0;
          border-radius: 8px;
          @media (max-width: $sm){
            width: 100%;
          }
          img{
            vertical-align: middle;
          }
        }
        .react-reveal{
          .lazyload-wrapper{
            img{
              display: block;
              margin: 0 auto 30px 0;
              @media (max-width: $sm){
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
  .reelo_news{
    .reelonew_wrapper{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 60px 0 0;
      flex-wrap: wrap;
      .reeloNews{
        display: flex;
        align-items: center;
        justify-items: center;
        @media (max-width: $sm){
          text-align: center;
          display: block;
          justify-items: unset;
          align-items: unset;
        }
        .reelonews_icon{
          img{
            filter: gray;
            filter: grayscale(1);
            transition: .2s ease-in;
            &:hover{
              filter: none;
              transform: scale(1.2);
            }
          }
        }
      }
    }
  }
}
.pricing{
  .sec-heading2{
    font-family: $basierSemibold;
    color: #182020;
    margin: 40px 0;
  }
  .pricing_card-rows{
    display: flex;
    justify-content: center;
    gap: 25px;
    @media (max-width: $sm){
      display: block;
    }
    .cards{
      width: 370px;
      height: 100%;
      min-height: 380px;
      padding: 24px 32px;
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @media (max-width: $sm){
        width: 100%;
      }
      .white-btn{
        color: #1A424F;
        border: 1px solid #1A424F;
        border-radius: 8px;
        background-color: transparent;
        width: 100%;
      }
      .theme-btn{
        width: 100%;
      }
      h3{
        font-size: 24px;
        color: #354545;
        line-height: 30px;
        margin-bottom: 8px;
        font-weight: 700;
      }
      h4{
        font-size: 48px;
        line-height: 67px;
        color: #182020;
        font-weight: 600;
        p{
          display: inline;
          font-size: 48px;
          line-height: 67px;
          color: #182020;
          font-weight: 400;
        } 
        span{
          font-size: 14px;
          line-height: 18px;
        }   
      }
      h6{
        font-size: 16px;
        line-height: 30px;
        color: rgba(24, 32, 32, .75);
        font-weight: 400;
      }
      .pricing_desc2{
        font-size: 14px;
        color: #1a424f;
        line-height: 28px;
        text-align: center;
      }
      .pricing_features{
        display: flex;
        margin: 24px 0;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;
      }
    }
    .pricing_plan-card{
      background: #f5f5f5;
    }
    .pricing_plan-card__3SDlI{
      background: linear-gradient(157.35deg, #c4f2f4 14.72%, #9cffed 102.4%);
    }
  }
  .pricing_term{
    font-size: 12px;
    line-height: 17px;
    text-align: right;
    color: #838c8c;
  }
  .pricebanner-fixed{
    position: fixed;
    width: 335px;
    top:105px;
  }
  .pricing_compare-plans{
    h3{
      margin: 40px 0 16px;
    }
    .pricing_plans-head{
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 24px 0 16px;
      border-bottom: 2px solid #ddd;
      background-color: $white;
      .pricing_head-col{
        width: 50%;
        .pricing_span1{
            font-size: 24px;
            line-height: 34px;
            font-weight: 600;
        }
        .pricing_span2{
          color: #616d6d;
          font-weight: 600;
        }
      }
    }
    .pricing_mobile-price-row{
      padding: 16px 0;
      border-bottom: 1px solid #ddd;
      .pricing_question{
        cursor: pointer;
        h3{
          font-size: 16px;
          color: $black;
          line-height: 20px;
          margin: 0;
          img{
            width: 24px;
            padding-left: 12px;
          }
        }
        .pricing_icon-row{
          padding-top: 8px;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          div{
            width: 50%;
          }
        }
      }
      .pricing_dets-row{
        padding: 16px 0 4px;
        h5{
          font-size: 14px;
          color: #182020;
          line-height: 17px;
          margin-bottom: 8px;
          font-family: $basier;
          font-weight: 400;
        }
        .pricing_icon-row{
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          div{
            width: 50%;
          }
        }
      }
    }
  }
  .pricing-collasible-table{
    overflow-x: auto;
    padding: 0 60px;
    text-align: left;
    table{
      margin: 0;
      width: 100%;
      border-collapse: collapse;
      thead{
        tr{
          border-bottom: 1px solid rgba(0, 0, 0, .2);
          th{
            padding: 28px 0;
            font-size: 16px;
            line-height: 19px;
            font-family: $basier;
            color: #182020;
            font-size: 20px;
            line-height: 28px;
            span{
              font-size: 12px;
              font-family: $basier;
            }
            .pricing_span1 {
              font-size: 40px;
              line-height: 56px;
              font-family: $basier;
            }
            .pricing_span2 {
              font-size: 16px;
              color: #182020;
              line-height: 22px;
              font-family: $basier;
            }
          }
          th:first-of-type {
            font-size: 24px;
            line-height: 30px;
            vertical-align: middle;
            font-family: $basier;
          }    
          th:first-child {
            min-width: 330px;
          }    
        }
      }
      tbody{
        .pricing_feature-head{
          cursor: pointer;
          border-bottom: 1px solid rgba(0, 0, 0, .08);
          td{
            padding: 19px 0 23px;
            font-size: 18px;
            line-height: 22px;
            color: #354545;
            font-family: $basier;
          }
        }
        .pricing_feature-rows{
          border-bottom: 1px solid rgba(0, 0, 0, .08);
          td{
            font-size: 14px;
            line-height: 17px;
            color: #556969;
            padding: 14px 0 13px;
            min-width: 100px;
            .tt-img {
              cursor: pointer;
            }
          }
          .tableFlex{
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
  .pricing_contact-banner{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 32px;
    margin: 40px 0;
    background: #ecfaff;
    border-radius: 15px;
    @media (max-width: $sm){
      flex-direction: column;
      align-items: flex-start;
    }
    .pricing_rightcontent{
      margin-left: 50px;
      @media (max-width: $sm){
        margin-left: 0;
      }
      h3{
        font-size: 24px;
        line-height: 29px;
        color: #354545;
      }
      h5{
        font-size: 14px;
        line-height: 21px;
        color: #354545;
        margin: 15px 0;
      }
      .theme-btn{
        @media (max-width: $sm){
          width: 100%;
        }
      }
    }
  }
}
.growth-stories{
  .main_wrapper{
    background-color: #f8fbfb;
  }
  .display_table{
    flex-direction: unset;
  }
  .banner{
    height: 100vh;
    @media (max-width: $sm){
      height: unset;
      padding: 90px 0 0;
      .common_title{
        font-size: 28px;
        line-height: 32px;
      }
      .common_desc{
        font-size: 14px;
        line-height: 22px;
      }
    }
    .common_subtitle{
      font-size: 16px;
      line-height: 21px;
      letter-spacing: .18em;
      text-transform: uppercase;
      font-family: $basierSemibold;
      padding: 0 0 20px;
    }
    .loyaly_div{
      .growth-stories_right_side{
        min-width: 500px;
        padding: 24px 32px;
        border-radius: 16px;
        background: #0d9298;
        @media (max-width: $sm){
          min-width: 100%;
          padding: 20px;
          margin-bottom: 40px;
        }
        h3{
          font-size: 24px;
          margin: 0 0 20px;
          line-height: 30px;
          letter-spacing: -.01em;
          font-family: $basierSemibold;
          @media (max-width: $sm){
            font-size: 14px;
            margin: 0 0 12px;
            line-height: 20px;
          }
        }
        .growth-stories_points{
          gap: 12px;
          margin: 0 0 20px;
          display: flex;
          align-items: center;
          .growthimg{
            min-height: 32px;
            min-width: 32px;
            @media (max-width: $sm){
              min-height: 20px;
              min-width: 20px;
            }
          }
          p{
            font-size: 20px;
            max-width: 465px;
            line-height: 26px;
            letter-spacing: -.01em;
            @media (max-width: $sm){
              max-width: unset;
              font-size: 12px;
              line-height: 16px;
            }
          }
        }
      }
    }
  }
  .sec_title{
    font-size: 48px;
    line-height: 60px;
    letter-spacing: -.02em;
    font-family: $basierSemibold;
    @media (max-width: $sm){
      font-size: 24px;
      line-height: 30px;
    }
   }
   .sec-heading{
    margin: 28px 0 12px;
    @media (max-width: $sm){
      font-size: 16px;
      line-height: 22px;
    }
   }
   p.sec-heading{
    @media (max-width: $sm){
      font-size: 14px;
      line-height: 20px;
      margin:0;
    }
   }
  .common_title{
    font-size: 56px;
    margin: 0 0 16px;
    line-height: 62px;
    font-family: $basierMedium;
  }
  .common_desc{
    font-size: 20px;
    margin: 0 0 40px;
    line-height: 30px;
    font-family: $basier;
  }
.reelo_growthstories{
  .blogBox{
    padding: 0 20px 30px;
    @media (max-width: $sm){
      padding: 0 0 30px;
    }
    .webinar-box-wrapper{
      .webinar-box{
        .webinar_Img{
          iframe{
            height: 345px;
            outline: none;
            border: none;
            @media (max-width: $sm){
              height: 195px;
            }
          }
        }
      }
    }
  }
  
}
.featured_brand{
  background-color: #f8fbfb;
  .featured_wrapper{
    gap: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: $sm){
      flex-wrap: wrap;
      gap:16px;
      img{
        width: 94px;
      }
    }
  }
}
  .lastsection{
    border-radius: 24px;
    padding: 40px 20px;
    @media (max-width: $sm){
      margin: 0 20px;
    }
  }
}
.press_release{
  @media (max-width: $sm){
    .pr1, .pr2{
      width: 100px;
    }
  }
  .banner{
    background: #f5ffff;
    height: 70vh;
    .tagline_wrapper{
      position: absolute;
      top:60%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      .common_subtitle{
        margin: 0 0 8px;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        letter-spacing: -.36px;
        font-family: $basierMedium;
        @media (max-width: $sm){
          font-size: 16px;
          line-height: 20px;
        }
      }
      .common_title{
        font-size: 60px;
        margin: 0 0 18px;
        color: #0e1b1b;
        line-height: 64px;
        text-align: center;
        letter-spacing: -1.8px;
        font-family: $basierSemibold;
        @media (max-width: $sm){
          font-size: 36px;
          line-height: 40px;
          letter-spacing: -1px;
        }
      }
      .common_desc{
        font-size: 24px;
        margin: 0 0 48px;
        color: #0e1b1b;
        line-height: 30px;
        text-align: center;
        letter-spacing: -.72px;
        font-family: $basierMedium;
        @media (max-width: $sm){
          font-size: 20px;
          line-height: 24px;
          margin: 0 auto 24px;
          letter-spacing: -.52px;
        }
      }
    }
  }
  .sec_title{
    font-size: 42px;
    color: #0e1b1b;
    line-height: 48px;
    text-align: center;
    margin: 0 auto 48px;
    letter-spacing: -.84px;
    font-weight: 500;
    font-family: $basierMedium;
    @media (max-width: $sm){
      font-size: 28px;
      line-height: 32px;
    }
   }
   .p_wrapper{
    a{
      .press-box{
        padding: 0 20px;
        @media (max-width: $sm){
         padding: 0;
        }
          .press_Img{
            img{ border-radius: 16px;}
          }
      }
      .p_content{
        padding: 75px 0 0 20px;
        @media (max-width: $sm){
          padding: 0;
        }
      }
      .sec-heading{
        font-size: 28px;
        margin: 28px 0 0;
        line-height: 34px;
        letter-spacing: -.84px;
        font-family: $basierMedium;
        font-weight: 500;
        @media (max-width: $sm){
          font-size: 20px;
          margin: 16px 0 0;
          line-height: 24px;
          letter-spacing: -.4px;
        }
      }
      &:hover{
        .sec-heading{
          color: #0d9298;
        }
      }
    }
   }
   .reelo_pressRelease{
    .press-box{
      padding: 20px;
      height: 470px;
      @media (max-width: $sm){
        padding: 20px 0;
        height: unset;
       }
      a{
        .press_Img{
          img{ border-radius: 16px;}
        }
        .sec-heading{
          font-size: 28px;
          margin: 28px 0 0;
          line-height: 34px;
          letter-spacing: -.84px;
          font-family: $basierMedium;
          font-weight: 500;
          @media (max-width: $sm){
            font-size: 20px;
            margin: 16px 0 0;
            line-height: 24px;
            letter-spacing: -.4px;
          }
        }
      }
      &:hover{
        .sec-heading{
          color: #0d9298;
        }
      }
    }
   }
   .lastsection{
    border-radius: 24px;
    padding: 40px 20px;
    @media (max-width: $sm){
      margin: 0 20px;
    }
  }
}
.fresources_listing{
  .category_frslider{
    @media (max-width: $sm){
      display: flex;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch; 
      width: 100%;
      white-space: nowrap;
      scrollbar-width: thin;
      padding: 20px 0;
    }

  .category_frslider::-webkit-scrollbar {
    @media (max-width: $sm){
      display: none;
    }
  }
    li{
      a{
        font-size: 16px;
      }
    }
  }
  .latest_fr{
    margin: 60px 0 0;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    height: 499px;
    @media (max-width: $sm){
      flex-wrap: wrap;
      margin: 30px 0 0;
      height: unset;
      text-align: center;
    }
    .latest_frContent{
      max-width: 570px;
      margin: 0 auto 0 40px;
      @media (max-width: $sm){
        max-width: 100%;
        padding: 20px;
        margin: 0;
      }
      .latest_caption{
        color: #5F4D07;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        background: #EBBD07;
        padding: 8px 20px;
        border-radius: 90px;
        @media (max-width: $sm){
          font-size: 14px;
          padding: 6px 15px;
        }
      }
      .sec_title{
        @media (max-width: $sm){
          font-size: 24px;
          line-height: 28px;
        }
        .highlight {
          color: $yellow;
        }
      }
      .frshort_description{
        padding: 20px 0;
      }
    }
    .latest_frImg{
      .fr_latestImg{
        img {
          min-height: 494px;
          position: relative;
          top: 2.5px;
          border-top-right-radius: 40px;
          border-bottom-right-radius: 40px;
          @media (max-width:$sm){
            border-top-right-radius: 0px;
            border-bottom-left-radius: 24px;
            min-height: unset;
            top: 6.5px;
          }
        }
      }
      margin: 0;
      
      @media (max-width: $sm){
        max-width: 100%;
      }
    }
  }
  .r-center{
    .sec-title{
      @media (max-width: $sm){
        font-size: 28px;
        line-height: 32px;
      }
    }
  }
  .resource_fold{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:30px 0 40px;
    @media (max-width: $sm){
      display: block;
    }
    .resource_left_fold{
      width: 410px;
      @media (max-width: $sm){
        width: 100%;
      }
    }
    .resource_right_fold{
      @media (max-width: $sm){
        padding: 30px 0 0;
      }
    }
  }
  .fresources_wrapper{
    .fresources_blogs{
      .fresources{
        padding: 10px;
        .fresources-box-wrapper{
          .fresources-box{
            border-radius: 11px;
            height: 490px;
            @media (max-width:$sm){
              height: unset;
            }
            .fresources_Img{
              .img-bg {
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                min-height: 294px;
                border-top-left-radius: 12px;
                border-top-right-radius: 12px;
                @media (max-width:$sm){
                  min-height: 180px;
                }
              }
              // img{
              //   height: 320px;
              //   border-top-left-radius: 11px;
              //   border-top-right-radius: 11px;
              //   @media (max-width: $sm){
              //     height: 252px;
              //   }
              // }
            }
            .fresources-content{
              padding: 24px 0;
              .fcategory{
                font-size: 14px;
              }
              .fresources-title {
                font-size: 22px;
                font-style: normal;
                font-weight: 600;
                line-height: 120%;
                letter-spacing: -0.64px;
                padding: 6px 0 15px;
                height: 74px;
                @media (max-width: $sm){
                  font-size: 22px;
                  line-height: 32px;
                }
              }
              .f-btn {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .pdf_text{
                  color: #638A8A;
                  font-size: 14px;
                  font-weight: 600;
                  line-height: 17.5px;
                  border-radius: 90px;
                  padding: 8px 20px;
                  background: #A6DBDB;
                  background-blend-mode: multiply;
                }
                .dwn_text{
                  color: #252525;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 25px;
                  letter-spacing: -0.323px;
                  text-decoration: underline;
                  font-family: $basierMedium;
                }
              }
            }
          }
        }
      }
    }
  }
  .lastsection{
    border-radius: 24px;
    padding: 40px 20px;
    @media (max-width: $sm){
      margin: 0 20px;
    }
  }
}
.free_resources{
  .box-bg{
    background-image: url('../images/box.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .banner{
    .tagline_wrapper{
      position: absolute;
      top:54%;
      transform: translate(-50%, -50%);
      left: 50%;
      width: 880px;
      @media (max-width: $sm){
        width: 100%;
        padding: 0 15px;
      }
      .resource_title{
        font-size: 56px;
        margin: 0 0 16px;
        line-height: 62px;
        letter-spacing: -.01em;
        font-family: $basierMedium;
        @media (max-width: $sm){
          font-size: 28px;
          line-height: 40px;
        }
      }
      .resource_desc{
        font-size: 20px;
        color: #092d2c;
        line-height: 32px;
        margin: 0 auto 48px;
        @media (max-width: $sm){
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
    .marketingCalendar_tag{
      font-size: 18px;
      line-height: 24px;
      padding: 8px 20px;
      position: absolute;
      border-radius: 28px;
      font-family: $basier;
      @media (max-width: $sm){
        display: none;
      }
    }
    .marketingCalendar_tag-1{
      top: 45%;
      left: 6%;
      background: #ffedd9;
      transform: rotate(-35.74deg);
    }
    .marketingCalendar_tag-2{
      top: 53%;
      right: 6%;
      background: #fff3d3;
      transform: rotate(31.82deg);
    }
    .marketingCalendar_tag-3{
      bottom: 26%;
      left: 12%;
      background: #f1f3ff;
      transform: rotate(-14.83deg);
    }
    .marketingCalendar_tag-4{
      bottom: 20%;
      right: 12%;
      background: #ffe3e5;
      transform: rotate(15.52deg);
    }
    .marketing_fold{
      gap: 42px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 60px 0;
      @media (max-width: $sm){
       gap: 24px;
       flex-wrap: wrap;
       flex-direction: column-reverse;
       padding: 30px 0 0;
       text-align: center;
      }
      .marketing_fold_left{
        max-width: 669px;
        @media (max-width: $md){
          max-width: 550px;
        }
        @media (max-width: $xs){
          max-width: 100%;
        }
        .resource_subtitle{
          font-size: 16px;
          color: #0d9298;
          line-height: 21px;
          letter-spacing: .18em;
          text-transform: uppercase;
          @media (max-width: $sm){
            font-size: 12px;
            line-height: 21px;
          }
        }
        .resource_title{
          font-size: 56px;
          color: #0e1b1b;
          line-height: 64px;
          margin: 16px 0 20px;
          letter-spacing: -.01em;
          font-weight: 900;
          font-family: $basierMedium;
          @media (max-width: $sm){
            font-size: 28px;
            line-height: 40px;
          }
        }
        .resource_desc{
          font-size: 20px;
          margin: 0 0 40px;
          color: #374040;
          line-height: 28px;
          font-family: $basier;
          @media (max-width: $sm){
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
      .marketing_fold_right{
        max-width: 440px;
        img{
          border-radius: 24px;
        }
        @media (max-width: $sm){
          max-width: 100%;
        }
      }
    }
  }
  .resource1{
    .resource_title{
      font-size: 42px;
      line-height: 60px;
      font-family: $basierMedium;
      @media (max-width: $sm){
        font-size: 24px;
        line-height: 32px;
      }
    }
    .resource_desc{
      p{
        font-size: 20px;
        line-height: 32px;
        margin: 16px 0;
        @media (max-width: $sm){
          font-size: 16px;
          line-height: 24px;
        }
        span{
          font-size: 16px;
          line-height: 20px;
        }
      }
      ul{
        padding-left: 20px;
        li{
          font-size: 20px;
          color: $white;
          line-height: 32px;
          padding: 0 0 10px;
          @media (max-width: $sm){
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }
    .resources_wrapper{
      gap: 104px;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: $sm){
        gap: 24px;
        flex-wrap: wrap;
       }
      .resources_left{
        max-width: 627px;
        @media (max-width: $sm){
          max-width: 100%;
        }
        .resource_desc{
          p{
            @media (max-width: $sm){
              font-size: 16px;
              line-height: 24px;
            }
          }
        }
      }
      .resources_right{
        min-width: 360px;
        @media (max-width: $sm){
          min-width: 100%;
        }
      }
    }
  }
  .resources_brand{
    .resource_title{
      font-size: 30px;
      margin: 0 0 32px;
      color: #182020;
      line-height: 38px;
      font-weight: 600;
      @media (max-width: $sm){
        font-size: 24px;
        line-height: 32px;
      }
    }
    .marketingPlaybook_brands{
      gap: 56px;
      flex-wrap: wrap;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: $sm){
        gap: 24px;
        img{
          width: 88px;
        }
      }
    }
  }
  .resources_download{
    .resource_title{
      font-size: 42px;
      color: #092d2c;
      line-height: 60px;
      text-align: center;
      margin: 0 auto 40px;
      @media (max-width: $sm){
        font-size: 24px;
        line-height: 32px;
      }
    }
    .download_my_resource{
      margin: 0 auto;
      max-width: 800px;
      @media (max-width: $sm){
        max-width: 100%;
      }
    }
    .marketingCalendar_tag{
      font-size: 18px;
      line-height: 24px;
      padding: 8px 20px;
      position: absolute;
      border-radius: 28px;
      font-family: $basier;
      @media (max-width: $sm){
        display: none;
      }
    }
    .marketingCalendar_tag-1{
      top: 6%;
      left: 15%;
      background: #f9ffd6;
      transform: rotate(19.05deg);
    }
    .marketingCalendar_tag-2{
      top: 6%;
      right: 12%;
      background: #fff3d3;
      transform: rotate(31.82deg);
    }
    .marketingCalendar_tag-3{
      top: 34%;
      left: 9%;
      background: #ecfff9;
      transform: rotate(-24.29deg);
    }
    .marketingCalendar_tag-4{
      top: 34%;
      right: 12%;
      background: #ffe3e5;
      transform: rotate(15.52deg);
    }
    .marketingCalendar_tag-5{
      left: 24%;
      bottom: 20%;
      background: #e4ecff;
      transform: rotate(15.52deg);
    }
    .marketingCalendar_tag-6{
      right: 15%;
      bottom: 20%;
      background: #ffe8f7;
      transform: rotate(15.52deg);
    }
  }
}
.report_resources{
  .banner{
    .bell {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: -1;
      @media (max-width: $sm){
        position: unset;
        transform: unset;
        margin: -34px 0 0;
      }
    }  
    .marketing_fold{
      gap: 42px;
      display: flex;
      justify-content: space-between;
      padding: 40px 0 0;
      @media (max-width: $sm){
       gap: 24px;
       flex-wrap: wrap;
      }
      .marketing_fold_left{
        max-width: 669px;
        padding: 40px 0 0;
        @media (max-width: $md){
          max-width: 550px;
        }
        @media (max-width: $xs){
          max-width: 100%;
        }
        .resource_subtitle{
          color: #0E1B1B;
          font-family: "Area Variable";
          font-size: 33.522px;
          font-style: normal;
          font-weight: 800;
          line-height: 40.705px;
          padding-top: 8px;
          letter-spacing: -0.335px;
          border-bottom: 2px solid #01999f96;
          display: inline-block;
          @media (max-width: $sm){
              padding-top: 0;
              font-size: 20px;
              letter-spacing: -0.2px;
          }
        }
        .resource_title{
          color: #01999F;
          font-family: "Area Variable";
          font-size: 56px;
          font-style: normal;
          font-weight: 600;
          line-height: 121%;
          letter-spacing: -0.56px;
          @media (max-width: $sm){
            font-size: 36px;
            letter-spacing: -0.36px;
          }
        }
        .resource_desc{
          color: #374040;
          font-family: "Area Variable";
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; 
          padding-top: 30px;
          @media (max-width: $sm){
            font-size: 16px;
            padding-top: 20px;
          }
        }
      }
      .marketing_fold_right{
        min-width: 440px;
        border-radius: 20px;
        background: rgba(255, 255, 255, 0.10);
        box-shadow: -4px 8px 11px 0px rgba(0, 45, 50, 0.10);
        backdrop-filter: blur(18.157896041870117px);
        padding: 32px;
        position: relative;
        z-index: 3;
        .form_title{
          color: #023342;
          text-align: center;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 28px; /* 116.667% */
          letter-spacing: -0.48px;
          padding-bottom: 26px;
          @media (max-width: $sm){
            color: #023342;
            font-size: 20.29px;
            font-style: normal;
            line-height: 23.671px; /* 116.667% */
            letter-spacing: -0.406px;
          }
        }
        @media (max-width: $sm){
          min-width: 100%;
          padding: 30px 20px;
        }
      }
    }
  }
  .report-svg{
    position: absolute;
    top: -40px;
    left: 0;
    z-index: -2;
    width:100%;
    height:2800px;
    @media (max-width: $md){
      top: -171px;
      height: 1900px;
    }
    @media (max-width: $xs){
      top: -171px;
      height: 2400px;
    }
  }
  .gradient-bg{
    margin: -250px 0 0;
    @media (max-width: $sm){
      margin: 0;
    }
    .reportFold{
      margin: 280px 0 0;
      @media (max-width: $sm){
        margin: 0;
      }
      .report_title{
        color: #023342;
        font-family: "Area Variable";
        font-size: 56px;
        font-style: normal;
        font-weight: 700;
        line-height: 121%; /* 121.429% */
        letter-spacing: -0.56px;
        @media (max-width: $sm){
          font-size: 20px;
          letter-spacing: -0.2px;
        }
        span{
          font-family: "Area Variable";
          font-size: 96px;
          font-style: normal;
          font-weight: 900;
          line-height: 120%;
          letter-spacing: -0.96px;
          @media (max-width: $sm){
            font-size: 30px;
            letter-spacing: -0.3px;
          }
        }
      }
      .ame-tabs-wrapper{
        padding: 25px 0 50px;
        @media (max-width: $sm){
          padding: 25px 0 30px;
          display: flex;
          overflow-x: auto;
          -webkit-overflow-scrolling: touch;
          width: 100%;
          white-space: nowrap;
          scrollbar-width: thin;
          position: relative;
          z-index: 1;
        }
        a{
          color: $white;
          font-family: "Area Variable";
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 140%;
          border-radius: 90px;
          padding: 13px 20px 8px;
          margin: 0 20px 0 0;
          background: rgba(255, 255, 255, 0.10);
          box-shadow: -4px 8px 11px 0px rgba(0, 45, 50, 0.10);
          backdrop-filter: blur(18.157896041870117px);
          @media (max-width: $sm){
            font-size: 12px;
            padding: 7px 12px 5px;
            margin: 0 12px 0 0;
          }
          &.active{
            border-radius: 90px;
            box-shadow: -4px 8px 11px 0px rgba(0, 45, 50, 0.10);
            backdrop-filter: blur(18.157896041870117px);
          }
          &.active.activeTab1{
            background: linear-gradient(31deg, #001055 12.54%, #286DF0 86.69%);
          }
          &.active.activeTab2{
            background: radial-gradient(79.93% 79.93% at 87.12% 41.59%, #957DFB 0%, #5237E6 100%);
          }
          &.active.activeTab3{
            background: radial-gradient(79.93% 79.93% at 87.12% 41.59%, #FFB02C 0%, #B66504 100%);
          }
          &.active.activeTab4{
            background: radial-gradient(79.93% 79.93% at 87.12% 41.59%, #EF4866 0%, #8B0010 100%), rgba(255, 255, 255, 0.10);
          }
          &.active.activeTab5{
            background: radial-gradient(79.93% 79.93% at 87.12% 41.59%, #079B15 0%, #0D5614 100%);
          }
        }
      }
      .ame-wrap{
        .slider-products{
          .item {
            padding: 70px;
            border-radius:40px;
            height: 560px !important;
            @media (max-width: $md){
              padding: 18px;
              border-radius: 12px;
              height: 250px !important;
            }
            @media (max-width: $xs){
              padding: 18px;
              border-radius: 12px;
              height: 180px !important;
            }
          }
          
          .slick-dots {
            display: flex;
            justify-content: center;
            margin: 0;
            list-style-type: none;
            align-items: center;
            position: absolute;
            right: -45%;
            top: 50%;
            transform: translateY(-50%) rotate(90deg);
            padding: 0;
            @media (max-width: $sm){
              right: -40%;
            }
            button {
                display: block;
                width: 15px;
                height:15px;
                padding: 0;
                background-color: $white;
                border: 1px solid $white !important;
                text-indent: -9999px;
                cursor: pointer;
                border-radius: 30px;
                border: unset;
                position: relative;
                margin: 0 8px;
                @media (max-width: $sm){
                  width: 8px;
                  height: 8px;
                }
            }
          }

          li.slick-active {
            button {
                width: 19px;
                height: 19px;
                background-color: $white;
                @media (max-width: $sm){
                  width: 12px;
                  height: 12px;
                }
                &:before{
                    position: absolute;
                    content: '';
                    width: 27px;
                    height: 27px;
                    border-radius: 100%;
                    border: 1px solid $white;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    @media (max-width: $sm){
                      width: 18px;
                      height: 19px;
                    }
                }
            }
          }
        }
        .toptrend_slider{
          .slides1{
            background: #C5D9FF;
          }
          .slides2{
            background: #3B72E2;
          }
          .slides2.top_trend{
            .trend_title{
              color: #C5D9FF;
            }
          }
          .top_trend{
            .trend_title{
              color: #2058CB;
              font-family: "Area Variable";
              font-size: 70px;
              font-style: normal;
              font-weight: 900;
              line-height: 120%; 
              letter-spacing: -0.7px;
              padding-bottom: 60px;
              @media (max-width: $sm){
                font-size: 18px;
                letter-spacing: -0.22px;
                padding-bottom: 20px;
              }
            }
            .trend_div{
              .trend_point{
                display: flex;
                align-items: center;
                gap: 20px;
                padding-bottom: 50px;
                @media (max-width: $sm){
                  padding-bottom: 15px;
                }
                .trend_name{
                  color: #011055;
                  font-size: 40px;
                  font-style: normal;
                  font-weight: 900;
                  line-height: 120%; /* 48px */
                  text-transform: uppercase;
                  @media (max-width: $sm){
                    font-size: 12px;
                  }
                }
                .trend_desc{
                  font-family: "Area Variable";
                  font-size: 50px;
                  font-style: normal;
                  font-weight: 900;
                  line-height: 120%;
                  background: linear-gradient(31deg, #001055 12.54%, #286DF0 86.69%);
                  background-clip: text; 
                  -webkit-background-clip: text;
                  -webkit-text-fill-color: transparent;
                  @media (max-width: $sm){
                    font-size: 16px;
                  }
                }
              }
            }
          }
        }
        .marketing_slider{
            .slides1{
              background: #DFD8FF;
            }
            .slides2{
              background: #957DFB;
              padding: 30px 70px !important;
              @media (max-width: $sm){
                padding: 15px !important;
              }
            }
            .percent{
              font-family: "Area Variable";
              font-size: 210px;
              font-style: normal;
              font-weight: 900;
              line-height: 110%;
              letter-spacing: -4.2px;
              padding-top: 20px;
              background: radial-gradient(79.93% 79.93% at 87.12% 41.59%, #957DFB 0%, #5237E6 100%);
              background-clip: text;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              @media (max-width: $sm){
                font-size: 64px;
                letter-spacing: -1.289px;
                padding-top: 0;
              }
            }
            .percent_desc{
              color: #957DFB;
              font-family: "Area Variable";
              font-size: 70px;
              font-style: normal;
              font-weight: 900;
              line-height: 120%;
              letter-spacing: -0.7px;
              @media (max-width: $sm){
                font-size: 21px;
                letter-spacing: -0.215px;
              }
            }
            .marketingFlex{
              display: flex;
              align-items: flex-start;
              gap: 200px;
              // justify-content: space-between;
              @media (max-width: $sm){
                gap: 42px;
              }
              .marketing1{
                padding: 20px 0 0;
                @media (max-width: $sm){
                  padding: 0;
                }
                .marketing_title{
                  color: #5237E6;
                  font-size: 40px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 120%;
                  letter-spacing: -0.4px;
                  padding-bottom: 20px;
                  @media (max-width: $sm){
                    font-size: 12px;
                    letter-spacing: -0.123px;
                  }
                }
                .marketing_desc{
                  color: #DFD8FF;
                  font-size: 70px;
                  font-style: normal;
                  font-weight: 900;
                  line-height: 110%;
                  letter-spacing: -0.7px;
                  @media (max-width: $sm){
                    font-size: 21px;
                    letter-spacing: -0.215px;
                  }
                }
              }
              .marketing2{
                img{
                  width: 420px;
                  @media (max-width: $md){
                    width: 190px;
                  }
                  @media (max-width: $xs){
                    width: unset;
                  }
                }
              }
            }
        }
        .technology_slider{
            .slides1{
              padding: 100px 70px 70px;
              background: #FFD48D;
              @media (max-width: $sm){
                padding: 30px 15px 15px;
              }
              .technology1{
                  display: flex;
                  align-items: baseline;
                  justify-content: flex-start;
                  gap: 70px;
                  @media (max-width: $sm){
                    gap:20px;
                  }
                .tech_number{
                  font-size: 210px;
                  font-style: normal;
                  font-weight: 900;
                  line-height: 110%;
                  letter-spacing: -4.2px;
                  background: radial-gradient(79.93% 79.93% at 87.12% 41.59%, #FFB02C 0%, #B66504 100%);
                  background-clip: text;
                  -webkit-background-clip: text;
                  -webkit-text-fill-color: transparent;
                  @media (max-width: $sm){
                    font-size: 64px;
                    letter-spacing: -1.289px;
                  }
                }
              }
              .technology2{
                .tech_desc{
                  color: #AC6C00;
                  font-size: 70px;
                  font-style: normal;
                  font-weight: 900;
                  line-height: 110%;
                  letter-spacing: -0.7px;
                  @media (max-width: $sm){
                    font-size: 21px;
                    letter-spacing: -0.215px;
                  }
                }
              }
            }
            .slides2{
              background: #EDA42A;
              padding: 120px 70px 70px;
              @media (max-width: $sm){
                padding: 45px 15px 15px;
              }
              .percent{
                background: radial-gradient(79.93% 79.93% at 87.12% 41.59%, #FDF2DF 0%, #FFD287 100%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-size: 210px;
                font-style: normal;
                font-weight: 900;
                line-height: 110%;
                letter-spacing: -4.2px;
                @media (max-width: $sm){
                  font-size: 64px;
                  letter-spacing: -1.289px;
                }
              }
              .percent_desc{
                color: #FFE3B4;
                font-size: 70px;
                font-style: normal;
                font-weight: 900;
                line-height: 120%;
                letter-spacing: -0.7px;
                @media (max-width: $sm){
                  font-size: 21px;
                  letter-spacing: -0.215px;
                }
              }
              .techcircle {
                position: absolute;
                top: 0;
                right: 0;
              }
            }
        }
        .loyalty_slider{
          .slides1{
            background: #FFCFD8;
            padding: 10px 70px !important;
            @media (max-width: $sm){
              padding: 15px !important;
            }
            .loyaltyFlex{
              display: flex;
              align-items: center;
              justify-content: space-between;
              .loyalty1{
                .loyalty_title{
                  color: #70000B;
                  font-size: 60px;
                  font-style: normal;
                  font-weight: 900;
                  line-height: 120%; /* 72px */
                  letter-spacing: -0.6px;
                  padding-bottom: 20px;
                  @media (max-width: $sm){
                    font-size: 18px;
                    letter-spacing: -0.184px;
                  }
                }
                .loyalty_desc{
                  font-size: 200px;
                  font-style: normal;
                  font-weight: 900;
                  line-height: 110%; /* 220px */
                  letter-spacing: -2px;
                  background: radial-gradient(79.93% 79.93% at 87.12% 41.59%, #EF4866 0%, #8B0010 100%);
                  background-clip: text;
                  -webkit-background-clip: text;
                  -webkit-text-fill-color: transparent;
                  @media (max-width: $sm){
                    font-size: 61.378px;
                    letter-spacing: -0.614px;
                  }
                }
              }
              .loyalty2{
                img{
                  position: relative;
                  top: -35px;
                  @media (max-width: $sm){
                    top:-13px;
                  }
                }
              }
            }
          }
          .slides2{
            background: #C22A40;
            padding: 17px 70px !important;
            @media (max-width: $sm){
              padding: 8px 15px !important;
            }
            .loyaltyFlex{
              display: flex;
              align-items: center;
              justify-content: space-between;
              .loyalty1{
                .loyalty_title{
                  color: #FFCFD8;
                  font-size: 70px;
                  font-style: normal;
                  font-weight: 900;
                  line-height: 120%; /* 72px */
                  letter-spacing: -0.7px;
                  @media (max-width: $sm){
                    font-size: 21px;
                    letter-spacing: -0.215px;
                  }
                }
              }
            }
          }
        }
        .feedback_slider{
          .slides1{
            background: #A7FFAF;
            padding: 40px 70px !important;
            @media (max-width: $sm){
              padding: 15px !important;
            }
            .technology1{
              display: flex;
              align-items: baseline;
              gap: 30px;
                .tech_number{
                  font-size: 208.084px;
                  font-style: normal;
                  font-weight: 900;
                  line-height: 110%; /* 228.893px */
                  letter-spacing: -4.162px;
                  background: radial-gradient(79.93% 79.93% at 87.12% 41.59%, #079B15 0%, #0D5614 100%);
                  background-clip: text;
                  -webkit-background-clip: text;
                  -webkit-text-fill-color: transparent;
                  @media (max-width: $sm){
                    font-size: 63.886px;
                    letter-spacing: -1.278px;
                  }
                }
          }
          .technology2{
            .tech_desc{
              color: #41BE4C;
              font-size: 70px;
              font-style: normal;
              font-weight: 900;
              line-height: 110%; /* 77px */
              letter-spacing: -0.7px;
              @media (max-width: $sm){
                font-size: 21.491px;
                letter-spacing: -0.215px;
              }
            }
          }
          }
          .slides2{
            background: #40BD4B;
            padding: 30px 70px !important;
            @media (max-width: $sm){
              padding: 15px !important;
            }
            .loyaltyFlex{
              display: flex;
              align-items: center;
              justify-content: space-between;
              .loyalty1{
                .loyalty_title{
                  color: #BDFFC3;
                  font-size: 70px;
                  font-style: normal;
                  font-weight: 900;
                  line-height: 120%; /* 84px */
                  letter-spacing: -0.7px;
                  @media (max-width: $sm){
                    font-size: 19.491px;
                    letter-spacing: -0.215px;
                  }
                }
              }
            }
          }
        }
      }
    }
    .reportFold1{
      .report_title{
        span{
          font-size: 96px;
          font-style: normal;
          font-weight: 900;
          line-height: 121%;
          letter-spacing: -0.96px;
          @media (max-width: $sm){
            font-size: 30px;
          }
        }
      }
      .quoteFlex{
        display: flex;
        align-items: flex-start;
        padding: 10px 0;
        justify-content: flex-start;
        gap: 20px;
        @media (max-width: $sm){
          display: block;
        }
        .quoteFlexLeft{
          min-width: 600px;
          @media (max-width: $sm){
            min-width: 100%;
          }
       }
        .quoteFlexRight{
          min-width: 596px;
          padding: 110px 0 0;
          @media (max-width: $sm){
            min-width: 100%;
            padding: 0;
          }
        }
      }
      
      .quotes{
          border-radius: 20px;
          padding:30px;
          box-shadow: -1px 4px 10.1px 0px rgba(0, 45, 50, 0.10);
          backdrop-filter: blur(18.157896041870117px);
          display: flex;
          align-items: flex-start;
          gap: 30px;
          margin: 20px 0;
          position: relative;
          @media (max-width: $sm){
            display: block;
            padding: 18px;
          }
          .basilio{
            min-width: 100px !important;
            .quotesimg{
              left: 75px;
            }
          }
          .quotes1{
            min-width: 81px;
            .quotesimg{
              position: absolute;
              top: 13px;
              left: 55px;
              @media (max-width: $sm){
                top:36px;
                left: 18px;
              }
            }
            .quoteslogo{
              position: absolute;
              left: 30px;
              bottom: 30px;
              @media (max-width: $sm){
                position: unset;
                display: flex;
                flex-direction: row-reverse;
                align-items: center;
                gap: 10px;
                padding-bottom: 20px;
                img{
                  width: 54px;
                }
              }
              p{
                color: #170F49;
                font-feature-settings: 'liga' off, 'clig' off;
                font-family: "Area Variable";
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: 20px;
                padding: 5px 0 0;
                @media (max-width: $sm){
                  font-size: 16px;
                }
              }
            }
          }
          .quotes2{
            p{
              color: #303030;
              font-feature-settings: 'liga' off, 'clig' off;
              font-family: "Area Normal";
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 150%; /* 24px */
            }
          }
      }
      .quote1{
        background: #FFFCCE;
      }
      .quote2{
        background: #DEFFE0;
      }
      .quote3{
        background: #FFE2E2;
      }
      .quote4{
        background: #FFECDA;
      }
    }
  }
  .resources_logo{
    padding: 230px 0 70px;
    @media (max-width: $sm){
      padding: 40px 0 70px;
    }
    .resource_title{
      color: #023342;
      font-family: "Area Variable";
      font-size: 40px;
      font-style: normal;
      font-weight: 900;
      line-height: 110%; /* 44px */
      letter-spacing: -0.4px;
      @media (max-width: $sm){
        font-size: 20px;
      }
    }
    .logo-container {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      gap: 18px;
      margin: 40px auto;
      @media (max-width: $sm){
        grid-template-columns: repeat(3, 1fr);
      }
      .logo-item {
          text-align: center;
          display: none;
      }     
    }
    #see-more-btn {
      display: block;
      margin: 0px auto;
      color: #0D9298;
      font-feature-settings: 'liga' off, 'clig' off;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px; /* 100% */
      border: none;
      background: none;
      cursor: pointer;
      &.hidden {
          display: none;
      }
 }
  }
  .lastsection{
    border-radius: 24px;
    padding: 40px 20px;
    @media (max-width: $sm){
      margin: 0 20px;
    }
  }
}
.testimonials{
    .success_stories{
      gap: 40px;
      display: flex;
      margin: 0 auto;
      align-items: center;
      justify-content: center;
      @media (max-width: $sm){
        flex-wrap: wrap;
      }
      .success_stories_left{
        max-width: 627px;
        padding: 90px 0 90px 84px;
        @media (max-width: $sm){
          padding: 40px 0 0;
          text-align: center;
        }
        .sec_title{
          font-size: 64px;
          color: #0e1b1b;
          line-height: 68px;
          margin: 16px 0 20px;
          letter-spacing: -.01em;
          font-weight: 900;
          font-family: $basierMedium;
          @media (max-width: $sm){
            margin: 12px 0;
            font-size: 28px;
            line-height: 32px;
          }
        }
        .sec_desc{
          font-size: 20px;
          margin: 0 0 40px;
          color: #374040;
          line-height: 30px;
          @media (max-width: $sm){
            font-size: 14px;
            margin: 0 0 24px;
            line-height: 19px;
          }
        }
      }
      .success_stories_right{
        width: 100%;
        max-width: 790px;
        .parent_Img{
          width: 100%;
          height: 100%;
          position: relative;
          user-select: none;
          @media (max-width: $sm){
            overflow-x: auto;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            scrollbar-width: none;
            scroll-snap-type: x mandatory;
          }
          img{
            @media (max-width: $sm){
              margin: 0 5px;
              min-width: 234px;
            }
          }
        }
      }
    }
    .testimonial_listing{
      .common_title{
        font-size: 56px;
        margin: 0 0 60px;
        line-height: 64px;
        text-align: center;
        font-family: $basierMedium;
        font-weight: 900;
        @media (max-width: $sm){
          margin: 12px 0;
          font-size: 28px;
          line-height: 32px;
        }
      }
      .reelo_testimonials{
        .middle-slide{
          margin: 40px 12px 0 !important;
          @media (max-width: $sm){
            margin: 0 !important;
          }
        }
        .testimonialsBox{
          padding: 24px;
          position: relative;
          border-radius: 16px;
          background-size: cover;
          background-position: 50%;
          background-repeat: no-repeat;
          background-image: url('../images/bg-shape.png');
          max-width: calc(33% - 20px);
          margin: 0 12px 55px;
          height: 580px;
          @media (max-width: $sm){
            margin: 25px 0 !important;
            max-width: 100%;
            width: 100%;
            margin: 0 0 30px;
            height: 515px;
          }
          &:hover{
            .testimonials-box-wrapper{
              .testimonials_Img{
                .video-thumbnail {
                  .play-icon-wrapper {
                    transform: translate(-50%,-50%) scale(1.2);
                  }
                }
              }
            }
          }
          .testimonials-box-wrapper{
            .testimonials_Img{
              .video-thumbnail {
                .videoimg{
                  height: 357px;
                  border-radius: 16px;
                  @media (max-width: $sm){
                    height: 294px;
                  }
                }
                .play-icon-wrapper {
                  position: absolute;
                  top: 50%;
                  transform: translate(-50%,-50%) scale(1);
                  left: 50%;
                  cursor: pointer;
                  transition: all .5s;
                  width: 48px;
                  height: 48px;
                  margin: 0 auto;
                }
              }
              iframe{
                height: 357px;
                border: none;
                outline:none;
                border-radius: 24px;
                @media (max-width: $sm){
                 height: 294px;
                }
              }
            }
            .testimonial-title{
              font-size: 28px;
              margin: 24px 0 0;
              color: #01292b;
              line-height: 34px;
              letter-spacing: -.01em;
              font-family: $basierSemibold;
              @media (max-width: $sm){
                font-size: 24px;
                line-height: 34px;
              }
            }
          }
        }
      }
    }
    .testimonial_review_listing{
      .common_title{
        font-size: 56px;
        margin: 0 0 60px;
        line-height: 64px;
        text-align: center;
        font-family: $basierMedium;
        font-weight: 900;
        text-align: left;
        @media (max-width: $sm){
          margin: 12px 0;
          font-size: 28px;
          line-height: 32px;
          text-align: center;
        }
      }
      .testiFlex{
        gap: 35px;
        width: auto;
        display: flex;
        @media (max-width: $sm){
          display: block;
          width: 100%;
        }
        .parent_testi{
          width: 25%;
          @media (max-width: $sm){
            display: none;
          }
        }
        .testimonial_review{
          padding: 16px;
          border-radius: 12px;
          border: 1px solid #e3e3e3;
          box-shadow: 0 2px 1px rgba(0, 0, 0, .05), 0 0 1px rgba(0, 0, 0, .25);
          margin: 0 0 35px;
          .review_detail{
            gap: 8px;
            margin: 0 0 16px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .reviewer_name{
              margin: 0;
              font-size: 16px;
              color: #0e1b1b;
              line-height: 22px;
              font-family: $basierSemibold;
            }
            .reviewer_post{
              margin: 0;
              font-size: 14px;
              color: #374040;
              line-height: 20px;
            }
          }
          .review_desc{
            margin: 0;
            font-size: 16px;
            line-height: 22px;
          }
        }
        .testimonial_mobile{
          @media (max-width: $sm){
            margin: 30px 0 0;
            .testimonial_review{
              margin: 0 5px;
              height: 420px;
            }
          }
        }
      }
    }
    .lastsection{
      border-radius: 24px;
      padding: 40px 20px;
      @media (max-width: $sm){
        margin: 0 20px;
      }
    }
}
.affiliate-program{
  .banner{
    height: 100vh;
    @media (max-width: $sm){
      height: unset;
    }
    .tagline_wrapper{
      // position: absolute;
      // top:50%;
      // left: 50%;
      // transform: translate(-50%, -50%);
      // width: 100%;
      text-align: center;
      padding: 30px 0 0;
      @media (max-width: $sm){
        padding: 50px 0 0;
      }
      .common_subtitle{
        font-size: 20px;
        color: #616d6d;
        line-height: 25px;
        @media (max-width: $sm){
          font-size: 14px;
          line-height: 18px;
        }
      }
      .banner_heading{
        .common_title{
          margin: 0;
          font-size: 48px;
          color: #182020;
          line-height: 64px;
          font-family: $basierSemibold;
          position: relative;
          display: inline-block;
          margin: 20px 0;
          @media (max-width: $sm){
            font-size: 28px;
            line-height: 36px;
          }
          span{
            color: #8e44ad;
          }
          .fill1{
            position: absolute;
            left: -20px;
            top: -24px;       
            @media (max-width: $sm){
              width: 64px;
            } 
          }
          .fill2{
            position: absolute;
            right: -20px;
            top: -24px;
            @media (max-width: $sm){
              width: 64px;
            }
          }
        }
      }
      .btn_wrapper{
        display: inline-block;
        margin: 30px 0 0;
        @media (max-width: $sm){
          margin: 30px 0 60px;
        }
        .fill3{
          position: absolute;
          left: -72px;
          top: -34px;
        }
        .fill4{
          position: absolute;
          right: -72px;
          top: -18px;
        }
        .black-btn {
          padding: 20px 94px;
          font-size: 22px !important;
          @media (max-width: $sm){
            padding: 12px 30px;
            font-size: 16px !important;
          }
        }
      }
    }
  }
  .sec_title{
    font-size: 40px;
    color: #182020;
    margin: 0 0 48px;
    line-height: 50px;
    text-align: center;
    @media (max-width: $sm){
      font-size: 20px;
      line-height: 25px;
      margin: 0 0 20px;
    }
  }
  .range_wrapper{
    padding: 200px 0 70px;
    @media (max-width: $sm){
      padding: 40px 0;
    }
    .affiliate_span1{
      font-size: 20px;
      color: #616d6d;
      line-height: 25px;
      @media (max-width: $sm){
        font-size: 14px;
        line-height: 18px;
      }
    }
    .output{
      padding: 0 20px;
      font-size: 48px;
      color: #182020;
      line-height: 60px;
      text-align: center;
      margin: 8px auto 40px;
      @media (max-width: $sm){
        font-size: 32px;
        line-height: 40px;
      }
    }
    .affiliate_range_slider_div{
      margin: 0 auto;
      padding: 0 40px;
      max-width: 1120px;
      .custom_range_slider{
        position: relative; 
        width: 100%; 
        height: 20px;
        .custom_range_slider_input {
          width: 100%; 
          opacity: 0; 
          position: absolute; 
          top: 0; 
          left: 0; 
          z-index: 2; 
        }
        .custom_range_slider_track{
          height: 10px; 
          border-radius: 5px; 
          position: relative; 
          width: 100%;
          .custom_range_slider_thumb {
            width: 48px;
            height: 48px;
            padding: 4px;
            border-radius: 50%;
            background-color: #fff;
            border: 4px solid #1a424f;
          }
        }
      }
      .affiliate_track_numbers{
        margin-top: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        span{
          font-size: 24px;
          line-height: 30px;
          color: #182020;
        }
      }
    }

  }
  .affiliate_how_it_works{
    background: #d4e9f7;
    .affiliate_how_it_works_cards{
      gap: 40px;
      flex-wrap: wrap;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: $sm){
        gap: 25px;
      }
      .affiliate_how_it_works_card{
        padding: 32px 30px;
        max-width: 346px;
        border-radius: 12px;
        background: #fff;  
        .card_header{
          margin: 24px 0;
          font-size: 24px;
          color: #182020;
          line-height: 30px;
          font-weight: 600;
          @media (max-width: $sm){
            font-size: 20px;
            line-height: 25px;
          }
        }
        .card_subheader{
          margin: 0;
          font-size: 20px;
          color: #616d6d;
          line-height: 25px;
          @media (max-width: $sm){
            font-size: 16px;
            line-height: 20px;
          }
        }
      }
    }
  }
  .affiliate_why_reelo{
    .affiliate_reviews{
      gap: 40px;
      flex-wrap: wrap;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: $sm){
        gap: 16px;
        overflow-x: auto;
        flex-wrap: nowrap;
        align-items: flex-start;
        justify-content: flex-start;
        scroll-snap-type: x mandatory;
      }
      .affiliate_review_div{
        width: 346px;
        height: 380px;
        padding: 32px;
        min-width: 240px;
        border-radius: 12px;
        background: #fafafa;
        @media (max-width: $sm){
          height: 270px;
          padding: 20px;
        }
        .card_review{
          margin: 24px 0;
          font-size: 24px;
          color: #182020;
          line-height: 28px;
          font-weight: 400;
          @media (max-width: $sm){
            font-size: 16px;
            line-height: 18px;
          }
        }
        .card_reviewer{
          margin: 0;
          font-size: 16px;
          color: #182020;
          line-height: 20px;
          @media (max-width: $sm){
            font-size: 14px;
            line-height: 18px;
          }
          span{
            color: #616d6d;
          }
        }
      }
    }
  }
  .resources_brand{
    .marketingPlaybook_brands{
      gap: 56px;
      flex-wrap: wrap;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 40px;
    }
  }
}
.demo{
  .display_table{
    flex-direction: unset;
  }
  .banner{
    // height: 100vh;
    padding: 100px 0 70px;
    @media (max-width: $sm){
      height: unset;
      padding: 90px 0 0;
    }
    .demo_right_fold{
      width: 100%;
      padding: 24px;
      max-width: 525px;
      min-width: 525px;
      min-height: 544px;
      border-radius: 12px;
      background: #f7f8f8;
      margin: 0 auto;
      @media (max-width: $sm){
        padding: 16px;
        min-width: unset;
        border-radius: 8px;
        margin: 20px auto 40px;
      }
      p{
        color: $black;
        font-size: 18px;
        margin: 0 0 24px;
        line-height: 28px;
        letter-spacing: -.18px;
        font-family: $basierMedium;
        @media (max-width: $sm){
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
  }
  .sec_title{
    font-size: 64px;
    line-height: 62px;
    text-align: center;
    margin: 0 auto 24px;
    letter-spacing: -2.56px;
    font-family: $basierMedium;
    color: #0e1b1b;
    font-weight: 500;
    @media (max-width:$sm) {
      font-size: 32px;
      line-height: 40px;
      text-align: center;
      margin-bottom: 18px;
      letter-spacing: -1.2px;
    }
   }
   .sec_desc{
    font-size: 18px;
    color: #697777;
    line-height: 26px;
    text-align: center;
    margin: 0 auto 32px;
    letter-spacing: -.18px;
    font-family: $basier;
    @media (max-width:$sm) {
      font-size: 16px;
      margin: 0 0 16px;
      line-height: 20px;
      text-align: center;
    }
   }
  .common_title{
    font-size: 64px;
    color: #182020;
    line-height: 65px;
    margin-bottom: 18px;
    letter-spacing: -2.4px;
    font-family: $basier;
    @media (max-width:$sm) {
      font-size: 32px;
      line-height: 40px;
      text-align: center;
      margin-bottom: 18px;
      letter-spacing: -1.2px;
    }
  }
  .common_desc{
    margin: 0;
    font-size: 18px;
    color: #697777;
    line-height: 28px;
    letter-spacing: -.18px;
    font-family: $basier;
    @media (max-width:$sm) {
      font-size: 16px;
      margin: 0 0 16px;
      line-height: 20px;
      text-align: center;
    }
  }
  .fold2{
    background-color: #f8f8f8;
    .tagline_wraper{
      &:hover{
        .quotes_Img {
          .video-thumbnail {
            .play-icon-wrapper{
              transform: translate(-50%, -50%) scale(1.2);
              box-shadow: 0 20px 30px -10px transparent;
            }
          }
        }
      }
      .quotes_Img {
        margin: 50px 0 0;
        .video-thumbnail {
          .top-gradient {
            top: 0;
            left: 0;
            right: 0;
            z-index: 2;
            width: 100%;
            height: 80px;
            padding: 24px;
            position: absolute;
            border-radius: 16px 16px 0 0;
            background: linear-gradient(180deg, rgba(0, 0, 0, .6), transparent);
            p{
              z-index: 1;
              font-size: 18px;
              text-align: center;
              line-height: 22px;
              overflow: hidden;
              white-space: nowrap;
            }
           }
           .videoimg{
            border-radius: 24px;
           }
          .play-icon-wrapper {
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%) scale(1);
            left: 50%;
            cursor: pointer;
            transition: all .3s;
          }
        }
        iframe{
          height: 709px;
          border: none;
          outline:none;
          border-radius: 24px;
          @media (max-width:$sm) {
            height: 189px;
          }
        }
      }
    }
  }
  .fold3{
    .demo_videos_row{
      gap: 24px;
      width: 100%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 60px 0 20px;
      @media (max-width:$sm){
        gap: 16px;
        padding: 0 16px;
        overflow-x: auto;
        justify-content: flex-start;
        scrollbar-width: none;
        scroll-snap-type: x mandatory;
      }
      .demo_video_col{
        width: 100%;
        height: 100%;
        position: relative;
        @media (max-width:$sm){
          min-width: 250px;
          scroll-snap-align: center;
        }
        &:hover{
            .video-thumbnail {
              .play-icon-wrapper{
                transform: translate(-50%, -50%) scale(1.2);
                box-shadow: 0 20px 30px -10px transparent;
              }
            }
        }
        .video-thumbnail {
           .videoimg{
            border-radius: 24px;
           }
          .play-icon-wrapper {
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%) scale(1);
            left: 50%;
            cursor: pointer;
            transition: all .3s;
          }
        }
        iframe{
          height: 528px;
          border: none;
          outline:none;
          position: relative;
          border-radius: 12px;
          @media (max-width:$sm){
            height: 444px;
          }
        }
      }
    }
  }
}
.loyalty-inspiration{
  .banner{
    padding: 50px 20px 0;
    .common_title{
      color: #354545;
      font-weight: 600;
      margin: 70px 0 30px;
      font-size: 56px;
      line-height: 62px;
      letter-spacing: -.01em;
      font-family: $basierMedium;
      @media (max-width: $sm){
        margin: 40px auto 20px;
        font-size: 32px;
        line-height: 42px;
      }
    }
  }
  
  .loyaltyDropdown{
    height: 40px;
    color: #a8b4b4;
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid #a8b4b4;
    width: 96%;
    font-size: 15px;
    option{
      color: $black !important;
      background-color: #ebebeb;
      font-size: 16px;
      line-height: 32px;
      font-family: $basier;
      margin: 10px 0;
      &:hover {
        color: $black;
       }
    }
  }
  .inspiration_wrapper{
    margin: 60px 0 0;
    .my-masonry-grid{
      display: flex;
      flex-wrap: wrap;
      margin-left: -30px;
      width: auto;
      @media (max-width:$sm) {
        flex-direction: column;
      }
      .my-masonry-grid_column>div {
        margin-bottom: 30px;
      }
      .my-masonry-grid_column{
        padding-left: 30px;
        background-clip: padding-box;
        width: 33.3333%;
        @media (max-width:$sm) {
          width: 100%;
        }
        .root-loyalty-card{
          filter: drop-shadow(5px 6px 20px rgba(96, 96, 96, .31));
          .final-preview-card{
            max-width: 414px;
            height: max-content;
            padding-bottom: 25px;
            border-radius: 15px;
            position: relative;
            margin: 0 auto;
            @media (max-width:$sm) {
              width: 100%;
            }
            .card-header {
              top: 0;
              right: 0;
              left: 0;
              width: 100%;
              height: 250px;
              padding: 0;
              overflow: hidden;
              position: relative;
              border-radius: 15px;
              -webkit-clip-path: ellipse(67% 88% at 81% 1%);
              clip-path: ellipse(67% 88% at 81% 1%);
              .react-transform-wrapper{
                width: 100%;
                height: 250px;
                .transform-component-module_content{
                  display: flex;
                  flex-wrap: wrap;
                  width: fit-content;
                  height: fit-content;
                  margin: 0;
                  padding: 0;
                  transform-origin: 0% 0%;
                  img{
                    width: 100%;
                    margin: 0 0 0 auto;
                    pointer-events: none;
                  }
                }
              }
            }
            .card-logo-div{
              top: 100px;
              left: 45px;
              z-index: 10;
              width: 124px;
              height: 124px;
              padding: 2.4px;
              position: absolute;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              .card-logo-image{
                width: 100%;
                height: 100%;
                border-radius: 50%;
                object-fit: cover;
              }
              .card-logo-name{
                font-size: 14px;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0;
                text-align: center;
                text-transform: uppercase;
                letter-spacing: .1em;
               }
            }
            .card-content{
              padding: 0 25px;
              h3{
                padding: 0 25px;
                font-size: 36px;
                line-height: 40px;
                @media (max-width:$sm) {
                  font-size: 26px;
                  line-height: 30px;
                }
              }
              h4{
                padding: 0 25px;
                font-size: 18px;
                line-height: 22px;
                @media (max-width:$sm) {
                  font-size: 16px;
                  line-height: 20px;
                }
              }
              h5{
                padding: 0 25px;
                font-size: 24px;
                line-height: 20px;
                margin-top: 20px;
                margin-bottom: 30px;
                position: relative;
                @media (max-width:$sm) {
                  font-size: 16px;
                  line-height: 20px;
                }
              }
              .cashback-content{
                padding: 0 25px;
                position: relative;
                p{
                  font-size: 16px;
                  line-height: 20px;
                  margin: 16px 0 0;
                }
                h2{
                  margin: 0;
                  font-size: 64px;
                  line-height: 70px;
                  text-align: left;
                  font-weight: 600;
                }
                h3{
                  padding: 0;
                  margin: 0 0 4px;
                  font-size: 24px;
                  line-height: 30px;
                  font-weight: 400;
                }
                h5{
                  margin: 0;
                  padding: 0;
                  font-size: 14px;
                  line-height: 17px;
                  font-weight: 500;
                }
              }
              .reward-row:last-child {
                  border: none;
              }
              .reward-row{
                display: flex;
                margin-bottom: 10px;
                padding-bottom: 10px;
                align-items: center;
                position: relative;
                border-bottom: 1px dashed #fff;
                h4{
                  font-size: 32px;
                  line-height: 32px;
                  min-width: 150px;
                  max-width: 150px;
                  margin: 0;
                  font-weight: 700;
                  span{
                    font-size: 14px;
                  }
                }
                p{
                  text-align: left;
                  padding-left: 10px;
                  font-size: 16px;
                }
              }
            }
            .preview-footer{
              margin: 15px 25px 0;
              padding: 13px 15px;
              border-radius: 10px;
              position: relative;
              border: 1px dashed #fff;
              h3{
                font-size: 10px;
                line-height: 12.5px;
              }
              p{
                margin: 0;
                font-size: 10px;
                line-height: 12.5px;
              }
            }
          }
          .cashback-terms{
            padding: 25px;
            margin: 0 auto;
            max-width: 414px;
            position: relative;
            border-radius: 15px;
            background-color: $white;
            h4{
              font-size: 16px;
              color: #545454;
              line-height: 20px;
              margin: 0 16px 16px;
            }
            .preview-footer{
              padding: 13px 15px;
              border-radius: 10px;
              position: relative;
              border: 1px dashed #354545;
              h3{
                font-size: 10px;
                line-height: 12.5px;
                margin-bottom: .5rem;
                font-weight: 600;
              }
              p{
                margin: 0;
                font-size: 10px;
                line-height: 12.5px;
              }
            }
          }
        }
      }
    }
  }
  .fold2{
    .inspiration_flexrow{
      margin: 0 auto;
      max-width: 1024px;
      padding: 0 20px 50px;
      @media (max-width:$sm){
        padding: 0;
      }
      .inspiration_custom-row{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 60px;
        @media (max-width:$sm){
          flex-direction: column;
        }
        .inspiration_custom-col{
          .inspiration_custom-col_left{
            max-width: 500px;
            h3{
              font-size: 24px;
              color: #354545;
              line-height: 31px;
              margin: 40px 0 20px;
              font-weight: 600;
            }
            ul{
                padding-left: 20px;
              li{
                font-size: 16px;
                color: #354545;
                line-height: 24px;
                font-weight: 400;
                padding: 0 0 12px;
              }
            }
          }
          .inspiration_custom-col_right{
            max-width: 500px;
            h3{
              font-size: 24px;
              color: #354545;
              line-height: 31px;
              margin: 40px 0 20px;
              font-weight: 600;
            }
            p{
              font-size: 16px;
              color: #354545;
              line-height: 24px;
              font-weight: 400;
              padding: 0 0 12px;
            }
          }
        }
      }
      .row_2{
        @media (max-width:$sm){
          flex-direction: column-reverse;
        }
      }
    }
  }
  #lastsection {
    @media (max-width:$sm) {
       height: 322px;
    }  
  }
}
.faq{
  .sec_title{
    font-size: 42px;
    line-height: 51px;
    letter-spacing: -.02em;
    font-weight: 500;
    font-family: $basierMedium;
    margin-bottom: 50px;
    @media (max-width:$sm) {
      margin-bottom: 25px;
      font-size: 24px;
        line-height: 28px;
    }
  }
  .set {
    position: relative;
    width: 100%;
    height: auto;
    margin:auto;
  }
  .set a {
      border-radius: 6px;
      display: block;
      padding: 16px;
      text-decoration: none;
      transition: all 0.2s linear;
      margin: 10px 0 0;
      font-size: 16px;
      color: #182020;
      line-height: 19px;
      background: #fafafa;
      font-weight: 600;
      position: relative;
      height: auto;
  }
  
  .set a i {
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 100%;
    transition: transform 0.2s ease-in-out;
    @media (max-width: $sm){
      right: 18px;
    }
  }
  
  .set a .icon-minus:after {
    width: 20px;
    height: 20px;
    background-image: url('../images/down.svg');
    background-repeat: no-repeat;
    top: 8px;
    position: absolute;
    content: '';
    top: 50%;
    transform: translate(-50%, -50%) rotate(180deg);
    text-align: center;
    transition: transform 0.2s ease-in-out;
    left: 50%;
  }
  .set a .icon-plus:before{
        position: absolute;
        content: '';
        top: 50%;
        transform: translate(-50%,-50%);
        text-align: center;
        left: 50%;
        background-image: url('../images/down.svg');
        background-repeat: no-repeat;
        width: 20px;
        height: 20px;
        transition: transform 0.2s ease-in-out;
  }
  .set .faqs_content {
    background-color: #fafafa;
    border-top: 1px solid #8080802e;
    padding: 20px;
    a{
      display: inline-block;
      padding: 0;
      margin: 0;
    }
    ul{
      padding: 10px 20px;
      li{
        padding: 0 0 6px;
        a{
          display: inline-block;
          padding: 0;
        }
      }
    }
    ol{
      padding: 10px 20px;
      li{
        padding: 0 0 6px;
        a{
          display: inline-block;
          padding: 0;
        }
      }
    }
  }
}

.webinar-registration{
  .today{
    padding: 90px 0 40px;
    @media (max-width: $sm){
      padding: 90px 0 0;
    }
  }
  .loyalty_first_fold{
    gap: 24px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    @media (max-width: $sm){
      flex-wrap: wrap;
    }
    .loyalty_left_side{
      max-width: 620px;
      margin: -10px 0 0;
      @media (max-width: $sm){
        margin: 0;
      }
      .speakerFlex {
        display: flex;
        align-items: center;
        gap: 30px;
        margin: 37px 0;
        @media (max-width: $sm){
          flex-wrap: wrap;
        }
        .speaker_content {
          display: flex;
          align-items: flex-start;
          gap: 20px;
          position: relative;
          .speakerImg{
            border-radius: 6px;
            background: #C7E1FF;
          }
          .speakers{
            .speaker_name{
              font-size: 20px;
              line-height: 26px;
              font-weight: 600;
            }
            .speaker_dir{
              font-size: 16px;
              line-height: 18px;
            }
            .speaker_desig{
              font-size: 10px;
              line-height: 16px;
              display: inline-block;
              padding: 4px 8px;
              border-radius: 90px;
              position: absolute;
              bottom: 0;
            }
          }
        }
      }
      .webinar_patch {
        border-radius: 6px;
        display: flex;
        background: #DFF;
        align-items: center;
        justify-content: space-around;
        @media (max-width: $sm){
          flex-wrap: wrap;
          padding: 12px 0;
        }
         span{
          font-family: $basierMedium;
          font-size: 20px;
          font-weight: 500;
          line-height: 50px;
          margin: 0 20px;
          color: #023342;
          @media (max-width: $sm){
            font-size: 16px;
            line-height: 24px;
            margin: 0;
            display: block;
          }
          img{
            position: relative;
            top: 3px;
            width: 22px;
          }
         }
      }
      .agenda {
        padding-left: 20px;
        li {
          padding: 0 0 4px; 
          color: #374040;
          font-size: 18px;
          line-height: 28px;
          @media (max-width: $sm){
            font-size: 14px;
            line-height: 22px;
          }
        }
      }
    }
    .loyalty_right_side{
      width: 100%;
      max-width: 571px;
      .hurry{
        background: #BEF8F8;
        border-radius: 24px;
        padding: 23px 30px;
        @media (max-width: $sm){
          padding: 20px;
          margin: 0;
        }
        .sec_title{
          font-size: 28px;
          line-height: 38px;
          letter-spacing: -.02em;
          font-weight: 500;
          font-family: $basierSemibold;
          margin:0;
        }
      }
    }
  }
  .display_table{
    flex-direction: unset;
  }
  .banner{
    padding: 0 0 80px;
    @media (max-width: $sm){
      height: unset;
      padding: 20px 0 40px;
      .common_title{
        font-size: 28px;
        line-height: 32px;
      }
      .common_desc{
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
  .sec_title{
    font-size: 42px;
    margin: 0 0 16px;
    line-height: 51px;
    letter-spacing: -.02em;
    font-weight: 500;
    font-family: $basierMedium;
    @media (max-width: $sm){
      font-size: 28px;
      line-height: 32px;
    }
   }
   .sec_desc{
    font-size: 36px;
    font-weight: 400;
    line-height: 43px;
    letter-spacing: -0.36px;
    font-family: $basier;
    padding: 12px 0;
    @media (max-width: $sm){
      font-size: 20px;
      line-height: 30px;
    }
   }
  .common_title{
    font-size: 56px;
    line-height: 68px;
    letter-spacing: -.01em;
    font-family: $basierMedium;
  }
  .common_desc{
    font-size: 18px;
    margin: 0 0 28px;
    line-height: 28px;
    font-family: $basier;
  }
  .webinar_agenda{
    .loyalty_first_fold{
      gap: 24px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      @media (max-width: $sm){
        flex-wrap: wrap;
      }
      .loyalty_left_side{
        max-width: 620px;
        .agenda_div{
          padding: 0 0 25px;
          .agenda_title{
            font-size: 24px;
            font-weight: 600;
            line-height: 120%;
            letter-spacing: -0.24px;
            padding: 0 0 15px;
            @media (max-width: $sm){
              font-size: 20px;
              line-height: 24px;
            }
          }
          .common_desc{
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 30px;
            @media (max-width: $sm){
              font-size: 16px;
              line-height: 24px;
            }
          }
          .agenda {
            padding-left: 20px;
            li {
              padding: 0 0 4px;
              font-size: 20px;
              font-weight: 400;
              line-height: 30px;
              @media (max-width: $sm){
                font-size: 16px;
                line-height: 24px;
              }
            }
          }
        }
      }
      .loyalty_right_side{
        width: 100%;
        max-width: 571px;
        border-radius: 24px;
        background: #BEF8F8;
        padding: 30px 43px;
        @media (max-width: $sm){
          padding: 20px;
        }
        .sec_title{
          font-size: 28px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px;
          letter-spacing: -0.56px;
          color: #0D9298;
          @media (max-width: $sm){
            font-size: 20px;
            line-height: 24px;
          }
        }
        .what_learn{
          padding: 0 0 20px;
          p{
            color: #003838;
            font-size: 20px;
            font-weight: 400;
            line-height: 30px;
            @media (max-width: $sm){
              font-size: 16px;
              line-height: 24px;
            }
            strong{
              color: #021B1B;
            }
          }
        }
      }
    }
    
  }
  .speaker{
    .speaker_wrapper{
      border-radius: 24px;
      padding: 30px 42px;
      height: 545px;
      margin: 0 15px;
      z-index: 1;
      @media (max-width: $sm){
        padding: 30px 20px;
        height: unset;
        margin: 0 0 30px;
      }
      .speakerimght{
        height: 180px;
        padding: 40px 0;
        @media (max-width: $sm){
          height: unset;
          padding: 0;
        }
      }
      .about_speaker{
        font-size: 42px;
        font-weight: 600;
        line-height: 60px;
        letter-spacing: -0.84px;
        padding: 15px 0;
        @media (max-width: $sm){
          font-size: 30px;
          line-height: 40px;
        }
      }
      .about_speaker_text{
        color: #001124;
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        text-align: left;
        @media (max-width: $sm){
          font-size: 16px;
          line-height: 24px;
        }
      }
      .union {
        position: absolute;
        right: 0;
        bottom: 0;
        mix-blend-mode: darken;
        z-index: -1;
        border-bottom-right-radius: 24px;
        @media (max-width: $sm){
          border-bottom-right-radius: 24px;
        }
      }
    }
  }
  #webinars {
    .webinar-box{
        padding: 30px 10px !important;
        &:hover{
            .video-thumbnail{
            .play-icon-wrapper {
                img{
                    transform:scale(1.2);
                    transition-duration: .3s;
                    transition-property: transform;
                }
            }
        }
    }
    
    .webinar_Img {
    .video-thumbnail{
        .videoimg{
            border-bottom-left-radius:unset ;
            border-bottom-right-radius:unset ;
            @media only screen and (max-width: 992px) {
                height: 180px;
            }
        }
        .play-icon-wrapper {
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            left: 50%;
            cursor: pointer;
        }
    }
    .play-icon{
      height: unset;
    }
    .videoimg {
    height: 206px;
    transition-duration: unset;
    transition-property: unset;
    transform: unset;
    @media only screen and (max-width: 992px) {
      height: unset;
  }
    }
    iframe {
        border-radius: 8px;
        @media only screen and (max-width: 992px) {
                height: 180px;
            }
    }
}

}
}
}
.webiContent{
  float: left;
  margin: -5px 0 0;
  padding: 0px 20px 20px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  height: 330px;
  @media (max-width: $sm){
    height: unset;
  }
}
.restautant-retargeting{
  .banner {
    .bg1{
        z-index: -1;
    }
    .bg2{
        position: absolute;
        right: 0;
        bottom: -110px;
        z-index: -1;
        @media (max-width: $sm){
          bottom: 0;
        }
    }
	.banner_wrapper{
        padding: 70px 0px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media (max-width: $sm){
          padding: 40px 0 0;
          flex-wrap: wrap;
          gap: 30px;
        }
        .banner_left_fold{
          max-width: 669px;
        }
        .banner_right_fold{
          width: 100%;
          max-width: 538px;
          padding: 24px;
          @media (max-width: $sm){
            padding: 0;
          }
        }
        .banner-container {
            .rating{
                p{
                    color: #697777;
                    font-size: 16px;
                    line-height: 22px;
                    font-weight: 400;
                    @media (max-width: $sm) {
                        font-size: 14px;
                        line-height: 20px;
                    }
                }
            }
            .banner-title{
                font-size: 64px;
                line-height: 70px;
                margin: 12px 0 22px;
                letter-spacing: -2.56px;
                font-family: $basierMedium;
                @media (max-width: $sm) {
                  margin: 12px auto 16px;
                  font-size: 28px;
                  line-height: 32px;
                  text-align: center;
                  letter-spacing: -1.36px;
                }
            }	
            .banner-text{
                font-size: 20px;
                margin: 0 0 40px;
                line-height: 30px;
                @media (max-width: $sm) {
                  margin: 0 0 40px;
                  font-size: 14px;
                  line-height: 19px;
                  text-align: center;
                }
            }
            .btn_wrapper{
                width: 330px;
                margin: 50px 0 40px;
                a{
                  width: 100%;
                }
                .text-small{
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 16px;
                    display: block;
                    margin: 8px 0 0;
                }
                @media (max-width: $sm){
                    width: unset;
                    margin: 0 0 30px;
                }
            }
            .flex_div{
                display: flex;
                align-items: center;
                align-items: center;
                justify-content: flex-start;
                gap: 20px;
                @media (max-width: $sm){
                  gap: 11px;
                    margin: 0 0 30px;
                    justify-content: center;
                }
                img {
                  width: 75px;
                    @media (max-width: $sm){
                        width: 40px;
                    }
                }
            }
        }
    }
    .disFlex{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
        @media (max-width: $sm){
            display: block;
        }
    }
  }
  .fold1{
    .sec-title{
      font-size: 56px;
      font-style: normal;
      font-weight: 700;
      line-height: 64px;
      letter-spacing: -.56px;
      font-family: $basierMedium;
      @media (max-width: $sm){
        font-size: 28px;
        line-height: 32px;
        margin-bottom: 24px;
    }
    }
  }
  #section2{
    .sec-title{
      font-size: 58px;
      font-style: normal;
      color: #0e1b1b;
      line-height: 64px;
      text-align: center;
      margin: 0 auto 48px;
      letter-spacing: -.02em;
      font-family: $basierSemibold;
      @media (max-width: $sm){
          font-size: 28px;
          line-height: 32px;
          letter-spacing: -.96px;
          width: 100%;
          margin-bottom: 24px;
      }
    }
    
      .tooltabs{
          border-radius: 49px;
          background: #f5f5f5;
          padding: 10px;
          width: 43%;
          margin: 30px auto 60px; 
          @media (max-width: $sm){
              margin: 20px auto 40px;
              width: 100%;
          }
          a{
              color: #697777;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 22px;
              letter-spacing: -.32px;
              font-family: $basierMedium;
              padding: 3px 30px;
              cursor: pointer;
              @media (max-width: $sm){
                font-size: 12px;
                line-height: 16px;
                padding: 3px 12px;
              }
              &.active{
                  color: #212123;
                  border-radius: 52px;
                  background: #fff;
                  -webkit-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .08);
                  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .08);
                  padding: 6px 30px;
                  @media (max-width: $sm){
                    font-size: 12px;
                    line-height: 16px;
                    padding: 3px 12px;
                  }
              }
          }
      }
      .tools, .tool{
          padding: 10px;
          border-radius: 16px;
          border: 1px solid #e9eded;
          background: #fff;
          -webkit-box-shadow: 0 2px 1px 0 rgba(0, 0, 0, .05), 0 0 1px 0 rgba(0, 0, 0, .25);
          box-shadow: 0 2px 1px 0 rgba(0, 0, 0, .05), 0 0 1px 0 rgba(0, 0, 0, .25);
          @media (max-width: $sm){
              background: #f7f8f8;
          }
          .card_img{
            img{
              height: 443px;
              border-radius: 8px;
              object-fit: cover;
              @media (max-width: $sm){
                height: 100%;
              }
            }
          }
          .tools_wrapper{
              padding: 30px;
              @media (max-width: $sm){
                  padding: 0;
              }
              h3{
                color: #0e1b1b;
                font-size: 41px;
                font-style: normal;
                font-weight: 600;
                line-height: 49.2px;
                letter-spacing: -2.05px;
                margin-bottom: 0;
                margin: 15px 0 0;
                @media (max-width: $sm){
                  font-size: 18px;
                  line-height: 24px;
                  letter-spacing: -.36px;
                }
              }
              p{
                margin: 16px 0;
                font-size: 16px;
                color: #697777;
                line-height: 22px;
                font-style: normal;
                @media (max-width: $sm){
                  margin: 8px 0;
                  font-size: 14px;
                  line-height: 20px;
                  letter-spacing: -.14px;
                }
              }
              .learnmore{
                  a{  
                      display: block;
                      padding: 20px 0;
                      text-decoration: underline !important;
                      font-weight: 500;
                      font-family: $basierMedium;
                      font-size: 16px;
                      cursor: pointer;
                  }
              }
          }
      }
  }

  #section3{
    .bgcolor{
      background-color: #f1fafa;
      margin: 0 40px;
      @media (max-width: $sm){
        margin: 0;
        padding: 40px 0 30px;
      }
    }
      .sec-title{
        font-size: 58px;
        font-style: normal;
        color: #0e1b1b;
        line-height: 64px;
        text-align: center;
        margin: 0 auto 48px;
        letter-spacing: -.02em;
        font-family: $basierSemibold;
        @media (max-width: $sm){
          font-size: 28px;
          line-height: 32px;
          letter-spacing: -.96px;
          width: 100%;
          margin-bottom: 24px;
      }
      }
      
      .counter_wrapper{
          padding: 30px 0;
          @media (max-width: $sm){
              padding: 0;
          }
          .blr{
              &::before{
                  content: "";
                  position: absolute;
                  left: 0;
                  top: 50%;
                  transform: translateY(-50%);
                  width: 1px;
                  height: 75px;
                  background-color: #0e1b1b;
                  opacity: .3;
                  @media (max-width: $sm){
                      top: -22px;
                      left: 50%;
                      transform: translateX(-50%);
                      width: 75px;
                      height: 1px;
                  }
              }
              &::after{
                  content: "";
                  position: absolute;
                  right: 0;
                  top: 50%;
                  transform: translateY(-50%);
                  width: 1px;
                  height: 75px;
                  background-color: #0e1b1b;
                  opacity: .3;
                  @media (max-width: $sm){
                      bottom: 22px;
                      right: unset;
                      left: 50%;
                      transform: translateX(-50%);
                      width: 75px;
                      height: 1px;
                      top:unset;
                  }
              }
          }
          .counter_div{
              @media (max-width: $sm){
                height: 147px;
              }
              .counter_num{
                p{
                  color: #0d9298;
                  text-align: center;
                  font-size: 64px;
                  line-height: normal;
                  letter-spacing: -.64px;
                  margin-bottom: 0;
                  font-family: $basierMedium;
                }
              }
              .counter_text{
                  color: #0e1b1b;
                  text-align: center;
                  font-size: 20px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 26px;
                  letter-spacing: -.2px;
                  padding: 15px 0 0;
                  @media (max-width: $sm){
                      font-size: 14px;
                      line-height: 20px;
                      padding: 10px 0 0;
                  }
              }
          }
      }
  }

  #section4{
    .sec-title{
      font-size: 58px;
      font-style: normal;
      color: #0e1b1b;
      line-height: 64px;
      text-align: center;
      margin: 0 auto 48px;
      letter-spacing: -.02em;
      font-family: $basierSemibold;
      @media (max-width: $sm){
        font-size: 28px;
        line-height: 32px;
        letter-spacing: -.96px;
        width: 100%;
        margin-bottom: 24px;
      }
    }
    
      .slider{
          padding: 40px 0 60px;
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          @media (max-width: $xs){
              display: block;
              padding: 20px 0 0px;
              .slider-prev{
                  bottom: 0;
                  left:calc(50% - 62px);
              }
              .slider-next{
                  bottom: 0;
                  right:calc(50% - 62px);
              }
          }
          .slide{
              border-radius: 12px;
              padding: 20px 24px;
              height: 440px;
              margin: 0 10px;
              .main_img{
                background-color: #fff;
                border-radius: 50%;
                width: 65px;
                height: 65px;
              }
              @media (max-width: $sm){
                height: 460px;
                  margin: 15px 0;
              }
              .slidetext{
                  padding: 16px 0 28px;
                  color: #0e1b1b;
                  font-weight: 400;
                  margin: 20px 0 28px;
                  height: 210px;
                  font-size: 16px;
                  line-height: 22px;
                  @media (max-width: $sm){
                      height: 250px;
                  }
              }
          }
          .slide1{
              background-color: #FFECEB;
          }
          .slide2{
              background-color: #FFF9F1;
          }
          .slide3{
              background-color: #FFF9E5;
          }
          .slide_flex {
              display: flex;
              align-items: center;
              gap: 15px;
              .alpha{
                width: 40px;
                height: 40px;
                background-color: #0d9298;
                color: #fff;
                border-radius: 50%;
                align-items: center;
                justify-content: center;
                font-size: 18px;
                line-height: 22px;
                font-family: $basierSemibold;
                padding: 8px 14px;
              }
              .author{
                  img{
                      border-radius: 100%;
                      
                  }
              }
              .author_desc{
                  .sec-desc{
                      padding: 0;
                  }
                  span{
                      font-size: 14px;
                      line-height: 18px;
                  }
              }
          }
      }
  }
  .qrcode_reviews{
    .qr_wrapper{
      padding-right: 60px;
      @media (max-width: $sm){
        padding-right: 0;
      }
    .loyalty_tabs{
      cursor: pointer;
      margin: 0 0 16px;
      padding: 16px 24px;
      border-radius: 16px;
      transition: all .5s;
      background: #f2f2f2;
      h3{
        font-size: 20px;
        color: #182020;
        line-height: 26px;
      }
      p {
        display: none;
        margin: 8px 0 0;
        font-size: 16px;
        color: $white;
        line-height: 22px;
        text-align: left;
      }
      ul {
        display: none;
        padding: 10px 0 0 15px;
        li{
          font-size: 16px;
          color: $white;
          line-height: 22px;
          text-align: left;
          padding: 0 0 5px 5px;
          position: relative;
          list-style: none;
          &:before {
            position: absolute;
            content: '✔';
            left: -13px;
          }
        }
      }
    }
    .loyalty_tabs{
      &.active{
        background-color: #01999f;
        h3{
          color: $white;
        }
        p, ul{
          display: block;
        }
      }
    }
    }
    .qr_slider{
      .slick-dots {
        display: flex;
          margin: 0;
          list-style-type: none;
          position: absolute;
          bottom: -9px;
          left: 50%;
          transform: translateX(-50%);
          button {
            display: block;
            width: 6px;
            height: 6px;
            padding: 0;
            background-color: grey;
            text-indent: -9999px;
            cursor: pointer;
            border-radius: 30px;
            border: unset;
            margin: 0 3px;
          }
        }
        li.slick-active {
          button {
            background-color: $black;
          }
      }
      img{
        border-radius: 16px;
      }
      .qr_desc{
        @media (max-width:$sm){
          text-align: left;
        }
        h3{
          font-size: 18px;
          color: #0e1b1b;
          line-height: 24px;
          margin: 32px auto 8px;      
        }
        p{
          font-size: 14px;
          color: #5a6565;
          max-width: 400px;
          line-height: 20px;
          margin: 0 auto 8px;
        }
        ul {
          padding: 10px 14px 20px;
          li{
            font-size: 14px;
            color: #5a6565;
            max-width: 400px;
            line-height: 20px;
            list-style: none;
            padding-bottom: 5px;
            position: relative;
            &:before {
              position: absolute;
              content: '✔';
              margin-left: -14px;
            }
          }
        }
      }
    }
  }

  .what-reelo-offers{
    .whatreelo_offers{
      display: flex;
      justify-content: center;
      gap: 30px;
      padding: 15px;
      @media (max-width: $sm){
        padding: 0;
        gap: 10px;
        margin: 5px 0;
      }
      .whatreelo_offers_div{
        width: 343px;
        height: 196px;
        padding: 32px 22px;
        border-radius: 17px;
        @media (max-width: $sm){
          height: 130px;
          padding: 15px;
          img{
            width: 35px;
          }
        }
        p{
          color: #171E59;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%;  
          padding: 15px 0 0;
          @media (max-width: $sm){
            font-size: 14px;
          }
        }
      }
      .offer_1{
        background: #EDFCF2;
      }
      .offer_2{
        background: #FEF6EE;
      }
      .offer_3{
        background: #F5F8FF;
      }
      .offer_4{
        background: #FFE5EB;
      }
      .offer_5{
        background: #DDFEFF;
      }
      .offer_6{
        background: #FFF1D4;
      }
    }
  }
  .pricing{
    .sec-heading2{
      font-size: 56px;
      font-style: normal;
      font-weight: 700;
      line-height: 64px;
      letter-spacing: -.56px;
      font-family: $basierMedium;
      @media (max-width: $sm){
        font-size: 28px;
        line-height: 32px;
      }
    }
  }
  .pricing_card-rows{
    display: flex;
    justify-content: center;
    gap: 25px;
    @media (max-width: $sm){
      display: block;
    }
    .cards{
      width: 370px;
      height: 100%;
      min-height: 380px;
      padding: 24px 32px;
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @media (max-width: $sm){
        width: 100%;
      }
      .white-btn{
        color: #1A424F;
        border: 1px solid #1A424F;
        border-radius: 8px;
        background-color: transparent;
        width: 100%;
      }
      .theme-btn{
        width: 100%;
      }
      h3{
        font-size: 24px;
        color: #354545;
        line-height: 30px;
        margin-bottom: 8px;
        font-weight: 700;
      }
      h4{
        font-size: 48px;
        line-height: 67px;
        color: #182020;
        font-weight: 600;
        p{
          display: inline;
          font-size: 48px;
          line-height: 67px;
          color: #182020;
          font-weight: 400;
        } 
        span{
          font-size: 14px;
          line-height: 18px;
        }   
      }
      h6{
        font-size: 16px;
        line-height: 30px;
        color: rgba(24, 32, 32, .75);
        font-weight: 400;
      }
      .pricing_desc2{
        font-size: 14px;
        color: #1a424f;
        line-height: 28px;
        text-align: center;
      }
      .pricing_features{
        display: flex;
        margin: 24px 0;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;
      }
    }
    .pricing_plan-card{
      background: #f5f5f5;
    }
    .pricing_plan-card__3SDlI{
      background: linear-gradient(157.35deg, #c4f2f4 14.72%, #9cffed 102.4%);
    }
  }
  .pricing_term{
    font-size: 12px;
    line-height: 17px;
    text-align: right;
    color: #838c8c;
  }
  .pre-built{
    .common_title{
      @media (max-width: $sm){
        font-size: 28px;
        line-height: 32px;
      }
    }
    .common_desc {
      @media (max-width: $sm){
        font-size: 18px;
      }
    }
     .campaigns_feature_card_root{
      gap: 24px;
      margin: 0 auto;
      padding: 0 16px;
      flex-wrap: wrap;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: $sm){
        display: block;
        border-radius: 16px;
        padding: 0;
        .slick-dots {
          display: flex;
            margin: 0;
            list-style-type: none;
            position: absolute;
            bottom: -9px;
            left: 50%;
            transform: translateX(-50%);
            button {
              display: block;
              width: 12px;
              height: 12px;
              padding: 0;
              background-color: #c6c6c6;
              text-indent: -9999px;
              cursor: pointer;
              border-radius: 30px;
              border: unset;
              margin: 0 3px;
            }
          }
          li.slick-active {
            button {
              background-color: $white;
            }
        }
      }
      .campaigns_feature_card{
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        border-radius: 16px;
        background: #f3f1ee;
        width: calc(50% - 12px);
        @media (max-width: $sm){
          display: block;
          padding: 0;
          width: 100%;
          margin: 0 0 30px;
        }
        .campaigns_left_side{
          padding: 30px;
          @media (max-width: $sm){
            padding: 34px 4px 34px 28px;
          }
          .campaigns_feature_number{
            padding: 4px 9px;
            width: 28px;
            height: 28px;
            font-size: 16px;
            color: $white;
            line-height: 22px;
            border-radius: 8px;
            background: #065151;
            font-family: $basierSemibold;
          }
          h3{
            margin: 8px 0;
            font-size: 28px;
            color: $color1;
            line-height: 34px;
            letter-spacing: -.02em;
            font-family: $basierSemibold;
            @media (max-width: $sm){
              font-size: 20px;
              line-height: 24px;
            }
            
          }
          p{
            font-size: 18px;
            color: #232d2d;
            line-height:130%;
          }
        }
        .campaigns_right_side{
          width: 100%;
          position: relative;
          bottom: -6px;
          @media (max-width: $sm){
            text-align: center;
            padding: 0 15px;
            max-width: 100%;
            bottom: 0;
            img{
              height: 300px;
            }
          }
        }
      }
      .last_card{
        width: 100%;
        height: auto;
      }
     }
   }
   .benifits_section{
    .bene_div{
      padding: 32px 30px;
      border-radius: 16px;
      width: 31%;
      margin: 40px 14px 0;
      @media (max-width: $sm){
        width: 100%;
        margin: 30px 0 0;
      }
      .bene_inner{
        padding: 36px 22px;
        background: #fff;
        border-radius: 16.7273px;
        p{
          font-size: 20px;
          color: #171e59;
          line-height: 28px;
          text-align: center;
          letter-spacing: -.02em;
          font-family: $basierMedium;
        }
        span{
          font-size: 48px;
          line-height: 62px;
          text-align: center;
          letter-spacing: -.02em;
          font-family:$basierSemibold;
          display: block;
        }
        .green{
          color: #16b364;
        }
        .red{
          color: #ef6820;
        }
        .blue{
          color: #1570ef;
        }
      }
    }
    .b1{
      background: #edfcf2;
    }
    .b2{
      background: #fef6ee;
    }
    .b3{
      background: #f5f8ff;
    }
  }
}
.cafe-marketing{
  .banner {
    .bg1{
        z-index: -1;
    }
    .bg2{
        position: absolute;
        right: 0;
        bottom: -110px;
        z-index: -1;
        @media (max-width: $sm){
          bottom: 0;
        }
    }
	.banner_wrapper{
        padding: 70px 0px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media (max-width: $sm){
          padding: 40px 0 0;
          flex-wrap: wrap;
          gap: 30px;
        }
        .banner_left_fold{
          max-width: 669px;
        }
        .banner_right_fold{
          width: 100%;
          max-width: 538px;
          padding: 24px;
          @media (max-width: $sm){
            padding: 0;
          }
        }
        .banner-container {
            .rating{
                p{
                    color: #697777;
                    font-size: 16px;
                    line-height: 22px;
                    font-weight: 400;
                    @media (max-width: $sm) {
                        font-size: 14px;
                        line-height: 20px;
                    }
                }
            }
            .banner-title{
                font-size: 64px;
                line-height: 70px;
                margin: 12px 0 22px;
                letter-spacing: -2.56px;
                font-family: $basierMedium;
                @media (max-width: $sm) {
                  margin: 12px auto 16px;
                  font-size: 28px;
                  line-height: 32px;
                  text-align: center;
                  letter-spacing: -1.36px;
                }
            }	
            .banner-text{
                font-size: 20px;
                margin: 0 0 40px;
                line-height: 30px;
                @media (max-width: $sm) {
                  margin: 0 0 40px;
                  font-size: 14px;
                  line-height: 19px;
                  text-align: center;
                }
            }
            .btn_wrapper{
                width: 330px;
                margin: 50px 0 40px;
                a{
                  width: 100%;
                }
                .text-small{
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 16px;
                    display: block;
                    margin: 8px 0 0;
                }
                @media (max-width: $sm){
                    width: unset;
                    margin: 0 0 30px;
                }
            }
            .flex_div{
                display: flex;
                align-items: center;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 20px;
                @media (max-width: $sm){
                    margin: 0 0 30px;
                    gap: 11px;
                    justify-content: center;
                }
                img {
                  width: 75px;
                    @media (max-width: $sm){
                        width: 40px;
                    }
                }
            }
        }
    }
    .disFlex{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
        @media (max-width: $sm){
            display: block;
        }
    }
}


#section2{
  .sec-title{
    font-size: 58px;
    font-style: normal;
    color: #0e1b1b;
    line-height: 64px;
    text-align: center;
    margin: 0 auto 48px;
    letter-spacing: -.02em;
    font-family: $basierSemibold;
    @media (max-width: $sm){
        font-size: 24px;
        line-height: 30px;
        letter-spacing: -.96px;
        width: 100%;
        margin-bottom: 24px;
    }
  }
  
    .tooltabs{
        border-radius: 49px;
        background: #f5f5f5;
        padding: 10px;
        width: 32%;
        margin: 30px auto 60px; 
        @media (max-width: $sm){
            margin: 20px auto 40px;
            width: 100%;
        }
        a{
            color: #697777;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
            letter-spacing: -.32px;
            font-family: $basierMedium;
            padding: 3px 30px;
            cursor: pointer;
            @media (max-width: $sm){
              font-size: 12px;
              line-height: 16px;
              padding: 3px 12px;
            }
            &.active{
                color: #212123;
                border-radius: 52px;
                background: #fff;
                -webkit-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .08);
                box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .08);
                padding: 6px 30px;
                @media (max-width: $sm){
                  font-size: 12px;
                  line-height: 16px;
                  padding: 3px 12px;
                }
            }
        }
    }
    .tools{
        padding: 10px;
        border-radius: 16px;
        border: 1px solid #e9eded;
        background: #fff;
        -webkit-box-shadow: 0 2px 1px 0 rgba(0, 0, 0, .05), 0 0 1px 0 rgba(0, 0, 0, .25);
        box-shadow: 0 2px 1px 0 rgba(0, 0, 0, .05), 0 0 1px 0 rgba(0, 0, 0, .25);
        @media (max-width: $sm){
            background: #f7f8f8;
        }
        .card_img{
          img{
            height: 443px;
            border-radius: 8px;
            object-fit: cover;
            @media (max-width: $sm){
              height: 100%;
            }
          }
        }
        .tools_wrapper{
            padding: 30px;
            @media (max-width: $sm){
                padding: 0;
            }
            h3{
              color: #0e1b1b;
              font-size: 41px;
              font-style: normal;
              font-weight: 600;
              line-height: 49.2px;
              letter-spacing: -2.05px;
              margin-bottom: 0;
              margin: 15px 0 0;
              @media (max-width: $sm){
                font-size: 18px;
                line-height: 24px;
                letter-spacing: -.36px;
              }
            }
            p{
              margin: 16px 0;
              font-size: 16px;
              color: #697777;
              line-height: 22px;
              font-style: normal;
              @media (max-width: $sm){
                margin: 8px 0;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: -.14px;
              }
            }
            .learnmore{
                a{  
                    display: block;
                    padding: 20px 0;
                    text-decoration: underline !important;
                    font-weight: 500;
                    font-family: $basierMedium;
                    font-size: 16px;
                    cursor: pointer;
                }
            }
        }
    }
}

#section3{
  .bgcolor{
    background-color: #f1fafa;
    margin: 0 40px;
    padding: 40px 0;
    @media (max-width: $sm){
      margin: 0;
      padding: 40px 0 30px;
    }
  }
    .sec-title{
      font-size: 58px;
      font-style: normal;
      color: #0e1b1b;
      line-height: 64px;
      text-align: center;
      margin: 0 auto 48px;
      letter-spacing: -.02em;
      font-family: $basierSemibold;
      @media (max-width: $sm){
        font-size: 24px;
        line-height: 30px;
        letter-spacing: -.96px;
        width: 100%;
        margin-bottom: 24px;
    }
    }
    
    .counter_wrapper{
      padding: 0px 0 30px;
        @media (max-width: $sm){
            padding: 0;
        }
        .blr{
            &::before{
                content: "";
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 1px;
                height: 75px;
                background-color: #0e1b1b;
                opacity: .3;
                @media (max-width: $sm){
                    top: -6px;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 75px;
                    height: 1px;
                }
            }
            &::after{
                content: "";
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 1px;
                height: 75px;
                background-color: #0e1b1b;
                opacity: .3;
                @media (max-width: $sm){
                    bottom: 4px;
                    right: unset;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 75px;
                    height: 1px;
                    top:unset;
                }
            }
        }
        .counter_div{
            @media (max-width: $sm){
               height: 147px;
            }
            .counter_num{
              p{
                color: #0d9298;
                text-align: center;
                font-size: 64px;
                line-height: normal;
                letter-spacing: -.64px;
                margin-bottom: 0;
                font-family: $basierMedium;
              }
            }
            .counter_text{
                color: #0e1b1b;
                text-align: center;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 26px;
                letter-spacing: -.2px;
                padding: 15px 0 0;
                @media (max-width: $sm){
                    font-size: 14px;
                    line-height: 20px;
                    padding: 10px 0 0;
                }
            }
        }
    }
}

#section4{
  .sec-title{
    font-size: 58px;
    font-style: normal;
    color: #0e1b1b;
    line-height: 64px;
    text-align: center;
    margin: 0 auto 48px;
    letter-spacing: -.02em;
    font-family: $basierSemibold;
    @media (max-width: $sm){
      font-size: 24px;
      line-height: 30px;
      letter-spacing: -.96px;
      width: 100%;
      margin-bottom: 24px;
    }
  }
  
    .slider{
        padding: 40px 0 60px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        @media (max-width: $xs){
            display: block;
            padding: 20px 0 0px;
            .slider-prev{
                bottom: 0;
                left:calc(50% - 62px);
            }
            .slider-next{
                bottom: 0;
                right:calc(50% - 62px);
            }
        }
        .slide{
            border-radius: 12px;
            padding: 20px 24px;
            height: 440px;
            margin: 0 10px;
            .main_img{
              background-color: #fff;
              border-radius: 50%;
              width: 65px;
              height: 65px;
            }
            @media (max-width: $sm){
              height: 460px;
                margin: 15px 0;
            }
            .slidetext{
                padding: 16px 0 28px;
                color: #0e1b1b;
                font-weight: 400;
                margin: 20px 0 28px;
                height: 210px;
                font-size: 16px;
                line-height: 22px;
                @media (max-width: $sm){
                    height: 250px;
                }
            }
        }
        .slide1{
            background-color: #FFECEB;
        }
        .slide2{
            background-color: #FFF9F1;
        }
        .slide3{
            background-color: #FFF9E5;
        }
        .slide_flex {
            display: flex;
            align-items: center;
            gap: 15px;
            .alpha{
              width: 40px;
              height: 40px;
              background-color: #0d9298;
              color: #fff;
              border-radius: 50%;
              align-items: center;
              justify-content: center;
              font-size: 18px;
              line-height: 22px;
              font-family: $basierSemibold;
              padding: 8px 14px;
            }
            .author{
                img{
                    border-radius: 100%;
                    
                }
            }
            .author_desc{
                .sec-desc{
                    padding: 0;
                }
                span{
                    font-size: 14px;
                    line-height: 18px;
                }
            }
        }
    }
}
}

.petpooja{
  .faq{
    .sec_title{
      margin: 0 0 40px;
      font-size: 32px;
      color: #182020;
      line-height: 40px;
      font-weight: 700;
    }
  }
  .petpooja_first_fold {
    padding: 180px 0 90px;
    @media (max-width:$sm) {
      flex-wrap: wrap;
      padding: 90px 0 50px;
    }
    // .petpooja_left_side{
    //   max-width: 650px;
    // }
    .petpooja_right_side{
      width: 571px;
      min-width: 380px;
      @media (max-width:$sm) {
        all: unset;
        width: 100%;
      }
      .quotes_Img {
        margin: 50px 0 0;
        @media (max-width:$sm) {
          margin: 0;
        }
        .video-thumbnail {
          .top-gradient {
            top: 0;
            left: 0;
            right: 0;
            z-index: 2;
            width: 100%;
            height: 80px;
            padding: 24px;
            position: absolute;
            border-radius: 16px 16px 0 0;
            background: linear-gradient(180deg, rgba(0, 0, 0, .6), transparent);
            p{
              z-index: 1;
              font-size: 18px;
              text-align: center;
              line-height: 22px;
              overflow: hidden;
              white-space: nowrap;
            }
           }
           .videoimg{
            border-radius: 24px;
           }
          .play-icon-wrapper {
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%) scale(1);
            left: 50%;
            cursor: pointer;
            transition: all .3s;
          }
        }
        iframe{
          height: 321px;
          border: none;
          outline:none;
          border-radius: 24px;
          @media (max-width:$sm) {
            height: 189px;
          }
        }
      }
    }
  }
  .fold1 {
    .sec-title{
      font-size: 32px;
      margin: 0 0 56px;
      line-height: 40px;
      text-align: center;
      letter-spacing: -.01em;
      font-family: $basierSemibold;
      @media (max-width:$sm) {
        font-size: 20px;
        line-height: 24px;
      }
    }
    .fold1_wrapper{
      .reeloBrand_slider{ 
        padding:  0 0 100px;
        @media (max-width:$sm){
          padding: 0 0 30px;
        }
        .brandSlide{
          height: 120px;
          padding: 0 27px;
          display: flex !important;
          align-items: center;
          justify-content: center;
          padding: 30px 24px;
          @media (max-width:$sm){
            padding: 10px 24px;
            height: 80px;
            width: 120px !important;
          }
          img{
            width: 120px;
            height: 120px;
            object-fit: contain;
            @media (max-width:$sm){
              width: 80px;
              height: 80px;
            }
          }
        }
      }
    }
  }
  
  .display_table{
    flex-direction: unset;
  }
  .banner{
    @media (max-width: $sm){
      text-align: center;
      .common_title{
        font-size: 28px;
        line-height: 32px;
      }
      .common_desc{
        font-size: 14px;
        line-height: 22px;
        margin: 0;
      }
    }
    .common_subtitle{
      font-size: 16px;
      line-height: 21px;
      letter-spacing: .18em;
      text-transform: uppercase;
      font-family: $basierSemibold;
    }
  }
  .sec-title{
    @media (max-width: $sm){
      font-size: 28px;
      line-height: 42px;
    }
  }
  .sec_title{
    font-size: 42px;
    margin: 0 0 16px;
    line-height: 51px;
    letter-spacing: -.02em;
    font-weight: 500;
    font-family: $basierMedium;
    @media (max-width: $sm){
      font-size: 28px;
      line-height: 32px;
    }
   }
   .sec_desc{
    font-size: 20px;
    margin: 0 0 24px;
    line-height: 30px;
    font-family: $basier;
   }
  .common_title{
    font-size: 74px;
    line-height: 78px;
    margin: 16px 0 20px;
    letter-spacing: -.01em;
    font-family: $basierMedium;
  }
  .common_desc{
    font-size: 20px;
    margin: 0 0 40px;
    line-height: 30px;
    font-family: $basier;
  }
.compare_fold_wrapper{
  background: #eaeefe;
  .compare_features{
    gap: 40px;
    margin: 0 auto;
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    justify-content: center;
    .compare_feature_card{
      width: 385px;
      padding: 24px;
      background: #fff;
      border-radius: 12px;
      h3{
        font-size: 20px;
        color: #182020;
        line-height: 32px;
        margin: 32px 0 8px;
      }
      p{
        font-size: 18px;
        color: #182020;
        line-height: 24px;
        margin-bottom: 32px;
      }
    }
  }
}

 .pre-built{
  .common_title{
    @media (max-width: $sm){
      font-size: 28px;
      line-height: 32px;
    }
  }
  .common_desc {
    @media (max-width: $sm){
      font-size: 18px;
    }
  }
   .campaigns_feature_card_root{
    gap: 24px;
    margin: 0 auto;
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: $sm){
      display: block;
      border-radius: 16px;
      padding: 0;
    }
    .flx{
      align-items: center !important;
    }
    .campaigns_feature_card{
      height: 350px;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      padding: 0 24px 0 0;
      border-radius: 16px;
      background: #f3f1ee;
      width: calc(50% - 12px);
      @media (max-width: $sm){
        display: block;
        padding: 0;
        height: unset;
        width: 100%;
        margin: 0 0 30px;
      }
      .campaigns_left_side{
        padding: 32px 8px 32px 22px;
        @media (max-width: $sm){
          padding: 34px 8px 34px 22px;
        }
        .campaigns_feature_number{
          padding: 4px 9px;
          width: 28px;
          height: 28px;
          font-size: 16px;
          color: $white;
          line-height: 22px;
          border-radius: 8px;
          background: #065151;
          font-family: $basierSemibold;
        }
        h3{
          margin: 8px 0;
          font-size: 26px;
          color: $color1;
          line-height: 30px;
          letter-spacing: -.02em;
          font-family: $basierSemibold;
          @media (max-width: $sm){
            font-size: 20px;
            line-height: 24px;
          }
        }
        p{
          font-size: 16px;
          color: #232d2d;
          line-height: 24px;
        }
      }
      .campaigns_right_side{
        width: 100%;
        max-width: 270px;
        position: relative;
        bottom: -6px;
        @media (max-width: $sm){
          max-width:250px;
         margin: 0 auto;
        }
      }
    }
    .last_card{
      width: 100%;
      height: auto;
    }
   }
 }

 .fold3{
  .marrows {
    margin: 30px 0 35px;
    svg {
      width: 48px;
      height: 48px;
      background-color: #f3f5f5;
      border-radius: 100%;
      padding: 10px;
      margin: 0 10px;
      &:hover{
        background-color: #0d9298;
        path{
          stroke: $white;
        }
      }
    }  
    #cnext{
      position: relative;
      transform: rotate(180deg);
    }
  }
  .reviewCarousel_parent{
    .reviewCarousel{
      gap: 48px;
      padding: 24px;
      height: 381px;
      margin: 0 4px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      border-radius: 24px;
      align-items: stretch;
      background: #e0eaff;
      @media (max-width:$sm){
          gap: 12px;
          padding: 16px;
          margin: 0 4px;
          border-radius: 8px;
          flex-direction: column;
          height: unset;
      }
      .reviewCarousel_left{
        max-width: 665px;
        overflow: hidden;
        width: calc(50% - 24px);
        @media (max-width:$sm){
          max-width: 100%;
          width: 100%;
          overflow: unset;
        }
        iframe{
          width: 100%;
          height: 100%;
          border: none;
          outline: none;
          border-radius: 12px;
        }
      }
      .reviewCarousel_right{
        gap: 24px;
        padding: 10px 0 0;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;
        max-width: -moz-calc(50% - 24px);
        max-width: calc(50% - 24px);
        @media (max-width:$sm){
          max-width: 100%;
          min-width: 100%;
          padding: 15px 0 0;
        }
        h3{
          font-size: 32px;
          line-height: 40px;
          letter-spacing: -1.28px;
          font-family: $basierMedium;
          font-weight: 500;
          @media (max-width:$sm){
            margin: 0 0 8px;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: -.24px;
          }
        }
        .reviewCarousel_review_detail{
          gap: 16px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          .reviewImgs{
            border-radius: 50%;
            object-fit: contain;
            background-color: #fff;
          }
          .reviewer_name{
            font-size: 22px;
            line-height: 26px;
            font-family: $basierMedium;
            padding: 0 0 6px;
            @media (max-width:$sm){
              font-size: 16px;
              line-height: 22px;
            }
          }
          .reviewer_post{
            font-size: 14px;
            color: #374040;
            line-height: 20px;
            letter-spacing: -.14px;
            @media (max-width:$sm){
              font-size: 12px;
              line-height: 16px;
            }
          }
        }
      }
    }
  }
} 
  .lastsection{
    border-radius: 24px;
    padding: 40px 20px;
    @media (max-width: $sm){
      margin: 0 20px;
    }
  }
}

.comparision{
  .theme-btn {
    padding: 14px 25px 12px;
  }
  .sec_title{
    font-size: 40px;
    color: #182020;
    line-height: 60px;
    max-width: 820px;
    margin: 0 auto 24px;
    font-family: $basierMedium;
    @media (max-width: $sm){
      font-size: 32px;
      line-height: 48px;
    }
  }
  .common_desc{
    font-size: 20px;
    color: #182020;
    line-height: 26px;
    margin: 0 auto 24px;
    @media (max-width: $sm){
      
    }
  }
  .comparison_wrapper{
    background: #f5faff;
    padding: 60px 0 0;
    .compare_square{
      border-radius: 10px;
      background: #e6f2ff;
    }
    .compare_square_1{
      top: 20vh;
      left: 80px;
      width: 84px;
      height: 84px;
      position: absolute;
    }
    .compare_square_2{
      left: 200px;
      width: 150px;
      height: 150px;
      position: absolute;
      border-radius: 30px;
      top: calc(20vh + 124px);
    }
    .compare_square_3{
      top: 20vh;
      right: 80px;
      width: 40px;
      height: 40px;
      position: absolute;
    }
    .compare_square_4{
      right: 200px;
      width: 64px;
      height: 64px;
      position: absolute;
      top: calc(20vh + 100px);
    }
    .compare_square_5{
      right: 40px;
      width: 40px;
      height: 40px;
      position: absolute;
      top: calc(20vh + 300px);
    }
    .compare_fold1{
      gap: 40px;
      z-index: 1;
      width: 100%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: $sm){
        flex-wrap: wrap;
        gap:0;
      }
      .compare_left_side{
        max-width: 500px;
        .common_title{
          font-size: 48px;
          color: #182020;
          line-height: 72px;
          position: relative;
          margin: 50px auto 40px;
          font-family: $basierMedium;
          @media (max-width: $sm){
            font-size: 32px;
            line-height: 48px;
          }
        }
      }
      .compare_right_side{
        margin: 40px 0;
        max-width: 540px;
        .quotes_Img {
          margin: 50px 0 0;
          @media (max-width:$sm) {
            margin: 0;
          }
          .video-thumbnail {
            .top-gradient {
              top: 0;
              left: 0;
              right: 0;
              z-index: 2;
              width: 100%;
              height: 80px;
              padding: 24px;
              position: absolute;
              border-radius: 16px 16px 0 0;
              background: linear-gradient(180deg, rgba(0, 0, 0, .6), transparent);
              p{
                z-index: 1;
                font-size: 18px;
                text-align: center;
                line-height: 22px;
                overflow: hidden;
                white-space: nowrap;
              }
             }
             .videoimg{
              border-radius: 24px;
             }
            .play-icon-wrapper {
              position: absolute;
              top: 50%;
              transform: translate(-50%, -50%) scale(1);
              left: 50%;
              cursor: pointer;
              transition: all .3s;
            }
          }
          iframe{
            height: 321px;
            width: 540px;
            border: none;
            outline:none;
            border-radius: 24px;
            @media (max-width:$sm) {
              height: 189px;
              width: 100%;
            }
          }
        }
      }
    }
    
  }

  .fold1 {
    .sec-title{
      font-size: 32px;
      margin: 0 0 56px;
      line-height: 40px;
      text-align: center;
      letter-spacing: -.01em;
      font-family: $basierSemibold;
    }
    .compare_brands{
      gap: 56px;
      flex-wrap: wrap;
      display: flex;
      -moz-box-align: center;
      align-items: center;
      -moz-box-pack: center;
      justify-content: center;
      margin-bottom: 40px;
      @media (max-width: $sm){
        gap: 24px;
        img{
          width: 88px;
        }
      }
    }
  }

  .compare_fold_wrapper{
    background: #eaeefe;
    .sec-title{
      @media (max-width: $sm){
        font-size: 32px;
        line-height: 48px;
      }
    }
    .compare_features{
      gap: 40px;
      margin: 0 auto;
      flex-wrap: wrap;
      display: flex;
      align-items: center;
      justify-content: center;
      .compare_feature_card{
        width: 385px;
        padding: 24px;
        background: #fff;
        border-radius: 12px;
        h3{
          font-size: 20px;
          color: #182020;
          line-height: 32px;
          margin: 32px 0 8px;
          font-family: $basierMedium;
        }
        p{
          font-size: 18px;
          color: #182020;
          line-height: 24px;
          margin-bottom: 32px;
        }
      }
    }
  }
  .compare_customer_reviews{
    gap: 24px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    @media (max-width: $sm){
      flex-wrap: wrap;
    }
    .compare_review_box{
      height: 100%;
      border-radius: 16px;
      background: #f5f5f5;
      .quotes_Img {
        .video-thumbnail {
          .top-gradient {
            top: 0;
            left: 0;
            right: 0;
            z-index: 2;
            width: 100%;
            height: 80px;
            padding: 24px;
            position: absolute;
            border-radius: 16px 16px 0 0;
            background: linear-gradient(180deg, rgba(0, 0, 0, .6), transparent);
            p{
              z-index: 1;
              font-size: 18px;
              text-align: center;
              line-height: 22px;
              overflow: hidden;
              white-space: nowrap;
            }
           }
           .videoimg{
            border-radius: 14px;
            height: 195px;
            width: 347px;
           }
          .play-icon-wrapper {
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%) scale(1);
            left: 50%;
            cursor: pointer;
            transition: all .3s;
          }
        }
        iframe{
          height: 195px;
          width: 347px;
          border: none;
          outline:none;
          border-radius: 24px;
          @media (max-width:$sm) {
            height: 189px;
            width: 100%;
          }
        }
      }
      .compare_video_dets{
        gap: 16px;
        padding: 16px;
        max-width: 347px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 117px;
        h3{
          font-size: 20px;
          margin: 0 0 8px;
          color: #182020;
          line-height: 26px;
          font-family: $basierSemibold
        }
        p{
          margin: 0;
          font-size: 16px;
          color: #616d6d;
          line-height: 21px;
        }
      }
    }
  }

  .compare_common_fold{
    gap: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: $sm){
      flex-direction: column-reverse;
    }
    .compare_left_side{
      max-width: 540px;
      .sec_title{
        font-size: 48px;
        color: #182020;
        line-height: 58px;
        margin: 8px 0 24px;
        font-family: $basierMedium;
        @media (max-width: $sm){
          font-size: 24px;
          line-height: 30px;
        }
      }
      .p2{
        padding-bottom: 20px;
      }
    }
    .compare_right_side{
      max-width: 540px;
    }
  }
  .compare_fold_2{
    background: #fdf5ff;
    .compare_pos_row{
      gap: 24px;
      margin: 40px auto 0;
      flex-wrap: wrap;
      max-width: 600px;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: $sm){
        gap: 16px;
      }
      .compare_pos_div{
        width: 120px;
        height: 120px;
        padding: 10px;
        border-radius: 12px;
        background-color: #fff;
        box-shadow: 0 4px 34px rgba(0, 0, 0, .1);
        position: relative;
        @media (max-width: $sm){
          width: 90px;
          height: 90px;
        }
        img{
          left: 50%;
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          padding: 10px;
        }
      }
    }
  }
  .compare_fold_3{
    .compare_business_types{
      gap: 80px;
      flex-wrap: wrap;
      margin-bottom: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 90px 0 100px;
      @media (max-width: $sm){
        gap: 40px;
      }
      .compare_business{
        h3{
          margin: 24px 0 0;
          font-size: 24px;
          color: #182020;
          line-height: 26px;
        }
      }
    }
    .cta-action-card{
      gap: 40px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 37px 40px;
      position: relative;
      border-radius: 16px;
      background: #1a424f;
      @media (max-width: $sm){
        flex-wrap: wrap;
        gap: 20px;
        padding: 20px;
      }
      .ctaActionBg{
        position: absolute;
        right: 0;
        top:0;
        @media (max-width: $sm){
          bottom: 0;
          height: 100%;
        }
      }
      .content{
        z-index: 1;
        position: relative;
        h3{
          font-size: 32px;
          margin: 0 0 24px;
          line-height: 38px;
          font-family: $basierMedium;
          @media (max-width: $sm){
             font-size: 24px;
             line-height: 29px;
          }
        }
        p{
          margin: 0;
          font-size: 18px;
          line-height: 24px;
        }
      }
    }
  }
  .compare_fold_4{
    background: #e1f3f4;
    .marrows{
      #cprev{
        position: absolute;
        left: 20px;
        top: 40%;
        z-index: 1;
      }
      #cnext{
        position: absolute;
        right: 20px;
        top: 40%;
        z-index: 1;
        transform: rotate(180deg);
      }
    }
    .compare_slick_slider{
      .compare_template{
        height: 360px;
        margin: 0 24px;
        .compare_template_card{
          width: 264px;
          height: 264px;
          margin: 0 0 16px;
          border-radius: 8px;
          position: relative;
          transition: .1s linear;
          @media (max-width: $sm){
            width: 100%;
          }
          &:after{
            top: 0;
            left: 0;
            content: "";
            height: 100%;
            width: 100%;
            position: absolute;
            border-radius: 15px;
            background: linear-gradient(180deg, rgba(0, 0, 0, .3) 0, hsla(0, 0%, 100%, 0) 80%);
          }
          .compare_template_img{
            img{
              object-fit: cover;
              border-radius: 15px;
              vertical-align: middle;
              height: 264px;
              width: 100%;
            }
          }
          .compare_template_title{
            left: 0;
            top: 16px;
            z-index: 2;
            height: 60%;
            color: $white;
            font-size: 24px;
            padding: 0 16px;
            line-height: 29px;
            position: absolute;
            word-wrap: break-word;
            font-family: $basierMedium;
                font-weight: 700;
          }
        }
        .compare_template_objective{
          margin: 0;
          font-size: 20px;
          color: $black;
          max-width: 220px;
          line-height: 24px;
          font-family: $basierMedium;
          font-weight: 700;
        }
      }
    }
  }
  
}

.restaurant-trend{
  .common_desc{
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: -0.4px;
    padding: 0 0 20px;
    @media (max-width: $sm){
      font-size: 20px;
      padding: 15px 0 20px;
    }
  }
  .tagline_wrapper {
    padding: 70px 0 0;
    .common_title{
      font-size: 56px;
      font-style: normal;
      font-weight: 700;
      line-height: 121%;
      font-family: $basierMedium;
      letter-spacing: -0.56px;
      @media (max-width: $sm){
        font-size: 36px;
      }
    }
  }
  .benifits_section{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 25px;
    margin: 100px 0 0;
    @media (max-width: $sm){
      flex-wrap: wrap;
      margin: 0;
      gap: 0;
    }
    .bene_div{
      padding: 32px 30px;
      border-radius: 12px;
      width: 367px;
      @media (max-width: $sm){
        width: 100%;
        margin: 30px 0 0;
      }
        p{
          color: #171E59;
          font-size: 17.923px;
          font-style: normal;
          font-weight: 500;
          line-height: 110%;
          letter-spacing: -0.358px;
        }
        span{
          font-size: 43.015px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: -0.86px;
          font-family:$basierSemibold;
          display: block;
        }
        .green{
          color: #16b364;
        }
        .red{
          color: #ef6820;
        }
        .blue{
          color: #1570ef;
        }
    }
    .b1{
      background: #EDFCF2;
    }
    .b2{
      background: #FEF6EE;
    }
    .b3{
      background: #F5F8FF;
    }
  }
  #outer_div{
    .Vector{
      position: absolute;
      top: -28%;
      z-index: -1;
      left: 0;
      width:100%;
      @media (max-width: $sm){
        top: 0;
      }
    }
    .survey_fold1{
      .fold_wrapper{
        .fold{
          padding: 160px 0;
          @media (max-width: $sm){
            padding: 0 0 40px;
          }
          .common_title{
            font-size: 56px;
            font-style: normal;
            font-weight: 600;
            line-height: 112.30%;
            letter-spacing: -1.12px;
            padding-bottom: 15px;
            @media (max-width: $sm){
              font-size: 28px;
              line-height: 32px;
              padding: 15px 0 0;
            }
          }
          .fold1img{
            border-radius: 12px;
            position: absolute;
            right: 0;
            top: -4%;
            @media (max-width: $sm){
              position: unset;
            }
          }
          .fold2img{
            position: absolute;
            left: 0;
            top: -20%;
            @media (max-width: $sm){
              position: unset;
            }
          }
          .fold3img{
            position: absolute;
            right: 0;
            top: -20%;
            @media (max-width: $sm){
              position: unset;
            }
          }
        }
        .fold2{
          text-align: right;
          @media (max-width: $sm){
            text-align: center;
          }
        }
      }
    }
  }
  .survey_fold2{
    .survey1{
      border-radius: 40px 40px 0px 0px;
      background: #F7E49B;
      padding: 40px;
      @media (max-width: $sm){
        padding: 20px;
      }
      .tag{
        border-radius: 900px;
        background: #EBBD07;
        color: #5F4D07;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 125%; 
        padding: 12px 30px;
        display: inline-block;
      }
      ol{
        padding: 40px 0 0 40px;
        @media (max-width: $sm){
          padding: 20px 0 0 20px;
        }
        li{
          color: $black;
          font-size: 32px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          @media (max-width: $sm){
            font-size: 20px;
          }
        }
      }
    }
    .survey2{
      border-radius: 0px 0px 40px 40px;
      background: #065151;     
      padding: 10px 40px; 
      @media (max-width: $sm){
        padding: 10px 20px;
      }
      .gift{
        position: absolute;
        right: -68px;
        top: -71px;
        @media (max-width: $sm){
          right: -32px;
          top: unset;
          width: 159px;
          bottom: -21px;
        }
      }
      p{
        font-size: 36px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%; /* 43.2px */
        letter-spacing: -0.36px;
        padding: 30px 0 0;
        @media (max-width: $sm){
          font-size: 20px;
        }
      }
    }
  }
} 
.event_wrapper{
  .event_firstFold{
    gap: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0 0;
    @media (max-width: $md){
      flex-wrap: wrap;
      justify-content: center;
      gap: 40px;
    }
    .event_left_fold{
      max-width: 620px;
      @media (max-width: $md){
        max-width: 100%;
        text-align: center;
      }
      .venue_wrapper{
        display: flex;
        justify-content: center;
        gap: 15px;
        padding: 30px 0 0;
        @media (max-width: $sm){
          padding: 0 0 20px;
        }
        .venue{
          width: 198px;
          height: 146px;
          padding: 16px 10px;
          gap: 12px;
          border-radius: 24px;
          color: $white;
          text-align: center;
          @media (max-width: $sm){
            height: 100px;
            padding: 16px 5px;
            width: 102px;
          }
          .venue_title {
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 6px;
              padding: 0 0 15px;
              @media (max-width: $sm){
                padding: 0 0 7px;
              }
              img{
                width: 14px;
              }
              span{
                font-size: 14px;
                line-height: 110%;
                @media (max-width: $sm){
                  font-size: 9px;
                }
              }
          }
          // span{
          //   font-size: 31px;
          //   line-height: 120%;
          //   font-weight: 600;
          //   @media (max-width: $sm){
          //     font-size: 14px;
          //   }
          // }
          // .hfour{
          //   font-size: 35px;
          //   line-height: 120%;
          //   @media (max-width: $sm){
          //     font-size: 14px;
          //   }
          // }
          p{
            font-size: 20px;
            font-weight: 500;
            line-height: 120%;
            @media (max-width: $sm){
              font-size: 10px;
            }
          }
        }
        .v1 {
          background: #4d80bd;
        }
        .v2 {
          background: #efba50;
        }
        .v3 {
          background: #5fcfbe;
        }
      }
    }
    .event_right_fold{
      width: 100%;
      max-width: 571px;
      @media (max-width: $md){
        max-width: 100%;
      }
      .hurry{
        background: #BEF8F8;
        border-radius: 24px;
        padding: 23px 30px;
        @media (max-width: $sm){
          padding: 20px;
          margin: 0;
        }
        .sec_title{
          font-size: 28px;
          line-height: 38px;
          letter-spacing: -.02em;
          font-weight: 500;
          font-family: $basierSemibold;
          margin:0;
          @media (max-width: $sm){
            font-size: 24px;
            line-height: 28px;
          }
        }
      }
    }
  }
  .display_table{
    flex-direction: unset;
  }
  .banner{
   
    @media (max-width: $sm){
      padding: 60px 0 0;
      text-align: center;
      .common_title{
        font-size: 28px;
        line-height: 32px;
      }
      .common_desc{
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
  .sec-light{
    @media (max-width:$sm){
      letter-spacing: -.02em;
      font-weight: 500;
      font-family:$basierMedium;
      font-size: 24px;
        line-height: 28px;
    }
  }
  .sec-title{
    @media (max-width: $sm){
      font-size: 24px;
      line-height: 28px;
    }
  }
  .sec_title{
    font-size: 42px;
    line-height: 51px;
    letter-spacing: -.02em;
    font-weight: 500;
    font-family: $basierMedium;
    @media (max-width: $sm){
      font-size: 24px;
      line-height: 28px;
    }
   }
   .sec_desc{
    font-size: 20px;
    margin: 0 0 24px;
    line-height: 30px;
    font-family: $basier;
   }
  .common_title{
    font-size: 56px;
    margin: 0 0 16px;
    line-height: 62px;
    letter-spacing: -.01em;
    font-family: $basierMedium;
  }
  .common_desc{
    font-size: 20px;
    margin: 0 0 40px;
    line-height: 30px;
    font-family: $basier;
  }
  .loyaly_div{
    width: 557px;
    margin: 70px auto 0;
    @media (max-width: $sm){
      width: 100%;
      margin: 0;
    }
  }

  .fold1 {
    .sec-title{
      font-size: 32px;
      margin: 0 0 56px;
      line-height: 40px;
      text-align: center;
      letter-spacing: -.01em;
      font-family: $basierSemibold;
      @media (max-width:$sm) {
        font-size: 20px;
        line-height: 24px;
      }
    }
    .fold1_wrapper{
      .reeloBrand_slider{ 
        padding:  0 0 30px;
        .brandSlide{
          height: 120px;
          padding: 0 27px;
          display: flex !important;
          align-items: center;
          justify-content: center;
          padding: 30px 24px;
          @media (max-width:$sm){
            padding: 10px 24px;
            height: 80px;
            width: 120px !important;
          }
          img{
            width: 120px;
            height: 120px;
            object-fit: contain;
            @media (max-width:$sm){
              width: 80px;
              height: 80px;
            }
          }
        }
      }
    }
  }
  .meet-us{
    border-radius: 40px;
    padding: 70px;
    @media (max-width: $md){
      padding: 30px;
    }
    @media (max-width: $xs){
      padding: 20px;
    }
    .meet_us_wrapper{
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      @media (max-width: $sm){
        display: block;
      }
      .meet{
        border-radius: 16px;
        background: #F3F1EE;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 20px;
        padding: 36px;
        margin: 10px;
        @media (max-width: $sm){
          padding: 10px;
          margin: 0 0 15px;
          img{
            width: 65px;
          }
        }
        p{
          font-size: 28px;
          font-weight: 600;
          line-height: 120%;
          letter-spacing: -0.56px;
          @media (max-width: $sm){
            font-size: 20px;
            line-height: 30px;
          }
        }
      }
    }
  }
  .woh_meets{
    .woh_steps{
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 20px;
      padding: 0 0 60px;
      @media (max-width: $sm){
        padding: 0 0 30px;
      }
      .bonus_left{
        max-width: 200px !important;
        @media (max-width: $sm){
          max-width: 110px !important;
        }
        &:before{
          position: unset !important;
        }
      }
      .step_left{
        max-width: 175px;
        z-index: 1;
        @media (max-width: $sm){
          max-width: 110px;
        }
        &:before{
          position: absolute;
          content: '';
          width: 3px;
          height: 94px;
          background-color: #7c0489;
          right: 40px;
          top: 69px;
          @media (max-width: $sm){
            height: 42px;
            right: 25px;
            top: 46px;
          }
        }
        .steps{
          border-radius: 45px;
          background: #ECE6DC;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 8px 16px;
          gap: 20px;
          @media (max-width: $sm){
            padding: 8px 11px;
            gap: 6px;
            justify-content: space-between;
          }
          p{
            color: #570360;
            font-size: 34px;
            font-weight: 900;
            line-height: 130%;
            letter-spacing: -0.678px;
            @media (max-width: $sm){
              font-size: 16px;
            }
          }
          .bonus{
            color: #CD8810;
          }
          .number{
            width: 54.484px;
            height: 54.484px;
            border-radius: 100px;
            background-color: #C998CF;
            padding: 4px 0px;
            @media (max-width: $sm){
              width: 30px;
              height: 30px;
            }
            span{
              font-size: 34px;
              font-weight: 900;
              line-height: 130%;
              letter-spacing: -0.678px;
              padding: 18px;
              @media (max-width: $sm){
                font-size: 20px;
                padding: 10px;
              }
            }
            img{
              padding: 8px 11px;
              @media (max-width: $sm){
                padding: 2px 6px;
              }
            }
          }
          .y{
            background-color: #CD8810;
          }
        }
      }
      .step_right{
        max-width: 500px;
        p{
          font-size: 28px;
          font-weight: 400;
          line-height: 120%;
          letter-spacing: -0.56px;
          @media (max-width: $sm){
            font-size: 16px;
          }
        }
      }
    }
  }
  .lastsection{
    border-radius: 24px;
    padding: 40px 20px;
    @media (max-width: $sm){
      margin: 0 20px;
    }
  }
}
.aahar{
  .event_firstFold{
    gap: 24px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 20px 0 0;
    @media (max-width: $md){
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      gap: 40px;
    }
    .event_left_fold{
      max-width: 620px;
      padding: 30px 0 0;
      @media (max-width: $md){
        max-width: 100%;
        text-align: center;
        padding: 0;
      }
      .venue_wrapper{
        display: flex;
        justify-content: center;
        gap: 15px;
        padding: 30px 0 0;
        @media (max-width: $sm){
          padding: 0 0 20px;
        }
        .venue{
          width: 198px;
          height: 146px;
          padding: 16px 9px;
          gap: 12px;
          border-radius: 24px;
          color: $white;
          text-align: center;
          @media (max-width: $sm){
            height: 100px;
            padding: 16px 5px;
            width: 102px;
          }
          .venue_title {
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 6px;
              padding: 0 0 20px;
              @media (max-width: $sm){
                padding: 0 0 7px;
              }
              img{
                width: 14px;
              }
              span{
                font-size: 14px;
                line-height: 110%;
                @media (max-width: $sm){
                  font-size: 9px;
                }
              }
          }
          p{
            font-size: 20px;
            font-weight: 500;
            line-height: 120%;
            @media (max-width: $sm){
              font-size: 10px;
            }
          }
        }
        .v1 {
          background: #4d80bd;
        }
        .v2 {
          background: #efba50;
        }
        .v3 {
          background: #5fcfbe;
        }
      }
    }
    .event_right_fold{
      width: 100%;
      max-width: 571px;
      @media (max-width: $md){
        max-width: 100%;
      }
      .hurry{
        background: #BEF8F8;
        border-radius: 24px;
        padding: 23px 30px;
        @media (max-width: $sm){
          padding: 20px;
          margin: 0;
        }
        .sec_title{
          font-size: 28px;
          line-height: 38px;
          letter-spacing: -.02em;
          font-weight: 500;
          font-family: $basierSemibold;
          margin:0;
          @media (max-width: $sm){
            font-size: 24px;
            line-height: 28px;
          }
        }
      }
    }
  }
  .display_table{
    flex-direction: unset;
  }
  .banner{
    @media (max-width: $sm){
      padding: 60px 0 0;
      text-align: center;
      .common_title{
        font-size: 28px;
        line-height: 32px;
      }
      .common_desc{
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
  .sec-light{
    @media (max-width:$sm){
      letter-spacing: -.02em;
      font-weight: 500;
      font-family:$basierMedium;
      font-size: 24px;
        line-height: 28px;
    }
  }
  .sec-title{
    @media (max-width: $sm){
      font-size: 24px;
      line-height: 28px;
    }
  }
  .sec_title{
    font-size: 42px;
    line-height: 51px;
    letter-spacing: -.02em;
    font-weight: 500;
    font-family: $basierMedium;
    @media (max-width: $sm){
      font-size: 24px;
      line-height: 28px;
    }
   }
   .sec_desc{
    font-size: 20px;
    margin: 0 0 24px;
    line-height: 30px;
    font-family: $basier;
   }
  .common_title{
    font-size: 56px;
    margin: 0 0 16px;
    line-height: 62px;
    letter-spacing: -.01em;
    font-family: $basierMedium;
  }
  .common_desc{
    font-size: 20px;
    line-height: 30px;
    margin: 0 0 30px;
    font-family: $basier;
  }
  .loyaly_div{
    width: 557px;
    margin: 70px auto 0;
    @media (max-width: $sm){
      width: 100%;
      margin: 0;
    }
  }

  .fold1 {
    .container{
      padding: 0;
      @media (max-width:$sm) {
        padding: 0 20px;
      }
    }
    .sec-title{
      font-size: 32px;
      margin: 0 0 56px;
      line-height: 40px;
      text-align: center;
      letter-spacing: -.01em;
      font-family: $basierSemibold;
      @media (max-width:$sm) {
        font-size: 20px;
        line-height: 24px;
      }
    }
    .fold1_wrapper{
      .reeloBrand_slider{ 
        padding:  0 0 30px;
        .brandSlide{
          height: 120px;
          padding: 0 27px;
          display: flex !important;
          align-items: center;
          justify-content: center;
          padding: 30px 24px;
          @media (max-width:$sm){
            padding: 10px 24px;
            height: 80px;
            width: 120px !important;
          }
          img{
            width: 120px;
            height: 120px;
            object-fit: contain;
            @media (max-width:$sm){
              width: 80px;
              height: 80px;
            }
          }
        }
      }
    }
  }
  .meet-us{
    border-radius: 40px;
    padding: 70px;
    @media (max-width: $md){
      padding: 30px;
    }
    @media (max-width: $xs){
      padding: 20px;
    }
    .meet_us_wrapper{
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      @media (max-width: $sm){
        display: block;
      }
      .meet{
        border-radius: 16px;
        background: #F3F1EE;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 20px;
        padding: 36px;
        margin: 10px;
        @media (max-width: $sm){
          padding: 10px;
          margin: 0 0 15px;
          img{
            width: 65px;
          }
        }
        p{
          font-size: 28px;
          font-weight: 600;
          line-height: 120%;
          letter-spacing: -0.56px;
          @media (max-width: $sm){
            font-size: 20px;
            line-height: 30px;
          }
        }
      }
    }
  }
  .woh_meets{
    .woh_steps{
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 20px;
      padding: 0 0 60px;
      @media (max-width: $sm){
        padding: 0 0 30px;
      }
      .bonus_left{
        max-width: 200px !important;
        @media (max-width: $sm){
          max-width: 110px !important;
        }
        &:before{
          position: unset !important;
        }
      }
      .step_left{
        max-width: 175px;
        z-index: 1;
        @media (max-width: $sm){
          max-width: 110px;
        }
        &:before{
          position: absolute;
          content: '';
          width: 3px;
          height: 94px;
          background-color: #7c0489;
          right: 40px;
          top: 69px;
          @media (max-width: $sm){
            height: 42px;
            right: 25px;
            top: 46px;
          }
        }
        .steps{
          border-radius: 45px;
          background: #ECE6DC;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 8px 16px;
          gap: 20px;
          @media (max-width: $sm){
            padding: 8px 11px;
            gap: 6px;
            justify-content: space-between;
          }
          p{
            color: #570360;
            font-size: 34px;
            font-weight: 900;
            line-height: 130%;
            letter-spacing: -0.678px;
            @media (max-width: $sm){
              font-size: 16px;
            }
          }
          .bonus{
            color: #CD8810;
          }
          .number{
            width: 54.484px;
            height: 54.484px;
            border-radius: 100px;
            background-color: #C998CF;
            padding: 4px 0px;
            @media (max-width: $sm){
              width: 30px;
              height: 30px;
            }
            span{
              font-size: 34px;
              font-weight: 900;
              line-height: 130%;
              letter-spacing: -0.678px;
              padding: 18px;
              @media (max-width: $sm){
                font-size: 20px;
                padding: 10px;
              }
            }
            img{
              padding: 8px 11px;
              @media (max-width: $sm){
                padding: 2px 6px;
              }
            }
          }
          .y{
            background-color: #CD8810;
          }
        }
      }
      .step_right{
        max-width: 500px;
        p{
          font-size: 28px;
          font-weight: 400;
          line-height: 120%;
          letter-spacing: -0.56px;
          @media (max-width: $sm){
            font-size: 16px;
          }
        }
      }
    }
  }
  .lastsection{
    border-radius: 24px;
    padding: 40px 20px;
    @media (max-width: $sm){
      margin: 0 20px;
    }
  }
}
.icon-wrapper{
  display: inline-block;
  float: right;
  position: absolute;
  right: 0;
}
/* Tooltip styling */
.tooltip-text {
    background: #FFF;
    box-shadow: -2px 4px 10px 0px rgba(89, 60, 29, 0.12);
    padding: 12px;
    border-radius: 5px;
    white-space: nowrap;
    margin-top: 5px;
    color: #374040;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    display: none;
    position: absolute;
    right: -50px;
    top: -164px;
    text-wrap: auto;
    z-index: 1;
    width: 290px;
    text-transform: none;
    @media (max-width: $sm){
      right: 0;
      font-size: 16px;
    }
}

/* Tooltip arrow */
.tooltip-text::after {
  content: "";
    position: absolute;
    bottom: -10px;
    right: 18%;
    transform: translateX(-50%) rotate(180deg);
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #fff;
    @media (max-width: $sm){
      right: 0;
    }
}

/* Show tooltip on hover */
.icontip:hover + .tooltip-text {
  display: block;
}
.icontip {
  float: right;
}
.inspiration-reelo{
  .banner{
    padding: 120px 70px 70px;
    @media (max-width: $sm){
      padding: 70px 0 40px;
    }
    .inspi1{
      width: 380px;
      @media (max-width: $sm){
        display: none;
      }
    }
    .inspired{
      width:280px;
      @media (max-width: $sm){
        width: 200px;
      }
    }
    .inspi2{
      width: 380px;
      @media (max-width: $sm){
        width: 100%;
        padding: 0 20px;
      }
    }
    .tag_wrapper {
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      left: 50%;
      text-align: center;
      @media (max-width: $sm){
        position: relative;
        transform: unset;
        left: unset;
        padding: 30px;
      }
    }
  }
  .all_inspiration{
    background: linear-gradient(0deg, #FFF 0.39%, #FFF9F0 15.98%, #FFF9F0 81.44%, #FFF 99.92%);
    .dispFlex{
      display: flex;
      @media (max-width:$sm){
        flex-wrap: wrap;
      }
    }
    .Inspiration_Title{
      font-size: 42px;
      font-weight: 600;
      line-height: 120%;
      letter-spacing: -0.84px;
      @media (max-width:$sm){
        font-size: 24px;
      }
    }
    .sticky-fixed {
      position: fixed;
      top: 45px;
      width: 280px;
      @media (max-width:$sm){
        width: 100%;
        position: unset;
      }
    }
    .sidebar{
      padding: 60px 30px 0 0;
      width: 280px;
      @media (max-width: $sm){
      padding: 0;
      width: 100%;
      }
      .filters{
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 60px; /* 300% */
        letter-spacing: -0.4px;
        text-transform: uppercase;
      }
      .search_div {
        padding: 0 !important;
        .search-container{
          button {
            background: #fff;
            font-size: 0;
            border: none;
            cursor: pointer;
            position: absolute;
            left: 2px;
            bottom: 10px;
          }
          input[type=text] {
            padding: 8px 6px 8px 32px;
            font-size: 17px;
            border-radius: 90px;
            margin: 10px 0 40px;
          }
        }
      }
      .filter_wrapper {
        .categorySlider{
          li{
            display: block;
            padding-bottom: 30px;
            position: relative;
            @media (max-width: $sm){
             padding-bottom: 24px;
            }
            a{
              color: #023342;
              font-size: 20px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              text-transform: uppercase;  
              &.active{
                color: $blue;
              }
              @media (max-width: $sm){
                font-size: 18px;
              } 
              .icon-wrapper{
                display: inline-block;
                float: right;
              }     
            }
         
          }
        }
        .custom-dropdown{
          .dropdown-button {
            color: #023342;
            font-family: "Basier Circle";
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            background-color: unset;
          }
          .dropdown-menu {
            // display: none; /* Hide the dropdown items by default */
            position: absolute;
            background-color: #f9f9f9;
            min-width: 160px;
            box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
            z-index: 1;
            padding: 20px;
            margin: 20px 0 0;
            li{
              list-style-type: none;
              padding: 0 0 5px;
              .category-option{
                display: inline-block;
                width: unset;
                border: none;
                box-shadow: none;
              }
            }
          }
          .dropdown-arrow {
            img {
              transition: transform 0.3s ease; /* Smooth transition for rotation */
              border: 1px solid #01999F;
              padding: 5px;
              border-radius: 90px;
              vertical-align: bottom;
            }
          }
        }
        .share_wrapper{
          padding: 60px 0 0;
          .inspired {
            color: #374040;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 130%;
            padding: 0 0 10px;
            a{
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 130%;
            }
          }
        }
      }
    }
    
    .inspiration_wrapper{
      min-height: 600px;
      .masonary-grid{ 
        display: flex;
        flex-wrap: wrap;
        margin-left: -30px;
        width: auto;
        padding: 40px 0;
        @media (max-width: $sm){
         margin-left: 0;
         padding: 40px 0;
        }
        .masonary-column{
          padding-left: 30px;
          background-clip: padding-box;
          width: 33.3%;
          @media (max-width: $sm){
           padding-left: 0;
           width: 100%;
          }
          .hover_wrapper{
            border-radius: 12px;
            background: #FFF;
            box-shadow: -2px 4px 10px 0px rgba(89, 60, 29, 0.12);
            display: block;
            margin: 0 0 20px;
            padding: 12px;
            &:hover{
              .inspi-box{
                .inspi_Img {
                  &:before{
                    position: absolute;
                    content: '';
                    width: 100%;
                    height: 99%;
                    background: #00000080;
                    background-blend-mode: multiply, normal;
                    border-radius: 12px;
                  }
                  .vstory{
                    bottom: 20px;
                  }
                }
              }
            }
            // .inspi_wrapper{
            //   &:hover{
            //     .hover_div {
            //       opacity: 1;
            //     }
            //   }
            //   .hover_div {
            //     position: absolute;
            //     width: 100%;
            //     height: 100%;
            //     border-radius: 12px;
            //     padding: 50px 12px 0;
            //     top: 50%;
            //     transform: translate(-50%, -50%);
            //     left: 50%;
            //     opacity: 0;
            //     // .inspi_desc{
            //     //   .inspi-subtitle{
            //     //     font-size: 28px;
            //     //     font-weight: 700;
            //     //     line-height: 120%;
            //     //     padding: 12px 0;
            //     //   }
            //     //   .inspi-title{
            //     //     font-size: 18px;
            //     //     font-weight: 500;
            //     //     line-height: 120%;
            //     //   }
            //     // }
            //     // .vstory{
            //     //   color: #1A424F;
            //     //   font-size: 20px;
            //     //   font-style: normal;
            //     //   font-weight: 500;
            //     //   line-height: normal;
            //     //   text-transform: uppercase;
            //     //   padding: 12px;
            //     //   border-radius: 90px;
            //     //   background: #FFF;
            //     //   box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            //     //   position: absolute;
            //     //   bottom: 20px;
            //     //   left: 50%;
            //     //   transform: translateX(-50%);
            //     //   display: none;
            //     //   min-width: 212px;
            //     //   text-align: center;
            //     //   transition: bottom 0.3s ease-in-out;
            //     // }
            //   }
            //   .inspi_img{
            //     .fimg{
            //       border-radius: 12px;
            //       // height: 272px;
            //     }
            //   }
            //   .inspi_content{
            //     padding: 12px;
            //     margin: -7px 0 0;
            //     border-radius: 0 0 12px 12px;
            //     .inspi-category{
            //       font-size: 18px;
            //       font-style: normal;
            //       font-weight: 600;
            //       line-height: normal;
            //       text-transform: uppercase;
            //       padding-bottom: 10px;
            //       letter-spacing: 1px;
            //       padding: 6px 12px;
            //     }
            //     .inspi-pos{
            //       padding: 6px 12px;
            //       color: #7B3700;
            //       font-size: 14px;
            //       font-weight: 400;
            //       line-height: normal;
            //       text-transform: uppercase;
            //       display: inline-block;
            //       border-radius: 90px;
            //     }
            //   }
            // }
            .inspi-box{
              .inspi_Img {
                margin-bottom: 16px;
                overflow: hidden;
                .vstory{
                  color: #1A424F;
                  font-size: 20px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: normal;
                  text-transform: uppercase;
                  padding: 12px;
                  border-radius: 90px;
                  background: #FFF;
                  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                  position: absolute;
                  bottom: -60px;
                  left: 50%;
                  transform: translateX(-50%);
                  min-width: 212px;
                  text-align: center;
                  transition: all 0.4s ease;
                }
                .fimg{
                  border-radius: 12px;
                  // height: 272px;
                }
              }
              .inspi-title{
                color: #171717;
                font-size: 16px;
                line-height: normal;
                text-transform: capitalize;
              }
              .inspi-category{
                padding: 6px 12px;
                font-size: 14px;
                font-weight: 400;
                line-height: normal;
                text-transform: uppercase;
                margin: 10px 0;
                display: inline-block;
                border-radius: 90px;
                letter-spacing: 1px;                
              }
              .inspi-pos{
                padding: 6px 12px;
                color: #7B3700;
                font-size: 14px;
                font-weight: 400;
                line-height: normal;
                text-transform: uppercase;
                display: inline-block;
                border-radius: 90px;
              }
            }
          }
        }
      }
    }
  }
  .lastsection{
    border-radius: 24px;
    padding: 40px 20px;
    @media (max-width: $sm){
      margin: 0 20px;
    }
  }
}
.inspiration-single{
  .banner{
    border-radius: 20px;
    background: #F4AA16;
    padding: 30px 40px;
    @media (max-width: $sm){
      padding: 30px 20px;
    }
    .displayFlex{
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media (max-width: $sm){
       flex-wrap: wrap;
       gap: 15px;
      }
      .logoFold{
        display: flex;
        align-items: center;
        gap:20px;
        .inspiTitle{
          font-size: 32px;
          font-weight: 600;
          line-height: 60px;
          letter-spacing: -0.64px;
          @media (max-width: $sm){
            font-size: 22px;
          }
        }
      }
      .tagsFold{
        .category{
          font-size: 18px;
          font-weight: 600;
          line-height: normal;
          text-transform: uppercase;
          padding-bottom: 45px;
          text-align: right;
          @media (max-width: $sm){
            font-size: 16px;
            padding-bottom: 25px;
            text-align: left;
          }
        }
        .inspi-brand, .inspi-pos{
          color: #A06A00;
          font-size: 14px;
          font-weight: 400;
          line-height: normal;
          text-transform: uppercase;
          border-radius: 90px;
          background: #FACE77;
          padding: 6px 16px;
        }
        .inspi-brand{
          margin: 0 10px 0 0;
        }
      }
    }
  }
  .breadcrumbs{
    a{
      color: $black;
      &:hover{
        color: $blue;
      }
    }
    .post_color{
      color: $blue !important;
    }
  }
  .overview{
    .overviewFold{
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      @media (max-width: $sm){
        flex-wrap: wrap;
        gap: 30px;
        display: unset;
      }
      .overviewLeft{
        max-width: 605px;
        @media (max-width: $sm){
          max-width: 100%;
        }
        .logoFold{
          display: flex;
          align-items: center;
          gap:20px;
          padding-bottom: 20px;
          
          .inspiTitle{
            font-size: 32px;
            font-weight: 600;
            line-height: 60px;
            letter-spacing: -0.64px;
           
            @media (max-width: $sm){
              font-size: 22px;
            }
          }
        }
        .overview-title{
          font-size: 42px;
          line-height: 120%;
          padding-bottom: 40px;
          @media (max-width: $sm){
           font-size: 24px;
           padding-bottom: 20px;
          }
        }
        .about{
          color: #0E1B1B;
          font-size: 20px;
          font-weight: 600;
          line-height: 60px; /* 300% */
          letter-spacing: -0.4px;
          text-transform: uppercase;
        }
        .overview_desc{
          color: #374040;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 30px; /* 150% */
          @media (max-width: $sm){
            font-size: 16px;
            padding-bottom: 22px;
           }
        }
        .btn_wrapper{
          display: flex;
          align-items: center;
          gap: 6px;
          @media (max-width: $sm){
            gap: 16px;
            flex-wrap: wrap;
          }
        }
      }
      .overviewRight{
        max-width: 605px;
        @media (max-width: $sm){
          max-width: 100%;
        }
        .single-img-wrapper{
          img{
              width: 463px;
              height: 489px;
              border-radius: 12px;
              @media (max-width: $sm){
                height: 370px;
                width: 100%;
            }
          }
        }
        .custom-gallery{
          width: 463px;
          height: 489px;
          @media (max-width: $sm){
            width: 100%;
            height: unset;
          }
          .slick-dots {
            display: flex;
              margin: 0;
              list-style-type: none;
              position: absolute;
              bottom: 20px;
              left: 50%;
              transform: translateX(-50%);
              button {
                display: block;
                width: 13px;
                height: 13px;
                padding: 0;
                background-color: $black;
                text-indent: -9999px;
                cursor: pointer;
                border-radius: 30px;
                border: unset;
                margin: 0 6px;
              }
            }
            li.slick-active {
              button {
                background-color: #d2cfcf;
              }
            }
          .imgSlide{
            img{
              width: 100%;
              height: 489px;
              border-radius: 12px;
              @media (max-width: $sm){
                height: 370px;
              }
            }
          }
        }
        // .custom-gallery{
        //   width: 463px;
        //   height: 489px;
        //   overflow-x: scroll;
        //   scrollbar-width: none;
        //   @media (max-width: $sm){
        //     width: 100%;
        //     height: 370px;
        //   }
        //   &::-webkit-scrollbar {
        //     display: none; /* Chrome, Safari */
        // }
        // }
      }
    }
  }
  .category_section{
    .categoryFold{
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      @media (max-width: $sm){
        flex-wrap: wrap;
        gap: 30px;
      }
      .categoryLeft{
        width: 705px;
        min-height: 367px;
        padding: 70px 0;    
        .categoryDiv{
          position: absolute;
          width: 80%;
          top: 44%;
          transform: translateY(-50%);
          @media (max-width: $sm){
            position: unset;
            transform: unset;
            width: 100%;
            top:unset;
          }
          p{
            font-size: 18px;
            line-height: 130%;
            @media (max-width: $sm){
              font-size: 16px;
            }
          }
        }
        @media (max-width: $sm){
          width: 100%;
          padding: 0;
          min-height: unset;
        }
        .category{
          font-size: 40px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          text-transform: uppercase;
          padding-bottom: 16px;
          @media (max-width: $sm){
            font-size: 16px;
            padding-bottom: 25px;
            text-align: left;
            img{
              width: 18px;
            }
          }
        }
      }
      .categoryRight{
        max-width: 605px;
        img{
          bottom: -5px;
        }
        @media (max-width: $sm){
          max-width: 100%;
        }
      }
    }
  }
  .testimonial{
    .quote{
      color: #01999F;
      font-size: 56px;
      line-height: 120%; /* 67.2px */
      letter-spacing: -0.56px;
      @media (max-width: $sm){
        font-size: 30px;
       }
    }
    .quoteFlex{
      display: flex;
      align-items: baseline;
      justify-content: center;
      gap: 20px;
      padding: 50px 0 0;
      .sep{
        &:before {
          position: absolute;
          content: '';
          width: 1px;
          height: 50px;
          background-color: #01999F;
          top: -50px;
        }
      }
      .quoteDiv {
        display: flex;
        align-items: end;
        img{
          border-radius: 90%;
          width: 52px;
        }
        .client{
          padding: 0 0 0 18px;
          .name{
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 21px; 
          }
          .desig{
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 25.026px; /* 156.41% */
            letter-spacing: 0.104px;
          }
        }
      }
    }
  }
  .about-section{
    .about-inspi{
      border-radius: 20px;
      background: #E7F8F9;
       .aboutFold{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        @media (max-width: $sm){
          flex-wrap: wrap;
        }
        .aboutLeft{
          padding: 60px 97px;
          width: 750px;
          height: 510px;
          .about_block {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 80%;
            @media (max-width: $sm){
              padding: 40px 20px;
              width: 100%;
              height: unset;
              position: unset;
              transform: unset;
            }
          }
          @media (max-width: $sm){
           width: 100%;
           height: unset;
           padding: 0;
          }
          .title{
            color: #092D2C;
            font-size: 42px;
            font-style: normal;
            line-height: 120%; /* 50.4px */
            padding-bottom: 40px;
            @media (max-width: $sm){
              font-size: 24px;
              padding-bottom: 20px;
             }
          }
          .desc{
            color: #374040;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 30px;
            @media (max-width: $sm){
              font-size: 16px;
             }
            p{
              max-width: 560px;
            }
          }
        }
        .aboutRight{
          max-width: 450px;
          .single-img-wrapper{
              img{
                width: 100%;
                height: 510px;
                margin: 0 0 0 auto;
                border-radius: 0 12px 12px 0;
                @media (max-width: $sm){
                  border-radius: 0 0 12px 12px;
                  height: 370px;
                }
              }
          }
          .media_Slider{
            .slick-dots {
              display: flex;
                margin: 0;
                list-style-type: none;
                position: absolute;
                bottom: 20px;
                left: 50%;
                transform: translateX(-50%);
                button {
                  display: block;
                  width: 13px;
                  height: 13px;
                  padding: 0;
                  background-color: #d2cfcf;
                  text-indent: -9999px;
                  cursor: pointer;
                  border-radius: 30px;
                  border: unset;
                  margin: 0 6px;
                }
              }
              li.slick-active {
                button {
                  background-color: $white;
                }
              }
            .imgSlide{
              img{
                width: 100%;
                height: 510px;
                margin: 0 0 0 auto;
                border-radius: 0 12px 12px 0;
                @media (max-width: $sm){
                  border-radius: 0 0 12px 12px;
                  height: 370px;
                }
              }
            }
          }
          @media (max-width: $sm){
            max-width: 100%;
          }
        }
      }
    }
  }
  .recentBlogs{
    .title{
      font-size: 42px;
      font-style: normal;
      line-height: 120%; /* 50.4px */
      padding-bottom: 30px;
      @media (max-width: $sm){
        font-size: 24px;
        padding-bottom: 0;
       }
    }
    .blog_wrapper{
      .inspi_wrapper{
        padding: 0 20px 0 0;
        min-height: 480px;
        @media (max-width: $sm){
         padding: 30px 0;
         min-height: unset;
         }
        .fimg{
          // height: 272px;
          border-radius: 12px;
        }
        .inspi-category{
          padding: 6px 12px;
          font-size: 14px;
          font-weight: 400;
          line-height: normal;
          text-transform: uppercase;
          margin: 10px 0;
          display: inline-block;
          border-radius: 90px;
          letter-spacing: 1px; 
         }
        .inspi-title{
          color: #1A424F;
          font-size: 20px;
          font-style: normal;
          line-height: 120%; 
          padding: 16px 0 10px;
        }
      }
      @media (max-width: $sm){
        padding: 0;
      }
    }
  }
  .lastsection{
    border-radius: 24px;
    padding: 40px 20px;
    @media (max-width: $sm){
      margin: 0 20px;
    }
  }
}
.error-page{
  .error_wrapper{
   height: 100vh;
   .not-found-page {
    position: absolute;
    width: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    @media (max-width: $sm){
      padding: 40px 20px;
    }
    .sec_title{
      font-size: 120px;
      line-height: 168px;
    }
    .sec-title{
      padding: 30px 0 10px;
      font-family: $basierMedium;
      @media (max-width: $sm){
        font-size: 30px;
        line-height: 40px;
      }
    }
    .sec-heading2{
      @media (max-width: $sm){
        font-size: 24px;
        line-height: 34px;
      }
    }
  }
  }
}
.thankyou-page{
  padding: 20px 0 0;
  @media (max-width: $sm){
    padding: 40px 0 0;
  }
  section{
    background: #f2f9f6;
  }
  .common_title{
    font-size: 48px;
    margin: 0 0 10px;
    color: #092d2c;
    line-height: 60px;
    letter-spacing: -.05em;
    font-family: $basierSemibold;
    @media (max-width: $sm){
      font-size: 40px;
      line-height: 56px;
    }
  }
  .common_desc{
    font-size: 20px;
    margin: 0 0 40px;
    color: #092d2c;
    line-height: 32px;
    @media (max-width: $sm){
      font-size: 18px;
      line-height: 28px;
    }
  }
  .ThankYou_fold{
    gap: 54px;
    display: flex;
    align-items: stretch;
    justify-content: center;
    @media (max-width: $sm){
      flex-wrap: wrap;
    }
    .ThankYou_fold_left_side{
      width: 100%;
      max-width: 628px;
      padding: 60px 30px;
      background: $white;
      box-shadow: 0 1px 3px rgba(63, 63, 68, .15), 0 0 0 1px rgba(63, 63, 68, .05);
      border-bottom: 5px solid #26a0a0;
      @media (max-width: $sm){
        padding: 24px;
      }
      .ThankYou_fold_img{
        img{
          height: 250px;
          margin: 0 auto;
          position: relative;
          @media (max-width: $sm){
            height: unset;
          }
        }
      }
      h2{
        font-size: 30px;
        color: #092d2c;
        line-height: 38px;
        text-align: center;
        margin: 28px auto;
        letter-spacing: -.05em;
        font-family: $basierSemibold;
        @media (max-width: $sm){
          font-size: 24px;
          line-height: 32px;
        }
      }
    }
    .ThankYou_fold_right_side{
      width: 100%;
      max-width: 412px;
      padding: 40px 32px;
      background: $white;
      box-shadow: 0 1px 3px rgba(63, 63, 68, .15), 0 0 0 1px rgba(63, 63, 68, .05);
      .ThankYou_fold_img{
        width: fit-content;
        margin: 0 auto 40px;
      }
      p{
        font-size: 24px;
        color: #092d2c;
        margin: 0 0 110px;
        line-height: 32px;
        text-align: center;
        letter-spacing: -.05em;
        font-weight: 600;
        @media (max-width: $sm){
          margin: 0 0 30px;
        }
      }
    }
  }
}

.dynamic_cta_wrapper{
    border-radius: 16px;
    background-blend-mode: multiply, normal, normal;
    display: flex;
    padding: 30px 40px;
    margin: 20px 0 0;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    align-items: flex-end;
    justify-content: space-between;
    @media (max-width: $sm){
      flex-wrap: wrap;
      padding: 20px;
    }
  .content_wrapper{
    .common-title{
      font-family: $basierMedium;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      text-align: left;
      line-height: 110%;
      @media (max-width: $sm){
        font-size: 26px;
        line-height: 36px;
      }
    }
    .common-desc{
      font-size: 16px;
      text-align: left;
      font-style: normal;
      font-weight: 400;
      line-height: 130%; 
      padding: 10px 0 0;
      @media (max-width: $sm){
        font-size: 18px;
        line-height: 26px;
      }
    }
  }
  .btn_wrapper{
    margin: 30px 0 0;
    .yellow-btn {
      padding: 8px 12px;
    }
  }
}
#hubspot-conversations-iframe {
  display: initial !important;
  border: none !important;
  position: absolute !important;
  bottom: 0 !important;
  right: 0 !important;
  background: transparent !important;
  z-index: -1;
}

#close, #formclose {
  top: 5px;
  right: 10px;
  position: absolute;
  font-size: 30px;
  color: $blue;
  cursor: pointer;
  z-index: 9999;
}

#overlay, #formoverlay{
  overflow: auto;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 9999;
}
#popup{
  width: 60%;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  border: 1px solid #1C3A693C;
  border-radius: 10px;
  opacity: 1;
  padding: 25px;
  .refund{
    .refund_title {
      font-size: 20px;
      line-height: 30px;
      font-weight: 500;
      text-decoration: underline;
      padding: 0 0 20px;
   }
   p{
    padding: 5px 0;
   }
  }
  @media (max-width: $sm){
    width: 90%;
  }
}
#formpopup {
  width: 60%;
  background: rgba(250, 254, 255, 1);
  position: absolute;
  top: 52%;
  left: 50%;
  transform: translate(-50%,-50%);
  border: 1px solid #1C3A693C;
  border-radius: 10px;
  opacity: 1;
  padding: 25px 25px 0;
  height: 540px;
  overflow: auto;
  @media (max-width: $sm){
    width: 90%;
  }
  .formWrapper {
    display: flex;
    align-items: center;
    @media (max-width: $sm){
      display: block;
      .demoform{
        padding: 30px 0 0;
      }
    }
  }
}
/*** iPhone and iOS Form Input Zoom Fixes ***/
/* Fix Input Zoom on devices older than iPhone 5: */
@media screen and (device-aspect-ratio: 2/3) {
  select, textarea, input[type="text"], input[type="password"],
  input[type="datetime"], input[type="datetime-local"],
  input[type="date"], input[type="month"], input[type="time"],
  input[type="week"], input[type="number"], input[type="email"],
  input[type="url"]{ font-size: 16px; }
}

/* Fix Input Zoom on iPhone 5, 5C, 5S, iPod Touch 5g */
@media screen and (device-aspect-ratio: 40/71) {
  select, textarea, input[type="text"], input[type="password"],
  input[type="datetime"], input[type="datetime-local"],
  input[type="date"], input[type="month"], input[type="time"],
  input[type="week"], input[type="number"], input[type="email"],
  input[type="url"]{ font-size: 16px; }
}

/* Fix Input Zoom on iPhone 6, iPhone 6s, iPhone 7  */
@media screen and (device-aspect-ratio: 375/667) {
  select, textarea, input[type="text"], input[type="password"],
  input[type="datetime"], input[type="datetime-local"],
  input[type="date"], input[type="month"], input[type="time"],
  input[type="week"], input[type="number"], input[type="email"],
  input[type="url"]{ font-size: 16px; }
}

/* Fix Input Zoom on iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus, iPhone 8, iPhone X, XS, XS Max  */
@media screen and (device-aspect-ratio: 9/16) {
  select, textarea, input[type="text"], input[type="password"],
  input[type="datetime"], input[type="datetime-local"],
  input[type="date"], input[type="month"], input[type="time"],
  input[type="week"], input[type="number"], input[type="email"],
  input[type="url"]{ font-size: 16px; }
}