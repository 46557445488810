$basier:'Basier Circle';
$basierSemibold: 'Basier Circle Semibold'; 
$basierMedium:'Basier Circle medium';
$primary_color:#63FAFF;
$blue: #0d9298;
$grey: #697777;
$yellow:#ebbd07;
$titledark:#1A424F;
$black: #000;
$white: #fff;
$light:#354545;
$color1:#0e1b1b;
$color2:#374040;
$color3:#023342;
$bg-white: #fff;
$bg-light: #EDFCF2;
$bg-grey: #E9EDED;
$bg-blue: #0D9298;
$bg-light:#C2F6F6;
$bgBlue:#e9fff8;
$bg-pink:#fff5f5;
$bg-colorpink:#fdf5ff;
$bg-green:#065151;
$lg:1800px;
$md:1024px;
$sm:992px;
$xs:767px;
$xss:330px;