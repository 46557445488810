@charset "UTF-8";
@font-face {
  font-family: "Basier Circle";
  src: url("../fonts/BasierCircle-Regular.eot");
  src: url("../fonts/BasierCircle-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/BasierCircle-Regular.woff2") format("woff2"), url("../fonts/BasierCircle-Regular.woff") format("woff"), url("../fonts/BasierCircle-Regular.ttf") format("truetype"), url("../fonts/BasierCircle-Regular.svg#BasierCircle-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Basier Circle Semibold";
  src: url("../fonts/BasierCircle-SemiBold.eot");
  src: url("../fonts/BasierCircle-SemiBold.eot?#iefix") format("embedded-opentype"), url("../fonts/BasierCircle-SemiBold.woff2") format("woff2"), url("../fonts/BasierCircle-SemiBold.woff") format("woff"), url("../fonts/BasierCircle-SemiBold.ttf") format("truetype"), url("../fonts/BasierCircle-SemiBold.svg#BasierCircle-SemiBold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Basier Circle Medium";
  src: url("../fonts/BasierCircle-Medium.eot");
  src: url("../fonts/BasierCircle-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/BasierCircle-Medium.woff2") format("woff2"), url("../fonts/BasierCircle-Medium.woff") format("woff"), url("../fonts/BasierCircle-Medium.ttf") format("truetype"), url("../fonts/BasierCircle-Medium.svg#BasierCircle-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
.intl-tel-input {
  width: 100%;
  position: relative;
  display: inline-block;
}

.intl-tel-input * {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.intl-tel-input .hide {
  display: none;
}

.intl-tel-input .v-hide {
  visibility: hidden;
}

.intl-tel-input input, .intl-tel-input input[type=text], .intl-tel-input input[type=tel] {
  position: relative;
  z-index: 0;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-right: 36px;
  margin-right: 0;
}

.intl-tel-input .flag-container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  padding: 1px;
}

.intl-tel-input .selected-flag {
  z-index: 1;
  position: relative;
  width: 36px;
  height: 100%;
  padding: 0 0 0 8px;
}

.intl-tel-input .selected-flag .iti-flag {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}

.intl-tel-input .selected-flag .iti-arrow {
  position: absolute;
  top: 50%;
  margin-top: -2px;
  right: 6px;
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 4px solid #555;
}

.intl-tel-input .selected-flag .iti-arrow.up {
  border-top: none;
  border-bottom: 4px solid #555;
}

.intl-tel-input .country-list {
  position: absolute;
  z-index: 2;
  list-style: none;
  text-align: left;
  padding: 0;
  margin: 0 0 0 -1px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  background-color: white;
  border: 1px solid #CCC;
  white-space: nowrap;
  max-height: 200px;
  overflow-y: scroll;
}

.intl-tel-input .country-list.dropup {
  bottom: 100%;
  margin-bottom: -1px;
}

.intl-tel-input .country-list .flag-box {
  display: inline-block;
  width: 20px;
}

@media (max-width: 500px) {
  .intl-tel-input .country-list {
    white-space: normal;
  }
}
.intl-tel-input .country-list .divider {
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-bottom: 1px solid #CCC;
}

.intl-tel-input .country-list .country {
  padding: 5px 10px;
}

.intl-tel-input .country-list .country .dial-code {
  color: #999;
}

.intl-tel-input .country-list .country.highlight {
  background-color: rgba(0, 0, 0, 0.05);
}

.intl-tel-input .country-list .flag-box, .intl-tel-input .country-list .country-name, .intl-tel-input .country-list .dial-code {
  vertical-align: middle;
}

.intl-tel-input .country-list .flag-box, .intl-tel-input .country-list .country-name {
  margin-right: 6px;
}

.intl-tel-input.allow-dropdown input, .intl-tel-input.allow-dropdown input[type=text], .intl-tel-input.allow-dropdown input[type=tel], .intl-tel-input.separate-dial-code input, .intl-tel-input.separate-dial-code input[type=text], .intl-tel-input.separate-dial-code input[type=tel] {
  padding-right: 6px;
  padding-left: 52px;
  margin-left: 0;
}

.intl-tel-input.allow-dropdown .flag-container, .intl-tel-input.separate-dial-code .flag-container {
  right: auto;
  left: 0;
}

.intl-tel-input.allow-dropdown .selected-flag, .intl-tel-input.separate-dial-code .selected-flag {
  width: 46px;
}

.intl-tel-input.allow-dropdown .flag-container:hover {
  cursor: pointer;
}

.intl-tel-input.allow-dropdown .flag-container:hover .selected-flag {
  background-color: rgba(0, 0, 0, 0.05);
}

.intl-tel-input.allow-dropdown input[disabled] + .flag-container:hover, .intl-tel-input.allow-dropdown input[readonly] + .flag-container:hover {
  cursor: default;
}

.intl-tel-input.allow-dropdown input[disabled] + .flag-container:hover .selected-flag, .intl-tel-input.allow-dropdown input[readonly] + .flag-container:hover .selected-flag {
  background-color: transparent;
}

.intl-tel-input.separate-dial-code .selected-flag {
  background-color: rgba(0, 0, 0, 0.05);
  display: table;
}

.intl-tel-input.separate-dial-code .selected-dial-code {
  display: table-cell;
  vertical-align: middle;
  padding-left: 28px;
}

.intl-tel-input.separate-dial-code.iti-sdc-2 input, .intl-tel-input.separate-dial-code.iti-sdc-2 input[type=text], .intl-tel-input.separate-dial-code.iti-sdc-2 input[type=tel] {
  padding-left: 66px;
}

.intl-tel-input.separate-dial-code.iti-sdc-2 .selected-flag {
  width: 60px;
}

.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-2 input, .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-2 input[type=text], .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-2 input[type=tel] {
  padding-left: 76px;
}

.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-2 .selected-flag {
  width: 70px;
}

.intl-tel-input.separate-dial-code.iti-sdc-3 input, .intl-tel-input.separate-dial-code.iti-sdc-3 input[type=text], .intl-tel-input.separate-dial-code.iti-sdc-3 input[type=tel] {
  padding-left: 74px;
}

.intl-tel-input.separate-dial-code.iti-sdc-3 .selected-flag {
  width: 68px;
}

.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-3 input, .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-3 input[type=text], .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-3 input[type=tel] {
  padding-left: 84px;
}

.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-3 .selected-flag {
  width: 78px;
}

.intl-tel-input.separate-dial-code.iti-sdc-4 input, .intl-tel-input.separate-dial-code.iti-sdc-4 input[type=text], .intl-tel-input.separate-dial-code.iti-sdc-4 input[type=tel] {
  padding-left: 82px;
}

.intl-tel-input.separate-dial-code.iti-sdc-4 .selected-flag {
  width: 76px;
}

.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-4 input, .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-4 input[type=text], .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-4 input[type=tel] {
  padding-left: 92px;
}

.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-4 .selected-flag {
  width: 86px;
}

.intl-tel-input.separate-dial-code.iti-sdc-5 input, .intl-tel-input.separate-dial-code.iti-sdc-5 input[type=text], .intl-tel-input.separate-dial-code.iti-sdc-5 input[type=tel] {
  padding-left: 90px;
}

.intl-tel-input.separate-dial-code.iti-sdc-5 .selected-flag {
  width: 84px;
}

.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-5 input, .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-5 input[type=text], .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-5 input[type=tel] {
  padding-left: 100px;
}

.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-5 .selected-flag {
  width: 94px;
}

.intl-tel-input.iti-container {
  position: absolute;
  top: -1000px;
  left: -1000px;
  z-index: 1060;
  padding: 1px;
}

.intl-tel-input.iti-container:hover {
  cursor: pointer;
}

.iti-mobile .intl-tel-input.iti-container {
  top: 30px;
  bottom: 30px;
  left: 30px;
  right: 30px;
  position: fixed;
}

.iti-mobile .intl-tel-input .country-list {
  max-height: 100%;
  width: 100%;
}

.iti-mobile .intl-tel-input .country-list .country {
  padding: 10px 10px;
  line-height: 1.5em;
}

.iti-flag {
  width: 20px;
}

.iti-flag.be {
  width: 18px;
}

.iti-flag.ch {
  width: 15px;
}

.iti-flag.mc {
  width: 19px;
}

.iti-flag.ne {
  width: 18px;
}

.iti-flag.np {
  width: 13px;
}

.iti-flag.va {
  width: 15px;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .iti-flag {
    background-size: 5630px 15px;
  }
}
.iti-flag.ac {
  height: 10px;
  background-position: 0px 0px;
}

.iti-flag.ad {
  height: 14px;
  background-position: -22px 0px;
}

.iti-flag.ae {
  height: 10px;
  background-position: -44px 0px;
}

.iti-flag.af {
  height: 14px;
  background-position: -66px 0px;
}

.iti-flag.ag {
  height: 14px;
  background-position: -88px 0px;
}

.iti-flag.ai {
  height: 10px;
  background-position: -110px 0px;
}

.iti-flag.al {
  height: 15px;
  background-position: -132px 0px;
}

.iti-flag.am {
  height: 10px;
  background-position: -154px 0px;
}

.iti-flag.ao {
  height: 14px;
  background-position: -176px 0px;
}

.iti-flag.aq {
  height: 14px;
  background-position: -198px 0px;
}

.iti-flag.ar {
  height: 13px;
  background-position: -220px 0px;
}

.iti-flag.as {
  height: 10px;
  background-position: -242px 0px;
}

.iti-flag.at {
  height: 14px;
  background-position: -264px 0px;
}

.iti-flag.au {
  height: 10px;
  background-position: -286px 0px;
}

.iti-flag.aw {
  height: 14px;
  background-position: -308px 0px;
}

.iti-flag.ax {
  height: 13px;
  background-position: -330px 0px;
}

.iti-flag.az {
  height: 10px;
  background-position: -352px 0px;
}

.iti-flag.ba {
  height: 10px;
  background-position: -374px 0px;
}

.iti-flag.bb {
  height: 14px;
  background-position: -396px 0px;
}

.iti-flag.bd {
  height: 12px;
  background-position: -418px 0px;
}

.iti-flag.be {
  height: 15px;
  background-position: -440px 0px;
}

.iti-flag.bf {
  height: 14px;
  background-position: -460px 0px;
}

.iti-flag.bg {
  height: 12px;
  background-position: -482px 0px;
}

.iti-flag.bh {
  height: 12px;
  background-position: -504px 0px;
}

.iti-flag.bi {
  height: 12px;
  background-position: -526px 0px;
}

.iti-flag.bj {
  height: 14px;
  background-position: -548px 0px;
}

.iti-flag.bl {
  height: 14px;
  background-position: -570px 0px;
}

.iti-flag.bm {
  height: 10px;
  background-position: -592px 0px;
}

.iti-flag.bn {
  height: 10px;
  background-position: -614px 0px;
}

.iti-flag.bo {
  height: 14px;
  background-position: -636px 0px;
}

.iti-flag.bq {
  height: 14px;
  background-position: -658px 0px;
}

.iti-flag.br {
  height: 14px;
  background-position: -680px 0px;
}

.iti-flag.bs {
  height: 10px;
  background-position: -702px 0px;
}

.iti-flag.bt {
  height: 14px;
  background-position: -724px 0px;
}

.iti-flag.bv {
  height: 15px;
  background-position: -746px 0px;
}

.iti-flag.bw {
  height: 14px;
  background-position: -768px 0px;
}

.iti-flag.by {
  height: 10px;
  background-position: -790px 0px;
}

.iti-flag.bz {
  height: 14px;
  background-position: -812px 0px;
}

.iti-flag.ca {
  height: 10px;
  background-position: -834px 0px;
}

.iti-flag.cc {
  height: 10px;
  background-position: -856px 0px;
}

.iti-flag.cd {
  height: 15px;
  background-position: -878px 0px;
}

.iti-flag.cf {
  height: 14px;
  background-position: -900px 0px;
}

.iti-flag.cg {
  height: 14px;
  background-position: -922px 0px;
}

.iti-flag.ch {
  height: 15px;
  background-position: -944px 0px;
}

.iti-flag.ci {
  height: 14px;
  background-position: -961px 0px;
}

.iti-flag.ck {
  height: 10px;
  background-position: -983px 0px;
}

.iti-flag.cl {
  height: 14px;
  background-position: -1005px 0px;
}

.iti-flag.cm {
  height: 14px;
  background-position: -1027px 0px;
}

.iti-flag.cn {
  height: 14px;
  background-position: -1049px 0px;
}

.iti-flag.co {
  height: 14px;
  background-position: -1071px 0px;
}

.iti-flag.cp {
  height: 14px;
  background-position: -1093px 0px;
}

.iti-flag.cr {
  height: 12px;
  background-position: -1115px 0px;
}

.iti-flag.cu {
  height: 10px;
  background-position: -1137px 0px;
}

.iti-flag.cv {
  height: 12px;
  background-position: -1159px 0px;
}

.iti-flag.cw {
  height: 14px;
  background-position: -1181px 0px;
}

.iti-flag.cx {
  height: 10px;
  background-position: -1203px 0px;
}

.iti-flag.cy {
  height: 13px;
  background-position: -1225px 0px;
}

.iti-flag.cz {
  height: 14px;
  background-position: -1247px 0px;
}

.iti-flag.de {
  height: 12px;
  background-position: -1269px 0px;
}

.iti-flag.dg {
  height: 10px;
  background-position: -1291px 0px;
}

.iti-flag.dj {
  height: 14px;
  background-position: -1313px 0px;
}

.iti-flag.dk {
  height: 15px;
  background-position: -1335px 0px;
}

.iti-flag.dm {
  height: 10px;
  background-position: -1357px 0px;
}

.iti-flag.do {
  height: 13px;
  background-position: -1379px 0px;
}

.iti-flag.dz {
  height: 14px;
  background-position: -1401px 0px;
}

.iti-flag.ea {
  height: 14px;
  background-position: -1423px 0px;
}

.iti-flag.ec {
  height: 14px;
  background-position: -1445px 0px;
}

.iti-flag.ee {
  height: 13px;
  background-position: -1467px 0px;
}

.iti-flag.eg {
  height: 14px;
  background-position: -1489px 0px;
}

.iti-flag.eh {
  height: 10px;
  background-position: -1511px 0px;
}

.iti-flag.er {
  height: 10px;
  background-position: -1533px 0px;
}

.iti-flag.es {
  height: 14px;
  background-position: -1555px 0px;
}

.iti-flag.et {
  height: 10px;
  background-position: -1577px 0px;
}

.iti-flag.eu {
  height: 14px;
  background-position: -1599px 0px;
}

.iti-flag.fi {
  height: 12px;
  background-position: -1621px 0px;
}

.iti-flag.fj {
  height: 10px;
  background-position: -1643px 0px;
}

.iti-flag.fk {
  height: 10px;
  background-position: -1665px 0px;
}

.iti-flag.fm {
  height: 11px;
  background-position: -1687px 0px;
}

.iti-flag.fo {
  height: 15px;
  background-position: -1709px 0px;
}

.iti-flag.fr {
  height: 14px;
  background-position: -1731px 0px;
}

.iti-flag.ga {
  height: 15px;
  background-position: -1753px 0px;
}

.iti-flag.gb {
  height: 10px;
  background-position: -1775px 0px;
}

.iti-flag.gd {
  height: 12px;
  background-position: -1797px 0px;
}

.iti-flag.ge {
  height: 14px;
  background-position: -1819px 0px;
}

.iti-flag.gf {
  height: 14px;
  background-position: -1841px 0px;
}

.iti-flag.gg {
  height: 14px;
  background-position: -1863px 0px;
}

.iti-flag.gh {
  height: 14px;
  background-position: -1885px 0px;
}

.iti-flag.gi {
  height: 10px;
  background-position: -1907px 0px;
}

.iti-flag.gl {
  height: 14px;
  background-position: -1929px 0px;
}

.iti-flag.gm {
  height: 14px;
  background-position: -1951px 0px;
}

.iti-flag.gn {
  height: 14px;
  background-position: -1973px 0px;
}

.iti-flag.gp {
  height: 14px;
  background-position: -1995px 0px;
}

.iti-flag.gq {
  height: 14px;
  background-position: -2017px 0px;
}

.iti-flag.gr {
  height: 14px;
  background-position: -2039px 0px;
}

.iti-flag.gs {
  height: 10px;
  background-position: -2061px 0px;
}

.iti-flag.gt {
  height: 13px;
  background-position: -2083px 0px;
}

.iti-flag.gu {
  height: 11px;
  background-position: -2105px 0px;
}

.iti-flag.gw {
  height: 10px;
  background-position: -2127px 0px;
}

.iti-flag.gy {
  height: 12px;
  background-position: -2149px 0px;
}

.iti-flag.hk {
  height: 14px;
  background-position: -2171px 0px;
}

.iti-flag.hm {
  height: 10px;
  background-position: -2193px 0px;
}

.iti-flag.hn {
  height: 10px;
  background-position: -2215px 0px;
}

.iti-flag.hr {
  height: 10px;
  background-position: -2237px 0px;
}

.iti-flag.ht {
  height: 12px;
  background-position: -2259px 0px;
}

.iti-flag.hu {
  height: 10px;
  background-position: -2281px 0px;
}

.iti-flag.ic {
  height: 14px;
  background-position: -2303px 0px;
}

.iti-flag.id {
  height: 14px;
  background-position: -2325px 0px;
}

.iti-flag.ie {
  height: 10px;
  background-position: -2347px 0px;
}

.iti-flag.il {
  height: 15px;
  background-position: -2369px 0px;
}

.iti-flag.im {
  height: 10px;
  background-position: -2391px 0px;
}

.iti-flag.in {
  height: 14px;
  background-position: -2413px 0px;
}

.iti-flag.io {
  height: 10px;
  background-position: -2435px 0px;
}

.iti-flag.iq {
  height: 14px;
  background-position: -2457px 0px;
}

.iti-flag.ir {
  height: 12px;
  background-position: -2479px 0px;
}

.iti-flag.is {
  height: 15px;
  background-position: -2501px 0px;
}

.iti-flag.it {
  height: 14px;
  background-position: -2523px 0px;
}

.iti-flag.je {
  height: 12px;
  background-position: -2545px 0px;
}

.iti-flag.jm {
  height: 10px;
  background-position: -2567px 0px;
}

.iti-flag.jo {
  height: 10px;
  background-position: -2589px 0px;
}

.iti-flag.jp {
  height: 14px;
  background-position: -2611px 0px;
}

.iti-flag.ke {
  height: 14px;
  background-position: -2633px 0px;
}

.iti-flag.kg {
  height: 12px;
  background-position: -2655px 0px;
}

.iti-flag.kh {
  height: 13px;
  background-position: -2677px 0px;
}

.iti-flag.ki {
  height: 10px;
  background-position: -2699px 0px;
}

.iti-flag.km {
  height: 12px;
  background-position: -2721px 0px;
}

.iti-flag.kn {
  height: 14px;
  background-position: -2743px 0px;
}

.iti-flag.kp {
  height: 10px;
  background-position: -2765px 0px;
}

.iti-flag.kr {
  height: 14px;
  background-position: -2787px 0px;
}

.iti-flag.kw {
  height: 10px;
  background-position: -2809px 0px;
}

.iti-flag.ky {
  height: 10px;
  background-position: -2831px 0px;
}

.iti-flag.kz {
  height: 10px;
  background-position: -2853px 0px;
}

.iti-flag.la {
  height: 14px;
  background-position: -2875px 0px;
}

.iti-flag.lb {
  height: 14px;
  background-position: -2897px 0px;
}

.iti-flag.lc {
  height: 10px;
  background-position: -2919px 0px;
}

.iti-flag.li {
  height: 12px;
  background-position: -2941px 0px;
}

.iti-flag.lk {
  height: 10px;
  background-position: -2963px 0px;
}

.iti-flag.lr {
  height: 11px;
  background-position: -2985px 0px;
}

.iti-flag.ls {
  height: 14px;
  background-position: -3007px 0px;
}

.iti-flag.lt {
  height: 12px;
  background-position: -3029px 0px;
}

.iti-flag.lu {
  height: 12px;
  background-position: -3051px 0px;
}

.iti-flag.lv {
  height: 10px;
  background-position: -3073px 0px;
}

.iti-flag.ly {
  height: 10px;
  background-position: -3095px 0px;
}

.iti-flag.ma {
  height: 14px;
  background-position: -3117px 0px;
}

.iti-flag.mc {
  height: 15px;
  background-position: -3139px 0px;
}

.iti-flag.md {
  height: 10px;
  background-position: -3160px 0px;
}

.iti-flag.me {
  height: 10px;
  background-position: -3182px 0px;
}

.iti-flag.mf {
  height: 14px;
  background-position: -3204px 0px;
}

.iti-flag.mg {
  height: 14px;
  background-position: -3226px 0px;
}

.iti-flag.mh {
  height: 11px;
  background-position: -3248px 0px;
}

.iti-flag.mk {
  height: 10px;
  background-position: -3270px 0px;
}

.iti-flag.ml {
  height: 14px;
  background-position: -3292px 0px;
}

.iti-flag.mm {
  height: 14px;
  background-position: -3314px 0px;
}

.iti-flag.mn {
  height: 10px;
  background-position: -3336px 0px;
}

.iti-flag.mo {
  height: 14px;
  background-position: -3358px 0px;
}

.iti-flag.mp {
  height: 10px;
  background-position: -3380px 0px;
}

.iti-flag.mq {
  height: 14px;
  background-position: -3402px 0px;
}

.iti-flag.mr {
  height: 14px;
  background-position: -3424px 0px;
}

.iti-flag.ms {
  height: 10px;
  background-position: -3446px 0px;
}

.iti-flag.mt {
  height: 14px;
  background-position: -3468px 0px;
}

.iti-flag.mu {
  height: 14px;
  background-position: -3490px 0px;
}

.iti-flag.mv {
  height: 14px;
  background-position: -3512px 0px;
}

.iti-flag.mw {
  height: 14px;
  background-position: -3534px 0px;
}

.iti-flag.mx {
  height: 12px;
  background-position: -3556px 0px;
}

.iti-flag.my {
  height: 10px;
  background-position: -3578px 0px;
}

.iti-flag.mz {
  height: 14px;
  background-position: -3600px 0px;
}

.iti-flag.na {
  height: 14px;
  background-position: -3622px 0px;
}

.iti-flag.nc {
  height: 10px;
  background-position: -3644px 0px;
}

.iti-flag.ne {
  height: 15px;
  background-position: -3666px 0px;
}

.iti-flag.nf {
  height: 10px;
  background-position: -3686px 0px;
}

.iti-flag.ng {
  height: 10px;
  background-position: -3708px 0px;
}

.iti-flag.ni {
  height: 12px;
  background-position: -3730px 0px;
}

.iti-flag.nl {
  height: 14px;
  background-position: -3752px 0px;
}

.iti-flag.no {
  height: 15px;
  background-position: -3774px 0px;
}

.iti-flag.np {
  height: 15px;
  background-position: -3796px 0px;
}

.iti-flag.nr {
  height: 10px;
  background-position: -3811px 0px;
}

.iti-flag.nu {
  height: 10px;
  background-position: -3833px 0px;
}

.iti-flag.nz {
  height: 10px;
  background-position: -3855px 0px;
}

.iti-flag.om {
  height: 10px;
  background-position: -3877px 0px;
}

.iti-flag.pa {
  height: 14px;
  background-position: -3899px 0px;
}

.iti-flag.pe {
  height: 14px;
  background-position: -3921px 0px;
}

.iti-flag.pf {
  height: 14px;
  background-position: -3943px 0px;
}

.iti-flag.pg {
  height: 15px;
  background-position: -3965px 0px;
}

.iti-flag.ph {
  height: 10px;
  background-position: -3987px 0px;
}

.iti-flag.pk {
  height: 14px;
  background-position: -4009px 0px;
}

.iti-flag.pl {
  height: 13px;
  background-position: -4031px 0px;
}

.iti-flag.pm {
  height: 14px;
  background-position: -4053px 0px;
}

.iti-flag.pn {
  height: 10px;
  background-position: -4075px 0px;
}

.iti-flag.pr {
  height: 14px;
  background-position: -4097px 0px;
}

.iti-flag.ps {
  height: 10px;
  background-position: -4119px 0px;
}

.iti-flag.pt {
  height: 14px;
  background-position: -4141px 0px;
}

.iti-flag.pw {
  height: 13px;
  background-position: -4163px 0px;
}

.iti-flag.py {
  height: 11px;
  background-position: -4185px 0px;
}

.iti-flag.qa {
  height: 8px;
  background-position: -4207px 0px;
}

.iti-flag.re {
  height: 14px;
  background-position: -4229px 0px;
}

.iti-flag.ro {
  height: 14px;
  background-position: -4251px 0px;
}

.iti-flag.rs {
  height: 14px;
  background-position: -4273px 0px;
}

.iti-flag.ru {
  height: 14px;
  background-position: -4295px 0px;
}

.iti-flag.rw {
  height: 14px;
  background-position: -4317px 0px;
}

.iti-flag.sa {
  height: 14px;
  background-position: -4339px 0px;
}

.iti-flag.sb {
  height: 10px;
  background-position: -4361px 0px;
}

.iti-flag.sc {
  height: 10px;
  background-position: -4383px 0px;
}

.iti-flag.sd {
  height: 10px;
  background-position: -4405px 0px;
}

.iti-flag.se {
  height: 13px;
  background-position: -4427px 0px;
}

.iti-flag.sg {
  height: 14px;
  background-position: -4449px 0px;
}

.iti-flag.sh {
  height: 10px;
  background-position: -4471px 0px;
}

.iti-flag.si {
  height: 10px;
  background-position: -4493px 0px;
}

.iti-flag.sj {
  height: 15px;
  background-position: -4515px 0px;
}

.iti-flag.sk {
  height: 14px;
  background-position: -4537px 0px;
}

.iti-flag.sl {
  height: 14px;
  background-position: -4559px 0px;
}

.iti-flag.sm {
  height: 15px;
  background-position: -4581px 0px;
}

.iti-flag.sn {
  height: 14px;
  background-position: -4603px 0px;
}

.iti-flag.so {
  height: 14px;
  background-position: -4625px 0px;
}

.iti-flag.sr {
  height: 14px;
  background-position: -4647px 0px;
}

.iti-flag.ss {
  height: 10px;
  background-position: -4669px 0px;
}

.iti-flag.st {
  height: 10px;
  background-position: -4691px 0px;
}

.iti-flag.sv {
  height: 12px;
  background-position: -4713px 0px;
}

.iti-flag.sx {
  height: 14px;
  background-position: -4735px 0px;
}

.iti-flag.sy {
  height: 14px;
  background-position: -4757px 0px;
}

.iti-flag.sz {
  height: 14px;
  background-position: -4779px 0px;
}

.iti-flag.ta {
  height: 10px;
  background-position: -4801px 0px;
}

.iti-flag.tc {
  height: 10px;
  background-position: -4823px 0px;
}

.iti-flag.td {
  height: 14px;
  background-position: -4845px 0px;
}

.iti-flag.tf {
  height: 14px;
  background-position: -4867px 0px;
}

.iti-flag.tg {
  height: 13px;
  background-position: -4889px 0px;
}

.iti-flag.th {
  height: 14px;
  background-position: -4911px 0px;
}

.iti-flag.tj {
  height: 10px;
  background-position: -4933px 0px;
}

.iti-flag.tk {
  height: 10px;
  background-position: -4955px 0px;
}

.iti-flag.tl {
  height: 10px;
  background-position: -4977px 0px;
}

.iti-flag.tm {
  height: 14px;
  background-position: -4999px 0px;
}

.iti-flag.tn {
  height: 14px;
  background-position: -5021px 0px;
}

.iti-flag.to {
  height: 10px;
  background-position: -5043px 0px;
}

.iti-flag.tr {
  height: 14px;
  background-position: -5065px 0px;
}

.iti-flag.tt {
  height: 12px;
  background-position: -5087px 0px;
}

.iti-flag.tv {
  height: 10px;
  background-position: -5109px 0px;
}

.iti-flag.tw {
  height: 14px;
  background-position: -5131px 0px;
}

.iti-flag.tz {
  height: 14px;
  background-position: -5153px 0px;
}

.iti-flag.ua {
  height: 14px;
  background-position: -5175px 0px;
}

.iti-flag.ug {
  height: 14px;
  background-position: -5197px 0px;
}

.iti-flag.um {
  height: 11px;
  background-position: -5219px 0px;
}

.iti-flag.us {
  height: 11px;
  background-position: -5241px 0px;
}

.iti-flag.uy {
  height: 14px;
  background-position: -5263px 0px;
}

.iti-flag.uz {
  height: 10px;
  background-position: -5285px 0px;
}

.iti-flag.va {
  height: 15px;
  background-position: -5307px 0px;
}

.iti-flag.vc {
  height: 14px;
  background-position: -5324px 0px;
}

.iti-flag.ve {
  height: 14px;
  background-position: -5346px 0px;
}

.iti-flag.vg {
  height: 10px;
  background-position: -5368px 0px;
}

.iti-flag.vi {
  height: 14px;
  background-position: -5390px 0px;
}

.iti-flag.vn {
  height: 14px;
  background-position: -5412px 0px;
}

.iti-flag.vu {
  height: 12px;
  background-position: -5434px 0px;
}

.iti-flag.wf {
  height: 14px;
  background-position: -5456px 0px;
}

.iti-flag.ws {
  height: 10px;
  background-position: -5478px 0px;
}

.iti-flag.xk {
  height: 15px;
  background-position: -5500px 0px;
}

.iti-flag.ye {
  height: 14px;
  background-position: -5522px 0px;
}

.iti-flag.yt {
  height: 14px;
  background-position: -5544px 0px;
}

.iti-flag.za {
  height: 14px;
  background-position: -5566px 0px;
}

.iti-flag.zm {
  height: 14px;
  background-position: -5588px 0px;
}

.iti-flag.zw {
  height: 10px;
  background-position: -5610px 0px;
}

.iti-flag {
  width: 20px;
  height: 15px;
  box-shadow: 0px 0px 1px 0px #888;
  background-image: url("../images/flags.webp");
  background-repeat: no-repeat;
  background-color: #DBDBDB;
  background-position: 20px 0;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .iti-flag {
    background-image: url("../images/flags@2x.webp");
  }
}
.iti-flag.np {
  background-color: transparent;
}

.slick-list, .slick-slider, .slick-track {
  position: relative;
  display: block;
}

.slick-slider {
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: 0;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-list, .slick-slider .slick-track {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  top: 0;
  left: 0;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:after, .slick-track:before {
  display: table;
  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-slide, .slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir=rtl] .slick-slide {
  float: right;
}

.slick-initialized .slick-slide, .slick-slide img {
  display: block;
}

.slick-arrow.slick-hidden, .slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

* {
  -webkit-tap-highlight-color: transparent !important;
}

.areaFont {
  font-family: "area-variable", sans-serif !important;
  font-variation-settings: "slnt" 0, "INKT" 70;
}

.areaFont0 {
  font-family: "area-variable", sans-serif !important;
  font-variation-settings: "slnt" 0, "INKT" 0;
}

html,
body {
  padding: 0;
  margin: 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  font-display: swap;
  font-family: "Basier Circle";
  overflow-x: hidden;
}

a,
img,
button,
input[type=submit] {
  text-decoration: none;
  outline: none;
  border: 0;
  /*transition: 0.2s ease;*/
}

*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

::selection {
  background-color: #33c1c0;
  color: #fff;
}

a:focus,
a:hover {
  text-decoration: none !important;
}

p,
ul,
ol,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
dt,
dd {
  padding: 0;
  margin: 0;
}

input,
textarea,
select {
  width: 100%;
  display: block;
  padding: 10px 12px;
  font-size: 14px;
  line-height: 20px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
  outline: none;
}

.selldof .btn {
  width: unset;
}

p {
  font-size: 16px;
  line-height: 26px;
}

.container-fluid {
  width: 100%;
  margin: 0 auto;
  padding: 0 0px;
}

.container {
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 20px;
}

.container.small {
  max-width: 850px;
  padding: 0 20px;
}

.container.medium {
  max-width: 1000px;
  padding: 0 20px;
}

.pull-left {
  float: left !important;
}

.pull-right {
  float: right !important;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-italic {
  font-style: italic;
}

.text-medium {
  font-weight: 400;
}

.text-bold {
  font-weight: 500;
}

.text-bolder {
  font-weight: 700;
}

.text-black {
  color: #000;
}

.text-pink {
  color: #DD2590;
}

.text-dark {
  color: #182020;
}

.text-light {
  color: #354545;
}

.text-white {
  color: #fff;
}

.text-blue {
  color: #0d9298;
}

.text-lightblue {
  color: #01697e;
}

.text-green {
  color: #065151;
}

.text-greyish {
  color: #C1C8CC;
}

.text-grey {
  color: #0e1b1b;
}

.text-red {
  color: red;
}

.titledark {
  color: #1A424F;
}

.text-yellow {
  color: #ebbd07;
}

.color1 {
  color: #0e1b1b;
}

.color2 {
  color: #374040;
}

.color3 {
  color: #023342;
}

.primary_color {
  color: #63FAFF;
}

.bg-light {
  background-color: #C2F6F6;
}

.bg-grey {
  background-color: #EFF0F0;
}

.img-responsive {
  max-width: 100%;
  height: auto;
}

.img-full {
  width: 100%;
  height: auto;
}

.center-block {
  margin: 0 auto;
}

.bg-white {
  background-color: #fff;
}

.bg-blue {
  background-color: #0d9298;
}

.bg-pink {
  background-color: #fff5f5;
}

.bg-colorpink {
  background-color: #fdf5ff;
}

.bg-green {
  background-color: #065151;
}

.bgBlue {
  background-color: #e9fff8;
}

.basierMedium {
  font-family: "Basier Circle medium";
}

.basierSemibold {
  font-family: "Basier Circle Semibold";
}

.bg-cover {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.col-1 {
  width: 8.3333333333%;
}

.col-2 {
  width: 16.6666666667%;
}

.col-3 {
  width: 25%;
}

.col-4 {
  width: 33.3333333333%;
}

.col-5 {
  width: 41.6666666667%;
}

.col-6 {
  width: 50%;
}

.col-7 {
  width: 58.3333333333%;
}

.col-8 {
  width: 66.6666666667%;
}

.col-9 {
  width: 75%;
}

.col-10 {
  width: 83.3333333333%;
}

.col-11 {
  width: 91.6666666667%;
}

.col-12 {
  width: 100%;
}

.col-12-5 {
  width: 20%;
}

.margintop-20 {
  margin-top: 20px;
}

.margintop-30 {
  margin-top: 30px;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col-12-5,
.w30,
.w70 {
  float: left;
}

section {
  float: left;
  width: 100%;
}

.padding {
  padding: 70px 0px;
}

.padding-top {
  padding: 120px 0 70px 0;
}

.padd-10 {
  padding-top: 10px;
}

.padd-20 {
  padding-top: 20px;
}

.padd-30 {
  padding-top: 30px;
}

.pd-bt-15 {
  padding-bottom: 15px;
}

.pd-bt-10 {
  padding-bottom: 10px;
}

.pd-bt-20 {
  padding-bottom: 20px;
}

.pd-bt-30 {
  padding-bottom: 30px;
}

.pd-tp-15 {
  padding-top: 15px;
}

.pd-tp-10 {
  padding-top: 10px;
}

.pd-tp-20 {
  padding-top: 20px;
}

.pd-tp-30 {
  padding-top: 30px;
}

.pd-tp-40 {
  padding-top: 40px;
}

.pd-tp-50 {
  padding-top: 50px;
}

.padd-top {
  padding: 10px 0;
}

.mg-bt-30 {
  margin-bottom: 30px;
}

.mg-bt-10 {
  margin-bottom: 10px;
}

.pd-tp-0 {
  padding-top: 0 !important;
}

.pd-bt-0 {
  padding-bottom: 0 !important;
}

.pos-rel {
  position: relative;
}

.pos-abs {
  position: absolute;
}

.pos-fix {
  position: fixed;
}

.bottom {
  bottom: 0;
}

.top {
  top: 0;
}

.left {
  left: 0;
}

.right {
  right: 0;
}

.visible-xs {
  display: none;
}

.visible-sm {
  display: none;
}

.hidden-xs {
  display: block;
}

.hidden-portrait {
  display: block !important;
}

.visible-portrait {
  display: none !important;
}

.displayblock {
  display: block;
}

.displayinline {
  display: inline-block;
}

.row:before,
.row:after {
  display: table;
  content: " ";
}

.row:after,
.clearfix {
  clear: both;
}

.parallaxbg {
  background-attachment: fixed;
}

.disptable {
  display: table;
}

.table-cell {
  display: table-cell;
  vertical-align: middle;
}

.visible-xs {
  display: none;
}

.hidden-lg {
  display: none;
}

.hidden-portrait {
  display: block !important;
}

.visible-portrait {
  display: none !important;
}

.vert-middle {
  vertical-align: middle;
}

.disp-block {
  display: block;
}

.disp-flex {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
  margin: 35px 0;
}

.center-block {
  float: none !important;
  display: block;
  margin: 10px auto 0;
}

.sticky_header {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: #0d9298;
  padding: 6px 15px;
  border-radius: 30px;
  z-index: 2;
}

header {
  position: fixed;
  width: 100%;
  background-color: #fff;
  width: 100%;
  height: 70px;
  padding: 10px 0;
  border-bottom: 1px solid #e9eded;
  z-index: 9999;
  transition: all 0.3s linear;
}
header .center-header {
  gap: 18px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto !important;
  max-width: 1304px;
}
header .theme-btn, header .white-btn {
  font-size: 14px !important;
  padding: 10px 12px;
}

.brand-logo {
  padding: 10px 0;
}

.border {
  margin: 4px 0 0;
}

a:hover, button:hover, input[type=button]:hover, input[type=submit]:hover {
  cursor: pointer;
}

.nav-links {
  list-style-type: none;
  display: inline-block;
}
.nav-links li {
  display: inline-block;
}
.nav-links .custom-dropdown {
  float: left;
  overflow: hidden;
  height: 80px;
}
.nav-links .custom-dropdown:hover .dropdown-menu-parent {
  display: block;
}
.nav-links .custom-dropdown .dropbtn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nav-links .custom-dropdown .dropbtn span {
  font-size: 14px;
  color: #374040;
  line-height: 20px;
  font-family: "Basier Circle medium";
  cursor: pointer;
  position: relative;
  transition: all 0.2s linear;
  margin: 12px 10px 12px 22px;
  display: inline-block;
}
.nav-links .custom-dropdown .dropbtn span a {
  font-size: 14px;
  color: #374040;
  font-family: "Basier Circle medium";
  line-height: 20px;
  cursor: pointer;
  position: relative;
}
.nav-links .custom-dropdown .dropdown-menu-parent {
  left: 0;
  top: 60px;
  width: 100%;
  z-index: 10;
  display: none;
  position: absolute;
  backdrop-filter: blur(22px);
  height: calc(100dvh - 130px);
  display: none;
}
.nav-links .custom-dropdown .dropdown-menu-parent .custom-dropdown-menu {
  overflow-y: auto;
  padding: 32px 25px;
  background: #fff;
  max-height: calc(100dvh - 130px);
  box-shadow: 4px 0 4px rgba(0, 0, 0, 0.04);
}
.nav-links .custom-dropdown .dropdown-menu-parent .custom-dropdown-menu .dropdown-content {
  margin: 0 auto;
  max-width: 1366px;
}
.nav-links .custom-dropdown .dropdown-menu-parent .custom-dropdown-menu .dropdown-content .section-header {
  margin: 0 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nav-links .custom-dropdown .dropdown-menu-parent .custom-dropdown-menu .dropdown-content .section-header .section-title {
  margin: 0;
  font-size: 14px;
  line-height: 20px;
  width: fit-content;
  color: transparent;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-family: "Basier Circle Semibold";
  background-clip: text;
  background-image: linear-gradient(97.27deg, #ff7b34 9.71%, #8d63fe 102.01%);
}
.nav-links .custom-dropdown .dropdown-menu-parent .custom-dropdown-menu .dropdown-content .section-1 .section-body {
  display: flex;
  grid-gap: 16px 54px;
  gap: 16px 0px;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: flex-start;
}
.nav-links .custom-dropdown .dropdown-menu-parent .custom-dropdown-menu .dropdown-content .section-1 .section-body .menu-item {
  grid-gap: 16px;
  gap: 16px;
  width: 390px;
  padding: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 8px;
}
.nav-links .custom-dropdown .dropdown-menu-parent .custom-dropdown-menu .dropdown-content .section-1 .section-body .menu-item:hover {
  background: #f4f9f9;
}
.nav-links .custom-dropdown .dropdown-menu-parent .custom-dropdown-menu .dropdown-content .section-1 .section-body .menu-item:hover .icon-div {
  background: #0d9298;
}
.nav-links .custom-dropdown .dropdown-menu-parent .custom-dropdown-menu .dropdown-content .section-1 .section-body .menu-item:hover .icon-div svg path {
  stroke: #fff;
}
.nav-links .custom-dropdown .dropdown-menu-parent .custom-dropdown-menu .dropdown-content .section-1 .section-body .menu-item .icon-div {
  width: 44px;
  height: 44px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f4f9f9;
}
.nav-links .custom-dropdown .dropdown-menu-parent .custom-dropdown-menu .dropdown-content .section-1 .section-body .menu-item .icon-div svg path {
  stroke: #0d9298;
}
.nav-links .custom-dropdown .dropdown-menu-parent .custom-dropdown-menu .dropdown-content .section-1 .section-body .menu-item .content-div {
  max-width: calc(100% - 76px);
}
.nav-links .custom-dropdown .dropdown-menu-parent .custom-dropdown-menu .dropdown-content .section-1 .section-body .menu-item .content-div h6 {
  margin: 0 0 2px;
  font-size: 16px;
  color: #0e1b1b;
  line-height: 22px;
  font-weight: 600;
}
.nav-links .custom-dropdown .dropdown-menu-parent .custom-dropdown-menu .dropdown-content .section-1 .section-body .menu-item .content-div p {
  margin: 0;
  font-size: 14px;
  color: #747c7c;
  line-height: 21px;
  letter-spacing: -0.02em;
}
.nav-links .custom-dropdown .dropdown-menu-parent .custom-dropdown-menu .dropdown-content .h_img {
  width: 200px;
  height: 132px;
  border-radius: 10px;
}
.nav-links .custom-dropdown .dropdown-menu-parent .custom-dropdown-menu .dropdown-content .section-2 {
  margin-bottom: 32px;
}
.nav-links .custom-dropdown .dropdown-menu-parent .custom-dropdown-menu .dropdown-content .section-2 .section-body {
  display: flex;
  grid-gap: 32px;
  gap: 32px;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: flex-start;
}
.nav-links .custom-dropdown .dropdown-menu-parent .custom-dropdown-menu .dropdown-content .section-2 .section-body .menu-item {
  grid-gap: 20px;
  gap: 20px;
  width: 528px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.nav-links .custom-dropdown .dropdown-menu-parent .custom-dropdown-menu .dropdown-content .section-2 .section-body .menu-item .content-div {
  max-width: calc(100% - 220px);
}
.nav-links .custom-dropdown .dropdown-menu-parent .custom-dropdown-menu .dropdown-content .section-2 .section-body .menu-item .content-div h6 {
  margin: 0;
  font-size: 16px;
  color: #0e1b1b;
  line-height: 22px;
}
.nav-links .custom-dropdown .dropdown-menu-parent .custom-dropdown-menu .dropdown-content .section-2 .section-body .menu-item .content-div p {
  font-size: 14px;
  color: #747c7c;
  margin: 4px 0 8px;
  line-height: 21px;
  letter-spacing: -0.02em;
}
.nav-links .custom-dropdown .dropdown-menu-parent .custom-dropdown-menu .dropdown-content .section-2 .section-body .menu-item .content-div span {
  font-size: 16px;
  line-height: 22px;
  color: #0d9298 !important;
  text-decoration: underline !important;
  font-weight: 500;
}
.nav-links .custom-dropdown .dropdown-menu-parent .custom-dropdown-menu .dropdown-content .section-3 .section-body {
  display: flex;
  grid-gap: 16px 32px;
  gap: 16px 32px;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: flex-start;
}
.nav-links .custom-dropdown .dropdown-menu-parent .custom-dropdown-menu .dropdown-content .section-3 .section-body .menu-item {
  width: 315px;
  cursor: pointer;
}
.nav-links .custom-dropdown .dropdown-menu-parent .custom-dropdown-menu .dropdown-content .section-3 .section-body .menu-item img {
  border-radius: 8px;
  height: 150px;
}
.nav-links .custom-dropdown .dropdown-menu-parent .custom-dropdown-menu .dropdown-content .section-3 .section-body .menu-item h6 {
  font-size: 16px;
  margin: 20px 0 0;
  color: #0e1b1b;
  line-height: 22px;
  font-family: "Basier Circle Semibold";
}
.nav-links .custom-dropdown .dropdown-menu-parent .custom-dropdown-menu .dropdown-content .section-3 .section-body .menu-item p {
  font-size: 14px;
  color: #747c7c;
  margin: 4px 0 8px;
  line-height: 21px;
  letter-spacing: -0.02em;
  font-family: "Basier Circle";
}
.nav-links .custom-dropdown .dropdown-menu-parent .custom-dropdown-menu .dropdown-content .section-3 .section-body .menu-item span {
  font-size: 16px;
  line-height: 22px;
  color: #0d9298 !important;
  font-family: "Basier Circle medium";
  text-decoration: underline !important;
  font-weight: 500;
}

.call-us img {
  vertical-align: middle;
  background-color: #11274C;
  width: 37px;
  height: 37px;
  padding: 9px;
  border-radius: 50px;
}

a.enquire-toggle-button.extLink.active:before {
  content: unset;
}

.logo-div img {
  padding: 10px 0 0;
}

.burger-menu {
  display: none;
  padding: 7px 8px;
  margin-top: 25px;
}

.bar1,
.bar2,
.bar3 {
  width: 20px;
  height: 3px;
  background: #0D9298;
  margin: 5px 0;
  transition: 0.4s;
}

.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-5px, 5px) !important;
  transform: rotate(-45deg) translate(-5px, 5px) !important;
}

.change .bar2 {
  opacity: 0;
}

.change .bar3 {
  -webkit-transform: rotate(45deg) translate(-6px, -6px);
  transform: rotate(45deg) translate(-6px, -6px);
  width: 20px;
  left: 1px;
  margin: unset;
}

.sticky-btn {
  position: fixed;
  top: 165px;
  right: 0;
  -webkit-transform: rotate(-90deg);
  -webkit-transform-origin: bottom right;
  transform-origin: bottom right;
  width: 270px;
  z-index: 999;
  display: inline-block;
  text-align: center;
  text-decoration: none;
}

.visible-xs {
  display: none !important;
}

.col-12.menu a:last-child {
  padding-right: 0;
}

.btn_wrapper {
  margin: 30px 0;
}

.wpcf7-not-valid-tip {
  position: absolute;
  bottom: -21px;
  font-size: 10px;
}

.wpcf7 form .wpcf7-response-output {
  display: none;
}

.theme-btn {
  padding: 14px 25px;
  font-size: 16px !important;
  line-height: 20px;
  display: inline-block;
  background: #0d9298 !important;
  text-align: center;
  border-radius: 6px;
  color: #fff;
  font-family: "Basier Circle medium";
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.05);
}
.theme-btn:hover {
  background-color: #354545 !important;
}
@media (max-width: 992px) {
  .theme-btn {
    font-size: 14px !important;
  }
}

.black-btn {
  padding: 14px 25px;
  font-size: 16px !important;
  line-height: 20px;
  display: inline-block;
  background: #023342;
  text-align: center;
  border-radius: 8px;
  font-family: "Basier Circle medium";
  color: #fff;
}
.black-btn:hover {
  background-color: #fff !important;
  color: #023342;
}
@media (max-width: 992px) {
  .black-btn {
    font-size: 14px !important;
  }
}

.white-btn {
  padding: 14px 25px;
  font-size: 16px !important;
  line-height: 20px;
  display: inline-block;
  background: white;
  text-align: center;
  border-radius: 8px;
  font-family: "Basier Circle medium";
  color: #023342;
  border: 1px solid #023342;
}
.white-btn:hover {
  background-color: #354545 !important;
  color: #fff !important;
}
@media (max-width: 992px) {
  .white-btn {
    font-size: 14px !important;
  }
}

.blue-btn {
  padding: 14px 25px;
  font-size: 16px !important;
  line-height: 20px;
  display: inline-block;
  background: white;
  text-align: center;
  font-family: "Basier Circle medium";
  border-radius: 6px;
  color: #0d9298;
  border: 1px solid #0d9298;
}
@media (max-width: 992px) {
  .blue-btn {
    font-size: 14px !important;
  }
}

.yellow-btn {
  padding: 14px 25px;
  font-size: 16px !important;
  line-height: 20px;
  display: inline-block;
  background: #ebbd07;
  text-align: center;
  font-family: "Basier Circle medium";
  border-radius: 6px;
  color: #013030;
  border: 1px solid #ebbd07;
}
@media (max-width: 992px) {
  .yellow-btn {
    font-size: 14px !important;
  }
}

.grey-btn {
  padding: 10px 30px;
  font-size: 16px !important;
  line-height: 20px;
  display: inline-block;
  color: #1A424F;
  background: #EEEEEE;
  border-radius: 8px;
  text-align: center;
  font-family: "Basier Circle medium";
}
.grey-btn:hover {
  background-color: #0d9298 !important;
  color: #fff !important;
}
.grey-btn:hover svg path {
  fill: #fff;
}
.grey-btn svg {
  vertical-align: text-top;
}
@media (max-width: 992px) {
  .grey-btn {
    font-size: 14px !important;
  }
}

.red-btn img {
  float: right;
  margin-top: 7px;
}

.light-btn {
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #171717;
  background-color: #EFF0F0;
  border-radius: 50px;
  padding: 8px 20px;
}

.button-3 {
  border: 2px solid #3c73ff;
  background-color: #3c73ff;
  border-radius: 20px;
  color: #fff;
  transition: 0.3s;
}

.button-3:hover {
  box-shadow: 8px 8px #99bdff;
  transition: 0.3s;
}

.learnmore {
  position: relative;
  display: inline-block;
}
.learnmore::after {
  content: "";
  position: absolute;
  top: 1px;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 6px 6px #023342;
  transition: top 0.3s ease-in-out, left 0.3s ease-in-out;
  border-radius: 8px;
}
.learnmore .theme-newbtn {
  padding: 11px 16px 8px;
  font-size: 16px;
  line-height: 20px;
  display: inline-block;
  background: #0d9298;
  text-align: center;
  border-radius: 8px;
  color: #fff;
  transition: transform 0.3s ease-in-out;
  position: relative;
  left: 6px;
  top: 7px;
  z-index: 2;
}
.learnmore:hover .theme-newbtn {
  transform: translate(-5px, -5px);
}

.theme-newbtn {
  padding: 11px 16px;
  font-size: 16px !important;
  line-height: 20px;
  display: inline-block;
  background: #0d9298;
  text-align: center;
  border-radius: 8px;
  color: #fff;
}
.theme-newbtn:hover {
  box-shadow: 6px 6px #000;
  transition: 0.3s;
}
@media (max-width: 992px) {
  .theme-newbtn {
    font-size: 14px !important;
  }
}

.ctahover {
  position: relative;
  display: inline-block;
}
.ctahover::after {
  content: "";
  position: absolute;
  top: 1px;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 6px 6px #63faff;
  transition: top 0.3s ease-in-out, left 0.3s ease-in-out;
  border-radius: 8px;
}
.ctahover .black-newbtn {
  padding: 11px 16px;
  font-size: 16px !important;
  line-height: 20px;
  display: inline-block;
  background: #023342;
  text-align: center;
  border-radius: 8px;
  font-family: "Basier Circle medium";
  color: #fff;
  transition: transform 0.3s ease-in-out;
  position: relative;
  left: 6px;
  top: 7px;
  z-index: 2;
}
.ctahover:hover .black-newbtn {
  transform: translate(-5px, -5px);
}

.ctahoverw {
  position: relative;
  display: inline-block;
}
.ctahoverw::after {
  content: "";
  position: absolute;
  top: 1px;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 6px 6px #023342;
  transition: top 0.3s ease-in-out, left 0.3s ease-in-out;
  border-radius: 8px;
}
.ctahoverw .white-newbtn {
  padding: 11px 16px;
  font-size: 16px !important;
  line-height: 20px;
  display: inline-block;
  background: #fff;
  text-align: center;
  border-radius: 8px;
  font-family: "Basier Circle medium";
  color: #023342;
  transition: transform 0.3s ease-in-out;
  position: relative;
  left: 6px;
  top: 7px;
  z-index: 2;
}
.ctahoverw:hover .white-newbtn {
  transform: translate(-5px, -5px);
}

.white-newbtn {
  padding: 11px 16px;
  font-size: 16px !important;
  line-height: 20px;
  display: inline-block;
  background: white;
  text-align: center;
  border-radius: 8px;
  font-family: "Basier Circle medium";
  color: #023342;
}
.white-newbtn:hover {
  box-shadow: 6px 6px #000;
  transition: 0.3s;
}
@media (max-width: 992px) {
  .white-newbtn {
    font-size: 14px !important;
  }
}

.col-12.header-upper {
  text-align: right;
}

.footer-call-enquire {
  display: none;
}

.checkbox {
  color: #222 !important;
  margin: 0 !important;
}

a {
  cursor: pointer;
}

.footer-div {
  padding: 40px 0;
  float: left;
  width: 100%;
  padding-bottom: 0;
}

.footer-top p, .footer-top a {
  font-size: 16px;
  line-height: 24px;
  color: #182020;
}

#jp-relatedposts {
  display: none !important;
}

.footer-top {
  padding: 0 0 40px;
}

.footer-top input.theme-btn {
  margin: 20px 0;
  width: unset;
}

.footer-title {
  padding: 20px 0 40px;
}

.footer_desc {
  font-size: 14px;
}

.footer-top ul li {
  font-size: 16px;
  line-height: 28px;
  color: #182020;
  list-style-type: none;
}

.footer-bottom {
  padding: 10px;
  font-size: 16px;
  color: #616d6d;
  line-height: 21px;
  text-align: center;
  background: #f5f5f5;
  font-family: "DM Sans", sans-serif;
}

.social-links a img {
  padding: 8px 4px;
}

.vh {
  visibility: hidden;
}

.footer-top h4 {
  padding: 0px 0 20px;
  color: #182020;
}

.thankyou {
  text-align: center;
  font-size: 18px;
  left: 22px;
}

.sec-subtitle {
  font-size: 22px;
  line-height: 32px;
  font-weight: 700;
  padding: 0 0 10px;
}

.sec_title {
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: 64px;
  letter-spacing: -0.56px;
  font-family: "Basier Circle medium";
}
@media (max-width: 992px) {
  .sec_title {
    font-size: 28px;
    line-height: 32px;
  }
}

.sec-title {
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: -0.8px;
}

.sec-light {
  font-size: 25px;
  line-height: 36px;
  font-weight: 400;
  padding: 0 0 20px;
}

.sec-small {
  font-size: 13px;
  line-height: 24px;
  font-weight: 400;
}

.sec-heading {
  font-size: 20px;
  line-height: 30px;
}
@media (max-width: 992px) {
  .sec-heading {
    font-size: 14px;
    line-height: 22px;
  }
}

.sec-heading2 {
  font-size: 36px;
  line-height: 50px;
}

.sec-desc {
  font-size: 18px;
  line-height: 24px;
  padding: 10px 0;
}

.sec-text {
  font-size: 16px;
  line-height: 24px;
}

/*Animation Start*/
.revealOnScroll {
  opacity: 0;
  transition: all 0.4s ease-in-out;
}

.animated {
  -moz-animation-duration: 1.2s;
  -o-animation-duration: 1.2s;
  -webkit-animation-duration: 1.2s;
  -ms-animation-duration: 1.2s;
  animation-duration: 1.2s;
  -moz-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  animation-fill-mode: both;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    -o-transform: translate3d(-100%, 0, 0);
    -moz-transform: translate3d(-100%, 0, 0);
    -ms-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    -ms-transform: none;
    -moz-transform: none;
    -o-transform: none;
    transform: none;
  }
}
.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  -o-animation-name: fadeInLeft;
  -ms-animation-name: fadeInLeft;
  -moz-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    -o-transform: translate3d(100%, 0, 0);
    -ms-transform: translate3d(100%, 0, 0);
    -moz-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    -o-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    transform: none;
  }
}
.fadeInRight {
  -webkit-animation-name: fadeInRight;
  -o-animation-name: fadeInRight;
  -ms-animation-name: fadeInRight;
  -moz-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    -o-transform: translate3d(0, 100%, 0);
    -ms-transform: translate3d(0, 100%, 0);
    -moz-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    -o-transform: none;
    -ms-transform: none;
    -moz-transform: none;
    transform: none;
  }
}
.fadeInUp {
  -webkit-animation-name: fadeInUp;
  -o-animation-name: fadeInUp;
  -ms-animation-name: fadeInUp;
  -moz-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    -o-transform: translate3d(0, -100%, 0);
    -moz-transform: translate3d(0, -100%, 0);
    -ms-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    -o-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    transform: none;
  }
}
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  -o-animation-name: fadeInDown;
  -ms-animation-name: fadeInDown;
  -moz-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fadeIn {
  animation-name: fadeIn;
}

.slick-arrow {
  cursor: pointer;
}

.imgbox img {
  border-radius: 25px;
}

/******************* Single Job **************/
.awsm-job-container {
  padding: 50px;
}

.awsm-job-container h1 {
  margin-bottom: 20px;
}

.awsm-job-container h4 {
  margin-bottom: 10px;
  margin-top: 20px;
}

.awsm-job-container ul {
  padding-left: 30px;
}

.awsm-job-container ul li {
  padding-bottom: 10px;
  line-height: 26px;
  font-size: 16px;
}

.awsm-job-container ul li strong {
  font-weight: 600;
}

.awsm-job-specification-item {
  margin: 10px 0;
}

.awsm-job-post-title {
  color: #171717;
  font-weight: 600;
}

.awsm-job-more {
  color: #0d9298;
}

.menu {
  display: none;
}

@media only screen and (max-width: 1024px) {
  .col-md-1 {
    width: 8.3333333333%;
  }
  .col-md-2 {
    width: 16.6666666667%;
  }
  .col-md-3 {
    width: 25%;
  }
  .col-md-4 {
    width: 33.3333333333%;
  }
  .col-md-5 {
    width: 41.6666666667%;
  }
  .col-md-6 {
    width: 50%;
  }
  .col-md-7 {
    width: 58.3333333333%;
  }
  .col-md-8 {
    width: 66.6666666667%;
  }
  .col-md-9 {
    width: 75%;
  }
  .col-md-10 {
    width: 83.3333333333%;
  }
  .col-md-11 {
    width: 91.6666666667%;
  }
  .col-md-12 {
    width: 100%;
  }
  .col-md-12-5 {
    width: 20%;
  }
  .hidden-md {
    display: none !important;
  }
  .visible-md {
    display: block !important;
  }
}
@media only screen and (max-width: 992px) {
  .text-center-xs {
    text-align: center;
  }
  .visible-xs {
    display: block !important;
  }
  .hidden-xs {
    display: none !important;
  }
  .sidePadd {
    padding: 0 20px;
  }
  .padding {
    padding: 40px 0px;
  }
  .col-xs-6 {
    width: 50%;
  }
  .col-xs-12 {
    width: 100%;
  }
  header {
    height: 85px;
    width: 100%;
    border-radius: unset;
    margin: 0;
    box-shadow: none;
  }
  .burger-menu {
    display: block;
    padding: 0;
    margin-top: 0;
  }
  .sidelogo {
    width: 130px;
  }
  .menu {
    padding: 70px 0 0;
    height: 100%;
    overflow: auto;
    display: none;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: -1;
    border-bottom: 1px solid #ccc;
    padding: 0px;
    background-color: #fff;
    transform: unset;
  }
  .project-mobile-menu {
    height: auto;
    display: none;
    position: absolute;
    top: 50px;
    width: 100%;
    left: 0px;
    right: 0px;
    z-index: -1;
    border-bottom: 1px solid #ccc;
    padding: 0px;
    background-color: #000;
    transform: unset;
    z-index: 99;
  }
  .menu a, .project-mobile-menu a {
    text-align: left;
    font-size: 14px;
    padding: 6px 0px;
    border-right: 0px;
    margin: 0px;
    color: #fff;
    display: block;
  }
  .menu a.active, .project-mobile-menu a.active {
    position: relative;
    font-weight: 600;
    color: #fff;
  }
  .menu a.enquire-toggle-button.header-enquire, .menu a.call-us, #home a.home-enquire {
    display: none;
  }
  .menu li, .project-mobile-menu li {
    display: block;
    margin: 0 auto;
  }
  .nav-links, .project-mobile-menu {
    display: none;
  }
  .mobile_tabs h3 {
    padding: 10px 0px;
  }
  .mobile_tabs h3.active svg {
    transform: rotate(0deg);
  }
  .mobile_tabs h3 svg {
    width: 20px;
    float: right;
    position: relative;
    transform: rotate(270deg);
    transition: all 0.5s;
  }
  .menu li a {
    color: #000;
    margin: 0 auto;
    padding: 10px;
    font-size: 16px;
    line-height: 17px;
  }
  .menu .sub-menu {
    position: unset;
    margin-top: 0;
    display: none;
  }
  .menu .expand {
    visibility: visible;
    opacity: 1;
    display: block;
    width: 100%;
  }
  .parent-menu > a:after {
    border-top: 5px solid #fff;
    right: unset;
    top: 18px;
    margin-left: 10px;
  }
  .footer-call-enquire {
    display: block;
    position: fixed;
    bottom: 0;
    text-align: center;
    background-color: #fff;
    z-index: 2;
    border-top: 1px solid #ebebeb;
  }
  .footer-call-enquire a {
    font-size: 16px;
    font-weight: 600;
  }
  .enquire-toggle-button.mobile {
    cursor: pointer;
    letter-spacing: 1px;
    font-size: 16px;
    border: none;
    padding: 0 !important;
    float: unset;
    line-height: 25px;
    margin-left: 0;
    text-transform: uppercase;
    font-weight: 500;
  }
  .floating-enquire-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  #popup {
    padding: 30px 10px;
  }
  header .col-12 {
    padding: 8px 4px;
  }
  .brand-logo {
    padding: 0;
    border-right: unset;
  }
  .enquire-close-mobile {
    text-align: right;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .form-control {
    padding: 5px 12px;
  }
  .form_wrapper {
    padding: 20px;
  }
  .form-group {
    padding: 0;
  }
  input#submit_form {
    margin: 0;
    width: 100%;
  }
  .footer-top h4 {
    padding: 20px 0;
  }
  .footer-bottom {
    padding: 10px 0;
  }
  .footer-top .col-4 {
    padding: 0 0px 20px 0;
  }
  .rera p, .rera p a, .marketed p a, .rera-text span, .rera-text p {
    line-height: 23px;
    font-size: 14px;
  }
  .enq {
    padding: 8px 0;
  }
  .rera, .rera a, .marketed a, .marketed p {
    font-size: 14px;
    line-height: 26px;
  }
  .slick-prev, .slick-next {
    display: none !important;
  }
  .slider-drag-btn-wrapper {
    width: 285px;
  }
  .footer-mob {
    padding-bottom: 50px;
  }
  header .form-container {
    position: absolute;
    width: 100%;
    right: 0;
    top: 0;
    height: 100vh;
    background-color: #ffffff;
    padding: 10px;
    display: none;
    box-shadow: 0px 16px 20px 0px rgba(0, 0, 0, 0.28);
    padding-top: 30px !important;
  }
  .sell_do_virtual_numbers {
    color: #fff;
  }
}
@media only screen and (max-width: 700px) {
  header {
    height: 70px;
  }
  .menu {
    top: 70px;
    padding: 25px 20px 90px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .menu {
    top: 0px;
    padding: 70px 0 0;
  }
  .menu li a {
    padding: 15px 3px;
    font-size: 13px;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1200px) {
  .menu a {
    padding: 0px 3px;
    font-size: 12px;
  }
  .footer-bottom {
    padding: 0 25px 15px;
  }
  .footer-top {
    padding: 40px 30px 0px;
  }
}
/********* Homepage **************/
@keyframes homepage_spin_words {
  35% {
    transform: translateY(-100%);
  }
  70% {
    transform: translateY(-200%);
  }
  100% {
    transform: translateY(-300%);
  }
}
.homepage_spin_words {
  animation-name: homepage_spin_words;
  animation-duration: 6s;
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
}

.fold1_wrapper .reeloBrand_slider {
  padding: 40px 0;
}
.fold1_wrapper .reeloBrand_slider .brandSlide {
  height: 120px;
  padding: 0 27px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 30px 24px;
}
@media (max-width: 992px) {
  .fold1_wrapper .reeloBrand_slider .brandSlide {
    padding: 10px 24px;
    height: 80px;
    width: 120px !important;
  }
}
.fold1_wrapper .reeloBrand_slider .brandSlide img {
  width: 100px;
  height: 100px;
  object-fit: contain;
}
@media (max-width: 992px) {
  .fold1_wrapper .reeloBrand_slider .brandSlide img {
    width: 80px;
    height: 80px;
  }
}

.homepage .sec_subtitle {
  font-size: 24px;
  color: #697777;
  line-height: 28px;
  text-align: center;
  margin-bottom: 8px;
  letter-spacing: -0.96px;
  font-family: "Basier Circle medium";
}
@media (max-width: 992px) {
  .homepage .sec_subtitle {
    font-size: 16px;
    line-height: 24px;
  }
}
.homepage .sec_title {
  font-size: 58px;
  line-height: 64px;
  letter-spacing: -2.32px;
  font-family: "Basier Circle medium";
  font-weight: 500;
}
@media (max-width: 992px) {
  .homepage .sec_title {
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.96px;
  }
}
.homepage .banner {
  height: 100vh;
}
.homepage .banner .banner_wrapper .banner-container {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  padding: 0 15px;
}
.homepage .banner .banner_wrapper .banner-container .rating {
  margin-bottom: 24px;
}
@media (max-width: 992px) {
  .homepage .banner .banner_wrapper .banner-container .rating {
    margin-bottom: 0;
  }
}
.homepage .banner .banner_wrapper .banner-container .rating p {
  font-size: 16px;
  line-height: 22px;
  font-family: "Basier Circle medium";
  color: #697777;
}
@media (max-width: 992px) {
  .homepage .banner .banner_wrapper .banner-container .rating p {
    font-size: 14px;
    line-height: 20px;
  }
}
.homepage .banner .banner_wrapper .banner-container .homepage_title_wrapper {
  font-size: 72px;
  color: #192121;
  line-height: 75px;
  text-align: center;
  letter-spacing: -2.88px;
  font-family: "Basier Circle Semibold";
  margin: 12px auto 24px;
  gap: 0 16px;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 990px;
}
@media (max-width: 992px) {
  .homepage .banner .banner_wrapper .banner-container .homepage_title_wrapper {
    font-size: 34px;
    line-height: 42px;
    letter-spacing: -1.36px;
    width: 100%;
    margin: 10px 0;
  }
}
.homepage .banner .banner_wrapper .banner-container .banner-text {
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  color: #697777;
  text-align: center;
  letter-spacing: -0.2px;
  width: 815px;
  margin: 0 auto;
}
@media (max-width: 992px) {
  .homepage .banner .banner_wrapper .banner-container .banner-text {
    font-size: 14px;
    line-height: 20px;
    width: 100%;
  }
}
.homepage .banner .banner_wrapper .banner-container .btn_wrapper .theme-btn {
  font-size: 18px !important;
  width: 249px;
  margin: 20px auto 8px;
  padding: 15px 25px;
  font-family: "Basier Circle medium";
}
@media (max-width: 992px) {
  .homepage .banner .banner_wrapper .banner-container .btn_wrapper .theme-btn {
    margin: 0 auto 8px;
    height: unset;
  }
}
.homepage .banner .banner_wrapper .banner-container .btn_wrapper .text-small {
  color: #697777;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  font-family: "Basier Circle medium";
}
.homepage .fold1 .sec-title {
  font-size: 28px;
  color: #0e1b1b;
  line-height: 34px;
  text-align: center;
  margin: 0px 0 30px;
  letter-spacing: -0.28px;
  font-family: "Basier Circle medium";
  font-weight: 500;
}
@media (max-width: 992px) {
  .homepage .fold1 .sec-title {
    font-size: 24px;
    line-height: 30px;
    margin: 0 auto 12px;
    letter-spacing: -0.96px;
  }
}
.homepage .fold1 .sec-title span {
  color: #0d9298;
}
.homepage .fold3 .marrows {
  margin: 30px 0 35px;
}
.homepage .fold3 .marrows svg {
  width: 48px;
  height: 48px;
  background-color: #f3f5f5;
  border-radius: 100%;
  padding: 10px;
  margin: 0 10px;
}
.homepage .fold3 .marrows svg:hover {
  background-color: #0d9298;
}
.homepage .fold3 .marrows svg:hover path {
  stroke: #fff;
}
.homepage .fold3 .marrows #cnext {
  position: relative;
  transform: rotate(180deg);
}
.homepage .fold3 .reviewCarousel_parent .reviewCarousel {
  gap: 48px;
  padding: 24px;
  height: 381px;
  margin: 0 4px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  border-radius: 24px;
  align-items: stretch;
  background: #e0eaff;
}
@media (max-width: 992px) {
  .homepage .fold3 .reviewCarousel_parent .reviewCarousel {
    gap: 12px;
    padding: 16px;
    margin: 0 4px;
    border-radius: 8px;
    flex-direction: column;
    height: unset;
  }
}
.homepage .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_left {
  max-width: 665px;
  overflow: hidden;
  width: calc(50% - 24px);
}
@media (max-width: 992px) {
  .homepage .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_left {
    max-width: 100%;
    width: 100%;
    overflow: unset;
  }
}
.homepage .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_left iframe {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  border-radius: 12px;
}
.homepage .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_right {
  gap: 24px;
  padding: 10px 0 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  max-width: -moz-calc(50% - 24px);
  max-width: calc(50% - 24px);
}
@media (max-width: 992px) {
  .homepage .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_right {
    max-width: 100%;
    min-width: 100%;
    padding: 15px 0 0;
  }
}
.homepage .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_right h3 {
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -1.28px;
  font-family: "Basier Circle medium";
  font-weight: 500;
}
@media (max-width: 992px) {
  .homepage .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_right h3 {
    margin: 0 0 8px;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.24px;
  }
}
.homepage .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_right .reviewCarousel_review_detail {
  gap: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.homepage .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_right .reviewCarousel_review_detail .reviewImgs {
  border-radius: 50%;
  object-fit: contain;
  background-color: #fff;
}
.homepage .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_right .reviewCarousel_review_detail .reviewer_name {
  font-size: 22px;
  line-height: 26px;
  font-family: "Basier Circle medium";
  padding: 0 0 6px;
}
@media (max-width: 992px) {
  .homepage .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_right .reviewCarousel_review_detail .reviewer_name {
    font-size: 16px;
    line-height: 22px;
  }
}
.homepage .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_right .reviewCarousel_review_detail .reviewer_post {
  font-size: 14px;
  color: #374040;
  line-height: 20px;
  letter-spacing: -0.14px;
}
@media (max-width: 992px) {
  .homepage .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_right .reviewCarousel_review_detail .reviewer_post {
    font-size: 12px;
    line-height: 16px;
  }
}
.homepage .fold8 .reviewCarousel_case_studies {
  gap: 24px;
  margin: 60px 0 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
@media (max-width: 992px) {
  .homepage .fold8 .reviewCarousel_case_studies {
    gap: 18px;
    overflow-x: auto;
    margin: 24px 0 0;
    -moz-box-pack: start;
    justify-content: flex-start;
  }
}
.homepage .fold8 .reviewCarousel_case_studies .reviewCarousel_case_study {
  width: calc(50% - 12px);
  display: block;
}
@media (max-width: 992px) {
  .homepage .fold8 .reviewCarousel_case_studies .reviewCarousel_case_study {
    min-width: 254px;
    width: calc(50% - 9px);
  }
}
.homepage .fold8 .reviewCarousel_case_studies .reviewCarousel_case_study .reviewCarousel_link h3 {
  font-size: 28px;
  margin: 20px 0 12px;
  line-height: 36px;
  letter-spacing: -1.12px;
  color: #0e1b1b;
}
@media (max-width: 992px) {
  .homepage .fold8 .reviewCarousel_case_studies .reviewCarousel_case_study .reviewCarousel_link h3 {
    font-size: 14px;
    line-height: 20px;
    margin: 10px 0;
    letter-spacing: -0.14px;
  }
}
.homepage .fold8 .reviewCarousel_case_studies .reviewCarousel_case_study .reviewCarousel_link p {
  text-decoration: underline;
  font-family: "Basier Circle medium";
  color: #0e1b1b;
}
@media (max-width: 992px) {
  .homepage .fold8 .reviewCarousel_case_studies .reviewCarousel_case_study .reviewCarousel_link p {
    margin: 0 0 12px;
    font-size: 14px;
    line-height: 16px;
  }
}
.homepage .fold2 .fold2_wrapper {
  width: 100%;
  height: 565px;
  max-width: 1024px;
  margin: 40px auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}
@media (max-width: 992px) {
  .homepage .fold2 .fold2_wrapper {
    height: unset;
    max-width: 100%;
    width: 100%;
    display: block;
  }
}
.homepage .fold2 .fold2_wrapper .quotes_Img {
  width: 760px;
}
@media (max-width: 992px) {
  .homepage .fold2 .fold2_wrapper .quotes_Img {
    width: 100%;
  }
}
.homepage .fold2 .fold2_wrapper .quotes_Img .video-thumbnail .play-icon-wrapper {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  cursor: pointer;
}
.homepage .fold2 .fold2_wrapper .quotes_Img iframe {
  height: 403px;
  border: none;
  outline: none;
  border-radius: 24px;
}
.homepage .fold4 .sec-subtitle {
  font-size: 24px;
  color: #697777;
  line-height: 28px;
  text-align: center;
  margin-bottom: 8px;
  letter-spacing: -0.96px;
}
@media (max-width: 992px) {
  .homepage .fold4 .sec-subtitle {
    font-size: 16px;
    line-height: 20px;
  }
}
.homepage .fold4 .homepage_features {
  margin: 60px 0 0;
  gap: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
@media (max-width: 992px) {
  .homepage .fold4 .homepage_features {
    margin: 30px 0 0;
    gap: 32px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
}
.homepage .fold4 .homepage_features .homepage_feature_card {
  gap: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 992px) {
  .homepage .fold4 .homepage_features .homepage_feature_card {
    gap: 16px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
  }
}
.homepage .fold4 .homepage_features .homepage_feature_card .homepage_left {
  max-width: 453px;
}
@media (max-width: 992px) {
  .homepage .fold4 .homepage_features .homepage_feature_card .homepage_left {
    max-width: 100%;
  }
}
.homepage .fold4 .homepage_features .homepage_feature_card .homepage_right {
  max-width: 582px;
}
@media (max-width: 992px) {
  .homepage .fold4 .homepage_features .homepage_feature_card .homepage_right {
    max-width: 100%;
  }
}
.homepage .fold4 .homepage_features .homepage_feature_card .homepage_right h3 {
  font-size: 32px;
  margin: 0 0 14px;
  color: #0e1b1b;
  line-height: 38px;
  letter-spacing: -0.96px;
  font-family: "Basier Circle medium";
  font-weight: 500;
}
@media (max-width: 992px) {
  .homepage .fold4 .homepage_features .homepage_feature_card .homepage_right h3 {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.14px;
  }
}
.homepage .fold4 .homepage_features .homepage_feature_card .homepage_right p {
  font-size: 18px;
  margin: 0 0 28px;
  color: #697777;
  line-height: 24px;
  letter-spacing: -0.18px;
}
@media (max-width: 992px) {
  .homepage .fold4 .homepage_features .homepage_feature_card .homepage_right p {
    font-size: 14px;
    margin: 0 0 16px;
    line-height: 20px;
    letter-spacing: -0.14px;
  }
}
.homepage .fold4 .homepage_features .homepage_feature_card .homepage_right .homepage_btn_row {
  gap: 28px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
@media (max-width: 992px) {
  .homepage .fold4 .homepage_features .homepage_feature_card .homepage_right .homepage_btn_row {
    gap: 16px;
  }
  .homepage .fold4 .homepage_features .homepage_feature_card .homepage_right .homepage_btn_row .theme-btn {
    padding: 7px 10px;
    width: 125px;
    font-size: 12px !important;
  }
}
.homepage .fold4 .homepage_features .homepage_feature_card .homepage_right .homepage_btn_row .custom-btn {
  color: #0E1B1B;
  background: transparent;
  border-radius: 8px;
  text-decoration: underline;
  font-weight: 600;
}
.homepage .fold5 .homepage_more_features_cards {
  margin: 60px 0 0;
  gap: 32px 36px;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 992px) {
  .homepage .fold5 .homepage_more_features_cards {
    gap: 28px;
  }
}
.homepage .fold5 .homepage_more_features_cards .homepage_more_feature_card {
  border-radius: 12px;
  padding: 32px 40px 0;
  width: calc(50% - 18px);
  background-color: #f7f8f8;
  height: 608px;
}
@media (max-width: 992px) {
  .homepage .fold5 .homepage_more_features_cards .homepage_more_feature_card {
    width: 100%;
    border-radius: 8px;
    padding: 20px 20px 0;
    background-color: #f7f8f8;
    height: auto;
  }
}
.homepage .fold5 .homepage_more_features_cards .homepage_more_feature_card h3 {
  font-size: 24px;
  color: #0e1b1b;
  line-height: 30px;
  letter-spacing: -0.24px;
  font-family: "Basier Circle medium";
  font-weight: 500;
}
@media (max-width: 992px) {
  .homepage .fold5 .homepage_more_features_cards .homepage_more_feature_card h3 {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.14px;
  }
}
.homepage .fold5 .homepage_more_features_cards .homepage_more_feature_card p {
  margin: 8px 0;
  font-size: 20px;
  color: #374040;
  line-height: 26px;
  letter-spacing: -0.2px;
}
@media (max-width: 992px) {
  .homepage .fold5 .homepage_more_features_cards .homepage_more_feature_card p {
    margin: 4px 0;
    font-size: 14px;
    line-height: 20px;
  }
}
.homepage .fold5 .homepage_more_features_cards .homepage_more_feature_card a {
  color: #0d9298 !important;
  font-family: "Basier Circle medium";
  text-decoration: underline !important;
}
@media (max-width: 992px) {
  .homepage .fold5 .homepage_more_features_cards .homepage_more_feature_card a {
    font-size: 12px;
  }
}
.homepage .fold5 .homepage_more_features_cards .homepage_more_feature_card .homepage_card_img {
  width: 100%;
  height: 410px;
  cursor: pointer;
  margin: 20px 0 0;
  position: relative;
}
@media (max-width: 992px) {
  .homepage .fold5 .homepage_more_features_cards .homepage_more_feature_card .homepage_card_img {
    height: 260px;
  }
}
.homepage .fold5 .homepage_more_features_cards .homepage_more_feature_card .homepage_card_img img {
  height: 410px;
}
@media (max-width: 992px) {
  .homepage .fold5 .homepage_more_features_cards .homepage_more_feature_card .homepage_card_img img {
    height: 260px;
  }
}
.homepage .fold6 {
  padding: 40px 0;
}
@media (max-width: 992px) {
  .homepage .fold6 {
    padding: 30px 0 0;
  }
}
.homepage .fold6 .homepage_start_free_trial {
  padding: 34px 0;
  cursor: pointer;
  transform: skewY(-1.47deg);
  background: linear-gradient(93deg, #168489 -2.4%, #20c3c3 94.01%);
  overflow: hidden;
  white-space: nowrap;
}
@media (max-width: 992px) {
  .homepage .fold6 .homepage_start_free_trial {
    padding: 18px 0;
  }
}
.homepage .fold6 .homepage_start_free_trial .marquee {
  display: flex;
  animation: marquee 20s linear infinite;
}
.homepage .fold6 .homepage_start_free_trial .marquee .marquee-content {
  display: flex;
  white-space: nowrap;
}
.homepage .fold6 .homepage_start_free_trial .marquee .marquee-content .homepage_free_trial_text {
  padding: 0 48px;
  width: 100%;
  display: inline-flex !important;
  align-items: center;
  margin-right: 2rem;
}
.homepage .fold6 .homepage_start_free_trial .marquee .marquee-content .homepage_free_trial_text p {
  font-size: 28px;
  color: #fff;
  line-height: 34px;
  letter-spacing: -0.28px;
  font-family: "Basier Circle medium";
}
@media (max-width: 992px) {
  .homepage .fold6 .homepage_start_free_trial .marquee .marquee-content .homepage_free_trial_text p {
    font-size: 18px;
  }
}
@keyframes marquee {
  from {
    transform: translateX(-50%);
  }
  to {
    transform: translateX(0);
  }
}
.homepage .fold7 .homepage_quality_cards {
  margin: 60px 0 0;
  gap: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: stretch;
}
@media (max-width: 992px) {
  .homepage .fold7 .homepage_quality_cards {
    gap: 16px;
    padding: 0 16px;
    overflow-x: auto;
    justify-content: flex-start;
    margin: 20px 0 0;
  }
}
.homepage .fold7 .homepage_quality_cards .homepage_quality_card {
  padding: 40px;
  border-radius: 12px;
  background: #0e1b1b;
  width: -moz-calc(33% - 14px);
  width: calc(33% - 14px);
}
@media (max-width: 992px) {
  .homepage .fold7 .homepage_quality_cards .homepage_quality_card {
    padding: 20px;
    min-width: 226px;
    border-radius: 8px;
    width: calc(33% - 14px);
  }
}
.homepage .fold7 .homepage_quality_cards .homepage_quality_card svg {
  display: block;
  margin: 0 auto 8px;
}
@media (max-width: 992px) {
  .homepage .fold7 .homepage_quality_cards .homepage_quality_card svg {
    width: 24px;
    height: 24px;
  }
}
.homepage .fold7 .homepage_quality_cards .homepage_quality_card h3 {
  font-size: 24px;
  color: #ebbd07;
  margin: 0 0 32px;
  line-height: 30px;
  text-align: center;
  letter-spacing: -0.24px;
  font-family: "Basier Circle medium";
  font-weight: 500;
  height: 30px;
}
@media (max-width: 992px) {
  .homepage .fold7 .homepage_quality_cards .homepage_quality_card h3 {
    font-size: 14px;
    margin: 0 0 5px;
    line-height: 20px;
    letter-spacing: -0.14px;
  }
}
.homepage .fold7 .homepage_quality_cards .homepage_quality_card .homepage_number {
  margin: 0 0 10px;
  font-size: 56px;
  line-height: 84px;
  color: #fff;
  text-align: center;
  letter-spacing: -0.56px;
  font-family: "Basier Circle Semibold";
}
@media (max-width: 992px) {
  .homepage .fold7 .homepage_quality_cards .homepage_quality_card .homepage_number {
    margin: 0 0 8px;
    font-size: 28px;
    line-height: 34px;
    letter-spacing: -0.28px;
  }
}
.homepage .fold7 .homepage_quality_cards .homepage_quality_card .homepage_subtitle {
  font-size: 20px;
  text-align: center;
  font-style: italic;
  line-height: 26px;
  letter-spacing: -0.2px;
  color: hsla(0, 0%, 100%, 0.78);
}
@media (max-width: 992px) {
  .homepage .fold7 .homepage_quality_cards .homepage_quality_card .homepage_subtitle {
    font-size: 12px;
    line-height: 16px;
  }
}
.homepage .fold9 {
  background-color: #f7f8f8;
  padding: 70px 0 220px;
}
@media (max-width: 992px) {
  .homepage .fold9 {
    padding: 40px 0 100px;
  }
}
.homepage .fold9 .sec_desc {
  font-size: 20px;
  color: #697777;
  line-height: 28px;
  text-align: center;
  margin: 16px 0 32px;
  letter-spacing: -0.2px;
}
.homepage .fold9 .templateCarousel_templates_carousel .templateCarousel_channel {
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 166px;
}
@media (max-width: 992px) {
  .homepage .fold9 .templateCarousel_templates_carousel .templateCarousel_channel {
    margin: 0 auto 160px;
  }
}
.homepage .fold9 .templateCarousel_templates_carousel .templateCarousel_channel .templateCarousel_tab {
  cursor: pointer;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 80px;
  background: #fff;
  border: 1px solid #e9eded;
}
.homepage .fold9 .templateCarousel_templates_carousel .templateCarousel_channel .templateCarousel_tab span {
  margin: 0;
  font-size: 18px;
  color: #697777;
  line-height: 24px;
  letter-spacing: -0.18px;
  font-family: "Basier Circle medium";
}
.homepage .fold9 .templateCarousel_templates_carousel .templateCarousel_channel .templateCarousel_tab.active_channel {
  background: #0d9298;
  border: 1px solid #0d9298;
}
.homepage .fold9 .templateCarousel_templates_carousel .templateCarousel_channel .templateCarousel_tab.active_channel span {
  color: #fff;
}
.homepage .fold9 .templateCarousel_templates_carousel .templateCarousel_slick_parent .customer-logos .templateCarousel_card_parent .templateCarousel_card {
  height: 377px;
  position: relative;
  border-radius: 18px;
  transition: 0.1s linear;
}
@media (max-width: 992px) {
  .homepage .fold9 .templateCarousel_templates_carousel .templateCarousel_slick_parent .customer-logos .templateCarousel_card_parent .templateCarousel_card {
    height: 160px;
  }
}
.homepage .fold9 .templateCarousel_templates_carousel .templateCarousel_slick_parent .customer-logos .templateCarousel_card_parent .templateCarousel_card:before {
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 18px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0, hsla(0, 0%, 100%, 0) 80%);
  z-index: 1;
}
.homepage .fold9 .templateCarousel_templates_carousel .templateCarousel_slick_parent .customer-logos .templateCarousel_card_parent .templateCarousel_card .templateCarousel_cardImg img {
  height: 377px;
  position: relative;
  border-radius: 18px;
  transition: 0.1s linear;
  object-fit: cover;
  border-radius: 18px;
  vertical-align: middle;
}
@media (max-width: 992px) {
  .homepage .fold9 .templateCarousel_templates_carousel .templateCarousel_slick_parent .customer-logos .templateCarousel_card_parent .templateCarousel_card .templateCarousel_cardImg img {
    height: 160px;
  }
}
.homepage .fold9 .templateCarousel_templates_carousel .templateCarousel_slick_parent .customer-logos .templateCarousel_card_parent .templateCarousel_card .templateCarousel_template_title {
  width: 100%;
  left: 0;
  top: 31px;
  z-index: 2;
  height: 60%;
  color: #fff;
  font-size: 24px;
  line-height: 29px;
  position: absolute;
  word-wrap: break-word;
  padding: 0 8px 0 20px;
  font-family: "Basier Circle Semibold";
}
@media (max-width: 992px) {
  .homepage .fold9 .templateCarousel_templates_carousel .templateCarousel_slick_parent .customer-logos .templateCarousel_card_parent .templateCarousel_card .templateCarousel_template_title {
    font-size: 12px;
    line-height: 16px;
    padding: 0 8px 0 10px;
    top: 10px;
  }
}
.homepage .fold10 .homepage_integration {
  margin: 40px 0 0;
}
@media (max-width: 992px) {
  .homepage .fold10 .homepage_integration {
    margin: 20px 0 0;
  }
}
.homepage .fold10 .homepage_integration .logoSlider {
  padding: 30px 0;
  display: flex;
  white-space: nowrap;
}
@media (max-width: 992px) {
  .homepage .fold10 .homepage_integration .logoSlider {
    padding: 10px 0;
  }
}
.homepage .fold10 .homepage_integration .logoSlider .homepage_logo_parent {
  height: 114px;
  margin: 0 24px;
  position: relative;
  border-radius: 12px;
  background: #f7f8f8;
  width: 267px !important;
}
@media (max-width: 992px) {
  .homepage .fold10 .homepage_integration .logoSlider .homepage_logo_parent {
    height: 58px;
    padding: 0 8px;
    margin: 0 12px;
    border-radius: 8px;
    width: 100px !important;
  }
}
.homepage .fold10 .homepage_integration .logoSlider .homepage_logo_parent img {
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  object-fit: scale-down;
  filter: none;
  background-size: cover;
  background-image: none;
  padding: 20px 35px;
}
@media (max-width: 992px) {
  .homepage .fold10 .homepage_integration .logoSlider .homepage_logo_parent img {
    padding: 9px 10px;
  }
}

@keyframes marquee1 {
  from {
    transform: translateX(-50%);
  }
  to {
    transform: translateX(0);
  }
}
.homepagenew .sec_title {
  font-size: 57px;
  font-weight: 800;
  line-height: 120%;
}
@media (max-width: 992px) {
  .homepagenew .sec_title {
    font-size: 28px;
  }
}
.homepagenew .sec_desc {
  font-size: 24px;
  font-weight: 400;
  line-height: 150%;
  padding: 10px 0 40px;
}
@media (max-width: 992px) {
  .homepagenew .sec_desc {
    font-size: 16px;
  }
}
.homepagenew .banner {
  background-image: url("../images/home-bg.webp");
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 1500px;
}
@media (max-width: 992px) {
  .homepagenew .banner {
    height: 858px;
    background-image: url("../images/home-bg-mob.webp");
  }
}
.homepagenew .banner .banner_wrapper {
  padding: 120px 0 0;
}
.homepagenew .banner .banner_wrapper .banner_container .rating {
  font-size: 13px;
  font-weight: 400;
  line-height: 150%;
}
.homepagenew .banner .banner_wrapper .banner_container .rating img {
  vertical-align: bottom;
  margin-right: 10px;
  font-size: 8px;
}
.homepagenew .banner .banner_wrapper .banner_container .banner_title {
  font-size: 76px;
  font-weight: 800;
  line-height: 110%;
  letter-spacing: -0.76px;
  padding: 20px 0 24px;
}
@media (max-width: 992px) {
  .homepagenew .banner .banner_wrapper .banner_container .banner_title {
    font-size: 32px;
  }
}
.homepagenew .banner .banner_wrapper .banner_container .banner_desc {
  font-size: 24px;
  font-weight: 500;
  line-height: 150%; /* 36px */
}
@media (max-width: 992px) {
  .homepagenew .banner .banner_wrapper .banner_container .banner_desc {
    font-size: 15px;
  }
}
.homepagenew .banner .banner_wrapper .banner_container .banner_img {
  padding: 40px 0 0;
}
@media (max-width: 992px) {
  .homepagenew .banner .banner_wrapper .banner_container .banner_img {
    padding: 20px 0 0;
  }
}
.homepagenew .fold1 .fold1_wrapper .logoSlider {
  padding: 20px 0;
  display: flex;
  white-space: nowrap;
}
.homepagenew .fold1 .fold1_wrapper .logoSlider .homepage_logo_parent {
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: auto !important;
}
.homepagenew .fold1 .fold1_wrapper .logoSlider .homepage_logo_parent img {
  object-fit: contain;
  height: 85px;
}
@media (max-width: 992px) {
  .homepagenew .fold1 .fold1_wrapper .logoSlider .homepage_logo_parent img {
    height: 60px;
  }
}
.homepagenew .fold2 .card_wrapper .cards-section .card {
  top: 0;
  left: 0;
  margin: 0 0 30px;
  width: 100%;
  height: 440px;
  border-radius: 30px 30px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.5s ease, z-index 0.5s ease;
}
@media (max-width: 992px) {
  .homepagenew .fold2 .card_wrapper .cards-section .card {
    display: block;
    height: unset;
    margin: 0;
  }
}
.homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}
@media (max-width: 992px) {
  .homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex {
    display: block;
  }
}
.homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .flex1 {
  border-radius: 30px;
  width: 463px;
  height: 450px;
  display: flex;
  align-items: center;
  padding: 20px;
}
@media (max-width: 992px) {
  .homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .flex1 {
    width: 100%;
    height: unset;
    display: block;
    padding: 20px;
    margin: 0 0 20px;
    border-radius: 14px;
  }
}
.homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .flex1 .challenge {
  font-size: 16px;
  font-weight: 700;
  line-height: 150%; /* 27px */
  text-transform: uppercase;
  padding-bottom: 8px;
}
@media (max-width: 992px) {
  .homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .flex1 .challenge {
    font-size: 14px;
  }
}
.homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .flex1 .c1 {
  color: #FFBBCC;
}
.homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .flex1 .c2 {
  color: #C9FFE5;
}
.homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .flex1 .c3 {
  color: #FFE7B6;
}
.homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .flex1 .c4 {
  color: #D1E6FF;
}
.homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .flex1 .c_title {
  font-size: 36px;
  font-weight: 700;
  line-height: 120%; /* 43.2px */
}
@media (max-width: 992px) {
  .homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .flex1 .c_title {
    font-size: 24px;
  }
}
.homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .flex1 .flex-list {
  padding: 30px 0 0 18px;
}
.homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .flex1 .flex-list li {
  font-size: 16px;
  font-weight: 400;
  line-height: 150%; /* 24px */
}
@media (max-width: 992px) {
  .homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .flex1 .flex-list li {
    font-size: 14px;
  }
}
.homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .flex1 .fl1 li {
  color: #FFF9F0;
}
.homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .flex1 .fl2 li {
  color: #fff;
}
.homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .f1 {
  background: #990024;
}
.homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .f2 {
  background: #15B79E;
}
.homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .f3 {
  background: #E29600;
}
.homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .f4 {
  background: #044A9E;
}
.homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .flexkomal {
  padding: 0px 100px 0 74px !important;
}
@media (max-width: 992px) {
  .homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .flexkomal {
    padding: 0 !important;
  }
}
.homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .flex2 {
  display: flex;
  width: 657px;
  height: 450px;
  padding: 0px 74px 0 74px;
  justify-content: center;
  align-items: flex-end;
  border-radius: 30px;
}
@media (max-width: 992px) {
  .homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .flex2 {
    width: 100%;
    height: unset;
    padding: 10px 20px 0;
    margin: 0 0 20px;
    border-radius: 14px;
  }
}
.homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .flex2 .flimg {
  width: 596.749px;
}
@media (max-width: 992px) {
  .homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .flex2 .flimg {
    width: 100%;
  }
}
.homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .flex2 .komal {
  width: 630.387px;
  border-radius: 0 0 0 30px;
}
@media (max-width: 992px) {
  .homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .flex2 .komal {
    width: 100%;
    border-radius: 0 0 0 14px;
  }
}
.homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .fc1 {
  background: #FDE0E7;
  align-items: center;
}
.homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .fc2 {
  background: #CAFFE5;
}
.homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .fc3 {
  background: #FFE7B6;
}
.homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .fc4 {
  background: #D1E6FF;
}
.homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .flex3 {
  display: flex;
  width: 349px;
  flex-direction: column;
  align-items: flex-start;
  gap: 50px;
  flex-shrink: 0;
}
.homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .flex3 .list ul {
  padding: 0 0 0 17px;
}
.homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .flex3 .list ul li {
  color: #1A1A1A;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%; /* 24px */
}
.homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .flex3 .quote {
  font-size: 24px;
  font-weight: 500;
  line-height: 150%;
}
.homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .flex3 .cardquote1 {
  color: #4B001A;
}
.homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .flex3 .cardquote2 {
  color: #6C5501;
}
.homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .flex3 .cardquote3 {
  color: #6E3E00;
}
.homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .flex3 .cardquote4 {
  color: #004B26;
}
.homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .flex3 .quote_div {
  font-feature-settings: "liga" off, "clig" off;
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 20px 0 0;
}
.homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .flex3 .cardtext1 {
  color: #B62256;
}
.homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .flex3 .cardtext2 {
  color: #AA8500;
}
.homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .flex3 .cardtext3 {
  color: #EC921C;
}
.homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .flex3 .cardtext4 {
  color: #254A38;
}
.homepagenew .fold3 {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 100px 0 96px;
}
@media (max-width: 992px) {
  .homepagenew .fold3 {
    padding: 30px 0;
    height: unset;
    background-image: url("../images/video-frame-mob.webp") !important;
  }
}
@media (max-width: 992px) {
  .homepagenew .fold3 .sec_title {
    font-size: 20px;
  }
}
@media (max-width: 992px) {
  .homepagenew .fold3 .sec_desc {
    font-size: 10px;
    padding: 10px 0;
  }
}
@media (max-width: 992px) {
  .homepagenew .fold3 .video_wrapper {
    padding: 0 20px;
  }
}
.homepagenew .fold3 .video_wrapper .quotes_Img .video-thumbnail .play-icon-wrapper {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  cursor: pointer;
}
.homepagenew .fold3 .video_wrapper iframe {
  height: 563px;
  border-radius: 12px;
  border: none;
  border-radius: 8px;
}
@media (max-width: 992px) {
  .homepagenew .fold3 .video_wrapper iframe {
    height: 265px;
  }
}
.homepagenew .fold4 .tooltabs {
  padding: 15px 0 72px;
}
@media (max-width: 992px) {
  .homepagenew .fold4 .tooltabs {
    display: flex;
    overflow-x: auto;
    width: 100%;
    white-space: nowrap;
    scrollbar-width: thin;
    padding: 15px 0 30px;
    margin: 0 0 10px;
  }
}
.homepagenew .fold4 .tooltabs a {
  color: #023342;
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
  border-radius: 48px;
  border: 1px solid #023342;
  padding: 10px 24px 6px;
  margin: 12px 12px 0 0;
  display: inline-block;
}
@media (max-width: 992px) {
  .homepagenew .fold4 .tooltabs a {
    font-size: 14px;
    padding: 8px 16px 6px;
    margin: 12px 6px 0 0;
  }
}
.homepagenew .fold4 .tooltabs a.active {
  color: #fff;
  background: var(--colour-primary-teal-dark, #023342);
}
.homepagenew .fold4 .toolFold {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 72px;
}
@media (max-width: 992px) {
  .homepagenew .fold4 .toolFold {
    flex-direction: column-reverse;
    gap: 30px;
  }
}
.homepagenew .fold4 .toolFold .toolLeft {
  max-width: 530px;
}
@media (max-width: 992px) {
  .homepagenew .fold4 .toolFold .toolLeft {
    max-width: 100%;
  }
}
.homepagenew .fold4 .toolFold .toolLeft .sec_title {
  color: #023342;
  font-size: 43px;
  font-weight: 600;
  line-height: 120%;
  padding-bottom: 20px;
}
@media (max-width: 992px) {
  .homepagenew .fold4 .toolFold .toolLeft .sec_title {
    font-size: 24px;
  }
}
.homepagenew .fold4 .toolFold .toolLeft ul {
  padding: 0 0 40px 22px;
}
.homepagenew .fold4 .toolFold .toolLeft ul li {
  color: #1A1A1A;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 27px */
}
@media (max-width: 992px) {
  .homepagenew .fold4 .toolFold .toolLeft ul li {
    font-size: 16px;
  }
}
.homepagenew .fold4 .toolFold .toolRight {
  max-width: 560px;
}
@media (max-width: 992px) {
  .homepagenew .fold4 .toolFold .toolRight {
    max-width: 100%;
  }
}
.homepagenew .fold5 {
  background: #FFF1DD;
}
.homepagenew .fold5 .static_wrapper {
  padding: 40px 0 0;
  border-radius: 12px;
}
.homepagenew .fold5 .static_wrapper .fold1 {
  background: var(--secondary-shades-teal03-3t300, #1A424F);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px;
  height: 400px;
  border-radius: 12px 0 0 12px;
}
@media (max-width: 992px) {
  .homepagenew .fold5 .static_wrapper .fold1 {
    border-radius: 12px 12px 0 0;
    height: 350px;
  }
}
.homepagenew .fold5 .static_wrapper .fold1 .fold1_desc .title {
  font-size: 32px;
  font-weight: 800;
  line-height: 130%;
}
.homepagenew .fold5 .static_wrapper .fold1 .fold1_desc .desc {
  font-size: 14px;
  font-weight: 400;
  line-height: 130%;
  padding: 8px 0 0;
}
.homepagenew .fold5 .static_wrapper .fold1 .fold1_desc .cta-btn {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 19.6px */
  text-transform: uppercase;
  color: #04BEBD;
}
.homepagenew .fold5 .static_wrapper .fold2 .fold2_wrapper .foldLeft {
  background-color: #D16309;
  height: 270px;
  padding: 165px 30px 30px 30px;
}
@media (max-width: 992px) {
  .homepagenew .fold5 .static_wrapper .fold2 .fold2_wrapper .foldLeft {
    padding: 80px 30px 30px;
    height: unset;
  }
}
.homepagenew .fold5 .static_wrapper .fold2 .fold2_wrapper .foldLeft .cs_count .number {
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: 130%;
}
.homepagenew .fold5 .static_wrapper .fold2 .fold2_wrapper .foldLeft .cs_count .desc {
  font-size: 14px;
  font-weight: 400;
  line-height: 130%;
  padding: 8px 0 0;
}
.homepagenew .fold5 .static_wrapper .fold2 .fold2_wrapper .foldRight {
  height: 270px;
}
@media (max-width: 992px) {
  .homepagenew .fold5 .static_wrapper .fold2 .fold2_wrapper .foldRight {
    height: unset;
  }
}
.homepagenew .fold5 .static_wrapper .fold2 .fold2_wrapper .foldRight img {
  border-radius: 0 12px 12px 0;
}
@media (max-width: 992px) {
  .homepagenew .fold5 .static_wrapper .fold2 .fold2_wrapper .foldRight img {
    border-radius: 0;
  }
}
.homepagenew .fold5 .static_wrapper .fold2 .foldBottom {
  background: #F9CE78;
  border-radius: 0 0 12px 0;
}
@media (max-width: 992px) {
  .homepagenew .fold5 .static_wrapper .fold2 .foldBottom {
    border-radius: 0 0 12px 12px;
    margin: -5px 0 0;
  }
}
.homepagenew .fold5 .static_wrapper .fold2 .foldBottom .flexBox {
  display: inline-flex;
  padding: 26px 90px 26px 30.258px;
  align-items: flex-start;
  gap: 20.314px;
}
@media (max-width: 992px) {
  .homepagenew .fold5 .static_wrapper .fold2 .foldBottom .flexBox {
    padding: 20px;
    gap: 10px;
    flex-wrap: wrap;
  }
}
.homepagenew .fold5 .static_wrapper .fold2 .foldBottom .flexBox p {
  color: #1A424F;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}
@media (max-width: 992px) {
  .homepagenew .fold5 .static_wrapper .fold2 .foldBottom .flexBox p {
    font-size: 16px;
  }
}
.homepagenew .fold5 .reelo_cs {
  padding: 24px 0 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
@media (max-width: 992px) {
  .homepagenew .fold5 .reelo_cs {
    display: block;
  }
}
.homepagenew .fold5 .reelo_cs .blogBox {
  width: 404px;
}
@media (max-width: 992px) {
  .homepagenew .fold5 .reelo_cs .blogBox {
    width: 100%;
  }
}
.homepagenew .fold5 .reelo_cs .blogBox .csinner-box-wrapper {
  border-radius: 12px;
  background: var(--colour-monochrome-white, #FFF);
  box-shadow: 3px 4px 12.2px 0px rgba(2, 51, 66, 0.12);
  padding: 20px;
}
.homepagenew .fold5 .reelo_cs .blogBox .csinner-box-wrapper a .cs-box .cs_Img {
  padding: 0 0 12px;
}
.homepagenew .fold5 .reelo_cs .blogBox .csinner-box-wrapper a .cs-box .title {
  color: #1A424F;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  padding: 12px 0 0;
}
@media (max-width: 992px) {
  .homepagenew .fold5 .reelo_cs .blogBox .csinner-box-wrapper a .cs-box .title {
    font-size: 20px;
  }
}
.homepagenew .fold5 .reelo_cs .blogBox .csinner-box-wrapper a .cs-box .desc {
  color: #666;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  padding: 14px 0;
}
.homepagenew .fold5 .reelo_cs .blogBox .csinner-box-wrapper a .cs-box .cta-btn {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 19.6px */
  text-transform: uppercase;
  color: #04BEBD;
}
.homepagenew .fold6 .integrate_wrapper {
  padding-top: 50px;
}
@media (max-width: 992px) {
  .homepagenew .fold6 .integrate_wrapper {
    padding-top: 20px;
  }
}
.homepagenew .fold6 .integrate_wrapper .flexDiv {
  padding-top: 8px;
  display: flex;
  align-items: center;
  align-content: center;
  gap: 8px;
  flex-wrap: wrap;
}
@media (max-width: 992px) {
  .homepagenew .fold6 .integrate_wrapper .flexDiv {
    padding-top: 40px;
    align-items: center;
    align-content: center;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 6px;
    text-align: center;
  }
}
.homepagenew .fold6 .integrate_wrapper .flexDiv .integrate img {
  height: 60px;
}
@media (max-width: 992px) {
  .homepagenew .fold6 .integrate_wrapper .flexDiv .integrate img {
    height: 30px;
  }
}
.homepagenew .fold7 {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@media (max-width: 992px) {
  .homepagenew .fold7 {
    background-image: url("../images/intergration-bg-mob.webp") !important;
  }
}
.homepagenew .fold7 .lovereelo_wrapper {
  padding-top: 60px;
  display: flex;
  align-items: flex-start;
  gap: 20px;
  justify-content: center;
}
@media (max-width: 992px) {
  .homepagenew .fold7 .lovereelo_wrapper {
    flex-wrap: wrap;
    gap: 40px;
    padding-top: 40px;
  }
}
.homepagenew .fold7 .lovereelo_wrapper .lovereelo_inner .lovereelo_title {
  font-size: 43px;
  font-weight: 600;
  line-height: 120%;
}
@media (max-width: 992px) {
  .homepagenew .fold7 .lovereelo_wrapper .lovereelo_inner .lovereelo_title {
    font-size: 30px;
  }
}
.homepagenew .fold7 .lovereelo_wrapper .lovereelo_inner .t1 {
  color: #FAC8D4;
}
.homepagenew .fold7 .lovereelo_wrapper .lovereelo_inner .t2 {
  color: #B6EB9A;
}
.homepagenew .fold7 .lovereelo_wrapper .lovereelo_inner .t3 {
  color: #FFE7B6;
}
.homepagenew .fold7 .lovereelo_wrapper .lovereelo_inner .lovereelo_img {
  padding: 20px 0;
}
.homepagenew .fold7 .lovereelo_wrapper .lovereelo_inner .lovereelo_desc {
  display: flex;
  align-items: flex-start;
  gap: 14px;
  justify-content: center;
}
.homepagenew .fold7 .lovereelo_wrapper .lovereelo_inner .lovereelo_desc span {
  font-size: 48px;
  font-weight: 900;
  line-height: 120%;
}
@media (max-width: 992px) {
  .homepagenew .fold7 .lovereelo_wrapper .lovereelo_inner .lovereelo_desc span {
    font-size: 39px;
  }
}
.homepagenew .fold7 .lovereelo_wrapper .lovereelo_inner .lovereelo_desc p {
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
  text-align: left;
}
@media (max-width: 992px) {
  .homepagenew .fold7 .lovereelo_wrapper .lovereelo_inner .lovereelo_desc p {
    font-size: 13px;
  }
}
.homepagenew .fold8 {
  margin: -2px 0 0;
  background: #023342;
}
.homepagenew .fold8 .faq_wrapper {
  padding: 40px 0 0;
}
.homepagenew .fold8 .faqs-list {
  padding: 0 20px 0 0;
  min-height: 400px;
}
@media (max-width: 992px) {
  .homepagenew .fold8 .faqs-list {
    padding: 0;
    min-height: unset;
  }
}
.homepagenew .fold8 .faqs-list .book-demo {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  margin: 60px 0 0;
}
@media (max-width: 992px) {
  .homepagenew .fold8 .faqs-list .book-demo {
    flex-wrap: wrap;
    gap: 20px;
    text-align: center;
  }
}
.homepagenew .fold8 .faqs-list .book-demo p {
  color: #04BEBD;
  font-size: 24px;
  font-weight: 600;
  line-height: 100%; /* 24px */
}
@media (max-width: 992px) {
  .homepagenew .fold8 .faqs-list .book-demo p {
    font-size: 18px;
  }
}
.homepagenew .fold8 .set {
  position: relative;
  width: 100%;
  height: auto;
  margin: auto;
}
.homepagenew .fold8 .set a {
  display: block;
  padding: 16px;
  text-decoration: none;
  transition: all 0.2s linear;
  margin: 8px 0 0;
  font-size: 20px;
  color: #1A424F;
  line-height: 130%;
  border-radius: 12px;
  background: var(--colour-monochrome-white, #FFF);
  font-weight: 600;
  position: relative;
  height: auto;
}
@media (max-width: 992px) {
  .homepagenew .fold8 .set a {
    font-size: 16px;
  }
}
.homepagenew .fold8 .set a i {
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 100%;
  transition: transform 0.2s ease-in-out;
}
@media (max-width: 992px) {
  .homepagenew .fold8 .set a i {
    right: 18px;
  }
}
.homepagenew .fold8 .set a .icon-minus:after {
  width: 20px;
  height: 20px;
  background-image: url("../images/minus.webp");
  background-repeat: no-repeat;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  content: "";
  text-align: center;
  transition: transform 0.2s ease-in-out;
  left: 50%;
}
.homepagenew .fold8 .set a .icon-plus:before {
  position: absolute;
  content: "";
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  left: 50%;
  background-image: url("../images/plus.webp");
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  transition: transform 0.2s ease-in-out;
}
.homepagenew .fold8 .set .faqs_content {
  background: var(--colour-monochrome-white, #FFF);
  padding: 20px;
  margin: -10px 0 0;
  border-radius: 0 0 12px 12px;
}
.homepagenew .fold8 .set .faqs_content div {
  color: #141415;
  font-size: 14px;
  font-weight: 400;
  line-height: 130%;
}
.homepagenew .fold8 .set .faqs_content div a {
  display: inline-block;
  padding: 0;
  margin: 0;
}
.homepagenew .fold8 .set .faqs_content div ul {
  padding: 10px 20px;
}
.homepagenew .fold8 .set .faqs_content div ul li {
  padding: 0 0 6px;
}
.homepagenew .fold8 .set .faqs_content div ul li a {
  display: inline-block;
  padding: 0;
}
.homepagenew .fold8 .set .faqs_content div ol {
  padding: 10px 20px;
}
.homepagenew .fold8 .set .faqs_content div ol li {
  padding: 0 0 6px;
}
.homepagenew .fold8 .set .faqs_content div ol li a {
  display: inline-block;
  padding: 0;
}
.homepagenew .fold9 {
  margin: -2px 0 0;
}
.homepagenew .fold9:before {
  position: absolute;
  content: "";
  background: #023342;
  left: 0;
  top: 0;
  height: 50%;
  width: 100%;
  z-index: -1;
}
@media (max-width: 992px) {
  .homepagenew .fold9:before {
    height: 33%;
  }
}
.homepagenew .fold9 .try_wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.homepagenew .fold9 .try_wrapper .big-arrow {
  position: relative;
  border-radius: 100%;
  left: -6px;
}
.homepagenew .fold9 .try_wrapper .big-arrow:hover {
  box-shadow: 4px 6px #000;
  transition: 0.4s;
  transform: rotate(-45deg);
}
@media (max-width: 992px) {
  .homepagenew .fold9 .try_wrapper .big-arrow {
    left: -2px;
    width: 60px;
  }
}
.homepagenew .fold9 .try_wrapper .ctabtn_big {
  font-size: 80px;
  font-weight: 800;
  line-height: 130%;
  text-transform: capitalize;
  border-radius: 500px;
  background: #0E8085;
  padding: 101.545px 83px 96.455px 83px;
}
@media (max-width: 992px) {
  .homepagenew .fold9 .try_wrapper .ctabtn_big {
    font-size: 18px;
    padding: 18px 50px 15px;
  }
}
.homepagenew .fold9 .try_wrapper .ctabtn_big span {
  color: #63FAFF;
}
.homepagenew .fold10 .testimonial1 {
  margin: 70px 0 0;
  border-radius: 12px;
  background: #DAF9DE;
  display: flex;
  align-items: flex-end;
  gap: 65px;
}
@media (max-width: 992px) {
  .homepagenew .fold10 .testimonial1 {
    gap: 40px;
    flex-direction: column-reverse;
    margin: 50px 0 0;
  }
}
.homepagenew .fold10 .testimonial1 .q1 {
  position: absolute;
  top: -40px;
  left: 20px;
}
.homepagenew .fold10 .testimonial1 .youngman {
  border-radius: 0 0 0 12px;
}
.homepagenew .fold10 .testimonial1 .quote1 {
  display: flex;
  width: 601px;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  padding: 40px 0;
}
@media (max-width: 992px) {
  .homepagenew .fold10 .testimonial1 .quote1 {
    width: 100%;
    gap: 20px;
    padding: 68px 20px 0;
  }
}
.homepagenew .fold10 .testimonial1 .quote1 p {
  color: #0D793D;
  font-size: 43px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 48px */
}
@media (max-width: 992px) {
  .homepagenew .fold10 .testimonial1 .quote1 p {
    font-size: 20px;
  }
}
.homepagenew .fold10 .testimonial1 .quote1 .q2 {
  position: absolute;
  right: 10px;
  bottom: 10px;
}
@media (max-width: 992px) {
  .homepagenew .fold10 .testimonial1 .quote1 .q2 {
    bottom: -12px;
  }
}
.homepagenew .fold10 .testimonial1 .quote1 .quoteFlex {
  padding: 40px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  align-self: stretch;
  border-top: 1px solid #A0DCA8;
}
@media (max-width: 992px) {
  .homepagenew .fold10 .testimonial1 .quote1 .quoteFlex {
    padding: 20px 0 0;
    flex-wrap: wrap;
    gap: 20px;
  }
}
.homepagenew .fold10 .testimonial1 .quote1 .quoteFlex .name {
  color: #122E37;
  font-size: 32px;
  font-weight: 600;
  line-height: 120%; /* 38.4px */
}
@media (max-width: 992px) {
  .homepagenew .fold10 .testimonial1 .quote1 .quoteFlex .name {
    font-size: 18px;
  }
}
.homepagenew .fold10 .testimonial1 .quote1 .quoteFlex .desig {
  color: #038584;
  font-size: 24px;
  font-weight: 400;
  line-height: 150%; /* 36px */
}
@media (max-width: 992px) {
  .homepagenew .fold10 .testimonial1 .quote1 .quoteFlex .desig {
    font-size: 14px;
  }
}
.homepagenew .fold10 .testimonial2 .quote2 {
  border-radius: 12px;
  padding: 24px 20px;
  gap: 20px;
}
.homepagenew .fold10 .testimonial2 .quote2 .moblogo {
  position: absolute;
  right: 20px;
  top: 10px;
  width: 52px;
}
.homepagenew .fold10 .testimonial2 .quote2 .quoteFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 992px) {
  .homepagenew .fold10 .testimonial2 .quote2 .quoteFlex {
    display: block;
  }
}
.homepagenew .fold10 .testimonial2 .quote2 .quoteFlex .quote_div {
  display: flex;
  gap: 18px;
  align-items: center;
}
.homepagenew .fold10 .testimonial2 .quote2 .quoteFlex .quote_div .client img {
  width: 78px;
  height: 78px;
  border-radius: 100%;
}
.homepagenew .fold10 .testimonial2 .quote2 .quoteFlex .quote_div .name {
  color: #023342;
  font-size: 20px;
  font-weight: 600;
  line-height: 130%;
}
@media (max-width: 992px) {
  .homepagenew .fold10 .testimonial2 .quote2 .quoteFlex .quote_div .name {
    font-size: 18px;
  }
}
.homepagenew .fold10 .testimonial2 .quote2 .quoteFlex .quote_div .desig {
  color: #333;
  font-size: 16px;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: 0.104px;
}
@media (max-width: 992px) {
  .homepagenew .fold10 .testimonial2 .quote2 .quoteFlex .quote_div .desig {
    font-size: 14px;
  }
}
.homepagenew .fold10 .testimonial2 .quote2 p {
  font-size: 23px;
  font-weight: 400;
  line-height: 150%;
  padding: 20px 0 0;
  min-height: 260px;
}
@media (max-width: 992px) {
  .homepagenew .fold10 .testimonial2 .quote2 p {
    font-size: 18px;
  }
}
.homepagenew .fold10 .testimonial2 .quote2 .textclr1 {
  color: #8A3B08;
}
.homepagenew .fold10 .testimonial2 .quote2 .textclr2 {
  color: #232D2D;
}
.homepagenew .fold10 .testimonial2 .clr1 {
  background: #FED6BB;
}
.homepagenew .fold10 .testimonial2 .clr2 {
  background: #E3CDFF;
}
.homepagenew .fold10 .padd1 {
  padding: 15px 8px 0 0;
}
@media (max-width: 992px) {
  .homepagenew .fold10 .padd1 {
    padding: 15px 0 0;
  }
}
.homepagenew .fold10 .padd2 {
  padding: 15px 0 0 8px;
}
@media (max-width: 992px) {
  .homepagenew .fold10 .padd2 {
    padding: 15px 0 0;
  }
}

.webinars .latest_fr {
  margin: 60px 0 0;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  height: 572px;
}
@media (max-width: 992px) {
  .webinars .latest_fr {
    flex-wrap: wrap;
    margin: 30px 0;
    height: unset;
    gap: 0;
  }
}
.webinars .latest_fr .latest_frContent {
  max-width: 600px;
  margin: 0 auto 0 40px;
}
@media (max-width: 992px) {
  .webinars .latest_fr .latest_frContent {
    max-width: 100%;
    padding: 20px;
    text-align: center;
    margin: 0;
  }
}
.webinars .latest_fr .latest_frContent .latest_caption {
  color: #5F4D07;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  background: #EBBD07;
  padding: 8px 20px;
  border-radius: 90px;
}
@media (max-width: 992px) {
  .webinars .latest_fr .latest_frContent .latest_caption {
    font-size: 14px;
    padding: 6px 15px;
  }
}
@media (max-width: 992px) {
  .webinars .latest_fr .latest_frContent .sec_title {
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.48px;
  }
}
.webinars .latest_fr .latest_frContent .sec_title .highlight {
  color: #ebbd07;
}
.webinars .latest_fr .latest_frContent .frshort_description {
  padding: 20px 0;
}
.webinars .latest_fr .latest_frImg {
  margin: 0;
}
.webinars .latest_fr .latest_frImg .fr_latestImg img {
  min-height: 494px;
  position: relative;
  top: 2.5px;
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
}
@media (max-width: 992px) {
  .webinars .latest_fr .latest_frImg .fr_latestImg img {
    border-top-right-radius: 0px;
    border-bottom-left-radius: 24px;
    min-height: unset;
    top: 6.5px;
  }
}
@media (max-width: 992px) {
  .webinars .latest_fr .latest_frImg {
    max-width: 100%;
  }
}
.webinars .blog_Title {
  color: #354545;
  font-weight: 600;
  font-size: 56px;
  line-height: 110%;
  letter-spacing: -0.01em;
  font-family: "Basier Circle medium";
}
@media (max-width: 992px) {
  .webinars .blog_Title {
    font-size: 24px;
    line-height: 32px;
  }
}
@media (max-width: 992px) {
  .webinars .blog_wrapper .sec-title {
    font-size: 28px;
    line-height: 32px;
  }
}
.webinars .webinar-box {
  padding: 0 10px 30px !important;
  height: unset;
}
@media (max-width: 992px) {
  .webinars .webinar-box {
    padding: 20px 0 !important;
  }
}
.webinars .webinar-box .webinar-title {
  font-size: 28px;
  line-height: 34px;
  color: #0e1b1b;
}
@media (max-width: 992px) {
  .webinars .webinar-box .webinar-title {
    font-size: 20px;
    line-height: 24px;
  }
}
.webinars .webinar-box .webitext {
  font-size: 20px;
  line-height: 28px;
  color: #0e1b1b;
}
@media (max-width: 992px) {
  .webinars .webinar-box .webitext {
    font-size: 16px;
    line-height: 24px;
  }
}
.webinars .webinar-box .webinar_Img iframe {
  border: none;
}

.webinar_content .profileImg img {
  width: 75px;
  height: 75px;
  border-radius: 100%;
}
.webinar_content .profile_desc {
  padding: 8px 0 8px 8px;
}
.webinar_content .profile_desc .speaker_name {
  font-size: 20px;
  color: #0e1b1b;
  line-height: 26px;
  letter-spacing: -0.2px;
  font-weight: 600;
  padding: 0 0 6px;
}
.webinar_content .profile_desc .speaker_bio {
  font-size: 16px;
  color: #374040;
  line-height: 22px;
}

.webinar_Img iframe {
  border-radius: 8px;
  height: 225px;
}
.webinar_Img .video-thumbnail .videoimg {
  height: 225px;
}

.webitext {
  padding: 20px 0;
}
@media (max-width: 992px) {
  .webitext {
    height: unset;
  }
}

.webinar-title {
  font-size: 22px;
  line-height: 28px;
  padding: 10px 0;
  height: 120px;
}

.blog_banner {
  margin: 70px 0 0;
}
@media (max-width: 992px) {
  .blog_banner {
    margin: 50px 0 0;
  }
}

.latest_blogWrapper {
  border-radius: 40px;
  background: #FAECDE;
  height: 422px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 26px;
}
@media (max-width: 992px) {
  .latest_blogWrapper {
    flex-wrap: wrap;
  }
}

.latest_caption {
  padding: 12px 30px;
  background-color: #f0dbc6;
  border-radius: 30px;
  display: inline-block;
  margin: 0 0 26px;
  color: #A58565;
  font-size: 16px;
  font-weight: 600;
  line-height: 125%;
  text-transform: uppercase;
}
@media (max-width: 992px) {
  .latest_caption {
    padding: 5px 10px;
    margin: 0 0 8px;
  }
}
.latest_caption img {
  vertical-align: text-bottom;
}

.blog_Title {
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  letter-spacing: -0.46px;
  margin-bottom: 26px;
}
@media (max-width: 992px) {
  .blog_Title {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 20px;
  }
}

.latest_blogContent {
  max-width: 600px;
  padding: 0 0 0 40px;
}
@media (max-width: 992px) {
  .latest_blogContent {
    padding: 0 0 20px;
  }
}
.latest_blogContent .short_description {
  height: 85px !important;
}
@media (max-width: 992px) {
  .latest_blogContent .short_description {
    height: unset !important;
  }
}

.short_description {
  padding: 14px 0;
}

.latest_blogImg {
  width: 540px;
  margin: 0 auto;
}

.blog_latestImg img {
  position: relative;
  transform: rotate(5.5deg);
  mix-blend-mode: darken;
  height: 287px;
}

.read_now {
  letter-spacing: -0.32px;
  font-weight: 600;
  display: inline-flex;
  transition-duration: 0.3s;
  transition-property: transform;
}
.read_now img {
  vertical-align: bottom;
}
.read_now:hover {
  transform: scale(0.9);
}

.see_more {
  color: #0d9298;
  font-weight: 600;
  float: right;
}
@media (max-width: 992px) {
  .see_more {
    float: left !important;
  }
}
.see_more img {
  vertical-align: bottom;
}

.blog-category {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  padding: 0 0 10px;
}

.blog-title {
  font-size: 22px;
  line-height: 28px;
}

.resources-title {
  font-size: 18px;
  line-height: 24px;
  padding: 10px 0;
  height: 65px;
}

.blog_heading {
  border-top: 1px solid #D7DDDD;
  padding: 40px 0 0;
}

.blog_wrapper {
  padding: 40px 0;
}
@media (max-width: 992px) {
  .blog_wrapper {
    padding: 0 0 40px;
  }
}

#recent_blogs .blog-box {
  padding: 0 50px 0 0;
}
@media (max-width: 992px) {
  #recent_blogs .blog-box {
    padding: 20px 0 40px;
  }
}
#recent_blogs .blog-title {
  height: 85px !important;
}
#recent_blogs .short_description {
  height: 90px;
}

#all_blogs .search_div {
  padding: 0 !important;
}
@media (max-width: 992px) {
  #all_blogs .search_div {
    padding: 0 !important;
  }
}
#all_blogs .search_div .search-container button {
  background: #fff;
  font-size: 0;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 2px;
  bottom: 6px;
}
#all_blogs .search_div .search-container input[type=text] {
  padding: 8px 6px;
  margin-top: 8px;
  font-size: 17px;
}
#all_blogs .blog-box {
  padding: 0 10px;
  height: 405px;
  transition-duration: 0.3s;
  transition-property: transform;
}
#all_blogs .blog-box:hover .blog_Img img {
  transform: translateY(-8px);
}
#all_blogs .blog-box .blog_Img img {
  height: 213px;
}
@media (max-width: 992px) {
  #all_blogs .blog-box .blog_Img img {
    height: 185px;
  }
}
@media (max-width: 992px) {
  #all_blogs .blog-box {
    padding: 20px 0;
    height: unset;
  }
}

.blog_Img img {
  border-radius: 8px;
  margin: 0 0 10px;
}

#free-resources {
  padding: 0 0 50px;
}
@media (max-width: 992px) {
  #free-resources {
    padding: 0;
  }
}

.resources-box {
  padding: 0 10px;
  transition-duration: 0.3s;
  transition-property: transform;
}
.resources-box:hover .resources_Img img {
  transform: scale(0.9);
}
@media (max-width: 992px) {
  .resources-box {
    padding: 15px 0;
  }
}

.csinner-box {
  padding: 0 20px 20px 0px;
  transition-duration: 0.3s;
  transition-property: transform;
}
.csinner-box:hover .cs_Img img {
  transform: scale(0.9);
}
@media (max-width: 992px) {
  .csinner-box {
    padding: 15px 0;
    height: unset;
  }
}
.csinner-box .cs_Img img {
  height: 206px;
}

.resources_Img img {
  border-radius: 8px;
}

.cs_Img img {
  border-radius: 8px;
}

.click_to_connect {
  background-color: #C2F6F6;
  padding: 46px;
  border-radius: 8px;
}
@media (max-width: 992px) {
  .click_to_connect {
    padding: 0;
  }
  .click_to_connect .sec-padd {
    padding: 20px;
  }
}
.click_to_connect .click-title {
  font-size: 40px;
  line-height: 50px;
  font-weight: 300;
  letter-spacing: -1.9px;
}
@media (max-width: 992px) {
  .click_to_connect .click-title {
    font-size: 22px;
    line-height: 32px;
  }
}
.click_to_connect .click-title span {
  color: green;
  font-weight: 600;
}

.cs_wrapper {
  padding: 20px 0;
}

.cs-category {
  color: #0d9298;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 10px 0;
}

.csinner_Title {
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  height: 100px;
}
@media (max-width: 992px) {
  .csinner_Title {
    height: unset;
    padding: 0 0 15px;
  }
}

.cs_Title {
  font-size: 28px;
  line-height: 38px;
  font-weight: 600;
}
@media (max-width: 992px) {
  .cs_Title {
    font-size: 20px;
    line-height: 28px;
  }
}

.CS_blogContent {
  padding: 40px 0 0 40px;
}
@media (max-width: 992px) {
  .CS_blogContent {
    padding: 0;
  }
}

.cs_author {
  font-size: 18px;
  line-height: 22px;
  color: #697777;
  font-weight: 500;
  text-transform: capitalize;
  padding: 8px 0 0;
}
.cs_author .userimg {
  width: 36px;
  height: 36px;
  border-radius: 100%;
  position: relative;
  top: 10px;
  left: -5px;
}

.girl {
  position: absolute;
  bottom: 0;
  right: 0;
}
@media (max-width: 992px) {
  .girl {
    position: relative;
  }
}

.category_slider {
  padding: 40px 0 20px;
}
@media (max-width: 992px) {
  .category_slider {
    display: flex;
    overflow-x: auto;
    width: 100%;
    white-space: nowrap;
    scrollbar-width: thin;
    padding: 30px 0;
  }
}

.category_slider li, .category_frslider li {
  list-style: none;
  display: inline-block;
}
.category_slider li a, .category_frslider li a {
  color: #fff;
  font-weight: 600;
  background-color: #0D9298;
  padding: 8px 12px;
  border-radius: 30px;
  margin: 0 3px;
  font-size: 14px;
  transition-duration: 0.3s;
}
.category_slider li a:hover, .category_frslider li a:hover {
  background-color: unset;
  color: #000;
}
.category_slider li a.active, .category_frslider li a.active {
  background-color: #fff;
  border: 1px solid #0d9298;
  color: #0d9298;
}
.category_slider li.slick-current.slick-active a, .category_frslider li.slick-current.slick-active a {
  background-color: #fff;
  border: 1px solid #0d9298;
  color: #0d9298;
}
.category_slider .slick-track, .category_frslider .slick-track {
  padding: 40px 0;
}

.see_all {
  color: #fff;
  background-color: #0D9298;
}

#blog-details .latest_blogContent {
  padding: 0;
}
@media (max-width: 992px) {
  #blog-details {
    margin: 30px 0 0;
  }
}
#blog-details .singlepost {
  background: #FAECDE;
}
@media (max-width: 992px) {
  #blog-details .singlepost {
    padding: 20px 10px;
  }
}
@media (max-width: 992px) {
  #blog-details .backblogs {
    border: 1px solid #E9EDED;
  }
}
#blog-details .backblogs .backtoblogs {
  color: #0d9298;
  font-weight: 600;
  padding: 30px 0;
  display: block;
}
@media (max-width: 992px) {
  #blog-details .backblogs .backtoblogs {
    padding: 15px 0;
  }
}
#blog-details .latest_blogWrapper {
  border-radius: 8px;
  background: #FAECDE;
  height: 422px;
}
@media (max-width: 992px) {
  #blog-details .latest_blogWrapper {
    height: unset;
    padding: 0;
    gap: 0;
  }
}
#blog-details .latest_blogWrapper .latest_blogContent .blog_Title {
  font-size: 40px;
  line-height: 50px;
}
@media (max-width: 992px) {
  #blog-details .latest_blogWrapper .latest_blogContent .blog_Title {
    font-size: 22px;
    line-height: 32px;
  }
}
#blog-details .latest_blogWrapper .publish_date {
  padding: 25px 0;
}
@media (max-width: 992px) {
  #blog-details .latest_blogWrapper .publish_date {
    padding: 0;
    font-size: 14px;
  }
}
#blog-details .latest_blogWrapper .publish_date span img {
  vertical-align: text-bottom;
}
#blog-details .latest_blogWrapper .publish_date span .userimg {
  width: 36px;
  height: 36px;
  border-radius: 100%;
  position: relative;
  top: 7px;
  left: -5px;
}
#blog-details .latest_blogWrapper .latest_blogImg {
  width: 562px;
  margin: 0 auto;
}
@media (max-width: 992px) {
  #blog-details .latest_blogWrapper .latest_blogImg .blog_Bigimage {
    padding: 20px 0 10px;
  }
}
#blog-details .latest_blogWrapper .latest_blogImg .blog_Bigimage img {
  mix-blend-mode: darken;
}
@media (max-width: 992px) {
  #blog-details .latest_blogWrapper .latest_blogImg .blog_Bigimage img {
    border-radius: 8px;
  }
}

.main_wrapper {
  border-top: 1px solid #D7DDDD;
}
.main_wrapper .detail_wrapper {
  display: flex;
}
@media (max-width: 992px) {
  .main_wrapper .detail_wrapper {
    display: block;
  }
}
.main_wrapper .navbar-fixed {
  position: fixed;
  top: 100px;
  width: 315px;
}
@media (max-width: 992px) {
  .main_wrapper .navbar-fixed {
    width: 100%;
    position: unset;
  }
}
.main_wrapper .csbar-fixed {
  position: fixed;
  top: 107px;
  width: 200px;
}
@media (max-width: 992px) {
  .main_wrapper .csbar-fixed {
    width: 100%;
    position: unset;
  }
}
.main_wrapper .demodiv-fixed {
  position: fixed;
  top: 120px;
  width: 210px;
}
@media (max-width: 992px) {
  .main_wrapper .demodiv-fixed {
    width: 100%;
    position: unset;
  }
}
.main_wrapper .csdemodiv-fixed {
  position: fixed;
  top: 107px;
  width: 315px;
}
@media (max-width: 992px) {
  .main_wrapper .csdemodiv-fixed {
    width: 100%;
    position: unset;
  }
}
.main_wrapper .stickySidebar {
  background-color: #fff;
  padding: 20px 8px;
  border-radius: 8px;
  box-shadow: 2px 2px 4px rgba(128, 128, 128, 0.2509803922);
}
@media (max-width: 992px) {
  .main_wrapper .stickySidebar {
    margin: 0 0 20px;
  }
}
.main_wrapper .stickySidebar .table_content {
  padding: 20px 4px 20px 20px;
}
.main_wrapper .stickySidebar .table_content li .sublist {
  padding: 5px 0 5px 14px;
}
.main_wrapper .stickySidebar .table_content li .sublist li {
  list-style-type: disc;
}
.main_wrapper .stickySidebar .table_content li .sublist li a {
  color: #000;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}
.main_wrapper .stickySidebar .table_content li .sublist li a.active {
  color: #60ACAC;
}
.main_wrapper .stickySidebar .table_content li a {
  color: #000;
  font-size: 16px;
  line-height: 28px;
  font-weight: 500;
}
.main_wrapper .stickySidebar .table_content li a.active {
  color: #60ACAC;
}
.main_wrapper .cs_stickySidebar {
  background-color: #fff;
  padding: 20px 15px;
  border-radius: 8px;
  box-shadow: 2px 2px 4px rgba(128, 128, 128, 0.2509803922);
  width: 200px;
}
@media (max-width: 992px) {
  .main_wrapper .cs_stickySidebar {
    margin: 0 0 20px;
    width: 100%;
  }
}
.main_wrapper .cs_stickySidebar li {
  list-style: none;
}
.main_wrapper .cs_stickySidebar li a {
  color: #000;
  font-size: 16px;
  line-height: 28px;
  font-weight: 500;
}
.main_wrapper .cs_stickySidebar li a.active {
  color: #60ACAC;
}
.main_wrapper .blogdetails_wrapper {
  padding: 0 35px;
  font-size: 18px;
  line-height: 26px;
  text-align: justify;
}
@media (max-width: 992px) {
  .main_wrapper .blogdetails_wrapper {
    padding: 0;
  }
}
.main_wrapper .blogdetails_wrapper a {
  color: #0d9298;
  text-align: justify;
}
.main_wrapper .blogdetails_wrapper h2 {
  font-size: 30px !important;
  line-height: 38px !important;
  padding: 24px 0;
  text-transform: capitalize;
  letter-spacing: -0.7px;
  text-align: left;
}
@media (max-width: 992px) {
  .main_wrapper .blogdetails_wrapper h2 {
    font-size: 22px !important;
    line-height: 32px !important;
    padding: 10px 0 15px;
  }
}
.main_wrapper .blogdetails_wrapper h3 {
  font-size: 26px !important;
  line-height: 34px !important;
  padding: 18px 0;
  text-transform: capitalize;
  letter-spacing: -0.56px;
  text-align: left;
}
@media (max-width: 992px) {
  .main_wrapper .blogdetails_wrapper h3 {
    font-size: 20px !important;
    line-height: 30px !important;
    padding: 10px 0 15px;
  }
}
.main_wrapper .blogdetails_wrapper h4 {
  font-size: 20px !important;
  line-height: 30px !important;
  padding: 18px 0;
  text-transform: capitalize;
  text-align: left;
  letter-spacing: -0.56px;
}
@media (max-width: 992px) {
  .main_wrapper .blogdetails_wrapper h4 {
    font-size: 18px !important;
    line-height: 28px !important;
    padding: 10px 0 15px;
  }
}
.main_wrapper .blogdetails_wrapper p {
  font-size: 18px;
  line-height: 26px;
  padding: 10px 0;
  text-align: justify;
}
@media (max-width: 992px) {
  .main_wrapper .blogdetails_wrapper p {
    padding: 0 0 10px;
  }
}
.main_wrapper .blogdetails_wrapper .quotes {
  background-color: #fff;
  box-shadow: 1px 3px 8px 2px rgba(128, 128, 128, 0.1607843137);
  padding: 20px;
  margin: 10px 0 20px;
  text-align: justify;
}
.main_wrapper .blogdetails_wrapper blockquote {
  background-color: #fff;
  box-shadow: 1px 3px 8px 2px rgba(128, 128, 128, 0.1607843137);
  padding: 20px;
  margin: 10px 0 20px;
  text-align: justify;
}
.main_wrapper .blogdetails_wrapper img {
  margin: 15px 0;
  border-radius: 8px;
  width: 100%;
  box-shadow: 1px 1px 1px 8px rgba(228, 228, 228, 0.6117647059);
}
.main_wrapper .blogdetails_wrapper iframe {
  margin: 25px 0 15px;
  border-radius: 8px;
  display: block;
  width: 100%;
  box-shadow: 1px 1px 1px 8px hsla(0, 0%, 89%, 0.6117647059);
  height: 400px;
}
@media (max-width: 992px) {
  .main_wrapper .blogdetails_wrapper iframe {
    height: 280px;
  }
}
.main_wrapper .blogdetails_wrapper ul {
  padding-left: 20px;
}
.main_wrapper .blogdetails_wrapper ul li {
  font-size: 18px;
  line-height: 26px;
  text-align: justify;
  padding: 8px 0;
}
.main_wrapper .blogdetails_wrapper ul li img {
  margin: 30px 0 20px;
}
.main_wrapper .blogdetails_wrapper ul li ul {
  padding: 5px 0 0 15px;
}
.main_wrapper .blogdetails_wrapper ul li ul li {
  font-size: 18px;
  line-height: 26px;
  list-style-type: disc;
  font-weight: 500;
  text-align: justify;
}
.main_wrapper .blogdetails_wrapper ol {
  padding-left: 20px;
}
.main_wrapper .blogdetails_wrapper ol li {
  font-size: 18px;
  line-height: 26px;
  text-align: justify;
  padding: 8px 0;
}
.main_wrapper .blogdetails_wrapper ol li img {
  margin: 30px 0 20px;
}
.main_wrapper .blogdetails_wrapper ol li ul {
  padding: 5px 0 0 15px;
}
.main_wrapper .blogdetails_wrapper ol li ul li {
  font-size: 18px;
  line-height: 26px;
  list-style-type: disc;
  font-weight: 500;
  text-align: justify;
}
.main_wrapper .blogdetails_wrapper .author_wrapper {
  background-color: #F7F8F8;
  padding: 20px;
  border-radius: 8px;
  margin: 20px 0;
}
@media (max-width: 992px) {
  .main_wrapper .blogdetails_wrapper .author_wrapper {
    margin: 20px 0 40px;
  }
}
.main_wrapper .blogdetails_wrapper .author_wrapper .author-bio img {
  width: 150px;
  height: 100%;
  border-radius: 100%;
}
@media (max-width: 992px) {
  .main_wrapper .blogdetails_wrapper .author_wrapper .author-bio img {
    width: 90px;
  }
}
.main_wrapper .blogdetails_wrapper .author_wrapper .author_content {
  padding: 20px;
}
@media (max-width: 992px) {
  .main_wrapper .blogdetails_wrapper .author_wrapper .author_content {
    padding: 0;
  }
}
.main_wrapper .blogdetails_wrapper .author_wrapper .author_content .user_name {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  padding: 0 0 10px;
}
.main_wrapper .rightSidebar {
  padding: 20px 0 0;
}
.main_wrapper .rightSidebar .social_links {
  padding: 20px 0 0;
}
.main_wrapper .rightSidebar .social_links a img {
  width: 25px;
}
.main_wrapper .rightSidebar .demo_div {
  background-color: #F4F3FF;
  border-radius: 12px;
  padding: 20px 15px;
  margin: 30px 0 0;
}
.main_wrapper .rightSidebar .demo_div .demo_title {
  font-size: 19px;
  line-height: 28px;
  font-weight: 600;
}
@media (max-width: 992px) {
  .main_wrapper .rightSidebar .demo_div .demo_title {
    font-size: 22px;
    line-height: 32px;
  }
}
.main_wrapper .rightSidebar .demo_div .demo_title span {
  color: blue;
}
.main_wrapper .rightSidebar .demo_div p {
  font-size: 14px;
  line-height: 20px;
  padding: 10px 0;
}
.main_wrapper .rightSidebar .demo_div .theme-btn, .main_wrapper .rightSidebar .demo_div .white-btn {
  display: block;
  padding: 14px 18px;
  margin: 0 0 10px;
  font-size: 14px !important;
}
.main_wrapper .cs_rightSidebar {
  padding: 20px 0 0;
}
.main_wrapper .cs_rightSidebar .social_links {
  padding: 20px 0 0;
}
.main_wrapper .cs_rightSidebar .social_links a img {
  width: 25px;
}
.main_wrapper .cs_rightSidebar .demo_div {
  background-color: #F4F3FF;
  border-radius: 12px;
  padding: 20px 15px;
  margin: 30px 0 0;
}
.main_wrapper .cs_rightSidebar .demo_div .demo_title {
  font-size: 19px;
  line-height: 28px;
  font-weight: 600;
}
@media (max-width: 992px) {
  .main_wrapper .cs_rightSidebar .demo_div .demo_title {
    font-size: 22px;
    line-height: 32px;
  }
}
.main_wrapper .cs_rightSidebar .demo_div .demo_title span {
  color: blue;
}
.main_wrapper .cs_rightSidebar .demo_div p {
  font-size: 14px;
  line-height: 20px;
  padding: 10px 0;
}
.main_wrapper .cs_rightSidebar .demo_div .theme-btn, .main_wrapper .cs_rightSidebar .demo_div .white-btn {
  display: block;
  margin: 0 0 10px;
}

#recentBlogs .blogBox .blog-box-wrapper .blog-box {
  padding: 0 15px;
}
@media (max-width: 992px) {
  #recentBlogs .blogBox .blog-box-wrapper .blog-box {
    padding: 15px 0;
  }
}
#recentBlogs .blogBox .blog-box-wrapper .blog-box .blog_Img img {
  height: 213px;
}

#subscribe .subscribe_wrapper {
  padding: 48px 50px;
  height: 390px;
  border-radius: 8px;
}
#subscribe .subscribe_wrapper .sec-padd {
  max-width: 500px;
}
@media (max-width: 992px) {
  #subscribe .subscribe_wrapper {
    padding: 0;
    height: unset;
  }
  #subscribe .subscribe_wrapper .sec-title {
    font-size: 24px;
    line-height: 34px;
  }
  #subscribe .subscribe_wrapper .sec-padd {
    padding: 20px;
  }
}
#subscribe .subscribe_wrapper .subscribe {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 390px;
}
@media (max-width: 992px) {
  #subscribe .subscribe_wrapper .subscribe {
    position: relative;
    height: unset;
  }
}

#lastsection {
  background-color: #0D9298;
  height: 600px;
}
@media (max-width: 992px) {
  #lastsection .sheet {
    width: 98px;
  }
}
#lastsection .tagline_wraper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
@media (max-width: 992px) {
  #lastsection .tagline_wraper {
    position: relative;
    top: unset;
    transform: unset;
    left: unset;
  }
}
#lastsection .tagline_wraper .sec-title {
  font-size: 60px !important;
  line-height: 70px !important;
  letter-spacing: -1.2px;
  padding-bottom: 20px;
}
@media (max-width: 992px) {
  #lastsection .tagline_wraper .sec-title {
    font-size: 24px !important;
    line-height: 34px !important;
  }
}
#lastsection .tagline_wraper .free-trial {
  font-size: 20px;
  margin: 20px 0 50px;
}
@media (max-width: 992px) {
  #lastsection .tagline_wraper .free-trial {
    font-size: 14px;
    line-height: 20px;
    margin: 0px 0 35px;
  }
}
#lastsection .tagline_wraper .theme-btn {
  background-color: #fff;
  color: #0d9298;
}

.pagination-links {
  text-align: center;
  margin: 20px 0;
}
.pagination-links a {
  margin: 0 5px;
  padding: 10px 15px;
  border: 1px solid #ddd;
  text-decoration: none;
  background-color: #f4f4f4;
  color: #0d9298;
  transition: background-color 0.3s ease, color 0.3s ease;
}
.pagination-links a:hover {
  background-color: #0d9298;
  color: #fff;
  border-color: #0d9298;
}
.pagination-links .current {
  font-weight: bold;
  pointer-events: none;
  background-color: #0d9298;
  color: #fff;
  border-color: #0d9298;
}
.pagination-links .page-number {
  cursor: pointer;
}
.pagination-links .prev_pagination, .pagination-links .next_pagination, .pagination-links .last-page, .pagination-links .first-page {
  background-color: rgba(235, 235, 235, 0.6588235294);
  padding: 11px;
  border-radius: 4px;
}

.cs_flex {
  display: flex;
  gap: 40px;
}
@media (max-width: 992px) {
  .cs_flex {
    display: block;
  }
}

.single_cs .cs_left {
  padding-left: 40px;
}
@media (max-width: 992px) {
  .single_cs .cs_left {
    padding-left: 0;
  }
}
.single_cs .cs_left .case_Content {
  font-size: 18px;
  line-height: 26px;
  padding: 10px 0;
  color: #1e1e1e;
  font-family: "Basier Circle";
}
.single_cs .cs_left .case_Content a {
  color: #33c1c0;
  text-decoration: underline;
}
.single_cs .cs_left .case_Content h2 {
  font-size: 30px;
  line-height: 38px;
  padding: 24px 0;
  color: #0e1b1b;
  text-transform: capitalize;
  letter-spacing: -0.7px;
  font-family: "Basier Circle Semibold";
}
@media (max-width: 992px) {
  .single_cs .cs_left .case_Content h2 {
    font-size: 22px;
    line-height: 32px;
    padding: 18px 0;
  }
}
.single_cs .cs_left .case_Content h3 {
  font-size: 26px;
  line-height: 34px;
  margin: 14px 0;
}
@media (max-width: 992px) {
  .single_cs .cs_left .case_Content h3 {
    font-size: 20px;
    line-height: 30px;
  }
}
.single_cs .cs_left .case_Content p {
  font-size: 18px;
  line-height: 26px;
  padding: 10px 0;
  color: #1e1e1e;
  font-family: "Basier Circle";
}
.single_cs .cs_left .case_Content p a {
  color: #33c1c0;
  text-decoration: underline;
}
.single_cs .cs_left .case_Content iframe {
  margin: 25px 0 15px;
  border-radius: 8px;
  display: block;
  width: 100%;
  box-shadow: 1px 1px 1px 8px hsla(0, 0%, 89%, 0.6117647059);
  height: 400px;
}
@media (max-width: 992px) {
  .single_cs .cs_left .case_Content iframe {
    height: 280px;
  }
}
.single_cs .cs_left .case_Content ul {
  padding-left: 20px;
}
.single_cs .cs_left .case_Content ul li {
  font-size: 18px;
  line-height: 26px;
  text-align: justify;
  padding: 8px 0;
}
.single_cs .cs_left .case_Content ul li img {
  margin: 30px 0 20px;
}
.single_cs .cs_left .case_Content ul li ul {
  padding: 5px 0 0 15px;
}
.single_cs .cs_left .case_Content ul li ul li {
  font-size: 18px;
  line-height: 26px;
  list-style-type: disc;
  font-weight: 500;
  text-align: justify;
}
.single_cs .cs_left .case_Content ol {
  padding-left: 20px;
}
.single_cs .cs_left .case_Content ol li {
  font-size: 18px;
  line-height: 26px;
  text-align: justify;
  padding: 8px 0;
}
.single_cs .cs_left .case_Content ol li img {
  margin: 30px 0 20px;
}
.single_cs .cs_left .case_Content ol li ul {
  padding: 5px 0 0 15px;
}
.single_cs .cs_left .case_Content ol li ul li {
  font-size: 18px;
  line-height: 26px;
  list-style-type: disc;
  font-weight: 500;
  text-align: justify;
}
.single_cs .cs_left .case_Content img {
  margin: 25px 0 15px;
  border-radius: 8px;
  width: 100%;
  box-shadow: 1px 1px 1px 8px hsla(0, 0%, 89%, 0.61176);
}
.single_cs .cs_left .case_Content blockquote {
  background: #f9f9f9;
  border-left: 10px solid #ccc;
  margin: 1.5em 0;
  padding: 15px;
  quotes: "“" "”" "‘" "’";
}
.single_cs .cs_left .arrow-left {
  margin: 0;
  border-radius: 0;
  vertical-align: bottom;
}
.single_cs .caseTitle {
  margin: 8px 0;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  letter-spacing: -0.46px;
  margin-bottom: 26px;
  color: #0e1b1b;
  font-family: "Basier Circle Semibold";
}
@media (max-width: 992px) {
  .single_cs .caseTitle {
    font-size: 24px;
    line-height: 34px;
  }
}
.single_cs .cs_category, .single_cs .cs_author {
  font-size: 12px;
  color: #038c8b;
  line-height: 18px;
  margin-bottom: 20px;
}
.single_cs iframe {
  height: 400px;
  border-radius: 12px;
  margin: 15px 0;
}
@media (max-width: 992px) {
  .single_cs iframe {
    height: 190px;
  }
}

.terms p {
  text-align: justify;
}
.terms ol {
  padding: 10px;
}
.terms ol li {
  color: #374040;
  font-size: 16px;
  line-height: 24px;
  padding: 8px 0;
  text-align: justify;
}
.terms ol li ol {
  padding: 8px 16px;
}
.terms ol p {
  padding: 10px 0;
  text-align: justify;
}

.contact_wrapper {
  padding: 20px 0 0;
}
.contact_wrapper .info_title {
  font-size: 24px;
  line-height: 31px;
  color: #354545;
  margin: 20px 0;
}
.contact_wrapper .info_div {
  margin: 20px 0;
  padding: 20px 0;
}
.contact_wrapper .info_div::before {
  position: absolute;
  content: "";
  background-color: #dee2e6;
  width: 60%;
  left: 0;
  top: 0;
  height: 1px;
}
@media (max-width: 992px) {
  .contact_wrapper .info_div::before {
    width: 100%;
  }
}
.contact_wrapper .info_div::after {
  position: absolute;
  content: "";
  background-color: #dee2e6;
  width: 60%;
  left: 0;
  bottom: 0;
  height: 1px;
}
@media (max-width: 992px) {
  .contact_wrapper .info_div::after {
    width: 100%;
  }
}
.contact_wrapper .info_div h3 {
  font-size: 18px;
  line-height: 23px;
  color: #354545;
}
.contact_wrapper .info_div p {
  font-size: 14px;
  line-height: 20px;
  color: #354545;
  padding: 10px 0 0;
}
.contact_wrapper .info_div p a {
  font-size: 14px;
  line-height: 20px;
  color: #354545;
}
.contact_wrapper .contactus .form-control {
  background-color: #f5f8fa;
  border: 1px solid #cbd6e2;
  border-radius: 3px;
}
.contact_wrapper .contactus .fname {
  padding-right: 20px;
}
@media (max-width: 992px) {
  .contact_wrapper .contactus .fname {
    padding-right: 0;
  }
}

.display_table {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}
@media (max-width: 992px) {
  .display_table {
    display: block;
  }
}

@media (max-width: 992px) {
  .products .btn_wrapper a {
    display: block;
  }
}
.products .banner {
  height: 100vh;
  padding: 0 175px;
}
@media (max-width: 992px) {
  .products .banner {
    height: unset;
    padding: 90px 0 0;
  }
}
.products .common_subtitle {
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 17px;
  font-family: "Basier Circle";
}
.products .common_title {
  font-size: 36px;
  line-height: 43px;
}
@media (max-width: 992px) {
  .products .common_title {
    font-size: 24px;
    line-height: 34px;
  }
}
.products .common_desc {
  font-size: 16px;
  line-height: 24px;
  margin: 25px 0 60px;
  font-weight: 400;
  font-family: "Basier Circle";
}
@media (max-width: 992px) {
  .products .common_desc {
    margin: 25px 0;
  }
}
.products .feed_div {
  height: 350px;
}
@media (max-width: 992px) {
  .products .feed_div {
    height: unset;
    padding: 20px 0;
  }
}
.products .feed_div img {
  height: 180px;
}
.products .feed_div .feedback_title {
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  padding: 8px 0 0;
}
.products .feed_div1 {
  height: 420px;
}
@media (max-width: 992px) {
  .products .feed_div1 {
    height: unset;
    padding: 20px 0;
  }
}
.products .feedback-card-root .feedback-card {
  width: 100%;
  max-width: 410px;
  padding: 15px 24px;
  position: relative;
  border-radius: 12px;
  margin: 10px auto 20px;
  background: #dce8ec;
}
.products .feedback-card-root .feedback-card .feedback-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.products .feedback-card-root .feedback-card .feedback-head .card-logo-div {
  width: 104px;
  height: 104px;
  border-radius: 50%;
}
.products .feedback-card-root .feedback-card .feedback-head .card-logo-div .card-logo-image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: contain;
  background: #fff;
}
.products .feedback-card-root .feedback-card .feedback-head h4 {
  font-size: 14px;
  color: #354545;
  line-height: 18px;
  max-width: 208px;
  padding-left: 10px;
  font-family: "Basier Circle";
}
.products .feedback-card-root .feedback-card .arrow-div .feedback-test-text {
  margin: 0;
  top: 230px;
  right: -210px;
  position: absolute;
  font-size: 14px;
  line-height: 18px;
  color: #354545;
  text-align: center;
  max-width: 186px;
  font-family: "Basier Circle";
}
.products .feedback-card-root .feedback-card .arrow-div .feedback-test-img {
  top: 260px;
  right: -140px;
  z-index: 1;
  position: absolute;
}
@media (max-width: 992px) {
  .products .feedback-card-root .feedback-card .arrow-div .feedback-test-img {
    right: -65px;
  }
}
.products .feedback-card-root .feedback-card .feedback-details {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: justify;
  justify-content: space-between;
  margin: 17px 0 18px;
}
.products .feedback-card-root .feedback-card .feedback-details h5 {
  margin: 0;
  color: #354545;
  font-size: 10px;
  line-height: 13px;
}
.products .feedback-card-root .feedback-card .feedback-details h5 span {
  font-family: "Basier Circle";
}
.products .feedback-card-root .feedback-card .bonus-card {
  padding: 5px 8px;
  margin-bottom: 16px;
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: center;
  justify-content: center;
  border-radius: 12px;
  background: #fff;
}
.products .feedback-card-root .feedback-card .bonus-card h3 {
  font-size: 12px;
  color: #354545;
  margin: 0 0 0 5px;
  line-height: 16px;
}
.products .feedback-card-root .feedback-card .rating-card {
  min-width: 430px;
  padding: 18px 24px;
  border-radius: 12px;
  background: #fff;
  -webkit-transform: translateX(-35px);
  -moz-transform: translateX(-35px);
  transform: translateX(-35px);
  -webkit-box-shadow: 2px 3px 10px #c5d1d5;
  box-shadow: 2px 3px 10px #c5d1d5;
}
@media (max-width: 992px) {
  .products .feedback-card-root .feedback-card .rating-card {
    min-width: 100%;
  }
}
.products .feedback-card-root .feedback-card .rating-card h4 {
  font-size: 14px;
  color: #354545;
  line-height: 18px;
  margin-bottom: 25px;
  font-family: "Basier Circle";
}
.products .feedback-card-root .feedback-card .rating-card .rating-row {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: justify;
  justify-content: space-between;
}
.products .feedback-card-root .feedback-card .rating-card .rating-row .emojis {
  cursor: pointer;
  -webkit-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.18, 1.3) 0s;
  -moz-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.18, 1.3) 0s;
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.18, 1.3) 0s;
}
.products .feedback-card-root .feedback-card .rating-card .rating-row .emojis:hover {
  transform: scale(1.4);
}

.campaign_padd {
  gap: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.campaign_padd .campaign_content {
  padding: 90px 0 0 90px;
}
@media (max-width: 992px) {
  .campaign_padd .campaign_content {
    padding: 20px 0;
  }
}
.campaign_padd .tr {
  text-align: right;
}
@media (max-width: 992px) {
  .campaign_padd .tr {
    text-align: center;
  }
}
.campaign_padd .campaign_div {
  width: 250px;
  padding: 35px 24px;
  border-radius: 10px;
}
@media (max-width: 992px) {
  .campaign_padd .campaign_div {
    width: 100%;
  }
}
.campaign_padd .campaign_div .campaign_title {
  font-size: 18px;
  line-height: 28px;
  margin: 20px 0 0;
  font-family: "Basier Circle";
  font-weight: 500;
}
.campaign_padd .campaign1 {
  background-color: #E6F5F0;
}
.campaign_padd .campaign2 {
  background-color: #FFF9D9;
}
.campaign_padd .campaign3 {
  background-color: #EAF0FF;
}
.campaign_padd .campaign4 {
  background-color: #EBF9FF;
}
@media (max-width: 992px) {
  .campaign_padd {
    padding: 0;
    text-align: center;
  }
}

.more-reelo {
  background: linear-gradient(180deg, #f8fdfd, rgba(248, 249, 255, 0));
}
.more-reelo .morereelo_div {
  padding: 40px 20px 0;
  text-align: center;
}
@media (max-width: 992px) {
  .more-reelo .morereelo_div {
    padding: 30px 0 0;
  }
}
.more-reelo .morereelo_div h3 {
  font-size: 32px;
  color: #092d2c;
  line-height: 42px;
  margin: 24px 0 8px;
  letter-spacing: -0.02em;
  font-family: "Basier Circle Semibold";
}
@media (max-width: 992px) {
  .more-reelo .morereelo_div h3 {
    font-size: 24px;
    line-height: 34px;
  }
}
.more-reelo .morereelo_div p {
  font-size: 17px;
  color: #404444;
  margin: 0 0 16px;
  line-height: 26px;
  font-family: "Basier Circle";
  height: 105px;
}
@media (max-width: 992px) {
  .more-reelo .morereelo_div p {
    height: 110px;
  }
}
.more-reelo .morereelo_div .read_now {
  font-size: 20px;
  color: #0d9298;
  line-height: 28px;
  font-family: "Basier Circle medium";
  font-weight: 400;
}

.slick-dots {
  display: flex;
  margin: 0;
  list-style-type: none;
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
}
.slick-dots button {
  display: block;
  width: 6px;
  height: 6px;
  padding: 0;
  background-color: grey;
  text-indent: -9999px;
  cursor: pointer;
  border-radius: 30px;
  border: unset;
  margin: 0 3px;
}

li.slick-active button {
  background-color: #000;
}

.loyalty .loyalty_first_fold {
  gap: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 45px 0 0;
}
@media (max-width: 992px) {
  .loyalty .loyalty_first_fold {
    flex-wrap: wrap;
  }
}
.loyalty .loyalty_first_fold .loyalty_left_side {
  max-width: 620px;
}
.loyalty .loyalty_first_fold .loyalty_right_side {
  width: 100%;
  max-width: 571px;
}
.loyalty .display_table {
  flex-direction: unset;
}
.loyalty .banner {
  height: 100vh;
}
@media (max-width: 992px) {
  .loyalty .banner {
    height: unset;
    padding: 90px 0 0;
    text-align: center;
  }
  .loyalty .banner .common_title {
    font-size: 28px;
    line-height: 32px;
  }
  .loyalty .banner .common_desc {
    font-size: 14px;
    line-height: 22px;
  }
}
.loyalty .sec_title {
  font-size: 42px;
  margin: 0 0 16px;
  line-height: 51px;
  letter-spacing: -0.02em;
  font-weight: 500;
  font-family: "Basier Circle medium";
}
@media (max-width: 992px) {
  .loyalty .sec_title {
    font-size: 28px;
    line-height: 32px;
  }
}
.loyalty .sec_desc {
  font-size: 20px;
  margin: 0 0 24px;
  line-height: 30px;
  font-family: "Basier Circle";
}
.loyalty .common_title {
  font-size: 56px;
  margin: 0 0 16px;
  line-height: 62px;
  letter-spacing: -0.01em;
  font-family: "Basier Circle medium";
}
.loyalty .common_desc {
  font-size: 20px;
  margin: 0 0 40px;
  line-height: 30px;
  font-family: "Basier Circle";
}
.loyalty .loyaly_div {
  overflow: hidden;
  border-radius: 24px;
  background: #fef6ee;
  padding: 40px 0 0;
}
@media (max-width: 992px) {
  .loyalty .loyaly_div {
    padding: 20px 0 0;
  }
}
.loyalty .loyaly_div .loyalty_img_parent {
  width: 100%;
  height: 100%;
  position: relative;
}
.loyalty .loyaly_div .loyalty_img_parent .loyaly_slider {
  bottom: 0;
  top: auto;
  width: 100%;
  height: 523px;
}
@media (max-width: 992px) {
  .loyalty .loyaly_div .loyalty_img_parent .loyaly_slider {
    height: 332px;
  }
}
.loyalty .loyaly_div .loyalty_img_parent .loyaly_slider .slider_loyaltyimg {
  top: auto;
  bottom: 0;
  margin: 0 auto !important;
  display: block !important;
  width: calc(100% - 96px) !important;
}
@media (max-width: 992px) {
  .loyalty .loyaly_div .loyalty_img_parent .loyaly_slider .slider_loyaltyimg {
    width: calc(100% - 34px) !important;
  }
}
@media (max-width: 992px) {
  .loyalty .loyaly_div {
    width: 100%;
    margin: 0;
  }
}
.loyalty .loyalty_tabs_wrapper {
  border-bottom: 1px solid rgba(238, 238, 238, 0.9333333333);
  padding: 60px 80px 0;
}
.loyalty .loyalty_tabs_wrapper .loyalty_tab {
  display: inline-block;
  width: 240px;
  height: 140px;
  cursor: pointer;
}
.loyalty .loyalty_tabs_wrapper .loyalty_tab:before {
  position: absolute;
  content: "";
  width: 95%;
  height: 4px;
  bottom: 0;
  background-color: #eee;
  transform: translateX(-50%);
  left: 50%;
}
.loyalty .loyalty_tabs_wrapper .loyalty_tab span {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: #f3f4f6;
  font-size: 24px;
  font-family: "Basier Circle Semibold";
  padding: 10px 19px;
}
.loyalty .loyalty_tabs_wrapper .loyalty_tab p {
  font-size: 18px;
  color: #959595;
  line-height: 26px;
  text-align: center;
  font-family: "Basier Circle medium";
  padding: 30px 0 0;
}
.loyalty .loyalty_tabs_wrapper .loyalty_tab.active:before {
  background-color: #6172f3;
}
.loyalty .loyalty_tabs_wrapper .loyalty_tab.active p {
  color: #182020;
}
.loyalty .loyalty_slider2 .slick-dots {
  display: flex;
  margin: 0;
  list-style-type: none;
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
}
.loyalty .loyalty_slider2 .slick-dots button {
  display: block;
  width: 6px;
  height: 6px;
  padding: 0;
  background-color: grey;
  text-indent: -9999px;
  cursor: pointer;
  border-radius: 30px;
  border: unset;
  margin: 0 3px;
}
.loyalty .loyalty_slider2 li.slick-active button {
  background-color: #000;
}
.loyalty .loyalty_slider2 .loyalty_Slide {
  padding: 64px 0 64px 64px;
  background: #f5f8ff;
  margin: 40px auto 0;
  border-radius: 24px;
}
@media (max-width: 992px) {
  .loyalty .loyalty_slider2 .loyalty_Slide {
    padding: 20px;
    height: unset;
    margin: 0 0 50px;
  }
}
@media (max-width: 992px) {
  .loyalty .loyalty_slider2 .loyalty_Slide .loyalty_img img {
    width: 200px;
    margin: 0 auto;
  }
}
.loyalty .loyalty_slider2 .loyalty_Slide .loyalty_content {
  padding: 0 0 30px;
}
.loyalty .loyalty_slider2 .loyalty_Slide .loyalty_content span {
  width: 64px;
  height: 64px;
  font-size: 34px;
  color: #fff;
  line-height: 42px;
  border-radius: 50%;
  display: -moz-box;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #6172f3;
  font-family: "Basier Circle Semibold";
}
@media (max-width: 992px) {
  .loyalty .loyalty_slider2 .loyalty_Slide .loyalty_content span {
    width: 34px;
    height: 34px;
    font-size: 20px;
    line-height: 28px;
  }
}
.loyalty .loyalty_slider2 .loyalty_Slide .loyalty_content h3 {
  font-size: 36px;
  color: #171e59;
  line-height: 50px;
  margin: 32px 0 12px;
  letter-spacing: -0.02em;
  font-family: "Basier Circle Semibold";
}
@media (max-width: 992px) {
  .loyalty .loyalty_slider2 .loyalty_Slide .loyalty_content h3 {
    font-size: 28px;
    line-height: 32px;
  }
}
.loyalty .loyalty_slider2 .loyalty_Slide .loyalty_content p {
  font-size: 28px;
  color: #171e59;
  line-height: 40px;
  letter-spacing: -0.02em;
  font-family: "Basier Circle medium";
}
@media (max-width: 992px) {
  .loyalty .loyalty_slider2 .loyalty_Slide .loyalty_content p {
    font-size: 16px;
    line-height: 26px;
  }
}
.loyalty .benifits_section .bene_div {
  padding: 32px 30px;
  border-radius: 16px;
  width: 31%;
  margin: 40px 14px 0;
}
@media (max-width: 992px) {
  .loyalty .benifits_section .bene_div {
    width: 100%;
    margin: 30px 0 0;
  }
}
.loyalty .benifits_section .bene_div .bene_inner {
  padding: 36px 22px;
  background: #fff;
  border-radius: 16.7273px;
}
.loyalty .benifits_section .bene_div .bene_inner p {
  font-size: 20px;
  color: #171e59;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.02em;
  font-family: "Basier Circle medium";
}
.loyalty .benifits_section .bene_div .bene_inner span {
  font-size: 48px;
  line-height: 62px;
  text-align: center;
  letter-spacing: -0.02em;
  font-family: "Basier Circle Semibold";
  display: block;
}
.loyalty .benifits_section .bene_div .bene_inner .green {
  color: #16b364;
}
.loyalty .benifits_section .bene_div .bene_inner .red {
  color: #ef6820;
}
.loyalty .benifits_section .bene_div .bene_inner .blue {
  color: #1570ef;
}
.loyalty .benifits_section .b1 {
  background: #edfcf2;
}
.loyalty .benifits_section .b2 {
  background: #fef6ee;
}
.loyalty .benifits_section .b3 {
  background: #f5f8ff;
}
.loyalty .inspiration {
  border-radius: 16px;
  padding: 48px 40px 0;
  background: #fef6ee;
}
@media (max-width: 992px) {
  .loyalty .inspiration {
    padding: 40px 20px 0;
  }
}
.loyalty .quotes_section p {
  font-size: 24px;
  color: #092d2c;
  line-height: 32px;
  text-align: center;
  font-family: "Basier Circle";
}
@media (max-width: 992px) {
  .loyalty .quotes_section p {
    font-size: 18px;
    line-height: 26px;
  }
}
.loyalty .quotes_section .quote_flex {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.loyalty .quotes_section .quote_flex img {
  border-right: 1px solid #000;
  padding-right: 10px;
}
.loyalty .quotes_section .quote_flex p {
  font-size: 18px;
  color: #092d2c;
  line-height: 28px;
  text-align: left;
}
.loyalty .lastsection {
  border-radius: 24px;
  padding: 40px 20px;
}
@media (max-width: 992px) {
  .loyalty .lastsection {
    margin: 0 20px;
  }
}

.marrows {
  margin: 30px 0 35px;
}
.marrows svg {
  width: 48px;
  height: 48px;
  background-color: #f3f5f5;
  border-radius: 100%;
  padding: 10px;
  margin: 0 10px;
}
.marrows svg:hover {
  background-color: #0d9298;
}
.marrows svg:hover path {
  stroke: #fff;
}
.marrows #snext, .marrows #lnext {
  position: relative;
  transform: rotate(180deg);
}

.campaigns .display_table {
  flex-direction: unset;
}
.campaigns .banner {
  height: 100vh;
}
@media (max-width: 992px) {
  .campaigns .banner {
    height: unset;
    padding: 90px 0 0;
    text-align: center;
  }
  .campaigns .banner .common_title {
    font-size: 28px;
    line-height: 32px;
  }
  .campaigns .banner .common_desc {
    font-size: 14px;
    line-height: 22px;
  }
}
.campaigns .banner .common_subtitle {
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.18em;
  text-transform: uppercase;
  font-family: "Basier Circle Semibold";
  padding: 0 0 20px;
}
.campaigns .sec_title {
  font-size: 42px;
  margin: 0 0 16px;
  line-height: 51px;
  letter-spacing: -0.02em;
  font-weight: 500;
  font-family: "Basier Circle medium";
}
@media (max-width: 992px) {
  .campaigns .sec_title {
    font-size: 28px;
    line-height: 32px;
  }
}
.campaigns .sec_desc {
  font-size: 20px;
  margin: 0 0 24px;
  line-height: 30px;
  font-family: "Basier Circle";
}
.campaigns .common_title {
  font-size: 56px;
  margin: 0 0 16px;
  line-height: 62px;
  font-family: "Basier Circle medium";
}
.campaigns .common_desc {
  font-size: 20px;
  margin: 0 0 40px;
  line-height: 30px;
  font-family: "Basier Circle";
}
.campaigns .loyaly_div {
  width: 557px;
  margin: 70px auto 0;
}
@media (max-width: 992px) {
  .campaigns .loyaly_div {
    width: 100%;
    margin: 0 0 40px;
  }
}
.campaigns .loyaly_div .loyaly_slider {
  border-radius: 24px;
}
@media (max-width: 992px) {
  .campaigns .pre-built .common_title {
    font-size: 28px;
    line-height: 32px;
  }
}
@media (max-width: 992px) {
  .campaigns .pre-built .common_desc {
    font-size: 18px;
  }
}
.campaigns .pre-built .campaigns_feature_card_root {
  gap: 24px;
  margin: 0 auto;
  padding: 0 16px;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 992px) {
  .campaigns .pre-built .campaigns_feature_card_root {
    display: block;
    border-radius: 16px;
    padding: 0;
  }
}
.campaigns .pre-built .campaigns_feature_card_root .flx {
  align-items: center !important;
}
.campaigns .pre-built .campaigns_feature_card_root .campaigns_feature_card {
  height: 395px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0 24px 0 0;
  border-radius: 16px;
  background: #f3f1ee;
  width: calc(50% - 12px);
}
@media (max-width: 992px) {
  .campaigns .pre-built .campaigns_feature_card_root .campaigns_feature_card {
    display: block;
    padding: 0;
    height: unset;
    width: 100%;
    margin: 0 0 30px;
  }
}
.campaigns .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_left_side {
  padding: 174px 8px 48px 28px;
}
@media (max-width: 992px) {
  .campaigns .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_left_side {
    padding: 34px 8px 34px 28px;
  }
}
.campaigns .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_left_side .campaigns_feature_number {
  padding: 4px 9px;
  width: 28px;
  height: 28px;
  font-size: 16px;
  color: #fff;
  line-height: 22px;
  border-radius: 8px;
  background: #065151;
  font-family: "Basier Circle Semibold";
}
.campaigns .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_left_side h3 {
  margin: 8px 0;
  font-size: 25px;
  color: #0e1b1b;
  line-height: 26px;
  letter-spacing: -0.02em;
  font-family: "Basier Circle Semibold";
}
@media (max-width: 992px) {
  .campaigns .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_left_side h3 {
    font-size: 20px;
    line-height: 24px;
  }
}
.campaigns .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_left_side p {
  font-size: 14px;
  color: #232d2d;
  line-height: 20px;
}
.campaigns .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_right_side {
  width: 100%;
  max-width: 312px;
  position: relative;
  height: -moz-calc(100% - 48px);
}
@media (max-width: 992px) {
  .campaigns .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_right_side {
    max-width: 250px;
    margin: 0 auto;
  }
}
.campaigns .pre-built .campaigns_feature_card_root .last_card {
  width: 100%;
  height: auto;
}
.campaigns .how_to_send_cards .common_subtitle {
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.18em;
  text-transform: uppercase;
  font-family: "Basier Circle Semibold";
  padding: 0 0 20px;
}
.campaigns .how_to_send_cards .campaigns_how_to_send_cards_parent .campaigns_how_to_send_card {
  gap: 24px;
  height: 402px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 16px;
  background: #f5f8ff;
}
@media (max-width: 992px) {
  .campaigns .how_to_send_cards .campaigns_how_to_send_cards_parent .campaigns_how_to_send_card {
    height: unset;
    display: block;
  }
}
.campaigns .how_to_send_cards .campaigns_how_to_send_cards_parent .campaigns_how_to_send_card .campaigns_left_side {
  flex-grow: 1;
  padding: 48px 64px;
}
@media (max-width: 992px) {
  .campaigns .how_to_send_cards .campaigns_how_to_send_cards_parent .campaigns_how_to_send_card .campaigns_left_side {
    padding: 20px;
  }
}
.campaigns .how_to_send_cards .campaigns_how_to_send_cards_parent .campaigns_how_to_send_card .campaigns_left_side .campaigns_card_number {
  width: 64px;
  height: 64px;
  font-size: 34px;
  color: #fff;
  line-height: 42px;
  border-radius: 50%;
  display: -moz-box;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #6172f3;
  font-family: "Basier Circle Semibold";
}
@media (max-width: 992px) {
  .campaigns .how_to_send_cards .campaigns_how_to_send_cards_parent .campaigns_how_to_send_card .campaigns_left_side .campaigns_card_number {
    width: 34px;
    height: 34px;
    font-size: 20px;
    line-height: 28px;
  }
}
.campaigns .how_to_send_cards .campaigns_how_to_send_cards_parent .campaigns_how_to_send_card .campaigns_left_side h3 {
  font-size: 36px;
  color: #171e59;
  line-height: 50px;
  margin: 32px 0 14px;
  letter-spacing: -0.02em;
  font-family: "Basier Circle Semibold";
}
@media (max-width: 992px) {
  .campaigns .how_to_send_cards .campaigns_how_to_send_cards_parent .campaigns_how_to_send_card .campaigns_left_side h3 {
    font-size: 28px;
    line-height: 32px;
  }
}
.campaigns .how_to_send_cards .campaigns_how_to_send_cards_parent .campaigns_how_to_send_card .campaigns_left_side p {
  font-size: 18px;
  color: #101e6f;
  line-height: 28px;
}
@media (max-width: 992px) {
  .campaigns .how_to_send_cards .campaigns_how_to_send_cards_parent .campaigns_how_to_send_card .campaigns_left_side p {
    font-size: 16px;
    line-height: 26px;
  }
}
.campaigns .how_to_send_cards .campaigns_how_to_send_cards_parent .campaigns_how_to_send_card .campaigns_right_side img {
  padding: 20px 64px 20px 0;
}
@media (max-width: 992px) {
  .campaigns .how_to_send_cards .campaigns_how_to_send_cards_parent .campaigns_how_to_send_card .campaigns_right_side img {
    padding: 20px 20px 0;
  }
}
.campaigns .benifits_section .bene_div {
  padding: 32px 30px;
  border-radius: 16px;
  width: 31%;
  margin: 40px 14px 0;
  height: 450px;
}
@media (max-width: 992px) {
  .campaigns .benifits_section .bene_div {
    width: 100%;
    margin: 30px 0 0;
  }
}
.campaigns .benifits_section .bene_div p {
  font-size: 18px;
  color: #0e1b1b;
  line-height: 28px;
  letter-spacing: -0.02em;
  font-family: "Basier Circle";
}
.campaigns .benifits_section .bene_div .bene_inner {
  padding: 36px 22px;
  background: #fff;
  border-radius: 16.7273px;
  height: 190px;
  margin: 0 0 30px;
}
.campaigns .benifits_section .bene_div .bene_inner p {
  font-size: 20px;
  color: #171e59;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.02em;
  font-family: "Basier Circle medium";
}
.campaigns .benifits_section .bene_div .bene_inner span {
  font-size: 48px;
  line-height: 62px;
  text-align: center;
  letter-spacing: -0.02em;
  font-family: "Basier Circle Semibold";
  display: block;
}
.campaigns .benifits_section .bene_div .bene_inner .green {
  color: #16b364;
}
.campaigns .benifits_section .bene_div .bene_inner .red {
  color: #ef6820;
}
.campaigns .benifits_section .bene_div .bene_inner .blue {
  color: #1570ef;
}
.campaigns .benifits_section .b1 {
  background: #edfcf2;
}
.campaigns .benifits_section .b2 {
  background: #fef6ee;
}
.campaigns .benifits_section .b3 {
  background: #f5f8ff;
}
.campaigns .quotes_section .navneet {
  font-size: 24px;
  color: #092d2c;
  line-height: 32px;
  font-family: "Basier Circle medium";
  padding: 0 0 20px;
}
@media (max-width: 992px) {
  .campaigns .quotes_section .navneet {
    font-size: 18px;
    line-height: 26px;
    padding: 20px 0 20px;
  }
}
.campaigns .quotes_section p {
  font-size: 16px;
  color: #374040;
  line-height: 24px;
  letter-spacing: -0.03em;
  font-family: "Basier Circle medium";
}
.campaigns .quotes_section .quotes-box .quotes_Img .video-thumbnail img {
  border-radius: 8px;
}
.campaigns .quotes_section .quotes-box .quotes_Img .video-thumbnail .play-icon-wrapper {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  cursor: pointer;
}
.campaigns .quotes_section .quotes-box iframe {
  height: 295px;
  border: none;
  border-radius: 8px;
}
@media (max-width: 992px) {
  .campaigns .quotes_section .quotes-box iframe {
    height: 265px;
  }
}
.campaigns .quotes_section .quote_right {
  padding: 0 0 0 40px;
}
@media (max-width: 992px) {
  .campaigns .quotes_section .quote_right {
    padding: 0;
  }
}
.campaigns .feedback-card-root .feedback-card {
  width: 100%;
  max-width: 410px;
  padding: 15px 24px;
  position: relative;
  border-radius: 12px;
  margin: 10px auto 20px;
  background: #dce8ec;
}
.campaigns .feedback-card-root .feedback-card .feedback-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.campaigns .feedback-card-root .feedback-card .feedback-head .card-logo-div {
  width: 104px;
  height: 104px;
  border-radius: 50%;
}
.campaigns .feedback-card-root .feedback-card .feedback-head .card-logo-div .card-logo-image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: contain;
  background: #fff;
}
.campaigns .feedback-card-root .feedback-card .feedback-head h4 {
  font-size: 14px;
  color: #354545;
  line-height: 18px;
  max-width: 208px;
  padding-left: 10px;
  font-family: "Basier Circle";
}
.campaigns .feedback-card-root .feedback-card .arrow-div .feedback-test-text {
  margin: 0;
  top: 230px;
  right: -210px;
  position: absolute;
  font-size: 14px;
  line-height: 18px;
  color: #354545;
  text-align: center;
  max-width: 186px;
  font-family: "Basier Circle";
}
.campaigns .feedback-card-root .feedback-card .arrow-div .feedback-test-img {
  top: 260px;
  right: -140px;
  z-index: 1;
  position: absolute;
}
@media (max-width: 992px) {
  .campaigns .feedback-card-root .feedback-card .arrow-div .feedback-test-img {
    right: -65px;
  }
}
.campaigns .feedback-card-root .feedback-card .feedback-details {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: justify;
  justify-content: space-between;
  margin: 17px 0 18px;
}
.campaigns .feedback-card-root .feedback-card .feedback-details h5 {
  margin: 0;
  color: #354545;
  font-size: 10px;
  line-height: 13px;
}
.campaigns .feedback-card-root .feedback-card .feedback-details h5 span {
  font-family: "Basier Circle";
}
.campaigns .feedback-card-root .feedback-card .bonus-card {
  padding: 5px 8px;
  margin-bottom: 16px;
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: center;
  justify-content: center;
  border-radius: 12px;
  background: #fff;
}
.campaigns .feedback-card-root .feedback-card .bonus-card h3 {
  font-size: 12px;
  color: #354545;
  margin: 0 0 0 5px;
  line-height: 16px;
}
.campaigns .feedback-card-root .feedback-card .rating-card {
  min-width: 430px;
  padding: 18px 24px;
  border-radius: 12px;
  background: #fff;
  -webkit-transform: translateX(-35px);
  -moz-transform: translateX(-35px);
  transform: translateX(-35px);
  -webkit-box-shadow: 2px 3px 10px #c5d1d5;
  box-shadow: 2px 3px 10px #c5d1d5;
}
@media (max-width: 992px) {
  .campaigns .feedback-card-root .feedback-card .rating-card {
    min-width: 100%;
  }
}
.campaigns .feedback-card-root .feedback-card .rating-card h4 {
  font-size: 14px;
  color: #354545;
  line-height: 18px;
  margin-bottom: 25px;
  font-family: "Basier Circle";
}
.campaigns .feedback-card-root .feedback-card .rating-card .rating-row {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: justify;
  justify-content: space-between;
}
.campaigns .feedback-card-root .feedback-card .rating-card .rating-row .emojis {
  cursor: pointer;
  -webkit-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.18, 1.3) 0s;
  -moz-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.18, 1.3) 0s;
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.18, 1.3) 0s;
}
.campaigns .feedback-card-root .feedback-card .rating-card .rating-row .emojis:hover {
  transform: scale(1.4);
}
.campaigns .lastsection {
  border-radius: 24px;
  padding: 40px 20px;
}
@media (max-width: 992px) {
  .campaigns .lastsection {
    margin: 0 20px;
  }
}
@media (max-width: 992px) {
  .campaigns #lastsection {
    height: 350px;
  }
}

.online_reviews .loyalty_tabs_wrapper {
  padding: 0 80px;
}
.online_reviews .loyalty_tabs_wrapper .loyalty_tab {
  display: inline-block;
  padding: 50px 10px;
  cursor: pointer;
}
.online_reviews .loyalty_tabs_wrapper .loyalty_tab p {
  font-size: 18px;
  cursor: pointer;
  color: #374040;
  line-height: 28px;
  padding: 16px 24px;
  border-radius: 48px;
  border: 1px solid #bdc9c9;
  font-family: "Basier Circle medium";
}
.online_reviews .loyalty_tabs_wrapper .loyalty_tab.active p {
  color: #fff;
  background: #0d9298;
  border: 1px solid #0d9298;
}
.online_reviews .loyalty_slider2 .slick-dots {
  display: flex;
  margin: 0;
  list-style-type: none;
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
}
.online_reviews .loyalty_slider2 .slick-dots button {
  display: block;
  width: 6px;
  height: 6px;
  padding: 0;
  background-color: grey;
  text-indent: -9999px;
  cursor: pointer;
  border-radius: 30px;
  border: unset;
  margin: 0 3px;
}
.online_reviews .loyalty_slider2 li.slick-active button {
  background-color: #000;
}
.online_reviews .loyalty_slider2 .loyalty_Slide {
  width: 100%;
  height: 450px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 64px;
  border-radius: 16px;
  background: #f5f8ff;
}
@media (max-width: 992px) {
  .online_reviews .loyalty_slider2 .loyalty_Slide {
    padding: 20px;
    height: 600px;
    margin: 0 0 50px;
    display: block;
  }
  .online_reviews .loyalty_slider2 .loyalty_Slide img {
    padding: 20px 0 0;
  }
}
.online_reviews .loyalty_slider2 .loyalty_Slide h3 {
  font-size: 36px;
  color: #171e59;
  line-height: 50px;
  letter-spacing: -0.02em;
  font-family: "Basier Circle Semibold";
  max-width: 534px;
}
@media (max-width: 992px) {
  .online_reviews .loyalty_slider2 .loyalty_Slide h3 {
    font-size: 18px;
    line-height: 26px;
  }
}

.qr_code .display_table {
  flex-direction: unset;
}
.qr_code .banner {
  height: 100vh;
}
@media (max-width: 992px) {
  .qr_code .banner {
    height: unset;
    padding: 90px 0 0;
    text-align: center;
  }
  .qr_code .banner .common_title {
    font-size: 28px;
    line-height: 32px;
  }
  .qr_code .banner .common_desc {
    font-size: 14px;
    line-height: 22px;
  }
}
.qr_code .sec_title {
  font-size: 42px;
  margin: 0 0 16px;
  line-height: 51px;
  letter-spacing: -0.02em;
  font-weight: 500;
  font-family: "Basier Circle medium";
}
@media (max-width: 992px) {
  .qr_code .sec_title {
    font-size: 28px;
    line-height: 32px;
  }
}
.qr_code .sec_desc {
  font-size: 20px;
  margin: 0 0 24px;
  line-height: 30px;
  font-family: "Basier Circle";
}
.qr_code .common_title {
  font-size: 56px;
  margin: 0 0 16px;
  line-height: 62px;
  letter-spacing: -0.01em;
  font-family: "Basier Circle medium";
}
.qr_code .common_desc {
  font-size: 20px;
  margin: 0 0 40px;
  line-height: 30px;
  font-family: "Basier Circle";
}
.qr_code .loyaly_div {
  width: 557px;
  margin: 70px auto 0;
}
@media (max-width: 992px) {
  .qr_code .loyaly_div {
    width: 100%;
    margin: 0;
  }
}
.qr_code .loyaly_div .loyaly_slider {
  border-radius: 24px;
  background: #fef6ee;
  padding: 30px 30px 0;
}
.qr_code .loyalty_tabs_wrapper {
  border-bottom: 1px solid rgba(238, 238, 238, 0.9333333333);
  padding: 0 80px;
}
.qr_code .loyalty_tabs_wrapper .loyalty_tab {
  display: inline-block;
  padding: 50px 30px;
  cursor: pointer;
}
.qr_code .loyalty_tabs_wrapper .loyalty_tab:before {
  position: absolute;
  content: "";
  width: 95%;
  height: 4px;
  bottom: 0;
  background-color: #eee;
  transform: translateX(-50%);
  left: 50%;
}
.qr_code .loyalty_tabs_wrapper .loyalty_tab span {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: #f3f4f6;
  font-size: 24px;
  font-family: "Basier Circle Semibold";
  padding: 10px 19px;
}
.qr_code .loyalty_tabs_wrapper .loyalty_tab p {
  font-size: 18px;
  color: #959595;
  line-height: 26px;
  text-align: center;
  font-family: "Basier Circle medium";
  padding: 40px 0 0;
}
.qr_code .loyalty_tabs_wrapper .loyalty_tab.active:before {
  background-color: #01999f;
}
.qr_code .loyalty_tabs_wrapper .loyalty_tab.active p {
  color: #182020;
}
.qr_code .loyalty_slider2 {
  margin: 50px 0 0;
}
@media (max-width: 992px) {
  .qr_code .loyalty_slider2 {
    margin: 0;
  }
}
.qr_code .loyalty_slider2 .slick-dots {
  display: flex;
  margin: 0;
  list-style-type: none;
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
}
.qr_code .loyalty_slider2 .slick-dots button {
  display: block;
  width: 6px;
  height: 6px;
  padding: 0;
  background-color: grey;
  text-indent: -9999px;
  cursor: pointer;
  border-radius: 30px;
  border: unset;
  margin: 0 3px;
}
.qr_code .loyalty_slider2 li.slick-active button {
  background-color: #000;
}
.qr_code .loyalty_slider2 .loyalty_Slide {
  width: 100%;
  height: 490px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 64px;
  border-radius: 16px;
  background: #f5f8ff;
}
@media (max-width: 992px) {
  .qr_code .loyalty_slider2 .loyalty_Slide {
    padding: 30px 20px;
    height: unset;
    margin: 0 0 50px;
    display: block;
  }
}
@media (max-width: 992px) {
  .qr_code .loyalty_slider2 .loyalty_Slide .loyalty_img img {
    width: 200px;
    margin: 0 auto;
  }
}
.qr_code .loyalty_slider2 .loyalty_Slide .loyalty_content {
  padding: 0 0 30px;
  max-width: 60%;
}
@media (max-width: 992px) {
  .qr_code .loyalty_slider2 .loyalty_Slide .loyalty_content {
    max-width: 100%;
  }
}
.qr_code .loyalty_slider2 .loyalty_Slide .loyalty_content span {
  width: 64px;
  height: 64px;
  font-size: 34px;
  color: #fff;
  line-height: 42px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #01999f;
  font-family: "Basier Circle Semibold";
}
@media (max-width: 992px) {
  .qr_code .loyalty_slider2 .loyalty_Slide .loyalty_content span {
    width: 34px;
    height: 34px;
    font-size: 20px;
    line-height: 28px;
  }
}
.qr_code .loyalty_slider2 .loyalty_Slide .loyalty_content h3 {
  font-size: 36px;
  color: #171e59;
  line-height: 50px;
  margin: 32px 0 12px;
  letter-spacing: -0.02em;
  font-family: "Basier Circle Semibold";
}
@media (max-width: 992px) {
  .qr_code .loyalty_slider2 .loyalty_Slide .loyalty_content h3 {
    font-size: 28px;
    line-height: 32px;
  }
}
.qr_code .loyalty_slider2 .loyalty_Slide .loyalty_content p {
  font-size: 28px;
  color: #171e59;
  line-height: 40px;
  letter-spacing: -0.02em;
  font-family: "Basier Circle medium";
}
@media (max-width: 992px) {
  .qr_code .loyalty_slider2 .loyalty_Slide .loyalty_content p {
    font-size: 16px;
    line-height: 26px;
  }
}
.qr_code .qrcode_reviews {
  padding: 70px 0 0;
}
@media (max-width: 992px) {
  .qr_code .qrcode_reviews {
    padding: 40px 0;
  }
}
.qr_code .qrcode_reviews .qr_wrapper {
  padding-right: 60px;
}
@media (max-width: 992px) {
  .qr_code .qrcode_reviews .qr_wrapper {
    padding-right: 0;
  }
}
.qr_code .qrcode_reviews .qr_wrapper .loyalty_tabs {
  cursor: pointer;
  margin: 0 0 16px;
  padding: 16px 24px;
  border-radius: 16px;
  transition: all 0.5s;
  background: #f2f2f2;
}
.qr_code .qrcode_reviews .qr_wrapper .loyalty_tabs h3 {
  font-size: 16px;
  color: #182020;
  line-height: 22px;
}
.qr_code .qrcode_reviews .qr_wrapper .loyalty_tabs p {
  display: none;
  margin: 8px 0 0;
  font-size: 16px;
  color: #fff;
  line-height: 22px;
  text-align: left;
}
.qr_code .qrcode_reviews .qr_wrapper .loyalty_tabs.active {
  background-color: #01999f;
}
.qr_code .qrcode_reviews .qr_wrapper .loyalty_tabs.active h3 {
  color: #fff;
}
.qr_code .qrcode_reviews .qr_wrapper .loyalty_tabs.active p {
  display: block;
}
.qr_code .qrcode_reviews .qr_slider .slick-dots {
  display: flex;
  margin: 0;
  list-style-type: none;
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
}
.qr_code .qrcode_reviews .qr_slider .slick-dots button {
  display: block;
  width: 6px;
  height: 6px;
  padding: 0;
  background-color: grey;
  text-indent: -9999px;
  cursor: pointer;
  border-radius: 30px;
  border: unset;
  margin: 0 3px;
}
.qr_code .qrcode_reviews .qr_slider li.slick-active button {
  background-color: #000;
}
.qr_code .qrcode_reviews .qr_slider img {
  border-radius: 16px;
}
.qr_code .qrcode_reviews .qr_slider .qr_desc h3 {
  font-size: 18px;
  color: #0e1b1b;
  line-height: 24px;
  text-align: center;
  margin: 32px auto 8px;
}
.qr_code .qrcode_reviews .qr_slider .qr_desc p {
  font-size: 14px;
  color: #5a6565;
  max-width: 400px;
  line-height: 20px;
  margin: 0 auto 32px;
}
.qr_code .benifits_section {
  background: linear-gradient(0deg, rgba(86, 186, 153, 0.12) -71.36%, rgba(86, 186, 153, 0));
}
.qr_code .benifits_section .bene_div {
  padding: 32px 30px;
  border-radius: 16px;
  margin: 40px 0 0;
}
@media (max-width: 992px) {
  .qr_code .benifits_section .bene_div {
    width: 100%;
    margin: 30px 0 0;
    padding: 0;
  }
}
.qr_code .benifits_section .bene_div .bene_inner p {
  font-size: 16px;
  color: #092d2c;
  line-height: 21px;
  letter-spacing: -0.05em;
  font-family: "Basier Circle medium";
}
@media (max-width: 992px) {
  .qr_code .benifits_section .bene_div .bene_inner p {
    font-size: 14px;
    line-height: 20px;
  }
}
.qr_code .benifits_section .bene_div .bene_inner span {
  display: block;
  font-size: 52px;
  color: #56ba99;
  line-height: 68px;
  letter-spacing: -0.05em;
  font-weight: 600;
}
@media (max-width: 992px) {
  .qr_code .benifits_section .bene_div .bene_inner span {
    font-size: 32px;
    line-height: 42px;
  }
}
.qr_code .inspiration {
  border-radius: 16px;
  padding: 48px 40px 0;
  background: #fef6ee;
}
@media (max-width: 992px) {
  .qr_code .inspiration {
    padding: 40px 20px 0;
  }
}
.qr_code .quotes_section p {
  font-size: 24px;
  color: #092d2c;
  line-height: 32px;
  text-align: center;
  font-family: "Basier Circle";
}
@media (max-width: 992px) {
  .qr_code .quotes_section p {
    font-size: 18px;
    line-height: 26px;
  }
}
.qr_code .quotes_section .quote_flex {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.qr_code .quotes_section .quote_flex img {
  border-right: 1px solid #000;
  padding-right: 10px;
}
.qr_code .quotes_section .quote_flex p {
  font-size: 18px;
  color: #092d2c;
  line-height: 28px;
  text-align: left;
}
.qr_code .lastsection {
  border-radius: 24px;
  padding: 40px 20px;
}
@media (max-width: 992px) {
  .qr_code .lastsection {
    margin: 0 20px;
  }
}

.whatsapp-marketing .display_table {
  flex-direction: unset;
}
@media (max-width: 992px) {
  .whatsapp-marketing .banner {
    padding: 60px 0 0;
    text-align: center;
  }
  .whatsapp-marketing .banner .common_title {
    font-size: 28px;
    line-height: 32px;
  }
  .whatsapp-marketing .banner .common_desc {
    font-size: 14px;
    line-height: 22px;
  }
}
@media (max-width: 992px) {
  .whatsapp-marketing .sec-light {
    letter-spacing: -0.02em;
    font-weight: 500;
    font-family: "Basier Circle medium";
    font-size: 24px;
    line-height: 28px;
  }
}
@media (max-width: 992px) {
  .whatsapp-marketing .sec-title {
    font-size: 24px;
    line-height: 28px;
  }
}
.whatsapp-marketing .sec_title {
  font-size: 42px;
  line-height: 51px;
  letter-spacing: -0.02em;
  font-weight: 500;
  font-family: "Basier Circle medium";
}
@media (max-width: 992px) {
  .whatsapp-marketing .sec_title {
    font-size: 24px;
    line-height: 28px;
  }
}
.whatsapp-marketing .sec_desc {
  font-size: 20px;
  margin: 0 0 24px;
  line-height: 30px;
  font-family: "Basier Circle";
}
.whatsapp-marketing .common_title {
  font-size: 56px;
  margin: 0 0 16px;
  line-height: 62px;
  letter-spacing: -0.01em;
  font-family: "Basier Circle medium";
}
.whatsapp-marketing .common_desc {
  font-size: 20px;
  margin: 0 0 40px;
  line-height: 30px;
  font-family: "Basier Circle";
}
.whatsapp-marketing .loyaly_div {
  width: 557px;
  margin: 70px auto 0;
}
@media (max-width: 992px) {
  .whatsapp-marketing .loyaly_div {
    width: 100%;
    margin: 0;
  }
}
@media (max-width: 992px) {
  .whatsapp-marketing .pre-built .common_title {
    font-size: 28px;
    line-height: 32px;
  }
}
@media (max-width: 992px) {
  .whatsapp-marketing .pre-built .common_desc {
    font-size: 18px;
  }
}
.whatsapp-marketing .pre-built .campaigns_feature_card_root {
  gap: 24px;
  margin: 0 auto;
  padding: 0 16px;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 992px) {
  .whatsapp-marketing .pre-built .campaigns_feature_card_root {
    display: block;
    border-radius: 16px;
    padding: 0;
  }
  .whatsapp-marketing .pre-built .campaigns_feature_card_root .slick-dots {
    display: flex;
    margin: 0;
    list-style-type: none;
    position: absolute;
    bottom: -9px;
    left: 50%;
    transform: translateX(-50%);
  }
  .whatsapp-marketing .pre-built .campaigns_feature_card_root .slick-dots button {
    display: block;
    width: 12px;
    height: 12px;
    padding: 0;
    background-color: #c6c6c6;
    text-indent: -9999px;
    cursor: pointer;
    border-radius: 30px;
    border: unset;
    margin: 0 3px;
  }
  .whatsapp-marketing .pre-built .campaigns_feature_card_root li.slick-active button {
    background-color: #fff;
  }
}
.whatsapp-marketing .pre-built .campaigns_feature_card_root .campaigns_feature_card {
  height: 300px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0 24px 0 0;
  border-radius: 16px;
  background: #f3f1ee;
  width: calc(50% - 12px);
}
@media (max-width: 992px) {
  .whatsapp-marketing .pre-built .campaigns_feature_card_root .campaigns_feature_card {
    display: block;
    padding: 0;
    height: 540px;
    width: 100%;
    margin: 0 0 30px;
  }
}
.whatsapp-marketing .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_left_side {
  padding: 174px 4px 48px 28px;
}
@media (max-width: 992px) {
  .whatsapp-marketing .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_left_side {
    padding: 34px 4px 34px 28px;
  }
}
.whatsapp-marketing .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_left_side .campaigns_feature_number {
  padding: 4px 9px;
  width: 28px;
  height: 28px;
  font-size: 16px;
  color: #fff;
  line-height: 22px;
  border-radius: 8px;
  background: #065151;
  font-family: "Basier Circle Semibold";
}
.whatsapp-marketing .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_left_side h3 {
  margin: 8px 0;
  font-size: 28px;
  color: #0e1b1b;
  line-height: 34px;
  letter-spacing: -0.02em;
  font-family: "Basier Circle Semibold";
}
@media (max-width: 992px) {
  .whatsapp-marketing .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_left_side h3 {
    font-size: 20px;
    line-height: 24px;
  }
}
.whatsapp-marketing .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_left_side p {
  font-size: 14px;
  color: #232d2d;
  line-height: 20px;
}
.whatsapp-marketing .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_right_side {
  width: 100%;
  position: relative;
  bottom: -6px;
}
@media (max-width: 992px) {
  .whatsapp-marketing .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_right_side {
    text-align: center;
    padding: 0 15px;
    max-width: 100%;
    bottom: 0;
  }
  .whatsapp-marketing .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_right_side img {
    height: 300px;
  }
}
.whatsapp-marketing .pre-built .campaigns_feature_card_root .last_card {
  width: 100%;
  height: auto;
}
.whatsapp-marketing .qrcode_reviews .qr_wrapper {
  padding-right: 60px;
}
@media (max-width: 992px) {
  .whatsapp-marketing .qrcode_reviews .qr_wrapper {
    padding-right: 0;
  }
}
.whatsapp-marketing .qrcode_reviews .qr_wrapper .loyalty_tabs {
  cursor: pointer;
  margin: 0 0 16px;
  padding: 16px 24px;
  border-radius: 16px;
  transition: all 0.5s;
  background: #f2f2f2;
}
.whatsapp-marketing .qrcode_reviews .qr_wrapper .loyalty_tabs h3 {
  font-size: 20px;
  color: #182020;
  line-height: 26px;
}
.whatsapp-marketing .qrcode_reviews .qr_wrapper .loyalty_tabs p {
  display: none;
  margin: 8px 0 0;
  font-size: 16px;
  color: #fff;
  line-height: 22px;
  text-align: left;
}
.whatsapp-marketing .qrcode_reviews .qr_wrapper .loyalty_tabs ul {
  display: none;
  padding: 10px 0 0 15px;
}
.whatsapp-marketing .qrcode_reviews .qr_wrapper .loyalty_tabs ul li {
  font-size: 16px;
  color: #fff;
  line-height: 22px;
  text-align: left;
  padding: 0 0 5px 5px;
  position: relative;
  list-style: none;
}
.whatsapp-marketing .qrcode_reviews .qr_wrapper .loyalty_tabs ul li:before {
  position: absolute;
  content: "✔";
  left: -13px;
}
.whatsapp-marketing .qrcode_reviews .qr_wrapper .loyalty_tabs.active {
  background-color: #01999f;
}
.whatsapp-marketing .qrcode_reviews .qr_wrapper .loyalty_tabs.active h3 {
  color: #fff;
}
.whatsapp-marketing .qrcode_reviews .qr_wrapper .loyalty_tabs.active p, .whatsapp-marketing .qrcode_reviews .qr_wrapper .loyalty_tabs.active ul {
  display: block;
}
.whatsapp-marketing .qrcode_reviews .qr_slider .slick-dots {
  display: flex;
  margin: 0;
  list-style-type: none;
  position: absolute;
  bottom: -9px;
  left: 50%;
  transform: translateX(-50%);
}
.whatsapp-marketing .qrcode_reviews .qr_slider .slick-dots button {
  display: block;
  width: 6px;
  height: 6px;
  padding: 0;
  background-color: grey;
  text-indent: -9999px;
  cursor: pointer;
  border-radius: 30px;
  border: unset;
  margin: 0 3px;
}
.whatsapp-marketing .qrcode_reviews .qr_slider li.slick-active button {
  background-color: #000;
}
.whatsapp-marketing .qrcode_reviews .qr_slider img {
  border-radius: 16px;
}
@media (max-width: 992px) {
  .whatsapp-marketing .qrcode_reviews .qr_slider .qr_desc {
    text-align: left;
  }
}
.whatsapp-marketing .qrcode_reviews .qr_slider .qr_desc h3 {
  font-size: 18px;
  color: #0e1b1b;
  line-height: 24px;
  margin: 32px auto 8px;
}
.whatsapp-marketing .qrcode_reviews .qr_slider .qr_desc p {
  font-size: 14px;
  color: #5a6565;
  max-width: 400px;
  line-height: 20px;
  margin: 0 auto 8px;
}
.whatsapp-marketing .qrcode_reviews .qr_slider .qr_desc ul {
  padding: 10px 14px 20px;
}
.whatsapp-marketing .qrcode_reviews .qr_slider .qr_desc ul li {
  font-size: 14px;
  color: #5a6565;
  max-width: 400px;
  line-height: 20px;
  list-style: none;
  padding-bottom: 5px;
  position: relative;
}
.whatsapp-marketing .qrcode_reviews .qr_slider .qr_desc ul li:before {
  position: absolute;
  content: "✔";
  margin-left: -14px;
}
.whatsapp-marketing .fold3 .marrows {
  margin: 30px 0 35px;
}
.whatsapp-marketing .fold3 .marrows svg {
  width: 48px;
  height: 48px;
  background-color: #f3f5f5;
  border-radius: 100%;
  padding: 10px;
  margin: 0 10px;
}
.whatsapp-marketing .fold3 .marrows svg:hover {
  background-color: #0d9298;
}
.whatsapp-marketing .fold3 .marrows svg:hover path {
  stroke: #fff;
}
.whatsapp-marketing .fold3 .marrows #cnext {
  position: relative;
  transform: rotate(180deg);
}
.whatsapp-marketing .fold3 .reviewCarousel_parent .reviewCarousel {
  gap: 48px;
  padding: 24px;
  height: 381px;
  margin: 0 4px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  border-radius: 24px;
  align-items: stretch;
  background: #e0eaff;
}
@media (max-width: 992px) {
  .whatsapp-marketing .fold3 .reviewCarousel_parent .reviewCarousel {
    gap: 12px;
    padding: 16px;
    margin: 0 4px;
    border-radius: 8px;
    flex-direction: column;
    height: unset;
  }
}
.whatsapp-marketing .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_left {
  max-width: 665px;
  overflow: hidden;
  width: calc(50% - 24px);
}
@media (max-width: 992px) {
  .whatsapp-marketing .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_left {
    max-width: 100%;
    width: 100%;
    overflow: unset;
  }
}
.whatsapp-marketing .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_left iframe {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  border-radius: 12px;
}
.whatsapp-marketing .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_right {
  gap: 24px;
  padding: 10px 0 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  max-width: -moz-calc(50% - 24px);
  max-width: calc(50% - 24px);
}
@media (max-width: 992px) {
  .whatsapp-marketing .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_right {
    max-width: 100%;
    min-width: 100%;
    padding: 15px 0 0;
  }
}
.whatsapp-marketing .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_right h3 {
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -1.28px;
  font-family: "Basier Circle medium";
  font-weight: 500;
}
@media (max-width: 992px) {
  .whatsapp-marketing .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_right h3 {
    margin: 0 0 8px;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.24px;
  }
}
.whatsapp-marketing .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_right .reviewCarousel_review_detail {
  gap: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.whatsapp-marketing .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_right .reviewCarousel_review_detail .reviewImgs {
  border-radius: 50%;
  object-fit: contain;
  background-color: #fff;
}
.whatsapp-marketing .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_right .reviewCarousel_review_detail .reviewer_name {
  font-size: 22px;
  line-height: 26px;
  font-family: "Basier Circle medium";
  padding: 0 0 6px;
}
@media (max-width: 992px) {
  .whatsapp-marketing .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_right .reviewCarousel_review_detail .reviewer_name {
    font-size: 16px;
    line-height: 22px;
  }
}
.whatsapp-marketing .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_right .reviewCarousel_review_detail .reviewer_post {
  font-size: 14px;
  color: #374040;
  line-height: 20px;
  letter-spacing: -0.14px;
}
@media (max-width: 992px) {
  .whatsapp-marketing .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_right .reviewCarousel_review_detail .reviewer_post {
    font-size: 12px;
    line-height: 16px;
  }
}
.whatsapp-marketing .what-reelo-offers .whatreelo_offers {
  display: flex;
  justify-content: center;
  gap: 30px;
  padding: 15px;
}
@media (max-width: 992px) {
  .whatsapp-marketing .what-reelo-offers .whatreelo_offers {
    padding: 0;
    gap: 10px;
    margin: 5px 0;
  }
}
.whatsapp-marketing .what-reelo-offers .whatreelo_offers .whatreelo_offers_div {
  width: 343px;
  height: 196px;
  padding: 32px 22px;
  border-radius: 17px;
}
@media (max-width: 992px) {
  .whatsapp-marketing .what-reelo-offers .whatreelo_offers .whatreelo_offers_div {
    height: 130px;
    padding: 15px;
  }
  .whatsapp-marketing .what-reelo-offers .whatreelo_offers .whatreelo_offers_div img {
    width: 35px;
  }
}
.whatsapp-marketing .what-reelo-offers .whatreelo_offers .whatreelo_offers_div p {
  color: #171E59;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  padding: 15px 0 0;
}
@media (max-width: 992px) {
  .whatsapp-marketing .what-reelo-offers .whatreelo_offers .whatreelo_offers_div p {
    font-size: 14px;
  }
}
.whatsapp-marketing .what-reelo-offers .whatreelo_offers .offer_1 {
  background: #EDFCF2;
}
.whatsapp-marketing .what-reelo-offers .whatreelo_offers .offer_2 {
  background: #FEF6EE;
}
.whatsapp-marketing .what-reelo-offers .whatreelo_offers .offer_3 {
  background: #F5F8FF;
}
.whatsapp-marketing .what-reelo-offers .whatreelo_offers .offer_4 {
  background: #FFE5EB;
}
.whatsapp-marketing .what-reelo-offers .whatreelo_offers .offer_5 {
  background: #DDFEFF;
}
.whatsapp-marketing .what-reelo-offers .whatreelo_offers .offer_6 {
  background: #FFF1D4;
}
.whatsapp-marketing .lastsection {
  border-radius: 24px;
  padding: 40px 20px;
}
@media (max-width: 992px) {
  .whatsapp-marketing .lastsection {
    margin: 0 20px;
  }
}

.referral_section .display_table {
  flex-direction: unset;
}
.referral_section .banner {
  height: 100vh;
}
@media (max-width: 992px) {
  .referral_section .banner {
    height: unset;
    padding: 90px 0 0;
    text-align: center;
  }
  .referral_section .banner .common_title {
    font-size: 28px;
    line-height: 32px;
  }
  .referral_section .banner .common_desc {
    font-size: 14px;
    line-height: 22px;
  }
}
.referral_section .banner .common_subtitle {
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.18em;
  text-transform: uppercase;
  font-family: "Basier Circle Semibold";
  padding: 0 0 20px;
}
.referral_section .sec_title {
  font-size: 42px;
  margin: 0 0 16px;
  line-height: 51px;
  letter-spacing: -0.02em;
  font-weight: 500;
  font-family: "Basier Circle medium";
}
@media (max-width: 992px) {
  .referral_section .sec_title {
    font-size: 28px;
    line-height: 32px;
  }
}
.referral_section .sec_desc {
  font-size: 20px;
  margin: 0 0 24px;
  line-height: 30px;
  font-family: "Basier Circle";
}
.referral_section .common_title {
  font-size: 56px;
  margin: 0 0 16px;
  line-height: 62px;
  font-family: "Basier Circle medium";
}
.referral_section .common_desc {
  font-size: 20px;
  margin: 0 0 40px;
  line-height: 30px;
  font-family: "Basier Circle";
}
@media (max-width: 992px) {
  .referral_section .loyaly_div {
    width: 100%;
    margin: 0;
  }
}
.referral_section .loyaly_div .ref_video {
  width: 557px;
  height: 545px;
  overflow: hidden;
  border-radius: 24px;
  background: #d8ecee;
}
@media (max-width: 992px) {
  .referral_section .loyaly_div .ref_video {
    width: 100%;
    height: 100%;
  }
}
@media (max-width: 992px) {
  .referral_section .pre-built .common_title {
    font-size: 28px;
    line-height: 32px;
  }
}
@media (max-width: 992px) {
  .referral_section .pre-built .common_desc {
    font-size: 18px;
  }
}
.referral_section .pre-built .campaigns_feature_card_root {
  gap: 24px;
  margin: 0 auto;
  padding: 0 16px;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 992px) {
  .referral_section .pre-built .campaigns_feature_card_root {
    display: block;
    border-radius: 16px;
    padding: 0;
  }
}
.referral_section .pre-built .campaigns_feature_card_root .campaigns_feature_card {
  height: 360px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0 24px 0 0;
  border-radius: 16px;
  background: #f3f1ee;
  width: calc(50% - 12px);
}
@media (max-width: 992px) {
  .referral_section .pre-built .campaigns_feature_card_root .campaigns_feature_card {
    display: block;
    padding: 0;
    height: unset;
    width: 100%;
    margin: 0 0 30px;
  }
}
.referral_section .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_left_side {
  padding: 174px 8px 48px 28px;
}
@media (max-width: 992px) {
  .referral_section .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_left_side {
    padding: 34px 8px 34px 28px;
  }
}
.referral_section .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_left_side .campaigns_feature_number {
  padding: 4px 9px;
  width: 28px;
  height: 28px;
  font-size: 16px;
  color: #fff;
  line-height: 22px;
  border-radius: 8px;
  background: #065151;
  font-family: "Basier Circle Semibold";
}
.referral_section .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_left_side h3 {
  margin: 8px 0;
  font-size: 28px;
  color: #0e1b1b;
  line-height: 34px;
  letter-spacing: -0.02em;
  font-family: "Basier Circle Semibold";
}
@media (max-width: 992px) {
  .referral_section .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_left_side h3 {
    font-size: 20px;
    line-height: 24px;
  }
}
.referral_section .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_left_side p {
  font-size: 14px;
  color: #232d2d;
  line-height: 20px;
}
.referral_section .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_right_side {
  width: 100%;
  max-width: 312px;
  position: relative;
  height: -moz-calc(100% - 48px);
}
.referral_section .pre-built .campaigns_feature_card_root .last_card {
  width: 100%;
  height: auto;
}
.referral_section .referral_programs .referral_slider {
  margin: 45px 0;
}
.referral_section .referral_programs .referral_slider img {
  display: inline-block !important;
}
.referral_section .referral_programs .referral_slider .slick-dots {
  display: flex;
  margin: 0;
  list-style-type: none;
  position: absolute;
  bottom: -35px;
  left: 50%;
  transform: translateX(-50%);
}
.referral_section .referral_programs .referral_slider .slick-dots button {
  display: block;
  width: 10px;
  height: 10px;
  padding: 0;
  background-color: #038c8b;
  text-indent: -9999px;
  cursor: pointer;
  border-radius: 30px;
  border: unset;
  margin: 0 5px;
  opacity: 0.25;
}
.referral_section .referral_programs .referral_slider li.slick-active button {
  opacity: 1;
}
.referral_section .referral_programs .referral_slider .ref_slide p {
  font-size: 16px;
  margin: 24px 0 0;
  color: #374040;
  line-height: 24px;
  text-align: center;
  font-family: "Basier Circle medium";
}
.referral_section .how_to_send_cards .common_subtitle {
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.18em;
  text-transform: uppercase;
  font-family: "Basier Circle Semibold";
  padding: 0 0 20px;
}
.referral_section .how_to_send_cards .marrows {
  margin: 30px 0 35px;
}
.referral_section .how_to_send_cards .marrows svg {
  width: 48px;
  height: 48px;
  background-color: #f3f5f5;
  border-radius: 100%;
  padding: 10px;
  margin: 0 10px;
}
.referral_section .how_to_send_cards .marrows svg:hover {
  background-color: #0d9298;
}
.referral_section .how_to_send_cards .marrows svg:hover path {
  stroke: #fff;
}
.referral_section .how_to_send_cards .marrows #snext {
  position: relative;
  transform: rotate(180deg);
}
.referral_section .how_to_send_cards .campaigns_how_to_send_cards_parent .campaigns_how_to_send_card {
  gap: 24px;
  height: 580px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 16px;
  background: #f5f8ff;
}
@media (max-width: 992px) {
  .referral_section .how_to_send_cards .campaigns_how_to_send_cards_parent .campaigns_how_to_send_card {
    height: unset;
    display: block;
  }
}
.referral_section .how_to_send_cards .campaigns_how_to_send_cards_parent .campaigns_how_to_send_card .campaigns_left_side {
  flex-grow: 1;
  padding: 48px 64px;
}
@media (max-width: 992px) {
  .referral_section .how_to_send_cards .campaigns_how_to_send_cards_parent .campaigns_how_to_send_card .campaigns_left_side {
    padding: 20px;
  }
}
.referral_section .how_to_send_cards .campaigns_how_to_send_cards_parent .campaigns_how_to_send_card .campaigns_left_side .campaigns_card_number {
  width: 64px;
  height: 64px;
  font-size: 34px;
  color: #fff;
  line-height: 42px;
  border-radius: 50%;
  display: -moz-box;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #6172f3;
  font-family: "Basier Circle Semibold";
}
@media (max-width: 992px) {
  .referral_section .how_to_send_cards .campaigns_how_to_send_cards_parent .campaigns_how_to_send_card .campaigns_left_side .campaigns_card_number {
    width: 34px;
    height: 34px;
    font-size: 20px;
    line-height: 28px;
  }
}
.referral_section .how_to_send_cards .campaigns_how_to_send_cards_parent .campaigns_how_to_send_card .campaigns_left_side h3 {
  font-size: 36px;
  margin: 32px 0 0;
  color: #171e59;
  line-height: 50px;
  letter-spacing: -0.02em;
  font-family: "Basier Circle Semibold";
}
@media (max-width: 992px) {
  .referral_section .how_to_send_cards .campaigns_how_to_send_cards_parent .campaigns_how_to_send_card .campaigns_left_side h3 {
    font-size: 28px;
    line-height: 32px;
  }
}
.referral_section .how_to_send_cards .campaigns_how_to_send_cards_parent .campaigns_how_to_send_card .campaigns_left_side p {
  margin: 40px 0 0;
  color: rgba(23, 30, 89, 0.2);
  font-size: 36px;
  line-height: 50px;
  letter-spacing: -0.02em;
  font-family: "Basier Circle Semibold";
}
@media (max-width: 992px) {
  .referral_section .how_to_send_cards .campaigns_how_to_send_cards_parent .campaigns_how_to_send_card .campaigns_left_side p {
    font-size: 16px;
    line-height: 26px;
    display: none;
  }
}
.referral_section .how_to_send_cards .campaigns_how_to_send_cards_parent .campaigns_how_to_send_card .campaigns_right_side img {
  padding: 20px 64px 20px 0;
}
@media (max-width: 992px) {
  .referral_section .how_to_send_cards .campaigns_how_to_send_cards_parent .campaigns_how_to_send_card .campaigns_right_side img {
    padding: 20px 20px 0;
  }
}
.referral_section .miss_out .bene_div {
  padding: 32px 30px;
  border-radius: 16px;
}
@media (max-width: 992px) {
  .referral_section .miss_out .bene_div {
    width: 100%;
    margin: 30px 0 0;
    padding: 0;
  }
}
.referral_section .miss_out .bene_div .bene_inner p {
  font-size: 24px;
  color: #374040;
  line-height: 34px;
  letter-spacing: -0.02em;
  font-family: "Basier Circle medium";
}
@media (max-width: 992px) {
  .referral_section .miss_out .bene_div .bene_inner p {
    font-size: 14px;
    line-height: 20px;
  }
}
.referral_section .miss_out .bene_div .bene_inner span {
  display: block;
  font-size: 64px;
  color: #15b79e;
  line-height: 78px;
  letter-spacing: -0.02em;
  font-weight: 600;
}
@media (max-width: 992px) {
  .referral_section .miss_out .bene_div .bene_inner span {
    font-size: 32px;
    line-height: 42px;
  }
}
.referral_section .benifits_section .bene_div {
  padding: 32px 30px;
  border-radius: 16px;
  width: 47%;
  margin: 40px 14px 0;
}
@media (max-width: 992px) {
  .referral_section .benifits_section .bene_div {
    width: 100%;
    margin: 30px 0 0;
  }
}
.referral_section .benifits_section .bene_div h3 {
  font-size: 24px;
  color: #171e59;
  line-height: 32px;
  margin: 40px auto 16px;
  letter-spacing: -0.02em;
  font-family: "Basier Circle medium";
}
.referral_section .benifits_section .bene_div p {
  font-size: 18px;
  color: #171e59;
  line-height: 28px;
  font-family: "Basier Circle";
}
.referral_section .benifits_section .bene_div .bene_inner {
  padding: 36px 22px;
  background: #fff;
  border-radius: 16.7273px;
  margin: 0 0 30px;
}
.referral_section .benifits_section .bene_div .bene_inner p {
  font-size: 20px;
  color: #171e59;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.02em;
  font-family: "Basier Circle medium";
}
.referral_section .benifits_section .bene_div .bene_inner span {
  font-size: 48px;
  line-height: 62px;
  text-align: center;
  letter-spacing: -0.02em;
  font-family: "Basier Circle Semibold";
  display: block;
}
.referral_section .benifits_section .bene_div .bene_inner .green {
  color: #16b364;
}
.referral_section .benifits_section .bene_div .bene_inner .red {
  color: #ef6820;
}
.referral_section .benifits_section .bene_div .bene_inner .blue {
  color: #1570ef;
}
.referral_section .benifits_section .b1 {
  background: #edfcf2;
}
.referral_section .benifits_section .b2 {
  background: #fef6ee;
}
.referral_section .benifits_section .b3 {
  background: #f5f8ff;
}
.referral_section .quotes_section p {
  font-size: 24px;
  color: #092d2c;
  line-height: 32px;
  text-align: center;
  font-family: "Basier Circle";
}
@media (max-width: 992px) {
  .referral_section .quotes_section p {
    font-size: 18px;
    line-height: 26px;
  }
}
.referral_section .quotes_section .quote_flex {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.referral_section .quotes_section .quote_flex img {
  border-right: 1px solid #000;
  padding-right: 10px;
}
.referral_section .quotes_section .quote_flex p {
  font-size: 18px;
  color: #092d2c;
  line-height: 28px;
  text-align: left;
}
.referral_section .lastsection {
  border-radius: 24px;
  padding: 40px 20px;
}
@media (max-width: 992px) {
  .referral_section .lastsection {
    margin: 0 20px;
  }
}

.about-us .about_banner {
  display: flex;
  align-items: center;
  gap: 45px;
  padding: 0 0 60px;
}
@media (max-width: 992px) {
  .about-us .about_banner {
    flex-direction: column;
    gap: 20px;
    padding: 20px 0;
  }
}
.about-us .common_title {
  color: #354545;
  font-weight: 600;
  margin: 70px 0;
  font-size: 56px;
  line-height: 62px;
  letter-spacing: -0.01em;
  font-family: "Basier Circle medium";
}
@media (max-width: 992px) {
  .about-us .common_title {
    margin: 40px auto 20px;
    font-size: 32px;
    line-height: 42px;
  }
}
.about-us .sec_title {
  font-size: 36px;
  line-height: 43px;
  font-weight: 600;
  color: #354545;
}
.about-us .about_content h2 {
  font-size: 24px;
  line-height: 36px;
  color: #354545;
  text-align: center;
  margin: 80px 0;
}
@media (max-width: 992px) {
  .about-us .about_content h2 {
    margin: 20px 0;
  }
}
.about-us .about_content h2 .selected_text {
  background-color: #33c1c0;
  color: #fff;
  font-weight: 400;
}
.about-us .guiding {
  background-color: #f6ffff;
}
.about-us .guiding .common_desc {
  font-size: 24px;
  line-height: 34px;
  color: #354545;
  max-width: 440px;
  font-weight: 600;
  margin: 10px 0 45px;
}
.about-us .guiding .about_banner {
  justify-content: space-between;
}
.about-us .guiding .about_banner .principles h3 {
  font-size: 24px;
  line-height: 34px;
  color: #33c1c0;
  font-weight: 600;
  padding: 0 0 8px;
}
.about-us .guiding .about_banner .principles p {
  font-size: 16px;
  line-height: 24px;
  color: #354545;
}
.about-us .our_story .about_banner {
  gap: 30px;
}
@media (max-width: 992px) {
  .about-us .our_story .about_banner {
    flex-direction: column;
    gap: 20px;
  }
}
.about-us .our_team {
  background-color: #fff9e9;
}
.about-us .our_team .aboutus_custom-row {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 50px;
}
@media (max-width: 992px) {
  .about-us .our_team .aboutus_custom-row {
    flex-direction: column;
    gap: 0px;
    justify-content: center;
  }
}
.about-us .our_team .aboutus_custom-row .aboutus_left img {
  margin: 0 0 30px auto;
  display: block;
}
@media (max-width: 992px) {
  .about-us .our_team .aboutus_custom-row .aboutus_left img {
    width: 100%;
  }
}
.about-us .our_team .aboutus_custom-row .aboutus_left .aboutus_text-box h2 {
  font-size: 36px;
  line-height: 43px;
  color: #354545;
  font-weight: 600;
}
.about-us .our_team .aboutus_custom-row .aboutus_left .aboutus_text-box p {
  font-size: 16px;
  line-height: 24px;
  color: #354545;
  padding: 15px 0;
}
@media (max-width: 992px) {
  .about-us .our_team .aboutus_custom-row .aboutus_right {
    width: 100%;
  }
}
.about-us .our_team .aboutus_custom-row .aboutus_right .theme-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 45px;
  border: none;
  color: #ffffff;
  background: #33c1c0;
  border-radius: 8px;
}
@media (max-width: 992px) {
  .about-us .our_team .aboutus_custom-row .aboutus_right .theme-btn {
    width: 100%;
  }
}
.about-us .our_team .aboutus_custom-row .aboutus_right .theme-btn img {
  vertical-align: middle;
}
.about-us .our_team .aboutus_custom-row .aboutus_right .react-reveal .lazyload-wrapper img {
  display: block;
  margin: 0 auto 30px 0;
}
@media (max-width: 992px) {
  .about-us .our_team .aboutus_custom-row .aboutus_right .react-reveal .lazyload-wrapper img {
    width: 100%;
  }
}
.about-us .reelo_news .reelonew_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 60px 0 0;
  flex-wrap: wrap;
}
.about-us .reelo_news .reelonew_wrapper .reeloNews {
  display: flex;
  align-items: center;
  justify-items: center;
}
@media (max-width: 992px) {
  .about-us .reelo_news .reelonew_wrapper .reeloNews {
    text-align: center;
    display: block;
    justify-items: unset;
    align-items: unset;
  }
}
.about-us .reelo_news .reelonew_wrapper .reeloNews .reelonews_icon img {
  filter: gray;
  filter: grayscale(1);
  transition: 0.2s ease-in;
}
.about-us .reelo_news .reelonew_wrapper .reeloNews .reelonews_icon img:hover {
  filter: none;
  transform: scale(1.2);
}

.pricing .sec-heading2 {
  font-family: "Basier Circle Semibold";
  color: #182020;
  margin: 40px 0;
}
.pricing .pricing_card-rows {
  display: flex;
  justify-content: center;
  gap: 25px;
}
@media (max-width: 992px) {
  .pricing .pricing_card-rows {
    display: block;
  }
}
.pricing .pricing_card-rows .cards {
  width: 370px;
  height: 100%;
  min-height: 380px;
  padding: 24px 32px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (max-width: 992px) {
  .pricing .pricing_card-rows .cards {
    width: 100%;
  }
}
.pricing .pricing_card-rows .cards .white-btn {
  color: #1A424F;
  border: 1px solid #1A424F;
  border-radius: 8px;
  background-color: transparent;
  width: 100%;
}
.pricing .pricing_card-rows .cards .theme-btn {
  width: 100%;
}
.pricing .pricing_card-rows .cards h3 {
  font-size: 24px;
  color: #354545;
  line-height: 30px;
  margin-bottom: 8px;
  font-weight: 700;
}
.pricing .pricing_card-rows .cards h4 {
  font-size: 48px;
  line-height: 67px;
  color: #182020;
  font-weight: 600;
}
.pricing .pricing_card-rows .cards h4 p {
  display: inline;
  font-size: 48px;
  line-height: 67px;
  color: #182020;
  font-weight: 400;
}
.pricing .pricing_card-rows .cards h4 span {
  font-size: 14px;
  line-height: 18px;
}
.pricing .pricing_card-rows .cards h6 {
  font-size: 16px;
  line-height: 30px;
  color: rgba(24, 32, 32, 0.75);
  font-weight: 400;
}
.pricing .pricing_card-rows .cards .pricing_desc2 {
  font-size: 14px;
  color: #1a424f;
  line-height: 28px;
  text-align: center;
}
.pricing .pricing_card-rows .cards .pricing_features {
  display: flex;
  margin: 24px 0;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}
.pricing .pricing_card-rows .pricing_plan-card {
  background: #f5f5f5;
}
.pricing .pricing_card-rows .pricing_plan-card__3SDlI {
  background: linear-gradient(157.35deg, #c4f2f4 14.72%, #9cffed 102.4%);
}
.pricing .pricing_term {
  font-size: 12px;
  line-height: 17px;
  text-align: right;
  color: #838c8c;
}
.pricing .pricebanner-fixed {
  position: fixed;
  width: 335px;
  top: 105px;
}
.pricing .pricing_compare-plans h3 {
  margin: 40px 0 16px;
}
.pricing .pricing_compare-plans .pricing_plans-head {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 24px 0 16px;
  border-bottom: 2px solid #ddd;
  background-color: #fff;
}
.pricing .pricing_compare-plans .pricing_plans-head .pricing_head-col {
  width: 50%;
}
.pricing .pricing_compare-plans .pricing_plans-head .pricing_head-col .pricing_span1 {
  font-size: 24px;
  line-height: 34px;
  font-weight: 600;
}
.pricing .pricing_compare-plans .pricing_plans-head .pricing_head-col .pricing_span2 {
  color: #616d6d;
  font-weight: 600;
}
.pricing .pricing_compare-plans .pricing_mobile-price-row {
  padding: 16px 0;
  border-bottom: 1px solid #ddd;
}
.pricing .pricing_compare-plans .pricing_mobile-price-row .pricing_question {
  cursor: pointer;
}
.pricing .pricing_compare-plans .pricing_mobile-price-row .pricing_question h3 {
  font-size: 16px;
  color: #000;
  line-height: 20px;
  margin: 0;
}
.pricing .pricing_compare-plans .pricing_mobile-price-row .pricing_question h3 img {
  width: 24px;
  padding-left: 12px;
}
.pricing .pricing_compare-plans .pricing_mobile-price-row .pricing_question .pricing_icon-row {
  padding-top: 8px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.pricing .pricing_compare-plans .pricing_mobile-price-row .pricing_question .pricing_icon-row div {
  width: 50%;
}
.pricing .pricing_compare-plans .pricing_mobile-price-row .pricing_dets-row {
  padding: 16px 0 4px;
}
.pricing .pricing_compare-plans .pricing_mobile-price-row .pricing_dets-row h5 {
  font-size: 14px;
  color: #182020;
  line-height: 17px;
  margin-bottom: 8px;
  font-family: "Basier Circle";
  font-weight: 400;
}
.pricing .pricing_compare-plans .pricing_mobile-price-row .pricing_dets-row .pricing_icon-row {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.pricing .pricing_compare-plans .pricing_mobile-price-row .pricing_dets-row .pricing_icon-row div {
  width: 50%;
}
.pricing .pricing-collasible-table {
  overflow-x: auto;
  padding: 0 60px;
  text-align: left;
}
.pricing .pricing-collasible-table table {
  margin: 0;
  width: 100%;
  border-collapse: collapse;
}
.pricing .pricing-collasible-table table thead tr {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.pricing .pricing-collasible-table table thead tr th {
  padding: 28px 0;
  font-size: 16px;
  line-height: 19px;
  font-family: "Basier Circle";
  color: #182020;
  font-size: 20px;
  line-height: 28px;
}
.pricing .pricing-collasible-table table thead tr th span {
  font-size: 12px;
  font-family: "Basier Circle";
}
.pricing .pricing-collasible-table table thead tr th .pricing_span1 {
  font-size: 40px;
  line-height: 56px;
  font-family: "Basier Circle";
}
.pricing .pricing-collasible-table table thead tr th .pricing_span2 {
  font-size: 16px;
  color: #182020;
  line-height: 22px;
  font-family: "Basier Circle";
}
.pricing .pricing-collasible-table table thead tr th:first-of-type {
  font-size: 24px;
  line-height: 30px;
  vertical-align: middle;
  font-family: "Basier Circle";
}
.pricing .pricing-collasible-table table thead tr th:first-child {
  min-width: 330px;
}
.pricing .pricing-collasible-table table tbody .pricing_feature-head {
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}
.pricing .pricing-collasible-table table tbody .pricing_feature-head td {
  padding: 19px 0 23px;
  font-size: 18px;
  line-height: 22px;
  color: #354545;
  font-family: "Basier Circle";
}
.pricing .pricing-collasible-table table tbody .pricing_feature-rows {
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}
.pricing .pricing-collasible-table table tbody .pricing_feature-rows td {
  font-size: 14px;
  line-height: 17px;
  color: #556969;
  padding: 14px 0 13px;
  min-width: 100px;
}
.pricing .pricing-collasible-table table tbody .pricing_feature-rows td .tt-img {
  cursor: pointer;
}
.pricing .pricing-collasible-table table tbody .pricing_feature-rows .tableFlex {
  display: flex;
  align-items: center;
}
.pricing .pricing_contact-banner {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 32px;
  margin: 40px 0;
  background: #ecfaff;
  border-radius: 15px;
}
@media (max-width: 992px) {
  .pricing .pricing_contact-banner {
    flex-direction: column;
    align-items: flex-start;
  }
}
.pricing .pricing_contact-banner .pricing_rightcontent {
  margin-left: 50px;
}
@media (max-width: 992px) {
  .pricing .pricing_contact-banner .pricing_rightcontent {
    margin-left: 0;
  }
}
.pricing .pricing_contact-banner .pricing_rightcontent h3 {
  font-size: 24px;
  line-height: 29px;
  color: #354545;
}
.pricing .pricing_contact-banner .pricing_rightcontent h5 {
  font-size: 14px;
  line-height: 21px;
  color: #354545;
  margin: 15px 0;
}
@media (max-width: 992px) {
  .pricing .pricing_contact-banner .pricing_rightcontent .theme-btn {
    width: 100%;
  }
}

.growth-stories .main_wrapper {
  background-color: #f8fbfb;
}
.growth-stories .display_table {
  flex-direction: unset;
}
.growth-stories .banner {
  height: 100vh;
}
@media (max-width: 992px) {
  .growth-stories .banner {
    height: unset;
    padding: 90px 0 0;
  }
  .growth-stories .banner .common_title {
    font-size: 28px;
    line-height: 32px;
  }
  .growth-stories .banner .common_desc {
    font-size: 14px;
    line-height: 22px;
  }
}
.growth-stories .banner .common_subtitle {
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.18em;
  text-transform: uppercase;
  font-family: "Basier Circle Semibold";
  padding: 0 0 20px;
}
.growth-stories .banner .loyaly_div .growth-stories_right_side {
  min-width: 500px;
  padding: 24px 32px;
  border-radius: 16px;
  background: #0d9298;
}
@media (max-width: 992px) {
  .growth-stories .banner .loyaly_div .growth-stories_right_side {
    min-width: 100%;
    padding: 20px;
    margin-bottom: 40px;
  }
}
.growth-stories .banner .loyaly_div .growth-stories_right_side h3 {
  font-size: 24px;
  margin: 0 0 20px;
  line-height: 30px;
  letter-spacing: -0.01em;
  font-family: "Basier Circle Semibold";
}
@media (max-width: 992px) {
  .growth-stories .banner .loyaly_div .growth-stories_right_side h3 {
    font-size: 14px;
    margin: 0 0 12px;
    line-height: 20px;
  }
}
.growth-stories .banner .loyaly_div .growth-stories_right_side .growth-stories_points {
  gap: 12px;
  margin: 0 0 20px;
  display: flex;
  align-items: center;
}
.growth-stories .banner .loyaly_div .growth-stories_right_side .growth-stories_points .growthimg {
  min-height: 32px;
  min-width: 32px;
}
@media (max-width: 992px) {
  .growth-stories .banner .loyaly_div .growth-stories_right_side .growth-stories_points .growthimg {
    min-height: 20px;
    min-width: 20px;
  }
}
.growth-stories .banner .loyaly_div .growth-stories_right_side .growth-stories_points p {
  font-size: 20px;
  max-width: 465px;
  line-height: 26px;
  letter-spacing: -0.01em;
}
@media (max-width: 992px) {
  .growth-stories .banner .loyaly_div .growth-stories_right_side .growth-stories_points p {
    max-width: unset;
    font-size: 12px;
    line-height: 16px;
  }
}
.growth-stories .sec_title {
  font-size: 48px;
  line-height: 60px;
  letter-spacing: -0.02em;
  font-family: "Basier Circle Semibold";
}
@media (max-width: 992px) {
  .growth-stories .sec_title {
    font-size: 24px;
    line-height: 30px;
  }
}
.growth-stories .sec-heading {
  margin: 28px 0 12px;
}
@media (max-width: 992px) {
  .growth-stories .sec-heading {
    font-size: 16px;
    line-height: 22px;
  }
}
@media (max-width: 992px) {
  .growth-stories p.sec-heading {
    font-size: 14px;
    line-height: 20px;
    margin: 0;
  }
}
.growth-stories .common_title {
  font-size: 56px;
  margin: 0 0 16px;
  line-height: 62px;
  font-family: "Basier Circle medium";
}
.growth-stories .common_desc {
  font-size: 20px;
  margin: 0 0 40px;
  line-height: 30px;
  font-family: "Basier Circle";
}
.growth-stories .reelo_growthstories .blogBox {
  padding: 0 20px 30px;
}
@media (max-width: 992px) {
  .growth-stories .reelo_growthstories .blogBox {
    padding: 0 0 30px;
  }
}
.growth-stories .reelo_growthstories .blogBox .webinar-box-wrapper .webinar-box .webinar_Img iframe {
  height: 345px;
  outline: none;
  border: none;
}
@media (max-width: 992px) {
  .growth-stories .reelo_growthstories .blogBox .webinar-box-wrapper .webinar-box .webinar_Img iframe {
    height: 195px;
  }
}
.growth-stories .featured_brand {
  background-color: #f8fbfb;
}
.growth-stories .featured_brand .featured_wrapper {
  gap: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 992px) {
  .growth-stories .featured_brand .featured_wrapper {
    flex-wrap: wrap;
    gap: 16px;
  }
  .growth-stories .featured_brand .featured_wrapper img {
    width: 94px;
  }
}
.growth-stories .lastsection {
  border-radius: 24px;
  padding: 40px 20px;
}
@media (max-width: 992px) {
  .growth-stories .lastsection {
    margin: 0 20px;
  }
}

@media (max-width: 992px) {
  .press_release .pr1, .press_release .pr2 {
    width: 100px;
  }
}
.press_release .banner {
  background: #f5ffff;
  height: 70vh;
}
.press_release .banner .tagline_wrapper {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
.press_release .banner .tagline_wrapper .common_subtitle {
  margin: 0 0 8px;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.36px;
  font-family: "Basier Circle medium";
}
@media (max-width: 992px) {
  .press_release .banner .tagline_wrapper .common_subtitle {
    font-size: 16px;
    line-height: 20px;
  }
}
.press_release .banner .tagline_wrapper .common_title {
  font-size: 60px;
  margin: 0 0 18px;
  color: #0e1b1b;
  line-height: 64px;
  text-align: center;
  letter-spacing: -1.8px;
  font-family: "Basier Circle Semibold";
}
@media (max-width: 992px) {
  .press_release .banner .tagline_wrapper .common_title {
    font-size: 36px;
    line-height: 40px;
    letter-spacing: -1px;
  }
}
.press_release .banner .tagline_wrapper .common_desc {
  font-size: 24px;
  margin: 0 0 48px;
  color: #0e1b1b;
  line-height: 30px;
  text-align: center;
  letter-spacing: -0.72px;
  font-family: "Basier Circle medium";
}
@media (max-width: 992px) {
  .press_release .banner .tagline_wrapper .common_desc {
    font-size: 20px;
    line-height: 24px;
    margin: 0 auto 24px;
    letter-spacing: -0.52px;
  }
}
.press_release .sec_title {
  font-size: 42px;
  color: #0e1b1b;
  line-height: 48px;
  text-align: center;
  margin: 0 auto 48px;
  letter-spacing: -0.84px;
  font-weight: 500;
  font-family: "Basier Circle medium";
}
@media (max-width: 992px) {
  .press_release .sec_title {
    font-size: 28px;
    line-height: 32px;
  }
}
.press_release .p_wrapper a .press-box {
  padding: 0 20px;
}
@media (max-width: 992px) {
  .press_release .p_wrapper a .press-box {
    padding: 0;
  }
}
.press_release .p_wrapper a .press-box .press_Img img {
  border-radius: 16px;
}
.press_release .p_wrapper a .p_content {
  padding: 75px 0 0 20px;
}
@media (max-width: 992px) {
  .press_release .p_wrapper a .p_content {
    padding: 0;
  }
}
.press_release .p_wrapper a .sec-heading {
  font-size: 28px;
  margin: 28px 0 0;
  line-height: 34px;
  letter-spacing: -0.84px;
  font-family: "Basier Circle medium";
  font-weight: 500;
}
@media (max-width: 992px) {
  .press_release .p_wrapper a .sec-heading {
    font-size: 20px;
    margin: 16px 0 0;
    line-height: 24px;
    letter-spacing: -0.4px;
  }
}
.press_release .p_wrapper a:hover .sec-heading {
  color: #0d9298;
}
.press_release .reelo_pressRelease .press-box {
  padding: 20px;
  height: 470px;
}
@media (max-width: 992px) {
  .press_release .reelo_pressRelease .press-box {
    padding: 20px 0;
    height: unset;
  }
}
.press_release .reelo_pressRelease .press-box a .press_Img img {
  border-radius: 16px;
}
.press_release .reelo_pressRelease .press-box a .sec-heading {
  font-size: 28px;
  margin: 28px 0 0;
  line-height: 34px;
  letter-spacing: -0.84px;
  font-family: "Basier Circle medium";
  font-weight: 500;
}
@media (max-width: 992px) {
  .press_release .reelo_pressRelease .press-box a .sec-heading {
    font-size: 20px;
    margin: 16px 0 0;
    line-height: 24px;
    letter-spacing: -0.4px;
  }
}
.press_release .reelo_pressRelease .press-box:hover .sec-heading {
  color: #0d9298;
}
.press_release .lastsection {
  border-radius: 24px;
  padding: 40px 20px;
}
@media (max-width: 992px) {
  .press_release .lastsection {
    margin: 0 20px;
  }
}

@media (max-width: 992px) {
  .fresources_listing .category_frslider {
    display: flex;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    width: 100%;
    white-space: nowrap;
    scrollbar-width: thin;
    padding: 20px 0;
  }
}
@media (max-width: 992px) {
  .fresources_listing .category_frslider .category_frslider::-webkit-scrollbar {
    display: none;
  }
}
.fresources_listing .category_frslider li a {
  font-size: 16px;
}
.fresources_listing .latest_fr {
  margin: 60px 0 0;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  height: 499px;
}
@media (max-width: 992px) {
  .fresources_listing .latest_fr {
    flex-wrap: wrap;
    margin: 30px 0 0;
    height: unset;
    text-align: center;
  }
}
.fresources_listing .latest_fr .latest_frContent {
  max-width: 570px;
  margin: 0 auto 0 40px;
}
@media (max-width: 992px) {
  .fresources_listing .latest_fr .latest_frContent {
    max-width: 100%;
    padding: 20px;
    margin: 0;
  }
}
.fresources_listing .latest_fr .latest_frContent .latest_caption {
  color: #5F4D07;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  background: #EBBD07;
  padding: 8px 20px;
  border-radius: 90px;
}
@media (max-width: 992px) {
  .fresources_listing .latest_fr .latest_frContent .latest_caption {
    font-size: 14px;
    padding: 6px 15px;
  }
}
@media (max-width: 992px) {
  .fresources_listing .latest_fr .latest_frContent .sec_title {
    font-size: 24px;
    line-height: 28px;
  }
}
.fresources_listing .latest_fr .latest_frContent .sec_title .highlight {
  color: #ebbd07;
}
.fresources_listing .latest_fr .latest_frContent .frshort_description {
  padding: 20px 0;
}
.fresources_listing .latest_fr .latest_frImg {
  margin: 0;
}
.fresources_listing .latest_fr .latest_frImg .fr_latestImg img {
  min-height: 494px;
  position: relative;
  top: 2.5px;
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
}
@media (max-width: 992px) {
  .fresources_listing .latest_fr .latest_frImg .fr_latestImg img {
    border-top-right-radius: 0px;
    border-bottom-left-radius: 24px;
    min-height: unset;
    top: 6.5px;
  }
}
@media (max-width: 992px) {
  .fresources_listing .latest_fr .latest_frImg {
    max-width: 100%;
  }
}
@media (max-width: 992px) {
  .fresources_listing .r-center .sec-title {
    font-size: 28px;
    line-height: 32px;
  }
}
.fresources_listing .resource_fold {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0 40px;
}
@media (max-width: 992px) {
  .fresources_listing .resource_fold {
    display: block;
  }
}
.fresources_listing .resource_fold .resource_left_fold {
  width: 410px;
}
@media (max-width: 992px) {
  .fresources_listing .resource_fold .resource_left_fold {
    width: 100%;
  }
}
@media (max-width: 992px) {
  .fresources_listing .resource_fold .resource_right_fold {
    padding: 30px 0 0;
  }
}
.fresources_listing .fresources_wrapper .fresources_blogs .fresources {
  padding: 10px;
}
.fresources_listing .fresources_wrapper .fresources_blogs .fresources .fresources-box-wrapper .fresources-box {
  border-radius: 11px;
  height: 490px;
}
@media (max-width: 992px) {
  .fresources_listing .fresources_wrapper .fresources_blogs .fresources .fresources-box-wrapper .fresources-box {
    height: unset;
  }
}
.fresources_listing .fresources_wrapper .fresources_blogs .fresources .fresources-box-wrapper .fresources-box .fresources_Img .img-bg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 294px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
@media (max-width: 992px) {
  .fresources_listing .fresources_wrapper .fresources_blogs .fresources .fresources-box-wrapper .fresources-box .fresources_Img .img-bg {
    min-height: 180px;
  }
}
.fresources_listing .fresources_wrapper .fresources_blogs .fresources .fresources-box-wrapper .fresources-box .fresources-content {
  padding: 24px 0;
}
.fresources_listing .fresources_wrapper .fresources_blogs .fresources .fresources-box-wrapper .fresources-box .fresources-content .fcategory {
  font-size: 14px;
}
.fresources_listing .fresources_wrapper .fresources_blogs .fresources .fresources-box-wrapper .fresources-box .fresources-content .fresources-title {
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.64px;
  padding: 6px 0 15px;
  height: 74px;
}
@media (max-width: 992px) {
  .fresources_listing .fresources_wrapper .fresources_blogs .fresources .fresources-box-wrapper .fresources-box .fresources-content .fresources-title {
    font-size: 22px;
    line-height: 32px;
  }
}
.fresources_listing .fresources_wrapper .fresources_blogs .fresources .fresources-box-wrapper .fresources-box .fresources-content .f-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.fresources_listing .fresources_wrapper .fresources_blogs .fresources .fresources-box-wrapper .fresources-box .fresources-content .f-btn .pdf_text {
  color: #638A8A;
  font-size: 14px;
  font-weight: 600;
  line-height: 17.5px;
  border-radius: 90px;
  padding: 8px 20px;
  background: #A6DBDB;
  background-blend-mode: multiply;
}
.fresources_listing .fresources_wrapper .fresources_blogs .fresources .fresources-box-wrapper .fresources-box .fresources-content .f-btn .dwn_text {
  color: #252525;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: -0.323px;
  text-decoration: underline;
  font-family: "Basier Circle medium";
}
.fresources_listing .lastsection {
  border-radius: 24px;
  padding: 40px 20px;
}
@media (max-width: 992px) {
  .fresources_listing .lastsection {
    margin: 0 20px;
  }
}

.free_resources .box-bg {
  background-image: url("../images/box.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.free_resources .banner .tagline_wrapper {
  position: absolute;
  top: 54%;
  transform: translate(-50%, -50%);
  left: 50%;
  width: 880px;
}
@media (max-width: 992px) {
  .free_resources .banner .tagline_wrapper {
    width: 100%;
    padding: 0 15px;
  }
}
.free_resources .banner .tagline_wrapper .resource_title {
  font-size: 56px;
  margin: 0 0 16px;
  line-height: 62px;
  letter-spacing: -0.01em;
  font-family: "Basier Circle medium";
}
@media (max-width: 992px) {
  .free_resources .banner .tagline_wrapper .resource_title {
    font-size: 28px;
    line-height: 40px;
  }
}
.free_resources .banner .tagline_wrapper .resource_desc {
  font-size: 20px;
  color: #092d2c;
  line-height: 32px;
  margin: 0 auto 48px;
}
@media (max-width: 992px) {
  .free_resources .banner .tagline_wrapper .resource_desc {
    font-size: 16px;
    line-height: 24px;
  }
}
.free_resources .banner .marketingCalendar_tag {
  font-size: 18px;
  line-height: 24px;
  padding: 8px 20px;
  position: absolute;
  border-radius: 28px;
  font-family: "Basier Circle";
}
@media (max-width: 992px) {
  .free_resources .banner .marketingCalendar_tag {
    display: none;
  }
}
.free_resources .banner .marketingCalendar_tag-1 {
  top: 45%;
  left: 6%;
  background: #ffedd9;
  transform: rotate(-35.74deg);
}
.free_resources .banner .marketingCalendar_tag-2 {
  top: 53%;
  right: 6%;
  background: #fff3d3;
  transform: rotate(31.82deg);
}
.free_resources .banner .marketingCalendar_tag-3 {
  bottom: 26%;
  left: 12%;
  background: #f1f3ff;
  transform: rotate(-14.83deg);
}
.free_resources .banner .marketingCalendar_tag-4 {
  bottom: 20%;
  right: 12%;
  background: #ffe3e5;
  transform: rotate(15.52deg);
}
.free_resources .banner .marketing_fold {
  gap: 42px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 60px 0;
}
@media (max-width: 992px) {
  .free_resources .banner .marketing_fold {
    gap: 24px;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    padding: 30px 0 0;
    text-align: center;
  }
}
.free_resources .banner .marketing_fold .marketing_fold_left {
  max-width: 669px;
}
@media (max-width: 1024px) {
  .free_resources .banner .marketing_fold .marketing_fold_left {
    max-width: 550px;
  }
}
@media (max-width: 767px) {
  .free_resources .banner .marketing_fold .marketing_fold_left {
    max-width: 100%;
  }
}
.free_resources .banner .marketing_fold .marketing_fold_left .resource_subtitle {
  font-size: 16px;
  color: #0d9298;
  line-height: 21px;
  letter-spacing: 0.18em;
  text-transform: uppercase;
}
@media (max-width: 992px) {
  .free_resources .banner .marketing_fold .marketing_fold_left .resource_subtitle {
    font-size: 12px;
    line-height: 21px;
  }
}
.free_resources .banner .marketing_fold .marketing_fold_left .resource_title {
  font-size: 56px;
  color: #0e1b1b;
  line-height: 64px;
  margin: 16px 0 20px;
  letter-spacing: -0.01em;
  font-weight: 900;
  font-family: "Basier Circle medium";
}
@media (max-width: 992px) {
  .free_resources .banner .marketing_fold .marketing_fold_left .resource_title {
    font-size: 28px;
    line-height: 40px;
  }
}
.free_resources .banner .marketing_fold .marketing_fold_left .resource_desc {
  font-size: 20px;
  margin: 0 0 40px;
  color: #374040;
  line-height: 28px;
  font-family: "Basier Circle";
}
@media (max-width: 992px) {
  .free_resources .banner .marketing_fold .marketing_fold_left .resource_desc {
    font-size: 16px;
    line-height: 24px;
  }
}
.free_resources .banner .marketing_fold .marketing_fold_right {
  max-width: 440px;
}
.free_resources .banner .marketing_fold .marketing_fold_right img {
  border-radius: 24px;
}
@media (max-width: 992px) {
  .free_resources .banner .marketing_fold .marketing_fold_right {
    max-width: 100%;
  }
}
.free_resources .resource1 .resource_title {
  font-size: 42px;
  line-height: 60px;
  font-family: "Basier Circle medium";
}
@media (max-width: 992px) {
  .free_resources .resource1 .resource_title {
    font-size: 24px;
    line-height: 32px;
  }
}
.free_resources .resource1 .resource_desc p {
  font-size: 20px;
  line-height: 32px;
  margin: 16px 0;
}
@media (max-width: 992px) {
  .free_resources .resource1 .resource_desc p {
    font-size: 16px;
    line-height: 24px;
  }
}
.free_resources .resource1 .resource_desc p span {
  font-size: 16px;
  line-height: 20px;
}
.free_resources .resource1 .resource_desc ul {
  padding-left: 20px;
}
.free_resources .resource1 .resource_desc ul li {
  font-size: 20px;
  color: #fff;
  line-height: 32px;
  padding: 0 0 10px;
}
@media (max-width: 992px) {
  .free_resources .resource1 .resource_desc ul li {
    font-size: 16px;
    line-height: 24px;
  }
}
.free_resources .resource1 .resources_wrapper {
  gap: 104px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 992px) {
  .free_resources .resource1 .resources_wrapper {
    gap: 24px;
    flex-wrap: wrap;
  }
}
.free_resources .resource1 .resources_wrapper .resources_left {
  max-width: 627px;
}
@media (max-width: 992px) {
  .free_resources .resource1 .resources_wrapper .resources_left {
    max-width: 100%;
  }
}
@media (max-width: 992px) {
  .free_resources .resource1 .resources_wrapper .resources_left .resource_desc p {
    font-size: 16px;
    line-height: 24px;
  }
}
.free_resources .resource1 .resources_wrapper .resources_right {
  min-width: 360px;
}
@media (max-width: 992px) {
  .free_resources .resource1 .resources_wrapper .resources_right {
    min-width: 100%;
  }
}
.free_resources .resources_brand .resource_title {
  font-size: 30px;
  margin: 0 0 32px;
  color: #182020;
  line-height: 38px;
  font-weight: 600;
}
@media (max-width: 992px) {
  .free_resources .resources_brand .resource_title {
    font-size: 24px;
    line-height: 32px;
  }
}
.free_resources .resources_brand .marketingPlaybook_brands {
  gap: 56px;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 992px) {
  .free_resources .resources_brand .marketingPlaybook_brands {
    gap: 24px;
  }
  .free_resources .resources_brand .marketingPlaybook_brands img {
    width: 88px;
  }
}
.free_resources .resources_download .resource_title {
  font-size: 42px;
  color: #092d2c;
  line-height: 60px;
  text-align: center;
  margin: 0 auto 40px;
}
@media (max-width: 992px) {
  .free_resources .resources_download .resource_title {
    font-size: 24px;
    line-height: 32px;
  }
}
.free_resources .resources_download .download_my_resource {
  margin: 0 auto;
  max-width: 800px;
}
@media (max-width: 992px) {
  .free_resources .resources_download .download_my_resource {
    max-width: 100%;
  }
}
.free_resources .resources_download .marketingCalendar_tag {
  font-size: 18px;
  line-height: 24px;
  padding: 8px 20px;
  position: absolute;
  border-radius: 28px;
  font-family: "Basier Circle";
}
@media (max-width: 992px) {
  .free_resources .resources_download .marketingCalendar_tag {
    display: none;
  }
}
.free_resources .resources_download .marketingCalendar_tag-1 {
  top: 6%;
  left: 15%;
  background: #f9ffd6;
  transform: rotate(19.05deg);
}
.free_resources .resources_download .marketingCalendar_tag-2 {
  top: 6%;
  right: 12%;
  background: #fff3d3;
  transform: rotate(31.82deg);
}
.free_resources .resources_download .marketingCalendar_tag-3 {
  top: 34%;
  left: 9%;
  background: #ecfff9;
  transform: rotate(-24.29deg);
}
.free_resources .resources_download .marketingCalendar_tag-4 {
  top: 34%;
  right: 12%;
  background: #ffe3e5;
  transform: rotate(15.52deg);
}
.free_resources .resources_download .marketingCalendar_tag-5 {
  left: 24%;
  bottom: 20%;
  background: #e4ecff;
  transform: rotate(15.52deg);
}
.free_resources .resources_download .marketingCalendar_tag-6 {
  right: 15%;
  bottom: 20%;
  background: #ffe8f7;
  transform: rotate(15.52deg);
}

.report_resources .banner .bell {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
}
@media (max-width: 992px) {
  .report_resources .banner .bell {
    position: unset;
    transform: unset;
    margin: -34px 0 0;
  }
}
.report_resources .banner .marketing_fold {
  gap: 42px;
  display: flex;
  justify-content: space-between;
  padding: 40px 0 0;
}
@media (max-width: 992px) {
  .report_resources .banner .marketing_fold {
    gap: 24px;
    flex-wrap: wrap;
  }
}
.report_resources .banner .marketing_fold .marketing_fold_left {
  max-width: 669px;
  padding: 40px 0 0;
}
@media (max-width: 1024px) {
  .report_resources .banner .marketing_fold .marketing_fold_left {
    max-width: 550px;
  }
}
@media (max-width: 767px) {
  .report_resources .banner .marketing_fold .marketing_fold_left {
    max-width: 100%;
  }
}
.report_resources .banner .marketing_fold .marketing_fold_left .resource_subtitle {
  color: #0E1B1B;
  font-family: "Area Variable";
  font-size: 33.522px;
  font-style: normal;
  font-weight: 800;
  line-height: 40.705px;
  padding-top: 8px;
  letter-spacing: -0.335px;
  border-bottom: 2px solid rgba(1, 153, 159, 0.5882352941);
  display: inline-block;
}
@media (max-width: 992px) {
  .report_resources .banner .marketing_fold .marketing_fold_left .resource_subtitle {
    padding-top: 0;
    font-size: 20px;
    letter-spacing: -0.2px;
  }
}
.report_resources .banner .marketing_fold .marketing_fold_left .resource_title {
  color: #01999F;
  font-family: "Area Variable";
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
  line-height: 121%;
  letter-spacing: -0.56px;
}
@media (max-width: 992px) {
  .report_resources .banner .marketing_fold .marketing_fold_left .resource_title {
    font-size: 36px;
    letter-spacing: -0.36px;
  }
}
.report_resources .banner .marketing_fold .marketing_fold_left .resource_desc {
  color: #374040;
  font-family: "Area Variable";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  padding-top: 30px;
}
@media (max-width: 992px) {
  .report_resources .banner .marketing_fold .marketing_fold_left .resource_desc {
    font-size: 16px;
    padding-top: 20px;
  }
}
.report_resources .banner .marketing_fold .marketing_fold_right {
  min-width: 440px;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: -4px 8px 11px 0px rgba(0, 45, 50, 0.1);
  backdrop-filter: blur(18.1578960419px);
  padding: 32px;
  position: relative;
  z-index: 3;
}
.report_resources .banner .marketing_fold .marketing_fold_right .form_title {
  color: #023342;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 116.667% */
  letter-spacing: -0.48px;
  padding-bottom: 26px;
}
@media (max-width: 992px) {
  .report_resources .banner .marketing_fold .marketing_fold_right .form_title {
    color: #023342;
    font-size: 20.29px;
    font-style: normal;
    line-height: 23.671px; /* 116.667% */
    letter-spacing: -0.406px;
  }
}
@media (max-width: 992px) {
  .report_resources .banner .marketing_fold .marketing_fold_right {
    min-width: 100%;
    padding: 30px 20px;
  }
}
.report_resources .report-svg {
  position: absolute;
  top: -40px;
  left: 0;
  z-index: -2;
  width: 100%;
  height: 2800px;
}
@media (max-width: 1024px) {
  .report_resources .report-svg {
    top: -171px;
    height: 1900px;
  }
}
@media (max-width: 767px) {
  .report_resources .report-svg {
    top: -171px;
    height: 2400px;
  }
}
.report_resources .gradient-bg {
  margin: -250px 0 0;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg {
    margin: 0;
  }
}
.report_resources .gradient-bg .reportFold {
  margin: 280px 0 0;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold {
    margin: 0;
  }
}
.report_resources .gradient-bg .reportFold .report_title {
  color: #023342;
  font-family: "Area Variable";
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: 121%; /* 121.429% */
  letter-spacing: -0.56px;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold .report_title {
    font-size: 20px;
    letter-spacing: -0.2px;
  }
}
.report_resources .gradient-bg .reportFold .report_title span {
  font-family: "Area Variable";
  font-size: 96px;
  font-style: normal;
  font-weight: 900;
  line-height: 120%;
  letter-spacing: -0.96px;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold .report_title span {
    font-size: 30px;
    letter-spacing: -0.3px;
  }
}
.report_resources .gradient-bg .reportFold .ame-tabs-wrapper {
  padding: 25px 0 50px;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold .ame-tabs-wrapper {
    padding: 25px 0 30px;
    display: flex;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    width: 100%;
    white-space: nowrap;
    scrollbar-width: thin;
    position: relative;
    z-index: 1;
  }
}
.report_resources .gradient-bg .reportFold .ame-tabs-wrapper a {
  color: #fff;
  font-family: "Area Variable";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  border-radius: 90px;
  padding: 13px 20px 8px;
  margin: 0 20px 0 0;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: -4px 8px 11px 0px rgba(0, 45, 50, 0.1);
  backdrop-filter: blur(18.1578960419px);
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold .ame-tabs-wrapper a {
    font-size: 12px;
    padding: 7px 12px 5px;
    margin: 0 12px 0 0;
  }
}
.report_resources .gradient-bg .reportFold .ame-tabs-wrapper a.active {
  border-radius: 90px;
  box-shadow: -4px 8px 11px 0px rgba(0, 45, 50, 0.1);
  backdrop-filter: blur(18.1578960419px);
}
.report_resources .gradient-bg .reportFold .ame-tabs-wrapper a.active.activeTab1 {
  background: linear-gradient(31deg, #001055 12.54%, #286DF0 86.69%);
}
.report_resources .gradient-bg .reportFold .ame-tabs-wrapper a.active.activeTab2 {
  background: radial-gradient(79.93% 79.93% at 87.12% 41.59%, #957DFB 0%, #5237E6 100%);
}
.report_resources .gradient-bg .reportFold .ame-tabs-wrapper a.active.activeTab3 {
  background: radial-gradient(79.93% 79.93% at 87.12% 41.59%, #FFB02C 0%, #B66504 100%);
}
.report_resources .gradient-bg .reportFold .ame-tabs-wrapper a.active.activeTab4 {
  background: radial-gradient(79.93% 79.93% at 87.12% 41.59%, #EF4866 0%, #8B0010 100%), rgba(255, 255, 255, 0.1);
}
.report_resources .gradient-bg .reportFold .ame-tabs-wrapper a.active.activeTab5 {
  background: radial-gradient(79.93% 79.93% at 87.12% 41.59%, #079B15 0%, #0D5614 100%);
}
.report_resources .gradient-bg .reportFold .ame-wrap .slider-products .item {
  padding: 70px;
  border-radius: 40px;
  height: 560px !important;
}
@media (max-width: 1024px) {
  .report_resources .gradient-bg .reportFold .ame-wrap .slider-products .item {
    padding: 18px;
    border-radius: 12px;
    height: 250px !important;
  }
}
@media (max-width: 767px) {
  .report_resources .gradient-bg .reportFold .ame-wrap .slider-products .item {
    padding: 18px;
    border-radius: 12px;
    height: 180px !important;
  }
}
.report_resources .gradient-bg .reportFold .ame-wrap .slider-products .slick-dots {
  display: flex;
  justify-content: center;
  margin: 0;
  list-style-type: none;
  align-items: center;
  position: absolute;
  right: -45%;
  top: 50%;
  transform: translateY(-50%) rotate(90deg);
  padding: 0;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold .ame-wrap .slider-products .slick-dots {
    right: -40%;
  }
}
.report_resources .gradient-bg .reportFold .ame-wrap .slider-products .slick-dots button {
  display: block;
  width: 15px;
  height: 15px;
  padding: 0;
  background-color: #fff;
  border: 1px solid #fff !important;
  text-indent: -9999px;
  cursor: pointer;
  border-radius: 30px;
  border: unset;
  position: relative;
  margin: 0 8px;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold .ame-wrap .slider-products .slick-dots button {
    width: 8px;
    height: 8px;
  }
}
.report_resources .gradient-bg .reportFold .ame-wrap .slider-products li.slick-active button {
  width: 19px;
  height: 19px;
  background-color: #fff;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold .ame-wrap .slider-products li.slick-active button {
    width: 12px;
    height: 12px;
  }
}
.report_resources .gradient-bg .reportFold .ame-wrap .slider-products li.slick-active button:before {
  position: absolute;
  content: "";
  width: 27px;
  height: 27px;
  border-radius: 100%;
  border: 1px solid #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold .ame-wrap .slider-products li.slick-active button:before {
    width: 18px;
    height: 19px;
  }
}
.report_resources .gradient-bg .reportFold .ame-wrap .toptrend_slider .slides1 {
  background: #C5D9FF;
}
.report_resources .gradient-bg .reportFold .ame-wrap .toptrend_slider .slides2 {
  background: #3B72E2;
}
.report_resources .gradient-bg .reportFold .ame-wrap .toptrend_slider .slides2.top_trend .trend_title {
  color: #C5D9FF;
}
.report_resources .gradient-bg .reportFold .ame-wrap .toptrend_slider .top_trend .trend_title {
  color: #2058CB;
  font-family: "Area Variable";
  font-size: 70px;
  font-style: normal;
  font-weight: 900;
  line-height: 120%;
  letter-spacing: -0.7px;
  padding-bottom: 60px;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold .ame-wrap .toptrend_slider .top_trend .trend_title {
    font-size: 18px;
    letter-spacing: -0.22px;
    padding-bottom: 20px;
  }
}
.report_resources .gradient-bg .reportFold .ame-wrap .toptrend_slider .top_trend .trend_div .trend_point {
  display: flex;
  align-items: center;
  gap: 20px;
  padding-bottom: 50px;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold .ame-wrap .toptrend_slider .top_trend .trend_div .trend_point {
    padding-bottom: 15px;
  }
}
.report_resources .gradient-bg .reportFold .ame-wrap .toptrend_slider .top_trend .trend_div .trend_point .trend_name {
  color: #011055;
  font-size: 40px;
  font-style: normal;
  font-weight: 900;
  line-height: 120%; /* 48px */
  text-transform: uppercase;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold .ame-wrap .toptrend_slider .top_trend .trend_div .trend_point .trend_name {
    font-size: 12px;
  }
}
.report_resources .gradient-bg .reportFold .ame-wrap .toptrend_slider .top_trend .trend_div .trend_point .trend_desc {
  font-family: "Area Variable";
  font-size: 50px;
  font-style: normal;
  font-weight: 900;
  line-height: 120%;
  background: linear-gradient(31deg, #001055 12.54%, #286DF0 86.69%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold .ame-wrap .toptrend_slider .top_trend .trend_div .trend_point .trend_desc {
    font-size: 16px;
  }
}
.report_resources .gradient-bg .reportFold .ame-wrap .marketing_slider .slides1 {
  background: #DFD8FF;
}
.report_resources .gradient-bg .reportFold .ame-wrap .marketing_slider .slides2 {
  background: #957DFB;
  padding: 30px 70px !important;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold .ame-wrap .marketing_slider .slides2 {
    padding: 15px !important;
  }
}
.report_resources .gradient-bg .reportFold .ame-wrap .marketing_slider .percent {
  font-family: "Area Variable";
  font-size: 210px;
  font-style: normal;
  font-weight: 900;
  line-height: 110%;
  letter-spacing: -4.2px;
  padding-top: 20px;
  background: radial-gradient(79.93% 79.93% at 87.12% 41.59%, #957DFB 0%, #5237E6 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold .ame-wrap .marketing_slider .percent {
    font-size: 64px;
    letter-spacing: -1.289px;
    padding-top: 0;
  }
}
.report_resources .gradient-bg .reportFold .ame-wrap .marketing_slider .percent_desc {
  color: #957DFB;
  font-family: "Area Variable";
  font-size: 70px;
  font-style: normal;
  font-weight: 900;
  line-height: 120%;
  letter-spacing: -0.7px;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold .ame-wrap .marketing_slider .percent_desc {
    font-size: 21px;
    letter-spacing: -0.215px;
  }
}
.report_resources .gradient-bg .reportFold .ame-wrap .marketing_slider .marketingFlex {
  display: flex;
  align-items: flex-start;
  gap: 200px;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold .ame-wrap .marketing_slider .marketingFlex {
    gap: 42px;
  }
}
.report_resources .gradient-bg .reportFold .ame-wrap .marketing_slider .marketingFlex .marketing1 {
  padding: 20px 0 0;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold .ame-wrap .marketing_slider .marketingFlex .marketing1 {
    padding: 0;
  }
}
.report_resources .gradient-bg .reportFold .ame-wrap .marketing_slider .marketingFlex .marketing1 .marketing_title {
  color: #5237E6;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.4px;
  padding-bottom: 20px;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold .ame-wrap .marketing_slider .marketingFlex .marketing1 .marketing_title {
    font-size: 12px;
    letter-spacing: -0.123px;
  }
}
.report_resources .gradient-bg .reportFold .ame-wrap .marketing_slider .marketingFlex .marketing1 .marketing_desc {
  color: #DFD8FF;
  font-size: 70px;
  font-style: normal;
  font-weight: 900;
  line-height: 110%;
  letter-spacing: -0.7px;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold .ame-wrap .marketing_slider .marketingFlex .marketing1 .marketing_desc {
    font-size: 21px;
    letter-spacing: -0.215px;
  }
}
.report_resources .gradient-bg .reportFold .ame-wrap .marketing_slider .marketingFlex .marketing2 img {
  width: 420px;
}
@media (max-width: 1024px) {
  .report_resources .gradient-bg .reportFold .ame-wrap .marketing_slider .marketingFlex .marketing2 img {
    width: 190px;
  }
}
@media (max-width: 767px) {
  .report_resources .gradient-bg .reportFold .ame-wrap .marketing_slider .marketingFlex .marketing2 img {
    width: unset;
  }
}
.report_resources .gradient-bg .reportFold .ame-wrap .technology_slider .slides1 {
  padding: 100px 70px 70px;
  background: #FFD48D;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold .ame-wrap .technology_slider .slides1 {
    padding: 30px 15px 15px;
  }
}
.report_resources .gradient-bg .reportFold .ame-wrap .technology_slider .slides1 .technology1 {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  gap: 70px;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold .ame-wrap .technology_slider .slides1 .technology1 {
    gap: 20px;
  }
}
.report_resources .gradient-bg .reportFold .ame-wrap .technology_slider .slides1 .technology1 .tech_number {
  font-size: 210px;
  font-style: normal;
  font-weight: 900;
  line-height: 110%;
  letter-spacing: -4.2px;
  background: radial-gradient(79.93% 79.93% at 87.12% 41.59%, #FFB02C 0%, #B66504 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold .ame-wrap .technology_slider .slides1 .technology1 .tech_number {
    font-size: 64px;
    letter-spacing: -1.289px;
  }
}
.report_resources .gradient-bg .reportFold .ame-wrap .technology_slider .slides1 .technology2 .tech_desc {
  color: #AC6C00;
  font-size: 70px;
  font-style: normal;
  font-weight: 900;
  line-height: 110%;
  letter-spacing: -0.7px;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold .ame-wrap .technology_slider .slides1 .technology2 .tech_desc {
    font-size: 21px;
    letter-spacing: -0.215px;
  }
}
.report_resources .gradient-bg .reportFold .ame-wrap .technology_slider .slides2 {
  background: #EDA42A;
  padding: 120px 70px 70px;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold .ame-wrap .technology_slider .slides2 {
    padding: 45px 15px 15px;
  }
}
.report_resources .gradient-bg .reportFold .ame-wrap .technology_slider .slides2 .percent {
  background: radial-gradient(79.93% 79.93% at 87.12% 41.59%, #FDF2DF 0%, #FFD287 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 210px;
  font-style: normal;
  font-weight: 900;
  line-height: 110%;
  letter-spacing: -4.2px;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold .ame-wrap .technology_slider .slides2 .percent {
    font-size: 64px;
    letter-spacing: -1.289px;
  }
}
.report_resources .gradient-bg .reportFold .ame-wrap .technology_slider .slides2 .percent_desc {
  color: #FFE3B4;
  font-size: 70px;
  font-style: normal;
  font-weight: 900;
  line-height: 120%;
  letter-spacing: -0.7px;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold .ame-wrap .technology_slider .slides2 .percent_desc {
    font-size: 21px;
    letter-spacing: -0.215px;
  }
}
.report_resources .gradient-bg .reportFold .ame-wrap .technology_slider .slides2 .techcircle {
  position: absolute;
  top: 0;
  right: 0;
}
.report_resources .gradient-bg .reportFold .ame-wrap .loyalty_slider .slides1 {
  background: #FFCFD8;
  padding: 10px 70px !important;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold .ame-wrap .loyalty_slider .slides1 {
    padding: 15px !important;
  }
}
.report_resources .gradient-bg .reportFold .ame-wrap .loyalty_slider .slides1 .loyaltyFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.report_resources .gradient-bg .reportFold .ame-wrap .loyalty_slider .slides1 .loyaltyFlex .loyalty1 .loyalty_title {
  color: #70000B;
  font-size: 60px;
  font-style: normal;
  font-weight: 900;
  line-height: 120%; /* 72px */
  letter-spacing: -0.6px;
  padding-bottom: 20px;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold .ame-wrap .loyalty_slider .slides1 .loyaltyFlex .loyalty1 .loyalty_title {
    font-size: 18px;
    letter-spacing: -0.184px;
  }
}
.report_resources .gradient-bg .reportFold .ame-wrap .loyalty_slider .slides1 .loyaltyFlex .loyalty1 .loyalty_desc {
  font-size: 200px;
  font-style: normal;
  font-weight: 900;
  line-height: 110%; /* 220px */
  letter-spacing: -2px;
  background: radial-gradient(79.93% 79.93% at 87.12% 41.59%, #EF4866 0%, #8B0010 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold .ame-wrap .loyalty_slider .slides1 .loyaltyFlex .loyalty1 .loyalty_desc {
    font-size: 61.378px;
    letter-spacing: -0.614px;
  }
}
.report_resources .gradient-bg .reportFold .ame-wrap .loyalty_slider .slides1 .loyaltyFlex .loyalty2 img {
  position: relative;
  top: -35px;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold .ame-wrap .loyalty_slider .slides1 .loyaltyFlex .loyalty2 img {
    top: -13px;
  }
}
.report_resources .gradient-bg .reportFold .ame-wrap .loyalty_slider .slides2 {
  background: #C22A40;
  padding: 17px 70px !important;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold .ame-wrap .loyalty_slider .slides2 {
    padding: 8px 15px !important;
  }
}
.report_resources .gradient-bg .reportFold .ame-wrap .loyalty_slider .slides2 .loyaltyFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.report_resources .gradient-bg .reportFold .ame-wrap .loyalty_slider .slides2 .loyaltyFlex .loyalty1 .loyalty_title {
  color: #FFCFD8;
  font-size: 70px;
  font-style: normal;
  font-weight: 900;
  line-height: 120%; /* 72px */
  letter-spacing: -0.7px;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold .ame-wrap .loyalty_slider .slides2 .loyaltyFlex .loyalty1 .loyalty_title {
    font-size: 21px;
    letter-spacing: -0.215px;
  }
}
.report_resources .gradient-bg .reportFold .ame-wrap .feedback_slider .slides1 {
  background: #A7FFAF;
  padding: 40px 70px !important;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold .ame-wrap .feedback_slider .slides1 {
    padding: 15px !important;
  }
}
.report_resources .gradient-bg .reportFold .ame-wrap .feedback_slider .slides1 .technology1 {
  display: flex;
  align-items: baseline;
  gap: 30px;
}
.report_resources .gradient-bg .reportFold .ame-wrap .feedback_slider .slides1 .technology1 .tech_number {
  font-size: 208.084px;
  font-style: normal;
  font-weight: 900;
  line-height: 110%; /* 228.893px */
  letter-spacing: -4.162px;
  background: radial-gradient(79.93% 79.93% at 87.12% 41.59%, #079B15 0%, #0D5614 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold .ame-wrap .feedback_slider .slides1 .technology1 .tech_number {
    font-size: 63.886px;
    letter-spacing: -1.278px;
  }
}
.report_resources .gradient-bg .reportFold .ame-wrap .feedback_slider .slides1 .technology2 .tech_desc {
  color: #41BE4C;
  font-size: 70px;
  font-style: normal;
  font-weight: 900;
  line-height: 110%; /* 77px */
  letter-spacing: -0.7px;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold .ame-wrap .feedback_slider .slides1 .technology2 .tech_desc {
    font-size: 21.491px;
    letter-spacing: -0.215px;
  }
}
.report_resources .gradient-bg .reportFold .ame-wrap .feedback_slider .slides2 {
  background: #40BD4B;
  padding: 30px 70px !important;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold .ame-wrap .feedback_slider .slides2 {
    padding: 15px !important;
  }
}
.report_resources .gradient-bg .reportFold .ame-wrap .feedback_slider .slides2 .loyaltyFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.report_resources .gradient-bg .reportFold .ame-wrap .feedback_slider .slides2 .loyaltyFlex .loyalty1 .loyalty_title {
  color: #BDFFC3;
  font-size: 70px;
  font-style: normal;
  font-weight: 900;
  line-height: 120%; /* 84px */
  letter-spacing: -0.7px;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold .ame-wrap .feedback_slider .slides2 .loyaltyFlex .loyalty1 .loyalty_title {
    font-size: 19.491px;
    letter-spacing: -0.215px;
  }
}
.report_resources .gradient-bg .reportFold1 .report_title span {
  font-size: 96px;
  font-style: normal;
  font-weight: 900;
  line-height: 121%;
  letter-spacing: -0.96px;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold1 .report_title span {
    font-size: 30px;
  }
}
.report_resources .gradient-bg .reportFold1 .quoteFlex {
  display: flex;
  align-items: flex-start;
  padding: 10px 0;
  justify-content: flex-start;
  gap: 20px;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold1 .quoteFlex {
    display: block;
  }
}
.report_resources .gradient-bg .reportFold1 .quoteFlex .quoteFlexLeft {
  min-width: 600px;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold1 .quoteFlex .quoteFlexLeft {
    min-width: 100%;
  }
}
.report_resources .gradient-bg .reportFold1 .quoteFlex .quoteFlexRight {
  min-width: 596px;
  padding: 110px 0 0;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold1 .quoteFlex .quoteFlexRight {
    min-width: 100%;
    padding: 0;
  }
}
.report_resources .gradient-bg .reportFold1 .quotes {
  border-radius: 20px;
  padding: 30px;
  box-shadow: -1px 4px 10.1px 0px rgba(0, 45, 50, 0.1);
  backdrop-filter: blur(18.1578960419px);
  display: flex;
  align-items: flex-start;
  gap: 30px;
  margin: 20px 0;
  position: relative;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold1 .quotes {
    display: block;
    padding: 18px;
  }
}
.report_resources .gradient-bg .reportFold1 .quotes .basilio {
  min-width: 100px !important;
}
.report_resources .gradient-bg .reportFold1 .quotes .basilio .quotesimg {
  left: 75px;
}
.report_resources .gradient-bg .reportFold1 .quotes .quotes1 {
  min-width: 81px;
}
.report_resources .gradient-bg .reportFold1 .quotes .quotes1 .quotesimg {
  position: absolute;
  top: 13px;
  left: 55px;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold1 .quotes .quotes1 .quotesimg {
    top: 36px;
    left: 18px;
  }
}
.report_resources .gradient-bg .reportFold1 .quotes .quotes1 .quoteslogo {
  position: absolute;
  left: 30px;
  bottom: 30px;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold1 .quotes .quotes1 .quoteslogo {
    position: unset;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 10px;
    padding-bottom: 20px;
  }
  .report_resources .gradient-bg .reportFold1 .quotes .quotes1 .quoteslogo img {
    width: 54px;
  }
}
.report_resources .gradient-bg .reportFold1 .quotes .quotes1 .quoteslogo p {
  color: #170F49;
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Area Variable";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  padding: 5px 0 0;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold1 .quotes .quotes1 .quoteslogo p {
    font-size: 16px;
  }
}
.report_resources .gradient-bg .reportFold1 .quotes .quotes2 p {
  color: #303030;
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Area Normal";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
}
.report_resources .gradient-bg .reportFold1 .quote1 {
  background: #FFFCCE;
}
.report_resources .gradient-bg .reportFold1 .quote2 {
  background: #DEFFE0;
}
.report_resources .gradient-bg .reportFold1 .quote3 {
  background: #FFE2E2;
}
.report_resources .gradient-bg .reportFold1 .quote4 {
  background: #FFECDA;
}
.report_resources .resources_logo {
  padding: 230px 0 70px;
}
@media (max-width: 992px) {
  .report_resources .resources_logo {
    padding: 40px 0 70px;
  }
}
.report_resources .resources_logo .resource_title {
  color: #023342;
  font-family: "Area Variable";
  font-size: 40px;
  font-style: normal;
  font-weight: 900;
  line-height: 110%; /* 44px */
  letter-spacing: -0.4px;
}
@media (max-width: 992px) {
  .report_resources .resources_logo .resource_title {
    font-size: 20px;
  }
}
.report_resources .resources_logo .logo-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 18px;
  margin: 40px auto;
}
@media (max-width: 992px) {
  .report_resources .resources_logo .logo-container {
    grid-template-columns: repeat(3, 1fr);
  }
}
.report_resources .resources_logo .logo-container .logo-item {
  text-align: center;
  display: none;
}
.report_resources .resources_logo #see-more-btn {
  display: block;
  margin: 0px auto;
  color: #0D9298;
  font-feature-settings: "liga" off, "clig" off;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 100% */
  border: none;
  background: none;
  cursor: pointer;
}
.report_resources .resources_logo #see-more-btn.hidden {
  display: none;
}
.report_resources .lastsection {
  border-radius: 24px;
  padding: 40px 20px;
}
@media (max-width: 992px) {
  .report_resources .lastsection {
    margin: 0 20px;
  }
}

.testimonials .success_stories {
  gap: 40px;
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
}
@media (max-width: 992px) {
  .testimonials .success_stories {
    flex-wrap: wrap;
  }
}
.testimonials .success_stories .success_stories_left {
  max-width: 627px;
  padding: 90px 0 90px 84px;
}
@media (max-width: 992px) {
  .testimonials .success_stories .success_stories_left {
    padding: 40px 0 0;
    text-align: center;
  }
}
.testimonials .success_stories .success_stories_left .sec_title {
  font-size: 64px;
  color: #0e1b1b;
  line-height: 68px;
  margin: 16px 0 20px;
  letter-spacing: -0.01em;
  font-weight: 900;
  font-family: "Basier Circle medium";
}
@media (max-width: 992px) {
  .testimonials .success_stories .success_stories_left .sec_title {
    margin: 12px 0;
    font-size: 28px;
    line-height: 32px;
  }
}
.testimonials .success_stories .success_stories_left .sec_desc {
  font-size: 20px;
  margin: 0 0 40px;
  color: #374040;
  line-height: 30px;
}
@media (max-width: 992px) {
  .testimonials .success_stories .success_stories_left .sec_desc {
    font-size: 14px;
    margin: 0 0 24px;
    line-height: 19px;
  }
}
.testimonials .success_stories .success_stories_right {
  width: 100%;
  max-width: 790px;
}
.testimonials .success_stories .success_stories_right .parent_Img {
  width: 100%;
  height: 100%;
  position: relative;
  user-select: none;
}
@media (max-width: 992px) {
  .testimonials .success_stories .success_stories_right .parent_Img {
    overflow-x: auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    scrollbar-width: none;
    scroll-snap-type: x mandatory;
  }
}
@media (max-width: 992px) {
  .testimonials .success_stories .success_stories_right .parent_Img img {
    margin: 0 5px;
    min-width: 234px;
  }
}
.testimonials .testimonial_listing .common_title {
  font-size: 56px;
  margin: 0 0 60px;
  line-height: 64px;
  text-align: center;
  font-family: "Basier Circle medium";
  font-weight: 900;
}
@media (max-width: 992px) {
  .testimonials .testimonial_listing .common_title {
    margin: 12px 0;
    font-size: 28px;
    line-height: 32px;
  }
}
.testimonials .testimonial_listing .reelo_testimonials .middle-slide {
  margin: 40px 12px 0 !important;
}
@media (max-width: 992px) {
  .testimonials .testimonial_listing .reelo_testimonials .middle-slide {
    margin: 0 !important;
  }
}
.testimonials .testimonial_listing .reelo_testimonials .testimonialsBox {
  padding: 24px;
  position: relative;
  border-radius: 16px;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  background-image: url("../images/bg-shape.png");
  max-width: calc(33% - 20px);
  margin: 0 12px 55px;
  height: 580px;
}
@media (max-width: 992px) {
  .testimonials .testimonial_listing .reelo_testimonials .testimonialsBox {
    margin: 25px 0 !important;
    max-width: 100%;
    width: 100%;
    margin: 0 0 30px;
    height: 515px;
  }
}
.testimonials .testimonial_listing .reelo_testimonials .testimonialsBox:hover .testimonials-box-wrapper .testimonials_Img .video-thumbnail .play-icon-wrapper {
  transform: translate(-50%, -50%) scale(1.2);
}
.testimonials .testimonial_listing .reelo_testimonials .testimonialsBox .testimonials-box-wrapper .testimonials_Img .video-thumbnail .videoimg {
  height: 357px;
  border-radius: 16px;
}
@media (max-width: 992px) {
  .testimonials .testimonial_listing .reelo_testimonials .testimonialsBox .testimonials-box-wrapper .testimonials_Img .video-thumbnail .videoimg {
    height: 294px;
  }
}
.testimonials .testimonial_listing .reelo_testimonials .testimonialsBox .testimonials-box-wrapper .testimonials_Img .video-thumbnail .play-icon-wrapper {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%) scale(1);
  left: 50%;
  cursor: pointer;
  transition: all 0.5s;
  width: 48px;
  height: 48px;
  margin: 0 auto;
}
.testimonials .testimonial_listing .reelo_testimonials .testimonialsBox .testimonials-box-wrapper .testimonials_Img iframe {
  height: 357px;
  border: none;
  outline: none;
  border-radius: 24px;
}
@media (max-width: 992px) {
  .testimonials .testimonial_listing .reelo_testimonials .testimonialsBox .testimonials-box-wrapper .testimonials_Img iframe {
    height: 294px;
  }
}
.testimonials .testimonial_listing .reelo_testimonials .testimonialsBox .testimonials-box-wrapper .testimonial-title {
  font-size: 28px;
  margin: 24px 0 0;
  color: #01292b;
  line-height: 34px;
  letter-spacing: -0.01em;
  font-family: "Basier Circle Semibold";
}
@media (max-width: 992px) {
  .testimonials .testimonial_listing .reelo_testimonials .testimonialsBox .testimonials-box-wrapper .testimonial-title {
    font-size: 24px;
    line-height: 34px;
  }
}
.testimonials .testimonial_review_listing .common_title {
  font-size: 56px;
  margin: 0 0 60px;
  line-height: 64px;
  text-align: center;
  font-family: "Basier Circle medium";
  font-weight: 900;
  text-align: left;
}
@media (max-width: 992px) {
  .testimonials .testimonial_review_listing .common_title {
    margin: 12px 0;
    font-size: 28px;
    line-height: 32px;
    text-align: center;
  }
}
.testimonials .testimonial_review_listing .testiFlex {
  gap: 35px;
  width: auto;
  display: flex;
}
@media (max-width: 992px) {
  .testimonials .testimonial_review_listing .testiFlex {
    display: block;
    width: 100%;
  }
}
.testimonials .testimonial_review_listing .testiFlex .parent_testi {
  width: 25%;
}
@media (max-width: 992px) {
  .testimonials .testimonial_review_listing .testiFlex .parent_testi {
    display: none;
  }
}
.testimonials .testimonial_review_listing .testiFlex .testimonial_review {
  padding: 16px;
  border-radius: 12px;
  border: 1px solid #e3e3e3;
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.05), 0 0 1px rgba(0, 0, 0, 0.25);
  margin: 0 0 35px;
}
.testimonials .testimonial_review_listing .testiFlex .testimonial_review .review_detail {
  gap: 8px;
  margin: 0 0 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.testimonials .testimonial_review_listing .testiFlex .testimonial_review .review_detail .reviewer_name {
  margin: 0;
  font-size: 16px;
  color: #0e1b1b;
  line-height: 22px;
  font-family: "Basier Circle Semibold";
}
.testimonials .testimonial_review_listing .testiFlex .testimonial_review .review_detail .reviewer_post {
  margin: 0;
  font-size: 14px;
  color: #374040;
  line-height: 20px;
}
.testimonials .testimonial_review_listing .testiFlex .testimonial_review .review_desc {
  margin: 0;
  font-size: 16px;
  line-height: 22px;
}
@media (max-width: 992px) {
  .testimonials .testimonial_review_listing .testiFlex .testimonial_mobile {
    margin: 30px 0 0;
  }
  .testimonials .testimonial_review_listing .testiFlex .testimonial_mobile .testimonial_review {
    margin: 0 5px;
    height: 420px;
  }
}
.testimonials .lastsection {
  border-radius: 24px;
  padding: 40px 20px;
}
@media (max-width: 992px) {
  .testimonials .lastsection {
    margin: 0 20px;
  }
}

.affiliate-program .banner {
  height: 100vh;
}
@media (max-width: 992px) {
  .affiliate-program .banner {
    height: unset;
  }
}
.affiliate-program .banner .tagline_wrapper {
  text-align: center;
  padding: 30px 0 0;
}
@media (max-width: 992px) {
  .affiliate-program .banner .tagline_wrapper {
    padding: 50px 0 0;
  }
}
.affiliate-program .banner .tagline_wrapper .common_subtitle {
  font-size: 20px;
  color: #616d6d;
  line-height: 25px;
}
@media (max-width: 992px) {
  .affiliate-program .banner .tagline_wrapper .common_subtitle {
    font-size: 14px;
    line-height: 18px;
  }
}
.affiliate-program .banner .tagline_wrapper .banner_heading .common_title {
  margin: 0;
  font-size: 48px;
  color: #182020;
  line-height: 64px;
  font-family: "Basier Circle Semibold";
  position: relative;
  display: inline-block;
  margin: 20px 0;
}
@media (max-width: 992px) {
  .affiliate-program .banner .tagline_wrapper .banner_heading .common_title {
    font-size: 28px;
    line-height: 36px;
  }
}
.affiliate-program .banner .tagline_wrapper .banner_heading .common_title span {
  color: #8e44ad;
}
.affiliate-program .banner .tagline_wrapper .banner_heading .common_title .fill1 {
  position: absolute;
  left: -20px;
  top: -24px;
}
@media (max-width: 992px) {
  .affiliate-program .banner .tagline_wrapper .banner_heading .common_title .fill1 {
    width: 64px;
  }
}
.affiliate-program .banner .tagline_wrapper .banner_heading .common_title .fill2 {
  position: absolute;
  right: -20px;
  top: -24px;
}
@media (max-width: 992px) {
  .affiliate-program .banner .tagline_wrapper .banner_heading .common_title .fill2 {
    width: 64px;
  }
}
.affiliate-program .banner .tagline_wrapper .btn_wrapper {
  display: inline-block;
  margin: 30px 0 0;
}
@media (max-width: 992px) {
  .affiliate-program .banner .tagline_wrapper .btn_wrapper {
    margin: 30px 0 60px;
  }
}
.affiliate-program .banner .tagline_wrapper .btn_wrapper .fill3 {
  position: absolute;
  left: -72px;
  top: -34px;
}
.affiliate-program .banner .tagline_wrapper .btn_wrapper .fill4 {
  position: absolute;
  right: -72px;
  top: -18px;
}
.affiliate-program .banner .tagline_wrapper .btn_wrapper .black-btn {
  padding: 20px 94px;
  font-size: 22px !important;
}
@media (max-width: 992px) {
  .affiliate-program .banner .tagline_wrapper .btn_wrapper .black-btn {
    padding: 12px 30px;
    font-size: 16px !important;
  }
}
.affiliate-program .sec_title {
  font-size: 40px;
  color: #182020;
  margin: 0 0 48px;
  line-height: 50px;
  text-align: center;
}
@media (max-width: 992px) {
  .affiliate-program .sec_title {
    font-size: 20px;
    line-height: 25px;
    margin: 0 0 20px;
  }
}
.affiliate-program .range_wrapper {
  padding: 200px 0 70px;
}
@media (max-width: 992px) {
  .affiliate-program .range_wrapper {
    padding: 40px 0;
  }
}
.affiliate-program .range_wrapper .affiliate_span1 {
  font-size: 20px;
  color: #616d6d;
  line-height: 25px;
}
@media (max-width: 992px) {
  .affiliate-program .range_wrapper .affiliate_span1 {
    font-size: 14px;
    line-height: 18px;
  }
}
.affiliate-program .range_wrapper .output {
  padding: 0 20px;
  font-size: 48px;
  color: #182020;
  line-height: 60px;
  text-align: center;
  margin: 8px auto 40px;
}
@media (max-width: 992px) {
  .affiliate-program .range_wrapper .output {
    font-size: 32px;
    line-height: 40px;
  }
}
.affiliate-program .range_wrapper .affiliate_range_slider_div {
  margin: 0 auto;
  padding: 0 40px;
  max-width: 1120px;
}
.affiliate-program .range_wrapper .affiliate_range_slider_div .custom_range_slider {
  position: relative;
  width: 100%;
  height: 20px;
}
.affiliate-program .range_wrapper .affiliate_range_slider_div .custom_range_slider .custom_range_slider_input {
  width: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
.affiliate-program .range_wrapper .affiliate_range_slider_div .custom_range_slider .custom_range_slider_track {
  height: 10px;
  border-radius: 5px;
  position: relative;
  width: 100%;
}
.affiliate-program .range_wrapper .affiliate_range_slider_div .custom_range_slider .custom_range_slider_track .custom_range_slider_thumb {
  width: 48px;
  height: 48px;
  padding: 4px;
  border-radius: 50%;
  background-color: #fff;
  border: 4px solid #1a424f;
}
.affiliate-program .range_wrapper .affiliate_range_slider_div .affiliate_track_numbers {
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.affiliate-program .range_wrapper .affiliate_range_slider_div .affiliate_track_numbers span {
  font-size: 24px;
  line-height: 30px;
  color: #182020;
}
.affiliate-program .affiliate_how_it_works {
  background: #d4e9f7;
}
.affiliate-program .affiliate_how_it_works .affiliate_how_it_works_cards {
  gap: 40px;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 992px) {
  .affiliate-program .affiliate_how_it_works .affiliate_how_it_works_cards {
    gap: 25px;
  }
}
.affiliate-program .affiliate_how_it_works .affiliate_how_it_works_cards .affiliate_how_it_works_card {
  padding: 32px 30px;
  max-width: 346px;
  border-radius: 12px;
  background: #fff;
}
.affiliate-program .affiliate_how_it_works .affiliate_how_it_works_cards .affiliate_how_it_works_card .card_header {
  margin: 24px 0;
  font-size: 24px;
  color: #182020;
  line-height: 30px;
  font-weight: 600;
}
@media (max-width: 992px) {
  .affiliate-program .affiliate_how_it_works .affiliate_how_it_works_cards .affiliate_how_it_works_card .card_header {
    font-size: 20px;
    line-height: 25px;
  }
}
.affiliate-program .affiliate_how_it_works .affiliate_how_it_works_cards .affiliate_how_it_works_card .card_subheader {
  margin: 0;
  font-size: 20px;
  color: #616d6d;
  line-height: 25px;
}
@media (max-width: 992px) {
  .affiliate-program .affiliate_how_it_works .affiliate_how_it_works_cards .affiliate_how_it_works_card .card_subheader {
    font-size: 16px;
    line-height: 20px;
  }
}
.affiliate-program .affiliate_why_reelo .affiliate_reviews {
  gap: 40px;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 992px) {
  .affiliate-program .affiliate_why_reelo .affiliate_reviews {
    gap: 16px;
    overflow-x: auto;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    scroll-snap-type: x mandatory;
  }
}
.affiliate-program .affiliate_why_reelo .affiliate_reviews .affiliate_review_div {
  width: 346px;
  height: 380px;
  padding: 32px;
  min-width: 240px;
  border-radius: 12px;
  background: #fafafa;
}
@media (max-width: 992px) {
  .affiliate-program .affiliate_why_reelo .affiliate_reviews .affiliate_review_div {
    height: 270px;
    padding: 20px;
  }
}
.affiliate-program .affiliate_why_reelo .affiliate_reviews .affiliate_review_div .card_review {
  margin: 24px 0;
  font-size: 24px;
  color: #182020;
  line-height: 28px;
  font-weight: 400;
}
@media (max-width: 992px) {
  .affiliate-program .affiliate_why_reelo .affiliate_reviews .affiliate_review_div .card_review {
    font-size: 16px;
    line-height: 18px;
  }
}
.affiliate-program .affiliate_why_reelo .affiliate_reviews .affiliate_review_div .card_reviewer {
  margin: 0;
  font-size: 16px;
  color: #182020;
  line-height: 20px;
}
@media (max-width: 992px) {
  .affiliate-program .affiliate_why_reelo .affiliate_reviews .affiliate_review_div .card_reviewer {
    font-size: 14px;
    line-height: 18px;
  }
}
.affiliate-program .affiliate_why_reelo .affiliate_reviews .affiliate_review_div .card_reviewer span {
  color: #616d6d;
}
.affiliate-program .resources_brand .marketingPlaybook_brands {
  gap: 56px;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
}

.demo .display_table {
  flex-direction: unset;
}
.demo .banner {
  padding: 100px 0 70px;
}
@media (max-width: 992px) {
  .demo .banner {
    height: unset;
    padding: 90px 0 0;
  }
}
.demo .banner .demo_right_fold {
  width: 100%;
  padding: 24px;
  max-width: 525px;
  min-width: 525px;
  min-height: 544px;
  border-radius: 12px;
  background: #f7f8f8;
  margin: 0 auto;
}
@media (max-width: 992px) {
  .demo .banner .demo_right_fold {
    padding: 16px;
    min-width: unset;
    border-radius: 8px;
    margin: 20px auto 40px;
  }
}
.demo .banner .demo_right_fold p {
  color: #000;
  font-size: 18px;
  margin: 0 0 24px;
  line-height: 28px;
  letter-spacing: -0.18px;
  font-family: "Basier Circle medium";
}
@media (max-width: 992px) {
  .demo .banner .demo_right_fold p {
    font-size: 16px;
    line-height: 20px;
  }
}
.demo .sec_title {
  font-size: 64px;
  line-height: 62px;
  text-align: center;
  margin: 0 auto 24px;
  letter-spacing: -2.56px;
  font-family: "Basier Circle medium";
  color: #0e1b1b;
  font-weight: 500;
}
@media (max-width: 992px) {
  .demo .sec_title {
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    margin-bottom: 18px;
    letter-spacing: -1.2px;
  }
}
.demo .sec_desc {
  font-size: 18px;
  color: #697777;
  line-height: 26px;
  text-align: center;
  margin: 0 auto 32px;
  letter-spacing: -0.18px;
  font-family: "Basier Circle";
}
@media (max-width: 992px) {
  .demo .sec_desc {
    font-size: 16px;
    margin: 0 0 16px;
    line-height: 20px;
    text-align: center;
  }
}
.demo .common_title {
  font-size: 64px;
  color: #182020;
  line-height: 65px;
  margin-bottom: 18px;
  letter-spacing: -2.4px;
  font-family: "Basier Circle";
}
@media (max-width: 992px) {
  .demo .common_title {
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    margin-bottom: 18px;
    letter-spacing: -1.2px;
  }
}
.demo .common_desc {
  margin: 0;
  font-size: 18px;
  color: #697777;
  line-height: 28px;
  letter-spacing: -0.18px;
  font-family: "Basier Circle";
}
@media (max-width: 992px) {
  .demo .common_desc {
    font-size: 16px;
    margin: 0 0 16px;
    line-height: 20px;
    text-align: center;
  }
}
.demo .fold2 {
  background-color: #f8f8f8;
}
.demo .fold2 .tagline_wraper:hover .quotes_Img .video-thumbnail .play-icon-wrapper {
  transform: translate(-50%, -50%) scale(1.2);
  box-shadow: 0 20px 30px -10px transparent;
}
.demo .fold2 .tagline_wraper .quotes_Img {
  margin: 50px 0 0;
}
.demo .fold2 .tagline_wraper .quotes_Img .video-thumbnail .top-gradient {
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  width: 100%;
  height: 80px;
  padding: 24px;
  position: absolute;
  border-radius: 16px 16px 0 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.6), transparent);
}
.demo .fold2 .tagline_wraper .quotes_Img .video-thumbnail .top-gradient p {
  z-index: 1;
  font-size: 18px;
  text-align: center;
  line-height: 22px;
  overflow: hidden;
  white-space: nowrap;
}
.demo .fold2 .tagline_wraper .quotes_Img .video-thumbnail .videoimg {
  border-radius: 24px;
}
.demo .fold2 .tagline_wraper .quotes_Img .video-thumbnail .play-icon-wrapper {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%) scale(1);
  left: 50%;
  cursor: pointer;
  transition: all 0.3s;
}
.demo .fold2 .tagline_wraper .quotes_Img iframe {
  height: 709px;
  border: none;
  outline: none;
  border-radius: 24px;
}
@media (max-width: 992px) {
  .demo .fold2 .tagline_wraper .quotes_Img iframe {
    height: 189px;
  }
}
.demo .fold3 .demo_videos_row {
  gap: 24px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 60px 0 20px;
}
@media (max-width: 992px) {
  .demo .fold3 .demo_videos_row {
    gap: 16px;
    padding: 0 16px;
    overflow-x: auto;
    justify-content: flex-start;
    scrollbar-width: none;
    scroll-snap-type: x mandatory;
  }
}
.demo .fold3 .demo_videos_row .demo_video_col {
  width: 100%;
  height: 100%;
  position: relative;
}
@media (max-width: 992px) {
  .demo .fold3 .demo_videos_row .demo_video_col {
    min-width: 250px;
    scroll-snap-align: center;
  }
}
.demo .fold3 .demo_videos_row .demo_video_col:hover .video-thumbnail .play-icon-wrapper {
  transform: translate(-50%, -50%) scale(1.2);
  box-shadow: 0 20px 30px -10px transparent;
}
.demo .fold3 .demo_videos_row .demo_video_col .video-thumbnail .videoimg {
  border-radius: 24px;
}
.demo .fold3 .demo_videos_row .demo_video_col .video-thumbnail .play-icon-wrapper {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%) scale(1);
  left: 50%;
  cursor: pointer;
  transition: all 0.3s;
}
.demo .fold3 .demo_videos_row .demo_video_col iframe {
  height: 528px;
  border: none;
  outline: none;
  position: relative;
  border-radius: 12px;
}
@media (max-width: 992px) {
  .demo .fold3 .demo_videos_row .demo_video_col iframe {
    height: 444px;
  }
}

.loyalty-inspiration .banner {
  padding: 50px 20px 0;
}
.loyalty-inspiration .banner .common_title {
  color: #354545;
  font-weight: 600;
  margin: 70px 0 30px;
  font-size: 56px;
  line-height: 62px;
  letter-spacing: -0.01em;
  font-family: "Basier Circle medium";
}
@media (max-width: 992px) {
  .loyalty-inspiration .banner .common_title {
    margin: 40px auto 20px;
    font-size: 32px;
    line-height: 42px;
  }
}
.loyalty-inspiration .loyaltyDropdown {
  height: 40px;
  color: #a8b4b4;
  border-radius: 10px;
  background-color: #fff;
  border: 1px solid #a8b4b4;
  width: 96%;
  font-size: 15px;
}
.loyalty-inspiration .loyaltyDropdown option {
  color: #000 !important;
  background-color: #ebebeb;
  font-size: 16px;
  line-height: 32px;
  font-family: "Basier Circle";
  margin: 10px 0;
}
.loyalty-inspiration .loyaltyDropdown option:hover {
  color: #000;
}
.loyalty-inspiration .inspiration_wrapper {
  margin: 60px 0 0;
}
.loyalty-inspiration .inspiration_wrapper .my-masonry-grid {
  display: flex;
  flex-wrap: wrap;
  margin-left: -30px;
  width: auto;
}
@media (max-width: 992px) {
  .loyalty-inspiration .inspiration_wrapper .my-masonry-grid {
    flex-direction: column;
  }
}
.loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column > div {
  margin-bottom: 30px;
}
.loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column {
  padding-left: 30px;
  background-clip: padding-box;
  width: 33.3333%;
}
@media (max-width: 992px) {
  .loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column {
    width: 100%;
  }
}
.loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column .root-loyalty-card {
  filter: drop-shadow(5px 6px 20px rgba(96, 96, 96, 0.31));
}
.loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column .root-loyalty-card .final-preview-card {
  max-width: 414px;
  height: max-content;
  padding-bottom: 25px;
  border-radius: 15px;
  position: relative;
  margin: 0 auto;
}
@media (max-width: 992px) {
  .loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column .root-loyalty-card .final-preview-card {
    width: 100%;
  }
}
.loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column .root-loyalty-card .final-preview-card .card-header {
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 250px;
  padding: 0;
  overflow: hidden;
  position: relative;
  border-radius: 15px;
  -webkit-clip-path: ellipse(67% 88% at 81% 1%);
  clip-path: ellipse(67% 88% at 81% 1%);
}
.loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column .root-loyalty-card .final-preview-card .card-header .react-transform-wrapper {
  width: 100%;
  height: 250px;
}
.loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column .root-loyalty-card .final-preview-card .card-header .react-transform-wrapper .transform-component-module_content {
  display: flex;
  flex-wrap: wrap;
  width: fit-content;
  height: fit-content;
  margin: 0;
  padding: 0;
  transform-origin: 0% 0%;
}
.loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column .root-loyalty-card .final-preview-card .card-header .react-transform-wrapper .transform-component-module_content img {
  width: 100%;
  margin: 0 0 0 auto;
  pointer-events: none;
}
.loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column .root-loyalty-card .final-preview-card .card-logo-div {
  top: 100px;
  left: 45px;
  z-index: 10;
  width: 124px;
  height: 124px;
  padding: 2.4px;
  position: absolute;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column .root-loyalty-card .final-preview-card .card-logo-div .card-logo-image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}
.loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column .root-loyalty-card .final-preview-card .card-logo-div .card-logo-name {
  font-size: 14px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}
.loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column .root-loyalty-card .final-preview-card .card-content {
  padding: 0 25px;
}
.loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column .root-loyalty-card .final-preview-card .card-content h3 {
  padding: 0 25px;
  font-size: 36px;
  line-height: 40px;
}
@media (max-width: 992px) {
  .loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column .root-loyalty-card .final-preview-card .card-content h3 {
    font-size: 26px;
    line-height: 30px;
  }
}
.loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column .root-loyalty-card .final-preview-card .card-content h4 {
  padding: 0 25px;
  font-size: 18px;
  line-height: 22px;
}
@media (max-width: 992px) {
  .loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column .root-loyalty-card .final-preview-card .card-content h4 {
    font-size: 16px;
    line-height: 20px;
  }
}
.loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column .root-loyalty-card .final-preview-card .card-content h5 {
  padding: 0 25px;
  font-size: 24px;
  line-height: 20px;
  margin-top: 20px;
  margin-bottom: 30px;
  position: relative;
}
@media (max-width: 992px) {
  .loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column .root-loyalty-card .final-preview-card .card-content h5 {
    font-size: 16px;
    line-height: 20px;
  }
}
.loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column .root-loyalty-card .final-preview-card .card-content .cashback-content {
  padding: 0 25px;
  position: relative;
}
.loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column .root-loyalty-card .final-preview-card .card-content .cashback-content p {
  font-size: 16px;
  line-height: 20px;
  margin: 16px 0 0;
}
.loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column .root-loyalty-card .final-preview-card .card-content .cashback-content h2 {
  margin: 0;
  font-size: 64px;
  line-height: 70px;
  text-align: left;
  font-weight: 600;
}
.loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column .root-loyalty-card .final-preview-card .card-content .cashback-content h3 {
  padding: 0;
  margin: 0 0 4px;
  font-size: 24px;
  line-height: 30px;
  font-weight: 400;
}
.loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column .root-loyalty-card .final-preview-card .card-content .cashback-content h5 {
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
}
.loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column .root-loyalty-card .final-preview-card .card-content .reward-row:last-child {
  border: none;
}
.loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column .root-loyalty-card .final-preview-card .card-content .reward-row {
  display: flex;
  margin-bottom: 10px;
  padding-bottom: 10px;
  align-items: center;
  position: relative;
  border-bottom: 1px dashed #fff;
}
.loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column .root-loyalty-card .final-preview-card .card-content .reward-row h4 {
  font-size: 32px;
  line-height: 32px;
  min-width: 150px;
  max-width: 150px;
  margin: 0;
  font-weight: 700;
}
.loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column .root-loyalty-card .final-preview-card .card-content .reward-row h4 span {
  font-size: 14px;
}
.loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column .root-loyalty-card .final-preview-card .card-content .reward-row p {
  text-align: left;
  padding-left: 10px;
  font-size: 16px;
}
.loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column .root-loyalty-card .final-preview-card .preview-footer {
  margin: 15px 25px 0;
  padding: 13px 15px;
  border-radius: 10px;
  position: relative;
  border: 1px dashed #fff;
}
.loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column .root-loyalty-card .final-preview-card .preview-footer h3 {
  font-size: 10px;
  line-height: 12.5px;
}
.loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column .root-loyalty-card .final-preview-card .preview-footer p {
  margin: 0;
  font-size: 10px;
  line-height: 12.5px;
}
.loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column .root-loyalty-card .cashback-terms {
  padding: 25px;
  margin: 0 auto;
  max-width: 414px;
  position: relative;
  border-radius: 15px;
  background-color: #fff;
}
.loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column .root-loyalty-card .cashback-terms h4 {
  font-size: 16px;
  color: #545454;
  line-height: 20px;
  margin: 0 16px 16px;
}
.loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column .root-loyalty-card .cashback-terms .preview-footer {
  padding: 13px 15px;
  border-radius: 10px;
  position: relative;
  border: 1px dashed #354545;
}
.loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column .root-loyalty-card .cashback-terms .preview-footer h3 {
  font-size: 10px;
  line-height: 12.5px;
  margin-bottom: 0.5rem;
  font-weight: 600;
}
.loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column .root-loyalty-card .cashback-terms .preview-footer p {
  margin: 0;
  font-size: 10px;
  line-height: 12.5px;
}
.loyalty-inspiration .fold2 .inspiration_flexrow {
  margin: 0 auto;
  max-width: 1024px;
  padding: 0 20px 50px;
}
@media (max-width: 992px) {
  .loyalty-inspiration .fold2 .inspiration_flexrow {
    padding: 0;
  }
}
.loyalty-inspiration .fold2 .inspiration_flexrow .inspiration_custom-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 60px;
}
@media (max-width: 992px) {
  .loyalty-inspiration .fold2 .inspiration_flexrow .inspiration_custom-row {
    flex-direction: column;
  }
}
.loyalty-inspiration .fold2 .inspiration_flexrow .inspiration_custom-row .inspiration_custom-col .inspiration_custom-col_left {
  max-width: 500px;
}
.loyalty-inspiration .fold2 .inspiration_flexrow .inspiration_custom-row .inspiration_custom-col .inspiration_custom-col_left h3 {
  font-size: 24px;
  color: #354545;
  line-height: 31px;
  margin: 40px 0 20px;
  font-weight: 600;
}
.loyalty-inspiration .fold2 .inspiration_flexrow .inspiration_custom-row .inspiration_custom-col .inspiration_custom-col_left ul {
  padding-left: 20px;
}
.loyalty-inspiration .fold2 .inspiration_flexrow .inspiration_custom-row .inspiration_custom-col .inspiration_custom-col_left ul li {
  font-size: 16px;
  color: #354545;
  line-height: 24px;
  font-weight: 400;
  padding: 0 0 12px;
}
.loyalty-inspiration .fold2 .inspiration_flexrow .inspiration_custom-row .inspiration_custom-col .inspiration_custom-col_right {
  max-width: 500px;
}
.loyalty-inspiration .fold2 .inspiration_flexrow .inspiration_custom-row .inspiration_custom-col .inspiration_custom-col_right h3 {
  font-size: 24px;
  color: #354545;
  line-height: 31px;
  margin: 40px 0 20px;
  font-weight: 600;
}
.loyalty-inspiration .fold2 .inspiration_flexrow .inspiration_custom-row .inspiration_custom-col .inspiration_custom-col_right p {
  font-size: 16px;
  color: #354545;
  line-height: 24px;
  font-weight: 400;
  padding: 0 0 12px;
}
@media (max-width: 992px) {
  .loyalty-inspiration .fold2 .inspiration_flexrow .row_2 {
    flex-direction: column-reverse;
  }
}
@media (max-width: 992px) {
  .loyalty-inspiration #lastsection {
    height: 322px;
  }
}

.faq .sec_title {
  font-size: 42px;
  line-height: 51px;
  letter-spacing: -0.02em;
  font-weight: 500;
  font-family: "Basier Circle medium";
  margin-bottom: 50px;
}
@media (max-width: 992px) {
  .faq .sec_title {
    margin-bottom: 25px;
    font-size: 24px;
    line-height: 28px;
  }
}
.faq .set {
  position: relative;
  width: 100%;
  height: auto;
  margin: auto;
}
.faq .set a {
  border-radius: 6px;
  display: block;
  padding: 16px;
  text-decoration: none;
  transition: all 0.2s linear;
  margin: 10px 0 0;
  font-size: 16px;
  color: #182020;
  line-height: 19px;
  background: #fafafa;
  font-weight: 600;
  position: relative;
  height: auto;
}
.faq .set a i {
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 100%;
  transition: transform 0.2s ease-in-out;
}
@media (max-width: 992px) {
  .faq .set a i {
    right: 18px;
  }
}
.faq .set a .icon-minus:after {
  width: 20px;
  height: 20px;
  background-image: url("../images/down.svg");
  background-repeat: no-repeat;
  top: 8px;
  position: absolute;
  content: "";
  top: 50%;
  transform: translate(-50%, -50%) rotate(180deg);
  text-align: center;
  transition: transform 0.2s ease-in-out;
  left: 50%;
}
.faq .set a .icon-plus:before {
  position: absolute;
  content: "";
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  left: 50%;
  background-image: url("../images/down.svg");
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  transition: transform 0.2s ease-in-out;
}
.faq .set .faqs_content {
  background-color: #fafafa;
  border-top: 1px solid rgba(128, 128, 128, 0.1803921569);
  padding: 20px;
}
.faq .set .faqs_content a {
  display: inline-block;
  padding: 0;
  margin: 0;
}
.faq .set .faqs_content ul {
  padding: 10px 20px;
}
.faq .set .faqs_content ul li {
  padding: 0 0 6px;
}
.faq .set .faqs_content ul li a {
  display: inline-block;
  padding: 0;
}
.faq .set .faqs_content ol {
  padding: 10px 20px;
}
.faq .set .faqs_content ol li {
  padding: 0 0 6px;
}
.faq .set .faqs_content ol li a {
  display: inline-block;
  padding: 0;
}

.webinar-registration .today {
  padding: 90px 0 40px;
}
@media (max-width: 992px) {
  .webinar-registration .today {
    padding: 90px 0 0;
  }
}
.webinar-registration .loyalty_first_fold {
  gap: 24px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
@media (max-width: 992px) {
  .webinar-registration .loyalty_first_fold {
    flex-wrap: wrap;
  }
}
.webinar-registration .loyalty_first_fold .loyalty_left_side {
  max-width: 620px;
  margin: -10px 0 0;
}
@media (max-width: 992px) {
  .webinar-registration .loyalty_first_fold .loyalty_left_side {
    margin: 0;
  }
}
.webinar-registration .loyalty_first_fold .loyalty_left_side .speakerFlex {
  display: flex;
  align-items: center;
  gap: 30px;
  margin: 37px 0;
}
@media (max-width: 992px) {
  .webinar-registration .loyalty_first_fold .loyalty_left_side .speakerFlex {
    flex-wrap: wrap;
  }
}
.webinar-registration .loyalty_first_fold .loyalty_left_side .speakerFlex .speaker_content {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  position: relative;
}
.webinar-registration .loyalty_first_fold .loyalty_left_side .speakerFlex .speaker_content .speakerImg {
  border-radius: 6px;
  background: #C7E1FF;
}
.webinar-registration .loyalty_first_fold .loyalty_left_side .speakerFlex .speaker_content .speakers .speaker_name {
  font-size: 20px;
  line-height: 26px;
  font-weight: 600;
}
.webinar-registration .loyalty_first_fold .loyalty_left_side .speakerFlex .speaker_content .speakers .speaker_dir {
  font-size: 16px;
  line-height: 18px;
}
.webinar-registration .loyalty_first_fold .loyalty_left_side .speakerFlex .speaker_content .speakers .speaker_desig {
  font-size: 10px;
  line-height: 16px;
  display: inline-block;
  padding: 4px 8px;
  border-radius: 90px;
  position: absolute;
  bottom: 0;
}
.webinar-registration .loyalty_first_fold .loyalty_left_side .webinar_patch {
  border-radius: 6px;
  display: flex;
  background: #DFF;
  align-items: center;
  justify-content: space-around;
}
@media (max-width: 992px) {
  .webinar-registration .loyalty_first_fold .loyalty_left_side .webinar_patch {
    flex-wrap: wrap;
    padding: 12px 0;
  }
}
.webinar-registration .loyalty_first_fold .loyalty_left_side .webinar_patch span {
  font-family: "Basier Circle medium";
  font-size: 20px;
  font-weight: 500;
  line-height: 50px;
  margin: 0 20px;
  color: #023342;
}
@media (max-width: 992px) {
  .webinar-registration .loyalty_first_fold .loyalty_left_side .webinar_patch span {
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    display: block;
  }
}
.webinar-registration .loyalty_first_fold .loyalty_left_side .webinar_patch span img {
  position: relative;
  top: 3px;
  width: 22px;
}
.webinar-registration .loyalty_first_fold .loyalty_left_side .agenda {
  padding-left: 20px;
}
.webinar-registration .loyalty_first_fold .loyalty_left_side .agenda li {
  padding: 0 0 4px;
  color: #374040;
  font-size: 18px;
  line-height: 28px;
}
@media (max-width: 992px) {
  .webinar-registration .loyalty_first_fold .loyalty_left_side .agenda li {
    font-size: 14px;
    line-height: 22px;
  }
}
.webinar-registration .loyalty_first_fold .loyalty_right_side {
  width: 100%;
  max-width: 571px;
}
.webinar-registration .loyalty_first_fold .loyalty_right_side .hurry {
  background: #BEF8F8;
  border-radius: 24px;
  padding: 23px 30px;
}
@media (max-width: 992px) {
  .webinar-registration .loyalty_first_fold .loyalty_right_side .hurry {
    padding: 20px;
    margin: 0;
  }
}
.webinar-registration .loyalty_first_fold .loyalty_right_side .hurry .sec_title {
  font-size: 28px;
  line-height: 38px;
  letter-spacing: -0.02em;
  font-weight: 500;
  font-family: "Basier Circle Semibold";
  margin: 0;
}
.webinar-registration .display_table {
  flex-direction: unset;
}
.webinar-registration .banner {
  padding: 0 0 80px;
}
@media (max-width: 992px) {
  .webinar-registration .banner {
    height: unset;
    padding: 20px 0 40px;
  }
  .webinar-registration .banner .common_title {
    font-size: 28px;
    line-height: 32px;
  }
  .webinar-registration .banner .common_desc {
    font-size: 14px;
    line-height: 22px;
  }
}
.webinar-registration .sec_title {
  font-size: 42px;
  margin: 0 0 16px;
  line-height: 51px;
  letter-spacing: -0.02em;
  font-weight: 500;
  font-family: "Basier Circle medium";
}
@media (max-width: 992px) {
  .webinar-registration .sec_title {
    font-size: 28px;
    line-height: 32px;
  }
}
.webinar-registration .sec_desc {
  font-size: 36px;
  font-weight: 400;
  line-height: 43px;
  letter-spacing: -0.36px;
  font-family: "Basier Circle";
  padding: 12px 0;
}
@media (max-width: 992px) {
  .webinar-registration .sec_desc {
    font-size: 20px;
    line-height: 30px;
  }
}
.webinar-registration .common_title {
  font-size: 56px;
  line-height: 68px;
  letter-spacing: -0.01em;
  font-family: "Basier Circle medium";
}
.webinar-registration .common_desc {
  font-size: 18px;
  margin: 0 0 28px;
  line-height: 28px;
  font-family: "Basier Circle";
}
.webinar-registration .webinar_agenda .loyalty_first_fold {
  gap: 24px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
@media (max-width: 992px) {
  .webinar-registration .webinar_agenda .loyalty_first_fold {
    flex-wrap: wrap;
  }
}
.webinar-registration .webinar_agenda .loyalty_first_fold .loyalty_left_side {
  max-width: 620px;
}
.webinar-registration .webinar_agenda .loyalty_first_fold .loyalty_left_side .agenda_div {
  padding: 0 0 25px;
}
.webinar-registration .webinar_agenda .loyalty_first_fold .loyalty_left_side .agenda_div .agenda_title {
  font-size: 24px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.24px;
  padding: 0 0 15px;
}
@media (max-width: 992px) {
  .webinar-registration .webinar_agenda .loyalty_first_fold .loyalty_left_side .agenda_div .agenda_title {
    font-size: 20px;
    line-height: 24px;
  }
}
.webinar-registration .webinar_agenda .loyalty_first_fold .loyalty_left_side .agenda_div .common_desc {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}
@media (max-width: 992px) {
  .webinar-registration .webinar_agenda .loyalty_first_fold .loyalty_left_side .agenda_div .common_desc {
    font-size: 16px;
    line-height: 24px;
  }
}
.webinar-registration .webinar_agenda .loyalty_first_fold .loyalty_left_side .agenda_div .agenda {
  padding-left: 20px;
}
.webinar-registration .webinar_agenda .loyalty_first_fold .loyalty_left_side .agenda_div .agenda li {
  padding: 0 0 4px;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
}
@media (max-width: 992px) {
  .webinar-registration .webinar_agenda .loyalty_first_fold .loyalty_left_side .agenda_div .agenda li {
    font-size: 16px;
    line-height: 24px;
  }
}
.webinar-registration .webinar_agenda .loyalty_first_fold .loyalty_right_side {
  width: 100%;
  max-width: 571px;
  border-radius: 24px;
  background: #BEF8F8;
  padding: 30px 43px;
}
@media (max-width: 992px) {
  .webinar-registration .webinar_agenda .loyalty_first_fold .loyalty_right_side {
    padding: 20px;
  }
}
.webinar-registration .webinar_agenda .loyalty_first_fold .loyalty_right_side .sec_title {
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.56px;
  color: #0D9298;
}
@media (max-width: 992px) {
  .webinar-registration .webinar_agenda .loyalty_first_fold .loyalty_right_side .sec_title {
    font-size: 20px;
    line-height: 24px;
  }
}
.webinar-registration .webinar_agenda .loyalty_first_fold .loyalty_right_side .what_learn {
  padding: 0 0 20px;
}
.webinar-registration .webinar_agenda .loyalty_first_fold .loyalty_right_side .what_learn p {
  color: #003838;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
}
@media (max-width: 992px) {
  .webinar-registration .webinar_agenda .loyalty_first_fold .loyalty_right_side .what_learn p {
    font-size: 16px;
    line-height: 24px;
  }
}
.webinar-registration .webinar_agenda .loyalty_first_fold .loyalty_right_side .what_learn p strong {
  color: #021B1B;
}
.webinar-registration .speaker .speaker_wrapper {
  border-radius: 24px;
  padding: 30px 42px;
  height: 545px;
  margin: 0 15px;
  z-index: 1;
}
@media (max-width: 992px) {
  .webinar-registration .speaker .speaker_wrapper {
    padding: 30px 20px;
    height: unset;
    margin: 0 0 30px;
  }
}
.webinar-registration .speaker .speaker_wrapper .speakerimght {
  height: 180px;
  padding: 40px 0;
}
@media (max-width: 992px) {
  .webinar-registration .speaker .speaker_wrapper .speakerimght {
    height: unset;
    padding: 0;
  }
}
.webinar-registration .speaker .speaker_wrapper .about_speaker {
  font-size: 42px;
  font-weight: 600;
  line-height: 60px;
  letter-spacing: -0.84px;
  padding: 15px 0;
}
@media (max-width: 992px) {
  .webinar-registration .speaker .speaker_wrapper .about_speaker {
    font-size: 30px;
    line-height: 40px;
  }
}
.webinar-registration .speaker .speaker_wrapper .about_speaker_text {
  color: #001124;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
}
@media (max-width: 992px) {
  .webinar-registration .speaker .speaker_wrapper .about_speaker_text {
    font-size: 16px;
    line-height: 24px;
  }
}
.webinar-registration .speaker .speaker_wrapper .union {
  position: absolute;
  right: 0;
  bottom: 0;
  mix-blend-mode: darken;
  z-index: -1;
  border-bottom-right-radius: 24px;
}
@media (max-width: 992px) {
  .webinar-registration .speaker .speaker_wrapper .union {
    border-bottom-right-radius: 24px;
  }
}
.webinar-registration #webinars .webinar-box {
  padding: 30px 10px !important;
}
.webinar-registration #webinars .webinar-box:hover .video-thumbnail .play-icon-wrapper img {
  transform: scale(1.2);
  transition-duration: 0.3s;
  transition-property: transform;
}
.webinar-registration #webinars .webinar-box .webinar_Img .video-thumbnail .videoimg {
  border-bottom-left-radius: unset;
  border-bottom-right-radius: unset;
}
@media only screen and (max-width: 992px) {
  .webinar-registration #webinars .webinar-box .webinar_Img .video-thumbnail .videoimg {
    height: 180px;
  }
}
.webinar-registration #webinars .webinar-box .webinar_Img .video-thumbnail .play-icon-wrapper {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  cursor: pointer;
}
.webinar-registration #webinars .webinar-box .webinar_Img .play-icon {
  height: unset;
}
.webinar-registration #webinars .webinar-box .webinar_Img .videoimg {
  height: 206px;
  transition-duration: unset;
  transition-property: unset;
  transform: unset;
}
@media only screen and (max-width: 992px) {
  .webinar-registration #webinars .webinar-box .webinar_Img .videoimg {
    height: unset;
  }
}
.webinar-registration #webinars .webinar-box .webinar_Img iframe {
  border-radius: 8px;
}
@media only screen and (max-width: 992px) {
  .webinar-registration #webinars .webinar-box .webinar_Img iframe {
    height: 180px;
  }
}

.webiContent {
  float: left;
  margin: -5px 0 0;
  padding: 0px 20px 20px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  height: 330px;
}
@media (max-width: 992px) {
  .webiContent {
    height: unset;
  }
}

.restautant-retargeting .banner .bg1 {
  z-index: -1;
}
.restautant-retargeting .banner .bg2 {
  position: absolute;
  right: 0;
  bottom: -110px;
  z-index: -1;
}
@media (max-width: 992px) {
  .restautant-retargeting .banner .bg2 {
    bottom: 0;
  }
}
.restautant-retargeting .banner .banner_wrapper {
  padding: 70px 0px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 992px) {
  .restautant-retargeting .banner .banner_wrapper {
    padding: 40px 0 0;
    flex-wrap: wrap;
    gap: 30px;
  }
}
.restautant-retargeting .banner .banner_wrapper .banner_left_fold {
  max-width: 669px;
}
.restautant-retargeting .banner .banner_wrapper .banner_right_fold {
  width: 100%;
  max-width: 538px;
  padding: 24px;
}
@media (max-width: 992px) {
  .restautant-retargeting .banner .banner_wrapper .banner_right_fold {
    padding: 0;
  }
}
.restautant-retargeting .banner .banner_wrapper .banner-container .rating p {
  color: #697777;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
}
@media (max-width: 992px) {
  .restautant-retargeting .banner .banner_wrapper .banner-container .rating p {
    font-size: 14px;
    line-height: 20px;
  }
}
.restautant-retargeting .banner .banner_wrapper .banner-container .banner-title {
  font-size: 64px;
  line-height: 70px;
  margin: 12px 0 22px;
  letter-spacing: -2.56px;
  font-family: "Basier Circle medium";
}
@media (max-width: 992px) {
  .restautant-retargeting .banner .banner_wrapper .banner-container .banner-title {
    margin: 12px auto 16px;
    font-size: 28px;
    line-height: 32px;
    text-align: center;
    letter-spacing: -1.36px;
  }
}
.restautant-retargeting .banner .banner_wrapper .banner-container .banner-text {
  font-size: 20px;
  margin: 0 0 40px;
  line-height: 30px;
}
@media (max-width: 992px) {
  .restautant-retargeting .banner .banner_wrapper .banner-container .banner-text {
    margin: 0 0 40px;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
  }
}
.restautant-retargeting .banner .banner_wrapper .banner-container .btn_wrapper {
  width: 330px;
  margin: 50px 0 40px;
}
.restautant-retargeting .banner .banner_wrapper .banner-container .btn_wrapper a {
  width: 100%;
}
.restautant-retargeting .banner .banner_wrapper .banner-container .btn_wrapper .text-small {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  display: block;
  margin: 8px 0 0;
}
@media (max-width: 992px) {
  .restautant-retargeting .banner .banner_wrapper .banner-container .btn_wrapper {
    width: unset;
    margin: 0 0 30px;
  }
}
.restautant-retargeting .banner .banner_wrapper .banner-container .flex_div {
  display: flex;
  align-items: center;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}
@media (max-width: 992px) {
  .restautant-retargeting .banner .banner_wrapper .banner-container .flex_div {
    gap: 11px;
    margin: 0 0 30px;
    justify-content: center;
  }
}
.restautant-retargeting .banner .banner_wrapper .banner-container .flex_div img {
  width: 75px;
}
@media (max-width: 992px) {
  .restautant-retargeting .banner .banner_wrapper .banner-container .flex_div img {
    width: 40px;
  }
}
.restautant-retargeting .banner .disFlex {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}
@media (max-width: 992px) {
  .restautant-retargeting .banner .disFlex {
    display: block;
  }
}
.restautant-retargeting .fold1 .sec-title {
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: 64px;
  letter-spacing: -0.56px;
  font-family: "Basier Circle medium";
}
@media (max-width: 992px) {
  .restautant-retargeting .fold1 .sec-title {
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 24px;
  }
}
.restautant-retargeting #section2 .sec-title {
  font-size: 58px;
  font-style: normal;
  color: #0e1b1b;
  line-height: 64px;
  text-align: center;
  margin: 0 auto 48px;
  letter-spacing: -0.02em;
  font-family: "Basier Circle Semibold";
}
@media (max-width: 992px) {
  .restautant-retargeting #section2 .sec-title {
    font-size: 28px;
    line-height: 32px;
    letter-spacing: -0.96px;
    width: 100%;
    margin-bottom: 24px;
  }
}
.restautant-retargeting #section2 .tooltabs {
  border-radius: 49px;
  background: #f5f5f5;
  padding: 10px;
  width: 43%;
  margin: 30px auto 60px;
}
@media (max-width: 992px) {
  .restautant-retargeting #section2 .tooltabs {
    margin: 20px auto 40px;
    width: 100%;
  }
}
.restautant-retargeting #section2 .tooltabs a {
  color: #697777;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.32px;
  font-family: "Basier Circle medium";
  padding: 3px 30px;
  cursor: pointer;
}
@media (max-width: 992px) {
  .restautant-retargeting #section2 .tooltabs a {
    font-size: 12px;
    line-height: 16px;
    padding: 3px 12px;
  }
}
.restautant-retargeting #section2 .tooltabs a.active {
  color: #212123;
  border-radius: 52px;
  background: #fff;
  -webkit-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.08);
  padding: 6px 30px;
}
@media (max-width: 992px) {
  .restautant-retargeting #section2 .tooltabs a.active {
    font-size: 12px;
    line-height: 16px;
    padding: 3px 12px;
  }
}
.restautant-retargeting #section2 .tools, .restautant-retargeting #section2 .tool {
  padding: 10px;
  border-radius: 16px;
  border: 1px solid #e9eded;
  background: #fff;
  -webkit-box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.05), 0 0 1px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.05), 0 0 1px 0 rgba(0, 0, 0, 0.25);
}
@media (max-width: 992px) {
  .restautant-retargeting #section2 .tools, .restautant-retargeting #section2 .tool {
    background: #f7f8f8;
  }
}
.restautant-retargeting #section2 .tools .card_img img, .restautant-retargeting #section2 .tool .card_img img {
  height: 443px;
  border-radius: 8px;
  object-fit: cover;
}
@media (max-width: 992px) {
  .restautant-retargeting #section2 .tools .card_img img, .restautant-retargeting #section2 .tool .card_img img {
    height: 100%;
  }
}
.restautant-retargeting #section2 .tools .tools_wrapper, .restautant-retargeting #section2 .tool .tools_wrapper {
  padding: 30px;
}
@media (max-width: 992px) {
  .restautant-retargeting #section2 .tools .tools_wrapper, .restautant-retargeting #section2 .tool .tools_wrapper {
    padding: 0;
  }
}
.restautant-retargeting #section2 .tools .tools_wrapper h3, .restautant-retargeting #section2 .tool .tools_wrapper h3 {
  color: #0e1b1b;
  font-size: 41px;
  font-style: normal;
  font-weight: 600;
  line-height: 49.2px;
  letter-spacing: -2.05px;
  margin-bottom: 0;
  margin: 15px 0 0;
}
@media (max-width: 992px) {
  .restautant-retargeting #section2 .tools .tools_wrapper h3, .restautant-retargeting #section2 .tool .tools_wrapper h3 {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.36px;
  }
}
.restautant-retargeting #section2 .tools .tools_wrapper p, .restautant-retargeting #section2 .tool .tools_wrapper p {
  margin: 16px 0;
  font-size: 16px;
  color: #697777;
  line-height: 22px;
  font-style: normal;
}
@media (max-width: 992px) {
  .restautant-retargeting #section2 .tools .tools_wrapper p, .restautant-retargeting #section2 .tool .tools_wrapper p {
    margin: 8px 0;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.14px;
  }
}
.restautant-retargeting #section2 .tools .tools_wrapper .learnmore a, .restautant-retargeting #section2 .tool .tools_wrapper .learnmore a {
  display: block;
  padding: 20px 0;
  text-decoration: underline !important;
  font-weight: 500;
  font-family: "Basier Circle medium";
  font-size: 16px;
  cursor: pointer;
}
.restautant-retargeting #section3 .bgcolor {
  background-color: #f1fafa;
  margin: 0 40px;
}
@media (max-width: 992px) {
  .restautant-retargeting #section3 .bgcolor {
    margin: 0;
    padding: 40px 0 30px;
  }
}
.restautant-retargeting #section3 .sec-title {
  font-size: 58px;
  font-style: normal;
  color: #0e1b1b;
  line-height: 64px;
  text-align: center;
  margin: 0 auto 48px;
  letter-spacing: -0.02em;
  font-family: "Basier Circle Semibold";
}
@media (max-width: 992px) {
  .restautant-retargeting #section3 .sec-title {
    font-size: 28px;
    line-height: 32px;
    letter-spacing: -0.96px;
    width: 100%;
    margin-bottom: 24px;
  }
}
.restautant-retargeting #section3 .counter_wrapper {
  padding: 30px 0;
}
@media (max-width: 992px) {
  .restautant-retargeting #section3 .counter_wrapper {
    padding: 0;
  }
}
.restautant-retargeting #section3 .counter_wrapper .blr::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 75px;
  background-color: #0e1b1b;
  opacity: 0.3;
}
@media (max-width: 992px) {
  .restautant-retargeting #section3 .counter_wrapper .blr::before {
    top: -22px;
    left: 50%;
    transform: translateX(-50%);
    width: 75px;
    height: 1px;
  }
}
.restautant-retargeting #section3 .counter_wrapper .blr::after {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 75px;
  background-color: #0e1b1b;
  opacity: 0.3;
}
@media (max-width: 992px) {
  .restautant-retargeting #section3 .counter_wrapper .blr::after {
    bottom: 22px;
    right: unset;
    left: 50%;
    transform: translateX(-50%);
    width: 75px;
    height: 1px;
    top: unset;
  }
}
@media (max-width: 992px) {
  .restautant-retargeting #section3 .counter_wrapper .counter_div {
    height: 147px;
  }
}
.restautant-retargeting #section3 .counter_wrapper .counter_div .counter_num p {
  color: #0d9298;
  text-align: center;
  font-size: 64px;
  line-height: normal;
  letter-spacing: -0.64px;
  margin-bottom: 0;
  font-family: "Basier Circle medium";
}
.restautant-retargeting #section3 .counter_wrapper .counter_div .counter_text {
  color: #0e1b1b;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.2px;
  padding: 15px 0 0;
}
@media (max-width: 992px) {
  .restautant-retargeting #section3 .counter_wrapper .counter_div .counter_text {
    font-size: 14px;
    line-height: 20px;
    padding: 10px 0 0;
  }
}
.restautant-retargeting #section4 .sec-title {
  font-size: 58px;
  font-style: normal;
  color: #0e1b1b;
  line-height: 64px;
  text-align: center;
  margin: 0 auto 48px;
  letter-spacing: -0.02em;
  font-family: "Basier Circle Semibold";
}
@media (max-width: 992px) {
  .restautant-retargeting #section4 .sec-title {
    font-size: 28px;
    line-height: 32px;
    letter-spacing: -0.96px;
    width: 100%;
    margin-bottom: 24px;
  }
}
.restautant-retargeting #section4 .slider {
  padding: 40px 0 60px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
@media (max-width: 767px) {
  .restautant-retargeting #section4 .slider {
    display: block;
    padding: 20px 0 0px;
  }
  .restautant-retargeting #section4 .slider .slider-prev {
    bottom: 0;
    left: calc(50% - 62px);
  }
  .restautant-retargeting #section4 .slider .slider-next {
    bottom: 0;
    right: calc(50% - 62px);
  }
}
.restautant-retargeting #section4 .slider .slide {
  border-radius: 12px;
  padding: 20px 24px;
  height: 440px;
  margin: 0 10px;
}
.restautant-retargeting #section4 .slider .slide .main_img {
  background-color: #fff;
  border-radius: 50%;
  width: 65px;
  height: 65px;
}
@media (max-width: 992px) {
  .restautant-retargeting #section4 .slider .slide {
    height: 460px;
    margin: 15px 0;
  }
}
.restautant-retargeting #section4 .slider .slide .slidetext {
  padding: 16px 0 28px;
  color: #0e1b1b;
  font-weight: 400;
  margin: 20px 0 28px;
  height: 210px;
  font-size: 16px;
  line-height: 22px;
}
@media (max-width: 992px) {
  .restautant-retargeting #section4 .slider .slide .slidetext {
    height: 250px;
  }
}
.restautant-retargeting #section4 .slider .slide1 {
  background-color: #FFECEB;
}
.restautant-retargeting #section4 .slider .slide2 {
  background-color: #FFF9F1;
}
.restautant-retargeting #section4 .slider .slide3 {
  background-color: #FFF9E5;
}
.restautant-retargeting #section4 .slider .slide_flex {
  display: flex;
  align-items: center;
  gap: 15px;
}
.restautant-retargeting #section4 .slider .slide_flex .alpha {
  width: 40px;
  height: 40px;
  background-color: #0d9298;
  color: #fff;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  line-height: 22px;
  font-family: "Basier Circle Semibold";
  padding: 8px 14px;
}
.restautant-retargeting #section4 .slider .slide_flex .author img {
  border-radius: 100%;
}
.restautant-retargeting #section4 .slider .slide_flex .author_desc .sec-desc {
  padding: 0;
}
.restautant-retargeting #section4 .slider .slide_flex .author_desc span {
  font-size: 14px;
  line-height: 18px;
}
.restautant-retargeting .qrcode_reviews .qr_wrapper {
  padding-right: 60px;
}
@media (max-width: 992px) {
  .restautant-retargeting .qrcode_reviews .qr_wrapper {
    padding-right: 0;
  }
}
.restautant-retargeting .qrcode_reviews .qr_wrapper .loyalty_tabs {
  cursor: pointer;
  margin: 0 0 16px;
  padding: 16px 24px;
  border-radius: 16px;
  transition: all 0.5s;
  background: #f2f2f2;
}
.restautant-retargeting .qrcode_reviews .qr_wrapper .loyalty_tabs h3 {
  font-size: 20px;
  color: #182020;
  line-height: 26px;
}
.restautant-retargeting .qrcode_reviews .qr_wrapper .loyalty_tabs p {
  display: none;
  margin: 8px 0 0;
  font-size: 16px;
  color: #fff;
  line-height: 22px;
  text-align: left;
}
.restautant-retargeting .qrcode_reviews .qr_wrapper .loyalty_tabs ul {
  display: none;
  padding: 10px 0 0 15px;
}
.restautant-retargeting .qrcode_reviews .qr_wrapper .loyalty_tabs ul li {
  font-size: 16px;
  color: #fff;
  line-height: 22px;
  text-align: left;
  padding: 0 0 5px 5px;
  position: relative;
  list-style: none;
}
.restautant-retargeting .qrcode_reviews .qr_wrapper .loyalty_tabs ul li:before {
  position: absolute;
  content: "✔";
  left: -13px;
}
.restautant-retargeting .qrcode_reviews .qr_wrapper .loyalty_tabs.active {
  background-color: #01999f;
}
.restautant-retargeting .qrcode_reviews .qr_wrapper .loyalty_tabs.active h3 {
  color: #fff;
}
.restautant-retargeting .qrcode_reviews .qr_wrapper .loyalty_tabs.active p, .restautant-retargeting .qrcode_reviews .qr_wrapper .loyalty_tabs.active ul {
  display: block;
}
.restautant-retargeting .qrcode_reviews .qr_slider .slick-dots {
  display: flex;
  margin: 0;
  list-style-type: none;
  position: absolute;
  bottom: -9px;
  left: 50%;
  transform: translateX(-50%);
}
.restautant-retargeting .qrcode_reviews .qr_slider .slick-dots button {
  display: block;
  width: 6px;
  height: 6px;
  padding: 0;
  background-color: grey;
  text-indent: -9999px;
  cursor: pointer;
  border-radius: 30px;
  border: unset;
  margin: 0 3px;
}
.restautant-retargeting .qrcode_reviews .qr_slider li.slick-active button {
  background-color: #000;
}
.restautant-retargeting .qrcode_reviews .qr_slider img {
  border-radius: 16px;
}
@media (max-width: 992px) {
  .restautant-retargeting .qrcode_reviews .qr_slider .qr_desc {
    text-align: left;
  }
}
.restautant-retargeting .qrcode_reviews .qr_slider .qr_desc h3 {
  font-size: 18px;
  color: #0e1b1b;
  line-height: 24px;
  margin: 32px auto 8px;
}
.restautant-retargeting .qrcode_reviews .qr_slider .qr_desc p {
  font-size: 14px;
  color: #5a6565;
  max-width: 400px;
  line-height: 20px;
  margin: 0 auto 8px;
}
.restautant-retargeting .qrcode_reviews .qr_slider .qr_desc ul {
  padding: 10px 14px 20px;
}
.restautant-retargeting .qrcode_reviews .qr_slider .qr_desc ul li {
  font-size: 14px;
  color: #5a6565;
  max-width: 400px;
  line-height: 20px;
  list-style: none;
  padding-bottom: 5px;
  position: relative;
}
.restautant-retargeting .qrcode_reviews .qr_slider .qr_desc ul li:before {
  position: absolute;
  content: "✔";
  margin-left: -14px;
}
.restautant-retargeting .what-reelo-offers .whatreelo_offers {
  display: flex;
  justify-content: center;
  gap: 30px;
  padding: 15px;
}
@media (max-width: 992px) {
  .restautant-retargeting .what-reelo-offers .whatreelo_offers {
    padding: 0;
    gap: 10px;
    margin: 5px 0;
  }
}
.restautant-retargeting .what-reelo-offers .whatreelo_offers .whatreelo_offers_div {
  width: 343px;
  height: 196px;
  padding: 32px 22px;
  border-radius: 17px;
}
@media (max-width: 992px) {
  .restautant-retargeting .what-reelo-offers .whatreelo_offers .whatreelo_offers_div {
    height: 130px;
    padding: 15px;
  }
  .restautant-retargeting .what-reelo-offers .whatreelo_offers .whatreelo_offers_div img {
    width: 35px;
  }
}
.restautant-retargeting .what-reelo-offers .whatreelo_offers .whatreelo_offers_div p {
  color: #171E59;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  padding: 15px 0 0;
}
@media (max-width: 992px) {
  .restautant-retargeting .what-reelo-offers .whatreelo_offers .whatreelo_offers_div p {
    font-size: 14px;
  }
}
.restautant-retargeting .what-reelo-offers .whatreelo_offers .offer_1 {
  background: #EDFCF2;
}
.restautant-retargeting .what-reelo-offers .whatreelo_offers .offer_2 {
  background: #FEF6EE;
}
.restautant-retargeting .what-reelo-offers .whatreelo_offers .offer_3 {
  background: #F5F8FF;
}
.restautant-retargeting .what-reelo-offers .whatreelo_offers .offer_4 {
  background: #FFE5EB;
}
.restautant-retargeting .what-reelo-offers .whatreelo_offers .offer_5 {
  background: #DDFEFF;
}
.restautant-retargeting .what-reelo-offers .whatreelo_offers .offer_6 {
  background: #FFF1D4;
}
.restautant-retargeting .pricing .sec-heading2 {
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: 64px;
  letter-spacing: -0.56px;
  font-family: "Basier Circle medium";
}
@media (max-width: 992px) {
  .restautant-retargeting .pricing .sec-heading2 {
    font-size: 28px;
    line-height: 32px;
  }
}
.restautant-retargeting .pricing_card-rows {
  display: flex;
  justify-content: center;
  gap: 25px;
}
@media (max-width: 992px) {
  .restautant-retargeting .pricing_card-rows {
    display: block;
  }
}
.restautant-retargeting .pricing_card-rows .cards {
  width: 370px;
  height: 100%;
  min-height: 380px;
  padding: 24px 32px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (max-width: 992px) {
  .restautant-retargeting .pricing_card-rows .cards {
    width: 100%;
  }
}
.restautant-retargeting .pricing_card-rows .cards .white-btn {
  color: #1A424F;
  border: 1px solid #1A424F;
  border-radius: 8px;
  background-color: transparent;
  width: 100%;
}
.restautant-retargeting .pricing_card-rows .cards .theme-btn {
  width: 100%;
}
.restautant-retargeting .pricing_card-rows .cards h3 {
  font-size: 24px;
  color: #354545;
  line-height: 30px;
  margin-bottom: 8px;
  font-weight: 700;
}
.restautant-retargeting .pricing_card-rows .cards h4 {
  font-size: 48px;
  line-height: 67px;
  color: #182020;
  font-weight: 600;
}
.restautant-retargeting .pricing_card-rows .cards h4 p {
  display: inline;
  font-size: 48px;
  line-height: 67px;
  color: #182020;
  font-weight: 400;
}
.restautant-retargeting .pricing_card-rows .cards h4 span {
  font-size: 14px;
  line-height: 18px;
}
.restautant-retargeting .pricing_card-rows .cards h6 {
  font-size: 16px;
  line-height: 30px;
  color: rgba(24, 32, 32, 0.75);
  font-weight: 400;
}
.restautant-retargeting .pricing_card-rows .cards .pricing_desc2 {
  font-size: 14px;
  color: #1a424f;
  line-height: 28px;
  text-align: center;
}
.restautant-retargeting .pricing_card-rows .cards .pricing_features {
  display: flex;
  margin: 24px 0;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}
.restautant-retargeting .pricing_card-rows .pricing_plan-card {
  background: #f5f5f5;
}
.restautant-retargeting .pricing_card-rows .pricing_plan-card__3SDlI {
  background: linear-gradient(157.35deg, #c4f2f4 14.72%, #9cffed 102.4%);
}
.restautant-retargeting .pricing_term {
  font-size: 12px;
  line-height: 17px;
  text-align: right;
  color: #838c8c;
}
@media (max-width: 992px) {
  .restautant-retargeting .pre-built .common_title {
    font-size: 28px;
    line-height: 32px;
  }
}
@media (max-width: 992px) {
  .restautant-retargeting .pre-built .common_desc {
    font-size: 18px;
  }
}
.restautant-retargeting .pre-built .campaigns_feature_card_root {
  gap: 24px;
  margin: 0 auto;
  padding: 0 16px;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 992px) {
  .restautant-retargeting .pre-built .campaigns_feature_card_root {
    display: block;
    border-radius: 16px;
    padding: 0;
  }
  .restautant-retargeting .pre-built .campaigns_feature_card_root .slick-dots {
    display: flex;
    margin: 0;
    list-style-type: none;
    position: absolute;
    bottom: -9px;
    left: 50%;
    transform: translateX(-50%);
  }
  .restautant-retargeting .pre-built .campaigns_feature_card_root .slick-dots button {
    display: block;
    width: 12px;
    height: 12px;
    padding: 0;
    background-color: #c6c6c6;
    text-indent: -9999px;
    cursor: pointer;
    border-radius: 30px;
    border: unset;
    margin: 0 3px;
  }
  .restautant-retargeting .pre-built .campaigns_feature_card_root li.slick-active button {
    background-color: #fff;
  }
}
.restautant-retargeting .pre-built .campaigns_feature_card_root .campaigns_feature_card {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  border-radius: 16px;
  background: #f3f1ee;
  width: calc(50% - 12px);
}
@media (max-width: 992px) {
  .restautant-retargeting .pre-built .campaigns_feature_card_root .campaigns_feature_card {
    display: block;
    padding: 0;
    width: 100%;
    margin: 0 0 30px;
  }
}
.restautant-retargeting .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_left_side {
  padding: 30px;
}
@media (max-width: 992px) {
  .restautant-retargeting .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_left_side {
    padding: 34px 4px 34px 28px;
  }
}
.restautant-retargeting .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_left_side .campaigns_feature_number {
  padding: 4px 9px;
  width: 28px;
  height: 28px;
  font-size: 16px;
  color: #fff;
  line-height: 22px;
  border-radius: 8px;
  background: #065151;
  font-family: "Basier Circle Semibold";
}
.restautant-retargeting .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_left_side h3 {
  margin: 8px 0;
  font-size: 28px;
  color: #0e1b1b;
  line-height: 34px;
  letter-spacing: -0.02em;
  font-family: "Basier Circle Semibold";
}
@media (max-width: 992px) {
  .restautant-retargeting .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_left_side h3 {
    font-size: 20px;
    line-height: 24px;
  }
}
.restautant-retargeting .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_left_side p {
  font-size: 18px;
  color: #232d2d;
  line-height: 130%;
}
.restautant-retargeting .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_right_side {
  width: 100%;
  position: relative;
  bottom: -6px;
}
@media (max-width: 992px) {
  .restautant-retargeting .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_right_side {
    text-align: center;
    padding: 0 15px;
    max-width: 100%;
    bottom: 0;
  }
  .restautant-retargeting .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_right_side img {
    height: 300px;
  }
}
.restautant-retargeting .pre-built .campaigns_feature_card_root .last_card {
  width: 100%;
  height: auto;
}
.restautant-retargeting .benifits_section .bene_div {
  padding: 32px 30px;
  border-radius: 16px;
  width: 31%;
  margin: 40px 14px 0;
}
@media (max-width: 992px) {
  .restautant-retargeting .benifits_section .bene_div {
    width: 100%;
    margin: 30px 0 0;
  }
}
.restautant-retargeting .benifits_section .bene_div .bene_inner {
  padding: 36px 22px;
  background: #fff;
  border-radius: 16.7273px;
}
.restautant-retargeting .benifits_section .bene_div .bene_inner p {
  font-size: 20px;
  color: #171e59;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.02em;
  font-family: "Basier Circle medium";
}
.restautant-retargeting .benifits_section .bene_div .bene_inner span {
  font-size: 48px;
  line-height: 62px;
  text-align: center;
  letter-spacing: -0.02em;
  font-family: "Basier Circle Semibold";
  display: block;
}
.restautant-retargeting .benifits_section .bene_div .bene_inner .green {
  color: #16b364;
}
.restautant-retargeting .benifits_section .bene_div .bene_inner .red {
  color: #ef6820;
}
.restautant-retargeting .benifits_section .bene_div .bene_inner .blue {
  color: #1570ef;
}
.restautant-retargeting .benifits_section .b1 {
  background: #edfcf2;
}
.restautant-retargeting .benifits_section .b2 {
  background: #fef6ee;
}
.restautant-retargeting .benifits_section .b3 {
  background: #f5f8ff;
}

.cafe-marketing .banner .bg1 {
  z-index: -1;
}
.cafe-marketing .banner .bg2 {
  position: absolute;
  right: 0;
  bottom: -110px;
  z-index: -1;
}
@media (max-width: 992px) {
  .cafe-marketing .banner .bg2 {
    bottom: 0;
  }
}
.cafe-marketing .banner .banner_wrapper {
  padding: 70px 0px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 992px) {
  .cafe-marketing .banner .banner_wrapper {
    padding: 40px 0 0;
    flex-wrap: wrap;
    gap: 30px;
  }
}
.cafe-marketing .banner .banner_wrapper .banner_left_fold {
  max-width: 669px;
}
.cafe-marketing .banner .banner_wrapper .banner_right_fold {
  width: 100%;
  max-width: 538px;
  padding: 24px;
}
@media (max-width: 992px) {
  .cafe-marketing .banner .banner_wrapper .banner_right_fold {
    padding: 0;
  }
}
.cafe-marketing .banner .banner_wrapper .banner-container .rating p {
  color: #697777;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
}
@media (max-width: 992px) {
  .cafe-marketing .banner .banner_wrapper .banner-container .rating p {
    font-size: 14px;
    line-height: 20px;
  }
}
.cafe-marketing .banner .banner_wrapper .banner-container .banner-title {
  font-size: 64px;
  line-height: 70px;
  margin: 12px 0 22px;
  letter-spacing: -2.56px;
  font-family: "Basier Circle medium";
}
@media (max-width: 992px) {
  .cafe-marketing .banner .banner_wrapper .banner-container .banner-title {
    margin: 12px auto 16px;
    font-size: 28px;
    line-height: 32px;
    text-align: center;
    letter-spacing: -1.36px;
  }
}
.cafe-marketing .banner .banner_wrapper .banner-container .banner-text {
  font-size: 20px;
  margin: 0 0 40px;
  line-height: 30px;
}
@media (max-width: 992px) {
  .cafe-marketing .banner .banner_wrapper .banner-container .banner-text {
    margin: 0 0 40px;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
  }
}
.cafe-marketing .banner .banner_wrapper .banner-container .btn_wrapper {
  width: 330px;
  margin: 50px 0 40px;
}
.cafe-marketing .banner .banner_wrapper .banner-container .btn_wrapper a {
  width: 100%;
}
.cafe-marketing .banner .banner_wrapper .banner-container .btn_wrapper .text-small {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  display: block;
  margin: 8px 0 0;
}
@media (max-width: 992px) {
  .cafe-marketing .banner .banner_wrapper .banner-container .btn_wrapper {
    width: unset;
    margin: 0 0 30px;
  }
}
.cafe-marketing .banner .banner_wrapper .banner-container .flex_div {
  display: flex;
  align-items: center;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}
@media (max-width: 992px) {
  .cafe-marketing .banner .banner_wrapper .banner-container .flex_div {
    margin: 0 0 30px;
    gap: 11px;
    justify-content: center;
  }
}
.cafe-marketing .banner .banner_wrapper .banner-container .flex_div img {
  width: 75px;
}
@media (max-width: 992px) {
  .cafe-marketing .banner .banner_wrapper .banner-container .flex_div img {
    width: 40px;
  }
}
.cafe-marketing .banner .disFlex {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}
@media (max-width: 992px) {
  .cafe-marketing .banner .disFlex {
    display: block;
  }
}
.cafe-marketing #section2 .sec-title {
  font-size: 58px;
  font-style: normal;
  color: #0e1b1b;
  line-height: 64px;
  text-align: center;
  margin: 0 auto 48px;
  letter-spacing: -0.02em;
  font-family: "Basier Circle Semibold";
}
@media (max-width: 992px) {
  .cafe-marketing #section2 .sec-title {
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.96px;
    width: 100%;
    margin-bottom: 24px;
  }
}
.cafe-marketing #section2 .tooltabs {
  border-radius: 49px;
  background: #f5f5f5;
  padding: 10px;
  width: 32%;
  margin: 30px auto 60px;
}
@media (max-width: 992px) {
  .cafe-marketing #section2 .tooltabs {
    margin: 20px auto 40px;
    width: 100%;
  }
}
.cafe-marketing #section2 .tooltabs a {
  color: #697777;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.32px;
  font-family: "Basier Circle medium";
  padding: 3px 30px;
  cursor: pointer;
}
@media (max-width: 992px) {
  .cafe-marketing #section2 .tooltabs a {
    font-size: 12px;
    line-height: 16px;
    padding: 3px 12px;
  }
}
.cafe-marketing #section2 .tooltabs a.active {
  color: #212123;
  border-radius: 52px;
  background: #fff;
  -webkit-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.08);
  padding: 6px 30px;
}
@media (max-width: 992px) {
  .cafe-marketing #section2 .tooltabs a.active {
    font-size: 12px;
    line-height: 16px;
    padding: 3px 12px;
  }
}
.cafe-marketing #section2 .tools {
  padding: 10px;
  border-radius: 16px;
  border: 1px solid #e9eded;
  background: #fff;
  -webkit-box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.05), 0 0 1px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.05), 0 0 1px 0 rgba(0, 0, 0, 0.25);
}
@media (max-width: 992px) {
  .cafe-marketing #section2 .tools {
    background: #f7f8f8;
  }
}
.cafe-marketing #section2 .tools .card_img img {
  height: 443px;
  border-radius: 8px;
  object-fit: cover;
}
@media (max-width: 992px) {
  .cafe-marketing #section2 .tools .card_img img {
    height: 100%;
  }
}
.cafe-marketing #section2 .tools .tools_wrapper {
  padding: 30px;
}
@media (max-width: 992px) {
  .cafe-marketing #section2 .tools .tools_wrapper {
    padding: 0;
  }
}
.cafe-marketing #section2 .tools .tools_wrapper h3 {
  color: #0e1b1b;
  font-size: 41px;
  font-style: normal;
  font-weight: 600;
  line-height: 49.2px;
  letter-spacing: -2.05px;
  margin-bottom: 0;
  margin: 15px 0 0;
}
@media (max-width: 992px) {
  .cafe-marketing #section2 .tools .tools_wrapper h3 {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.36px;
  }
}
.cafe-marketing #section2 .tools .tools_wrapper p {
  margin: 16px 0;
  font-size: 16px;
  color: #697777;
  line-height: 22px;
  font-style: normal;
}
@media (max-width: 992px) {
  .cafe-marketing #section2 .tools .tools_wrapper p {
    margin: 8px 0;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.14px;
  }
}
.cafe-marketing #section2 .tools .tools_wrapper .learnmore a {
  display: block;
  padding: 20px 0;
  text-decoration: underline !important;
  font-weight: 500;
  font-family: "Basier Circle medium";
  font-size: 16px;
  cursor: pointer;
}
.cafe-marketing #section3 .bgcolor {
  background-color: #f1fafa;
  margin: 0 40px;
  padding: 40px 0;
}
@media (max-width: 992px) {
  .cafe-marketing #section3 .bgcolor {
    margin: 0;
    padding: 40px 0 30px;
  }
}
.cafe-marketing #section3 .sec-title {
  font-size: 58px;
  font-style: normal;
  color: #0e1b1b;
  line-height: 64px;
  text-align: center;
  margin: 0 auto 48px;
  letter-spacing: -0.02em;
  font-family: "Basier Circle Semibold";
}
@media (max-width: 992px) {
  .cafe-marketing #section3 .sec-title {
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.96px;
    width: 100%;
    margin-bottom: 24px;
  }
}
.cafe-marketing #section3 .counter_wrapper {
  padding: 0px 0 30px;
}
@media (max-width: 992px) {
  .cafe-marketing #section3 .counter_wrapper {
    padding: 0;
  }
}
.cafe-marketing #section3 .counter_wrapper .blr::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 75px;
  background-color: #0e1b1b;
  opacity: 0.3;
}
@media (max-width: 992px) {
  .cafe-marketing #section3 .counter_wrapper .blr::before {
    top: -6px;
    left: 50%;
    transform: translateX(-50%);
    width: 75px;
    height: 1px;
  }
}
.cafe-marketing #section3 .counter_wrapper .blr::after {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 75px;
  background-color: #0e1b1b;
  opacity: 0.3;
}
@media (max-width: 992px) {
  .cafe-marketing #section3 .counter_wrapper .blr::after {
    bottom: 4px;
    right: unset;
    left: 50%;
    transform: translateX(-50%);
    width: 75px;
    height: 1px;
    top: unset;
  }
}
@media (max-width: 992px) {
  .cafe-marketing #section3 .counter_wrapper .counter_div {
    height: 147px;
  }
}
.cafe-marketing #section3 .counter_wrapper .counter_div .counter_num p {
  color: #0d9298;
  text-align: center;
  font-size: 64px;
  line-height: normal;
  letter-spacing: -0.64px;
  margin-bottom: 0;
  font-family: "Basier Circle medium";
}
.cafe-marketing #section3 .counter_wrapper .counter_div .counter_text {
  color: #0e1b1b;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.2px;
  padding: 15px 0 0;
}
@media (max-width: 992px) {
  .cafe-marketing #section3 .counter_wrapper .counter_div .counter_text {
    font-size: 14px;
    line-height: 20px;
    padding: 10px 0 0;
  }
}
.cafe-marketing #section4 .sec-title {
  font-size: 58px;
  font-style: normal;
  color: #0e1b1b;
  line-height: 64px;
  text-align: center;
  margin: 0 auto 48px;
  letter-spacing: -0.02em;
  font-family: "Basier Circle Semibold";
}
@media (max-width: 992px) {
  .cafe-marketing #section4 .sec-title {
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.96px;
    width: 100%;
    margin-bottom: 24px;
  }
}
.cafe-marketing #section4 .slider {
  padding: 40px 0 60px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
@media (max-width: 767px) {
  .cafe-marketing #section4 .slider {
    display: block;
    padding: 20px 0 0px;
  }
  .cafe-marketing #section4 .slider .slider-prev {
    bottom: 0;
    left: calc(50% - 62px);
  }
  .cafe-marketing #section4 .slider .slider-next {
    bottom: 0;
    right: calc(50% - 62px);
  }
}
.cafe-marketing #section4 .slider .slide {
  border-radius: 12px;
  padding: 20px 24px;
  height: 440px;
  margin: 0 10px;
}
.cafe-marketing #section4 .slider .slide .main_img {
  background-color: #fff;
  border-radius: 50%;
  width: 65px;
  height: 65px;
}
@media (max-width: 992px) {
  .cafe-marketing #section4 .slider .slide {
    height: 460px;
    margin: 15px 0;
  }
}
.cafe-marketing #section4 .slider .slide .slidetext {
  padding: 16px 0 28px;
  color: #0e1b1b;
  font-weight: 400;
  margin: 20px 0 28px;
  height: 210px;
  font-size: 16px;
  line-height: 22px;
}
@media (max-width: 992px) {
  .cafe-marketing #section4 .slider .slide .slidetext {
    height: 250px;
  }
}
.cafe-marketing #section4 .slider .slide1 {
  background-color: #FFECEB;
}
.cafe-marketing #section4 .slider .slide2 {
  background-color: #FFF9F1;
}
.cafe-marketing #section4 .slider .slide3 {
  background-color: #FFF9E5;
}
.cafe-marketing #section4 .slider .slide_flex {
  display: flex;
  align-items: center;
  gap: 15px;
}
.cafe-marketing #section4 .slider .slide_flex .alpha {
  width: 40px;
  height: 40px;
  background-color: #0d9298;
  color: #fff;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  line-height: 22px;
  font-family: "Basier Circle Semibold";
  padding: 8px 14px;
}
.cafe-marketing #section4 .slider .slide_flex .author img {
  border-radius: 100%;
}
.cafe-marketing #section4 .slider .slide_flex .author_desc .sec-desc {
  padding: 0;
}
.cafe-marketing #section4 .slider .slide_flex .author_desc span {
  font-size: 14px;
  line-height: 18px;
}

.petpooja .faq .sec_title {
  margin: 0 0 40px;
  font-size: 32px;
  color: #182020;
  line-height: 40px;
  font-weight: 700;
}
.petpooja .petpooja_first_fold {
  padding: 180px 0 90px;
}
@media (max-width: 992px) {
  .petpooja .petpooja_first_fold {
    flex-wrap: wrap;
    padding: 90px 0 50px;
  }
}
.petpooja .petpooja_first_fold .petpooja_right_side {
  width: 571px;
  min-width: 380px;
}
@media (max-width: 992px) {
  .petpooja .petpooja_first_fold .petpooja_right_side {
    all: unset;
    width: 100%;
  }
}
.petpooja .petpooja_first_fold .petpooja_right_side .quotes_Img {
  margin: 50px 0 0;
}
@media (max-width: 992px) {
  .petpooja .petpooja_first_fold .petpooja_right_side .quotes_Img {
    margin: 0;
  }
}
.petpooja .petpooja_first_fold .petpooja_right_side .quotes_Img .video-thumbnail .top-gradient {
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  width: 100%;
  height: 80px;
  padding: 24px;
  position: absolute;
  border-radius: 16px 16px 0 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.6), transparent);
}
.petpooja .petpooja_first_fold .petpooja_right_side .quotes_Img .video-thumbnail .top-gradient p {
  z-index: 1;
  font-size: 18px;
  text-align: center;
  line-height: 22px;
  overflow: hidden;
  white-space: nowrap;
}
.petpooja .petpooja_first_fold .petpooja_right_side .quotes_Img .video-thumbnail .videoimg {
  border-radius: 24px;
}
.petpooja .petpooja_first_fold .petpooja_right_side .quotes_Img .video-thumbnail .play-icon-wrapper {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%) scale(1);
  left: 50%;
  cursor: pointer;
  transition: all 0.3s;
}
.petpooja .petpooja_first_fold .petpooja_right_side .quotes_Img iframe {
  height: 321px;
  border: none;
  outline: none;
  border-radius: 24px;
}
@media (max-width: 992px) {
  .petpooja .petpooja_first_fold .petpooja_right_side .quotes_Img iframe {
    height: 189px;
  }
}
.petpooja .fold1 .sec-title {
  font-size: 32px;
  margin: 0 0 56px;
  line-height: 40px;
  text-align: center;
  letter-spacing: -0.01em;
  font-family: "Basier Circle Semibold";
}
@media (max-width: 992px) {
  .petpooja .fold1 .sec-title {
    font-size: 20px;
    line-height: 24px;
  }
}
.petpooja .fold1 .fold1_wrapper .reeloBrand_slider {
  padding: 0 0 100px;
}
@media (max-width: 992px) {
  .petpooja .fold1 .fold1_wrapper .reeloBrand_slider {
    padding: 0 0 30px;
  }
}
.petpooja .fold1 .fold1_wrapper .reeloBrand_slider .brandSlide {
  height: 120px;
  padding: 0 27px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 30px 24px;
}
@media (max-width: 992px) {
  .petpooja .fold1 .fold1_wrapper .reeloBrand_slider .brandSlide {
    padding: 10px 24px;
    height: 80px;
    width: 120px !important;
  }
}
.petpooja .fold1 .fold1_wrapper .reeloBrand_slider .brandSlide img {
  width: 120px;
  height: 120px;
  object-fit: contain;
}
@media (max-width: 992px) {
  .petpooja .fold1 .fold1_wrapper .reeloBrand_slider .brandSlide img {
    width: 80px;
    height: 80px;
  }
}
.petpooja .display_table {
  flex-direction: unset;
}
@media (max-width: 992px) {
  .petpooja .banner {
    text-align: center;
  }
  .petpooja .banner .common_title {
    font-size: 28px;
    line-height: 32px;
  }
  .petpooja .banner .common_desc {
    font-size: 14px;
    line-height: 22px;
    margin: 0;
  }
}
.petpooja .banner .common_subtitle {
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.18em;
  text-transform: uppercase;
  font-family: "Basier Circle Semibold";
}
@media (max-width: 992px) {
  .petpooja .sec-title {
    font-size: 28px;
    line-height: 42px;
  }
}
.petpooja .sec_title {
  font-size: 42px;
  margin: 0 0 16px;
  line-height: 51px;
  letter-spacing: -0.02em;
  font-weight: 500;
  font-family: "Basier Circle medium";
}
@media (max-width: 992px) {
  .petpooja .sec_title {
    font-size: 28px;
    line-height: 32px;
  }
}
.petpooja .sec_desc {
  font-size: 20px;
  margin: 0 0 24px;
  line-height: 30px;
  font-family: "Basier Circle";
}
.petpooja .common_title {
  font-size: 74px;
  line-height: 78px;
  margin: 16px 0 20px;
  letter-spacing: -0.01em;
  font-family: "Basier Circle medium";
}
.petpooja .common_desc {
  font-size: 20px;
  margin: 0 0 40px;
  line-height: 30px;
  font-family: "Basier Circle";
}
.petpooja .compare_fold_wrapper {
  background: #eaeefe;
}
.petpooja .compare_fold_wrapper .compare_features {
  gap: 40px;
  margin: 0 auto;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: center;
}
.petpooja .compare_fold_wrapper .compare_features .compare_feature_card {
  width: 385px;
  padding: 24px;
  background: #fff;
  border-radius: 12px;
}
.petpooja .compare_fold_wrapper .compare_features .compare_feature_card h3 {
  font-size: 20px;
  color: #182020;
  line-height: 32px;
  margin: 32px 0 8px;
}
.petpooja .compare_fold_wrapper .compare_features .compare_feature_card p {
  font-size: 18px;
  color: #182020;
  line-height: 24px;
  margin-bottom: 32px;
}
@media (max-width: 992px) {
  .petpooja .pre-built .common_title {
    font-size: 28px;
    line-height: 32px;
  }
}
@media (max-width: 992px) {
  .petpooja .pre-built .common_desc {
    font-size: 18px;
  }
}
.petpooja .pre-built .campaigns_feature_card_root {
  gap: 24px;
  margin: 0 auto;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 992px) {
  .petpooja .pre-built .campaigns_feature_card_root {
    display: block;
    border-radius: 16px;
    padding: 0;
  }
}
.petpooja .pre-built .campaigns_feature_card_root .flx {
  align-items: center !important;
}
.petpooja .pre-built .campaigns_feature_card_root .campaigns_feature_card {
  height: 350px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0 24px 0 0;
  border-radius: 16px;
  background: #f3f1ee;
  width: calc(50% - 12px);
}
@media (max-width: 992px) {
  .petpooja .pre-built .campaigns_feature_card_root .campaigns_feature_card {
    display: block;
    padding: 0;
    height: unset;
    width: 100%;
    margin: 0 0 30px;
  }
}
.petpooja .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_left_side {
  padding: 32px 8px 32px 22px;
}
@media (max-width: 992px) {
  .petpooja .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_left_side {
    padding: 34px 8px 34px 22px;
  }
}
.petpooja .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_left_side .campaigns_feature_number {
  padding: 4px 9px;
  width: 28px;
  height: 28px;
  font-size: 16px;
  color: #fff;
  line-height: 22px;
  border-radius: 8px;
  background: #065151;
  font-family: "Basier Circle Semibold";
}
.petpooja .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_left_side h3 {
  margin: 8px 0;
  font-size: 26px;
  color: #0e1b1b;
  line-height: 30px;
  letter-spacing: -0.02em;
  font-family: "Basier Circle Semibold";
}
@media (max-width: 992px) {
  .petpooja .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_left_side h3 {
    font-size: 20px;
    line-height: 24px;
  }
}
.petpooja .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_left_side p {
  font-size: 16px;
  color: #232d2d;
  line-height: 24px;
}
.petpooja .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_right_side {
  width: 100%;
  max-width: 270px;
  position: relative;
  bottom: -6px;
}
@media (max-width: 992px) {
  .petpooja .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_right_side {
    max-width: 250px;
    margin: 0 auto;
  }
}
.petpooja .pre-built .campaigns_feature_card_root .last_card {
  width: 100%;
  height: auto;
}
.petpooja .fold3 .marrows {
  margin: 30px 0 35px;
}
.petpooja .fold3 .marrows svg {
  width: 48px;
  height: 48px;
  background-color: #f3f5f5;
  border-radius: 100%;
  padding: 10px;
  margin: 0 10px;
}
.petpooja .fold3 .marrows svg:hover {
  background-color: #0d9298;
}
.petpooja .fold3 .marrows svg:hover path {
  stroke: #fff;
}
.petpooja .fold3 .marrows #cnext {
  position: relative;
  transform: rotate(180deg);
}
.petpooja .fold3 .reviewCarousel_parent .reviewCarousel {
  gap: 48px;
  padding: 24px;
  height: 381px;
  margin: 0 4px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  border-radius: 24px;
  align-items: stretch;
  background: #e0eaff;
}
@media (max-width: 992px) {
  .petpooja .fold3 .reviewCarousel_parent .reviewCarousel {
    gap: 12px;
    padding: 16px;
    margin: 0 4px;
    border-radius: 8px;
    flex-direction: column;
    height: unset;
  }
}
.petpooja .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_left {
  max-width: 665px;
  overflow: hidden;
  width: calc(50% - 24px);
}
@media (max-width: 992px) {
  .petpooja .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_left {
    max-width: 100%;
    width: 100%;
    overflow: unset;
  }
}
.petpooja .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_left iframe {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  border-radius: 12px;
}
.petpooja .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_right {
  gap: 24px;
  padding: 10px 0 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  max-width: -moz-calc(50% - 24px);
  max-width: calc(50% - 24px);
}
@media (max-width: 992px) {
  .petpooja .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_right {
    max-width: 100%;
    min-width: 100%;
    padding: 15px 0 0;
  }
}
.petpooja .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_right h3 {
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -1.28px;
  font-family: "Basier Circle medium";
  font-weight: 500;
}
@media (max-width: 992px) {
  .petpooja .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_right h3 {
    margin: 0 0 8px;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.24px;
  }
}
.petpooja .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_right .reviewCarousel_review_detail {
  gap: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.petpooja .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_right .reviewCarousel_review_detail .reviewImgs {
  border-radius: 50%;
  object-fit: contain;
  background-color: #fff;
}
.petpooja .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_right .reviewCarousel_review_detail .reviewer_name {
  font-size: 22px;
  line-height: 26px;
  font-family: "Basier Circle medium";
  padding: 0 0 6px;
}
@media (max-width: 992px) {
  .petpooja .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_right .reviewCarousel_review_detail .reviewer_name {
    font-size: 16px;
    line-height: 22px;
  }
}
.petpooja .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_right .reviewCarousel_review_detail .reviewer_post {
  font-size: 14px;
  color: #374040;
  line-height: 20px;
  letter-spacing: -0.14px;
}
@media (max-width: 992px) {
  .petpooja .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_right .reviewCarousel_review_detail .reviewer_post {
    font-size: 12px;
    line-height: 16px;
  }
}
.petpooja .lastsection {
  border-radius: 24px;
  padding: 40px 20px;
}
@media (max-width: 992px) {
  .petpooja .lastsection {
    margin: 0 20px;
  }
}

.comparision .theme-btn {
  padding: 14px 25px 12px;
}
.comparision .sec_title {
  font-size: 40px;
  color: #182020;
  line-height: 60px;
  max-width: 820px;
  margin: 0 auto 24px;
  font-family: "Basier Circle medium";
}
@media (max-width: 992px) {
  .comparision .sec_title {
    font-size: 32px;
    line-height: 48px;
  }
}
.comparision .common_desc {
  font-size: 20px;
  color: #182020;
  line-height: 26px;
  margin: 0 auto 24px;
}
.comparision .comparison_wrapper {
  background: #f5faff;
  padding: 60px 0 0;
}
.comparision .comparison_wrapper .compare_square {
  border-radius: 10px;
  background: #e6f2ff;
}
.comparision .comparison_wrapper .compare_square_1 {
  top: 20vh;
  left: 80px;
  width: 84px;
  height: 84px;
  position: absolute;
}
.comparision .comparison_wrapper .compare_square_2 {
  left: 200px;
  width: 150px;
  height: 150px;
  position: absolute;
  border-radius: 30px;
  top: calc(20vh + 124px);
}
.comparision .comparison_wrapper .compare_square_3 {
  top: 20vh;
  right: 80px;
  width: 40px;
  height: 40px;
  position: absolute;
}
.comparision .comparison_wrapper .compare_square_4 {
  right: 200px;
  width: 64px;
  height: 64px;
  position: absolute;
  top: calc(20vh + 100px);
}
.comparision .comparison_wrapper .compare_square_5 {
  right: 40px;
  width: 40px;
  height: 40px;
  position: absolute;
  top: calc(20vh + 300px);
}
.comparision .comparison_wrapper .compare_fold1 {
  gap: 40px;
  z-index: 1;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 992px) {
  .comparision .comparison_wrapper .compare_fold1 {
    flex-wrap: wrap;
    gap: 0;
  }
}
.comparision .comparison_wrapper .compare_fold1 .compare_left_side {
  max-width: 500px;
}
.comparision .comparison_wrapper .compare_fold1 .compare_left_side .common_title {
  font-size: 48px;
  color: #182020;
  line-height: 72px;
  position: relative;
  margin: 50px auto 40px;
  font-family: "Basier Circle medium";
}
@media (max-width: 992px) {
  .comparision .comparison_wrapper .compare_fold1 .compare_left_side .common_title {
    font-size: 32px;
    line-height: 48px;
  }
}
.comparision .comparison_wrapper .compare_fold1 .compare_right_side {
  margin: 40px 0;
  max-width: 540px;
}
.comparision .comparison_wrapper .compare_fold1 .compare_right_side .quotes_Img {
  margin: 50px 0 0;
}
@media (max-width: 992px) {
  .comparision .comparison_wrapper .compare_fold1 .compare_right_side .quotes_Img {
    margin: 0;
  }
}
.comparision .comparison_wrapper .compare_fold1 .compare_right_side .quotes_Img .video-thumbnail .top-gradient {
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  width: 100%;
  height: 80px;
  padding: 24px;
  position: absolute;
  border-radius: 16px 16px 0 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.6), transparent);
}
.comparision .comparison_wrapper .compare_fold1 .compare_right_side .quotes_Img .video-thumbnail .top-gradient p {
  z-index: 1;
  font-size: 18px;
  text-align: center;
  line-height: 22px;
  overflow: hidden;
  white-space: nowrap;
}
.comparision .comparison_wrapper .compare_fold1 .compare_right_side .quotes_Img .video-thumbnail .videoimg {
  border-radius: 24px;
}
.comparision .comparison_wrapper .compare_fold1 .compare_right_side .quotes_Img .video-thumbnail .play-icon-wrapper {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%) scale(1);
  left: 50%;
  cursor: pointer;
  transition: all 0.3s;
}
.comparision .comparison_wrapper .compare_fold1 .compare_right_side .quotes_Img iframe {
  height: 321px;
  width: 540px;
  border: none;
  outline: none;
  border-radius: 24px;
}
@media (max-width: 992px) {
  .comparision .comparison_wrapper .compare_fold1 .compare_right_side .quotes_Img iframe {
    height: 189px;
    width: 100%;
  }
}
.comparision .fold1 .sec-title {
  font-size: 32px;
  margin: 0 0 56px;
  line-height: 40px;
  text-align: center;
  letter-spacing: -0.01em;
  font-family: "Basier Circle Semibold";
}
.comparision .fold1 .compare_brands {
  gap: 56px;
  flex-wrap: wrap;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: center;
  justify-content: center;
  margin-bottom: 40px;
}
@media (max-width: 992px) {
  .comparision .fold1 .compare_brands {
    gap: 24px;
  }
  .comparision .fold1 .compare_brands img {
    width: 88px;
  }
}
.comparision .compare_fold_wrapper {
  background: #eaeefe;
}
@media (max-width: 992px) {
  .comparision .compare_fold_wrapper .sec-title {
    font-size: 32px;
    line-height: 48px;
  }
}
.comparision .compare_fold_wrapper .compare_features {
  gap: 40px;
  margin: 0 auto;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: center;
}
.comparision .compare_fold_wrapper .compare_features .compare_feature_card {
  width: 385px;
  padding: 24px;
  background: #fff;
  border-radius: 12px;
}
.comparision .compare_fold_wrapper .compare_features .compare_feature_card h3 {
  font-size: 20px;
  color: #182020;
  line-height: 32px;
  margin: 32px 0 8px;
  font-family: "Basier Circle medium";
}
.comparision .compare_fold_wrapper .compare_features .compare_feature_card p {
  font-size: 18px;
  color: #182020;
  line-height: 24px;
  margin-bottom: 32px;
}
.comparision .compare_customer_reviews {
  gap: 24px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
@media (max-width: 992px) {
  .comparision .compare_customer_reviews {
    flex-wrap: wrap;
  }
}
.comparision .compare_customer_reviews .compare_review_box {
  height: 100%;
  border-radius: 16px;
  background: #f5f5f5;
}
.comparision .compare_customer_reviews .compare_review_box .quotes_Img .video-thumbnail .top-gradient {
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  width: 100%;
  height: 80px;
  padding: 24px;
  position: absolute;
  border-radius: 16px 16px 0 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.6), transparent);
}
.comparision .compare_customer_reviews .compare_review_box .quotes_Img .video-thumbnail .top-gradient p {
  z-index: 1;
  font-size: 18px;
  text-align: center;
  line-height: 22px;
  overflow: hidden;
  white-space: nowrap;
}
.comparision .compare_customer_reviews .compare_review_box .quotes_Img .video-thumbnail .videoimg {
  border-radius: 14px;
  height: 195px;
  width: 347px;
}
.comparision .compare_customer_reviews .compare_review_box .quotes_Img .video-thumbnail .play-icon-wrapper {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%) scale(1);
  left: 50%;
  cursor: pointer;
  transition: all 0.3s;
}
.comparision .compare_customer_reviews .compare_review_box .quotes_Img iframe {
  height: 195px;
  width: 347px;
  border: none;
  outline: none;
  border-radius: 24px;
}
@media (max-width: 992px) {
  .comparision .compare_customer_reviews .compare_review_box .quotes_Img iframe {
    height: 189px;
    width: 100%;
  }
}
.comparision .compare_customer_reviews .compare_review_box .compare_video_dets {
  gap: 16px;
  padding: 16px;
  max-width: 347px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 117px;
}
.comparision .compare_customer_reviews .compare_review_box .compare_video_dets h3 {
  font-size: 20px;
  margin: 0 0 8px;
  color: #182020;
  line-height: 26px;
  font-family: "Basier Circle Semibold";
}
.comparision .compare_customer_reviews .compare_review_box .compare_video_dets p {
  margin: 0;
  font-size: 16px;
  color: #616d6d;
  line-height: 21px;
}
.comparision .compare_common_fold {
  gap: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 992px) {
  .comparision .compare_common_fold {
    flex-direction: column-reverse;
  }
}
.comparision .compare_common_fold .compare_left_side {
  max-width: 540px;
}
.comparision .compare_common_fold .compare_left_side .sec_title {
  font-size: 48px;
  color: #182020;
  line-height: 58px;
  margin: 8px 0 24px;
  font-family: "Basier Circle medium";
}
@media (max-width: 992px) {
  .comparision .compare_common_fold .compare_left_side .sec_title {
    font-size: 24px;
    line-height: 30px;
  }
}
.comparision .compare_common_fold .compare_left_side .p2 {
  padding-bottom: 20px;
}
.comparision .compare_common_fold .compare_right_side {
  max-width: 540px;
}
.comparision .compare_fold_2 {
  background: #fdf5ff;
}
.comparision .compare_fold_2 .compare_pos_row {
  gap: 24px;
  margin: 40px auto 0;
  flex-wrap: wrap;
  max-width: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 992px) {
  .comparision .compare_fold_2 .compare_pos_row {
    gap: 16px;
  }
}
.comparision .compare_fold_2 .compare_pos_row .compare_pos_div {
  width: 120px;
  height: 120px;
  padding: 10px;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 4px 34px rgba(0, 0, 0, 0.1);
  position: relative;
}
@media (max-width: 992px) {
  .comparision .compare_fold_2 .compare_pos_row .compare_pos_div {
    width: 90px;
    height: 90px;
  }
}
.comparision .compare_fold_2 .compare_pos_row .compare_pos_div img {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  padding: 10px;
}
.comparision .compare_fold_3 .compare_business_types {
  gap: 80px;
  flex-wrap: wrap;
  margin-bottom: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 90px 0 100px;
}
@media (max-width: 992px) {
  .comparision .compare_fold_3 .compare_business_types {
    gap: 40px;
  }
}
.comparision .compare_fold_3 .compare_business_types .compare_business h3 {
  margin: 24px 0 0;
  font-size: 24px;
  color: #182020;
  line-height: 26px;
}
.comparision .compare_fold_3 .cta-action-card {
  gap: 40px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 37px 40px;
  position: relative;
  border-radius: 16px;
  background: #1a424f;
}
@media (max-width: 992px) {
  .comparision .compare_fold_3 .cta-action-card {
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px;
  }
}
.comparision .compare_fold_3 .cta-action-card .ctaActionBg {
  position: absolute;
  right: 0;
  top: 0;
}
@media (max-width: 992px) {
  .comparision .compare_fold_3 .cta-action-card .ctaActionBg {
    bottom: 0;
    height: 100%;
  }
}
.comparision .compare_fold_3 .cta-action-card .content {
  z-index: 1;
  position: relative;
}
.comparision .compare_fold_3 .cta-action-card .content h3 {
  font-size: 32px;
  margin: 0 0 24px;
  line-height: 38px;
  font-family: "Basier Circle medium";
}
@media (max-width: 992px) {
  .comparision .compare_fold_3 .cta-action-card .content h3 {
    font-size: 24px;
    line-height: 29px;
  }
}
.comparision .compare_fold_3 .cta-action-card .content p {
  margin: 0;
  font-size: 18px;
  line-height: 24px;
}
.comparision .compare_fold_4 {
  background: #e1f3f4;
}
.comparision .compare_fold_4 .marrows #cprev {
  position: absolute;
  left: 20px;
  top: 40%;
  z-index: 1;
}
.comparision .compare_fold_4 .marrows #cnext {
  position: absolute;
  right: 20px;
  top: 40%;
  z-index: 1;
  transform: rotate(180deg);
}
.comparision .compare_fold_4 .compare_slick_slider .compare_template {
  height: 360px;
  margin: 0 24px;
}
.comparision .compare_fold_4 .compare_slick_slider .compare_template .compare_template_card {
  width: 264px;
  height: 264px;
  margin: 0 0 16px;
  border-radius: 8px;
  position: relative;
  transition: 0.1s linear;
}
@media (max-width: 992px) {
  .comparision .compare_fold_4 .compare_slick_slider .compare_template .compare_template_card {
    width: 100%;
  }
}
.comparision .compare_fold_4 .compare_slick_slider .compare_template .compare_template_card:after {
  top: 0;
  left: 0;
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  border-radius: 15px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0, hsla(0, 0%, 100%, 0) 80%);
}
.comparision .compare_fold_4 .compare_slick_slider .compare_template .compare_template_card .compare_template_img img {
  object-fit: cover;
  border-radius: 15px;
  vertical-align: middle;
  height: 264px;
  width: 100%;
}
.comparision .compare_fold_4 .compare_slick_slider .compare_template .compare_template_card .compare_template_title {
  left: 0;
  top: 16px;
  z-index: 2;
  height: 60%;
  color: #fff;
  font-size: 24px;
  padding: 0 16px;
  line-height: 29px;
  position: absolute;
  word-wrap: break-word;
  font-family: "Basier Circle medium";
  font-weight: 700;
}
.comparision .compare_fold_4 .compare_slick_slider .compare_template .compare_template_objective {
  margin: 0;
  font-size: 20px;
  color: #000;
  max-width: 220px;
  line-height: 24px;
  font-family: "Basier Circle medium";
  font-weight: 700;
}

.restaurant-trend .common_desc {
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.4px;
  padding: 0 0 20px;
}
@media (max-width: 992px) {
  .restaurant-trend .common_desc {
    font-size: 20px;
    padding: 15px 0 20px;
  }
}
.restaurant-trend .tagline_wrapper {
  padding: 70px 0 0;
}
.restaurant-trend .tagline_wrapper .common_title {
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: 121%;
  font-family: "Basier Circle medium";
  letter-spacing: -0.56px;
}
@media (max-width: 992px) {
  .restaurant-trend .tagline_wrapper .common_title {
    font-size: 36px;
  }
}
.restaurant-trend .benifits_section {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
  margin: 100px 0 0;
}
@media (max-width: 992px) {
  .restaurant-trend .benifits_section {
    flex-wrap: wrap;
    margin: 0;
    gap: 0;
  }
}
.restaurant-trend .benifits_section .bene_div {
  padding: 32px 30px;
  border-radius: 12px;
  width: 367px;
}
@media (max-width: 992px) {
  .restaurant-trend .benifits_section .bene_div {
    width: 100%;
    margin: 30px 0 0;
  }
}
.restaurant-trend .benifits_section .bene_div p {
  color: #171E59;
  font-size: 17.923px;
  font-style: normal;
  font-weight: 500;
  line-height: 110%;
  letter-spacing: -0.358px;
}
.restaurant-trend .benifits_section .bene_div span {
  font-size: 43.015px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.86px;
  font-family: "Basier Circle Semibold";
  display: block;
}
.restaurant-trend .benifits_section .bene_div .green {
  color: #16b364;
}
.restaurant-trend .benifits_section .bene_div .red {
  color: #ef6820;
}
.restaurant-trend .benifits_section .bene_div .blue {
  color: #1570ef;
}
.restaurant-trend .benifits_section .b1 {
  background: #EDFCF2;
}
.restaurant-trend .benifits_section .b2 {
  background: #FEF6EE;
}
.restaurant-trend .benifits_section .b3 {
  background: #F5F8FF;
}
.restaurant-trend #outer_div .Vector {
  position: absolute;
  top: -28%;
  z-index: -1;
  left: 0;
  width: 100%;
}
@media (max-width: 992px) {
  .restaurant-trend #outer_div .Vector {
    top: 0;
  }
}
.restaurant-trend #outer_div .survey_fold1 .fold_wrapper .fold {
  padding: 160px 0;
}
@media (max-width: 992px) {
  .restaurant-trend #outer_div .survey_fold1 .fold_wrapper .fold {
    padding: 0 0 40px;
  }
}
.restaurant-trend #outer_div .survey_fold1 .fold_wrapper .fold .common_title {
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
  line-height: 112.3%;
  letter-spacing: -1.12px;
  padding-bottom: 15px;
}
@media (max-width: 992px) {
  .restaurant-trend #outer_div .survey_fold1 .fold_wrapper .fold .common_title {
    font-size: 28px;
    line-height: 32px;
    padding: 15px 0 0;
  }
}
.restaurant-trend #outer_div .survey_fold1 .fold_wrapper .fold .fold1img {
  border-radius: 12px;
  position: absolute;
  right: 0;
  top: -4%;
}
@media (max-width: 992px) {
  .restaurant-trend #outer_div .survey_fold1 .fold_wrapper .fold .fold1img {
    position: unset;
  }
}
.restaurant-trend #outer_div .survey_fold1 .fold_wrapper .fold .fold2img {
  position: absolute;
  left: 0;
  top: -20%;
}
@media (max-width: 992px) {
  .restaurant-trend #outer_div .survey_fold1 .fold_wrapper .fold .fold2img {
    position: unset;
  }
}
.restaurant-trend #outer_div .survey_fold1 .fold_wrapper .fold .fold3img {
  position: absolute;
  right: 0;
  top: -20%;
}
@media (max-width: 992px) {
  .restaurant-trend #outer_div .survey_fold1 .fold_wrapper .fold .fold3img {
    position: unset;
  }
}
.restaurant-trend #outer_div .survey_fold1 .fold_wrapper .fold2 {
  text-align: right;
}
@media (max-width: 992px) {
  .restaurant-trend #outer_div .survey_fold1 .fold_wrapper .fold2 {
    text-align: center;
  }
}
.restaurant-trend .survey_fold2 .survey1 {
  border-radius: 40px 40px 0px 0px;
  background: #F7E49B;
  padding: 40px;
}
@media (max-width: 992px) {
  .restaurant-trend .survey_fold2 .survey1 {
    padding: 20px;
  }
}
.restaurant-trend .survey_fold2 .survey1 .tag {
  border-radius: 900px;
  background: #EBBD07;
  color: #5F4D07;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  padding: 12px 30px;
  display: inline-block;
}
.restaurant-trend .survey_fold2 .survey1 ol {
  padding: 40px 0 0 40px;
}
@media (max-width: 992px) {
  .restaurant-trend .survey_fold2 .survey1 ol {
    padding: 20px 0 0 20px;
  }
}
.restaurant-trend .survey_fold2 .survey1 ol li {
  color: #000;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
@media (max-width: 992px) {
  .restaurant-trend .survey_fold2 .survey1 ol li {
    font-size: 20px;
  }
}
.restaurant-trend .survey_fold2 .survey2 {
  border-radius: 0px 0px 40px 40px;
  background: #065151;
  padding: 10px 40px;
}
@media (max-width: 992px) {
  .restaurant-trend .survey_fold2 .survey2 {
    padding: 10px 20px;
  }
}
.restaurant-trend .survey_fold2 .survey2 .gift {
  position: absolute;
  right: -68px;
  top: -71px;
}
@media (max-width: 992px) {
  .restaurant-trend .survey_fold2 .survey2 .gift {
    right: -32px;
    top: unset;
    width: 159px;
    bottom: -21px;
  }
}
.restaurant-trend .survey_fold2 .survey2 p {
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 43.2px */
  letter-spacing: -0.36px;
  padding: 30px 0 0;
}
@media (max-width: 992px) {
  .restaurant-trend .survey_fold2 .survey2 p {
    font-size: 20px;
  }
}

.event_wrapper .event_firstFold {
  gap: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0 0;
}
@media (max-width: 1024px) {
  .event_wrapper .event_firstFold {
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
  }
}
.event_wrapper .event_firstFold .event_left_fold {
  max-width: 620px;
}
@media (max-width: 1024px) {
  .event_wrapper .event_firstFold .event_left_fold {
    max-width: 100%;
    text-align: center;
  }
}
.event_wrapper .event_firstFold .event_left_fold .venue_wrapper {
  display: flex;
  justify-content: center;
  gap: 15px;
  padding: 30px 0 0;
}
@media (max-width: 992px) {
  .event_wrapper .event_firstFold .event_left_fold .venue_wrapper {
    padding: 0 0 20px;
  }
}
.event_wrapper .event_firstFold .event_left_fold .venue_wrapper .venue {
  width: 198px;
  height: 146px;
  padding: 16px 10px;
  gap: 12px;
  border-radius: 24px;
  color: #fff;
  text-align: center;
}
@media (max-width: 992px) {
  .event_wrapper .event_firstFold .event_left_fold .venue_wrapper .venue {
    height: 100px;
    padding: 16px 5px;
    width: 102px;
  }
}
.event_wrapper .event_firstFold .event_left_fold .venue_wrapper .venue .venue_title {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  padding: 0 0 15px;
}
@media (max-width: 992px) {
  .event_wrapper .event_firstFold .event_left_fold .venue_wrapper .venue .venue_title {
    padding: 0 0 7px;
  }
}
.event_wrapper .event_firstFold .event_left_fold .venue_wrapper .venue .venue_title img {
  width: 14px;
}
.event_wrapper .event_firstFold .event_left_fold .venue_wrapper .venue .venue_title span {
  font-size: 14px;
  line-height: 110%;
}
@media (max-width: 992px) {
  .event_wrapper .event_firstFold .event_left_fold .venue_wrapper .venue .venue_title span {
    font-size: 9px;
  }
}
.event_wrapper .event_firstFold .event_left_fold .venue_wrapper .venue p {
  font-size: 20px;
  font-weight: 500;
  line-height: 120%;
}
@media (max-width: 992px) {
  .event_wrapper .event_firstFold .event_left_fold .venue_wrapper .venue p {
    font-size: 10px;
  }
}
.event_wrapper .event_firstFold .event_left_fold .venue_wrapper .v1 {
  background: #4d80bd;
}
.event_wrapper .event_firstFold .event_left_fold .venue_wrapper .v2 {
  background: #efba50;
}
.event_wrapper .event_firstFold .event_left_fold .venue_wrapper .v3 {
  background: #5fcfbe;
}
.event_wrapper .event_firstFold .event_right_fold {
  width: 100%;
  max-width: 571px;
}
@media (max-width: 1024px) {
  .event_wrapper .event_firstFold .event_right_fold {
    max-width: 100%;
  }
}
.event_wrapper .event_firstFold .event_right_fold .hurry {
  background: #BEF8F8;
  border-radius: 24px;
  padding: 23px 30px;
}
@media (max-width: 992px) {
  .event_wrapper .event_firstFold .event_right_fold .hurry {
    padding: 20px;
    margin: 0;
  }
}
.event_wrapper .event_firstFold .event_right_fold .hurry .sec_title {
  font-size: 28px;
  line-height: 38px;
  letter-spacing: -0.02em;
  font-weight: 500;
  font-family: "Basier Circle Semibold";
  margin: 0;
}
@media (max-width: 992px) {
  .event_wrapper .event_firstFold .event_right_fold .hurry .sec_title {
    font-size: 24px;
    line-height: 28px;
  }
}
.event_wrapper .display_table {
  flex-direction: unset;
}
@media (max-width: 992px) {
  .event_wrapper .banner {
    padding: 60px 0 0;
    text-align: center;
  }
  .event_wrapper .banner .common_title {
    font-size: 28px;
    line-height: 32px;
  }
  .event_wrapper .banner .common_desc {
    font-size: 14px;
    line-height: 22px;
  }
}
@media (max-width: 992px) {
  .event_wrapper .sec-light {
    letter-spacing: -0.02em;
    font-weight: 500;
    font-family: "Basier Circle medium";
    font-size: 24px;
    line-height: 28px;
  }
}
@media (max-width: 992px) {
  .event_wrapper .sec-title {
    font-size: 24px;
    line-height: 28px;
  }
}
.event_wrapper .sec_title {
  font-size: 42px;
  line-height: 51px;
  letter-spacing: -0.02em;
  font-weight: 500;
  font-family: "Basier Circle medium";
}
@media (max-width: 992px) {
  .event_wrapper .sec_title {
    font-size: 24px;
    line-height: 28px;
  }
}
.event_wrapper .sec_desc {
  font-size: 20px;
  margin: 0 0 24px;
  line-height: 30px;
  font-family: "Basier Circle";
}
.event_wrapper .common_title {
  font-size: 56px;
  margin: 0 0 16px;
  line-height: 62px;
  letter-spacing: -0.01em;
  font-family: "Basier Circle medium";
}
.event_wrapper .common_desc {
  font-size: 20px;
  margin: 0 0 40px;
  line-height: 30px;
  font-family: "Basier Circle";
}
.event_wrapper .loyaly_div {
  width: 557px;
  margin: 70px auto 0;
}
@media (max-width: 992px) {
  .event_wrapper .loyaly_div {
    width: 100%;
    margin: 0;
  }
}
.event_wrapper .fold1 .sec-title {
  font-size: 32px;
  margin: 0 0 56px;
  line-height: 40px;
  text-align: center;
  letter-spacing: -0.01em;
  font-family: "Basier Circle Semibold";
}
@media (max-width: 992px) {
  .event_wrapper .fold1 .sec-title {
    font-size: 20px;
    line-height: 24px;
  }
}
.event_wrapper .fold1 .fold1_wrapper .reeloBrand_slider {
  padding: 0 0 30px;
}
.event_wrapper .fold1 .fold1_wrapper .reeloBrand_slider .brandSlide {
  height: 120px;
  padding: 0 27px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 30px 24px;
}
@media (max-width: 992px) {
  .event_wrapper .fold1 .fold1_wrapper .reeloBrand_slider .brandSlide {
    padding: 10px 24px;
    height: 80px;
    width: 120px !important;
  }
}
.event_wrapper .fold1 .fold1_wrapper .reeloBrand_slider .brandSlide img {
  width: 120px;
  height: 120px;
  object-fit: contain;
}
@media (max-width: 992px) {
  .event_wrapper .fold1 .fold1_wrapper .reeloBrand_slider .brandSlide img {
    width: 80px;
    height: 80px;
  }
}
.event_wrapper .meet-us {
  border-radius: 40px;
  padding: 70px;
}
@media (max-width: 1024px) {
  .event_wrapper .meet-us {
    padding: 30px;
  }
}
@media (max-width: 767px) {
  .event_wrapper .meet-us {
    padding: 20px;
  }
}
.event_wrapper .meet-us .meet_us_wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
@media (max-width: 992px) {
  .event_wrapper .meet-us .meet_us_wrapper {
    display: block;
  }
}
.event_wrapper .meet-us .meet_us_wrapper .meet {
  border-radius: 16px;
  background: #F3F1EE;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  padding: 36px;
  margin: 10px;
}
@media (max-width: 992px) {
  .event_wrapper .meet-us .meet_us_wrapper .meet {
    padding: 10px;
    margin: 0 0 15px;
  }
  .event_wrapper .meet-us .meet_us_wrapper .meet img {
    width: 65px;
  }
}
.event_wrapper .meet-us .meet_us_wrapper .meet p {
  font-size: 28px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.56px;
}
@media (max-width: 992px) {
  .event_wrapper .meet-us .meet_us_wrapper .meet p {
    font-size: 20px;
    line-height: 30px;
  }
}
.event_wrapper .woh_meets .woh_steps {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  padding: 0 0 60px;
}
@media (max-width: 992px) {
  .event_wrapper .woh_meets .woh_steps {
    padding: 0 0 30px;
  }
}
.event_wrapper .woh_meets .woh_steps .bonus_left {
  max-width: 200px !important;
}
@media (max-width: 992px) {
  .event_wrapper .woh_meets .woh_steps .bonus_left {
    max-width: 110px !important;
  }
}
.event_wrapper .woh_meets .woh_steps .bonus_left:before {
  position: unset !important;
}
.event_wrapper .woh_meets .woh_steps .step_left {
  max-width: 175px;
  z-index: 1;
}
@media (max-width: 992px) {
  .event_wrapper .woh_meets .woh_steps .step_left {
    max-width: 110px;
  }
}
.event_wrapper .woh_meets .woh_steps .step_left:before {
  position: absolute;
  content: "";
  width: 3px;
  height: 94px;
  background-color: #7c0489;
  right: 40px;
  top: 69px;
}
@media (max-width: 992px) {
  .event_wrapper .woh_meets .woh_steps .step_left:before {
    height: 42px;
    right: 25px;
    top: 46px;
  }
}
.event_wrapper .woh_meets .woh_steps .step_left .steps {
  border-radius: 45px;
  background: #ECE6DC;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  gap: 20px;
}
@media (max-width: 992px) {
  .event_wrapper .woh_meets .woh_steps .step_left .steps {
    padding: 8px 11px;
    gap: 6px;
    justify-content: space-between;
  }
}
.event_wrapper .woh_meets .woh_steps .step_left .steps p {
  color: #570360;
  font-size: 34px;
  font-weight: 900;
  line-height: 130%;
  letter-spacing: -0.678px;
}
@media (max-width: 992px) {
  .event_wrapper .woh_meets .woh_steps .step_left .steps p {
    font-size: 16px;
  }
}
.event_wrapper .woh_meets .woh_steps .step_left .steps .bonus {
  color: #CD8810;
}
.event_wrapper .woh_meets .woh_steps .step_left .steps .number {
  width: 54.484px;
  height: 54.484px;
  border-radius: 100px;
  background-color: #C998CF;
  padding: 4px 0px;
}
@media (max-width: 992px) {
  .event_wrapper .woh_meets .woh_steps .step_left .steps .number {
    width: 30px;
    height: 30px;
  }
}
.event_wrapper .woh_meets .woh_steps .step_left .steps .number span {
  font-size: 34px;
  font-weight: 900;
  line-height: 130%;
  letter-spacing: -0.678px;
  padding: 18px;
}
@media (max-width: 992px) {
  .event_wrapper .woh_meets .woh_steps .step_left .steps .number span {
    font-size: 20px;
    padding: 10px;
  }
}
.event_wrapper .woh_meets .woh_steps .step_left .steps .number img {
  padding: 8px 11px;
}
@media (max-width: 992px) {
  .event_wrapper .woh_meets .woh_steps .step_left .steps .number img {
    padding: 2px 6px;
  }
}
.event_wrapper .woh_meets .woh_steps .step_left .steps .y {
  background-color: #CD8810;
}
.event_wrapper .woh_meets .woh_steps .step_right {
  max-width: 500px;
}
.event_wrapper .woh_meets .woh_steps .step_right p {
  font-size: 28px;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.56px;
}
@media (max-width: 992px) {
  .event_wrapper .woh_meets .woh_steps .step_right p {
    font-size: 16px;
  }
}
.event_wrapper .lastsection {
  border-radius: 24px;
  padding: 40px 20px;
}
@media (max-width: 992px) {
  .event_wrapper .lastsection {
    margin: 0 20px;
  }
}

.aahar .event_firstFold {
  gap: 24px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 20px 0 0;
}
@media (max-width: 1024px) {
  .aahar .event_firstFold {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 40px;
  }
}
.aahar .event_firstFold .event_left_fold {
  max-width: 620px;
  padding: 30px 0 0;
}
@media (max-width: 1024px) {
  .aahar .event_firstFold .event_left_fold {
    max-width: 100%;
    text-align: center;
    padding: 0;
  }
}
.aahar .event_firstFold .event_left_fold .venue_wrapper {
  display: flex;
  justify-content: center;
  gap: 15px;
  padding: 30px 0 0;
}
@media (max-width: 992px) {
  .aahar .event_firstFold .event_left_fold .venue_wrapper {
    padding: 0 0 20px;
  }
}
.aahar .event_firstFold .event_left_fold .venue_wrapper .venue {
  width: 198px;
  height: 146px;
  padding: 16px 9px;
  gap: 12px;
  border-radius: 24px;
  color: #fff;
  text-align: center;
}
@media (max-width: 992px) {
  .aahar .event_firstFold .event_left_fold .venue_wrapper .venue {
    height: 100px;
    padding: 16px 5px;
    width: 102px;
  }
}
.aahar .event_firstFold .event_left_fold .venue_wrapper .venue .venue_title {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  padding: 0 0 20px;
}
@media (max-width: 992px) {
  .aahar .event_firstFold .event_left_fold .venue_wrapper .venue .venue_title {
    padding: 0 0 7px;
  }
}
.aahar .event_firstFold .event_left_fold .venue_wrapper .venue .venue_title img {
  width: 14px;
}
.aahar .event_firstFold .event_left_fold .venue_wrapper .venue .venue_title span {
  font-size: 14px;
  line-height: 110%;
}
@media (max-width: 992px) {
  .aahar .event_firstFold .event_left_fold .venue_wrapper .venue .venue_title span {
    font-size: 9px;
  }
}
.aahar .event_firstFold .event_left_fold .venue_wrapper .venue p {
  font-size: 20px;
  font-weight: 500;
  line-height: 120%;
}
@media (max-width: 992px) {
  .aahar .event_firstFold .event_left_fold .venue_wrapper .venue p {
    font-size: 10px;
  }
}
.aahar .event_firstFold .event_left_fold .venue_wrapper .v1 {
  background: #4d80bd;
}
.aahar .event_firstFold .event_left_fold .venue_wrapper .v2 {
  background: #efba50;
}
.aahar .event_firstFold .event_left_fold .venue_wrapper .v3 {
  background: #5fcfbe;
}
.aahar .event_firstFold .event_right_fold {
  width: 100%;
  max-width: 571px;
}
@media (max-width: 1024px) {
  .aahar .event_firstFold .event_right_fold {
    max-width: 100%;
  }
}
.aahar .event_firstFold .event_right_fold .hurry {
  background: #BEF8F8;
  border-radius: 24px;
  padding: 23px 30px;
}
@media (max-width: 992px) {
  .aahar .event_firstFold .event_right_fold .hurry {
    padding: 20px;
    margin: 0;
  }
}
.aahar .event_firstFold .event_right_fold .hurry .sec_title {
  font-size: 28px;
  line-height: 38px;
  letter-spacing: -0.02em;
  font-weight: 500;
  font-family: "Basier Circle Semibold";
  margin: 0;
}
@media (max-width: 992px) {
  .aahar .event_firstFold .event_right_fold .hurry .sec_title {
    font-size: 24px;
    line-height: 28px;
  }
}
.aahar .display_table {
  flex-direction: unset;
}
@media (max-width: 992px) {
  .aahar .banner {
    padding: 60px 0 0;
    text-align: center;
  }
  .aahar .banner .common_title {
    font-size: 28px;
    line-height: 32px;
  }
  .aahar .banner .common_desc {
    font-size: 14px;
    line-height: 22px;
  }
}
@media (max-width: 992px) {
  .aahar .sec-light {
    letter-spacing: -0.02em;
    font-weight: 500;
    font-family: "Basier Circle medium";
    font-size: 24px;
    line-height: 28px;
  }
}
@media (max-width: 992px) {
  .aahar .sec-title {
    font-size: 24px;
    line-height: 28px;
  }
}
.aahar .sec_title {
  font-size: 42px;
  line-height: 51px;
  letter-spacing: -0.02em;
  font-weight: 500;
  font-family: "Basier Circle medium";
}
@media (max-width: 992px) {
  .aahar .sec_title {
    font-size: 24px;
    line-height: 28px;
  }
}
.aahar .sec_desc {
  font-size: 20px;
  margin: 0 0 24px;
  line-height: 30px;
  font-family: "Basier Circle";
}
.aahar .common_title {
  font-size: 56px;
  margin: 0 0 16px;
  line-height: 62px;
  letter-spacing: -0.01em;
  font-family: "Basier Circle medium";
}
.aahar .common_desc {
  font-size: 20px;
  line-height: 30px;
  margin: 0 0 30px;
  font-family: "Basier Circle";
}
.aahar .loyaly_div {
  width: 557px;
  margin: 70px auto 0;
}
@media (max-width: 992px) {
  .aahar .loyaly_div {
    width: 100%;
    margin: 0;
  }
}
.aahar .fold1 .container {
  padding: 0;
}
@media (max-width: 992px) {
  .aahar .fold1 .container {
    padding: 0 20px;
  }
}
.aahar .fold1 .sec-title {
  font-size: 32px;
  margin: 0 0 56px;
  line-height: 40px;
  text-align: center;
  letter-spacing: -0.01em;
  font-family: "Basier Circle Semibold";
}
@media (max-width: 992px) {
  .aahar .fold1 .sec-title {
    font-size: 20px;
    line-height: 24px;
  }
}
.aahar .fold1 .fold1_wrapper .reeloBrand_slider {
  padding: 0 0 30px;
}
.aahar .fold1 .fold1_wrapper .reeloBrand_slider .brandSlide {
  height: 120px;
  padding: 0 27px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 30px 24px;
}
@media (max-width: 992px) {
  .aahar .fold1 .fold1_wrapper .reeloBrand_slider .brandSlide {
    padding: 10px 24px;
    height: 80px;
    width: 120px !important;
  }
}
.aahar .fold1 .fold1_wrapper .reeloBrand_slider .brandSlide img {
  width: 120px;
  height: 120px;
  object-fit: contain;
}
@media (max-width: 992px) {
  .aahar .fold1 .fold1_wrapper .reeloBrand_slider .brandSlide img {
    width: 80px;
    height: 80px;
  }
}
.aahar .meet-us {
  border-radius: 40px;
  padding: 70px;
}
@media (max-width: 1024px) {
  .aahar .meet-us {
    padding: 30px;
  }
}
@media (max-width: 767px) {
  .aahar .meet-us {
    padding: 20px;
  }
}
.aahar .meet-us .meet_us_wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
@media (max-width: 992px) {
  .aahar .meet-us .meet_us_wrapper {
    display: block;
  }
}
.aahar .meet-us .meet_us_wrapper .meet {
  border-radius: 16px;
  background: #F3F1EE;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  padding: 36px;
  margin: 10px;
}
@media (max-width: 992px) {
  .aahar .meet-us .meet_us_wrapper .meet {
    padding: 10px;
    margin: 0 0 15px;
  }
  .aahar .meet-us .meet_us_wrapper .meet img {
    width: 65px;
  }
}
.aahar .meet-us .meet_us_wrapper .meet p {
  font-size: 28px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.56px;
}
@media (max-width: 992px) {
  .aahar .meet-us .meet_us_wrapper .meet p {
    font-size: 20px;
    line-height: 30px;
  }
}
.aahar .woh_meets .woh_steps {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  padding: 0 0 60px;
}
@media (max-width: 992px) {
  .aahar .woh_meets .woh_steps {
    padding: 0 0 30px;
  }
}
.aahar .woh_meets .woh_steps .bonus_left {
  max-width: 200px !important;
}
@media (max-width: 992px) {
  .aahar .woh_meets .woh_steps .bonus_left {
    max-width: 110px !important;
  }
}
.aahar .woh_meets .woh_steps .bonus_left:before {
  position: unset !important;
}
.aahar .woh_meets .woh_steps .step_left {
  max-width: 175px;
  z-index: 1;
}
@media (max-width: 992px) {
  .aahar .woh_meets .woh_steps .step_left {
    max-width: 110px;
  }
}
.aahar .woh_meets .woh_steps .step_left:before {
  position: absolute;
  content: "";
  width: 3px;
  height: 94px;
  background-color: #7c0489;
  right: 40px;
  top: 69px;
}
@media (max-width: 992px) {
  .aahar .woh_meets .woh_steps .step_left:before {
    height: 42px;
    right: 25px;
    top: 46px;
  }
}
.aahar .woh_meets .woh_steps .step_left .steps {
  border-radius: 45px;
  background: #ECE6DC;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  gap: 20px;
}
@media (max-width: 992px) {
  .aahar .woh_meets .woh_steps .step_left .steps {
    padding: 8px 11px;
    gap: 6px;
    justify-content: space-between;
  }
}
.aahar .woh_meets .woh_steps .step_left .steps p {
  color: #570360;
  font-size: 34px;
  font-weight: 900;
  line-height: 130%;
  letter-spacing: -0.678px;
}
@media (max-width: 992px) {
  .aahar .woh_meets .woh_steps .step_left .steps p {
    font-size: 16px;
  }
}
.aahar .woh_meets .woh_steps .step_left .steps .bonus {
  color: #CD8810;
}
.aahar .woh_meets .woh_steps .step_left .steps .number {
  width: 54.484px;
  height: 54.484px;
  border-radius: 100px;
  background-color: #C998CF;
  padding: 4px 0px;
}
@media (max-width: 992px) {
  .aahar .woh_meets .woh_steps .step_left .steps .number {
    width: 30px;
    height: 30px;
  }
}
.aahar .woh_meets .woh_steps .step_left .steps .number span {
  font-size: 34px;
  font-weight: 900;
  line-height: 130%;
  letter-spacing: -0.678px;
  padding: 18px;
}
@media (max-width: 992px) {
  .aahar .woh_meets .woh_steps .step_left .steps .number span {
    font-size: 20px;
    padding: 10px;
  }
}
.aahar .woh_meets .woh_steps .step_left .steps .number img {
  padding: 8px 11px;
}
@media (max-width: 992px) {
  .aahar .woh_meets .woh_steps .step_left .steps .number img {
    padding: 2px 6px;
  }
}
.aahar .woh_meets .woh_steps .step_left .steps .y {
  background-color: #CD8810;
}
.aahar .woh_meets .woh_steps .step_right {
  max-width: 500px;
}
.aahar .woh_meets .woh_steps .step_right p {
  font-size: 28px;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.56px;
}
@media (max-width: 992px) {
  .aahar .woh_meets .woh_steps .step_right p {
    font-size: 16px;
  }
}
.aahar .lastsection {
  border-radius: 24px;
  padding: 40px 20px;
}
@media (max-width: 992px) {
  .aahar .lastsection {
    margin: 0 20px;
  }
}

.icon-wrapper {
  display: inline-block;
  float: right;
  position: absolute;
  right: 0;
}

/* Tooltip styling */
.tooltip-text {
  background: #FFF;
  box-shadow: -2px 4px 10px 0px rgba(89, 60, 29, 0.12);
  padding: 12px;
  border-radius: 5px;
  white-space: nowrap;
  margin-top: 5px;
  color: #374040;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  display: none;
  position: absolute;
  right: -50px;
  top: -164px;
  text-wrap: auto;
  z-index: 1;
  width: 290px;
  text-transform: none;
}
@media (max-width: 992px) {
  .tooltip-text {
    right: 0;
    font-size: 16px;
  }
}

/* Tooltip arrow */
.tooltip-text::after {
  content: "";
  position: absolute;
  bottom: -10px;
  right: 18%;
  transform: translateX(-50%) rotate(180deg);
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #fff;
}
@media (max-width: 992px) {
  .tooltip-text::after {
    right: 0;
  }
}

/* Show tooltip on hover */
.icontip:hover + .tooltip-text {
  display: block;
}

.icontip {
  float: right;
}

.inspiration-reelo .banner {
  padding: 120px 70px 70px;
}
@media (max-width: 992px) {
  .inspiration-reelo .banner {
    padding: 70px 0 40px;
  }
}
.inspiration-reelo .banner .inspi1 {
  width: 380px;
}
@media (max-width: 992px) {
  .inspiration-reelo .banner .inspi1 {
    display: none;
  }
}
.inspiration-reelo .banner .inspired {
  width: 280px;
}
@media (max-width: 992px) {
  .inspiration-reelo .banner .inspired {
    width: 200px;
  }
}
.inspiration-reelo .banner .inspi2 {
  width: 380px;
}
@media (max-width: 992px) {
  .inspiration-reelo .banner .inspi2 {
    width: 100%;
    padding: 0 20px;
  }
}
.inspiration-reelo .banner .tag_wrapper {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  text-align: center;
}
@media (max-width: 992px) {
  .inspiration-reelo .banner .tag_wrapper {
    position: relative;
    transform: unset;
    left: unset;
    padding: 30px;
  }
}
.inspiration-reelo .all_inspiration {
  background: linear-gradient(0deg, #FFF 0.39%, #FFF9F0 15.98%, #FFF9F0 81.44%, #FFF 99.92%);
}
.inspiration-reelo .all_inspiration .dispFlex {
  display: flex;
}
@media (max-width: 992px) {
  .inspiration-reelo .all_inspiration .dispFlex {
    flex-wrap: wrap;
  }
}
.inspiration-reelo .all_inspiration .Inspiration_Title {
  font-size: 42px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.84px;
}
@media (max-width: 992px) {
  .inspiration-reelo .all_inspiration .Inspiration_Title {
    font-size: 24px;
  }
}
.inspiration-reelo .all_inspiration .sticky-fixed {
  position: fixed;
  top: 45px;
  width: 280px;
}
@media (max-width: 992px) {
  .inspiration-reelo .all_inspiration .sticky-fixed {
    width: 100%;
    position: unset;
  }
}
.inspiration-reelo .all_inspiration .sidebar {
  padding: 60px 30px 0 0;
  width: 280px;
}
@media (max-width: 992px) {
  .inspiration-reelo .all_inspiration .sidebar {
    padding: 0;
    width: 100%;
  }
}
.inspiration-reelo .all_inspiration .sidebar .filters {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px; /* 300% */
  letter-spacing: -0.4px;
  text-transform: uppercase;
}
.inspiration-reelo .all_inspiration .sidebar .search_div {
  padding: 0 !important;
}
.inspiration-reelo .all_inspiration .sidebar .search_div .search-container button {
  background: #fff;
  font-size: 0;
  border: none;
  cursor: pointer;
  position: absolute;
  left: 2px;
  bottom: 10px;
}
.inspiration-reelo .all_inspiration .sidebar .search_div .search-container input[type=text] {
  padding: 8px 6px 8px 32px;
  font-size: 17px;
  border-radius: 90px;
  margin: 10px 0 40px;
}
.inspiration-reelo .all_inspiration .sidebar .filter_wrapper .categorySlider li {
  display: block;
  padding-bottom: 30px;
  position: relative;
}
@media (max-width: 992px) {
  .inspiration-reelo .all_inspiration .sidebar .filter_wrapper .categorySlider li {
    padding-bottom: 24px;
  }
}
.inspiration-reelo .all_inspiration .sidebar .filter_wrapper .categorySlider li a {
  color: #023342;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.inspiration-reelo .all_inspiration .sidebar .filter_wrapper .categorySlider li a.active {
  color: #0d9298;
}
@media (max-width: 992px) {
  .inspiration-reelo .all_inspiration .sidebar .filter_wrapper .categorySlider li a {
    font-size: 18px;
  }
}
.inspiration-reelo .all_inspiration .sidebar .filter_wrapper .categorySlider li a .icon-wrapper {
  display: inline-block;
  float: right;
}
.inspiration-reelo .all_inspiration .sidebar .filter_wrapper .custom-dropdown .dropdown-button {
  color: #023342;
  font-family: "Basier Circle";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background-color: unset;
}
.inspiration-reelo .all_inspiration .sidebar .filter_wrapper .custom-dropdown .dropdown-menu {
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 20px;
  margin: 20px 0 0;
}
.inspiration-reelo .all_inspiration .sidebar .filter_wrapper .custom-dropdown .dropdown-menu li {
  list-style-type: none;
  padding: 0 0 5px;
}
.inspiration-reelo .all_inspiration .sidebar .filter_wrapper .custom-dropdown .dropdown-menu li .category-option {
  display: inline-block;
  width: unset;
  border: none;
  box-shadow: none;
}
.inspiration-reelo .all_inspiration .sidebar .filter_wrapper .custom-dropdown .dropdown-arrow img {
  transition: transform 0.3s ease; /* Smooth transition for rotation */
  border: 1px solid #01999F;
  padding: 5px;
  border-radius: 90px;
  vertical-align: bottom;
}
.inspiration-reelo .all_inspiration .sidebar .filter_wrapper .share_wrapper {
  padding: 60px 0 0;
}
.inspiration-reelo .all_inspiration .sidebar .filter_wrapper .share_wrapper .inspired {
  color: #374040;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  padding: 0 0 10px;
}
.inspiration-reelo .all_inspiration .sidebar .filter_wrapper .share_wrapper .inspired a {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}
.inspiration-reelo .all_inspiration .inspiration_wrapper {
  min-height: 600px;
}
.inspiration-reelo .all_inspiration .inspiration_wrapper .masonary-grid {
  display: flex;
  flex-wrap: wrap;
  margin-left: -30px;
  width: auto;
  padding: 40px 0;
}
@media (max-width: 992px) {
  .inspiration-reelo .all_inspiration .inspiration_wrapper .masonary-grid {
    margin-left: 0;
    padding: 40px 0;
  }
}
.inspiration-reelo .all_inspiration .inspiration_wrapper .masonary-grid .masonary-column {
  padding-left: 30px;
  background-clip: padding-box;
  width: 33.3%;
}
@media (max-width: 992px) {
  .inspiration-reelo .all_inspiration .inspiration_wrapper .masonary-grid .masonary-column {
    padding-left: 0;
    width: 100%;
  }
}
.inspiration-reelo .all_inspiration .inspiration_wrapper .masonary-grid .masonary-column .hover_wrapper {
  border-radius: 12px;
  background: #FFF;
  box-shadow: -2px 4px 10px 0px rgba(89, 60, 29, 0.12);
  display: block;
  margin: 0 0 20px;
  padding: 12px;
}
.inspiration-reelo .all_inspiration .inspiration_wrapper .masonary-grid .masonary-column .hover_wrapper:hover .inspi-box .inspi_Img:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 99%;
  background: rgba(0, 0, 0, 0.5019607843);
  background-blend-mode: multiply, normal;
  border-radius: 12px;
}
.inspiration-reelo .all_inspiration .inspiration_wrapper .masonary-grid .masonary-column .hover_wrapper:hover .inspi-box .inspi_Img .vstory {
  bottom: 20px;
}
.inspiration-reelo .all_inspiration .inspiration_wrapper .masonary-grid .masonary-column .hover_wrapper .inspi-box .inspi_Img {
  margin-bottom: 16px;
  overflow: hidden;
}
.inspiration-reelo .all_inspiration .inspiration_wrapper .masonary-grid .masonary-column .hover_wrapper .inspi-box .inspi_Img .vstory {
  color: #1A424F;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  padding: 12px;
  border-radius: 90px;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  position: absolute;
  bottom: -60px;
  left: 50%;
  transform: translateX(-50%);
  min-width: 212px;
  text-align: center;
  transition: all 0.4s ease;
}
.inspiration-reelo .all_inspiration .inspiration_wrapper .masonary-grid .masonary-column .hover_wrapper .inspi-box .inspi_Img .fimg {
  border-radius: 12px;
}
.inspiration-reelo .all_inspiration .inspiration_wrapper .masonary-grid .masonary-column .hover_wrapper .inspi-box .inspi-title {
  color: #171717;
  font-size: 16px;
  line-height: normal;
  text-transform: capitalize;
}
.inspiration-reelo .all_inspiration .inspiration_wrapper .masonary-grid .masonary-column .hover_wrapper .inspi-box .inspi-category {
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  margin: 10px 0;
  display: inline-block;
  border-radius: 90px;
  letter-spacing: 1px;
}
.inspiration-reelo .all_inspiration .inspiration_wrapper .masonary-grid .masonary-column .hover_wrapper .inspi-box .inspi-pos {
  padding: 6px 12px;
  color: #7B3700;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  display: inline-block;
  border-radius: 90px;
}
.inspiration-reelo .lastsection {
  border-radius: 24px;
  padding: 40px 20px;
}
@media (max-width: 992px) {
  .inspiration-reelo .lastsection {
    margin: 0 20px;
  }
}

.inspiration-single .banner {
  border-radius: 20px;
  background: #F4AA16;
  padding: 30px 40px;
}
@media (max-width: 992px) {
  .inspiration-single .banner {
    padding: 30px 20px;
  }
}
.inspiration-single .banner .displayFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 992px) {
  .inspiration-single .banner .displayFlex {
    flex-wrap: wrap;
    gap: 15px;
  }
}
.inspiration-single .banner .displayFlex .logoFold {
  display: flex;
  align-items: center;
  gap: 20px;
}
.inspiration-single .banner .displayFlex .logoFold .inspiTitle {
  font-size: 32px;
  font-weight: 600;
  line-height: 60px;
  letter-spacing: -0.64px;
}
@media (max-width: 992px) {
  .inspiration-single .banner .displayFlex .logoFold .inspiTitle {
    font-size: 22px;
  }
}
.inspiration-single .banner .displayFlex .tagsFold .category {
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  padding-bottom: 45px;
  text-align: right;
}
@media (max-width: 992px) {
  .inspiration-single .banner .displayFlex .tagsFold .category {
    font-size: 16px;
    padding-bottom: 25px;
    text-align: left;
  }
}
.inspiration-single .banner .displayFlex .tagsFold .inspi-brand, .inspiration-single .banner .displayFlex .tagsFold .inspi-pos {
  color: #A06A00;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  border-radius: 90px;
  background: #FACE77;
  padding: 6px 16px;
}
.inspiration-single .banner .displayFlex .tagsFold .inspi-brand {
  margin: 0 10px 0 0;
}
.inspiration-single .breadcrumbs a {
  color: #000;
}
.inspiration-single .breadcrumbs a:hover {
  color: #0d9298;
}
.inspiration-single .breadcrumbs .post_color {
  color: #0d9298 !important;
}
.inspiration-single .overview .overviewFold {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
@media (max-width: 992px) {
  .inspiration-single .overview .overviewFold {
    flex-wrap: wrap;
    gap: 30px;
    display: unset;
  }
}
.inspiration-single .overview .overviewFold .overviewLeft {
  max-width: 605px;
}
@media (max-width: 992px) {
  .inspiration-single .overview .overviewFold .overviewLeft {
    max-width: 100%;
  }
}
.inspiration-single .overview .overviewFold .overviewLeft .logoFold {
  display: flex;
  align-items: center;
  gap: 20px;
  padding-bottom: 20px;
}
.inspiration-single .overview .overviewFold .overviewLeft .logoFold .inspiTitle {
  font-size: 32px;
  font-weight: 600;
  line-height: 60px;
  letter-spacing: -0.64px;
}
@media (max-width: 992px) {
  .inspiration-single .overview .overviewFold .overviewLeft .logoFold .inspiTitle {
    font-size: 22px;
  }
}
.inspiration-single .overview .overviewFold .overviewLeft .overview-title {
  font-size: 42px;
  line-height: 120%;
  padding-bottom: 40px;
}
@media (max-width: 992px) {
  .inspiration-single .overview .overviewFold .overviewLeft .overview-title {
    font-size: 24px;
    padding-bottom: 20px;
  }
}
.inspiration-single .overview .overviewFold .overviewLeft .about {
  color: #0E1B1B;
  font-size: 20px;
  font-weight: 600;
  line-height: 60px; /* 300% */
  letter-spacing: -0.4px;
  text-transform: uppercase;
}
.inspiration-single .overview .overviewFold .overviewLeft .overview_desc {
  color: #374040;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 150% */
}
@media (max-width: 992px) {
  .inspiration-single .overview .overviewFold .overviewLeft .overview_desc {
    font-size: 16px;
    padding-bottom: 22px;
  }
}
.inspiration-single .overview .overviewFold .overviewLeft .btn_wrapper {
  display: flex;
  align-items: center;
  gap: 6px;
}
@media (max-width: 992px) {
  .inspiration-single .overview .overviewFold .overviewLeft .btn_wrapper {
    gap: 16px;
    flex-wrap: wrap;
  }
}
.inspiration-single .overview .overviewFold .overviewRight {
  max-width: 605px;
}
@media (max-width: 992px) {
  .inspiration-single .overview .overviewFold .overviewRight {
    max-width: 100%;
  }
}
.inspiration-single .overview .overviewFold .overviewRight .single-img-wrapper img {
  width: 463px;
  height: 489px;
  border-radius: 12px;
}
@media (max-width: 992px) {
  .inspiration-single .overview .overviewFold .overviewRight .single-img-wrapper img {
    height: 370px;
    width: 100%;
  }
}
.inspiration-single .overview .overviewFold .overviewRight .custom-gallery {
  width: 463px;
  height: 489px;
}
@media (max-width: 992px) {
  .inspiration-single .overview .overviewFold .overviewRight .custom-gallery {
    width: 100%;
    height: unset;
  }
}
.inspiration-single .overview .overviewFold .overviewRight .custom-gallery .slick-dots {
  display: flex;
  margin: 0;
  list-style-type: none;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}
.inspiration-single .overview .overviewFold .overviewRight .custom-gallery .slick-dots button {
  display: block;
  width: 13px;
  height: 13px;
  padding: 0;
  background-color: #000;
  text-indent: -9999px;
  cursor: pointer;
  border-radius: 30px;
  border: unset;
  margin: 0 6px;
}
.inspiration-single .overview .overviewFold .overviewRight .custom-gallery li.slick-active button {
  background-color: #d2cfcf;
}
.inspiration-single .overview .overviewFold .overviewRight .custom-gallery .imgSlide img {
  width: 100%;
  height: 489px;
  border-radius: 12px;
}
@media (max-width: 992px) {
  .inspiration-single .overview .overviewFold .overviewRight .custom-gallery .imgSlide img {
    height: 370px;
  }
}
.inspiration-single .category_section .categoryFold {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
@media (max-width: 992px) {
  .inspiration-single .category_section .categoryFold {
    flex-wrap: wrap;
    gap: 30px;
  }
}
.inspiration-single .category_section .categoryFold .categoryLeft {
  width: 705px;
  min-height: 367px;
  padding: 70px 0;
}
.inspiration-single .category_section .categoryFold .categoryLeft .categoryDiv {
  position: absolute;
  width: 80%;
  top: 44%;
  transform: translateY(-50%);
}
@media (max-width: 992px) {
  .inspiration-single .category_section .categoryFold .categoryLeft .categoryDiv {
    position: unset;
    transform: unset;
    width: 100%;
    top: unset;
  }
}
.inspiration-single .category_section .categoryFold .categoryLeft .categoryDiv p {
  font-size: 18px;
  line-height: 130%;
}
@media (max-width: 992px) {
  .inspiration-single .category_section .categoryFold .categoryLeft .categoryDiv p {
    font-size: 16px;
  }
}
@media (max-width: 992px) {
  .inspiration-single .category_section .categoryFold .categoryLeft {
    width: 100%;
    padding: 0;
    min-height: unset;
  }
}
.inspiration-single .category_section .categoryFold .categoryLeft .category {
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  padding-bottom: 16px;
}
@media (max-width: 992px) {
  .inspiration-single .category_section .categoryFold .categoryLeft .category {
    font-size: 16px;
    padding-bottom: 25px;
    text-align: left;
  }
  .inspiration-single .category_section .categoryFold .categoryLeft .category img {
    width: 18px;
  }
}
.inspiration-single .category_section .categoryFold .categoryRight {
  max-width: 605px;
}
.inspiration-single .category_section .categoryFold .categoryRight img {
  bottom: -5px;
}
@media (max-width: 992px) {
  .inspiration-single .category_section .categoryFold .categoryRight {
    max-width: 100%;
  }
}
.inspiration-single .testimonial .quote {
  color: #01999F;
  font-size: 56px;
  line-height: 120%; /* 67.2px */
  letter-spacing: -0.56px;
}
@media (max-width: 992px) {
  .inspiration-single .testimonial .quote {
    font-size: 30px;
  }
}
.inspiration-single .testimonial .quoteFlex {
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: 20px;
  padding: 50px 0 0;
}
.inspiration-single .testimonial .quoteFlex .sep:before {
  position: absolute;
  content: "";
  width: 1px;
  height: 50px;
  background-color: #01999F;
  top: -50px;
}
.inspiration-single .testimonial .quoteFlex .quoteDiv {
  display: flex;
  align-items: end;
}
.inspiration-single .testimonial .quoteFlex .quoteDiv img {
  border-radius: 90%;
  width: 52px;
}
.inspiration-single .testimonial .quoteFlex .quoteDiv .client {
  padding: 0 0 0 18px;
}
.inspiration-single .testimonial .quoteFlex .quoteDiv .client .name {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
}
.inspiration-single .testimonial .quoteFlex .quoteDiv .client .desig {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25.026px; /* 156.41% */
  letter-spacing: 0.104px;
}
.inspiration-single .about-section .about-inspi {
  border-radius: 20px;
  background: #E7F8F9;
}
.inspiration-single .about-section .about-inspi .aboutFold {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
@media (max-width: 992px) {
  .inspiration-single .about-section .about-inspi .aboutFold {
    flex-wrap: wrap;
  }
}
.inspiration-single .about-section .about-inspi .aboutFold .aboutLeft {
  padding: 60px 97px;
  width: 750px;
  height: 510px;
}
.inspiration-single .about-section .about-inspi .aboutFold .aboutLeft .about_block {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 80%;
}
@media (max-width: 992px) {
  .inspiration-single .about-section .about-inspi .aboutFold .aboutLeft .about_block {
    padding: 40px 20px;
    width: 100%;
    height: unset;
    position: unset;
    transform: unset;
  }
}
@media (max-width: 992px) {
  .inspiration-single .about-section .about-inspi .aboutFold .aboutLeft {
    width: 100%;
    height: unset;
    padding: 0;
  }
}
.inspiration-single .about-section .about-inspi .aboutFold .aboutLeft .title {
  color: #092D2C;
  font-size: 42px;
  font-style: normal;
  line-height: 120%; /* 50.4px */
  padding-bottom: 40px;
}
@media (max-width: 992px) {
  .inspiration-single .about-section .about-inspi .aboutFold .aboutLeft .title {
    font-size: 24px;
    padding-bottom: 20px;
  }
}
.inspiration-single .about-section .about-inspi .aboutFold .aboutLeft .desc {
  color: #374040;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}
@media (max-width: 992px) {
  .inspiration-single .about-section .about-inspi .aboutFold .aboutLeft .desc {
    font-size: 16px;
  }
}
.inspiration-single .about-section .about-inspi .aboutFold .aboutLeft .desc p {
  max-width: 560px;
}
.inspiration-single .about-section .about-inspi .aboutFold .aboutRight {
  max-width: 450px;
}
.inspiration-single .about-section .about-inspi .aboutFold .aboutRight .single-img-wrapper img {
  width: 100%;
  height: 510px;
  margin: 0 0 0 auto;
  border-radius: 0 12px 12px 0;
}
@media (max-width: 992px) {
  .inspiration-single .about-section .about-inspi .aboutFold .aboutRight .single-img-wrapper img {
    border-radius: 0 0 12px 12px;
    height: 370px;
  }
}
.inspiration-single .about-section .about-inspi .aboutFold .aboutRight .media_Slider .slick-dots {
  display: flex;
  margin: 0;
  list-style-type: none;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}
.inspiration-single .about-section .about-inspi .aboutFold .aboutRight .media_Slider .slick-dots button {
  display: block;
  width: 13px;
  height: 13px;
  padding: 0;
  background-color: #d2cfcf;
  text-indent: -9999px;
  cursor: pointer;
  border-radius: 30px;
  border: unset;
  margin: 0 6px;
}
.inspiration-single .about-section .about-inspi .aboutFold .aboutRight .media_Slider li.slick-active button {
  background-color: #fff;
}
.inspiration-single .about-section .about-inspi .aboutFold .aboutRight .media_Slider .imgSlide img {
  width: 100%;
  height: 510px;
  margin: 0 0 0 auto;
  border-radius: 0 12px 12px 0;
}
@media (max-width: 992px) {
  .inspiration-single .about-section .about-inspi .aboutFold .aboutRight .media_Slider .imgSlide img {
    border-radius: 0 0 12px 12px;
    height: 370px;
  }
}
@media (max-width: 992px) {
  .inspiration-single .about-section .about-inspi .aboutFold .aboutRight {
    max-width: 100%;
  }
}
.inspiration-single .recentBlogs .title {
  font-size: 42px;
  font-style: normal;
  line-height: 120%; /* 50.4px */
  padding-bottom: 30px;
}
@media (max-width: 992px) {
  .inspiration-single .recentBlogs .title {
    font-size: 24px;
    padding-bottom: 0;
  }
}
.inspiration-single .recentBlogs .blog_wrapper .inspi_wrapper {
  padding: 0 20px 0 0;
  min-height: 480px;
}
@media (max-width: 992px) {
  .inspiration-single .recentBlogs .blog_wrapper .inspi_wrapper {
    padding: 30px 0;
    min-height: unset;
  }
}
.inspiration-single .recentBlogs .blog_wrapper .inspi_wrapper .fimg {
  border-radius: 12px;
}
.inspiration-single .recentBlogs .blog_wrapper .inspi_wrapper .inspi-category {
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  margin: 10px 0;
  display: inline-block;
  border-radius: 90px;
  letter-spacing: 1px;
}
.inspiration-single .recentBlogs .blog_wrapper .inspi_wrapper .inspi-title {
  color: #1A424F;
  font-size: 20px;
  font-style: normal;
  line-height: 120%;
  padding: 16px 0 10px;
}
@media (max-width: 992px) {
  .inspiration-single .recentBlogs .blog_wrapper {
    padding: 0;
  }
}
.inspiration-single .lastsection {
  border-radius: 24px;
  padding: 40px 20px;
}
@media (max-width: 992px) {
  .inspiration-single .lastsection {
    margin: 0 20px;
  }
}

.error-page .error_wrapper {
  height: 100vh;
}
.error-page .error_wrapper .not-found-page {
  position: absolute;
  width: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
@media (max-width: 992px) {
  .error-page .error_wrapper .not-found-page {
    padding: 40px 20px;
  }
}
.error-page .error_wrapper .not-found-page .sec_title {
  font-size: 120px;
  line-height: 168px;
}
.error-page .error_wrapper .not-found-page .sec-title {
  padding: 30px 0 10px;
  font-family: "Basier Circle medium";
}
@media (max-width: 992px) {
  .error-page .error_wrapper .not-found-page .sec-title {
    font-size: 30px;
    line-height: 40px;
  }
}
@media (max-width: 992px) {
  .error-page .error_wrapper .not-found-page .sec-heading2 {
    font-size: 24px;
    line-height: 34px;
  }
}

.thankyou-page {
  padding: 20px 0 0;
}
@media (max-width: 992px) {
  .thankyou-page {
    padding: 40px 0 0;
  }
}
.thankyou-page section {
  background: #f2f9f6;
}
.thankyou-page .common_title {
  font-size: 48px;
  margin: 0 0 10px;
  color: #092d2c;
  line-height: 60px;
  letter-spacing: -0.05em;
  font-family: "Basier Circle Semibold";
}
@media (max-width: 992px) {
  .thankyou-page .common_title {
    font-size: 40px;
    line-height: 56px;
  }
}
.thankyou-page .common_desc {
  font-size: 20px;
  margin: 0 0 40px;
  color: #092d2c;
  line-height: 32px;
}
@media (max-width: 992px) {
  .thankyou-page .common_desc {
    font-size: 18px;
    line-height: 28px;
  }
}
.thankyou-page .ThankYou_fold {
  gap: 54px;
  display: flex;
  align-items: stretch;
  justify-content: center;
}
@media (max-width: 992px) {
  .thankyou-page .ThankYou_fold {
    flex-wrap: wrap;
  }
}
.thankyou-page .ThankYou_fold .ThankYou_fold_left_side {
  width: 100%;
  max-width: 628px;
  padding: 60px 30px;
  background: #fff;
  box-shadow: 0 1px 3px rgba(63, 63, 68, 0.15), 0 0 0 1px rgba(63, 63, 68, 0.05);
  border-bottom: 5px solid #26a0a0;
}
@media (max-width: 992px) {
  .thankyou-page .ThankYou_fold .ThankYou_fold_left_side {
    padding: 24px;
  }
}
.thankyou-page .ThankYou_fold .ThankYou_fold_left_side .ThankYou_fold_img img {
  height: 250px;
  margin: 0 auto;
  position: relative;
}
@media (max-width: 992px) {
  .thankyou-page .ThankYou_fold .ThankYou_fold_left_side .ThankYou_fold_img img {
    height: unset;
  }
}
.thankyou-page .ThankYou_fold .ThankYou_fold_left_side h2 {
  font-size: 30px;
  color: #092d2c;
  line-height: 38px;
  text-align: center;
  margin: 28px auto;
  letter-spacing: -0.05em;
  font-family: "Basier Circle Semibold";
}
@media (max-width: 992px) {
  .thankyou-page .ThankYou_fold .ThankYou_fold_left_side h2 {
    font-size: 24px;
    line-height: 32px;
  }
}
.thankyou-page .ThankYou_fold .ThankYou_fold_right_side {
  width: 100%;
  max-width: 412px;
  padding: 40px 32px;
  background: #fff;
  box-shadow: 0 1px 3px rgba(63, 63, 68, 0.15), 0 0 0 1px rgba(63, 63, 68, 0.05);
}
.thankyou-page .ThankYou_fold .ThankYou_fold_right_side .ThankYou_fold_img {
  width: fit-content;
  margin: 0 auto 40px;
}
.thankyou-page .ThankYou_fold .ThankYou_fold_right_side p {
  font-size: 24px;
  color: #092d2c;
  margin: 0 0 110px;
  line-height: 32px;
  text-align: center;
  letter-spacing: -0.05em;
  font-weight: 600;
}
@media (max-width: 992px) {
  .thankyou-page .ThankYou_fold .ThankYou_fold_right_side p {
    margin: 0 0 30px;
  }
}

.dynamic_cta_wrapper {
  border-radius: 16px;
  background-blend-mode: multiply, normal, normal;
  display: flex;
  padding: 30px 40px;
  margin: 20px 0 0;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  align-items: flex-end;
  justify-content: space-between;
}
@media (max-width: 992px) {
  .dynamic_cta_wrapper {
    flex-wrap: wrap;
    padding: 20px;
  }
}
.dynamic_cta_wrapper .content_wrapper .common-title {
  font-family: "Basier Circle medium";
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  text-align: left;
  line-height: 110%;
}
@media (max-width: 992px) {
  .dynamic_cta_wrapper .content_wrapper .common-title {
    font-size: 26px;
    line-height: 36px;
  }
}
.dynamic_cta_wrapper .content_wrapper .common-desc {
  font-size: 16px;
  text-align: left;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  padding: 10px 0 0;
}
@media (max-width: 992px) {
  .dynamic_cta_wrapper .content_wrapper .common-desc {
    font-size: 18px;
    line-height: 26px;
  }
}
.dynamic_cta_wrapper .btn_wrapper {
  margin: 30px 0 0;
}
.dynamic_cta_wrapper .btn_wrapper .yellow-btn {
  padding: 8px 12px;
}

#hubspot-conversations-iframe {
  display: initial !important;
  border: none !important;
  position: absolute !important;
  bottom: 0 !important;
  right: 0 !important;
  background: transparent !important;
  z-index: -1;
}

#close, #formclose {
  top: 5px;
  right: 10px;
  position: absolute;
  font-size: 30px;
  color: #0d9298;
  cursor: pointer;
  z-index: 9999;
}

#overlay, #formoverlay {
  overflow: auto;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 9999;
}

#popup {
  width: 60%;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid rgba(28, 58, 105, 0.2352941176);
  border-radius: 10px;
  opacity: 1;
  padding: 25px;
}
#popup .refund .refund_title {
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  text-decoration: underline;
  padding: 0 0 20px;
}
#popup .refund p {
  padding: 5px 0;
}
@media (max-width: 992px) {
  #popup {
    width: 90%;
  }
}

#formpopup {
  width: 60%;
  background: rgb(250, 254, 255);
  position: absolute;
  top: 52%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid rgba(28, 58, 105, 0.2352941176);
  border-radius: 10px;
  opacity: 1;
  padding: 25px 25px 0;
  height: 540px;
  overflow: auto;
}
@media (max-width: 992px) {
  #formpopup {
    width: 90%;
  }
}
#formpopup .formWrapper {
  display: flex;
  align-items: center;
}
@media (max-width: 992px) {
  #formpopup .formWrapper {
    display: block;
  }
  #formpopup .formWrapper .demoform {
    padding: 30px 0 0;
  }
}

/*** iPhone and iOS Form Input Zoom Fixes ***/
/* Fix Input Zoom on devices older than iPhone 5: */
@media screen and (device-aspect-ratio: 2/3) {
  select, textarea, input[type=text], input[type=password],
  input[type=datetime], input[type=datetime-local],
  input[type=date], input[type=month], input[type=time],
  input[type=week], input[type=number], input[type=email],
  input[type=url] {
    font-size: 16px;
  }
}
/* Fix Input Zoom on iPhone 5, 5C, 5S, iPod Touch 5g */
@media screen and (device-aspect-ratio: 40/71) {
  select, textarea, input[type=text], input[type=password],
  input[type=datetime], input[type=datetime-local],
  input[type=date], input[type=month], input[type=time],
  input[type=week], input[type=number], input[type=email],
  input[type=url] {
    font-size: 16px;
  }
}
/* Fix Input Zoom on iPhone 6, iPhone 6s, iPhone 7  */
@media screen and (device-aspect-ratio: 375/667) {
  select, textarea, input[type=text], input[type=password],
  input[type=datetime], input[type=datetime-local],
  input[type=date], input[type=month], input[type=time],
  input[type=week], input[type=number], input[type=email],
  input[type=url] {
    font-size: 16px;
  }
}
/* Fix Input Zoom on iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus, iPhone 8, iPhone X, XS, XS Max  */
@media screen and (device-aspect-ratio: 9/16) {
  select, textarea, input[type=text], input[type=password],
  input[type=datetime], input[type=datetime-local],
  input[type=date], input[type=month], input[type=time],
  input[type=week], input[type=number], input[type=email],
  input[type=url] {
    font-size: 16px;
  }
}
* {
  -webkit-tap-highlight-color: transparent !important;
}

.areaFont {
  font-family: "area-variable", sans-serif !important;
  font-variation-settings: "slnt" 0, "INKT" 70;
}

.areaFont0 {
  font-family: "area-variable", sans-serif !important;
  font-variation-settings: "slnt" 0, "INKT" 0;
}

html,
body {
  padding: 0;
  margin: 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  font-display: swap;
  font-family: "Basier Circle";
  overflow-x: hidden;
}

a,
img,
button,
input[type=submit] {
  text-decoration: none;
  outline: none;
  border: 0;
  /*transition: 0.2s ease;*/
}

*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

::selection {
  background-color: #33c1c0;
  color: #fff;
}

a:focus,
a:hover {
  text-decoration: none !important;
}

p,
ul,
ol,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
dt,
dd {
  padding: 0;
  margin: 0;
}

input,
textarea,
select {
  width: 100%;
  display: block;
  padding: 10px 12px;
  font-size: 14px;
  line-height: 20px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
  outline: none;
}

.selldof .btn {
  width: unset;
}

p {
  font-size: 16px;
  line-height: 26px;
}

.container-fluid {
  width: 100%;
  margin: 0 auto;
  padding: 0 0px;
}

.container {
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 20px;
}

.container.small {
  max-width: 850px;
  padding: 0 20px;
}

.container.medium {
  max-width: 1000px;
  padding: 0 20px;
}

.pull-left {
  float: left !important;
}

.pull-right {
  float: right !important;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-italic {
  font-style: italic;
}

.text-medium {
  font-weight: 400;
}

.text-bold {
  font-weight: 500;
}

.text-bolder {
  font-weight: 700;
}

.text-black {
  color: #000;
}

.text-pink {
  color: #DD2590;
}

.text-dark {
  color: #182020;
}

.text-light {
  color: #354545;
}

.text-white {
  color: #fff;
}

.text-blue {
  color: #0d9298;
}

.text-lightblue {
  color: #01697e;
}

.text-green {
  color: #065151;
}

.text-greyish {
  color: #C1C8CC;
}

.text-grey {
  color: #0e1b1b;
}

.text-red {
  color: red;
}

.titledark {
  color: #1A424F;
}

.text-yellow {
  color: #ebbd07;
}

.color1 {
  color: #0e1b1b;
}

.color2 {
  color: #374040;
}

.color3 {
  color: #023342;
}

.primary_color {
  color: #63FAFF;
}

.bg-light {
  background-color: #C2F6F6;
}

.bg-grey {
  background-color: #EFF0F0;
}

.img-responsive {
  max-width: 100%;
  height: auto;
}

.img-full {
  width: 100%;
  height: auto;
}

.center-block {
  margin: 0 auto;
}

.bg-white {
  background-color: #fff;
}

.bg-blue {
  background-color: #0d9298;
}

.bg-pink {
  background-color: #fff5f5;
}

.bg-colorpink {
  background-color: #fdf5ff;
}

.bg-green {
  background-color: #065151;
}

.bgBlue {
  background-color: #e9fff8;
}

.basierMedium {
  font-family: "Basier Circle medium";
}

.basierSemibold {
  font-family: "Basier Circle Semibold";
}

.bg-cover {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.col-1 {
  width: 8.3333333333%;
}

.col-2 {
  width: 16.6666666667%;
}

.col-3 {
  width: 25%;
}

.col-4 {
  width: 33.3333333333%;
}

.col-5 {
  width: 41.6666666667%;
}

.col-6 {
  width: 50%;
}

.col-7 {
  width: 58.3333333333%;
}

.col-8 {
  width: 66.6666666667%;
}

.col-9 {
  width: 75%;
}

.col-10 {
  width: 83.3333333333%;
}

.col-11 {
  width: 91.6666666667%;
}

.col-12 {
  width: 100%;
}

.col-12-5 {
  width: 20%;
}

.margintop-20 {
  margin-top: 20px;
}

.margintop-30 {
  margin-top: 30px;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col-12-5,
.w30,
.w70 {
  float: left;
}

section {
  float: left;
  width: 100%;
}

.padding {
  padding: 70px 0px;
}

.padding-top {
  padding: 120px 0 70px 0;
}

.padd-10 {
  padding-top: 10px;
}

.padd-20 {
  padding-top: 20px;
}

.padd-30 {
  padding-top: 30px;
}

.pd-bt-15 {
  padding-bottom: 15px;
}

.pd-bt-10 {
  padding-bottom: 10px;
}

.pd-bt-20 {
  padding-bottom: 20px;
}

.pd-bt-30 {
  padding-bottom: 30px;
}

.pd-tp-15 {
  padding-top: 15px;
}

.pd-tp-10 {
  padding-top: 10px;
}

.pd-tp-20 {
  padding-top: 20px;
}

.pd-tp-30 {
  padding-top: 30px;
}

.pd-tp-40 {
  padding-top: 40px;
}

.pd-tp-50 {
  padding-top: 50px;
}

.padd-top {
  padding: 10px 0;
}

.mg-bt-30 {
  margin-bottom: 30px;
}

.mg-bt-10 {
  margin-bottom: 10px;
}

.pd-tp-0 {
  padding-top: 0 !important;
}

.pd-bt-0 {
  padding-bottom: 0 !important;
}

.pos-rel {
  position: relative;
}

.pos-abs {
  position: absolute;
}

.pos-fix {
  position: fixed;
}

.bottom {
  bottom: 0;
}

.top {
  top: 0;
}

.left {
  left: 0;
}

.right {
  right: 0;
}

.visible-xs {
  display: none;
}

.visible-sm {
  display: none;
}

.hidden-xs {
  display: block;
}

.hidden-portrait {
  display: block !important;
}

.visible-portrait {
  display: none !important;
}

.displayblock {
  display: block;
}

.displayinline {
  display: inline-block;
}

.row:before,
.row:after {
  display: table;
  content: " ";
}

.row:after,
.clearfix {
  clear: both;
}

.parallaxbg {
  background-attachment: fixed;
}

.disptable {
  display: table;
}

.table-cell {
  display: table-cell;
  vertical-align: middle;
}

.visible-xs {
  display: none;
}

.hidden-lg {
  display: none;
}

.hidden-portrait {
  display: block !important;
}

.visible-portrait {
  display: none !important;
}

.vert-middle {
  vertical-align: middle;
}

.disp-block {
  display: block;
}

.disp-flex {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
  margin: 35px 0;
}

.center-block {
  float: none !important;
  display: block;
  margin: 10px auto 0;
}

.sticky_header {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: #0d9298;
  padding: 6px 15px;
  border-radius: 30px;
  z-index: 2;
}

header {
  position: fixed;
  width: 100%;
  background-color: #fff;
  width: 100%;
  height: 70px;
  padding: 10px 0;
  border-bottom: 1px solid #e9eded;
  z-index: 9999;
  transition: all 0.3s linear;
}
header .center-header {
  gap: 18px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto !important;
  max-width: 1304px;
}
header .theme-btn, header .white-btn {
  font-size: 14px !important;
  padding: 10px 12px;
}

.brand-logo {
  padding: 10px 0;
}

.border {
  margin: 4px 0 0;
}

a:hover, button:hover, input[type=button]:hover, input[type=submit]:hover {
  cursor: pointer;
}

.nav-links {
  list-style-type: none;
  display: inline-block;
}
.nav-links li {
  display: inline-block;
}
.nav-links .custom-dropdown {
  float: left;
  overflow: hidden;
  height: 80px;
}
.nav-links .custom-dropdown:hover .dropdown-menu-parent {
  display: block;
}
.nav-links .custom-dropdown .dropbtn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nav-links .custom-dropdown .dropbtn span {
  font-size: 14px;
  color: #374040;
  line-height: 20px;
  font-family: "Basier Circle medium";
  cursor: pointer;
  position: relative;
  transition: all 0.2s linear;
  margin: 12px 10px 12px 22px;
  display: inline-block;
}
.nav-links .custom-dropdown .dropbtn span a {
  font-size: 14px;
  color: #374040;
  font-family: "Basier Circle medium";
  line-height: 20px;
  cursor: pointer;
  position: relative;
}
.nav-links .custom-dropdown .dropdown-menu-parent {
  left: 0;
  top: 60px;
  width: 100%;
  z-index: 10;
  display: none;
  position: absolute;
  backdrop-filter: blur(22px);
  height: calc(100dvh - 130px);
  display: none;
}
.nav-links .custom-dropdown .dropdown-menu-parent .custom-dropdown-menu {
  overflow-y: auto;
  padding: 32px 25px;
  background: #fff;
  max-height: calc(100dvh - 130px);
  box-shadow: 4px 0 4px rgba(0, 0, 0, 0.04);
}
.nav-links .custom-dropdown .dropdown-menu-parent .custom-dropdown-menu .dropdown-content {
  margin: 0 auto;
  max-width: 1366px;
}
.nav-links .custom-dropdown .dropdown-menu-parent .custom-dropdown-menu .dropdown-content .section-header {
  margin: 0 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nav-links .custom-dropdown .dropdown-menu-parent .custom-dropdown-menu .dropdown-content .section-header .section-title {
  margin: 0;
  font-size: 14px;
  line-height: 20px;
  width: fit-content;
  color: transparent;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-family: "Basier Circle Semibold";
  background-clip: text;
  background-image: linear-gradient(97.27deg, #ff7b34 9.71%, #8d63fe 102.01%);
}
.nav-links .custom-dropdown .dropdown-menu-parent .custom-dropdown-menu .dropdown-content .section-1 .section-body {
  display: flex;
  grid-gap: 16px 54px;
  gap: 16px 0px;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: flex-start;
}
.nav-links .custom-dropdown .dropdown-menu-parent .custom-dropdown-menu .dropdown-content .section-1 .section-body .menu-item {
  grid-gap: 16px;
  gap: 16px;
  width: 390px;
  padding: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 8px;
}
.nav-links .custom-dropdown .dropdown-menu-parent .custom-dropdown-menu .dropdown-content .section-1 .section-body .menu-item:hover {
  background: #f4f9f9;
}
.nav-links .custom-dropdown .dropdown-menu-parent .custom-dropdown-menu .dropdown-content .section-1 .section-body .menu-item:hover .icon-div {
  background: #0d9298;
}
.nav-links .custom-dropdown .dropdown-menu-parent .custom-dropdown-menu .dropdown-content .section-1 .section-body .menu-item:hover .icon-div svg path {
  stroke: #fff;
}
.nav-links .custom-dropdown .dropdown-menu-parent .custom-dropdown-menu .dropdown-content .section-1 .section-body .menu-item .icon-div {
  width: 44px;
  height: 44px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f4f9f9;
}
.nav-links .custom-dropdown .dropdown-menu-parent .custom-dropdown-menu .dropdown-content .section-1 .section-body .menu-item .icon-div svg path {
  stroke: #0d9298;
}
.nav-links .custom-dropdown .dropdown-menu-parent .custom-dropdown-menu .dropdown-content .section-1 .section-body .menu-item .content-div {
  max-width: calc(100% - 76px);
}
.nav-links .custom-dropdown .dropdown-menu-parent .custom-dropdown-menu .dropdown-content .section-1 .section-body .menu-item .content-div h6 {
  margin: 0 0 2px;
  font-size: 16px;
  color: #0e1b1b;
  line-height: 22px;
  font-weight: 600;
}
.nav-links .custom-dropdown .dropdown-menu-parent .custom-dropdown-menu .dropdown-content .section-1 .section-body .menu-item .content-div p {
  margin: 0;
  font-size: 14px;
  color: #747c7c;
  line-height: 21px;
  letter-spacing: -0.02em;
}
.nav-links .custom-dropdown .dropdown-menu-parent .custom-dropdown-menu .dropdown-content .h_img {
  width: 200px;
  height: 132px;
  border-radius: 10px;
}
.nav-links .custom-dropdown .dropdown-menu-parent .custom-dropdown-menu .dropdown-content .section-2 {
  margin-bottom: 32px;
}
.nav-links .custom-dropdown .dropdown-menu-parent .custom-dropdown-menu .dropdown-content .section-2 .section-body {
  display: flex;
  grid-gap: 32px;
  gap: 32px;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: flex-start;
}
.nav-links .custom-dropdown .dropdown-menu-parent .custom-dropdown-menu .dropdown-content .section-2 .section-body .menu-item {
  grid-gap: 20px;
  gap: 20px;
  width: 528px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.nav-links .custom-dropdown .dropdown-menu-parent .custom-dropdown-menu .dropdown-content .section-2 .section-body .menu-item .content-div {
  max-width: calc(100% - 220px);
}
.nav-links .custom-dropdown .dropdown-menu-parent .custom-dropdown-menu .dropdown-content .section-2 .section-body .menu-item .content-div h6 {
  margin: 0;
  font-size: 16px;
  color: #0e1b1b;
  line-height: 22px;
}
.nav-links .custom-dropdown .dropdown-menu-parent .custom-dropdown-menu .dropdown-content .section-2 .section-body .menu-item .content-div p {
  font-size: 14px;
  color: #747c7c;
  margin: 4px 0 8px;
  line-height: 21px;
  letter-spacing: -0.02em;
}
.nav-links .custom-dropdown .dropdown-menu-parent .custom-dropdown-menu .dropdown-content .section-2 .section-body .menu-item .content-div span {
  font-size: 16px;
  line-height: 22px;
  color: #0d9298 !important;
  text-decoration: underline !important;
  font-weight: 500;
}
.nav-links .custom-dropdown .dropdown-menu-parent .custom-dropdown-menu .dropdown-content .section-3 .section-body {
  display: flex;
  grid-gap: 16px 32px;
  gap: 16px 32px;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: flex-start;
}
.nav-links .custom-dropdown .dropdown-menu-parent .custom-dropdown-menu .dropdown-content .section-3 .section-body .menu-item {
  width: 315px;
  cursor: pointer;
}
.nav-links .custom-dropdown .dropdown-menu-parent .custom-dropdown-menu .dropdown-content .section-3 .section-body .menu-item img {
  border-radius: 8px;
  height: 150px;
}
.nav-links .custom-dropdown .dropdown-menu-parent .custom-dropdown-menu .dropdown-content .section-3 .section-body .menu-item h6 {
  font-size: 16px;
  margin: 20px 0 0;
  color: #0e1b1b;
  line-height: 22px;
  font-family: "Basier Circle Semibold";
}
.nav-links .custom-dropdown .dropdown-menu-parent .custom-dropdown-menu .dropdown-content .section-3 .section-body .menu-item p {
  font-size: 14px;
  color: #747c7c;
  margin: 4px 0 8px;
  line-height: 21px;
  letter-spacing: -0.02em;
  font-family: "Basier Circle";
}
.nav-links .custom-dropdown .dropdown-menu-parent .custom-dropdown-menu .dropdown-content .section-3 .section-body .menu-item span {
  font-size: 16px;
  line-height: 22px;
  color: #0d9298 !important;
  font-family: "Basier Circle medium";
  text-decoration: underline !important;
  font-weight: 500;
}

.call-us img {
  vertical-align: middle;
  background-color: #11274C;
  width: 37px;
  height: 37px;
  padding: 9px;
  border-radius: 50px;
}

a.enquire-toggle-button.extLink.active:before {
  content: unset;
}

.logo-div img {
  padding: 10px 0 0;
}

.burger-menu {
  display: none;
  padding: 7px 8px;
  margin-top: 25px;
}

.bar1,
.bar2,
.bar3 {
  width: 20px;
  height: 3px;
  background: #0D9298;
  margin: 5px 0;
  transition: 0.4s;
}

.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-5px, 5px) !important;
  transform: rotate(-45deg) translate(-5px, 5px) !important;
}

.change .bar2 {
  opacity: 0;
}

.change .bar3 {
  -webkit-transform: rotate(45deg) translate(-6px, -6px);
  transform: rotate(45deg) translate(-6px, -6px);
  width: 20px;
  left: 1px;
  margin: unset;
}

.sticky-btn {
  position: fixed;
  top: 165px;
  right: 0;
  -webkit-transform: rotate(-90deg);
  -webkit-transform-origin: bottom right;
  transform-origin: bottom right;
  width: 270px;
  z-index: 999;
  display: inline-block;
  text-align: center;
  text-decoration: none;
}

.visible-xs {
  display: none !important;
}

.col-12.menu a:last-child {
  padding-right: 0;
}

.btn_wrapper {
  margin: 30px 0;
}

.wpcf7-not-valid-tip {
  position: absolute;
  bottom: -21px;
  font-size: 10px;
}

.wpcf7 form .wpcf7-response-output {
  display: none;
}

.theme-btn {
  padding: 14px 25px;
  font-size: 16px !important;
  line-height: 20px;
  display: inline-block;
  background: #0d9298 !important;
  text-align: center;
  border-radius: 6px;
  color: #fff;
  font-family: "Basier Circle medium";
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.05);
}
.theme-btn:hover {
  background-color: #354545 !important;
}
@media (max-width: 992px) {
  .theme-btn {
    font-size: 14px !important;
  }
}

.black-btn {
  padding: 14px 25px;
  font-size: 16px !important;
  line-height: 20px;
  display: inline-block;
  background: #023342;
  text-align: center;
  border-radius: 8px;
  font-family: "Basier Circle medium";
  color: #fff;
}
.black-btn:hover {
  background-color: #fff !important;
  color: #023342;
}
@media (max-width: 992px) {
  .black-btn {
    font-size: 14px !important;
  }
}

.white-btn {
  padding: 14px 25px;
  font-size: 16px !important;
  line-height: 20px;
  display: inline-block;
  background: white;
  text-align: center;
  border-radius: 8px;
  font-family: "Basier Circle medium";
  color: #023342;
  border: 1px solid #023342;
}
.white-btn:hover {
  background-color: #354545 !important;
  color: #fff !important;
}
@media (max-width: 992px) {
  .white-btn {
    font-size: 14px !important;
  }
}

.blue-btn {
  padding: 14px 25px;
  font-size: 16px !important;
  line-height: 20px;
  display: inline-block;
  background: white;
  text-align: center;
  font-family: "Basier Circle medium";
  border-radius: 6px;
  color: #0d9298;
  border: 1px solid #0d9298;
}
@media (max-width: 992px) {
  .blue-btn {
    font-size: 14px !important;
  }
}

.yellow-btn {
  padding: 14px 25px;
  font-size: 16px !important;
  line-height: 20px;
  display: inline-block;
  background: #ebbd07;
  text-align: center;
  font-family: "Basier Circle medium";
  border-radius: 6px;
  color: #013030;
  border: 1px solid #ebbd07;
}
@media (max-width: 992px) {
  .yellow-btn {
    font-size: 14px !important;
  }
}

.grey-btn {
  padding: 10px 30px;
  font-size: 16px !important;
  line-height: 20px;
  display: inline-block;
  color: #1A424F;
  background: #EEEEEE;
  border-radius: 8px;
  text-align: center;
  font-family: "Basier Circle medium";
}
.grey-btn:hover {
  background-color: #0d9298 !important;
  color: #fff !important;
}
.grey-btn:hover svg path {
  fill: #fff;
}
.grey-btn svg {
  vertical-align: text-top;
}
@media (max-width: 992px) {
  .grey-btn {
    font-size: 14px !important;
  }
}

.red-btn img {
  float: right;
  margin-top: 7px;
}

.light-btn {
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #171717;
  background-color: #EFF0F0;
  border-radius: 50px;
  padding: 8px 20px;
}

.button-3 {
  border: 2px solid #3c73ff;
  background-color: #3c73ff;
  border-radius: 20px;
  color: #fff;
  transition: 0.3s;
}

.button-3:hover {
  box-shadow: 8px 8px #99bdff;
  transition: 0.3s;
}

.learnmore {
  position: relative;
  display: inline-block;
}
.learnmore::after {
  content: "";
  position: absolute;
  top: 1px;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 6px 6px #023342;
  transition: top 0.3s ease-in-out, left 0.3s ease-in-out;
  border-radius: 8px;
}
.learnmore .theme-newbtn {
  padding: 11px 16px 8px;
  font-size: 16px;
  line-height: 20px;
  display: inline-block;
  background: #0d9298;
  text-align: center;
  border-radius: 8px;
  color: #fff;
  transition: transform 0.3s ease-in-out;
  position: relative;
  left: 6px;
  top: 7px;
  z-index: 2;
}
.learnmore:hover .theme-newbtn {
  transform: translate(-5px, -5px);
}

.theme-newbtn {
  padding: 11px 16px;
  font-size: 16px !important;
  line-height: 20px;
  display: inline-block;
  background: #0d9298;
  text-align: center;
  border-radius: 8px;
  color: #fff;
}
.theme-newbtn:hover {
  box-shadow: 6px 6px #000;
  transition: 0.3s;
}
@media (max-width: 992px) {
  .theme-newbtn {
    font-size: 14px !important;
  }
}

.ctahover {
  position: relative;
  display: inline-block;
}
.ctahover::after {
  content: "";
  position: absolute;
  top: 1px;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 6px 6px #63faff;
  transition: top 0.3s ease-in-out, left 0.3s ease-in-out;
  border-radius: 8px;
}
.ctahover .black-newbtn {
  padding: 11px 16px;
  font-size: 16px !important;
  line-height: 20px;
  display: inline-block;
  background: #023342;
  text-align: center;
  border-radius: 8px;
  font-family: "Basier Circle medium";
  color: #fff;
  transition: transform 0.3s ease-in-out;
  position: relative;
  left: 6px;
  top: 7px;
  z-index: 2;
}
.ctahover:hover .black-newbtn {
  transform: translate(-5px, -5px);
}

.ctahoverw {
  position: relative;
  display: inline-block;
}
.ctahoverw::after {
  content: "";
  position: absolute;
  top: 1px;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 6px 6px #023342;
  transition: top 0.3s ease-in-out, left 0.3s ease-in-out;
  border-radius: 8px;
}
.ctahoverw .white-newbtn {
  padding: 11px 16px;
  font-size: 16px !important;
  line-height: 20px;
  display: inline-block;
  background: #fff;
  text-align: center;
  border-radius: 8px;
  font-family: "Basier Circle medium";
  color: #023342;
  transition: transform 0.3s ease-in-out;
  position: relative;
  left: 6px;
  top: 7px;
  z-index: 2;
}
.ctahoverw:hover .white-newbtn {
  transform: translate(-5px, -5px);
}

.white-newbtn {
  padding: 11px 16px;
  font-size: 16px !important;
  line-height: 20px;
  display: inline-block;
  background: white;
  text-align: center;
  border-radius: 8px;
  font-family: "Basier Circle medium";
  color: #023342;
}
.white-newbtn:hover {
  box-shadow: 6px 6px #000;
  transition: 0.3s;
}
@media (max-width: 992px) {
  .white-newbtn {
    font-size: 14px !important;
  }
}

.col-12.header-upper {
  text-align: right;
}

.footer-call-enquire {
  display: none;
}

.checkbox {
  color: #222 !important;
  margin: 0 !important;
}

a {
  cursor: pointer;
}

.footer-div {
  padding: 40px 0;
  float: left;
  width: 100%;
  padding-bottom: 0;
}

.footer-top p, .footer-top a {
  font-size: 16px;
  line-height: 24px;
  color: #182020;
}

#jp-relatedposts {
  display: none !important;
}

.footer-top {
  padding: 0 0 40px;
}

.footer-top input.theme-btn {
  margin: 20px 0;
  width: unset;
}

.footer-title {
  padding: 20px 0 40px;
}

.footer_desc {
  font-size: 14px;
}

.footer-top ul li {
  font-size: 16px;
  line-height: 28px;
  color: #182020;
  list-style-type: none;
}

.footer-bottom {
  padding: 10px;
  font-size: 16px;
  color: #616d6d;
  line-height: 21px;
  text-align: center;
  background: #f5f5f5;
  font-family: "DM Sans", sans-serif;
}

.social-links a img {
  padding: 8px 4px;
}

.vh {
  visibility: hidden;
}

.footer-top h4 {
  padding: 0px 0 20px;
  color: #182020;
}

.thankyou {
  text-align: center;
  font-size: 18px;
  left: 22px;
}

.sec-subtitle {
  font-size: 22px;
  line-height: 32px;
  font-weight: 700;
  padding: 0 0 10px;
}

.sec_title {
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: 64px;
  letter-spacing: -0.56px;
  font-family: "Basier Circle medium";
}
@media (max-width: 992px) {
  .sec_title {
    font-size: 28px;
    line-height: 32px;
  }
}

.sec-title {
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: -0.8px;
}

.sec-light {
  font-size: 25px;
  line-height: 36px;
  font-weight: 400;
  padding: 0 0 20px;
}

.sec-small {
  font-size: 13px;
  line-height: 24px;
  font-weight: 400;
}

.sec-heading {
  font-size: 20px;
  line-height: 30px;
}
@media (max-width: 992px) {
  .sec-heading {
    font-size: 14px;
    line-height: 22px;
  }
}

.sec-heading2 {
  font-size: 36px;
  line-height: 50px;
}

.sec-desc {
  font-size: 18px;
  line-height: 24px;
  padding: 10px 0;
}

.sec-text {
  font-size: 16px;
  line-height: 24px;
}

/*Animation Start*/
.revealOnScroll {
  opacity: 0;
  transition: all 0.4s ease-in-out;
}

.animated {
  -moz-animation-duration: 1.2s;
  -o-animation-duration: 1.2s;
  -webkit-animation-duration: 1.2s;
  -ms-animation-duration: 1.2s;
  animation-duration: 1.2s;
  -moz-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  animation-fill-mode: both;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    -o-transform: translate3d(-100%, 0, 0);
    -moz-transform: translate3d(-100%, 0, 0);
    -ms-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    -ms-transform: none;
    -moz-transform: none;
    -o-transform: none;
    transform: none;
  }
}
.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  -o-animation-name: fadeInLeft;
  -ms-animation-name: fadeInLeft;
  -moz-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    -o-transform: translate3d(100%, 0, 0);
    -ms-transform: translate3d(100%, 0, 0);
    -moz-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    -o-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    transform: none;
  }
}
.fadeInRight {
  -webkit-animation-name: fadeInRight;
  -o-animation-name: fadeInRight;
  -ms-animation-name: fadeInRight;
  -moz-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    -o-transform: translate3d(0, 100%, 0);
    -ms-transform: translate3d(0, 100%, 0);
    -moz-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    -o-transform: none;
    -ms-transform: none;
    -moz-transform: none;
    transform: none;
  }
}
.fadeInUp {
  -webkit-animation-name: fadeInUp;
  -o-animation-name: fadeInUp;
  -ms-animation-name: fadeInUp;
  -moz-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    -o-transform: translate3d(0, -100%, 0);
    -moz-transform: translate3d(0, -100%, 0);
    -ms-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    -o-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    transform: none;
  }
}
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  -o-animation-name: fadeInDown;
  -ms-animation-name: fadeInDown;
  -moz-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fadeIn {
  animation-name: fadeIn;
}

.slick-arrow {
  cursor: pointer;
}

.imgbox img {
  border-radius: 25px;
}

/******************* Single Job **************/
.awsm-job-container {
  padding: 50px;
}

.awsm-job-container h1 {
  margin-bottom: 20px;
}

.awsm-job-container h4 {
  margin-bottom: 10px;
  margin-top: 20px;
}

.awsm-job-container ul {
  padding-left: 30px;
}

.awsm-job-container ul li {
  padding-bottom: 10px;
  line-height: 26px;
  font-size: 16px;
}

.awsm-job-container ul li strong {
  font-weight: 600;
}

.awsm-job-specification-item {
  margin: 10px 0;
}

.awsm-job-post-title {
  color: #171717;
  font-weight: 600;
}

.awsm-job-more {
  color: #0d9298;
}

.menu {
  display: none;
}

@media only screen and (max-width: 1024px) {
  .col-md-1 {
    width: 8.3333333333%;
  }
  .col-md-2 {
    width: 16.6666666667%;
  }
  .col-md-3 {
    width: 25%;
  }
  .col-md-4 {
    width: 33.3333333333%;
  }
  .col-md-5 {
    width: 41.6666666667%;
  }
  .col-md-6 {
    width: 50%;
  }
  .col-md-7 {
    width: 58.3333333333%;
  }
  .col-md-8 {
    width: 66.6666666667%;
  }
  .col-md-9 {
    width: 75%;
  }
  .col-md-10 {
    width: 83.3333333333%;
  }
  .col-md-11 {
    width: 91.6666666667%;
  }
  .col-md-12 {
    width: 100%;
  }
  .col-md-12-5 {
    width: 20%;
  }
  .hidden-md {
    display: none !important;
  }
  .visible-md {
    display: block !important;
  }
}
@media only screen and (max-width: 992px) {
  .text-center-xs {
    text-align: center;
  }
  .visible-xs {
    display: block !important;
  }
  .hidden-xs {
    display: none !important;
  }
  .sidePadd {
    padding: 0 20px;
  }
  .padding {
    padding: 40px 0px;
  }
  .col-xs-6 {
    width: 50%;
  }
  .col-xs-12 {
    width: 100%;
  }
  header {
    height: 85px;
    width: 100%;
    border-radius: unset;
    margin: 0;
    box-shadow: none;
  }
  .burger-menu {
    display: block;
    padding: 0;
    margin-top: 0;
  }
  .sidelogo {
    width: 130px;
  }
  .menu {
    padding: 70px 0 0;
    height: 100%;
    overflow: auto;
    display: none;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: -1;
    border-bottom: 1px solid #ccc;
    padding: 0px;
    background-color: #fff;
    transform: unset;
  }
  .project-mobile-menu {
    height: auto;
    display: none;
    position: absolute;
    top: 50px;
    width: 100%;
    left: 0px;
    right: 0px;
    z-index: -1;
    border-bottom: 1px solid #ccc;
    padding: 0px;
    background-color: #000;
    transform: unset;
    z-index: 99;
  }
  .menu a, .project-mobile-menu a {
    text-align: left;
    font-size: 14px;
    padding: 6px 0px;
    border-right: 0px;
    margin: 0px;
    color: #fff;
    display: block;
  }
  .menu a.active, .project-mobile-menu a.active {
    position: relative;
    font-weight: 600;
    color: #fff;
  }
  .menu a.enquire-toggle-button.header-enquire, .menu a.call-us, #home a.home-enquire {
    display: none;
  }
  .menu li, .project-mobile-menu li {
    display: block;
    margin: 0 auto;
  }
  .nav-links, .project-mobile-menu {
    display: none;
  }
  .mobile_tabs h3 {
    padding: 10px 0px;
  }
  .mobile_tabs h3.active svg {
    transform: rotate(0deg);
  }
  .mobile_tabs h3 svg {
    width: 20px;
    float: right;
    position: relative;
    transform: rotate(270deg);
    transition: all 0.5s;
  }
  .menu li a {
    color: #000;
    margin: 0 auto;
    padding: 10px;
    font-size: 16px;
    line-height: 17px;
  }
  .menu .sub-menu {
    position: unset;
    margin-top: 0;
    display: none;
  }
  .menu .expand {
    visibility: visible;
    opacity: 1;
    display: block;
    width: 100%;
  }
  .parent-menu > a:after {
    border-top: 5px solid #fff;
    right: unset;
    top: 18px;
    margin-left: 10px;
  }
  .footer-call-enquire {
    display: block;
    position: fixed;
    bottom: 0;
    text-align: center;
    background-color: #fff;
    z-index: 2;
    border-top: 1px solid #ebebeb;
  }
  .footer-call-enquire a {
    font-size: 16px;
    font-weight: 600;
  }
  .enquire-toggle-button.mobile {
    cursor: pointer;
    letter-spacing: 1px;
    font-size: 16px;
    border: none;
    padding: 0 !important;
    float: unset;
    line-height: 25px;
    margin-left: 0;
    text-transform: uppercase;
    font-weight: 500;
  }
  .floating-enquire-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  #popup {
    padding: 30px 10px;
  }
  header .col-12 {
    padding: 8px 4px;
  }
  .brand-logo {
    padding: 0;
    border-right: unset;
  }
  .enquire-close-mobile {
    text-align: right;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .form-control {
    padding: 5px 12px;
  }
  .form_wrapper {
    padding: 20px;
  }
  .form-group {
    padding: 0;
  }
  input#submit_form {
    margin: 0;
    width: 100%;
  }
  .footer-top h4 {
    padding: 20px 0;
  }
  .footer-bottom {
    padding: 10px 0;
  }
  .footer-top .col-4 {
    padding: 0 0px 20px 0;
  }
  .rera p, .rera p a, .marketed p a, .rera-text span, .rera-text p {
    line-height: 23px;
    font-size: 14px;
  }
  .enq {
    padding: 8px 0;
  }
  .rera, .rera a, .marketed a, .marketed p {
    font-size: 14px;
    line-height: 26px;
  }
  .slick-prev, .slick-next {
    display: none !important;
  }
  .slider-drag-btn-wrapper {
    width: 285px;
  }
  .footer-mob {
    padding-bottom: 50px;
  }
  header .form-container {
    position: absolute;
    width: 100%;
    right: 0;
    top: 0;
    height: 100vh;
    background-color: #ffffff;
    padding: 10px;
    display: none;
    box-shadow: 0px 16px 20px 0px rgba(0, 0, 0, 0.28);
    padding-top: 30px !important;
  }
  .sell_do_virtual_numbers {
    color: #fff;
  }
}
@media only screen and (max-width: 700px) {
  header {
    height: 70px;
  }
  .menu {
    top: 70px;
    padding: 25px 20px 90px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .menu {
    top: 0px;
    padding: 70px 0 0;
  }
  .menu li a {
    padding: 15px 3px;
    font-size: 13px;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1200px) {
  .menu a {
    padding: 0px 3px;
    font-size: 12px;
  }
  .footer-bottom {
    padding: 0 25px 15px;
  }
  .footer-top {
    padding: 40px 30px 0px;
  }
}
/********* Homepage **************/
@keyframes homepage_spin_words {
  35% {
    transform: translateY(-100%);
  }
  70% {
    transform: translateY(-200%);
  }
  100% {
    transform: translateY(-300%);
  }
}
.homepage_spin_words {
  animation-name: homepage_spin_words;
  animation-duration: 6s;
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
}

.fold1_wrapper .reeloBrand_slider {
  padding: 40px 0;
}
.fold1_wrapper .reeloBrand_slider .brandSlide {
  height: 120px;
  padding: 0 27px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 30px 24px;
}
@media (max-width: 992px) {
  .fold1_wrapper .reeloBrand_slider .brandSlide {
    padding: 10px 24px;
    height: 80px;
    width: 120px !important;
  }
}
.fold1_wrapper .reeloBrand_slider .brandSlide img {
  width: 100px;
  height: 100px;
  object-fit: contain;
}
@media (max-width: 992px) {
  .fold1_wrapper .reeloBrand_slider .brandSlide img {
    width: 80px;
    height: 80px;
  }
}

.homepage .sec_subtitle {
  font-size: 24px;
  color: #697777;
  line-height: 28px;
  text-align: center;
  margin-bottom: 8px;
  letter-spacing: -0.96px;
  font-family: "Basier Circle medium";
}
@media (max-width: 992px) {
  .homepage .sec_subtitle {
    font-size: 16px;
    line-height: 24px;
  }
}
.homepage .sec_title {
  font-size: 58px;
  line-height: 64px;
  letter-spacing: -2.32px;
  font-family: "Basier Circle medium";
  font-weight: 500;
}
@media (max-width: 992px) {
  .homepage .sec_title {
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.96px;
  }
}
.homepage .banner {
  height: 100vh;
}
.homepage .banner .banner_wrapper .banner-container {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  padding: 0 15px;
}
.homepage .banner .banner_wrapper .banner-container .rating {
  margin-bottom: 24px;
}
@media (max-width: 992px) {
  .homepage .banner .banner_wrapper .banner-container .rating {
    margin-bottom: 0;
  }
}
.homepage .banner .banner_wrapper .banner-container .rating p {
  font-size: 16px;
  line-height: 22px;
  font-family: "Basier Circle medium";
  color: #697777;
}
@media (max-width: 992px) {
  .homepage .banner .banner_wrapper .banner-container .rating p {
    font-size: 14px;
    line-height: 20px;
  }
}
.homepage .banner .banner_wrapper .banner-container .homepage_title_wrapper {
  font-size: 72px;
  color: #192121;
  line-height: 75px;
  text-align: center;
  letter-spacing: -2.88px;
  font-family: "Basier Circle Semibold";
  margin: 12px auto 24px;
  gap: 0 16px;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 990px;
}
@media (max-width: 992px) {
  .homepage .banner .banner_wrapper .banner-container .homepage_title_wrapper {
    font-size: 34px;
    line-height: 42px;
    letter-spacing: -1.36px;
    width: 100%;
    margin: 10px 0;
  }
}
.homepage .banner .banner_wrapper .banner-container .banner-text {
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  color: #697777;
  text-align: center;
  letter-spacing: -0.2px;
  width: 815px;
  margin: 0 auto;
}
@media (max-width: 992px) {
  .homepage .banner .banner_wrapper .banner-container .banner-text {
    font-size: 14px;
    line-height: 20px;
    width: 100%;
  }
}
.homepage .banner .banner_wrapper .banner-container .btn_wrapper .theme-btn {
  font-size: 18px !important;
  width: 249px;
  margin: 20px auto 8px;
  padding: 15px 25px;
  font-family: "Basier Circle medium";
}
@media (max-width: 992px) {
  .homepage .banner .banner_wrapper .banner-container .btn_wrapper .theme-btn {
    margin: 0 auto 8px;
    height: unset;
  }
}
.homepage .banner .banner_wrapper .banner-container .btn_wrapper .text-small {
  color: #697777;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  font-family: "Basier Circle medium";
}
.homepage .fold1 .sec-title {
  font-size: 28px;
  color: #0e1b1b;
  line-height: 34px;
  text-align: center;
  margin: 0px 0 30px;
  letter-spacing: -0.28px;
  font-family: "Basier Circle medium";
  font-weight: 500;
}
@media (max-width: 992px) {
  .homepage .fold1 .sec-title {
    font-size: 24px;
    line-height: 30px;
    margin: 0 auto 12px;
    letter-spacing: -0.96px;
  }
}
.homepage .fold1 .sec-title span {
  color: #0d9298;
}
.homepage .fold3 .marrows {
  margin: 30px 0 35px;
}
.homepage .fold3 .marrows svg {
  width: 48px;
  height: 48px;
  background-color: #f3f5f5;
  border-radius: 100%;
  padding: 10px;
  margin: 0 10px;
}
.homepage .fold3 .marrows svg:hover {
  background-color: #0d9298;
}
.homepage .fold3 .marrows svg:hover path {
  stroke: #fff;
}
.homepage .fold3 .marrows #cnext {
  position: relative;
  transform: rotate(180deg);
}
.homepage .fold3 .reviewCarousel_parent .reviewCarousel {
  gap: 48px;
  padding: 24px;
  height: 381px;
  margin: 0 4px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  border-radius: 24px;
  align-items: stretch;
  background: #e0eaff;
}
@media (max-width: 992px) {
  .homepage .fold3 .reviewCarousel_parent .reviewCarousel {
    gap: 12px;
    padding: 16px;
    margin: 0 4px;
    border-radius: 8px;
    flex-direction: column;
    height: unset;
  }
}
.homepage .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_left {
  max-width: 665px;
  overflow: hidden;
  width: calc(50% - 24px);
}
@media (max-width: 992px) {
  .homepage .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_left {
    max-width: 100%;
    width: 100%;
    overflow: unset;
  }
}
.homepage .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_left iframe {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  border-radius: 12px;
}
.homepage .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_right {
  gap: 24px;
  padding: 10px 0 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  max-width: -moz-calc(50% - 24px);
  max-width: calc(50% - 24px);
}
@media (max-width: 992px) {
  .homepage .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_right {
    max-width: 100%;
    min-width: 100%;
    padding: 15px 0 0;
  }
}
.homepage .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_right h3 {
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -1.28px;
  font-family: "Basier Circle medium";
  font-weight: 500;
}
@media (max-width: 992px) {
  .homepage .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_right h3 {
    margin: 0 0 8px;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.24px;
  }
}
.homepage .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_right .reviewCarousel_review_detail {
  gap: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.homepage .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_right .reviewCarousel_review_detail .reviewImgs {
  border-radius: 50%;
  object-fit: contain;
  background-color: #fff;
}
.homepage .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_right .reviewCarousel_review_detail .reviewer_name {
  font-size: 22px;
  line-height: 26px;
  font-family: "Basier Circle medium";
  padding: 0 0 6px;
}
@media (max-width: 992px) {
  .homepage .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_right .reviewCarousel_review_detail .reviewer_name {
    font-size: 16px;
    line-height: 22px;
  }
}
.homepage .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_right .reviewCarousel_review_detail .reviewer_post {
  font-size: 14px;
  color: #374040;
  line-height: 20px;
  letter-spacing: -0.14px;
}
@media (max-width: 992px) {
  .homepage .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_right .reviewCarousel_review_detail .reviewer_post {
    font-size: 12px;
    line-height: 16px;
  }
}
.homepage .fold8 .reviewCarousel_case_studies {
  gap: 24px;
  margin: 60px 0 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
@media (max-width: 992px) {
  .homepage .fold8 .reviewCarousel_case_studies {
    gap: 18px;
    overflow-x: auto;
    margin: 24px 0 0;
    -moz-box-pack: start;
    justify-content: flex-start;
  }
}
.homepage .fold8 .reviewCarousel_case_studies .reviewCarousel_case_study {
  width: calc(50% - 12px);
  display: block;
}
@media (max-width: 992px) {
  .homepage .fold8 .reviewCarousel_case_studies .reviewCarousel_case_study {
    min-width: 254px;
    width: calc(50% - 9px);
  }
}
.homepage .fold8 .reviewCarousel_case_studies .reviewCarousel_case_study .reviewCarousel_link h3 {
  font-size: 28px;
  margin: 20px 0 12px;
  line-height: 36px;
  letter-spacing: -1.12px;
  color: #0e1b1b;
}
@media (max-width: 992px) {
  .homepage .fold8 .reviewCarousel_case_studies .reviewCarousel_case_study .reviewCarousel_link h3 {
    font-size: 14px;
    line-height: 20px;
    margin: 10px 0;
    letter-spacing: -0.14px;
  }
}
.homepage .fold8 .reviewCarousel_case_studies .reviewCarousel_case_study .reviewCarousel_link p {
  text-decoration: underline;
  font-family: "Basier Circle medium";
  color: #0e1b1b;
}
@media (max-width: 992px) {
  .homepage .fold8 .reviewCarousel_case_studies .reviewCarousel_case_study .reviewCarousel_link p {
    margin: 0 0 12px;
    font-size: 14px;
    line-height: 16px;
  }
}
.homepage .fold2 .fold2_wrapper {
  width: 100%;
  height: 565px;
  max-width: 1024px;
  margin: 40px auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}
@media (max-width: 992px) {
  .homepage .fold2 .fold2_wrapper {
    height: unset;
    max-width: 100%;
    width: 100%;
    display: block;
  }
}
.homepage .fold2 .fold2_wrapper .quotes_Img {
  width: 760px;
}
@media (max-width: 992px) {
  .homepage .fold2 .fold2_wrapper .quotes_Img {
    width: 100%;
  }
}
.homepage .fold2 .fold2_wrapper .quotes_Img .video-thumbnail .play-icon-wrapper {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  cursor: pointer;
}
.homepage .fold2 .fold2_wrapper .quotes_Img iframe {
  height: 403px;
  border: none;
  outline: none;
  border-radius: 24px;
}
.homepage .fold4 .sec-subtitle {
  font-size: 24px;
  color: #697777;
  line-height: 28px;
  text-align: center;
  margin-bottom: 8px;
  letter-spacing: -0.96px;
}
@media (max-width: 992px) {
  .homepage .fold4 .sec-subtitle {
    font-size: 16px;
    line-height: 20px;
  }
}
.homepage .fold4 .homepage_features {
  margin: 60px 0 0;
  gap: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
@media (max-width: 992px) {
  .homepage .fold4 .homepage_features {
    margin: 30px 0 0;
    gap: 32px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
}
.homepage .fold4 .homepage_features .homepage_feature_card {
  gap: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 992px) {
  .homepage .fold4 .homepage_features .homepage_feature_card {
    gap: 16px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
  }
}
.homepage .fold4 .homepage_features .homepage_feature_card .homepage_left {
  max-width: 453px;
}
@media (max-width: 992px) {
  .homepage .fold4 .homepage_features .homepage_feature_card .homepage_left {
    max-width: 100%;
  }
}
.homepage .fold4 .homepage_features .homepage_feature_card .homepage_right {
  max-width: 582px;
}
@media (max-width: 992px) {
  .homepage .fold4 .homepage_features .homepage_feature_card .homepage_right {
    max-width: 100%;
  }
}
.homepage .fold4 .homepage_features .homepage_feature_card .homepage_right h3 {
  font-size: 32px;
  margin: 0 0 14px;
  color: #0e1b1b;
  line-height: 38px;
  letter-spacing: -0.96px;
  font-family: "Basier Circle medium";
  font-weight: 500;
}
@media (max-width: 992px) {
  .homepage .fold4 .homepage_features .homepage_feature_card .homepage_right h3 {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.14px;
  }
}
.homepage .fold4 .homepage_features .homepage_feature_card .homepage_right p {
  font-size: 18px;
  margin: 0 0 28px;
  color: #697777;
  line-height: 24px;
  letter-spacing: -0.18px;
}
@media (max-width: 992px) {
  .homepage .fold4 .homepage_features .homepage_feature_card .homepage_right p {
    font-size: 14px;
    margin: 0 0 16px;
    line-height: 20px;
    letter-spacing: -0.14px;
  }
}
.homepage .fold4 .homepage_features .homepage_feature_card .homepage_right .homepage_btn_row {
  gap: 28px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
@media (max-width: 992px) {
  .homepage .fold4 .homepage_features .homepage_feature_card .homepage_right .homepage_btn_row {
    gap: 16px;
  }
  .homepage .fold4 .homepage_features .homepage_feature_card .homepage_right .homepage_btn_row .theme-btn {
    padding: 7px 10px;
    width: 125px;
    font-size: 12px !important;
  }
}
.homepage .fold4 .homepage_features .homepage_feature_card .homepage_right .homepage_btn_row .custom-btn {
  color: #0E1B1B;
  background: transparent;
  border-radius: 8px;
  text-decoration: underline;
  font-weight: 600;
}
.homepage .fold5 .homepage_more_features_cards {
  margin: 60px 0 0;
  gap: 32px 36px;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 992px) {
  .homepage .fold5 .homepage_more_features_cards {
    gap: 28px;
  }
}
.homepage .fold5 .homepage_more_features_cards .homepage_more_feature_card {
  border-radius: 12px;
  padding: 32px 40px 0;
  width: calc(50% - 18px);
  background-color: #f7f8f8;
  height: 608px;
}
@media (max-width: 992px) {
  .homepage .fold5 .homepage_more_features_cards .homepage_more_feature_card {
    width: 100%;
    border-radius: 8px;
    padding: 20px 20px 0;
    background-color: #f7f8f8;
    height: auto;
  }
}
.homepage .fold5 .homepage_more_features_cards .homepage_more_feature_card h3 {
  font-size: 24px;
  color: #0e1b1b;
  line-height: 30px;
  letter-spacing: -0.24px;
  font-family: "Basier Circle medium";
  font-weight: 500;
}
@media (max-width: 992px) {
  .homepage .fold5 .homepage_more_features_cards .homepage_more_feature_card h3 {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.14px;
  }
}
.homepage .fold5 .homepage_more_features_cards .homepage_more_feature_card p {
  margin: 8px 0;
  font-size: 20px;
  color: #374040;
  line-height: 26px;
  letter-spacing: -0.2px;
}
@media (max-width: 992px) {
  .homepage .fold5 .homepage_more_features_cards .homepage_more_feature_card p {
    margin: 4px 0;
    font-size: 14px;
    line-height: 20px;
  }
}
.homepage .fold5 .homepage_more_features_cards .homepage_more_feature_card a {
  color: #0d9298 !important;
  font-family: "Basier Circle medium";
  text-decoration: underline !important;
}
@media (max-width: 992px) {
  .homepage .fold5 .homepage_more_features_cards .homepage_more_feature_card a {
    font-size: 12px;
  }
}
.homepage .fold5 .homepage_more_features_cards .homepage_more_feature_card .homepage_card_img {
  width: 100%;
  height: 410px;
  cursor: pointer;
  margin: 20px 0 0;
  position: relative;
}
@media (max-width: 992px) {
  .homepage .fold5 .homepage_more_features_cards .homepage_more_feature_card .homepage_card_img {
    height: 260px;
  }
}
.homepage .fold5 .homepage_more_features_cards .homepage_more_feature_card .homepage_card_img img {
  height: 410px;
}
@media (max-width: 992px) {
  .homepage .fold5 .homepage_more_features_cards .homepage_more_feature_card .homepage_card_img img {
    height: 260px;
  }
}
.homepage .fold6 {
  padding: 40px 0;
}
@media (max-width: 992px) {
  .homepage .fold6 {
    padding: 30px 0 0;
  }
}
.homepage .fold6 .homepage_start_free_trial {
  padding: 34px 0;
  cursor: pointer;
  transform: skewY(-1.47deg);
  background: linear-gradient(93deg, #168489 -2.4%, #20c3c3 94.01%);
  overflow: hidden;
  white-space: nowrap;
}
@media (max-width: 992px) {
  .homepage .fold6 .homepage_start_free_trial {
    padding: 18px 0;
  }
}
.homepage .fold6 .homepage_start_free_trial .marquee {
  display: flex;
  animation: marquee 20s linear infinite;
}
.homepage .fold6 .homepage_start_free_trial .marquee .marquee-content {
  display: flex;
  white-space: nowrap;
}
.homepage .fold6 .homepage_start_free_trial .marquee .marquee-content .homepage_free_trial_text {
  padding: 0 48px;
  width: 100%;
  display: inline-flex !important;
  align-items: center;
  margin-right: 2rem;
}
.homepage .fold6 .homepage_start_free_trial .marquee .marquee-content .homepage_free_trial_text p {
  font-size: 28px;
  color: #fff;
  line-height: 34px;
  letter-spacing: -0.28px;
  font-family: "Basier Circle medium";
}
@media (max-width: 992px) {
  .homepage .fold6 .homepage_start_free_trial .marquee .marquee-content .homepage_free_trial_text p {
    font-size: 18px;
  }
}
@keyframes marquee {
  from {
    transform: translateX(-50%);
  }
  to {
    transform: translateX(0);
  }
}
.homepage .fold7 .homepage_quality_cards {
  margin: 60px 0 0;
  gap: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: stretch;
}
@media (max-width: 992px) {
  .homepage .fold7 .homepage_quality_cards {
    gap: 16px;
    padding: 0 16px;
    overflow-x: auto;
    justify-content: flex-start;
    margin: 20px 0 0;
  }
}
.homepage .fold7 .homepage_quality_cards .homepage_quality_card {
  padding: 40px;
  border-radius: 12px;
  background: #0e1b1b;
  width: -moz-calc(33% - 14px);
  width: calc(33% - 14px);
}
@media (max-width: 992px) {
  .homepage .fold7 .homepage_quality_cards .homepage_quality_card {
    padding: 20px;
    min-width: 226px;
    border-radius: 8px;
    width: calc(33% - 14px);
  }
}
.homepage .fold7 .homepage_quality_cards .homepage_quality_card svg {
  display: block;
  margin: 0 auto 8px;
}
@media (max-width: 992px) {
  .homepage .fold7 .homepage_quality_cards .homepage_quality_card svg {
    width: 24px;
    height: 24px;
  }
}
.homepage .fold7 .homepage_quality_cards .homepage_quality_card h3 {
  font-size: 24px;
  color: #ebbd07;
  margin: 0 0 32px;
  line-height: 30px;
  text-align: center;
  letter-spacing: -0.24px;
  font-family: "Basier Circle medium";
  font-weight: 500;
  height: 30px;
}
@media (max-width: 992px) {
  .homepage .fold7 .homepage_quality_cards .homepage_quality_card h3 {
    font-size: 14px;
    margin: 0 0 5px;
    line-height: 20px;
    letter-spacing: -0.14px;
  }
}
.homepage .fold7 .homepage_quality_cards .homepage_quality_card .homepage_number {
  margin: 0 0 10px;
  font-size: 56px;
  line-height: 84px;
  color: #fff;
  text-align: center;
  letter-spacing: -0.56px;
  font-family: "Basier Circle Semibold";
}
@media (max-width: 992px) {
  .homepage .fold7 .homepage_quality_cards .homepage_quality_card .homepage_number {
    margin: 0 0 8px;
    font-size: 28px;
    line-height: 34px;
    letter-spacing: -0.28px;
  }
}
.homepage .fold7 .homepage_quality_cards .homepage_quality_card .homepage_subtitle {
  font-size: 20px;
  text-align: center;
  font-style: italic;
  line-height: 26px;
  letter-spacing: -0.2px;
  color: hsla(0, 0%, 100%, 0.78);
}
@media (max-width: 992px) {
  .homepage .fold7 .homepage_quality_cards .homepage_quality_card .homepage_subtitle {
    font-size: 12px;
    line-height: 16px;
  }
}
.homepage .fold9 {
  background-color: #f7f8f8;
  padding: 70px 0 220px;
}
@media (max-width: 992px) {
  .homepage .fold9 {
    padding: 40px 0 100px;
  }
}
.homepage .fold9 .sec_desc {
  font-size: 20px;
  color: #697777;
  line-height: 28px;
  text-align: center;
  margin: 16px 0 32px;
  letter-spacing: -0.2px;
}
.homepage .fold9 .templateCarousel_templates_carousel .templateCarousel_channel {
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 166px;
}
@media (max-width: 992px) {
  .homepage .fold9 .templateCarousel_templates_carousel .templateCarousel_channel {
    margin: 0 auto 160px;
  }
}
.homepage .fold9 .templateCarousel_templates_carousel .templateCarousel_channel .templateCarousel_tab {
  cursor: pointer;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 80px;
  background: #fff;
  border: 1px solid #e9eded;
}
.homepage .fold9 .templateCarousel_templates_carousel .templateCarousel_channel .templateCarousel_tab span {
  margin: 0;
  font-size: 18px;
  color: #697777;
  line-height: 24px;
  letter-spacing: -0.18px;
  font-family: "Basier Circle medium";
}
.homepage .fold9 .templateCarousel_templates_carousel .templateCarousel_channel .templateCarousel_tab.active_channel {
  background: #0d9298;
  border: 1px solid #0d9298;
}
.homepage .fold9 .templateCarousel_templates_carousel .templateCarousel_channel .templateCarousel_tab.active_channel span {
  color: #fff;
}
.homepage .fold9 .templateCarousel_templates_carousel .templateCarousel_slick_parent .customer-logos .templateCarousel_card_parent .templateCarousel_card {
  height: 377px;
  position: relative;
  border-radius: 18px;
  transition: 0.1s linear;
}
@media (max-width: 992px) {
  .homepage .fold9 .templateCarousel_templates_carousel .templateCarousel_slick_parent .customer-logos .templateCarousel_card_parent .templateCarousel_card {
    height: 160px;
  }
}
.homepage .fold9 .templateCarousel_templates_carousel .templateCarousel_slick_parent .customer-logos .templateCarousel_card_parent .templateCarousel_card:before {
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 18px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0, hsla(0, 0%, 100%, 0) 80%);
  z-index: 1;
}
.homepage .fold9 .templateCarousel_templates_carousel .templateCarousel_slick_parent .customer-logos .templateCarousel_card_parent .templateCarousel_card .templateCarousel_cardImg img {
  height: 377px;
  position: relative;
  border-radius: 18px;
  transition: 0.1s linear;
  object-fit: cover;
  border-radius: 18px;
  vertical-align: middle;
}
@media (max-width: 992px) {
  .homepage .fold9 .templateCarousel_templates_carousel .templateCarousel_slick_parent .customer-logos .templateCarousel_card_parent .templateCarousel_card .templateCarousel_cardImg img {
    height: 160px;
  }
}
.homepage .fold9 .templateCarousel_templates_carousel .templateCarousel_slick_parent .customer-logos .templateCarousel_card_parent .templateCarousel_card .templateCarousel_template_title {
  width: 100%;
  left: 0;
  top: 31px;
  z-index: 2;
  height: 60%;
  color: #fff;
  font-size: 24px;
  line-height: 29px;
  position: absolute;
  word-wrap: break-word;
  padding: 0 8px 0 20px;
  font-family: "Basier Circle Semibold";
}
@media (max-width: 992px) {
  .homepage .fold9 .templateCarousel_templates_carousel .templateCarousel_slick_parent .customer-logos .templateCarousel_card_parent .templateCarousel_card .templateCarousel_template_title {
    font-size: 12px;
    line-height: 16px;
    padding: 0 8px 0 10px;
    top: 10px;
  }
}
.homepage .fold10 .homepage_integration {
  margin: 40px 0 0;
}
@media (max-width: 992px) {
  .homepage .fold10 .homepage_integration {
    margin: 20px 0 0;
  }
}
.homepage .fold10 .homepage_integration .logoSlider {
  padding: 30px 0;
  display: flex;
  white-space: nowrap;
}
@media (max-width: 992px) {
  .homepage .fold10 .homepage_integration .logoSlider {
    padding: 10px 0;
  }
}
.homepage .fold10 .homepage_integration .logoSlider .homepage_logo_parent {
  height: 114px;
  margin: 0 24px;
  position: relative;
  border-radius: 12px;
  background: #f7f8f8;
  width: 267px !important;
}
@media (max-width: 992px) {
  .homepage .fold10 .homepage_integration .logoSlider .homepage_logo_parent {
    height: 58px;
    padding: 0 8px;
    margin: 0 12px;
    border-radius: 8px;
    width: 100px !important;
  }
}
.homepage .fold10 .homepage_integration .logoSlider .homepage_logo_parent img {
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  object-fit: scale-down;
  filter: none;
  background-size: cover;
  background-image: none;
  padding: 20px 35px;
}
@media (max-width: 992px) {
  .homepage .fold10 .homepage_integration .logoSlider .homepage_logo_parent img {
    padding: 9px 10px;
  }
}

@keyframes marquee1 {
  from {
    transform: translateX(-50%);
  }
  to {
    transform: translateX(0);
  }
}
.homepagenew .sec_title {
  font-size: 57px;
  font-weight: 800;
  line-height: 120%;
}
@media (max-width: 992px) {
  .homepagenew .sec_title {
    font-size: 28px;
  }
}
.homepagenew .sec_desc {
  font-size: 24px;
  font-weight: 400;
  line-height: 150%;
  padding: 10px 0 40px;
}
@media (max-width: 992px) {
  .homepagenew .sec_desc {
    font-size: 16px;
  }
}
.homepagenew .banner {
  background-image: url("../images/home-bg.webp");
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 1500px;
}
@media (max-width: 992px) {
  .homepagenew .banner {
    height: 858px;
    background-image: url("../images/home-bg-mob.webp");
  }
}
.homepagenew .banner .banner_wrapper {
  padding: 120px 0 0;
}
.homepagenew .banner .banner_wrapper .banner_container .rating {
  font-size: 13px;
  font-weight: 400;
  line-height: 150%;
}
.homepagenew .banner .banner_wrapper .banner_container .rating img {
  vertical-align: bottom;
  margin-right: 10px;
  font-size: 8px;
}
.homepagenew .banner .banner_wrapper .banner_container .banner_title {
  font-size: 76px;
  font-weight: 800;
  line-height: 110%;
  letter-spacing: -0.76px;
  padding: 20px 0 24px;
}
@media (max-width: 992px) {
  .homepagenew .banner .banner_wrapper .banner_container .banner_title {
    font-size: 32px;
  }
}
.homepagenew .banner .banner_wrapper .banner_container .banner_desc {
  font-size: 24px;
  font-weight: 500;
  line-height: 150%; /* 36px */
}
@media (max-width: 992px) {
  .homepagenew .banner .banner_wrapper .banner_container .banner_desc {
    font-size: 15px;
  }
}
.homepagenew .banner .banner_wrapper .banner_container .banner_img {
  padding: 40px 0 0;
}
@media (max-width: 992px) {
  .homepagenew .banner .banner_wrapper .banner_container .banner_img {
    padding: 20px 0 0;
  }
}
.homepagenew .fold1 .fold1_wrapper .logoSlider {
  padding: 20px 0;
  display: flex;
  white-space: nowrap;
}
.homepagenew .fold1 .fold1_wrapper .logoSlider .homepage_logo_parent {
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: auto !important;
}
.homepagenew .fold1 .fold1_wrapper .logoSlider .homepage_logo_parent img {
  object-fit: contain;
  height: 85px;
}
@media (max-width: 992px) {
  .homepagenew .fold1 .fold1_wrapper .logoSlider .homepage_logo_parent img {
    height: 60px;
  }
}
.homepagenew .fold2 .card_wrapper .cards-section .card {
  top: 0;
  left: 0;
  margin: 0 0 30px;
  width: 100%;
  height: 440px;
  border-radius: 30px 30px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.5s ease, z-index 0.5s ease;
}
@media (max-width: 992px) {
  .homepagenew .fold2 .card_wrapper .cards-section .card {
    display: block;
    height: unset;
    margin: 0;
  }
}
.homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}
@media (max-width: 992px) {
  .homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex {
    display: block;
  }
}
.homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .flex1 {
  border-radius: 30px;
  width: 463px;
  height: 450px;
  display: flex;
  align-items: center;
  padding: 20px;
}
@media (max-width: 992px) {
  .homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .flex1 {
    width: 100%;
    height: unset;
    display: block;
    padding: 20px;
    margin: 0 0 20px;
    border-radius: 14px;
  }
}
.homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .flex1 .challenge {
  font-size: 16px;
  font-weight: 700;
  line-height: 150%; /* 27px */
  text-transform: uppercase;
  padding-bottom: 8px;
}
@media (max-width: 992px) {
  .homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .flex1 .challenge {
    font-size: 14px;
  }
}
.homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .flex1 .c1 {
  color: #FFBBCC;
}
.homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .flex1 .c2 {
  color: #C9FFE5;
}
.homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .flex1 .c3 {
  color: #FFE7B6;
}
.homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .flex1 .c4 {
  color: #D1E6FF;
}
.homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .flex1 .c_title {
  font-size: 36px;
  font-weight: 700;
  line-height: 120%; /* 43.2px */
}
@media (max-width: 992px) {
  .homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .flex1 .c_title {
    font-size: 24px;
  }
}
.homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .flex1 .flex-list {
  padding: 30px 0 0 18px;
}
.homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .flex1 .flex-list li {
  font-size: 16px;
  font-weight: 400;
  line-height: 150%; /* 24px */
}
@media (max-width: 992px) {
  .homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .flex1 .flex-list li {
    font-size: 14px;
  }
}
.homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .flex1 .fl1 li {
  color: #FFF9F0;
}
.homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .flex1 .fl2 li {
  color: #fff;
}
.homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .f1 {
  background: #990024;
}
.homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .f2 {
  background: #15B79E;
}
.homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .f3 {
  background: #E29600;
}
.homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .f4 {
  background: #044A9E;
}
.homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .flexkomal {
  padding: 0px 100px 0 74px !important;
}
@media (max-width: 992px) {
  .homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .flexkomal {
    padding: 0 !important;
  }
}
.homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .flex2 {
  display: flex;
  width: 657px;
  height: 450px;
  padding: 0px 74px 0 74px;
  justify-content: center;
  align-items: flex-end;
  border-radius: 30px;
}
@media (max-width: 992px) {
  .homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .flex2 {
    width: 100%;
    height: unset;
    padding: 10px 20px 0;
    margin: 0 0 20px;
    border-radius: 14px;
  }
}
.homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .flex2 .flimg {
  width: 596.749px;
}
@media (max-width: 992px) {
  .homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .flex2 .flimg {
    width: 100%;
  }
}
.homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .flex2 .komal {
  width: 630.387px;
  border-radius: 0 0 0 30px;
}
@media (max-width: 992px) {
  .homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .flex2 .komal {
    width: 100%;
    border-radius: 0 0 0 14px;
  }
}
.homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .fc1 {
  background: #FDE0E7;
  align-items: center;
}
.homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .fc2 {
  background: #CAFFE5;
}
.homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .fc3 {
  background: #FFE7B6;
}
.homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .fc4 {
  background: #D1E6FF;
}
.homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .flex3 {
  display: flex;
  width: 349px;
  flex-direction: column;
  align-items: flex-start;
  gap: 50px;
  flex-shrink: 0;
}
.homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .flex3 .list ul {
  padding: 0 0 0 17px;
}
.homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .flex3 .list ul li {
  color: #1A1A1A;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%; /* 24px */
}
.homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .flex3 .quote {
  font-size: 24px;
  font-weight: 500;
  line-height: 150%;
}
.homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .flex3 .cardquote1 {
  color: #4B001A;
}
.homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .flex3 .cardquote2 {
  color: #6C5501;
}
.homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .flex3 .cardquote3 {
  color: #6E3E00;
}
.homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .flex3 .cardquote4 {
  color: #004B26;
}
.homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .flex3 .quote_div {
  font-feature-settings: "liga" off, "clig" off;
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 20px 0 0;
}
.homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .flex3 .cardtext1 {
  color: #B62256;
}
.homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .flex3 .cardtext2 {
  color: #AA8500;
}
.homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .flex3 .cardtext3 {
  color: #EC921C;
}
.homepagenew .fold2 .card_wrapper .cards-section .card .cardFlex .flex3 .cardtext4 {
  color: #254A38;
}
.homepagenew .fold3 {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 100px 0 96px;
}
@media (max-width: 992px) {
  .homepagenew .fold3 {
    padding: 30px 0;
    height: unset;
    background-image: url("../images/video-frame-mob.webp") !important;
  }
}
@media (max-width: 992px) {
  .homepagenew .fold3 .sec_title {
    font-size: 20px;
  }
}
@media (max-width: 992px) {
  .homepagenew .fold3 .sec_desc {
    font-size: 10px;
    padding: 10px 0;
  }
}
@media (max-width: 992px) {
  .homepagenew .fold3 .video_wrapper {
    padding: 0 20px;
  }
}
.homepagenew .fold3 .video_wrapper .quotes_Img .video-thumbnail .play-icon-wrapper {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  cursor: pointer;
}
.homepagenew .fold3 .video_wrapper iframe {
  height: 563px;
  border-radius: 12px;
  border: none;
  border-radius: 8px;
}
@media (max-width: 992px) {
  .homepagenew .fold3 .video_wrapper iframe {
    height: 265px;
  }
}
.homepagenew .fold4 .tooltabs {
  padding: 15px 0 72px;
}
@media (max-width: 992px) {
  .homepagenew .fold4 .tooltabs {
    display: flex;
    overflow-x: auto;
    width: 100%;
    white-space: nowrap;
    scrollbar-width: thin;
    padding: 15px 0 30px;
    margin: 0 0 10px;
  }
}
.homepagenew .fold4 .tooltabs a {
  color: #023342;
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
  border-radius: 48px;
  border: 1px solid #023342;
  padding: 10px 24px 6px;
  margin: 12px 12px 0 0;
  display: inline-block;
}
@media (max-width: 992px) {
  .homepagenew .fold4 .tooltabs a {
    font-size: 14px;
    padding: 8px 16px 6px;
    margin: 12px 6px 0 0;
  }
}
.homepagenew .fold4 .tooltabs a.active {
  color: #fff;
  background: var(--colour-primary-teal-dark, #023342);
}
.homepagenew .fold4 .toolFold {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 72px;
}
@media (max-width: 992px) {
  .homepagenew .fold4 .toolFold {
    flex-direction: column-reverse;
    gap: 30px;
  }
}
.homepagenew .fold4 .toolFold .toolLeft {
  max-width: 530px;
}
@media (max-width: 992px) {
  .homepagenew .fold4 .toolFold .toolLeft {
    max-width: 100%;
  }
}
.homepagenew .fold4 .toolFold .toolLeft .sec_title {
  color: #023342;
  font-size: 43px;
  font-weight: 600;
  line-height: 120%;
  padding-bottom: 20px;
}
@media (max-width: 992px) {
  .homepagenew .fold4 .toolFold .toolLeft .sec_title {
    font-size: 24px;
  }
}
.homepagenew .fold4 .toolFold .toolLeft ul {
  padding: 0 0 40px 22px;
}
.homepagenew .fold4 .toolFold .toolLeft ul li {
  color: #1A1A1A;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 27px */
}
@media (max-width: 992px) {
  .homepagenew .fold4 .toolFold .toolLeft ul li {
    font-size: 16px;
  }
}
.homepagenew .fold4 .toolFold .toolRight {
  max-width: 560px;
}
@media (max-width: 992px) {
  .homepagenew .fold4 .toolFold .toolRight {
    max-width: 100%;
  }
}
.homepagenew .fold5 {
  background: #FFF1DD;
}
.homepagenew .fold5 .static_wrapper {
  padding: 40px 0 0;
  border-radius: 12px;
}
.homepagenew .fold5 .static_wrapper .fold1 {
  background: var(--secondary-shades-teal03-3t300, #1A424F);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px;
  height: 400px;
  border-radius: 12px 0 0 12px;
}
@media (max-width: 992px) {
  .homepagenew .fold5 .static_wrapper .fold1 {
    border-radius: 12px 12px 0 0;
    height: 350px;
  }
}
.homepagenew .fold5 .static_wrapper .fold1 .fold1_desc .title {
  font-size: 32px;
  font-weight: 800;
  line-height: 130%;
}
.homepagenew .fold5 .static_wrapper .fold1 .fold1_desc .desc {
  font-size: 14px;
  font-weight: 400;
  line-height: 130%;
  padding: 8px 0 0;
}
.homepagenew .fold5 .static_wrapper .fold1 .fold1_desc .cta-btn {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 19.6px */
  text-transform: uppercase;
  color: #04BEBD;
}
.homepagenew .fold5 .static_wrapper .fold2 .fold2_wrapper .foldLeft {
  background-color: #D16309;
  height: 270px;
  padding: 165px 30px 30px 30px;
}
@media (max-width: 992px) {
  .homepagenew .fold5 .static_wrapper .fold2 .fold2_wrapper .foldLeft {
    padding: 80px 30px 30px;
    height: unset;
  }
}
.homepagenew .fold5 .static_wrapper .fold2 .fold2_wrapper .foldLeft .cs_count .number {
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: 130%;
}
.homepagenew .fold5 .static_wrapper .fold2 .fold2_wrapper .foldLeft .cs_count .desc {
  font-size: 14px;
  font-weight: 400;
  line-height: 130%;
  padding: 8px 0 0;
}
.homepagenew .fold5 .static_wrapper .fold2 .fold2_wrapper .foldRight {
  height: 270px;
}
@media (max-width: 992px) {
  .homepagenew .fold5 .static_wrapper .fold2 .fold2_wrapper .foldRight {
    height: unset;
  }
}
.homepagenew .fold5 .static_wrapper .fold2 .fold2_wrapper .foldRight img {
  border-radius: 0 12px 12px 0;
}
@media (max-width: 992px) {
  .homepagenew .fold5 .static_wrapper .fold2 .fold2_wrapper .foldRight img {
    border-radius: 0;
  }
}
.homepagenew .fold5 .static_wrapper .fold2 .foldBottom {
  background: #F9CE78;
  border-radius: 0 0 12px 0;
}
@media (max-width: 992px) {
  .homepagenew .fold5 .static_wrapper .fold2 .foldBottom {
    border-radius: 0 0 12px 12px;
    margin: -5px 0 0;
  }
}
.homepagenew .fold5 .static_wrapper .fold2 .foldBottom .flexBox {
  display: inline-flex;
  padding: 26px 90px 26px 30.258px;
  align-items: flex-start;
  gap: 20.314px;
}
@media (max-width: 992px) {
  .homepagenew .fold5 .static_wrapper .fold2 .foldBottom .flexBox {
    padding: 20px;
    gap: 10px;
    flex-wrap: wrap;
  }
}
.homepagenew .fold5 .static_wrapper .fold2 .foldBottom .flexBox p {
  color: #1A424F;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}
@media (max-width: 992px) {
  .homepagenew .fold5 .static_wrapper .fold2 .foldBottom .flexBox p {
    font-size: 16px;
  }
}
.homepagenew .fold5 .reelo_cs {
  padding: 24px 0 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
@media (max-width: 992px) {
  .homepagenew .fold5 .reelo_cs {
    display: block;
  }
}
.homepagenew .fold5 .reelo_cs .blogBox {
  width: 404px;
}
@media (max-width: 992px) {
  .homepagenew .fold5 .reelo_cs .blogBox {
    width: 100%;
  }
}
.homepagenew .fold5 .reelo_cs .blogBox .csinner-box-wrapper {
  border-radius: 12px;
  background: var(--colour-monochrome-white, #FFF);
  box-shadow: 3px 4px 12.2px 0px rgba(2, 51, 66, 0.12);
  padding: 20px;
}
.homepagenew .fold5 .reelo_cs .blogBox .csinner-box-wrapper a .cs-box .cs_Img {
  padding: 0 0 12px;
}
.homepagenew .fold5 .reelo_cs .blogBox .csinner-box-wrapper a .cs-box .title {
  color: #1A424F;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  padding: 12px 0 0;
}
@media (max-width: 992px) {
  .homepagenew .fold5 .reelo_cs .blogBox .csinner-box-wrapper a .cs-box .title {
    font-size: 20px;
  }
}
.homepagenew .fold5 .reelo_cs .blogBox .csinner-box-wrapper a .cs-box .desc {
  color: #666;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  padding: 14px 0;
}
.homepagenew .fold5 .reelo_cs .blogBox .csinner-box-wrapper a .cs-box .cta-btn {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 19.6px */
  text-transform: uppercase;
  color: #04BEBD;
}
.homepagenew .fold6 .integrate_wrapper {
  padding-top: 50px;
}
@media (max-width: 992px) {
  .homepagenew .fold6 .integrate_wrapper {
    padding-top: 20px;
  }
}
.homepagenew .fold6 .integrate_wrapper .flexDiv {
  padding-top: 8px;
  display: flex;
  align-items: center;
  align-content: center;
  gap: 8px;
  flex-wrap: wrap;
}
@media (max-width: 992px) {
  .homepagenew .fold6 .integrate_wrapper .flexDiv {
    padding-top: 40px;
    align-items: center;
    align-content: center;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 6px;
    text-align: center;
  }
}
.homepagenew .fold6 .integrate_wrapper .flexDiv .integrate img {
  height: 60px;
}
@media (max-width: 992px) {
  .homepagenew .fold6 .integrate_wrapper .flexDiv .integrate img {
    height: 30px;
  }
}
.homepagenew .fold7 {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@media (max-width: 992px) {
  .homepagenew .fold7 {
    background-image: url("../images/intergration-bg-mob.webp") !important;
  }
}
.homepagenew .fold7 .lovereelo_wrapper {
  padding-top: 60px;
  display: flex;
  align-items: flex-start;
  gap: 20px;
  justify-content: center;
}
@media (max-width: 992px) {
  .homepagenew .fold7 .lovereelo_wrapper {
    flex-wrap: wrap;
    gap: 40px;
    padding-top: 40px;
  }
}
.homepagenew .fold7 .lovereelo_wrapper .lovereelo_inner .lovereelo_title {
  font-size: 43px;
  font-weight: 600;
  line-height: 120%;
}
@media (max-width: 992px) {
  .homepagenew .fold7 .lovereelo_wrapper .lovereelo_inner .lovereelo_title {
    font-size: 30px;
  }
}
.homepagenew .fold7 .lovereelo_wrapper .lovereelo_inner .t1 {
  color: #FAC8D4;
}
.homepagenew .fold7 .lovereelo_wrapper .lovereelo_inner .t2 {
  color: #B6EB9A;
}
.homepagenew .fold7 .lovereelo_wrapper .lovereelo_inner .t3 {
  color: #FFE7B6;
}
.homepagenew .fold7 .lovereelo_wrapper .lovereelo_inner .lovereelo_img {
  padding: 20px 0;
}
.homepagenew .fold7 .lovereelo_wrapper .lovereelo_inner .lovereelo_desc {
  display: flex;
  align-items: flex-start;
  gap: 14px;
  justify-content: center;
}
.homepagenew .fold7 .lovereelo_wrapper .lovereelo_inner .lovereelo_desc span {
  font-size: 48px;
  font-weight: 900;
  line-height: 120%;
}
@media (max-width: 992px) {
  .homepagenew .fold7 .lovereelo_wrapper .lovereelo_inner .lovereelo_desc span {
    font-size: 39px;
  }
}
.homepagenew .fold7 .lovereelo_wrapper .lovereelo_inner .lovereelo_desc p {
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
  text-align: left;
}
@media (max-width: 992px) {
  .homepagenew .fold7 .lovereelo_wrapper .lovereelo_inner .lovereelo_desc p {
    font-size: 13px;
  }
}
.homepagenew .fold8 {
  margin: -2px 0 0;
  background: #023342;
}
.homepagenew .fold8 .faq_wrapper {
  padding: 40px 0 0;
}
.homepagenew .fold8 .faqs-list {
  padding: 0 20px 0 0;
  min-height: 400px;
}
@media (max-width: 992px) {
  .homepagenew .fold8 .faqs-list {
    padding: 0;
    min-height: unset;
  }
}
.homepagenew .fold8 .faqs-list .book-demo {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  margin: 60px 0 0;
}
@media (max-width: 992px) {
  .homepagenew .fold8 .faqs-list .book-demo {
    flex-wrap: wrap;
    gap: 20px;
    text-align: center;
  }
}
.homepagenew .fold8 .faqs-list .book-demo p {
  color: #04BEBD;
  font-size: 24px;
  font-weight: 600;
  line-height: 100%; /* 24px */
}
@media (max-width: 992px) {
  .homepagenew .fold8 .faqs-list .book-demo p {
    font-size: 18px;
  }
}
.homepagenew .fold8 .set {
  position: relative;
  width: 100%;
  height: auto;
  margin: auto;
}
.homepagenew .fold8 .set a {
  display: block;
  padding: 16px;
  text-decoration: none;
  transition: all 0.2s linear;
  margin: 8px 0 0;
  font-size: 20px;
  color: #1A424F;
  line-height: 130%;
  border-radius: 12px;
  background: var(--colour-monochrome-white, #FFF);
  font-weight: 600;
  position: relative;
  height: auto;
}
@media (max-width: 992px) {
  .homepagenew .fold8 .set a {
    font-size: 16px;
  }
}
.homepagenew .fold8 .set a i {
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 100%;
  transition: transform 0.2s ease-in-out;
}
@media (max-width: 992px) {
  .homepagenew .fold8 .set a i {
    right: 18px;
  }
}
.homepagenew .fold8 .set a .icon-minus:after {
  width: 20px;
  height: 20px;
  background-image: url("../images/minus.webp");
  background-repeat: no-repeat;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  content: "";
  text-align: center;
  transition: transform 0.2s ease-in-out;
  left: 50%;
}
.homepagenew .fold8 .set a .icon-plus:before {
  position: absolute;
  content: "";
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  left: 50%;
  background-image: url("../images/plus.webp");
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  transition: transform 0.2s ease-in-out;
}
.homepagenew .fold8 .set .faqs_content {
  background: var(--colour-monochrome-white, #FFF);
  padding: 20px;
  margin: -10px 0 0;
  border-radius: 0 0 12px 12px;
}
.homepagenew .fold8 .set .faqs_content div {
  color: #141415;
  font-size: 14px;
  font-weight: 400;
  line-height: 130%;
}
.homepagenew .fold8 .set .faqs_content div a {
  display: inline-block;
  padding: 0;
  margin: 0;
}
.homepagenew .fold8 .set .faqs_content div ul {
  padding: 10px 20px;
}
.homepagenew .fold8 .set .faqs_content div ul li {
  padding: 0 0 6px;
}
.homepagenew .fold8 .set .faqs_content div ul li a {
  display: inline-block;
  padding: 0;
}
.homepagenew .fold8 .set .faqs_content div ol {
  padding: 10px 20px;
}
.homepagenew .fold8 .set .faqs_content div ol li {
  padding: 0 0 6px;
}
.homepagenew .fold8 .set .faqs_content div ol li a {
  display: inline-block;
  padding: 0;
}
.homepagenew .fold9 {
  margin: -2px 0 0;
}
.homepagenew .fold9:before {
  position: absolute;
  content: "";
  background: #023342;
  left: 0;
  top: 0;
  height: 50%;
  width: 100%;
  z-index: -1;
}
@media (max-width: 992px) {
  .homepagenew .fold9:before {
    height: 33%;
  }
}
.homepagenew .fold9 .try_wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.homepagenew .fold9 .try_wrapper .big-arrow {
  position: relative;
  border-radius: 100%;
  left: -6px;
}
.homepagenew .fold9 .try_wrapper .big-arrow:hover {
  box-shadow: 4px 6px #000;
  transition: 0.4s;
  transform: rotate(-45deg);
}
@media (max-width: 992px) {
  .homepagenew .fold9 .try_wrapper .big-arrow {
    left: -2px;
    width: 60px;
  }
}
.homepagenew .fold9 .try_wrapper .ctabtn_big {
  font-size: 80px;
  font-weight: 800;
  line-height: 130%;
  text-transform: capitalize;
  border-radius: 500px;
  background: #0E8085;
  padding: 101.545px 83px 96.455px 83px;
}
@media (max-width: 992px) {
  .homepagenew .fold9 .try_wrapper .ctabtn_big {
    font-size: 18px;
    padding: 18px 50px 15px;
  }
}
.homepagenew .fold9 .try_wrapper .ctabtn_big span {
  color: #63FAFF;
}
.homepagenew .fold10 .testimonial1 {
  margin: 70px 0 0;
  border-radius: 12px;
  background: #DAF9DE;
  display: flex;
  align-items: flex-end;
  gap: 65px;
}
@media (max-width: 992px) {
  .homepagenew .fold10 .testimonial1 {
    gap: 40px;
    flex-direction: column-reverse;
    margin: 50px 0 0;
  }
}
.homepagenew .fold10 .testimonial1 .q1 {
  position: absolute;
  top: -40px;
  left: 20px;
}
.homepagenew .fold10 .testimonial1 .youngman {
  border-radius: 0 0 0 12px;
}
.homepagenew .fold10 .testimonial1 .quote1 {
  display: flex;
  width: 601px;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  padding: 40px 0;
}
@media (max-width: 992px) {
  .homepagenew .fold10 .testimonial1 .quote1 {
    width: 100%;
    gap: 20px;
    padding: 68px 20px 0;
  }
}
.homepagenew .fold10 .testimonial1 .quote1 p {
  color: #0D793D;
  font-size: 43px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 48px */
}
@media (max-width: 992px) {
  .homepagenew .fold10 .testimonial1 .quote1 p {
    font-size: 20px;
  }
}
.homepagenew .fold10 .testimonial1 .quote1 .q2 {
  position: absolute;
  right: 10px;
  bottom: 10px;
}
@media (max-width: 992px) {
  .homepagenew .fold10 .testimonial1 .quote1 .q2 {
    bottom: -12px;
  }
}
.homepagenew .fold10 .testimonial1 .quote1 .quoteFlex {
  padding: 40px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  align-self: stretch;
  border-top: 1px solid #A0DCA8;
}
@media (max-width: 992px) {
  .homepagenew .fold10 .testimonial1 .quote1 .quoteFlex {
    padding: 20px 0 0;
    flex-wrap: wrap;
    gap: 20px;
  }
}
.homepagenew .fold10 .testimonial1 .quote1 .quoteFlex .name {
  color: #122E37;
  font-size: 32px;
  font-weight: 600;
  line-height: 120%; /* 38.4px */
}
@media (max-width: 992px) {
  .homepagenew .fold10 .testimonial1 .quote1 .quoteFlex .name {
    font-size: 18px;
  }
}
.homepagenew .fold10 .testimonial1 .quote1 .quoteFlex .desig {
  color: #038584;
  font-size: 24px;
  font-weight: 400;
  line-height: 150%; /* 36px */
}
@media (max-width: 992px) {
  .homepagenew .fold10 .testimonial1 .quote1 .quoteFlex .desig {
    font-size: 14px;
  }
}
.homepagenew .fold10 .testimonial2 .quote2 {
  border-radius: 12px;
  padding: 24px 20px;
  gap: 20px;
}
.homepagenew .fold10 .testimonial2 .quote2 .moblogo {
  position: absolute;
  right: 20px;
  top: 10px;
  width: 52px;
}
.homepagenew .fold10 .testimonial2 .quote2 .quoteFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 992px) {
  .homepagenew .fold10 .testimonial2 .quote2 .quoteFlex {
    display: block;
  }
}
.homepagenew .fold10 .testimonial2 .quote2 .quoteFlex .quote_div {
  display: flex;
  gap: 18px;
  align-items: center;
}
.homepagenew .fold10 .testimonial2 .quote2 .quoteFlex .quote_div .client img {
  width: 78px;
  height: 78px;
  border-radius: 100%;
}
.homepagenew .fold10 .testimonial2 .quote2 .quoteFlex .quote_div .name {
  color: #023342;
  font-size: 20px;
  font-weight: 600;
  line-height: 130%;
}
@media (max-width: 992px) {
  .homepagenew .fold10 .testimonial2 .quote2 .quoteFlex .quote_div .name {
    font-size: 18px;
  }
}
.homepagenew .fold10 .testimonial2 .quote2 .quoteFlex .quote_div .desig {
  color: #333;
  font-size: 16px;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: 0.104px;
}
@media (max-width: 992px) {
  .homepagenew .fold10 .testimonial2 .quote2 .quoteFlex .quote_div .desig {
    font-size: 14px;
  }
}
.homepagenew .fold10 .testimonial2 .quote2 p {
  font-size: 23px;
  font-weight: 400;
  line-height: 150%;
  padding: 20px 0 0;
  min-height: 260px;
}
@media (max-width: 992px) {
  .homepagenew .fold10 .testimonial2 .quote2 p {
    font-size: 18px;
  }
}
.homepagenew .fold10 .testimonial2 .quote2 .textclr1 {
  color: #8A3B08;
}
.homepagenew .fold10 .testimonial2 .quote2 .textclr2 {
  color: #232D2D;
}
.homepagenew .fold10 .testimonial2 .clr1 {
  background: #FED6BB;
}
.homepagenew .fold10 .testimonial2 .clr2 {
  background: #E3CDFF;
}
.homepagenew .fold10 .padd1 {
  padding: 15px 8px 0 0;
}
@media (max-width: 992px) {
  .homepagenew .fold10 .padd1 {
    padding: 15px 0 0;
  }
}
.homepagenew .fold10 .padd2 {
  padding: 15px 0 0 8px;
}
@media (max-width: 992px) {
  .homepagenew .fold10 .padd2 {
    padding: 15px 0 0;
  }
}

.webinars .latest_fr {
  margin: 60px 0 0;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  height: 572px;
}
@media (max-width: 992px) {
  .webinars .latest_fr {
    flex-wrap: wrap;
    margin: 30px 0;
    height: unset;
    gap: 0;
  }
}
.webinars .latest_fr .latest_frContent {
  max-width: 600px;
  margin: 0 auto 0 40px;
}
@media (max-width: 992px) {
  .webinars .latest_fr .latest_frContent {
    max-width: 100%;
    padding: 20px;
    text-align: center;
    margin: 0;
  }
}
.webinars .latest_fr .latest_frContent .latest_caption {
  color: #5F4D07;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  background: #EBBD07;
  padding: 8px 20px;
  border-radius: 90px;
}
@media (max-width: 992px) {
  .webinars .latest_fr .latest_frContent .latest_caption {
    font-size: 14px;
    padding: 6px 15px;
  }
}
@media (max-width: 992px) {
  .webinars .latest_fr .latest_frContent .sec_title {
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.48px;
  }
}
.webinars .latest_fr .latest_frContent .sec_title .highlight {
  color: #ebbd07;
}
.webinars .latest_fr .latest_frContent .frshort_description {
  padding: 20px 0;
}
.webinars .latest_fr .latest_frImg {
  margin: 0;
}
.webinars .latest_fr .latest_frImg .fr_latestImg img {
  min-height: 494px;
  position: relative;
  top: 2.5px;
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
}
@media (max-width: 992px) {
  .webinars .latest_fr .latest_frImg .fr_latestImg img {
    border-top-right-radius: 0px;
    border-bottom-left-radius: 24px;
    min-height: unset;
    top: 6.5px;
  }
}
@media (max-width: 992px) {
  .webinars .latest_fr .latest_frImg {
    max-width: 100%;
  }
}
.webinars .blog_Title {
  color: #354545;
  font-weight: 600;
  font-size: 56px;
  line-height: 110%;
  letter-spacing: -0.01em;
  font-family: "Basier Circle medium";
}
@media (max-width: 992px) {
  .webinars .blog_Title {
    font-size: 24px;
    line-height: 32px;
  }
}
@media (max-width: 992px) {
  .webinars .blog_wrapper .sec-title {
    font-size: 28px;
    line-height: 32px;
  }
}
.webinars .webinar-box {
  padding: 0 10px 30px !important;
  height: unset;
}
@media (max-width: 992px) {
  .webinars .webinar-box {
    padding: 20px 0 !important;
  }
}
.webinars .webinar-box .webinar-title {
  font-size: 28px;
  line-height: 34px;
  color: #0e1b1b;
}
@media (max-width: 992px) {
  .webinars .webinar-box .webinar-title {
    font-size: 20px;
    line-height: 24px;
  }
}
.webinars .webinar-box .webitext {
  font-size: 20px;
  line-height: 28px;
  color: #0e1b1b;
}
@media (max-width: 992px) {
  .webinars .webinar-box .webitext {
    font-size: 16px;
    line-height: 24px;
  }
}
.webinars .webinar-box .webinar_Img iframe {
  border: none;
}

.webinar_content .profileImg img {
  width: 75px;
  height: 75px;
  border-radius: 100%;
}
.webinar_content .profile_desc {
  padding: 8px 0 8px 8px;
}
.webinar_content .profile_desc .speaker_name {
  font-size: 20px;
  color: #0e1b1b;
  line-height: 26px;
  letter-spacing: -0.2px;
  font-weight: 600;
  padding: 0 0 6px;
}
.webinar_content .profile_desc .speaker_bio {
  font-size: 16px;
  color: #374040;
  line-height: 22px;
}

.webinar_Img iframe {
  border-radius: 8px;
  height: 225px;
}
.webinar_Img .video-thumbnail .videoimg {
  height: 225px;
}

.webitext {
  padding: 20px 0;
}
@media (max-width: 992px) {
  .webitext {
    height: unset;
  }
}

.webinar-title {
  font-size: 22px;
  line-height: 28px;
  padding: 10px 0;
  height: 120px;
}

.blog_banner {
  margin: 70px 0 0;
}
@media (max-width: 992px) {
  .blog_banner {
    margin: 50px 0 0;
  }
}

.latest_blogWrapper {
  border-radius: 40px;
  background: #FAECDE;
  height: 422px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 26px;
}
@media (max-width: 992px) {
  .latest_blogWrapper {
    flex-wrap: wrap;
  }
}

.latest_caption {
  padding: 12px 30px;
  background-color: #f0dbc6;
  border-radius: 30px;
  display: inline-block;
  margin: 0 0 26px;
  color: #A58565;
  font-size: 16px;
  font-weight: 600;
  line-height: 125%;
  text-transform: uppercase;
}
@media (max-width: 992px) {
  .latest_caption {
    padding: 5px 10px;
    margin: 0 0 8px;
  }
}
.latest_caption img {
  vertical-align: text-bottom;
}

.blog_Title {
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  letter-spacing: -0.46px;
  margin-bottom: 26px;
}
@media (max-width: 992px) {
  .blog_Title {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 20px;
  }
}

.latest_blogContent {
  max-width: 600px;
  padding: 0 0 0 40px;
}
@media (max-width: 992px) {
  .latest_blogContent {
    padding: 0 0 20px;
  }
}
.latest_blogContent .short_description {
  height: 85px !important;
}
@media (max-width: 992px) {
  .latest_blogContent .short_description {
    height: unset !important;
  }
}

.short_description {
  padding: 14px 0;
}

.latest_blogImg {
  width: 540px;
  margin: 0 auto;
}

.blog_latestImg img {
  position: relative;
  transform: rotate(5.5deg);
  mix-blend-mode: darken;
  height: 287px;
}

.read_now {
  letter-spacing: -0.32px;
  font-weight: 600;
  display: inline-flex;
  transition-duration: 0.3s;
  transition-property: transform;
}
.read_now img {
  vertical-align: bottom;
}
.read_now:hover {
  transform: scale(0.9);
}

.see_more {
  color: #0d9298;
  font-weight: 600;
  float: right;
}
@media (max-width: 992px) {
  .see_more {
    float: left !important;
  }
}
.see_more img {
  vertical-align: bottom;
}

.blog-category {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  padding: 0 0 10px;
}

.blog-title {
  font-size: 22px;
  line-height: 28px;
}

.resources-title {
  font-size: 18px;
  line-height: 24px;
  padding: 10px 0;
  height: 65px;
}

.blog_heading {
  border-top: 1px solid #D7DDDD;
  padding: 40px 0 0;
}

.blog_wrapper {
  padding: 40px 0;
}
@media (max-width: 992px) {
  .blog_wrapper {
    padding: 0 0 40px;
  }
}

#recent_blogs .blog-box {
  padding: 0 50px 0 0;
}
@media (max-width: 992px) {
  #recent_blogs .blog-box {
    padding: 20px 0 40px;
  }
}
#recent_blogs .blog-title {
  height: 85px !important;
}
#recent_blogs .short_description {
  height: 90px;
}

#all_blogs .search_div {
  padding: 0 !important;
}
@media (max-width: 992px) {
  #all_blogs .search_div {
    padding: 0 !important;
  }
}
#all_blogs .search_div .search-container button {
  background: #fff;
  font-size: 0;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 2px;
  bottom: 6px;
}
#all_blogs .search_div .search-container input[type=text] {
  padding: 8px 6px;
  margin-top: 8px;
  font-size: 17px;
}
#all_blogs .blog-box {
  padding: 0 10px;
  height: 405px;
  transition-duration: 0.3s;
  transition-property: transform;
}
#all_blogs .blog-box:hover .blog_Img img {
  transform: translateY(-8px);
}
#all_blogs .blog-box .blog_Img img {
  height: 213px;
}
@media (max-width: 992px) {
  #all_blogs .blog-box .blog_Img img {
    height: 185px;
  }
}
@media (max-width: 992px) {
  #all_blogs .blog-box {
    padding: 20px 0;
    height: unset;
  }
}

.blog_Img img {
  border-radius: 8px;
  margin: 0 0 10px;
}

#free-resources {
  padding: 0 0 50px;
}
@media (max-width: 992px) {
  #free-resources {
    padding: 0;
  }
}

.resources-box {
  padding: 0 10px;
  transition-duration: 0.3s;
  transition-property: transform;
}
.resources-box:hover .resources_Img img {
  transform: scale(0.9);
}
@media (max-width: 992px) {
  .resources-box {
    padding: 15px 0;
  }
}

.csinner-box {
  padding: 0 20px 20px 0px;
  transition-duration: 0.3s;
  transition-property: transform;
}
.csinner-box:hover .cs_Img img {
  transform: scale(0.9);
}
@media (max-width: 992px) {
  .csinner-box {
    padding: 15px 0;
    height: unset;
  }
}
.csinner-box .cs_Img img {
  height: 206px;
}

.resources_Img img {
  border-radius: 8px;
}

.cs_Img img {
  border-radius: 8px;
}

.click_to_connect {
  background-color: #C2F6F6;
  padding: 46px;
  border-radius: 8px;
}
@media (max-width: 992px) {
  .click_to_connect {
    padding: 0;
  }
  .click_to_connect .sec-padd {
    padding: 20px;
  }
}
.click_to_connect .click-title {
  font-size: 40px;
  line-height: 50px;
  font-weight: 300;
  letter-spacing: -1.9px;
}
@media (max-width: 992px) {
  .click_to_connect .click-title {
    font-size: 22px;
    line-height: 32px;
  }
}
.click_to_connect .click-title span {
  color: green;
  font-weight: 600;
}

.cs_wrapper {
  padding: 20px 0;
}

.cs-category {
  color: #0d9298;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 10px 0;
}

.csinner_Title {
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  height: 100px;
}
@media (max-width: 992px) {
  .csinner_Title {
    height: unset;
    padding: 0 0 15px;
  }
}

.cs_Title {
  font-size: 28px;
  line-height: 38px;
  font-weight: 600;
}
@media (max-width: 992px) {
  .cs_Title {
    font-size: 20px;
    line-height: 28px;
  }
}

.CS_blogContent {
  padding: 40px 0 0 40px;
}
@media (max-width: 992px) {
  .CS_blogContent {
    padding: 0;
  }
}

.cs_author {
  font-size: 18px;
  line-height: 22px;
  color: #697777;
  font-weight: 500;
  text-transform: capitalize;
  padding: 8px 0 0;
}
.cs_author .userimg {
  width: 36px;
  height: 36px;
  border-radius: 100%;
  position: relative;
  top: 10px;
  left: -5px;
}

.girl {
  position: absolute;
  bottom: 0;
  right: 0;
}
@media (max-width: 992px) {
  .girl {
    position: relative;
  }
}

.category_slider {
  padding: 40px 0 20px;
}
@media (max-width: 992px) {
  .category_slider {
    display: flex;
    overflow-x: auto;
    width: 100%;
    white-space: nowrap;
    scrollbar-width: thin;
    padding: 30px 0;
  }
}

.category_slider li, .category_frslider li {
  list-style: none;
  display: inline-block;
}
.category_slider li a, .category_frslider li a {
  color: #fff;
  font-weight: 600;
  background-color: #0D9298;
  padding: 8px 12px;
  border-radius: 30px;
  margin: 0 3px;
  font-size: 14px;
  transition-duration: 0.3s;
}
.category_slider li a:hover, .category_frslider li a:hover {
  background-color: unset;
  color: #000;
}
.category_slider li a.active, .category_frslider li a.active {
  background-color: #fff;
  border: 1px solid #0d9298;
  color: #0d9298;
}
.category_slider li.slick-current.slick-active a, .category_frslider li.slick-current.slick-active a {
  background-color: #fff;
  border: 1px solid #0d9298;
  color: #0d9298;
}
.category_slider .slick-track, .category_frslider .slick-track {
  padding: 40px 0;
}

.see_all {
  color: #fff;
  background-color: #0D9298;
}

#blog-details .latest_blogContent {
  padding: 0;
}
@media (max-width: 992px) {
  #blog-details {
    margin: 30px 0 0;
  }
}
#blog-details .singlepost {
  background: #FAECDE;
}
@media (max-width: 992px) {
  #blog-details .singlepost {
    padding: 20px 10px;
  }
}
@media (max-width: 992px) {
  #blog-details .backblogs {
    border: 1px solid #E9EDED;
  }
}
#blog-details .backblogs .backtoblogs {
  color: #0d9298;
  font-weight: 600;
  padding: 30px 0;
  display: block;
}
@media (max-width: 992px) {
  #blog-details .backblogs .backtoblogs {
    padding: 15px 0;
  }
}
#blog-details .latest_blogWrapper {
  border-radius: 8px;
  background: #FAECDE;
  height: 422px;
}
@media (max-width: 992px) {
  #blog-details .latest_blogWrapper {
    height: unset;
    padding: 0;
    gap: 0;
  }
}
#blog-details .latest_blogWrapper .latest_blogContent .blog_Title {
  font-size: 40px;
  line-height: 50px;
}
@media (max-width: 992px) {
  #blog-details .latest_blogWrapper .latest_blogContent .blog_Title {
    font-size: 22px;
    line-height: 32px;
  }
}
#blog-details .latest_blogWrapper .publish_date {
  padding: 25px 0;
}
@media (max-width: 992px) {
  #blog-details .latest_blogWrapper .publish_date {
    padding: 0;
    font-size: 14px;
  }
}
#blog-details .latest_blogWrapper .publish_date span img {
  vertical-align: text-bottom;
}
#blog-details .latest_blogWrapper .publish_date span .userimg {
  width: 36px;
  height: 36px;
  border-radius: 100%;
  position: relative;
  top: 7px;
  left: -5px;
}
#blog-details .latest_blogWrapper .latest_blogImg {
  width: 562px;
  margin: 0 auto;
}
@media (max-width: 992px) {
  #blog-details .latest_blogWrapper .latest_blogImg .blog_Bigimage {
    padding: 20px 0 10px;
  }
}
#blog-details .latest_blogWrapper .latest_blogImg .blog_Bigimage img {
  mix-blend-mode: darken;
}
@media (max-width: 992px) {
  #blog-details .latest_blogWrapper .latest_blogImg .blog_Bigimage img {
    border-radius: 8px;
  }
}

.main_wrapper {
  border-top: 1px solid #D7DDDD;
}
.main_wrapper .detail_wrapper {
  display: flex;
}
@media (max-width: 992px) {
  .main_wrapper .detail_wrapper {
    display: block;
  }
}
.main_wrapper .navbar-fixed {
  position: fixed;
  top: 100px;
  width: 315px;
}
@media (max-width: 992px) {
  .main_wrapper .navbar-fixed {
    width: 100%;
    position: unset;
  }
}
.main_wrapper .csbar-fixed {
  position: fixed;
  top: 107px;
  width: 200px;
}
@media (max-width: 992px) {
  .main_wrapper .csbar-fixed {
    width: 100%;
    position: unset;
  }
}
.main_wrapper .demodiv-fixed {
  position: fixed;
  top: 120px;
  width: 210px;
}
@media (max-width: 992px) {
  .main_wrapper .demodiv-fixed {
    width: 100%;
    position: unset;
  }
}
.main_wrapper .csdemodiv-fixed {
  position: fixed;
  top: 107px;
  width: 315px;
}
@media (max-width: 992px) {
  .main_wrapper .csdemodiv-fixed {
    width: 100%;
    position: unset;
  }
}
.main_wrapper .stickySidebar {
  background-color: #fff;
  padding: 20px 8px;
  border-radius: 8px;
  box-shadow: 2px 2px 4px rgba(128, 128, 128, 0.2509803922);
}
@media (max-width: 992px) {
  .main_wrapper .stickySidebar {
    margin: 0 0 20px;
  }
}
.main_wrapper .stickySidebar .table_content {
  padding: 20px 4px 20px 20px;
}
.main_wrapper .stickySidebar .table_content li .sublist {
  padding: 5px 0 5px 14px;
}
.main_wrapper .stickySidebar .table_content li .sublist li {
  list-style-type: disc;
}
.main_wrapper .stickySidebar .table_content li .sublist li a {
  color: #000;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}
.main_wrapper .stickySidebar .table_content li .sublist li a.active {
  color: #60ACAC;
}
.main_wrapper .stickySidebar .table_content li a {
  color: #000;
  font-size: 16px;
  line-height: 28px;
  font-weight: 500;
}
.main_wrapper .stickySidebar .table_content li a.active {
  color: #60ACAC;
}
.main_wrapper .cs_stickySidebar {
  background-color: #fff;
  padding: 20px 15px;
  border-radius: 8px;
  box-shadow: 2px 2px 4px rgba(128, 128, 128, 0.2509803922);
  width: 200px;
}
@media (max-width: 992px) {
  .main_wrapper .cs_stickySidebar {
    margin: 0 0 20px;
    width: 100%;
  }
}
.main_wrapper .cs_stickySidebar li {
  list-style: none;
}
.main_wrapper .cs_stickySidebar li a {
  color: #000;
  font-size: 16px;
  line-height: 28px;
  font-weight: 500;
}
.main_wrapper .cs_stickySidebar li a.active {
  color: #60ACAC;
}
.main_wrapper .blogdetails_wrapper {
  padding: 0 35px;
  font-size: 18px;
  line-height: 26px;
  text-align: justify;
}
@media (max-width: 992px) {
  .main_wrapper .blogdetails_wrapper {
    padding: 0;
  }
}
.main_wrapper .blogdetails_wrapper a {
  color: #0d9298;
  text-align: justify;
}
.main_wrapper .blogdetails_wrapper h2 {
  font-size: 30px !important;
  line-height: 38px !important;
  padding: 24px 0;
  text-transform: capitalize;
  letter-spacing: -0.7px;
  text-align: left;
}
@media (max-width: 992px) {
  .main_wrapper .blogdetails_wrapper h2 {
    font-size: 22px !important;
    line-height: 32px !important;
    padding: 10px 0 15px;
  }
}
.main_wrapper .blogdetails_wrapper h3 {
  font-size: 26px !important;
  line-height: 34px !important;
  padding: 18px 0;
  text-transform: capitalize;
  letter-spacing: -0.56px;
  text-align: left;
}
@media (max-width: 992px) {
  .main_wrapper .blogdetails_wrapper h3 {
    font-size: 20px !important;
    line-height: 30px !important;
    padding: 10px 0 15px;
  }
}
.main_wrapper .blogdetails_wrapper h4 {
  font-size: 20px !important;
  line-height: 30px !important;
  padding: 18px 0;
  text-transform: capitalize;
  text-align: left;
  letter-spacing: -0.56px;
}
@media (max-width: 992px) {
  .main_wrapper .blogdetails_wrapper h4 {
    font-size: 18px !important;
    line-height: 28px !important;
    padding: 10px 0 15px;
  }
}
.main_wrapper .blogdetails_wrapper p {
  font-size: 18px;
  line-height: 26px;
  padding: 10px 0;
  text-align: justify;
}
@media (max-width: 992px) {
  .main_wrapper .blogdetails_wrapper p {
    padding: 0 0 10px;
  }
}
.main_wrapper .blogdetails_wrapper .quotes {
  background-color: #fff;
  box-shadow: 1px 3px 8px 2px rgba(128, 128, 128, 0.1607843137);
  padding: 20px;
  margin: 10px 0 20px;
  text-align: justify;
}
.main_wrapper .blogdetails_wrapper blockquote {
  background-color: #fff;
  box-shadow: 1px 3px 8px 2px rgba(128, 128, 128, 0.1607843137);
  padding: 20px;
  margin: 10px 0 20px;
  text-align: justify;
}
.main_wrapper .blogdetails_wrapper img {
  margin: 15px 0;
  border-radius: 8px;
  width: 100%;
  box-shadow: 1px 1px 1px 8px rgba(228, 228, 228, 0.6117647059);
}
.main_wrapper .blogdetails_wrapper iframe {
  margin: 25px 0 15px;
  border-radius: 8px;
  display: block;
  width: 100%;
  box-shadow: 1px 1px 1px 8px hsla(0, 0%, 89%, 0.6117647059);
  height: 400px;
}
@media (max-width: 992px) {
  .main_wrapper .blogdetails_wrapper iframe {
    height: 280px;
  }
}
.main_wrapper .blogdetails_wrapper ul {
  padding-left: 20px;
}
.main_wrapper .blogdetails_wrapper ul li {
  font-size: 18px;
  line-height: 26px;
  text-align: justify;
  padding: 8px 0;
}
.main_wrapper .blogdetails_wrapper ul li img {
  margin: 30px 0 20px;
}
.main_wrapper .blogdetails_wrapper ul li ul {
  padding: 5px 0 0 15px;
}
.main_wrapper .blogdetails_wrapper ul li ul li {
  font-size: 18px;
  line-height: 26px;
  list-style-type: disc;
  font-weight: 500;
  text-align: justify;
}
.main_wrapper .blogdetails_wrapper ol {
  padding-left: 20px;
}
.main_wrapper .blogdetails_wrapper ol li {
  font-size: 18px;
  line-height: 26px;
  text-align: justify;
  padding: 8px 0;
}
.main_wrapper .blogdetails_wrapper ol li img {
  margin: 30px 0 20px;
}
.main_wrapper .blogdetails_wrapper ol li ul {
  padding: 5px 0 0 15px;
}
.main_wrapper .blogdetails_wrapper ol li ul li {
  font-size: 18px;
  line-height: 26px;
  list-style-type: disc;
  font-weight: 500;
  text-align: justify;
}
.main_wrapper .blogdetails_wrapper .author_wrapper {
  background-color: #F7F8F8;
  padding: 20px;
  border-radius: 8px;
  margin: 20px 0;
}
@media (max-width: 992px) {
  .main_wrapper .blogdetails_wrapper .author_wrapper {
    margin: 20px 0 40px;
  }
}
.main_wrapper .blogdetails_wrapper .author_wrapper .author-bio img {
  width: 150px;
  height: 100%;
  border-radius: 100%;
}
@media (max-width: 992px) {
  .main_wrapper .blogdetails_wrapper .author_wrapper .author-bio img {
    width: 90px;
  }
}
.main_wrapper .blogdetails_wrapper .author_wrapper .author_content {
  padding: 20px;
}
@media (max-width: 992px) {
  .main_wrapper .blogdetails_wrapper .author_wrapper .author_content {
    padding: 0;
  }
}
.main_wrapper .blogdetails_wrapper .author_wrapper .author_content .user_name {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  padding: 0 0 10px;
}
.main_wrapper .rightSidebar {
  padding: 20px 0 0;
}
.main_wrapper .rightSidebar .social_links {
  padding: 20px 0 0;
}
.main_wrapper .rightSidebar .social_links a img {
  width: 25px;
}
.main_wrapper .rightSidebar .demo_div {
  background-color: #F4F3FF;
  border-radius: 12px;
  padding: 20px 15px;
  margin: 30px 0 0;
}
.main_wrapper .rightSidebar .demo_div .demo_title {
  font-size: 19px;
  line-height: 28px;
  font-weight: 600;
}
@media (max-width: 992px) {
  .main_wrapper .rightSidebar .demo_div .demo_title {
    font-size: 22px;
    line-height: 32px;
  }
}
.main_wrapper .rightSidebar .demo_div .demo_title span {
  color: blue;
}
.main_wrapper .rightSidebar .demo_div p {
  font-size: 14px;
  line-height: 20px;
  padding: 10px 0;
}
.main_wrapper .rightSidebar .demo_div .theme-btn, .main_wrapper .rightSidebar .demo_div .white-btn {
  display: block;
  padding: 14px 18px;
  margin: 0 0 10px;
  font-size: 14px !important;
}
.main_wrapper .cs_rightSidebar {
  padding: 20px 0 0;
}
.main_wrapper .cs_rightSidebar .social_links {
  padding: 20px 0 0;
}
.main_wrapper .cs_rightSidebar .social_links a img {
  width: 25px;
}
.main_wrapper .cs_rightSidebar .demo_div {
  background-color: #F4F3FF;
  border-radius: 12px;
  padding: 20px 15px;
  margin: 30px 0 0;
}
.main_wrapper .cs_rightSidebar .demo_div .demo_title {
  font-size: 19px;
  line-height: 28px;
  font-weight: 600;
}
@media (max-width: 992px) {
  .main_wrapper .cs_rightSidebar .demo_div .demo_title {
    font-size: 22px;
    line-height: 32px;
  }
}
.main_wrapper .cs_rightSidebar .demo_div .demo_title span {
  color: blue;
}
.main_wrapper .cs_rightSidebar .demo_div p {
  font-size: 14px;
  line-height: 20px;
  padding: 10px 0;
}
.main_wrapper .cs_rightSidebar .demo_div .theme-btn, .main_wrapper .cs_rightSidebar .demo_div .white-btn {
  display: block;
  margin: 0 0 10px;
}

#recentBlogs .blogBox .blog-box-wrapper .blog-box {
  padding: 0 15px;
}
@media (max-width: 992px) {
  #recentBlogs .blogBox .blog-box-wrapper .blog-box {
    padding: 15px 0;
  }
}
#recentBlogs .blogBox .blog-box-wrapper .blog-box .blog_Img img {
  height: 213px;
}

#subscribe .subscribe_wrapper {
  padding: 48px 50px;
  height: 390px;
  border-radius: 8px;
}
#subscribe .subscribe_wrapper .sec-padd {
  max-width: 500px;
}
@media (max-width: 992px) {
  #subscribe .subscribe_wrapper {
    padding: 0;
    height: unset;
  }
  #subscribe .subscribe_wrapper .sec-title {
    font-size: 24px;
    line-height: 34px;
  }
  #subscribe .subscribe_wrapper .sec-padd {
    padding: 20px;
  }
}
#subscribe .subscribe_wrapper .subscribe {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 390px;
}
@media (max-width: 992px) {
  #subscribe .subscribe_wrapper .subscribe {
    position: relative;
    height: unset;
  }
}

#lastsection {
  background-color: #0D9298;
  height: 600px;
}
@media (max-width: 992px) {
  #lastsection .sheet {
    width: 98px;
  }
}
#lastsection .tagline_wraper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
@media (max-width: 992px) {
  #lastsection .tagline_wraper {
    position: relative;
    top: unset;
    transform: unset;
    left: unset;
  }
}
#lastsection .tagline_wraper .sec-title {
  font-size: 60px !important;
  line-height: 70px !important;
  letter-spacing: -1.2px;
  padding-bottom: 20px;
}
@media (max-width: 992px) {
  #lastsection .tagline_wraper .sec-title {
    font-size: 24px !important;
    line-height: 34px !important;
  }
}
#lastsection .tagline_wraper .free-trial {
  font-size: 20px;
  margin: 20px 0 50px;
}
@media (max-width: 992px) {
  #lastsection .tagline_wraper .free-trial {
    font-size: 14px;
    line-height: 20px;
    margin: 0px 0 35px;
  }
}
#lastsection .tagline_wraper .theme-btn {
  background-color: #fff;
  color: #0d9298;
}

.pagination-links {
  text-align: center;
  margin: 20px 0;
}
.pagination-links a {
  margin: 0 5px;
  padding: 10px 15px;
  border: 1px solid #ddd;
  text-decoration: none;
  background-color: #f4f4f4;
  color: #0d9298;
  transition: background-color 0.3s ease, color 0.3s ease;
}
.pagination-links a:hover {
  background-color: #0d9298;
  color: #fff;
  border-color: #0d9298;
}
.pagination-links .current {
  font-weight: bold;
  pointer-events: none;
  background-color: #0d9298;
  color: #fff;
  border-color: #0d9298;
}
.pagination-links .page-number {
  cursor: pointer;
}
.pagination-links .prev_pagination, .pagination-links .next_pagination, .pagination-links .last-page, .pagination-links .first-page {
  background-color: rgba(235, 235, 235, 0.6588235294);
  padding: 11px;
  border-radius: 4px;
}

.cs_flex {
  display: flex;
  gap: 40px;
}
@media (max-width: 992px) {
  .cs_flex {
    display: block;
  }
}

.single_cs .cs_left {
  padding-left: 40px;
}
@media (max-width: 992px) {
  .single_cs .cs_left {
    padding-left: 0;
  }
}
.single_cs .cs_left .case_Content {
  font-size: 18px;
  line-height: 26px;
  padding: 10px 0;
  color: #1e1e1e;
  font-family: "Basier Circle";
}
.single_cs .cs_left .case_Content a {
  color: #33c1c0;
  text-decoration: underline;
}
.single_cs .cs_left .case_Content h2 {
  font-size: 30px;
  line-height: 38px;
  padding: 24px 0;
  color: #0e1b1b;
  text-transform: capitalize;
  letter-spacing: -0.7px;
  font-family: "Basier Circle Semibold";
}
@media (max-width: 992px) {
  .single_cs .cs_left .case_Content h2 {
    font-size: 22px;
    line-height: 32px;
    padding: 18px 0;
  }
}
.single_cs .cs_left .case_Content h3 {
  font-size: 26px;
  line-height: 34px;
  margin: 14px 0;
}
@media (max-width: 992px) {
  .single_cs .cs_left .case_Content h3 {
    font-size: 20px;
    line-height: 30px;
  }
}
.single_cs .cs_left .case_Content p {
  font-size: 18px;
  line-height: 26px;
  padding: 10px 0;
  color: #1e1e1e;
  font-family: "Basier Circle";
}
.single_cs .cs_left .case_Content p a {
  color: #33c1c0;
  text-decoration: underline;
}
.single_cs .cs_left .case_Content iframe {
  margin: 25px 0 15px;
  border-radius: 8px;
  display: block;
  width: 100%;
  box-shadow: 1px 1px 1px 8px hsla(0, 0%, 89%, 0.6117647059);
  height: 400px;
}
@media (max-width: 992px) {
  .single_cs .cs_left .case_Content iframe {
    height: 280px;
  }
}
.single_cs .cs_left .case_Content ul {
  padding-left: 20px;
}
.single_cs .cs_left .case_Content ul li {
  font-size: 18px;
  line-height: 26px;
  text-align: justify;
  padding: 8px 0;
}
.single_cs .cs_left .case_Content ul li img {
  margin: 30px 0 20px;
}
.single_cs .cs_left .case_Content ul li ul {
  padding: 5px 0 0 15px;
}
.single_cs .cs_left .case_Content ul li ul li {
  font-size: 18px;
  line-height: 26px;
  list-style-type: disc;
  font-weight: 500;
  text-align: justify;
}
.single_cs .cs_left .case_Content ol {
  padding-left: 20px;
}
.single_cs .cs_left .case_Content ol li {
  font-size: 18px;
  line-height: 26px;
  text-align: justify;
  padding: 8px 0;
}
.single_cs .cs_left .case_Content ol li img {
  margin: 30px 0 20px;
}
.single_cs .cs_left .case_Content ol li ul {
  padding: 5px 0 0 15px;
}
.single_cs .cs_left .case_Content ol li ul li {
  font-size: 18px;
  line-height: 26px;
  list-style-type: disc;
  font-weight: 500;
  text-align: justify;
}
.single_cs .cs_left .case_Content img {
  margin: 25px 0 15px;
  border-radius: 8px;
  width: 100%;
  box-shadow: 1px 1px 1px 8px hsla(0, 0%, 89%, 0.61176);
}
.single_cs .cs_left .case_Content blockquote {
  background: #f9f9f9;
  border-left: 10px solid #ccc;
  margin: 1.5em 0;
  padding: 15px;
  quotes: "“" "”" "‘" "’";
}
.single_cs .cs_left .arrow-left {
  margin: 0;
  border-radius: 0;
  vertical-align: bottom;
}
.single_cs .caseTitle {
  margin: 8px 0;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  letter-spacing: -0.46px;
  margin-bottom: 26px;
  color: #0e1b1b;
  font-family: "Basier Circle Semibold";
}
@media (max-width: 992px) {
  .single_cs .caseTitle {
    font-size: 24px;
    line-height: 34px;
  }
}
.single_cs .cs_category, .single_cs .cs_author {
  font-size: 12px;
  color: #038c8b;
  line-height: 18px;
  margin-bottom: 20px;
}
.single_cs iframe {
  height: 400px;
  border-radius: 12px;
  margin: 15px 0;
}
@media (max-width: 992px) {
  .single_cs iframe {
    height: 190px;
  }
}

.terms p {
  text-align: justify;
}
.terms ol {
  padding: 10px;
}
.terms ol li {
  color: #374040;
  font-size: 16px;
  line-height: 24px;
  padding: 8px 0;
  text-align: justify;
}
.terms ol li ol {
  padding: 8px 16px;
}
.terms ol p {
  padding: 10px 0;
  text-align: justify;
}

.contact_wrapper {
  padding: 20px 0 0;
}
.contact_wrapper .info_title {
  font-size: 24px;
  line-height: 31px;
  color: #354545;
  margin: 20px 0;
}
.contact_wrapper .info_div {
  margin: 20px 0;
  padding: 20px 0;
}
.contact_wrapper .info_div::before {
  position: absolute;
  content: "";
  background-color: #dee2e6;
  width: 60%;
  left: 0;
  top: 0;
  height: 1px;
}
@media (max-width: 992px) {
  .contact_wrapper .info_div::before {
    width: 100%;
  }
}
.contact_wrapper .info_div::after {
  position: absolute;
  content: "";
  background-color: #dee2e6;
  width: 60%;
  left: 0;
  bottom: 0;
  height: 1px;
}
@media (max-width: 992px) {
  .contact_wrapper .info_div::after {
    width: 100%;
  }
}
.contact_wrapper .info_div h3 {
  font-size: 18px;
  line-height: 23px;
  color: #354545;
}
.contact_wrapper .info_div p {
  font-size: 14px;
  line-height: 20px;
  color: #354545;
  padding: 10px 0 0;
}
.contact_wrapper .info_div p a {
  font-size: 14px;
  line-height: 20px;
  color: #354545;
}
.contact_wrapper .contactus .form-control {
  background-color: #f5f8fa;
  border: 1px solid #cbd6e2;
  border-radius: 3px;
}
.contact_wrapper .contactus .fname {
  padding-right: 20px;
}
@media (max-width: 992px) {
  .contact_wrapper .contactus .fname {
    padding-right: 0;
  }
}

.display_table {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}
@media (max-width: 992px) {
  .display_table {
    display: block;
  }
}

@media (max-width: 992px) {
  .products .btn_wrapper a {
    display: block;
  }
}
.products .banner {
  height: 100vh;
  padding: 0 175px;
}
@media (max-width: 992px) {
  .products .banner {
    height: unset;
    padding: 90px 0 0;
  }
}
.products .common_subtitle {
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 17px;
  font-family: "Basier Circle";
}
.products .common_title {
  font-size: 36px;
  line-height: 43px;
}
@media (max-width: 992px) {
  .products .common_title {
    font-size: 24px;
    line-height: 34px;
  }
}
.products .common_desc {
  font-size: 16px;
  line-height: 24px;
  margin: 25px 0 60px;
  font-weight: 400;
  font-family: "Basier Circle";
}
@media (max-width: 992px) {
  .products .common_desc {
    margin: 25px 0;
  }
}
.products .feed_div {
  height: 350px;
}
@media (max-width: 992px) {
  .products .feed_div {
    height: unset;
    padding: 20px 0;
  }
}
.products .feed_div img {
  height: 180px;
}
.products .feed_div .feedback_title {
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  padding: 8px 0 0;
}
.products .feed_div1 {
  height: 420px;
}
@media (max-width: 992px) {
  .products .feed_div1 {
    height: unset;
    padding: 20px 0;
  }
}
.products .feedback-card-root .feedback-card {
  width: 100%;
  max-width: 410px;
  padding: 15px 24px;
  position: relative;
  border-radius: 12px;
  margin: 10px auto 20px;
  background: #dce8ec;
}
.products .feedback-card-root .feedback-card .feedback-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.products .feedback-card-root .feedback-card .feedback-head .card-logo-div {
  width: 104px;
  height: 104px;
  border-radius: 50%;
}
.products .feedback-card-root .feedback-card .feedback-head .card-logo-div .card-logo-image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: contain;
  background: #fff;
}
.products .feedback-card-root .feedback-card .feedback-head h4 {
  font-size: 14px;
  color: #354545;
  line-height: 18px;
  max-width: 208px;
  padding-left: 10px;
  font-family: "Basier Circle";
}
.products .feedback-card-root .feedback-card .arrow-div .feedback-test-text {
  margin: 0;
  top: 230px;
  right: -210px;
  position: absolute;
  font-size: 14px;
  line-height: 18px;
  color: #354545;
  text-align: center;
  max-width: 186px;
  font-family: "Basier Circle";
}
.products .feedback-card-root .feedback-card .arrow-div .feedback-test-img {
  top: 260px;
  right: -140px;
  z-index: 1;
  position: absolute;
}
@media (max-width: 992px) {
  .products .feedback-card-root .feedback-card .arrow-div .feedback-test-img {
    right: -65px;
  }
}
.products .feedback-card-root .feedback-card .feedback-details {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: justify;
  justify-content: space-between;
  margin: 17px 0 18px;
}
.products .feedback-card-root .feedback-card .feedback-details h5 {
  margin: 0;
  color: #354545;
  font-size: 10px;
  line-height: 13px;
}
.products .feedback-card-root .feedback-card .feedback-details h5 span {
  font-family: "Basier Circle";
}
.products .feedback-card-root .feedback-card .bonus-card {
  padding: 5px 8px;
  margin-bottom: 16px;
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: center;
  justify-content: center;
  border-radius: 12px;
  background: #fff;
}
.products .feedback-card-root .feedback-card .bonus-card h3 {
  font-size: 12px;
  color: #354545;
  margin: 0 0 0 5px;
  line-height: 16px;
}
.products .feedback-card-root .feedback-card .rating-card {
  min-width: 430px;
  padding: 18px 24px;
  border-radius: 12px;
  background: #fff;
  -webkit-transform: translateX(-35px);
  -moz-transform: translateX(-35px);
  transform: translateX(-35px);
  -webkit-box-shadow: 2px 3px 10px #c5d1d5;
  box-shadow: 2px 3px 10px #c5d1d5;
}
@media (max-width: 992px) {
  .products .feedback-card-root .feedback-card .rating-card {
    min-width: 100%;
  }
}
.products .feedback-card-root .feedback-card .rating-card h4 {
  font-size: 14px;
  color: #354545;
  line-height: 18px;
  margin-bottom: 25px;
  font-family: "Basier Circle";
}
.products .feedback-card-root .feedback-card .rating-card .rating-row {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: justify;
  justify-content: space-between;
}
.products .feedback-card-root .feedback-card .rating-card .rating-row .emojis {
  cursor: pointer;
  -webkit-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.18, 1.3) 0s;
  -moz-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.18, 1.3) 0s;
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.18, 1.3) 0s;
}
.products .feedback-card-root .feedback-card .rating-card .rating-row .emojis:hover {
  transform: scale(1.4);
}

.campaign_padd {
  gap: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.campaign_padd .campaign_content {
  padding: 90px 0 0 90px;
}
@media (max-width: 992px) {
  .campaign_padd .campaign_content {
    padding: 20px 0;
  }
}
.campaign_padd .tr {
  text-align: right;
}
@media (max-width: 992px) {
  .campaign_padd .tr {
    text-align: center;
  }
}
.campaign_padd .campaign_div {
  width: 250px;
  padding: 35px 24px;
  border-radius: 10px;
}
@media (max-width: 992px) {
  .campaign_padd .campaign_div {
    width: 100%;
  }
}
.campaign_padd .campaign_div .campaign_title {
  font-size: 18px;
  line-height: 28px;
  margin: 20px 0 0;
  font-family: "Basier Circle";
  font-weight: 500;
}
.campaign_padd .campaign1 {
  background-color: #E6F5F0;
}
.campaign_padd .campaign2 {
  background-color: #FFF9D9;
}
.campaign_padd .campaign3 {
  background-color: #EAF0FF;
}
.campaign_padd .campaign4 {
  background-color: #EBF9FF;
}
@media (max-width: 992px) {
  .campaign_padd {
    padding: 0;
    text-align: center;
  }
}

.more-reelo {
  background: linear-gradient(180deg, #f8fdfd, rgba(248, 249, 255, 0));
}
.more-reelo .morereelo_div {
  padding: 40px 20px 0;
  text-align: center;
}
@media (max-width: 992px) {
  .more-reelo .morereelo_div {
    padding: 30px 0 0;
  }
}
.more-reelo .morereelo_div h3 {
  font-size: 32px;
  color: #092d2c;
  line-height: 42px;
  margin: 24px 0 8px;
  letter-spacing: -0.02em;
  font-family: "Basier Circle Semibold";
}
@media (max-width: 992px) {
  .more-reelo .morereelo_div h3 {
    font-size: 24px;
    line-height: 34px;
  }
}
.more-reelo .morereelo_div p {
  font-size: 17px;
  color: #404444;
  margin: 0 0 16px;
  line-height: 26px;
  font-family: "Basier Circle";
  height: 105px;
}
@media (max-width: 992px) {
  .more-reelo .morereelo_div p {
    height: 110px;
  }
}
.more-reelo .morereelo_div .read_now {
  font-size: 20px;
  color: #0d9298;
  line-height: 28px;
  font-family: "Basier Circle medium";
  font-weight: 400;
}

.slick-dots {
  display: flex;
  margin: 0;
  list-style-type: none;
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
}
.slick-dots button {
  display: block;
  width: 6px;
  height: 6px;
  padding: 0;
  background-color: grey;
  text-indent: -9999px;
  cursor: pointer;
  border-radius: 30px;
  border: unset;
  margin: 0 3px;
}

li.slick-active button {
  background-color: #000;
}

.loyalty .loyalty_first_fold {
  gap: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 45px 0 0;
}
@media (max-width: 992px) {
  .loyalty .loyalty_first_fold {
    flex-wrap: wrap;
  }
}
.loyalty .loyalty_first_fold .loyalty_left_side {
  max-width: 620px;
}
.loyalty .loyalty_first_fold .loyalty_right_side {
  width: 100%;
  max-width: 571px;
}
.loyalty .display_table {
  flex-direction: unset;
}
.loyalty .banner {
  height: 100vh;
}
@media (max-width: 992px) {
  .loyalty .banner {
    height: unset;
    padding: 90px 0 0;
    text-align: center;
  }
  .loyalty .banner .common_title {
    font-size: 28px;
    line-height: 32px;
  }
  .loyalty .banner .common_desc {
    font-size: 14px;
    line-height: 22px;
  }
}
.loyalty .sec_title {
  font-size: 42px;
  margin: 0 0 16px;
  line-height: 51px;
  letter-spacing: -0.02em;
  font-weight: 500;
  font-family: "Basier Circle medium";
}
@media (max-width: 992px) {
  .loyalty .sec_title {
    font-size: 28px;
    line-height: 32px;
  }
}
.loyalty .sec_desc {
  font-size: 20px;
  margin: 0 0 24px;
  line-height: 30px;
  font-family: "Basier Circle";
}
.loyalty .common_title {
  font-size: 56px;
  margin: 0 0 16px;
  line-height: 62px;
  letter-spacing: -0.01em;
  font-family: "Basier Circle medium";
}
.loyalty .common_desc {
  font-size: 20px;
  margin: 0 0 40px;
  line-height: 30px;
  font-family: "Basier Circle";
}
.loyalty .loyaly_div {
  overflow: hidden;
  border-radius: 24px;
  background: #fef6ee;
  padding: 40px 0 0;
}
@media (max-width: 992px) {
  .loyalty .loyaly_div {
    padding: 20px 0 0;
  }
}
.loyalty .loyaly_div .loyalty_img_parent {
  width: 100%;
  height: 100%;
  position: relative;
}
.loyalty .loyaly_div .loyalty_img_parent .loyaly_slider {
  bottom: 0;
  top: auto;
  width: 100%;
  height: 523px;
}
@media (max-width: 992px) {
  .loyalty .loyaly_div .loyalty_img_parent .loyaly_slider {
    height: 332px;
  }
}
.loyalty .loyaly_div .loyalty_img_parent .loyaly_slider .slider_loyaltyimg {
  top: auto;
  bottom: 0;
  margin: 0 auto !important;
  display: block !important;
  width: calc(100% - 96px) !important;
}
@media (max-width: 992px) {
  .loyalty .loyaly_div .loyalty_img_parent .loyaly_slider .slider_loyaltyimg {
    width: calc(100% - 34px) !important;
  }
}
@media (max-width: 992px) {
  .loyalty .loyaly_div {
    width: 100%;
    margin: 0;
  }
}
.loyalty .loyalty_tabs_wrapper {
  border-bottom: 1px solid rgba(238, 238, 238, 0.9333333333);
  padding: 60px 80px 0;
}
.loyalty .loyalty_tabs_wrapper .loyalty_tab {
  display: inline-block;
  width: 240px;
  height: 140px;
  cursor: pointer;
}
.loyalty .loyalty_tabs_wrapper .loyalty_tab:before {
  position: absolute;
  content: "";
  width: 95%;
  height: 4px;
  bottom: 0;
  background-color: #eee;
  transform: translateX(-50%);
  left: 50%;
}
.loyalty .loyalty_tabs_wrapper .loyalty_tab span {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: #f3f4f6;
  font-size: 24px;
  font-family: "Basier Circle Semibold";
  padding: 10px 19px;
}
.loyalty .loyalty_tabs_wrapper .loyalty_tab p {
  font-size: 18px;
  color: #959595;
  line-height: 26px;
  text-align: center;
  font-family: "Basier Circle medium";
  padding: 30px 0 0;
}
.loyalty .loyalty_tabs_wrapper .loyalty_tab.active:before {
  background-color: #6172f3;
}
.loyalty .loyalty_tabs_wrapper .loyalty_tab.active p {
  color: #182020;
}
.loyalty .loyalty_slider2 .slick-dots {
  display: flex;
  margin: 0;
  list-style-type: none;
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
}
.loyalty .loyalty_slider2 .slick-dots button {
  display: block;
  width: 6px;
  height: 6px;
  padding: 0;
  background-color: grey;
  text-indent: -9999px;
  cursor: pointer;
  border-radius: 30px;
  border: unset;
  margin: 0 3px;
}
.loyalty .loyalty_slider2 li.slick-active button {
  background-color: #000;
}
.loyalty .loyalty_slider2 .loyalty_Slide {
  padding: 64px 0 64px 64px;
  background: #f5f8ff;
  margin: 40px auto 0;
  border-radius: 24px;
}
@media (max-width: 992px) {
  .loyalty .loyalty_slider2 .loyalty_Slide {
    padding: 20px;
    height: unset;
    margin: 0 0 50px;
  }
}
@media (max-width: 992px) {
  .loyalty .loyalty_slider2 .loyalty_Slide .loyalty_img img {
    width: 200px;
    margin: 0 auto;
  }
}
.loyalty .loyalty_slider2 .loyalty_Slide .loyalty_content {
  padding: 0 0 30px;
}
.loyalty .loyalty_slider2 .loyalty_Slide .loyalty_content span {
  width: 64px;
  height: 64px;
  font-size: 34px;
  color: #fff;
  line-height: 42px;
  border-radius: 50%;
  display: -moz-box;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #6172f3;
  font-family: "Basier Circle Semibold";
}
@media (max-width: 992px) {
  .loyalty .loyalty_slider2 .loyalty_Slide .loyalty_content span {
    width: 34px;
    height: 34px;
    font-size: 20px;
    line-height: 28px;
  }
}
.loyalty .loyalty_slider2 .loyalty_Slide .loyalty_content h3 {
  font-size: 36px;
  color: #171e59;
  line-height: 50px;
  margin: 32px 0 12px;
  letter-spacing: -0.02em;
  font-family: "Basier Circle Semibold";
}
@media (max-width: 992px) {
  .loyalty .loyalty_slider2 .loyalty_Slide .loyalty_content h3 {
    font-size: 28px;
    line-height: 32px;
  }
}
.loyalty .loyalty_slider2 .loyalty_Slide .loyalty_content p {
  font-size: 28px;
  color: #171e59;
  line-height: 40px;
  letter-spacing: -0.02em;
  font-family: "Basier Circle medium";
}
@media (max-width: 992px) {
  .loyalty .loyalty_slider2 .loyalty_Slide .loyalty_content p {
    font-size: 16px;
    line-height: 26px;
  }
}
.loyalty .benifits_section .bene_div {
  padding: 32px 30px;
  border-radius: 16px;
  width: 31%;
  margin: 40px 14px 0;
}
@media (max-width: 992px) {
  .loyalty .benifits_section .bene_div {
    width: 100%;
    margin: 30px 0 0;
  }
}
.loyalty .benifits_section .bene_div .bene_inner {
  padding: 36px 22px;
  background: #fff;
  border-radius: 16.7273px;
}
.loyalty .benifits_section .bene_div .bene_inner p {
  font-size: 20px;
  color: #171e59;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.02em;
  font-family: "Basier Circle medium";
}
.loyalty .benifits_section .bene_div .bene_inner span {
  font-size: 48px;
  line-height: 62px;
  text-align: center;
  letter-spacing: -0.02em;
  font-family: "Basier Circle Semibold";
  display: block;
}
.loyalty .benifits_section .bene_div .bene_inner .green {
  color: #16b364;
}
.loyalty .benifits_section .bene_div .bene_inner .red {
  color: #ef6820;
}
.loyalty .benifits_section .bene_div .bene_inner .blue {
  color: #1570ef;
}
.loyalty .benifits_section .b1 {
  background: #edfcf2;
}
.loyalty .benifits_section .b2 {
  background: #fef6ee;
}
.loyalty .benifits_section .b3 {
  background: #f5f8ff;
}
.loyalty .inspiration {
  border-radius: 16px;
  padding: 48px 40px 0;
  background: #fef6ee;
}
@media (max-width: 992px) {
  .loyalty .inspiration {
    padding: 40px 20px 0;
  }
}
.loyalty .quotes_section p {
  font-size: 24px;
  color: #092d2c;
  line-height: 32px;
  text-align: center;
  font-family: "Basier Circle";
}
@media (max-width: 992px) {
  .loyalty .quotes_section p {
    font-size: 18px;
    line-height: 26px;
  }
}
.loyalty .quotes_section .quote_flex {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.loyalty .quotes_section .quote_flex img {
  border-right: 1px solid #000;
  padding-right: 10px;
}
.loyalty .quotes_section .quote_flex p {
  font-size: 18px;
  color: #092d2c;
  line-height: 28px;
  text-align: left;
}
.loyalty .lastsection {
  border-radius: 24px;
  padding: 40px 20px;
}
@media (max-width: 992px) {
  .loyalty .lastsection {
    margin: 0 20px;
  }
}

.marrows {
  margin: 30px 0 35px;
}
.marrows svg {
  width: 48px;
  height: 48px;
  background-color: #f3f5f5;
  border-radius: 100%;
  padding: 10px;
  margin: 0 10px;
}
.marrows svg:hover {
  background-color: #0d9298;
}
.marrows svg:hover path {
  stroke: #fff;
}
.marrows #snext, .marrows #lnext {
  position: relative;
  transform: rotate(180deg);
}

.campaigns .display_table {
  flex-direction: unset;
}
.campaigns .banner {
  height: 100vh;
}
@media (max-width: 992px) {
  .campaigns .banner {
    height: unset;
    padding: 90px 0 0;
    text-align: center;
  }
  .campaigns .banner .common_title {
    font-size: 28px;
    line-height: 32px;
  }
  .campaigns .banner .common_desc {
    font-size: 14px;
    line-height: 22px;
  }
}
.campaigns .banner .common_subtitle {
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.18em;
  text-transform: uppercase;
  font-family: "Basier Circle Semibold";
  padding: 0 0 20px;
}
.campaigns .sec_title {
  font-size: 42px;
  margin: 0 0 16px;
  line-height: 51px;
  letter-spacing: -0.02em;
  font-weight: 500;
  font-family: "Basier Circle medium";
}
@media (max-width: 992px) {
  .campaigns .sec_title {
    font-size: 28px;
    line-height: 32px;
  }
}
.campaigns .sec_desc {
  font-size: 20px;
  margin: 0 0 24px;
  line-height: 30px;
  font-family: "Basier Circle";
}
.campaigns .common_title {
  font-size: 56px;
  margin: 0 0 16px;
  line-height: 62px;
  font-family: "Basier Circle medium";
}
.campaigns .common_desc {
  font-size: 20px;
  margin: 0 0 40px;
  line-height: 30px;
  font-family: "Basier Circle";
}
.campaigns .loyaly_div {
  width: 557px;
  margin: 70px auto 0;
}
@media (max-width: 992px) {
  .campaigns .loyaly_div {
    width: 100%;
    margin: 0 0 40px;
  }
}
.campaigns .loyaly_div .loyaly_slider {
  border-radius: 24px;
}
@media (max-width: 992px) {
  .campaigns .pre-built .common_title {
    font-size: 28px;
    line-height: 32px;
  }
}
@media (max-width: 992px) {
  .campaigns .pre-built .common_desc {
    font-size: 18px;
  }
}
.campaigns .pre-built .campaigns_feature_card_root {
  gap: 24px;
  margin: 0 auto;
  padding: 0 16px;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 992px) {
  .campaigns .pre-built .campaigns_feature_card_root {
    display: block;
    border-radius: 16px;
    padding: 0;
  }
}
.campaigns .pre-built .campaigns_feature_card_root .flx {
  align-items: center !important;
}
.campaigns .pre-built .campaigns_feature_card_root .campaigns_feature_card {
  height: 395px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0 24px 0 0;
  border-radius: 16px;
  background: #f3f1ee;
  width: calc(50% - 12px);
}
@media (max-width: 992px) {
  .campaigns .pre-built .campaigns_feature_card_root .campaigns_feature_card {
    display: block;
    padding: 0;
    height: unset;
    width: 100%;
    margin: 0 0 30px;
  }
}
.campaigns .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_left_side {
  padding: 174px 8px 48px 28px;
}
@media (max-width: 992px) {
  .campaigns .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_left_side {
    padding: 34px 8px 34px 28px;
  }
}
.campaigns .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_left_side .campaigns_feature_number {
  padding: 4px 9px;
  width: 28px;
  height: 28px;
  font-size: 16px;
  color: #fff;
  line-height: 22px;
  border-radius: 8px;
  background: #065151;
  font-family: "Basier Circle Semibold";
}
.campaigns .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_left_side h3 {
  margin: 8px 0;
  font-size: 25px;
  color: #0e1b1b;
  line-height: 26px;
  letter-spacing: -0.02em;
  font-family: "Basier Circle Semibold";
}
@media (max-width: 992px) {
  .campaigns .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_left_side h3 {
    font-size: 20px;
    line-height: 24px;
  }
}
.campaigns .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_left_side p {
  font-size: 14px;
  color: #232d2d;
  line-height: 20px;
}
.campaigns .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_right_side {
  width: 100%;
  max-width: 312px;
  position: relative;
  height: -moz-calc(100% - 48px);
}
@media (max-width: 992px) {
  .campaigns .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_right_side {
    max-width: 250px;
    margin: 0 auto;
  }
}
.campaigns .pre-built .campaigns_feature_card_root .last_card {
  width: 100%;
  height: auto;
}
.campaigns .how_to_send_cards .common_subtitle {
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.18em;
  text-transform: uppercase;
  font-family: "Basier Circle Semibold";
  padding: 0 0 20px;
}
.campaigns .how_to_send_cards .campaigns_how_to_send_cards_parent .campaigns_how_to_send_card {
  gap: 24px;
  height: 402px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 16px;
  background: #f5f8ff;
}
@media (max-width: 992px) {
  .campaigns .how_to_send_cards .campaigns_how_to_send_cards_parent .campaigns_how_to_send_card {
    height: unset;
    display: block;
  }
}
.campaigns .how_to_send_cards .campaigns_how_to_send_cards_parent .campaigns_how_to_send_card .campaigns_left_side {
  flex-grow: 1;
  padding: 48px 64px;
}
@media (max-width: 992px) {
  .campaigns .how_to_send_cards .campaigns_how_to_send_cards_parent .campaigns_how_to_send_card .campaigns_left_side {
    padding: 20px;
  }
}
.campaigns .how_to_send_cards .campaigns_how_to_send_cards_parent .campaigns_how_to_send_card .campaigns_left_side .campaigns_card_number {
  width: 64px;
  height: 64px;
  font-size: 34px;
  color: #fff;
  line-height: 42px;
  border-radius: 50%;
  display: -moz-box;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #6172f3;
  font-family: "Basier Circle Semibold";
}
@media (max-width: 992px) {
  .campaigns .how_to_send_cards .campaigns_how_to_send_cards_parent .campaigns_how_to_send_card .campaigns_left_side .campaigns_card_number {
    width: 34px;
    height: 34px;
    font-size: 20px;
    line-height: 28px;
  }
}
.campaigns .how_to_send_cards .campaigns_how_to_send_cards_parent .campaigns_how_to_send_card .campaigns_left_side h3 {
  font-size: 36px;
  color: #171e59;
  line-height: 50px;
  margin: 32px 0 14px;
  letter-spacing: -0.02em;
  font-family: "Basier Circle Semibold";
}
@media (max-width: 992px) {
  .campaigns .how_to_send_cards .campaigns_how_to_send_cards_parent .campaigns_how_to_send_card .campaigns_left_side h3 {
    font-size: 28px;
    line-height: 32px;
  }
}
.campaigns .how_to_send_cards .campaigns_how_to_send_cards_parent .campaigns_how_to_send_card .campaigns_left_side p {
  font-size: 18px;
  color: #101e6f;
  line-height: 28px;
}
@media (max-width: 992px) {
  .campaigns .how_to_send_cards .campaigns_how_to_send_cards_parent .campaigns_how_to_send_card .campaigns_left_side p {
    font-size: 16px;
    line-height: 26px;
  }
}
.campaigns .how_to_send_cards .campaigns_how_to_send_cards_parent .campaigns_how_to_send_card .campaigns_right_side img {
  padding: 20px 64px 20px 0;
}
@media (max-width: 992px) {
  .campaigns .how_to_send_cards .campaigns_how_to_send_cards_parent .campaigns_how_to_send_card .campaigns_right_side img {
    padding: 20px 20px 0;
  }
}
.campaigns .benifits_section .bene_div {
  padding: 32px 30px;
  border-radius: 16px;
  width: 31%;
  margin: 40px 14px 0;
  height: 450px;
}
@media (max-width: 992px) {
  .campaigns .benifits_section .bene_div {
    width: 100%;
    margin: 30px 0 0;
  }
}
.campaigns .benifits_section .bene_div p {
  font-size: 18px;
  color: #0e1b1b;
  line-height: 28px;
  letter-spacing: -0.02em;
  font-family: "Basier Circle";
}
.campaigns .benifits_section .bene_div .bene_inner {
  padding: 36px 22px;
  background: #fff;
  border-radius: 16.7273px;
  height: 190px;
  margin: 0 0 30px;
}
.campaigns .benifits_section .bene_div .bene_inner p {
  font-size: 20px;
  color: #171e59;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.02em;
  font-family: "Basier Circle medium";
}
.campaigns .benifits_section .bene_div .bene_inner span {
  font-size: 48px;
  line-height: 62px;
  text-align: center;
  letter-spacing: -0.02em;
  font-family: "Basier Circle Semibold";
  display: block;
}
.campaigns .benifits_section .bene_div .bene_inner .green {
  color: #16b364;
}
.campaigns .benifits_section .bene_div .bene_inner .red {
  color: #ef6820;
}
.campaigns .benifits_section .bene_div .bene_inner .blue {
  color: #1570ef;
}
.campaigns .benifits_section .b1 {
  background: #edfcf2;
}
.campaigns .benifits_section .b2 {
  background: #fef6ee;
}
.campaigns .benifits_section .b3 {
  background: #f5f8ff;
}
.campaigns .quotes_section .navneet {
  font-size: 24px;
  color: #092d2c;
  line-height: 32px;
  font-family: "Basier Circle medium";
  padding: 0 0 20px;
}
@media (max-width: 992px) {
  .campaigns .quotes_section .navneet {
    font-size: 18px;
    line-height: 26px;
    padding: 20px 0 20px;
  }
}
.campaigns .quotes_section p {
  font-size: 16px;
  color: #374040;
  line-height: 24px;
  letter-spacing: -0.03em;
  font-family: "Basier Circle medium";
}
.campaigns .quotes_section .quotes-box .quotes_Img .video-thumbnail img {
  border-radius: 8px;
}
.campaigns .quotes_section .quotes-box .quotes_Img .video-thumbnail .play-icon-wrapper {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  cursor: pointer;
}
.campaigns .quotes_section .quotes-box iframe {
  height: 295px;
  border: none;
  border-radius: 8px;
}
@media (max-width: 992px) {
  .campaigns .quotes_section .quotes-box iframe {
    height: 265px;
  }
}
.campaigns .quotes_section .quote_right {
  padding: 0 0 0 40px;
}
@media (max-width: 992px) {
  .campaigns .quotes_section .quote_right {
    padding: 0;
  }
}
.campaigns .feedback-card-root .feedback-card {
  width: 100%;
  max-width: 410px;
  padding: 15px 24px;
  position: relative;
  border-radius: 12px;
  margin: 10px auto 20px;
  background: #dce8ec;
}
.campaigns .feedback-card-root .feedback-card .feedback-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.campaigns .feedback-card-root .feedback-card .feedback-head .card-logo-div {
  width: 104px;
  height: 104px;
  border-radius: 50%;
}
.campaigns .feedback-card-root .feedback-card .feedback-head .card-logo-div .card-logo-image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: contain;
  background: #fff;
}
.campaigns .feedback-card-root .feedback-card .feedback-head h4 {
  font-size: 14px;
  color: #354545;
  line-height: 18px;
  max-width: 208px;
  padding-left: 10px;
  font-family: "Basier Circle";
}
.campaigns .feedback-card-root .feedback-card .arrow-div .feedback-test-text {
  margin: 0;
  top: 230px;
  right: -210px;
  position: absolute;
  font-size: 14px;
  line-height: 18px;
  color: #354545;
  text-align: center;
  max-width: 186px;
  font-family: "Basier Circle";
}
.campaigns .feedback-card-root .feedback-card .arrow-div .feedback-test-img {
  top: 260px;
  right: -140px;
  z-index: 1;
  position: absolute;
}
@media (max-width: 992px) {
  .campaigns .feedback-card-root .feedback-card .arrow-div .feedback-test-img {
    right: -65px;
  }
}
.campaigns .feedback-card-root .feedback-card .feedback-details {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: justify;
  justify-content: space-between;
  margin: 17px 0 18px;
}
.campaigns .feedback-card-root .feedback-card .feedback-details h5 {
  margin: 0;
  color: #354545;
  font-size: 10px;
  line-height: 13px;
}
.campaigns .feedback-card-root .feedback-card .feedback-details h5 span {
  font-family: "Basier Circle";
}
.campaigns .feedback-card-root .feedback-card .bonus-card {
  padding: 5px 8px;
  margin-bottom: 16px;
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: center;
  justify-content: center;
  border-radius: 12px;
  background: #fff;
}
.campaigns .feedback-card-root .feedback-card .bonus-card h3 {
  font-size: 12px;
  color: #354545;
  margin: 0 0 0 5px;
  line-height: 16px;
}
.campaigns .feedback-card-root .feedback-card .rating-card {
  min-width: 430px;
  padding: 18px 24px;
  border-radius: 12px;
  background: #fff;
  -webkit-transform: translateX(-35px);
  -moz-transform: translateX(-35px);
  transform: translateX(-35px);
  -webkit-box-shadow: 2px 3px 10px #c5d1d5;
  box-shadow: 2px 3px 10px #c5d1d5;
}
@media (max-width: 992px) {
  .campaigns .feedback-card-root .feedback-card .rating-card {
    min-width: 100%;
  }
}
.campaigns .feedback-card-root .feedback-card .rating-card h4 {
  font-size: 14px;
  color: #354545;
  line-height: 18px;
  margin-bottom: 25px;
  font-family: "Basier Circle";
}
.campaigns .feedback-card-root .feedback-card .rating-card .rating-row {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: justify;
  justify-content: space-between;
}
.campaigns .feedback-card-root .feedback-card .rating-card .rating-row .emojis {
  cursor: pointer;
  -webkit-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.18, 1.3) 0s;
  -moz-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.18, 1.3) 0s;
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.18, 1.3) 0s;
}
.campaigns .feedback-card-root .feedback-card .rating-card .rating-row .emojis:hover {
  transform: scale(1.4);
}
.campaigns .lastsection {
  border-radius: 24px;
  padding: 40px 20px;
}
@media (max-width: 992px) {
  .campaigns .lastsection {
    margin: 0 20px;
  }
}
@media (max-width: 992px) {
  .campaigns #lastsection {
    height: 350px;
  }
}

.online_reviews .loyalty_tabs_wrapper {
  padding: 0 80px;
}
.online_reviews .loyalty_tabs_wrapper .loyalty_tab {
  display: inline-block;
  padding: 50px 10px;
  cursor: pointer;
}
.online_reviews .loyalty_tabs_wrapper .loyalty_tab p {
  font-size: 18px;
  cursor: pointer;
  color: #374040;
  line-height: 28px;
  padding: 16px 24px;
  border-radius: 48px;
  border: 1px solid #bdc9c9;
  font-family: "Basier Circle medium";
}
.online_reviews .loyalty_tabs_wrapper .loyalty_tab.active p {
  color: #fff;
  background: #0d9298;
  border: 1px solid #0d9298;
}
.online_reviews .loyalty_slider2 .slick-dots {
  display: flex;
  margin: 0;
  list-style-type: none;
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
}
.online_reviews .loyalty_slider2 .slick-dots button {
  display: block;
  width: 6px;
  height: 6px;
  padding: 0;
  background-color: grey;
  text-indent: -9999px;
  cursor: pointer;
  border-radius: 30px;
  border: unset;
  margin: 0 3px;
}
.online_reviews .loyalty_slider2 li.slick-active button {
  background-color: #000;
}
.online_reviews .loyalty_slider2 .loyalty_Slide {
  width: 100%;
  height: 450px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 64px;
  border-radius: 16px;
  background: #f5f8ff;
}
@media (max-width: 992px) {
  .online_reviews .loyalty_slider2 .loyalty_Slide {
    padding: 20px;
    height: 600px;
    margin: 0 0 50px;
    display: block;
  }
  .online_reviews .loyalty_slider2 .loyalty_Slide img {
    padding: 20px 0 0;
  }
}
.online_reviews .loyalty_slider2 .loyalty_Slide h3 {
  font-size: 36px;
  color: #171e59;
  line-height: 50px;
  letter-spacing: -0.02em;
  font-family: "Basier Circle Semibold";
  max-width: 534px;
}
@media (max-width: 992px) {
  .online_reviews .loyalty_slider2 .loyalty_Slide h3 {
    font-size: 18px;
    line-height: 26px;
  }
}

.qr_code .display_table {
  flex-direction: unset;
}
.qr_code .banner {
  height: 100vh;
}
@media (max-width: 992px) {
  .qr_code .banner {
    height: unset;
    padding: 90px 0 0;
    text-align: center;
  }
  .qr_code .banner .common_title {
    font-size: 28px;
    line-height: 32px;
  }
  .qr_code .banner .common_desc {
    font-size: 14px;
    line-height: 22px;
  }
}
.qr_code .sec_title {
  font-size: 42px;
  margin: 0 0 16px;
  line-height: 51px;
  letter-spacing: -0.02em;
  font-weight: 500;
  font-family: "Basier Circle medium";
}
@media (max-width: 992px) {
  .qr_code .sec_title {
    font-size: 28px;
    line-height: 32px;
  }
}
.qr_code .sec_desc {
  font-size: 20px;
  margin: 0 0 24px;
  line-height: 30px;
  font-family: "Basier Circle";
}
.qr_code .common_title {
  font-size: 56px;
  margin: 0 0 16px;
  line-height: 62px;
  letter-spacing: -0.01em;
  font-family: "Basier Circle medium";
}
.qr_code .common_desc {
  font-size: 20px;
  margin: 0 0 40px;
  line-height: 30px;
  font-family: "Basier Circle";
}
.qr_code .loyaly_div {
  width: 557px;
  margin: 70px auto 0;
}
@media (max-width: 992px) {
  .qr_code .loyaly_div {
    width: 100%;
    margin: 0;
  }
}
.qr_code .loyaly_div .loyaly_slider {
  border-radius: 24px;
  background: #fef6ee;
  padding: 30px 30px 0;
}
.qr_code .loyalty_tabs_wrapper {
  border-bottom: 1px solid rgba(238, 238, 238, 0.9333333333);
  padding: 0 80px;
}
.qr_code .loyalty_tabs_wrapper .loyalty_tab {
  display: inline-block;
  padding: 50px 30px;
  cursor: pointer;
}
.qr_code .loyalty_tabs_wrapper .loyalty_tab:before {
  position: absolute;
  content: "";
  width: 95%;
  height: 4px;
  bottom: 0;
  background-color: #eee;
  transform: translateX(-50%);
  left: 50%;
}
.qr_code .loyalty_tabs_wrapper .loyalty_tab span {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: #f3f4f6;
  font-size: 24px;
  font-family: "Basier Circle Semibold";
  padding: 10px 19px;
}
.qr_code .loyalty_tabs_wrapper .loyalty_tab p {
  font-size: 18px;
  color: #959595;
  line-height: 26px;
  text-align: center;
  font-family: "Basier Circle medium";
  padding: 40px 0 0;
}
.qr_code .loyalty_tabs_wrapper .loyalty_tab.active:before {
  background-color: #01999f;
}
.qr_code .loyalty_tabs_wrapper .loyalty_tab.active p {
  color: #182020;
}
.qr_code .loyalty_slider2 {
  margin: 50px 0 0;
}
@media (max-width: 992px) {
  .qr_code .loyalty_slider2 {
    margin: 0;
  }
}
.qr_code .loyalty_slider2 .slick-dots {
  display: flex;
  margin: 0;
  list-style-type: none;
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
}
.qr_code .loyalty_slider2 .slick-dots button {
  display: block;
  width: 6px;
  height: 6px;
  padding: 0;
  background-color: grey;
  text-indent: -9999px;
  cursor: pointer;
  border-radius: 30px;
  border: unset;
  margin: 0 3px;
}
.qr_code .loyalty_slider2 li.slick-active button {
  background-color: #000;
}
.qr_code .loyalty_slider2 .loyalty_Slide {
  width: 100%;
  height: 490px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 64px;
  border-radius: 16px;
  background: #f5f8ff;
}
@media (max-width: 992px) {
  .qr_code .loyalty_slider2 .loyalty_Slide {
    padding: 30px 20px;
    height: unset;
    margin: 0 0 50px;
    display: block;
  }
}
@media (max-width: 992px) {
  .qr_code .loyalty_slider2 .loyalty_Slide .loyalty_img img {
    width: 200px;
    margin: 0 auto;
  }
}
.qr_code .loyalty_slider2 .loyalty_Slide .loyalty_content {
  padding: 0 0 30px;
  max-width: 60%;
}
@media (max-width: 992px) {
  .qr_code .loyalty_slider2 .loyalty_Slide .loyalty_content {
    max-width: 100%;
  }
}
.qr_code .loyalty_slider2 .loyalty_Slide .loyalty_content span {
  width: 64px;
  height: 64px;
  font-size: 34px;
  color: #fff;
  line-height: 42px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #01999f;
  font-family: "Basier Circle Semibold";
}
@media (max-width: 992px) {
  .qr_code .loyalty_slider2 .loyalty_Slide .loyalty_content span {
    width: 34px;
    height: 34px;
    font-size: 20px;
    line-height: 28px;
  }
}
.qr_code .loyalty_slider2 .loyalty_Slide .loyalty_content h3 {
  font-size: 36px;
  color: #171e59;
  line-height: 50px;
  margin: 32px 0 12px;
  letter-spacing: -0.02em;
  font-family: "Basier Circle Semibold";
}
@media (max-width: 992px) {
  .qr_code .loyalty_slider2 .loyalty_Slide .loyalty_content h3 {
    font-size: 28px;
    line-height: 32px;
  }
}
.qr_code .loyalty_slider2 .loyalty_Slide .loyalty_content p {
  font-size: 28px;
  color: #171e59;
  line-height: 40px;
  letter-spacing: -0.02em;
  font-family: "Basier Circle medium";
}
@media (max-width: 992px) {
  .qr_code .loyalty_slider2 .loyalty_Slide .loyalty_content p {
    font-size: 16px;
    line-height: 26px;
  }
}
.qr_code .qrcode_reviews {
  padding: 70px 0 0;
}
@media (max-width: 992px) {
  .qr_code .qrcode_reviews {
    padding: 40px 0;
  }
}
.qr_code .qrcode_reviews .qr_wrapper {
  padding-right: 60px;
}
@media (max-width: 992px) {
  .qr_code .qrcode_reviews .qr_wrapper {
    padding-right: 0;
  }
}
.qr_code .qrcode_reviews .qr_wrapper .loyalty_tabs {
  cursor: pointer;
  margin: 0 0 16px;
  padding: 16px 24px;
  border-radius: 16px;
  transition: all 0.5s;
  background: #f2f2f2;
}
.qr_code .qrcode_reviews .qr_wrapper .loyalty_tabs h3 {
  font-size: 16px;
  color: #182020;
  line-height: 22px;
}
.qr_code .qrcode_reviews .qr_wrapper .loyalty_tabs p {
  display: none;
  margin: 8px 0 0;
  font-size: 16px;
  color: #fff;
  line-height: 22px;
  text-align: left;
}
.qr_code .qrcode_reviews .qr_wrapper .loyalty_tabs.active {
  background-color: #01999f;
}
.qr_code .qrcode_reviews .qr_wrapper .loyalty_tabs.active h3 {
  color: #fff;
}
.qr_code .qrcode_reviews .qr_wrapper .loyalty_tabs.active p {
  display: block;
}
.qr_code .qrcode_reviews .qr_slider .slick-dots {
  display: flex;
  margin: 0;
  list-style-type: none;
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
}
.qr_code .qrcode_reviews .qr_slider .slick-dots button {
  display: block;
  width: 6px;
  height: 6px;
  padding: 0;
  background-color: grey;
  text-indent: -9999px;
  cursor: pointer;
  border-radius: 30px;
  border: unset;
  margin: 0 3px;
}
.qr_code .qrcode_reviews .qr_slider li.slick-active button {
  background-color: #000;
}
.qr_code .qrcode_reviews .qr_slider img {
  border-radius: 16px;
}
.qr_code .qrcode_reviews .qr_slider .qr_desc h3 {
  font-size: 18px;
  color: #0e1b1b;
  line-height: 24px;
  text-align: center;
  margin: 32px auto 8px;
}
.qr_code .qrcode_reviews .qr_slider .qr_desc p {
  font-size: 14px;
  color: #5a6565;
  max-width: 400px;
  line-height: 20px;
  margin: 0 auto 32px;
}
.qr_code .benifits_section {
  background: linear-gradient(0deg, rgba(86, 186, 153, 0.12) -71.36%, rgba(86, 186, 153, 0));
}
.qr_code .benifits_section .bene_div {
  padding: 32px 30px;
  border-radius: 16px;
  margin: 40px 0 0;
}
@media (max-width: 992px) {
  .qr_code .benifits_section .bene_div {
    width: 100%;
    margin: 30px 0 0;
    padding: 0;
  }
}
.qr_code .benifits_section .bene_div .bene_inner p {
  font-size: 16px;
  color: #092d2c;
  line-height: 21px;
  letter-spacing: -0.05em;
  font-family: "Basier Circle medium";
}
@media (max-width: 992px) {
  .qr_code .benifits_section .bene_div .bene_inner p {
    font-size: 14px;
    line-height: 20px;
  }
}
.qr_code .benifits_section .bene_div .bene_inner span {
  display: block;
  font-size: 52px;
  color: #56ba99;
  line-height: 68px;
  letter-spacing: -0.05em;
  font-weight: 600;
}
@media (max-width: 992px) {
  .qr_code .benifits_section .bene_div .bene_inner span {
    font-size: 32px;
    line-height: 42px;
  }
}
.qr_code .inspiration {
  border-radius: 16px;
  padding: 48px 40px 0;
  background: #fef6ee;
}
@media (max-width: 992px) {
  .qr_code .inspiration {
    padding: 40px 20px 0;
  }
}
.qr_code .quotes_section p {
  font-size: 24px;
  color: #092d2c;
  line-height: 32px;
  text-align: center;
  font-family: "Basier Circle";
}
@media (max-width: 992px) {
  .qr_code .quotes_section p {
    font-size: 18px;
    line-height: 26px;
  }
}
.qr_code .quotes_section .quote_flex {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.qr_code .quotes_section .quote_flex img {
  border-right: 1px solid #000;
  padding-right: 10px;
}
.qr_code .quotes_section .quote_flex p {
  font-size: 18px;
  color: #092d2c;
  line-height: 28px;
  text-align: left;
}
.qr_code .lastsection {
  border-radius: 24px;
  padding: 40px 20px;
}
@media (max-width: 992px) {
  .qr_code .lastsection {
    margin: 0 20px;
  }
}

.whatsapp-marketing .display_table {
  flex-direction: unset;
}
@media (max-width: 992px) {
  .whatsapp-marketing .banner {
    padding: 60px 0 0;
    text-align: center;
  }
  .whatsapp-marketing .banner .common_title {
    font-size: 28px;
    line-height: 32px;
  }
  .whatsapp-marketing .banner .common_desc {
    font-size: 14px;
    line-height: 22px;
  }
}
@media (max-width: 992px) {
  .whatsapp-marketing .sec-light {
    letter-spacing: -0.02em;
    font-weight: 500;
    font-family: "Basier Circle medium";
    font-size: 24px;
    line-height: 28px;
  }
}
@media (max-width: 992px) {
  .whatsapp-marketing .sec-title {
    font-size: 24px;
    line-height: 28px;
  }
}
.whatsapp-marketing .sec_title {
  font-size: 42px;
  line-height: 51px;
  letter-spacing: -0.02em;
  font-weight: 500;
  font-family: "Basier Circle medium";
}
@media (max-width: 992px) {
  .whatsapp-marketing .sec_title {
    font-size: 24px;
    line-height: 28px;
  }
}
.whatsapp-marketing .sec_desc {
  font-size: 20px;
  margin: 0 0 24px;
  line-height: 30px;
  font-family: "Basier Circle";
}
.whatsapp-marketing .common_title {
  font-size: 56px;
  margin: 0 0 16px;
  line-height: 62px;
  letter-spacing: -0.01em;
  font-family: "Basier Circle medium";
}
.whatsapp-marketing .common_desc {
  font-size: 20px;
  margin: 0 0 40px;
  line-height: 30px;
  font-family: "Basier Circle";
}
.whatsapp-marketing .loyaly_div {
  width: 557px;
  margin: 70px auto 0;
}
@media (max-width: 992px) {
  .whatsapp-marketing .loyaly_div {
    width: 100%;
    margin: 0;
  }
}
@media (max-width: 992px) {
  .whatsapp-marketing .pre-built .common_title {
    font-size: 28px;
    line-height: 32px;
  }
}
@media (max-width: 992px) {
  .whatsapp-marketing .pre-built .common_desc {
    font-size: 18px;
  }
}
.whatsapp-marketing .pre-built .campaigns_feature_card_root {
  gap: 24px;
  margin: 0 auto;
  padding: 0 16px;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 992px) {
  .whatsapp-marketing .pre-built .campaigns_feature_card_root {
    display: block;
    border-radius: 16px;
    padding: 0;
  }
  .whatsapp-marketing .pre-built .campaigns_feature_card_root .slick-dots {
    display: flex;
    margin: 0;
    list-style-type: none;
    position: absolute;
    bottom: -9px;
    left: 50%;
    transform: translateX(-50%);
  }
  .whatsapp-marketing .pre-built .campaigns_feature_card_root .slick-dots button {
    display: block;
    width: 12px;
    height: 12px;
    padding: 0;
    background-color: #c6c6c6;
    text-indent: -9999px;
    cursor: pointer;
    border-radius: 30px;
    border: unset;
    margin: 0 3px;
  }
  .whatsapp-marketing .pre-built .campaigns_feature_card_root li.slick-active button {
    background-color: #fff;
  }
}
.whatsapp-marketing .pre-built .campaigns_feature_card_root .campaigns_feature_card {
  height: 300px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0 24px 0 0;
  border-radius: 16px;
  background: #f3f1ee;
  width: calc(50% - 12px);
}
@media (max-width: 992px) {
  .whatsapp-marketing .pre-built .campaigns_feature_card_root .campaigns_feature_card {
    display: block;
    padding: 0;
    height: 540px;
    width: 100%;
    margin: 0 0 30px;
  }
}
.whatsapp-marketing .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_left_side {
  padding: 174px 4px 48px 28px;
}
@media (max-width: 992px) {
  .whatsapp-marketing .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_left_side {
    padding: 34px 4px 34px 28px;
  }
}
.whatsapp-marketing .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_left_side .campaigns_feature_number {
  padding: 4px 9px;
  width: 28px;
  height: 28px;
  font-size: 16px;
  color: #fff;
  line-height: 22px;
  border-radius: 8px;
  background: #065151;
  font-family: "Basier Circle Semibold";
}
.whatsapp-marketing .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_left_side h3 {
  margin: 8px 0;
  font-size: 28px;
  color: #0e1b1b;
  line-height: 34px;
  letter-spacing: -0.02em;
  font-family: "Basier Circle Semibold";
}
@media (max-width: 992px) {
  .whatsapp-marketing .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_left_side h3 {
    font-size: 20px;
    line-height: 24px;
  }
}
.whatsapp-marketing .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_left_side p {
  font-size: 14px;
  color: #232d2d;
  line-height: 20px;
}
.whatsapp-marketing .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_right_side {
  width: 100%;
  position: relative;
  bottom: -6px;
}
@media (max-width: 992px) {
  .whatsapp-marketing .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_right_side {
    text-align: center;
    padding: 0 15px;
    max-width: 100%;
    bottom: 0;
  }
  .whatsapp-marketing .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_right_side img {
    height: 300px;
  }
}
.whatsapp-marketing .pre-built .campaigns_feature_card_root .last_card {
  width: 100%;
  height: auto;
}
.whatsapp-marketing .qrcode_reviews .qr_wrapper {
  padding-right: 60px;
}
@media (max-width: 992px) {
  .whatsapp-marketing .qrcode_reviews .qr_wrapper {
    padding-right: 0;
  }
}
.whatsapp-marketing .qrcode_reviews .qr_wrapper .loyalty_tabs {
  cursor: pointer;
  margin: 0 0 16px;
  padding: 16px 24px;
  border-radius: 16px;
  transition: all 0.5s;
  background: #f2f2f2;
}
.whatsapp-marketing .qrcode_reviews .qr_wrapper .loyalty_tabs h3 {
  font-size: 20px;
  color: #182020;
  line-height: 26px;
}
.whatsapp-marketing .qrcode_reviews .qr_wrapper .loyalty_tabs p {
  display: none;
  margin: 8px 0 0;
  font-size: 16px;
  color: #fff;
  line-height: 22px;
  text-align: left;
}
.whatsapp-marketing .qrcode_reviews .qr_wrapper .loyalty_tabs ul {
  display: none;
  padding: 10px 0 0 15px;
}
.whatsapp-marketing .qrcode_reviews .qr_wrapper .loyalty_tabs ul li {
  font-size: 16px;
  color: #fff;
  line-height: 22px;
  text-align: left;
  padding: 0 0 5px 5px;
  position: relative;
  list-style: none;
}
.whatsapp-marketing .qrcode_reviews .qr_wrapper .loyalty_tabs ul li:before {
  position: absolute;
  content: "✔";
  left: -13px;
}
.whatsapp-marketing .qrcode_reviews .qr_wrapper .loyalty_tabs.active {
  background-color: #01999f;
}
.whatsapp-marketing .qrcode_reviews .qr_wrapper .loyalty_tabs.active h3 {
  color: #fff;
}
.whatsapp-marketing .qrcode_reviews .qr_wrapper .loyalty_tabs.active p, .whatsapp-marketing .qrcode_reviews .qr_wrapper .loyalty_tabs.active ul {
  display: block;
}
.whatsapp-marketing .qrcode_reviews .qr_slider .slick-dots {
  display: flex;
  margin: 0;
  list-style-type: none;
  position: absolute;
  bottom: -9px;
  left: 50%;
  transform: translateX(-50%);
}
.whatsapp-marketing .qrcode_reviews .qr_slider .slick-dots button {
  display: block;
  width: 6px;
  height: 6px;
  padding: 0;
  background-color: grey;
  text-indent: -9999px;
  cursor: pointer;
  border-radius: 30px;
  border: unset;
  margin: 0 3px;
}
.whatsapp-marketing .qrcode_reviews .qr_slider li.slick-active button {
  background-color: #000;
}
.whatsapp-marketing .qrcode_reviews .qr_slider img {
  border-radius: 16px;
}
@media (max-width: 992px) {
  .whatsapp-marketing .qrcode_reviews .qr_slider .qr_desc {
    text-align: left;
  }
}
.whatsapp-marketing .qrcode_reviews .qr_slider .qr_desc h3 {
  font-size: 18px;
  color: #0e1b1b;
  line-height: 24px;
  margin: 32px auto 8px;
}
.whatsapp-marketing .qrcode_reviews .qr_slider .qr_desc p {
  font-size: 14px;
  color: #5a6565;
  max-width: 400px;
  line-height: 20px;
  margin: 0 auto 8px;
}
.whatsapp-marketing .qrcode_reviews .qr_slider .qr_desc ul {
  padding: 10px 14px 20px;
}
.whatsapp-marketing .qrcode_reviews .qr_slider .qr_desc ul li {
  font-size: 14px;
  color: #5a6565;
  max-width: 400px;
  line-height: 20px;
  list-style: none;
  padding-bottom: 5px;
  position: relative;
}
.whatsapp-marketing .qrcode_reviews .qr_slider .qr_desc ul li:before {
  position: absolute;
  content: "✔";
  margin-left: -14px;
}
.whatsapp-marketing .fold3 .marrows {
  margin: 30px 0 35px;
}
.whatsapp-marketing .fold3 .marrows svg {
  width: 48px;
  height: 48px;
  background-color: #f3f5f5;
  border-radius: 100%;
  padding: 10px;
  margin: 0 10px;
}
.whatsapp-marketing .fold3 .marrows svg:hover {
  background-color: #0d9298;
}
.whatsapp-marketing .fold3 .marrows svg:hover path {
  stroke: #fff;
}
.whatsapp-marketing .fold3 .marrows #cnext {
  position: relative;
  transform: rotate(180deg);
}
.whatsapp-marketing .fold3 .reviewCarousel_parent .reviewCarousel {
  gap: 48px;
  padding: 24px;
  height: 381px;
  margin: 0 4px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  border-radius: 24px;
  align-items: stretch;
  background: #e0eaff;
}
@media (max-width: 992px) {
  .whatsapp-marketing .fold3 .reviewCarousel_parent .reviewCarousel {
    gap: 12px;
    padding: 16px;
    margin: 0 4px;
    border-radius: 8px;
    flex-direction: column;
    height: unset;
  }
}
.whatsapp-marketing .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_left {
  max-width: 665px;
  overflow: hidden;
  width: calc(50% - 24px);
}
@media (max-width: 992px) {
  .whatsapp-marketing .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_left {
    max-width: 100%;
    width: 100%;
    overflow: unset;
  }
}
.whatsapp-marketing .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_left iframe {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  border-radius: 12px;
}
.whatsapp-marketing .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_right {
  gap: 24px;
  padding: 10px 0 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  max-width: -moz-calc(50% - 24px);
  max-width: calc(50% - 24px);
}
@media (max-width: 992px) {
  .whatsapp-marketing .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_right {
    max-width: 100%;
    min-width: 100%;
    padding: 15px 0 0;
  }
}
.whatsapp-marketing .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_right h3 {
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -1.28px;
  font-family: "Basier Circle medium";
  font-weight: 500;
}
@media (max-width: 992px) {
  .whatsapp-marketing .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_right h3 {
    margin: 0 0 8px;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.24px;
  }
}
.whatsapp-marketing .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_right .reviewCarousel_review_detail {
  gap: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.whatsapp-marketing .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_right .reviewCarousel_review_detail .reviewImgs {
  border-radius: 50%;
  object-fit: contain;
  background-color: #fff;
}
.whatsapp-marketing .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_right .reviewCarousel_review_detail .reviewer_name {
  font-size: 22px;
  line-height: 26px;
  font-family: "Basier Circle medium";
  padding: 0 0 6px;
}
@media (max-width: 992px) {
  .whatsapp-marketing .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_right .reviewCarousel_review_detail .reviewer_name {
    font-size: 16px;
    line-height: 22px;
  }
}
.whatsapp-marketing .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_right .reviewCarousel_review_detail .reviewer_post {
  font-size: 14px;
  color: #374040;
  line-height: 20px;
  letter-spacing: -0.14px;
}
@media (max-width: 992px) {
  .whatsapp-marketing .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_right .reviewCarousel_review_detail .reviewer_post {
    font-size: 12px;
    line-height: 16px;
  }
}
.whatsapp-marketing .what-reelo-offers .whatreelo_offers {
  display: flex;
  justify-content: center;
  gap: 30px;
  padding: 15px;
}
@media (max-width: 992px) {
  .whatsapp-marketing .what-reelo-offers .whatreelo_offers {
    padding: 0;
    gap: 10px;
    margin: 5px 0;
  }
}
.whatsapp-marketing .what-reelo-offers .whatreelo_offers .whatreelo_offers_div {
  width: 343px;
  height: 196px;
  padding: 32px 22px;
  border-radius: 17px;
}
@media (max-width: 992px) {
  .whatsapp-marketing .what-reelo-offers .whatreelo_offers .whatreelo_offers_div {
    height: 130px;
    padding: 15px;
  }
  .whatsapp-marketing .what-reelo-offers .whatreelo_offers .whatreelo_offers_div img {
    width: 35px;
  }
}
.whatsapp-marketing .what-reelo-offers .whatreelo_offers .whatreelo_offers_div p {
  color: #171E59;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  padding: 15px 0 0;
}
@media (max-width: 992px) {
  .whatsapp-marketing .what-reelo-offers .whatreelo_offers .whatreelo_offers_div p {
    font-size: 14px;
  }
}
.whatsapp-marketing .what-reelo-offers .whatreelo_offers .offer_1 {
  background: #EDFCF2;
}
.whatsapp-marketing .what-reelo-offers .whatreelo_offers .offer_2 {
  background: #FEF6EE;
}
.whatsapp-marketing .what-reelo-offers .whatreelo_offers .offer_3 {
  background: #F5F8FF;
}
.whatsapp-marketing .what-reelo-offers .whatreelo_offers .offer_4 {
  background: #FFE5EB;
}
.whatsapp-marketing .what-reelo-offers .whatreelo_offers .offer_5 {
  background: #DDFEFF;
}
.whatsapp-marketing .what-reelo-offers .whatreelo_offers .offer_6 {
  background: #FFF1D4;
}
.whatsapp-marketing .lastsection {
  border-radius: 24px;
  padding: 40px 20px;
}
@media (max-width: 992px) {
  .whatsapp-marketing .lastsection {
    margin: 0 20px;
  }
}

.referral_section .display_table {
  flex-direction: unset;
}
.referral_section .banner {
  height: 100vh;
}
@media (max-width: 992px) {
  .referral_section .banner {
    height: unset;
    padding: 90px 0 0;
    text-align: center;
  }
  .referral_section .banner .common_title {
    font-size: 28px;
    line-height: 32px;
  }
  .referral_section .banner .common_desc {
    font-size: 14px;
    line-height: 22px;
  }
}
.referral_section .banner .common_subtitle {
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.18em;
  text-transform: uppercase;
  font-family: "Basier Circle Semibold";
  padding: 0 0 20px;
}
.referral_section .sec_title {
  font-size: 42px;
  margin: 0 0 16px;
  line-height: 51px;
  letter-spacing: -0.02em;
  font-weight: 500;
  font-family: "Basier Circle medium";
}
@media (max-width: 992px) {
  .referral_section .sec_title {
    font-size: 28px;
    line-height: 32px;
  }
}
.referral_section .sec_desc {
  font-size: 20px;
  margin: 0 0 24px;
  line-height: 30px;
  font-family: "Basier Circle";
}
.referral_section .common_title {
  font-size: 56px;
  margin: 0 0 16px;
  line-height: 62px;
  font-family: "Basier Circle medium";
}
.referral_section .common_desc {
  font-size: 20px;
  margin: 0 0 40px;
  line-height: 30px;
  font-family: "Basier Circle";
}
@media (max-width: 992px) {
  .referral_section .loyaly_div {
    width: 100%;
    margin: 0;
  }
}
.referral_section .loyaly_div .ref_video {
  width: 557px;
  height: 545px;
  overflow: hidden;
  border-radius: 24px;
  background: #d8ecee;
}
@media (max-width: 992px) {
  .referral_section .loyaly_div .ref_video {
    width: 100%;
    height: 100%;
  }
}
@media (max-width: 992px) {
  .referral_section .pre-built .common_title {
    font-size: 28px;
    line-height: 32px;
  }
}
@media (max-width: 992px) {
  .referral_section .pre-built .common_desc {
    font-size: 18px;
  }
}
.referral_section .pre-built .campaigns_feature_card_root {
  gap: 24px;
  margin: 0 auto;
  padding: 0 16px;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 992px) {
  .referral_section .pre-built .campaigns_feature_card_root {
    display: block;
    border-radius: 16px;
    padding: 0;
  }
}
.referral_section .pre-built .campaigns_feature_card_root .campaigns_feature_card {
  height: 360px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0 24px 0 0;
  border-radius: 16px;
  background: #f3f1ee;
  width: calc(50% - 12px);
}
@media (max-width: 992px) {
  .referral_section .pre-built .campaigns_feature_card_root .campaigns_feature_card {
    display: block;
    padding: 0;
    height: unset;
    width: 100%;
    margin: 0 0 30px;
  }
}
.referral_section .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_left_side {
  padding: 174px 8px 48px 28px;
}
@media (max-width: 992px) {
  .referral_section .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_left_side {
    padding: 34px 8px 34px 28px;
  }
}
.referral_section .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_left_side .campaigns_feature_number {
  padding: 4px 9px;
  width: 28px;
  height: 28px;
  font-size: 16px;
  color: #fff;
  line-height: 22px;
  border-radius: 8px;
  background: #065151;
  font-family: "Basier Circle Semibold";
}
.referral_section .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_left_side h3 {
  margin: 8px 0;
  font-size: 28px;
  color: #0e1b1b;
  line-height: 34px;
  letter-spacing: -0.02em;
  font-family: "Basier Circle Semibold";
}
@media (max-width: 992px) {
  .referral_section .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_left_side h3 {
    font-size: 20px;
    line-height: 24px;
  }
}
.referral_section .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_left_side p {
  font-size: 14px;
  color: #232d2d;
  line-height: 20px;
}
.referral_section .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_right_side {
  width: 100%;
  max-width: 312px;
  position: relative;
  height: -moz-calc(100% - 48px);
}
.referral_section .pre-built .campaigns_feature_card_root .last_card {
  width: 100%;
  height: auto;
}
.referral_section .referral_programs .referral_slider {
  margin: 45px 0;
}
.referral_section .referral_programs .referral_slider img {
  display: inline-block !important;
}
.referral_section .referral_programs .referral_slider .slick-dots {
  display: flex;
  margin: 0;
  list-style-type: none;
  position: absolute;
  bottom: -35px;
  left: 50%;
  transform: translateX(-50%);
}
.referral_section .referral_programs .referral_slider .slick-dots button {
  display: block;
  width: 10px;
  height: 10px;
  padding: 0;
  background-color: #038c8b;
  text-indent: -9999px;
  cursor: pointer;
  border-radius: 30px;
  border: unset;
  margin: 0 5px;
  opacity: 0.25;
}
.referral_section .referral_programs .referral_slider li.slick-active button {
  opacity: 1;
}
.referral_section .referral_programs .referral_slider .ref_slide p {
  font-size: 16px;
  margin: 24px 0 0;
  color: #374040;
  line-height: 24px;
  text-align: center;
  font-family: "Basier Circle medium";
}
.referral_section .how_to_send_cards .common_subtitle {
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.18em;
  text-transform: uppercase;
  font-family: "Basier Circle Semibold";
  padding: 0 0 20px;
}
.referral_section .how_to_send_cards .marrows {
  margin: 30px 0 35px;
}
.referral_section .how_to_send_cards .marrows svg {
  width: 48px;
  height: 48px;
  background-color: #f3f5f5;
  border-radius: 100%;
  padding: 10px;
  margin: 0 10px;
}
.referral_section .how_to_send_cards .marrows svg:hover {
  background-color: #0d9298;
}
.referral_section .how_to_send_cards .marrows svg:hover path {
  stroke: #fff;
}
.referral_section .how_to_send_cards .marrows #snext {
  position: relative;
  transform: rotate(180deg);
}
.referral_section .how_to_send_cards .campaigns_how_to_send_cards_parent .campaigns_how_to_send_card {
  gap: 24px;
  height: 580px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 16px;
  background: #f5f8ff;
}
@media (max-width: 992px) {
  .referral_section .how_to_send_cards .campaigns_how_to_send_cards_parent .campaigns_how_to_send_card {
    height: unset;
    display: block;
  }
}
.referral_section .how_to_send_cards .campaigns_how_to_send_cards_parent .campaigns_how_to_send_card .campaigns_left_side {
  flex-grow: 1;
  padding: 48px 64px;
}
@media (max-width: 992px) {
  .referral_section .how_to_send_cards .campaigns_how_to_send_cards_parent .campaigns_how_to_send_card .campaigns_left_side {
    padding: 20px;
  }
}
.referral_section .how_to_send_cards .campaigns_how_to_send_cards_parent .campaigns_how_to_send_card .campaigns_left_side .campaigns_card_number {
  width: 64px;
  height: 64px;
  font-size: 34px;
  color: #fff;
  line-height: 42px;
  border-radius: 50%;
  display: -moz-box;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #6172f3;
  font-family: "Basier Circle Semibold";
}
@media (max-width: 992px) {
  .referral_section .how_to_send_cards .campaigns_how_to_send_cards_parent .campaigns_how_to_send_card .campaigns_left_side .campaigns_card_number {
    width: 34px;
    height: 34px;
    font-size: 20px;
    line-height: 28px;
  }
}
.referral_section .how_to_send_cards .campaigns_how_to_send_cards_parent .campaigns_how_to_send_card .campaigns_left_side h3 {
  font-size: 36px;
  margin: 32px 0 0;
  color: #171e59;
  line-height: 50px;
  letter-spacing: -0.02em;
  font-family: "Basier Circle Semibold";
}
@media (max-width: 992px) {
  .referral_section .how_to_send_cards .campaigns_how_to_send_cards_parent .campaigns_how_to_send_card .campaigns_left_side h3 {
    font-size: 28px;
    line-height: 32px;
  }
}
.referral_section .how_to_send_cards .campaigns_how_to_send_cards_parent .campaigns_how_to_send_card .campaigns_left_side p {
  margin: 40px 0 0;
  color: rgba(23, 30, 89, 0.2);
  font-size: 36px;
  line-height: 50px;
  letter-spacing: -0.02em;
  font-family: "Basier Circle Semibold";
}
@media (max-width: 992px) {
  .referral_section .how_to_send_cards .campaigns_how_to_send_cards_parent .campaigns_how_to_send_card .campaigns_left_side p {
    font-size: 16px;
    line-height: 26px;
    display: none;
  }
}
.referral_section .how_to_send_cards .campaigns_how_to_send_cards_parent .campaigns_how_to_send_card .campaigns_right_side img {
  padding: 20px 64px 20px 0;
}
@media (max-width: 992px) {
  .referral_section .how_to_send_cards .campaigns_how_to_send_cards_parent .campaigns_how_to_send_card .campaigns_right_side img {
    padding: 20px 20px 0;
  }
}
.referral_section .miss_out .bene_div {
  padding: 32px 30px;
  border-radius: 16px;
}
@media (max-width: 992px) {
  .referral_section .miss_out .bene_div {
    width: 100%;
    margin: 30px 0 0;
    padding: 0;
  }
}
.referral_section .miss_out .bene_div .bene_inner p {
  font-size: 24px;
  color: #374040;
  line-height: 34px;
  letter-spacing: -0.02em;
  font-family: "Basier Circle medium";
}
@media (max-width: 992px) {
  .referral_section .miss_out .bene_div .bene_inner p {
    font-size: 14px;
    line-height: 20px;
  }
}
.referral_section .miss_out .bene_div .bene_inner span {
  display: block;
  font-size: 64px;
  color: #15b79e;
  line-height: 78px;
  letter-spacing: -0.02em;
  font-weight: 600;
}
@media (max-width: 992px) {
  .referral_section .miss_out .bene_div .bene_inner span {
    font-size: 32px;
    line-height: 42px;
  }
}
.referral_section .benifits_section .bene_div {
  padding: 32px 30px;
  border-radius: 16px;
  width: 47%;
  margin: 40px 14px 0;
}
@media (max-width: 992px) {
  .referral_section .benifits_section .bene_div {
    width: 100%;
    margin: 30px 0 0;
  }
}
.referral_section .benifits_section .bene_div h3 {
  font-size: 24px;
  color: #171e59;
  line-height: 32px;
  margin: 40px auto 16px;
  letter-spacing: -0.02em;
  font-family: "Basier Circle medium";
}
.referral_section .benifits_section .bene_div p {
  font-size: 18px;
  color: #171e59;
  line-height: 28px;
  font-family: "Basier Circle";
}
.referral_section .benifits_section .bene_div .bene_inner {
  padding: 36px 22px;
  background: #fff;
  border-radius: 16.7273px;
  margin: 0 0 30px;
}
.referral_section .benifits_section .bene_div .bene_inner p {
  font-size: 20px;
  color: #171e59;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.02em;
  font-family: "Basier Circle medium";
}
.referral_section .benifits_section .bene_div .bene_inner span {
  font-size: 48px;
  line-height: 62px;
  text-align: center;
  letter-spacing: -0.02em;
  font-family: "Basier Circle Semibold";
  display: block;
}
.referral_section .benifits_section .bene_div .bene_inner .green {
  color: #16b364;
}
.referral_section .benifits_section .bene_div .bene_inner .red {
  color: #ef6820;
}
.referral_section .benifits_section .bene_div .bene_inner .blue {
  color: #1570ef;
}
.referral_section .benifits_section .b1 {
  background: #edfcf2;
}
.referral_section .benifits_section .b2 {
  background: #fef6ee;
}
.referral_section .benifits_section .b3 {
  background: #f5f8ff;
}
.referral_section .quotes_section p {
  font-size: 24px;
  color: #092d2c;
  line-height: 32px;
  text-align: center;
  font-family: "Basier Circle";
}
@media (max-width: 992px) {
  .referral_section .quotes_section p {
    font-size: 18px;
    line-height: 26px;
  }
}
.referral_section .quotes_section .quote_flex {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.referral_section .quotes_section .quote_flex img {
  border-right: 1px solid #000;
  padding-right: 10px;
}
.referral_section .quotes_section .quote_flex p {
  font-size: 18px;
  color: #092d2c;
  line-height: 28px;
  text-align: left;
}
.referral_section .lastsection {
  border-radius: 24px;
  padding: 40px 20px;
}
@media (max-width: 992px) {
  .referral_section .lastsection {
    margin: 0 20px;
  }
}

.about-us .about_banner {
  display: flex;
  align-items: center;
  gap: 45px;
  padding: 0 0 60px;
}
@media (max-width: 992px) {
  .about-us .about_banner {
    flex-direction: column;
    gap: 20px;
    padding: 20px 0;
  }
}
.about-us .common_title {
  color: #354545;
  font-weight: 600;
  margin: 70px 0;
  font-size: 56px;
  line-height: 62px;
  letter-spacing: -0.01em;
  font-family: "Basier Circle medium";
}
@media (max-width: 992px) {
  .about-us .common_title {
    margin: 40px auto 20px;
    font-size: 32px;
    line-height: 42px;
  }
}
.about-us .sec_title {
  font-size: 36px;
  line-height: 43px;
  font-weight: 600;
  color: #354545;
}
.about-us .about_content h2 {
  font-size: 24px;
  line-height: 36px;
  color: #354545;
  text-align: center;
  margin: 80px 0;
}
@media (max-width: 992px) {
  .about-us .about_content h2 {
    margin: 20px 0;
  }
}
.about-us .about_content h2 .selected_text {
  background-color: #33c1c0;
  color: #fff;
  font-weight: 400;
}
.about-us .guiding {
  background-color: #f6ffff;
}
.about-us .guiding .common_desc {
  font-size: 24px;
  line-height: 34px;
  color: #354545;
  max-width: 440px;
  font-weight: 600;
  margin: 10px 0 45px;
}
.about-us .guiding .about_banner {
  justify-content: space-between;
}
.about-us .guiding .about_banner .principles h3 {
  font-size: 24px;
  line-height: 34px;
  color: #33c1c0;
  font-weight: 600;
  padding: 0 0 8px;
}
.about-us .guiding .about_banner .principles p {
  font-size: 16px;
  line-height: 24px;
  color: #354545;
}
.about-us .our_story .about_banner {
  gap: 30px;
}
@media (max-width: 992px) {
  .about-us .our_story .about_banner {
    flex-direction: column;
    gap: 20px;
  }
}
.about-us .our_team {
  background-color: #fff9e9;
}
.about-us .our_team .aboutus_custom-row {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 50px;
}
@media (max-width: 992px) {
  .about-us .our_team .aboutus_custom-row {
    flex-direction: column;
    gap: 0px;
    justify-content: center;
  }
}
.about-us .our_team .aboutus_custom-row .aboutus_left img {
  margin: 0 0 30px auto;
  display: block;
}
@media (max-width: 992px) {
  .about-us .our_team .aboutus_custom-row .aboutus_left img {
    width: 100%;
  }
}
.about-us .our_team .aboutus_custom-row .aboutus_left .aboutus_text-box h2 {
  font-size: 36px;
  line-height: 43px;
  color: #354545;
  font-weight: 600;
}
.about-us .our_team .aboutus_custom-row .aboutus_left .aboutus_text-box p {
  font-size: 16px;
  line-height: 24px;
  color: #354545;
  padding: 15px 0;
}
@media (max-width: 992px) {
  .about-us .our_team .aboutus_custom-row .aboutus_right {
    width: 100%;
  }
}
.about-us .our_team .aboutus_custom-row .aboutus_right .theme-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 45px;
  border: none;
  color: #ffffff;
  background: #33c1c0;
  border-radius: 8px;
}
@media (max-width: 992px) {
  .about-us .our_team .aboutus_custom-row .aboutus_right .theme-btn {
    width: 100%;
  }
}
.about-us .our_team .aboutus_custom-row .aboutus_right .theme-btn img {
  vertical-align: middle;
}
.about-us .our_team .aboutus_custom-row .aboutus_right .react-reveal .lazyload-wrapper img {
  display: block;
  margin: 0 auto 30px 0;
}
@media (max-width: 992px) {
  .about-us .our_team .aboutus_custom-row .aboutus_right .react-reveal .lazyload-wrapper img {
    width: 100%;
  }
}
.about-us .reelo_news .reelonew_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 60px 0 0;
  flex-wrap: wrap;
}
.about-us .reelo_news .reelonew_wrapper .reeloNews {
  display: flex;
  align-items: center;
  justify-items: center;
}
@media (max-width: 992px) {
  .about-us .reelo_news .reelonew_wrapper .reeloNews {
    text-align: center;
    display: block;
    justify-items: unset;
    align-items: unset;
  }
}
.about-us .reelo_news .reelonew_wrapper .reeloNews .reelonews_icon img {
  filter: gray;
  filter: grayscale(1);
  transition: 0.2s ease-in;
}
.about-us .reelo_news .reelonew_wrapper .reeloNews .reelonews_icon img:hover {
  filter: none;
  transform: scale(1.2);
}

.pricing .sec-heading2 {
  font-family: "Basier Circle Semibold";
  color: #182020;
  margin: 40px 0;
}
.pricing .pricing_card-rows {
  display: flex;
  justify-content: center;
  gap: 25px;
}
@media (max-width: 992px) {
  .pricing .pricing_card-rows {
    display: block;
  }
}
.pricing .pricing_card-rows .cards {
  width: 370px;
  height: 100%;
  min-height: 380px;
  padding: 24px 32px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (max-width: 992px) {
  .pricing .pricing_card-rows .cards {
    width: 100%;
  }
}
.pricing .pricing_card-rows .cards .white-btn {
  color: #1A424F;
  border: 1px solid #1A424F;
  border-radius: 8px;
  background-color: transparent;
  width: 100%;
}
.pricing .pricing_card-rows .cards .theme-btn {
  width: 100%;
}
.pricing .pricing_card-rows .cards h3 {
  font-size: 24px;
  color: #354545;
  line-height: 30px;
  margin-bottom: 8px;
  font-weight: 700;
}
.pricing .pricing_card-rows .cards h4 {
  font-size: 48px;
  line-height: 67px;
  color: #182020;
  font-weight: 600;
}
.pricing .pricing_card-rows .cards h4 p {
  display: inline;
  font-size: 48px;
  line-height: 67px;
  color: #182020;
  font-weight: 400;
}
.pricing .pricing_card-rows .cards h4 span {
  font-size: 14px;
  line-height: 18px;
}
.pricing .pricing_card-rows .cards h6 {
  font-size: 16px;
  line-height: 30px;
  color: rgba(24, 32, 32, 0.75);
  font-weight: 400;
}
.pricing .pricing_card-rows .cards .pricing_desc2 {
  font-size: 14px;
  color: #1a424f;
  line-height: 28px;
  text-align: center;
}
.pricing .pricing_card-rows .cards .pricing_features {
  display: flex;
  margin: 24px 0;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}
.pricing .pricing_card-rows .pricing_plan-card {
  background: #f5f5f5;
}
.pricing .pricing_card-rows .pricing_plan-card__3SDlI {
  background: linear-gradient(157.35deg, #c4f2f4 14.72%, #9cffed 102.4%);
}
.pricing .pricing_term {
  font-size: 12px;
  line-height: 17px;
  text-align: right;
  color: #838c8c;
}
.pricing .pricebanner-fixed {
  position: fixed;
  width: 335px;
  top: 105px;
}
.pricing .pricing_compare-plans h3 {
  margin: 40px 0 16px;
}
.pricing .pricing_compare-plans .pricing_plans-head {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 24px 0 16px;
  border-bottom: 2px solid #ddd;
  background-color: #fff;
}
.pricing .pricing_compare-plans .pricing_plans-head .pricing_head-col {
  width: 50%;
}
.pricing .pricing_compare-plans .pricing_plans-head .pricing_head-col .pricing_span1 {
  font-size: 24px;
  line-height: 34px;
  font-weight: 600;
}
.pricing .pricing_compare-plans .pricing_plans-head .pricing_head-col .pricing_span2 {
  color: #616d6d;
  font-weight: 600;
}
.pricing .pricing_compare-plans .pricing_mobile-price-row {
  padding: 16px 0;
  border-bottom: 1px solid #ddd;
}
.pricing .pricing_compare-plans .pricing_mobile-price-row .pricing_question {
  cursor: pointer;
}
.pricing .pricing_compare-plans .pricing_mobile-price-row .pricing_question h3 {
  font-size: 16px;
  color: #000;
  line-height: 20px;
  margin: 0;
}
.pricing .pricing_compare-plans .pricing_mobile-price-row .pricing_question h3 img {
  width: 24px;
  padding-left: 12px;
}
.pricing .pricing_compare-plans .pricing_mobile-price-row .pricing_question .pricing_icon-row {
  padding-top: 8px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.pricing .pricing_compare-plans .pricing_mobile-price-row .pricing_question .pricing_icon-row div {
  width: 50%;
}
.pricing .pricing_compare-plans .pricing_mobile-price-row .pricing_dets-row {
  padding: 16px 0 4px;
}
.pricing .pricing_compare-plans .pricing_mobile-price-row .pricing_dets-row h5 {
  font-size: 14px;
  color: #182020;
  line-height: 17px;
  margin-bottom: 8px;
  font-family: "Basier Circle";
  font-weight: 400;
}
.pricing .pricing_compare-plans .pricing_mobile-price-row .pricing_dets-row .pricing_icon-row {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.pricing .pricing_compare-plans .pricing_mobile-price-row .pricing_dets-row .pricing_icon-row div {
  width: 50%;
}
.pricing .pricing-collasible-table {
  overflow-x: auto;
  padding: 0 60px;
  text-align: left;
}
.pricing .pricing-collasible-table table {
  margin: 0;
  width: 100%;
  border-collapse: collapse;
}
.pricing .pricing-collasible-table table thead tr {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.pricing .pricing-collasible-table table thead tr th {
  padding: 28px 0;
  font-size: 16px;
  line-height: 19px;
  font-family: "Basier Circle";
  color: #182020;
  font-size: 20px;
  line-height: 28px;
}
.pricing .pricing-collasible-table table thead tr th span {
  font-size: 12px;
  font-family: "Basier Circle";
}
.pricing .pricing-collasible-table table thead tr th .pricing_span1 {
  font-size: 40px;
  line-height: 56px;
  font-family: "Basier Circle";
}
.pricing .pricing-collasible-table table thead tr th .pricing_span2 {
  font-size: 16px;
  color: #182020;
  line-height: 22px;
  font-family: "Basier Circle";
}
.pricing .pricing-collasible-table table thead tr th:first-of-type {
  font-size: 24px;
  line-height: 30px;
  vertical-align: middle;
  font-family: "Basier Circle";
}
.pricing .pricing-collasible-table table thead tr th:first-child {
  min-width: 330px;
}
.pricing .pricing-collasible-table table tbody .pricing_feature-head {
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}
.pricing .pricing-collasible-table table tbody .pricing_feature-head td {
  padding: 19px 0 23px;
  font-size: 18px;
  line-height: 22px;
  color: #354545;
  font-family: "Basier Circle";
}
.pricing .pricing-collasible-table table tbody .pricing_feature-rows {
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}
.pricing .pricing-collasible-table table tbody .pricing_feature-rows td {
  font-size: 14px;
  line-height: 17px;
  color: #556969;
  padding: 14px 0 13px;
  min-width: 100px;
}
.pricing .pricing-collasible-table table tbody .pricing_feature-rows td .tt-img {
  cursor: pointer;
}
.pricing .pricing-collasible-table table tbody .pricing_feature-rows .tableFlex {
  display: flex;
  align-items: center;
}
.pricing .pricing_contact-banner {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 32px;
  margin: 40px 0;
  background: #ecfaff;
  border-radius: 15px;
}
@media (max-width: 992px) {
  .pricing .pricing_contact-banner {
    flex-direction: column;
    align-items: flex-start;
  }
}
.pricing .pricing_contact-banner .pricing_rightcontent {
  margin-left: 50px;
}
@media (max-width: 992px) {
  .pricing .pricing_contact-banner .pricing_rightcontent {
    margin-left: 0;
  }
}
.pricing .pricing_contact-banner .pricing_rightcontent h3 {
  font-size: 24px;
  line-height: 29px;
  color: #354545;
}
.pricing .pricing_contact-banner .pricing_rightcontent h5 {
  font-size: 14px;
  line-height: 21px;
  color: #354545;
  margin: 15px 0;
}
@media (max-width: 992px) {
  .pricing .pricing_contact-banner .pricing_rightcontent .theme-btn {
    width: 100%;
  }
}

.growth-stories .main_wrapper {
  background-color: #f8fbfb;
}
.growth-stories .display_table {
  flex-direction: unset;
}
.growth-stories .banner {
  height: 100vh;
}
@media (max-width: 992px) {
  .growth-stories .banner {
    height: unset;
    padding: 90px 0 0;
  }
  .growth-stories .banner .common_title {
    font-size: 28px;
    line-height: 32px;
  }
  .growth-stories .banner .common_desc {
    font-size: 14px;
    line-height: 22px;
  }
}
.growth-stories .banner .common_subtitle {
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.18em;
  text-transform: uppercase;
  font-family: "Basier Circle Semibold";
  padding: 0 0 20px;
}
.growth-stories .banner .loyaly_div .growth-stories_right_side {
  min-width: 500px;
  padding: 24px 32px;
  border-radius: 16px;
  background: #0d9298;
}
@media (max-width: 992px) {
  .growth-stories .banner .loyaly_div .growth-stories_right_side {
    min-width: 100%;
    padding: 20px;
    margin-bottom: 40px;
  }
}
.growth-stories .banner .loyaly_div .growth-stories_right_side h3 {
  font-size: 24px;
  margin: 0 0 20px;
  line-height: 30px;
  letter-spacing: -0.01em;
  font-family: "Basier Circle Semibold";
}
@media (max-width: 992px) {
  .growth-stories .banner .loyaly_div .growth-stories_right_side h3 {
    font-size: 14px;
    margin: 0 0 12px;
    line-height: 20px;
  }
}
.growth-stories .banner .loyaly_div .growth-stories_right_side .growth-stories_points {
  gap: 12px;
  margin: 0 0 20px;
  display: flex;
  align-items: center;
}
.growth-stories .banner .loyaly_div .growth-stories_right_side .growth-stories_points .growthimg {
  min-height: 32px;
  min-width: 32px;
}
@media (max-width: 992px) {
  .growth-stories .banner .loyaly_div .growth-stories_right_side .growth-stories_points .growthimg {
    min-height: 20px;
    min-width: 20px;
  }
}
.growth-stories .banner .loyaly_div .growth-stories_right_side .growth-stories_points p {
  font-size: 20px;
  max-width: 465px;
  line-height: 26px;
  letter-spacing: -0.01em;
}
@media (max-width: 992px) {
  .growth-stories .banner .loyaly_div .growth-stories_right_side .growth-stories_points p {
    max-width: unset;
    font-size: 12px;
    line-height: 16px;
  }
}
.growth-stories .sec_title {
  font-size: 48px;
  line-height: 60px;
  letter-spacing: -0.02em;
  font-family: "Basier Circle Semibold";
}
@media (max-width: 992px) {
  .growth-stories .sec_title {
    font-size: 24px;
    line-height: 30px;
  }
}
.growth-stories .sec-heading {
  margin: 28px 0 12px;
}
@media (max-width: 992px) {
  .growth-stories .sec-heading {
    font-size: 16px;
    line-height: 22px;
  }
}
@media (max-width: 992px) {
  .growth-stories p.sec-heading {
    font-size: 14px;
    line-height: 20px;
    margin: 0;
  }
}
.growth-stories .common_title {
  font-size: 56px;
  margin: 0 0 16px;
  line-height: 62px;
  font-family: "Basier Circle medium";
}
.growth-stories .common_desc {
  font-size: 20px;
  margin: 0 0 40px;
  line-height: 30px;
  font-family: "Basier Circle";
}
.growth-stories .reelo_growthstories .blogBox {
  padding: 0 20px 30px;
}
@media (max-width: 992px) {
  .growth-stories .reelo_growthstories .blogBox {
    padding: 0 0 30px;
  }
}
.growth-stories .reelo_growthstories .blogBox .webinar-box-wrapper .webinar-box .webinar_Img iframe {
  height: 345px;
  outline: none;
  border: none;
}
@media (max-width: 992px) {
  .growth-stories .reelo_growthstories .blogBox .webinar-box-wrapper .webinar-box .webinar_Img iframe {
    height: 195px;
  }
}
.growth-stories .featured_brand {
  background-color: #f8fbfb;
}
.growth-stories .featured_brand .featured_wrapper {
  gap: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 992px) {
  .growth-stories .featured_brand .featured_wrapper {
    flex-wrap: wrap;
    gap: 16px;
  }
  .growth-stories .featured_brand .featured_wrapper img {
    width: 94px;
  }
}
.growth-stories .lastsection {
  border-radius: 24px;
  padding: 40px 20px;
}
@media (max-width: 992px) {
  .growth-stories .lastsection {
    margin: 0 20px;
  }
}

@media (max-width: 992px) {
  .press_release .pr1, .press_release .pr2 {
    width: 100px;
  }
}
.press_release .banner {
  background: #f5ffff;
  height: 70vh;
}
.press_release .banner .tagline_wrapper {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
.press_release .banner .tagline_wrapper .common_subtitle {
  margin: 0 0 8px;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.36px;
  font-family: "Basier Circle medium";
}
@media (max-width: 992px) {
  .press_release .banner .tagline_wrapper .common_subtitle {
    font-size: 16px;
    line-height: 20px;
  }
}
.press_release .banner .tagline_wrapper .common_title {
  font-size: 60px;
  margin: 0 0 18px;
  color: #0e1b1b;
  line-height: 64px;
  text-align: center;
  letter-spacing: -1.8px;
  font-family: "Basier Circle Semibold";
}
@media (max-width: 992px) {
  .press_release .banner .tagline_wrapper .common_title {
    font-size: 36px;
    line-height: 40px;
    letter-spacing: -1px;
  }
}
.press_release .banner .tagline_wrapper .common_desc {
  font-size: 24px;
  margin: 0 0 48px;
  color: #0e1b1b;
  line-height: 30px;
  text-align: center;
  letter-spacing: -0.72px;
  font-family: "Basier Circle medium";
}
@media (max-width: 992px) {
  .press_release .banner .tagline_wrapper .common_desc {
    font-size: 20px;
    line-height: 24px;
    margin: 0 auto 24px;
    letter-spacing: -0.52px;
  }
}
.press_release .sec_title {
  font-size: 42px;
  color: #0e1b1b;
  line-height: 48px;
  text-align: center;
  margin: 0 auto 48px;
  letter-spacing: -0.84px;
  font-weight: 500;
  font-family: "Basier Circle medium";
}
@media (max-width: 992px) {
  .press_release .sec_title {
    font-size: 28px;
    line-height: 32px;
  }
}
.press_release .p_wrapper a .press-box {
  padding: 0 20px;
}
@media (max-width: 992px) {
  .press_release .p_wrapper a .press-box {
    padding: 0;
  }
}
.press_release .p_wrapper a .press-box .press_Img img {
  border-radius: 16px;
}
.press_release .p_wrapper a .p_content {
  padding: 75px 0 0 20px;
}
@media (max-width: 992px) {
  .press_release .p_wrapper a .p_content {
    padding: 0;
  }
}
.press_release .p_wrapper a .sec-heading {
  font-size: 28px;
  margin: 28px 0 0;
  line-height: 34px;
  letter-spacing: -0.84px;
  font-family: "Basier Circle medium";
  font-weight: 500;
}
@media (max-width: 992px) {
  .press_release .p_wrapper a .sec-heading {
    font-size: 20px;
    margin: 16px 0 0;
    line-height: 24px;
    letter-spacing: -0.4px;
  }
}
.press_release .p_wrapper a:hover .sec-heading {
  color: #0d9298;
}
.press_release .reelo_pressRelease .press-box {
  padding: 20px;
  height: 470px;
}
@media (max-width: 992px) {
  .press_release .reelo_pressRelease .press-box {
    padding: 20px 0;
    height: unset;
  }
}
.press_release .reelo_pressRelease .press-box a .press_Img img {
  border-radius: 16px;
}
.press_release .reelo_pressRelease .press-box a .sec-heading {
  font-size: 28px;
  margin: 28px 0 0;
  line-height: 34px;
  letter-spacing: -0.84px;
  font-family: "Basier Circle medium";
  font-weight: 500;
}
@media (max-width: 992px) {
  .press_release .reelo_pressRelease .press-box a .sec-heading {
    font-size: 20px;
    margin: 16px 0 0;
    line-height: 24px;
    letter-spacing: -0.4px;
  }
}
.press_release .reelo_pressRelease .press-box:hover .sec-heading {
  color: #0d9298;
}
.press_release .lastsection {
  border-radius: 24px;
  padding: 40px 20px;
}
@media (max-width: 992px) {
  .press_release .lastsection {
    margin: 0 20px;
  }
}

@media (max-width: 992px) {
  .fresources_listing .category_frslider {
    display: flex;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    width: 100%;
    white-space: nowrap;
    scrollbar-width: thin;
    padding: 20px 0;
  }
}
@media (max-width: 992px) {
  .fresources_listing .category_frslider .category_frslider::-webkit-scrollbar {
    display: none;
  }
}
.fresources_listing .category_frslider li a {
  font-size: 16px;
}
.fresources_listing .latest_fr {
  margin: 60px 0 0;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  height: 499px;
}
@media (max-width: 992px) {
  .fresources_listing .latest_fr {
    flex-wrap: wrap;
    margin: 30px 0 0;
    height: unset;
    text-align: center;
  }
}
.fresources_listing .latest_fr .latest_frContent {
  max-width: 570px;
  margin: 0 auto 0 40px;
}
@media (max-width: 992px) {
  .fresources_listing .latest_fr .latest_frContent {
    max-width: 100%;
    padding: 20px;
    margin: 0;
  }
}
.fresources_listing .latest_fr .latest_frContent .latest_caption {
  color: #5F4D07;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  background: #EBBD07;
  padding: 8px 20px;
  border-radius: 90px;
}
@media (max-width: 992px) {
  .fresources_listing .latest_fr .latest_frContent .latest_caption {
    font-size: 14px;
    padding: 6px 15px;
  }
}
@media (max-width: 992px) {
  .fresources_listing .latest_fr .latest_frContent .sec_title {
    font-size: 24px;
    line-height: 28px;
  }
}
.fresources_listing .latest_fr .latest_frContent .sec_title .highlight {
  color: #ebbd07;
}
.fresources_listing .latest_fr .latest_frContent .frshort_description {
  padding: 20px 0;
}
.fresources_listing .latest_fr .latest_frImg {
  margin: 0;
}
.fresources_listing .latest_fr .latest_frImg .fr_latestImg img {
  min-height: 494px;
  position: relative;
  top: 2.5px;
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
}
@media (max-width: 992px) {
  .fresources_listing .latest_fr .latest_frImg .fr_latestImg img {
    border-top-right-radius: 0px;
    border-bottom-left-radius: 24px;
    min-height: unset;
    top: 6.5px;
  }
}
@media (max-width: 992px) {
  .fresources_listing .latest_fr .latest_frImg {
    max-width: 100%;
  }
}
@media (max-width: 992px) {
  .fresources_listing .r-center .sec-title {
    font-size: 28px;
    line-height: 32px;
  }
}
.fresources_listing .resource_fold {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0 40px;
}
@media (max-width: 992px) {
  .fresources_listing .resource_fold {
    display: block;
  }
}
.fresources_listing .resource_fold .resource_left_fold {
  width: 410px;
}
@media (max-width: 992px) {
  .fresources_listing .resource_fold .resource_left_fold {
    width: 100%;
  }
}
@media (max-width: 992px) {
  .fresources_listing .resource_fold .resource_right_fold {
    padding: 30px 0 0;
  }
}
.fresources_listing .fresources_wrapper .fresources_blogs .fresources {
  padding: 10px;
}
.fresources_listing .fresources_wrapper .fresources_blogs .fresources .fresources-box-wrapper .fresources-box {
  border-radius: 11px;
  height: 490px;
}
@media (max-width: 992px) {
  .fresources_listing .fresources_wrapper .fresources_blogs .fresources .fresources-box-wrapper .fresources-box {
    height: unset;
  }
}
.fresources_listing .fresources_wrapper .fresources_blogs .fresources .fresources-box-wrapper .fresources-box .fresources_Img .img-bg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 294px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
@media (max-width: 992px) {
  .fresources_listing .fresources_wrapper .fresources_blogs .fresources .fresources-box-wrapper .fresources-box .fresources_Img .img-bg {
    min-height: 180px;
  }
}
.fresources_listing .fresources_wrapper .fresources_blogs .fresources .fresources-box-wrapper .fresources-box .fresources-content {
  padding: 24px 0;
}
.fresources_listing .fresources_wrapper .fresources_blogs .fresources .fresources-box-wrapper .fresources-box .fresources-content .fcategory {
  font-size: 14px;
}
.fresources_listing .fresources_wrapper .fresources_blogs .fresources .fresources-box-wrapper .fresources-box .fresources-content .fresources-title {
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.64px;
  padding: 6px 0 15px;
  height: 74px;
}
@media (max-width: 992px) {
  .fresources_listing .fresources_wrapper .fresources_blogs .fresources .fresources-box-wrapper .fresources-box .fresources-content .fresources-title {
    font-size: 22px;
    line-height: 32px;
  }
}
.fresources_listing .fresources_wrapper .fresources_blogs .fresources .fresources-box-wrapper .fresources-box .fresources-content .f-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.fresources_listing .fresources_wrapper .fresources_blogs .fresources .fresources-box-wrapper .fresources-box .fresources-content .f-btn .pdf_text {
  color: #638A8A;
  font-size: 14px;
  font-weight: 600;
  line-height: 17.5px;
  border-radius: 90px;
  padding: 8px 20px;
  background: #A6DBDB;
  background-blend-mode: multiply;
}
.fresources_listing .fresources_wrapper .fresources_blogs .fresources .fresources-box-wrapper .fresources-box .fresources-content .f-btn .dwn_text {
  color: #252525;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: -0.323px;
  text-decoration: underline;
  font-family: "Basier Circle medium";
}
.fresources_listing .lastsection {
  border-radius: 24px;
  padding: 40px 20px;
}
@media (max-width: 992px) {
  .fresources_listing .lastsection {
    margin: 0 20px;
  }
}

.free_resources .box-bg {
  background-image: url("../images/box.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.free_resources .banner .tagline_wrapper {
  position: absolute;
  top: 54%;
  transform: translate(-50%, -50%);
  left: 50%;
  width: 880px;
}
@media (max-width: 992px) {
  .free_resources .banner .tagline_wrapper {
    width: 100%;
    padding: 0 15px;
  }
}
.free_resources .banner .tagline_wrapper .resource_title {
  font-size: 56px;
  margin: 0 0 16px;
  line-height: 62px;
  letter-spacing: -0.01em;
  font-family: "Basier Circle medium";
}
@media (max-width: 992px) {
  .free_resources .banner .tagline_wrapper .resource_title {
    font-size: 28px;
    line-height: 40px;
  }
}
.free_resources .banner .tagline_wrapper .resource_desc {
  font-size: 20px;
  color: #092d2c;
  line-height: 32px;
  margin: 0 auto 48px;
}
@media (max-width: 992px) {
  .free_resources .banner .tagline_wrapper .resource_desc {
    font-size: 16px;
    line-height: 24px;
  }
}
.free_resources .banner .marketingCalendar_tag {
  font-size: 18px;
  line-height: 24px;
  padding: 8px 20px;
  position: absolute;
  border-radius: 28px;
  font-family: "Basier Circle";
}
@media (max-width: 992px) {
  .free_resources .banner .marketingCalendar_tag {
    display: none;
  }
}
.free_resources .banner .marketingCalendar_tag-1 {
  top: 45%;
  left: 6%;
  background: #ffedd9;
  transform: rotate(-35.74deg);
}
.free_resources .banner .marketingCalendar_tag-2 {
  top: 53%;
  right: 6%;
  background: #fff3d3;
  transform: rotate(31.82deg);
}
.free_resources .banner .marketingCalendar_tag-3 {
  bottom: 26%;
  left: 12%;
  background: #f1f3ff;
  transform: rotate(-14.83deg);
}
.free_resources .banner .marketingCalendar_tag-4 {
  bottom: 20%;
  right: 12%;
  background: #ffe3e5;
  transform: rotate(15.52deg);
}
.free_resources .banner .marketing_fold {
  gap: 42px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 60px 0;
}
@media (max-width: 992px) {
  .free_resources .banner .marketing_fold {
    gap: 24px;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    padding: 30px 0 0;
    text-align: center;
  }
}
.free_resources .banner .marketing_fold .marketing_fold_left {
  max-width: 669px;
}
@media (max-width: 1024px) {
  .free_resources .banner .marketing_fold .marketing_fold_left {
    max-width: 550px;
  }
}
@media (max-width: 767px) {
  .free_resources .banner .marketing_fold .marketing_fold_left {
    max-width: 100%;
  }
}
.free_resources .banner .marketing_fold .marketing_fold_left .resource_subtitle {
  font-size: 16px;
  color: #0d9298;
  line-height: 21px;
  letter-spacing: 0.18em;
  text-transform: uppercase;
}
@media (max-width: 992px) {
  .free_resources .banner .marketing_fold .marketing_fold_left .resource_subtitle {
    font-size: 12px;
    line-height: 21px;
  }
}
.free_resources .banner .marketing_fold .marketing_fold_left .resource_title {
  font-size: 56px;
  color: #0e1b1b;
  line-height: 64px;
  margin: 16px 0 20px;
  letter-spacing: -0.01em;
  font-weight: 900;
  font-family: "Basier Circle medium";
}
@media (max-width: 992px) {
  .free_resources .banner .marketing_fold .marketing_fold_left .resource_title {
    font-size: 28px;
    line-height: 40px;
  }
}
.free_resources .banner .marketing_fold .marketing_fold_left .resource_desc {
  font-size: 20px;
  margin: 0 0 40px;
  color: #374040;
  line-height: 28px;
  font-family: "Basier Circle";
}
@media (max-width: 992px) {
  .free_resources .banner .marketing_fold .marketing_fold_left .resource_desc {
    font-size: 16px;
    line-height: 24px;
  }
}
.free_resources .banner .marketing_fold .marketing_fold_right {
  max-width: 440px;
}
.free_resources .banner .marketing_fold .marketing_fold_right img {
  border-radius: 24px;
}
@media (max-width: 992px) {
  .free_resources .banner .marketing_fold .marketing_fold_right {
    max-width: 100%;
  }
}
.free_resources .resource1 .resource_title {
  font-size: 42px;
  line-height: 60px;
  font-family: "Basier Circle medium";
}
@media (max-width: 992px) {
  .free_resources .resource1 .resource_title {
    font-size: 24px;
    line-height: 32px;
  }
}
.free_resources .resource1 .resource_desc p {
  font-size: 20px;
  line-height: 32px;
  margin: 16px 0;
}
@media (max-width: 992px) {
  .free_resources .resource1 .resource_desc p {
    font-size: 16px;
    line-height: 24px;
  }
}
.free_resources .resource1 .resource_desc p span {
  font-size: 16px;
  line-height: 20px;
}
.free_resources .resource1 .resource_desc ul {
  padding-left: 20px;
}
.free_resources .resource1 .resource_desc ul li {
  font-size: 20px;
  color: #fff;
  line-height: 32px;
  padding: 0 0 10px;
}
@media (max-width: 992px) {
  .free_resources .resource1 .resource_desc ul li {
    font-size: 16px;
    line-height: 24px;
  }
}
.free_resources .resource1 .resources_wrapper {
  gap: 104px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 992px) {
  .free_resources .resource1 .resources_wrapper {
    gap: 24px;
    flex-wrap: wrap;
  }
}
.free_resources .resource1 .resources_wrapper .resources_left {
  max-width: 627px;
}
@media (max-width: 992px) {
  .free_resources .resource1 .resources_wrapper .resources_left {
    max-width: 100%;
  }
}
@media (max-width: 992px) {
  .free_resources .resource1 .resources_wrapper .resources_left .resource_desc p {
    font-size: 16px;
    line-height: 24px;
  }
}
.free_resources .resource1 .resources_wrapper .resources_right {
  min-width: 360px;
}
@media (max-width: 992px) {
  .free_resources .resource1 .resources_wrapper .resources_right {
    min-width: 100%;
  }
}
.free_resources .resources_brand .resource_title {
  font-size: 30px;
  margin: 0 0 32px;
  color: #182020;
  line-height: 38px;
  font-weight: 600;
}
@media (max-width: 992px) {
  .free_resources .resources_brand .resource_title {
    font-size: 24px;
    line-height: 32px;
  }
}
.free_resources .resources_brand .marketingPlaybook_brands {
  gap: 56px;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 992px) {
  .free_resources .resources_brand .marketingPlaybook_brands {
    gap: 24px;
  }
  .free_resources .resources_brand .marketingPlaybook_brands img {
    width: 88px;
  }
}
.free_resources .resources_download .resource_title {
  font-size: 42px;
  color: #092d2c;
  line-height: 60px;
  text-align: center;
  margin: 0 auto 40px;
}
@media (max-width: 992px) {
  .free_resources .resources_download .resource_title {
    font-size: 24px;
    line-height: 32px;
  }
}
.free_resources .resources_download .download_my_resource {
  margin: 0 auto;
  max-width: 800px;
}
@media (max-width: 992px) {
  .free_resources .resources_download .download_my_resource {
    max-width: 100%;
  }
}
.free_resources .resources_download .marketingCalendar_tag {
  font-size: 18px;
  line-height: 24px;
  padding: 8px 20px;
  position: absolute;
  border-radius: 28px;
  font-family: "Basier Circle";
}
@media (max-width: 992px) {
  .free_resources .resources_download .marketingCalendar_tag {
    display: none;
  }
}
.free_resources .resources_download .marketingCalendar_tag-1 {
  top: 6%;
  left: 15%;
  background: #f9ffd6;
  transform: rotate(19.05deg);
}
.free_resources .resources_download .marketingCalendar_tag-2 {
  top: 6%;
  right: 12%;
  background: #fff3d3;
  transform: rotate(31.82deg);
}
.free_resources .resources_download .marketingCalendar_tag-3 {
  top: 34%;
  left: 9%;
  background: #ecfff9;
  transform: rotate(-24.29deg);
}
.free_resources .resources_download .marketingCalendar_tag-4 {
  top: 34%;
  right: 12%;
  background: #ffe3e5;
  transform: rotate(15.52deg);
}
.free_resources .resources_download .marketingCalendar_tag-5 {
  left: 24%;
  bottom: 20%;
  background: #e4ecff;
  transform: rotate(15.52deg);
}
.free_resources .resources_download .marketingCalendar_tag-6 {
  right: 15%;
  bottom: 20%;
  background: #ffe8f7;
  transform: rotate(15.52deg);
}

.report_resources .banner .bell {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
}
@media (max-width: 992px) {
  .report_resources .banner .bell {
    position: unset;
    transform: unset;
    margin: -34px 0 0;
  }
}
.report_resources .banner .marketing_fold {
  gap: 42px;
  display: flex;
  justify-content: space-between;
  padding: 40px 0 0;
}
@media (max-width: 992px) {
  .report_resources .banner .marketing_fold {
    gap: 24px;
    flex-wrap: wrap;
  }
}
.report_resources .banner .marketing_fold .marketing_fold_left {
  max-width: 669px;
  padding: 40px 0 0;
}
@media (max-width: 1024px) {
  .report_resources .banner .marketing_fold .marketing_fold_left {
    max-width: 550px;
  }
}
@media (max-width: 767px) {
  .report_resources .banner .marketing_fold .marketing_fold_left {
    max-width: 100%;
  }
}
.report_resources .banner .marketing_fold .marketing_fold_left .resource_subtitle {
  color: #0E1B1B;
  font-family: "Area Variable";
  font-size: 33.522px;
  font-style: normal;
  font-weight: 800;
  line-height: 40.705px;
  padding-top: 8px;
  letter-spacing: -0.335px;
  border-bottom: 2px solid rgba(1, 153, 159, 0.5882352941);
  display: inline-block;
}
@media (max-width: 992px) {
  .report_resources .banner .marketing_fold .marketing_fold_left .resource_subtitle {
    padding-top: 0;
    font-size: 20px;
    letter-spacing: -0.2px;
  }
}
.report_resources .banner .marketing_fold .marketing_fold_left .resource_title {
  color: #01999F;
  font-family: "Area Variable";
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
  line-height: 121%;
  letter-spacing: -0.56px;
}
@media (max-width: 992px) {
  .report_resources .banner .marketing_fold .marketing_fold_left .resource_title {
    font-size: 36px;
    letter-spacing: -0.36px;
  }
}
.report_resources .banner .marketing_fold .marketing_fold_left .resource_desc {
  color: #374040;
  font-family: "Area Variable";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  padding-top: 30px;
}
@media (max-width: 992px) {
  .report_resources .banner .marketing_fold .marketing_fold_left .resource_desc {
    font-size: 16px;
    padding-top: 20px;
  }
}
.report_resources .banner .marketing_fold .marketing_fold_right {
  min-width: 440px;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: -4px 8px 11px 0px rgba(0, 45, 50, 0.1);
  backdrop-filter: blur(18.1578960419px);
  padding: 32px;
  position: relative;
  z-index: 3;
}
.report_resources .banner .marketing_fold .marketing_fold_right .form_title {
  color: #023342;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 116.667% */
  letter-spacing: -0.48px;
  padding-bottom: 26px;
}
@media (max-width: 992px) {
  .report_resources .banner .marketing_fold .marketing_fold_right .form_title {
    color: #023342;
    font-size: 20.29px;
    font-style: normal;
    line-height: 23.671px; /* 116.667% */
    letter-spacing: -0.406px;
  }
}
@media (max-width: 992px) {
  .report_resources .banner .marketing_fold .marketing_fold_right {
    min-width: 100%;
    padding: 30px 20px;
  }
}
.report_resources .report-svg {
  position: absolute;
  top: -40px;
  left: 0;
  z-index: -2;
  width: 100%;
  height: 2800px;
}
@media (max-width: 1024px) {
  .report_resources .report-svg {
    top: -171px;
    height: 1900px;
  }
}
@media (max-width: 767px) {
  .report_resources .report-svg {
    top: -171px;
    height: 2400px;
  }
}
.report_resources .gradient-bg {
  margin: -250px 0 0;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg {
    margin: 0;
  }
}
.report_resources .gradient-bg .reportFold {
  margin: 280px 0 0;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold {
    margin: 0;
  }
}
.report_resources .gradient-bg .reportFold .report_title {
  color: #023342;
  font-family: "Area Variable";
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: 121%; /* 121.429% */
  letter-spacing: -0.56px;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold .report_title {
    font-size: 20px;
    letter-spacing: -0.2px;
  }
}
.report_resources .gradient-bg .reportFold .report_title span {
  font-family: "Area Variable";
  font-size: 96px;
  font-style: normal;
  font-weight: 900;
  line-height: 120%;
  letter-spacing: -0.96px;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold .report_title span {
    font-size: 30px;
    letter-spacing: -0.3px;
  }
}
.report_resources .gradient-bg .reportFold .ame-tabs-wrapper {
  padding: 25px 0 50px;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold .ame-tabs-wrapper {
    padding: 25px 0 30px;
    display: flex;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    width: 100%;
    white-space: nowrap;
    scrollbar-width: thin;
    position: relative;
    z-index: 1;
  }
}
.report_resources .gradient-bg .reportFold .ame-tabs-wrapper a {
  color: #fff;
  font-family: "Area Variable";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  border-radius: 90px;
  padding: 13px 20px 8px;
  margin: 0 20px 0 0;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: -4px 8px 11px 0px rgba(0, 45, 50, 0.1);
  backdrop-filter: blur(18.1578960419px);
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold .ame-tabs-wrapper a {
    font-size: 12px;
    padding: 7px 12px 5px;
    margin: 0 12px 0 0;
  }
}
.report_resources .gradient-bg .reportFold .ame-tabs-wrapper a.active {
  border-radius: 90px;
  box-shadow: -4px 8px 11px 0px rgba(0, 45, 50, 0.1);
  backdrop-filter: blur(18.1578960419px);
}
.report_resources .gradient-bg .reportFold .ame-tabs-wrapper a.active.activeTab1 {
  background: linear-gradient(31deg, #001055 12.54%, #286DF0 86.69%);
}
.report_resources .gradient-bg .reportFold .ame-tabs-wrapper a.active.activeTab2 {
  background: radial-gradient(79.93% 79.93% at 87.12% 41.59%, #957DFB 0%, #5237E6 100%);
}
.report_resources .gradient-bg .reportFold .ame-tabs-wrapper a.active.activeTab3 {
  background: radial-gradient(79.93% 79.93% at 87.12% 41.59%, #FFB02C 0%, #B66504 100%);
}
.report_resources .gradient-bg .reportFold .ame-tabs-wrapper a.active.activeTab4 {
  background: radial-gradient(79.93% 79.93% at 87.12% 41.59%, #EF4866 0%, #8B0010 100%), rgba(255, 255, 255, 0.1);
}
.report_resources .gradient-bg .reportFold .ame-tabs-wrapper a.active.activeTab5 {
  background: radial-gradient(79.93% 79.93% at 87.12% 41.59%, #079B15 0%, #0D5614 100%);
}
.report_resources .gradient-bg .reportFold .ame-wrap .slider-products .item {
  padding: 70px;
  border-radius: 40px;
  height: 560px !important;
}
@media (max-width: 1024px) {
  .report_resources .gradient-bg .reportFold .ame-wrap .slider-products .item {
    padding: 18px;
    border-radius: 12px;
    height: 250px !important;
  }
}
@media (max-width: 767px) {
  .report_resources .gradient-bg .reportFold .ame-wrap .slider-products .item {
    padding: 18px;
    border-radius: 12px;
    height: 180px !important;
  }
}
.report_resources .gradient-bg .reportFold .ame-wrap .slider-products .slick-dots {
  display: flex;
  justify-content: center;
  margin: 0;
  list-style-type: none;
  align-items: center;
  position: absolute;
  right: -45%;
  top: 50%;
  transform: translateY(-50%) rotate(90deg);
  padding: 0;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold .ame-wrap .slider-products .slick-dots {
    right: -40%;
  }
}
.report_resources .gradient-bg .reportFold .ame-wrap .slider-products .slick-dots button {
  display: block;
  width: 15px;
  height: 15px;
  padding: 0;
  background-color: #fff;
  border: 1px solid #fff !important;
  text-indent: -9999px;
  cursor: pointer;
  border-radius: 30px;
  border: unset;
  position: relative;
  margin: 0 8px;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold .ame-wrap .slider-products .slick-dots button {
    width: 8px;
    height: 8px;
  }
}
.report_resources .gradient-bg .reportFold .ame-wrap .slider-products li.slick-active button {
  width: 19px;
  height: 19px;
  background-color: #fff;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold .ame-wrap .slider-products li.slick-active button {
    width: 12px;
    height: 12px;
  }
}
.report_resources .gradient-bg .reportFold .ame-wrap .slider-products li.slick-active button:before {
  position: absolute;
  content: "";
  width: 27px;
  height: 27px;
  border-radius: 100%;
  border: 1px solid #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold .ame-wrap .slider-products li.slick-active button:before {
    width: 18px;
    height: 19px;
  }
}
.report_resources .gradient-bg .reportFold .ame-wrap .toptrend_slider .slides1 {
  background: #C5D9FF;
}
.report_resources .gradient-bg .reportFold .ame-wrap .toptrend_slider .slides2 {
  background: #3B72E2;
}
.report_resources .gradient-bg .reportFold .ame-wrap .toptrend_slider .slides2.top_trend .trend_title {
  color: #C5D9FF;
}
.report_resources .gradient-bg .reportFold .ame-wrap .toptrend_slider .top_trend .trend_title {
  color: #2058CB;
  font-family: "Area Variable";
  font-size: 70px;
  font-style: normal;
  font-weight: 900;
  line-height: 120%;
  letter-spacing: -0.7px;
  padding-bottom: 60px;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold .ame-wrap .toptrend_slider .top_trend .trend_title {
    font-size: 18px;
    letter-spacing: -0.22px;
    padding-bottom: 20px;
  }
}
.report_resources .gradient-bg .reportFold .ame-wrap .toptrend_slider .top_trend .trend_div .trend_point {
  display: flex;
  align-items: center;
  gap: 20px;
  padding-bottom: 50px;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold .ame-wrap .toptrend_slider .top_trend .trend_div .trend_point {
    padding-bottom: 15px;
  }
}
.report_resources .gradient-bg .reportFold .ame-wrap .toptrend_slider .top_trend .trend_div .trend_point .trend_name {
  color: #011055;
  font-size: 40px;
  font-style: normal;
  font-weight: 900;
  line-height: 120%; /* 48px */
  text-transform: uppercase;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold .ame-wrap .toptrend_slider .top_trend .trend_div .trend_point .trend_name {
    font-size: 12px;
  }
}
.report_resources .gradient-bg .reportFold .ame-wrap .toptrend_slider .top_trend .trend_div .trend_point .trend_desc {
  font-family: "Area Variable";
  font-size: 50px;
  font-style: normal;
  font-weight: 900;
  line-height: 120%;
  background: linear-gradient(31deg, #001055 12.54%, #286DF0 86.69%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold .ame-wrap .toptrend_slider .top_trend .trend_div .trend_point .trend_desc {
    font-size: 16px;
  }
}
.report_resources .gradient-bg .reportFold .ame-wrap .marketing_slider .slides1 {
  background: #DFD8FF;
}
.report_resources .gradient-bg .reportFold .ame-wrap .marketing_slider .slides2 {
  background: #957DFB;
  padding: 30px 70px !important;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold .ame-wrap .marketing_slider .slides2 {
    padding: 15px !important;
  }
}
.report_resources .gradient-bg .reportFold .ame-wrap .marketing_slider .percent {
  font-family: "Area Variable";
  font-size: 210px;
  font-style: normal;
  font-weight: 900;
  line-height: 110%;
  letter-spacing: -4.2px;
  padding-top: 20px;
  background: radial-gradient(79.93% 79.93% at 87.12% 41.59%, #957DFB 0%, #5237E6 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold .ame-wrap .marketing_slider .percent {
    font-size: 64px;
    letter-spacing: -1.289px;
    padding-top: 0;
  }
}
.report_resources .gradient-bg .reportFold .ame-wrap .marketing_slider .percent_desc {
  color: #957DFB;
  font-family: "Area Variable";
  font-size: 70px;
  font-style: normal;
  font-weight: 900;
  line-height: 120%;
  letter-spacing: -0.7px;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold .ame-wrap .marketing_slider .percent_desc {
    font-size: 21px;
    letter-spacing: -0.215px;
  }
}
.report_resources .gradient-bg .reportFold .ame-wrap .marketing_slider .marketingFlex {
  display: flex;
  align-items: flex-start;
  gap: 200px;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold .ame-wrap .marketing_slider .marketingFlex {
    gap: 42px;
  }
}
.report_resources .gradient-bg .reportFold .ame-wrap .marketing_slider .marketingFlex .marketing1 {
  padding: 20px 0 0;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold .ame-wrap .marketing_slider .marketingFlex .marketing1 {
    padding: 0;
  }
}
.report_resources .gradient-bg .reportFold .ame-wrap .marketing_slider .marketingFlex .marketing1 .marketing_title {
  color: #5237E6;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.4px;
  padding-bottom: 20px;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold .ame-wrap .marketing_slider .marketingFlex .marketing1 .marketing_title {
    font-size: 12px;
    letter-spacing: -0.123px;
  }
}
.report_resources .gradient-bg .reportFold .ame-wrap .marketing_slider .marketingFlex .marketing1 .marketing_desc {
  color: #DFD8FF;
  font-size: 70px;
  font-style: normal;
  font-weight: 900;
  line-height: 110%;
  letter-spacing: -0.7px;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold .ame-wrap .marketing_slider .marketingFlex .marketing1 .marketing_desc {
    font-size: 21px;
    letter-spacing: -0.215px;
  }
}
.report_resources .gradient-bg .reportFold .ame-wrap .marketing_slider .marketingFlex .marketing2 img {
  width: 420px;
}
@media (max-width: 1024px) {
  .report_resources .gradient-bg .reportFold .ame-wrap .marketing_slider .marketingFlex .marketing2 img {
    width: 190px;
  }
}
@media (max-width: 767px) {
  .report_resources .gradient-bg .reportFold .ame-wrap .marketing_slider .marketingFlex .marketing2 img {
    width: unset;
  }
}
.report_resources .gradient-bg .reportFold .ame-wrap .technology_slider .slides1 {
  padding: 100px 70px 70px;
  background: #FFD48D;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold .ame-wrap .technology_slider .slides1 {
    padding: 30px 15px 15px;
  }
}
.report_resources .gradient-bg .reportFold .ame-wrap .technology_slider .slides1 .technology1 {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  gap: 70px;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold .ame-wrap .technology_slider .slides1 .technology1 {
    gap: 20px;
  }
}
.report_resources .gradient-bg .reportFold .ame-wrap .technology_slider .slides1 .technology1 .tech_number {
  font-size: 210px;
  font-style: normal;
  font-weight: 900;
  line-height: 110%;
  letter-spacing: -4.2px;
  background: radial-gradient(79.93% 79.93% at 87.12% 41.59%, #FFB02C 0%, #B66504 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold .ame-wrap .technology_slider .slides1 .technology1 .tech_number {
    font-size: 64px;
    letter-spacing: -1.289px;
  }
}
.report_resources .gradient-bg .reportFold .ame-wrap .technology_slider .slides1 .technology2 .tech_desc {
  color: #AC6C00;
  font-size: 70px;
  font-style: normal;
  font-weight: 900;
  line-height: 110%;
  letter-spacing: -0.7px;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold .ame-wrap .technology_slider .slides1 .technology2 .tech_desc {
    font-size: 21px;
    letter-spacing: -0.215px;
  }
}
.report_resources .gradient-bg .reportFold .ame-wrap .technology_slider .slides2 {
  background: #EDA42A;
  padding: 120px 70px 70px;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold .ame-wrap .technology_slider .slides2 {
    padding: 45px 15px 15px;
  }
}
.report_resources .gradient-bg .reportFold .ame-wrap .technology_slider .slides2 .percent {
  background: radial-gradient(79.93% 79.93% at 87.12% 41.59%, #FDF2DF 0%, #FFD287 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 210px;
  font-style: normal;
  font-weight: 900;
  line-height: 110%;
  letter-spacing: -4.2px;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold .ame-wrap .technology_slider .slides2 .percent {
    font-size: 64px;
    letter-spacing: -1.289px;
  }
}
.report_resources .gradient-bg .reportFold .ame-wrap .technology_slider .slides2 .percent_desc {
  color: #FFE3B4;
  font-size: 70px;
  font-style: normal;
  font-weight: 900;
  line-height: 120%;
  letter-spacing: -0.7px;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold .ame-wrap .technology_slider .slides2 .percent_desc {
    font-size: 21px;
    letter-spacing: -0.215px;
  }
}
.report_resources .gradient-bg .reportFold .ame-wrap .technology_slider .slides2 .techcircle {
  position: absolute;
  top: 0;
  right: 0;
}
.report_resources .gradient-bg .reportFold .ame-wrap .loyalty_slider .slides1 {
  background: #FFCFD8;
  padding: 10px 70px !important;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold .ame-wrap .loyalty_slider .slides1 {
    padding: 15px !important;
  }
}
.report_resources .gradient-bg .reportFold .ame-wrap .loyalty_slider .slides1 .loyaltyFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.report_resources .gradient-bg .reportFold .ame-wrap .loyalty_slider .slides1 .loyaltyFlex .loyalty1 .loyalty_title {
  color: #70000B;
  font-size: 60px;
  font-style: normal;
  font-weight: 900;
  line-height: 120%; /* 72px */
  letter-spacing: -0.6px;
  padding-bottom: 20px;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold .ame-wrap .loyalty_slider .slides1 .loyaltyFlex .loyalty1 .loyalty_title {
    font-size: 18px;
    letter-spacing: -0.184px;
  }
}
.report_resources .gradient-bg .reportFold .ame-wrap .loyalty_slider .slides1 .loyaltyFlex .loyalty1 .loyalty_desc {
  font-size: 200px;
  font-style: normal;
  font-weight: 900;
  line-height: 110%; /* 220px */
  letter-spacing: -2px;
  background: radial-gradient(79.93% 79.93% at 87.12% 41.59%, #EF4866 0%, #8B0010 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold .ame-wrap .loyalty_slider .slides1 .loyaltyFlex .loyalty1 .loyalty_desc {
    font-size: 61.378px;
    letter-spacing: -0.614px;
  }
}
.report_resources .gradient-bg .reportFold .ame-wrap .loyalty_slider .slides1 .loyaltyFlex .loyalty2 img {
  position: relative;
  top: -35px;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold .ame-wrap .loyalty_slider .slides1 .loyaltyFlex .loyalty2 img {
    top: -13px;
  }
}
.report_resources .gradient-bg .reportFold .ame-wrap .loyalty_slider .slides2 {
  background: #C22A40;
  padding: 17px 70px !important;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold .ame-wrap .loyalty_slider .slides2 {
    padding: 8px 15px !important;
  }
}
.report_resources .gradient-bg .reportFold .ame-wrap .loyalty_slider .slides2 .loyaltyFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.report_resources .gradient-bg .reportFold .ame-wrap .loyalty_slider .slides2 .loyaltyFlex .loyalty1 .loyalty_title {
  color: #FFCFD8;
  font-size: 70px;
  font-style: normal;
  font-weight: 900;
  line-height: 120%; /* 72px */
  letter-spacing: -0.7px;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold .ame-wrap .loyalty_slider .slides2 .loyaltyFlex .loyalty1 .loyalty_title {
    font-size: 21px;
    letter-spacing: -0.215px;
  }
}
.report_resources .gradient-bg .reportFold .ame-wrap .feedback_slider .slides1 {
  background: #A7FFAF;
  padding: 40px 70px !important;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold .ame-wrap .feedback_slider .slides1 {
    padding: 15px !important;
  }
}
.report_resources .gradient-bg .reportFold .ame-wrap .feedback_slider .slides1 .technology1 {
  display: flex;
  align-items: baseline;
  gap: 30px;
}
.report_resources .gradient-bg .reportFold .ame-wrap .feedback_slider .slides1 .technology1 .tech_number {
  font-size: 208.084px;
  font-style: normal;
  font-weight: 900;
  line-height: 110%; /* 228.893px */
  letter-spacing: -4.162px;
  background: radial-gradient(79.93% 79.93% at 87.12% 41.59%, #079B15 0%, #0D5614 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold .ame-wrap .feedback_slider .slides1 .technology1 .tech_number {
    font-size: 63.886px;
    letter-spacing: -1.278px;
  }
}
.report_resources .gradient-bg .reportFold .ame-wrap .feedback_slider .slides1 .technology2 .tech_desc {
  color: #41BE4C;
  font-size: 70px;
  font-style: normal;
  font-weight: 900;
  line-height: 110%; /* 77px */
  letter-spacing: -0.7px;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold .ame-wrap .feedback_slider .slides1 .technology2 .tech_desc {
    font-size: 21.491px;
    letter-spacing: -0.215px;
  }
}
.report_resources .gradient-bg .reportFold .ame-wrap .feedback_slider .slides2 {
  background: #40BD4B;
  padding: 30px 70px !important;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold .ame-wrap .feedback_slider .slides2 {
    padding: 15px !important;
  }
}
.report_resources .gradient-bg .reportFold .ame-wrap .feedback_slider .slides2 .loyaltyFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.report_resources .gradient-bg .reportFold .ame-wrap .feedback_slider .slides2 .loyaltyFlex .loyalty1 .loyalty_title {
  color: #BDFFC3;
  font-size: 70px;
  font-style: normal;
  font-weight: 900;
  line-height: 120%; /* 84px */
  letter-spacing: -0.7px;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold .ame-wrap .feedback_slider .slides2 .loyaltyFlex .loyalty1 .loyalty_title {
    font-size: 19.491px;
    letter-spacing: -0.215px;
  }
}
.report_resources .gradient-bg .reportFold1 .report_title span {
  font-size: 96px;
  font-style: normal;
  font-weight: 900;
  line-height: 121%;
  letter-spacing: -0.96px;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold1 .report_title span {
    font-size: 30px;
  }
}
.report_resources .gradient-bg .reportFold1 .quoteFlex {
  display: flex;
  align-items: flex-start;
  padding: 10px 0;
  justify-content: flex-start;
  gap: 20px;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold1 .quoteFlex {
    display: block;
  }
}
.report_resources .gradient-bg .reportFold1 .quoteFlex .quoteFlexLeft {
  min-width: 600px;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold1 .quoteFlex .quoteFlexLeft {
    min-width: 100%;
  }
}
.report_resources .gradient-bg .reportFold1 .quoteFlex .quoteFlexRight {
  min-width: 596px;
  padding: 110px 0 0;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold1 .quoteFlex .quoteFlexRight {
    min-width: 100%;
    padding: 0;
  }
}
.report_resources .gradient-bg .reportFold1 .quotes {
  border-radius: 20px;
  padding: 30px;
  box-shadow: -1px 4px 10.1px 0px rgba(0, 45, 50, 0.1);
  backdrop-filter: blur(18.1578960419px);
  display: flex;
  align-items: flex-start;
  gap: 30px;
  margin: 20px 0;
  position: relative;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold1 .quotes {
    display: block;
    padding: 18px;
  }
}
.report_resources .gradient-bg .reportFold1 .quotes .basilio {
  min-width: 100px !important;
}
.report_resources .gradient-bg .reportFold1 .quotes .basilio .quotesimg {
  left: 75px;
}
.report_resources .gradient-bg .reportFold1 .quotes .quotes1 {
  min-width: 81px;
}
.report_resources .gradient-bg .reportFold1 .quotes .quotes1 .quotesimg {
  position: absolute;
  top: 13px;
  left: 55px;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold1 .quotes .quotes1 .quotesimg {
    top: 36px;
    left: 18px;
  }
}
.report_resources .gradient-bg .reportFold1 .quotes .quotes1 .quoteslogo {
  position: absolute;
  left: 30px;
  bottom: 30px;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold1 .quotes .quotes1 .quoteslogo {
    position: unset;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 10px;
    padding-bottom: 20px;
  }
  .report_resources .gradient-bg .reportFold1 .quotes .quotes1 .quoteslogo img {
    width: 54px;
  }
}
.report_resources .gradient-bg .reportFold1 .quotes .quotes1 .quoteslogo p {
  color: #170F49;
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Area Variable";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  padding: 5px 0 0;
}
@media (max-width: 992px) {
  .report_resources .gradient-bg .reportFold1 .quotes .quotes1 .quoteslogo p {
    font-size: 16px;
  }
}
.report_resources .gradient-bg .reportFold1 .quotes .quotes2 p {
  color: #303030;
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Area Normal";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
}
.report_resources .gradient-bg .reportFold1 .quote1 {
  background: #FFFCCE;
}
.report_resources .gradient-bg .reportFold1 .quote2 {
  background: #DEFFE0;
}
.report_resources .gradient-bg .reportFold1 .quote3 {
  background: #FFE2E2;
}
.report_resources .gradient-bg .reportFold1 .quote4 {
  background: #FFECDA;
}
.report_resources .resources_logo {
  padding: 230px 0 70px;
}
@media (max-width: 992px) {
  .report_resources .resources_logo {
    padding: 40px 0 70px;
  }
}
.report_resources .resources_logo .resource_title {
  color: #023342;
  font-family: "Area Variable";
  font-size: 40px;
  font-style: normal;
  font-weight: 900;
  line-height: 110%; /* 44px */
  letter-spacing: -0.4px;
}
@media (max-width: 992px) {
  .report_resources .resources_logo .resource_title {
    font-size: 20px;
  }
}
.report_resources .resources_logo .logo-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 18px;
  margin: 40px auto;
}
@media (max-width: 992px) {
  .report_resources .resources_logo .logo-container {
    grid-template-columns: repeat(3, 1fr);
  }
}
.report_resources .resources_logo .logo-container .logo-item {
  text-align: center;
  display: none;
}
.report_resources .resources_logo #see-more-btn {
  display: block;
  margin: 0px auto;
  color: #0D9298;
  font-feature-settings: "liga" off, "clig" off;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 100% */
  border: none;
  background: none;
  cursor: pointer;
}
.report_resources .resources_logo #see-more-btn.hidden {
  display: none;
}
.report_resources .lastsection {
  border-radius: 24px;
  padding: 40px 20px;
}
@media (max-width: 992px) {
  .report_resources .lastsection {
    margin: 0 20px;
  }
}

.testimonials .success_stories {
  gap: 40px;
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
}
@media (max-width: 992px) {
  .testimonials .success_stories {
    flex-wrap: wrap;
  }
}
.testimonials .success_stories .success_stories_left {
  max-width: 627px;
  padding: 90px 0 90px 84px;
}
@media (max-width: 992px) {
  .testimonials .success_stories .success_stories_left {
    padding: 40px 0 0;
    text-align: center;
  }
}
.testimonials .success_stories .success_stories_left .sec_title {
  font-size: 64px;
  color: #0e1b1b;
  line-height: 68px;
  margin: 16px 0 20px;
  letter-spacing: -0.01em;
  font-weight: 900;
  font-family: "Basier Circle medium";
}
@media (max-width: 992px) {
  .testimonials .success_stories .success_stories_left .sec_title {
    margin: 12px 0;
    font-size: 28px;
    line-height: 32px;
  }
}
.testimonials .success_stories .success_stories_left .sec_desc {
  font-size: 20px;
  margin: 0 0 40px;
  color: #374040;
  line-height: 30px;
}
@media (max-width: 992px) {
  .testimonials .success_stories .success_stories_left .sec_desc {
    font-size: 14px;
    margin: 0 0 24px;
    line-height: 19px;
  }
}
.testimonials .success_stories .success_stories_right {
  width: 100%;
  max-width: 790px;
}
.testimonials .success_stories .success_stories_right .parent_Img {
  width: 100%;
  height: 100%;
  position: relative;
  user-select: none;
}
@media (max-width: 992px) {
  .testimonials .success_stories .success_stories_right .parent_Img {
    overflow-x: auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    scrollbar-width: none;
    scroll-snap-type: x mandatory;
  }
}
@media (max-width: 992px) {
  .testimonials .success_stories .success_stories_right .parent_Img img {
    margin: 0 5px;
    min-width: 234px;
  }
}
.testimonials .testimonial_listing .common_title {
  font-size: 56px;
  margin: 0 0 60px;
  line-height: 64px;
  text-align: center;
  font-family: "Basier Circle medium";
  font-weight: 900;
}
@media (max-width: 992px) {
  .testimonials .testimonial_listing .common_title {
    margin: 12px 0;
    font-size: 28px;
    line-height: 32px;
  }
}
.testimonials .testimonial_listing .reelo_testimonials .middle-slide {
  margin: 40px 12px 0 !important;
}
@media (max-width: 992px) {
  .testimonials .testimonial_listing .reelo_testimonials .middle-slide {
    margin: 0 !important;
  }
}
.testimonials .testimonial_listing .reelo_testimonials .testimonialsBox {
  padding: 24px;
  position: relative;
  border-radius: 16px;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  background-image: url("../images/bg-shape.png");
  max-width: calc(33% - 20px);
  margin: 0 12px 55px;
  height: 580px;
}
@media (max-width: 992px) {
  .testimonials .testimonial_listing .reelo_testimonials .testimonialsBox {
    margin: 25px 0 !important;
    max-width: 100%;
    width: 100%;
    margin: 0 0 30px;
    height: 515px;
  }
}
.testimonials .testimonial_listing .reelo_testimonials .testimonialsBox:hover .testimonials-box-wrapper .testimonials_Img .video-thumbnail .play-icon-wrapper {
  transform: translate(-50%, -50%) scale(1.2);
}
.testimonials .testimonial_listing .reelo_testimonials .testimonialsBox .testimonials-box-wrapper .testimonials_Img .video-thumbnail .videoimg {
  height: 357px;
  border-radius: 16px;
}
@media (max-width: 992px) {
  .testimonials .testimonial_listing .reelo_testimonials .testimonialsBox .testimonials-box-wrapper .testimonials_Img .video-thumbnail .videoimg {
    height: 294px;
  }
}
.testimonials .testimonial_listing .reelo_testimonials .testimonialsBox .testimonials-box-wrapper .testimonials_Img .video-thumbnail .play-icon-wrapper {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%) scale(1);
  left: 50%;
  cursor: pointer;
  transition: all 0.5s;
  width: 48px;
  height: 48px;
  margin: 0 auto;
}
.testimonials .testimonial_listing .reelo_testimonials .testimonialsBox .testimonials-box-wrapper .testimonials_Img iframe {
  height: 357px;
  border: none;
  outline: none;
  border-radius: 24px;
}
@media (max-width: 992px) {
  .testimonials .testimonial_listing .reelo_testimonials .testimonialsBox .testimonials-box-wrapper .testimonials_Img iframe {
    height: 294px;
  }
}
.testimonials .testimonial_listing .reelo_testimonials .testimonialsBox .testimonials-box-wrapper .testimonial-title {
  font-size: 28px;
  margin: 24px 0 0;
  color: #01292b;
  line-height: 34px;
  letter-spacing: -0.01em;
  font-family: "Basier Circle Semibold";
}
@media (max-width: 992px) {
  .testimonials .testimonial_listing .reelo_testimonials .testimonialsBox .testimonials-box-wrapper .testimonial-title {
    font-size: 24px;
    line-height: 34px;
  }
}
.testimonials .testimonial_review_listing .common_title {
  font-size: 56px;
  margin: 0 0 60px;
  line-height: 64px;
  text-align: center;
  font-family: "Basier Circle medium";
  font-weight: 900;
  text-align: left;
}
@media (max-width: 992px) {
  .testimonials .testimonial_review_listing .common_title {
    margin: 12px 0;
    font-size: 28px;
    line-height: 32px;
    text-align: center;
  }
}
.testimonials .testimonial_review_listing .testiFlex {
  gap: 35px;
  width: auto;
  display: flex;
}
@media (max-width: 992px) {
  .testimonials .testimonial_review_listing .testiFlex {
    display: block;
    width: 100%;
  }
}
.testimonials .testimonial_review_listing .testiFlex .parent_testi {
  width: 25%;
}
@media (max-width: 992px) {
  .testimonials .testimonial_review_listing .testiFlex .parent_testi {
    display: none;
  }
}
.testimonials .testimonial_review_listing .testiFlex .testimonial_review {
  padding: 16px;
  border-radius: 12px;
  border: 1px solid #e3e3e3;
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.05), 0 0 1px rgba(0, 0, 0, 0.25);
  margin: 0 0 35px;
}
.testimonials .testimonial_review_listing .testiFlex .testimonial_review .review_detail {
  gap: 8px;
  margin: 0 0 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.testimonials .testimonial_review_listing .testiFlex .testimonial_review .review_detail .reviewer_name {
  margin: 0;
  font-size: 16px;
  color: #0e1b1b;
  line-height: 22px;
  font-family: "Basier Circle Semibold";
}
.testimonials .testimonial_review_listing .testiFlex .testimonial_review .review_detail .reviewer_post {
  margin: 0;
  font-size: 14px;
  color: #374040;
  line-height: 20px;
}
.testimonials .testimonial_review_listing .testiFlex .testimonial_review .review_desc {
  margin: 0;
  font-size: 16px;
  line-height: 22px;
}
@media (max-width: 992px) {
  .testimonials .testimonial_review_listing .testiFlex .testimonial_mobile {
    margin: 30px 0 0;
  }
  .testimonials .testimonial_review_listing .testiFlex .testimonial_mobile .testimonial_review {
    margin: 0 5px;
    height: 420px;
  }
}
.testimonials .lastsection {
  border-radius: 24px;
  padding: 40px 20px;
}
@media (max-width: 992px) {
  .testimonials .lastsection {
    margin: 0 20px;
  }
}

.affiliate-program .banner {
  height: 100vh;
}
@media (max-width: 992px) {
  .affiliate-program .banner {
    height: unset;
  }
}
.affiliate-program .banner .tagline_wrapper {
  text-align: center;
  padding: 30px 0 0;
}
@media (max-width: 992px) {
  .affiliate-program .banner .tagline_wrapper {
    padding: 50px 0 0;
  }
}
.affiliate-program .banner .tagline_wrapper .common_subtitle {
  font-size: 20px;
  color: #616d6d;
  line-height: 25px;
}
@media (max-width: 992px) {
  .affiliate-program .banner .tagline_wrapper .common_subtitle {
    font-size: 14px;
    line-height: 18px;
  }
}
.affiliate-program .banner .tagline_wrapper .banner_heading .common_title {
  margin: 0;
  font-size: 48px;
  color: #182020;
  line-height: 64px;
  font-family: "Basier Circle Semibold";
  position: relative;
  display: inline-block;
  margin: 20px 0;
}
@media (max-width: 992px) {
  .affiliate-program .banner .tagline_wrapper .banner_heading .common_title {
    font-size: 28px;
    line-height: 36px;
  }
}
.affiliate-program .banner .tagline_wrapper .banner_heading .common_title span {
  color: #8e44ad;
}
.affiliate-program .banner .tagline_wrapper .banner_heading .common_title .fill1 {
  position: absolute;
  left: -20px;
  top: -24px;
}
@media (max-width: 992px) {
  .affiliate-program .banner .tagline_wrapper .banner_heading .common_title .fill1 {
    width: 64px;
  }
}
.affiliate-program .banner .tagline_wrapper .banner_heading .common_title .fill2 {
  position: absolute;
  right: -20px;
  top: -24px;
}
@media (max-width: 992px) {
  .affiliate-program .banner .tagline_wrapper .banner_heading .common_title .fill2 {
    width: 64px;
  }
}
.affiliate-program .banner .tagline_wrapper .btn_wrapper {
  display: inline-block;
  margin: 30px 0 0;
}
@media (max-width: 992px) {
  .affiliate-program .banner .tagline_wrapper .btn_wrapper {
    margin: 30px 0 60px;
  }
}
.affiliate-program .banner .tagline_wrapper .btn_wrapper .fill3 {
  position: absolute;
  left: -72px;
  top: -34px;
}
.affiliate-program .banner .tagline_wrapper .btn_wrapper .fill4 {
  position: absolute;
  right: -72px;
  top: -18px;
}
.affiliate-program .banner .tagline_wrapper .btn_wrapper .black-btn {
  padding: 20px 94px;
  font-size: 22px !important;
}
@media (max-width: 992px) {
  .affiliate-program .banner .tagline_wrapper .btn_wrapper .black-btn {
    padding: 12px 30px;
    font-size: 16px !important;
  }
}
.affiliate-program .sec_title {
  font-size: 40px;
  color: #182020;
  margin: 0 0 48px;
  line-height: 50px;
  text-align: center;
}
@media (max-width: 992px) {
  .affiliate-program .sec_title {
    font-size: 20px;
    line-height: 25px;
    margin: 0 0 20px;
  }
}
.affiliate-program .range_wrapper {
  padding: 200px 0 70px;
}
@media (max-width: 992px) {
  .affiliate-program .range_wrapper {
    padding: 40px 0;
  }
}
.affiliate-program .range_wrapper .affiliate_span1 {
  font-size: 20px;
  color: #616d6d;
  line-height: 25px;
}
@media (max-width: 992px) {
  .affiliate-program .range_wrapper .affiliate_span1 {
    font-size: 14px;
    line-height: 18px;
  }
}
.affiliate-program .range_wrapper .output {
  padding: 0 20px;
  font-size: 48px;
  color: #182020;
  line-height: 60px;
  text-align: center;
  margin: 8px auto 40px;
}
@media (max-width: 992px) {
  .affiliate-program .range_wrapper .output {
    font-size: 32px;
    line-height: 40px;
  }
}
.affiliate-program .range_wrapper .affiliate_range_slider_div {
  margin: 0 auto;
  padding: 0 40px;
  max-width: 1120px;
}
.affiliate-program .range_wrapper .affiliate_range_slider_div .custom_range_slider {
  position: relative;
  width: 100%;
  height: 20px;
}
.affiliate-program .range_wrapper .affiliate_range_slider_div .custom_range_slider .custom_range_slider_input {
  width: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
.affiliate-program .range_wrapper .affiliate_range_slider_div .custom_range_slider .custom_range_slider_track {
  height: 10px;
  border-radius: 5px;
  position: relative;
  width: 100%;
}
.affiliate-program .range_wrapper .affiliate_range_slider_div .custom_range_slider .custom_range_slider_track .custom_range_slider_thumb {
  width: 48px;
  height: 48px;
  padding: 4px;
  border-radius: 50%;
  background-color: #fff;
  border: 4px solid #1a424f;
}
.affiliate-program .range_wrapper .affiliate_range_slider_div .affiliate_track_numbers {
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.affiliate-program .range_wrapper .affiliate_range_slider_div .affiliate_track_numbers span {
  font-size: 24px;
  line-height: 30px;
  color: #182020;
}
.affiliate-program .affiliate_how_it_works {
  background: #d4e9f7;
}
.affiliate-program .affiliate_how_it_works .affiliate_how_it_works_cards {
  gap: 40px;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 992px) {
  .affiliate-program .affiliate_how_it_works .affiliate_how_it_works_cards {
    gap: 25px;
  }
}
.affiliate-program .affiliate_how_it_works .affiliate_how_it_works_cards .affiliate_how_it_works_card {
  padding: 32px 30px;
  max-width: 346px;
  border-radius: 12px;
  background: #fff;
}
.affiliate-program .affiliate_how_it_works .affiliate_how_it_works_cards .affiliate_how_it_works_card .card_header {
  margin: 24px 0;
  font-size: 24px;
  color: #182020;
  line-height: 30px;
  font-weight: 600;
}
@media (max-width: 992px) {
  .affiliate-program .affiliate_how_it_works .affiliate_how_it_works_cards .affiliate_how_it_works_card .card_header {
    font-size: 20px;
    line-height: 25px;
  }
}
.affiliate-program .affiliate_how_it_works .affiliate_how_it_works_cards .affiliate_how_it_works_card .card_subheader {
  margin: 0;
  font-size: 20px;
  color: #616d6d;
  line-height: 25px;
}
@media (max-width: 992px) {
  .affiliate-program .affiliate_how_it_works .affiliate_how_it_works_cards .affiliate_how_it_works_card .card_subheader {
    font-size: 16px;
    line-height: 20px;
  }
}
.affiliate-program .affiliate_why_reelo .affiliate_reviews {
  gap: 40px;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 992px) {
  .affiliate-program .affiliate_why_reelo .affiliate_reviews {
    gap: 16px;
    overflow-x: auto;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    scroll-snap-type: x mandatory;
  }
}
.affiliate-program .affiliate_why_reelo .affiliate_reviews .affiliate_review_div {
  width: 346px;
  height: 380px;
  padding: 32px;
  min-width: 240px;
  border-radius: 12px;
  background: #fafafa;
}
@media (max-width: 992px) {
  .affiliate-program .affiliate_why_reelo .affiliate_reviews .affiliate_review_div {
    height: 270px;
    padding: 20px;
  }
}
.affiliate-program .affiliate_why_reelo .affiliate_reviews .affiliate_review_div .card_review {
  margin: 24px 0;
  font-size: 24px;
  color: #182020;
  line-height: 28px;
  font-weight: 400;
}
@media (max-width: 992px) {
  .affiliate-program .affiliate_why_reelo .affiliate_reviews .affiliate_review_div .card_review {
    font-size: 16px;
    line-height: 18px;
  }
}
.affiliate-program .affiliate_why_reelo .affiliate_reviews .affiliate_review_div .card_reviewer {
  margin: 0;
  font-size: 16px;
  color: #182020;
  line-height: 20px;
}
@media (max-width: 992px) {
  .affiliate-program .affiliate_why_reelo .affiliate_reviews .affiliate_review_div .card_reviewer {
    font-size: 14px;
    line-height: 18px;
  }
}
.affiliate-program .affiliate_why_reelo .affiliate_reviews .affiliate_review_div .card_reviewer span {
  color: #616d6d;
}
.affiliate-program .resources_brand .marketingPlaybook_brands {
  gap: 56px;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
}

.demo .display_table {
  flex-direction: unset;
}
.demo .banner {
  padding: 100px 0 70px;
}
@media (max-width: 992px) {
  .demo .banner {
    height: unset;
    padding: 90px 0 0;
  }
}
.demo .banner .demo_right_fold {
  width: 100%;
  padding: 24px;
  max-width: 525px;
  min-width: 525px;
  min-height: 544px;
  border-radius: 12px;
  background: #f7f8f8;
  margin: 0 auto;
}
@media (max-width: 992px) {
  .demo .banner .demo_right_fold {
    padding: 16px;
    min-width: unset;
    border-radius: 8px;
    margin: 20px auto 40px;
  }
}
.demo .banner .demo_right_fold p {
  color: #000;
  font-size: 18px;
  margin: 0 0 24px;
  line-height: 28px;
  letter-spacing: -0.18px;
  font-family: "Basier Circle medium";
}
@media (max-width: 992px) {
  .demo .banner .demo_right_fold p {
    font-size: 16px;
    line-height: 20px;
  }
}
.demo .sec_title {
  font-size: 64px;
  line-height: 62px;
  text-align: center;
  margin: 0 auto 24px;
  letter-spacing: -2.56px;
  font-family: "Basier Circle medium";
  color: #0e1b1b;
  font-weight: 500;
}
@media (max-width: 992px) {
  .demo .sec_title {
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    margin-bottom: 18px;
    letter-spacing: -1.2px;
  }
}
.demo .sec_desc {
  font-size: 18px;
  color: #697777;
  line-height: 26px;
  text-align: center;
  margin: 0 auto 32px;
  letter-spacing: -0.18px;
  font-family: "Basier Circle";
}
@media (max-width: 992px) {
  .demo .sec_desc {
    font-size: 16px;
    margin: 0 0 16px;
    line-height: 20px;
    text-align: center;
  }
}
.demo .common_title {
  font-size: 64px;
  color: #182020;
  line-height: 65px;
  margin-bottom: 18px;
  letter-spacing: -2.4px;
  font-family: "Basier Circle";
}
@media (max-width: 992px) {
  .demo .common_title {
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    margin-bottom: 18px;
    letter-spacing: -1.2px;
  }
}
.demo .common_desc {
  margin: 0;
  font-size: 18px;
  color: #697777;
  line-height: 28px;
  letter-spacing: -0.18px;
  font-family: "Basier Circle";
}
@media (max-width: 992px) {
  .demo .common_desc {
    font-size: 16px;
    margin: 0 0 16px;
    line-height: 20px;
    text-align: center;
  }
}
.demo .fold2 {
  background-color: #f8f8f8;
}
.demo .fold2 .tagline_wraper:hover .quotes_Img .video-thumbnail .play-icon-wrapper {
  transform: translate(-50%, -50%) scale(1.2);
  box-shadow: 0 20px 30px -10px transparent;
}
.demo .fold2 .tagline_wraper .quotes_Img {
  margin: 50px 0 0;
}
.demo .fold2 .tagline_wraper .quotes_Img .video-thumbnail .top-gradient {
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  width: 100%;
  height: 80px;
  padding: 24px;
  position: absolute;
  border-radius: 16px 16px 0 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.6), transparent);
}
.demo .fold2 .tagline_wraper .quotes_Img .video-thumbnail .top-gradient p {
  z-index: 1;
  font-size: 18px;
  text-align: center;
  line-height: 22px;
  overflow: hidden;
  white-space: nowrap;
}
.demo .fold2 .tagline_wraper .quotes_Img .video-thumbnail .videoimg {
  border-radius: 24px;
}
.demo .fold2 .tagline_wraper .quotes_Img .video-thumbnail .play-icon-wrapper {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%) scale(1);
  left: 50%;
  cursor: pointer;
  transition: all 0.3s;
}
.demo .fold2 .tagline_wraper .quotes_Img iframe {
  height: 709px;
  border: none;
  outline: none;
  border-radius: 24px;
}
@media (max-width: 992px) {
  .demo .fold2 .tagline_wraper .quotes_Img iframe {
    height: 189px;
  }
}
.demo .fold3 .demo_videos_row {
  gap: 24px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 60px 0 20px;
}
@media (max-width: 992px) {
  .demo .fold3 .demo_videos_row {
    gap: 16px;
    padding: 0 16px;
    overflow-x: auto;
    justify-content: flex-start;
    scrollbar-width: none;
    scroll-snap-type: x mandatory;
  }
}
.demo .fold3 .demo_videos_row .demo_video_col {
  width: 100%;
  height: 100%;
  position: relative;
}
@media (max-width: 992px) {
  .demo .fold3 .demo_videos_row .demo_video_col {
    min-width: 250px;
    scroll-snap-align: center;
  }
}
.demo .fold3 .demo_videos_row .demo_video_col:hover .video-thumbnail .play-icon-wrapper {
  transform: translate(-50%, -50%) scale(1.2);
  box-shadow: 0 20px 30px -10px transparent;
}
.demo .fold3 .demo_videos_row .demo_video_col .video-thumbnail .videoimg {
  border-radius: 24px;
}
.demo .fold3 .demo_videos_row .demo_video_col .video-thumbnail .play-icon-wrapper {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%) scale(1);
  left: 50%;
  cursor: pointer;
  transition: all 0.3s;
}
.demo .fold3 .demo_videos_row .demo_video_col iframe {
  height: 528px;
  border: none;
  outline: none;
  position: relative;
  border-radius: 12px;
}
@media (max-width: 992px) {
  .demo .fold3 .demo_videos_row .demo_video_col iframe {
    height: 444px;
  }
}

.loyalty-inspiration .banner {
  padding: 50px 20px 0;
}
.loyalty-inspiration .banner .common_title {
  color: #354545;
  font-weight: 600;
  margin: 70px 0 30px;
  font-size: 56px;
  line-height: 62px;
  letter-spacing: -0.01em;
  font-family: "Basier Circle medium";
}
@media (max-width: 992px) {
  .loyalty-inspiration .banner .common_title {
    margin: 40px auto 20px;
    font-size: 32px;
    line-height: 42px;
  }
}
.loyalty-inspiration .loyaltyDropdown {
  height: 40px;
  color: #a8b4b4;
  border-radius: 10px;
  background-color: #fff;
  border: 1px solid #a8b4b4;
  width: 96%;
  font-size: 15px;
}
.loyalty-inspiration .loyaltyDropdown option {
  color: #000 !important;
  background-color: #ebebeb;
  font-size: 16px;
  line-height: 32px;
  font-family: "Basier Circle";
  margin: 10px 0;
}
.loyalty-inspiration .loyaltyDropdown option:hover {
  color: #000;
}
.loyalty-inspiration .inspiration_wrapper {
  margin: 60px 0 0;
}
.loyalty-inspiration .inspiration_wrapper .my-masonry-grid {
  display: flex;
  flex-wrap: wrap;
  margin-left: -30px;
  width: auto;
}
@media (max-width: 992px) {
  .loyalty-inspiration .inspiration_wrapper .my-masonry-grid {
    flex-direction: column;
  }
}
.loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column > div {
  margin-bottom: 30px;
}
.loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column {
  padding-left: 30px;
  background-clip: padding-box;
  width: 33.3333%;
}
@media (max-width: 992px) {
  .loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column {
    width: 100%;
  }
}
.loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column .root-loyalty-card {
  filter: drop-shadow(5px 6px 20px rgba(96, 96, 96, 0.31));
}
.loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column .root-loyalty-card .final-preview-card {
  max-width: 414px;
  height: max-content;
  padding-bottom: 25px;
  border-radius: 15px;
  position: relative;
  margin: 0 auto;
}
@media (max-width: 992px) {
  .loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column .root-loyalty-card .final-preview-card {
    width: 100%;
  }
}
.loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column .root-loyalty-card .final-preview-card .card-header {
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 250px;
  padding: 0;
  overflow: hidden;
  position: relative;
  border-radius: 15px;
  -webkit-clip-path: ellipse(67% 88% at 81% 1%);
  clip-path: ellipse(67% 88% at 81% 1%);
}
.loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column .root-loyalty-card .final-preview-card .card-header .react-transform-wrapper {
  width: 100%;
  height: 250px;
}
.loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column .root-loyalty-card .final-preview-card .card-header .react-transform-wrapper .transform-component-module_content {
  display: flex;
  flex-wrap: wrap;
  width: fit-content;
  height: fit-content;
  margin: 0;
  padding: 0;
  transform-origin: 0% 0%;
}
.loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column .root-loyalty-card .final-preview-card .card-header .react-transform-wrapper .transform-component-module_content img {
  width: 100%;
  margin: 0 0 0 auto;
  pointer-events: none;
}
.loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column .root-loyalty-card .final-preview-card .card-logo-div {
  top: 100px;
  left: 45px;
  z-index: 10;
  width: 124px;
  height: 124px;
  padding: 2.4px;
  position: absolute;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column .root-loyalty-card .final-preview-card .card-logo-div .card-logo-image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}
.loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column .root-loyalty-card .final-preview-card .card-logo-div .card-logo-name {
  font-size: 14px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}
.loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column .root-loyalty-card .final-preview-card .card-content {
  padding: 0 25px;
}
.loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column .root-loyalty-card .final-preview-card .card-content h3 {
  padding: 0 25px;
  font-size: 36px;
  line-height: 40px;
}
@media (max-width: 992px) {
  .loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column .root-loyalty-card .final-preview-card .card-content h3 {
    font-size: 26px;
    line-height: 30px;
  }
}
.loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column .root-loyalty-card .final-preview-card .card-content h4 {
  padding: 0 25px;
  font-size: 18px;
  line-height: 22px;
}
@media (max-width: 992px) {
  .loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column .root-loyalty-card .final-preview-card .card-content h4 {
    font-size: 16px;
    line-height: 20px;
  }
}
.loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column .root-loyalty-card .final-preview-card .card-content h5 {
  padding: 0 25px;
  font-size: 24px;
  line-height: 20px;
  margin-top: 20px;
  margin-bottom: 30px;
  position: relative;
}
@media (max-width: 992px) {
  .loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column .root-loyalty-card .final-preview-card .card-content h5 {
    font-size: 16px;
    line-height: 20px;
  }
}
.loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column .root-loyalty-card .final-preview-card .card-content .cashback-content {
  padding: 0 25px;
  position: relative;
}
.loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column .root-loyalty-card .final-preview-card .card-content .cashback-content p {
  font-size: 16px;
  line-height: 20px;
  margin: 16px 0 0;
}
.loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column .root-loyalty-card .final-preview-card .card-content .cashback-content h2 {
  margin: 0;
  font-size: 64px;
  line-height: 70px;
  text-align: left;
  font-weight: 600;
}
.loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column .root-loyalty-card .final-preview-card .card-content .cashback-content h3 {
  padding: 0;
  margin: 0 0 4px;
  font-size: 24px;
  line-height: 30px;
  font-weight: 400;
}
.loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column .root-loyalty-card .final-preview-card .card-content .cashback-content h5 {
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
}
.loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column .root-loyalty-card .final-preview-card .card-content .reward-row:last-child {
  border: none;
}
.loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column .root-loyalty-card .final-preview-card .card-content .reward-row {
  display: flex;
  margin-bottom: 10px;
  padding-bottom: 10px;
  align-items: center;
  position: relative;
  border-bottom: 1px dashed #fff;
}
.loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column .root-loyalty-card .final-preview-card .card-content .reward-row h4 {
  font-size: 32px;
  line-height: 32px;
  min-width: 150px;
  max-width: 150px;
  margin: 0;
  font-weight: 700;
}
.loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column .root-loyalty-card .final-preview-card .card-content .reward-row h4 span {
  font-size: 14px;
}
.loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column .root-loyalty-card .final-preview-card .card-content .reward-row p {
  text-align: left;
  padding-left: 10px;
  font-size: 16px;
}
.loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column .root-loyalty-card .final-preview-card .preview-footer {
  margin: 15px 25px 0;
  padding: 13px 15px;
  border-radius: 10px;
  position: relative;
  border: 1px dashed #fff;
}
.loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column .root-loyalty-card .final-preview-card .preview-footer h3 {
  font-size: 10px;
  line-height: 12.5px;
}
.loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column .root-loyalty-card .final-preview-card .preview-footer p {
  margin: 0;
  font-size: 10px;
  line-height: 12.5px;
}
.loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column .root-loyalty-card .cashback-terms {
  padding: 25px;
  margin: 0 auto;
  max-width: 414px;
  position: relative;
  border-radius: 15px;
  background-color: #fff;
}
.loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column .root-loyalty-card .cashback-terms h4 {
  font-size: 16px;
  color: #545454;
  line-height: 20px;
  margin: 0 16px 16px;
}
.loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column .root-loyalty-card .cashback-terms .preview-footer {
  padding: 13px 15px;
  border-radius: 10px;
  position: relative;
  border: 1px dashed #354545;
}
.loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column .root-loyalty-card .cashback-terms .preview-footer h3 {
  font-size: 10px;
  line-height: 12.5px;
  margin-bottom: 0.5rem;
  font-weight: 600;
}
.loyalty-inspiration .inspiration_wrapper .my-masonry-grid .my-masonry-grid_column .root-loyalty-card .cashback-terms .preview-footer p {
  margin: 0;
  font-size: 10px;
  line-height: 12.5px;
}
.loyalty-inspiration .fold2 .inspiration_flexrow {
  margin: 0 auto;
  max-width: 1024px;
  padding: 0 20px 50px;
}
@media (max-width: 992px) {
  .loyalty-inspiration .fold2 .inspiration_flexrow {
    padding: 0;
  }
}
.loyalty-inspiration .fold2 .inspiration_flexrow .inspiration_custom-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 60px;
}
@media (max-width: 992px) {
  .loyalty-inspiration .fold2 .inspiration_flexrow .inspiration_custom-row {
    flex-direction: column;
  }
}
.loyalty-inspiration .fold2 .inspiration_flexrow .inspiration_custom-row .inspiration_custom-col .inspiration_custom-col_left {
  max-width: 500px;
}
.loyalty-inspiration .fold2 .inspiration_flexrow .inspiration_custom-row .inspiration_custom-col .inspiration_custom-col_left h3 {
  font-size: 24px;
  color: #354545;
  line-height: 31px;
  margin: 40px 0 20px;
  font-weight: 600;
}
.loyalty-inspiration .fold2 .inspiration_flexrow .inspiration_custom-row .inspiration_custom-col .inspiration_custom-col_left ul {
  padding-left: 20px;
}
.loyalty-inspiration .fold2 .inspiration_flexrow .inspiration_custom-row .inspiration_custom-col .inspiration_custom-col_left ul li {
  font-size: 16px;
  color: #354545;
  line-height: 24px;
  font-weight: 400;
  padding: 0 0 12px;
}
.loyalty-inspiration .fold2 .inspiration_flexrow .inspiration_custom-row .inspiration_custom-col .inspiration_custom-col_right {
  max-width: 500px;
}
.loyalty-inspiration .fold2 .inspiration_flexrow .inspiration_custom-row .inspiration_custom-col .inspiration_custom-col_right h3 {
  font-size: 24px;
  color: #354545;
  line-height: 31px;
  margin: 40px 0 20px;
  font-weight: 600;
}
.loyalty-inspiration .fold2 .inspiration_flexrow .inspiration_custom-row .inspiration_custom-col .inspiration_custom-col_right p {
  font-size: 16px;
  color: #354545;
  line-height: 24px;
  font-weight: 400;
  padding: 0 0 12px;
}
@media (max-width: 992px) {
  .loyalty-inspiration .fold2 .inspiration_flexrow .row_2 {
    flex-direction: column-reverse;
  }
}
@media (max-width: 992px) {
  .loyalty-inspiration #lastsection {
    height: 322px;
  }
}

.faq .sec_title {
  font-size: 42px;
  line-height: 51px;
  letter-spacing: -0.02em;
  font-weight: 500;
  font-family: "Basier Circle medium";
  margin-bottom: 50px;
}
@media (max-width: 992px) {
  .faq .sec_title {
    margin-bottom: 25px;
    font-size: 24px;
    line-height: 28px;
  }
}
.faq .set {
  position: relative;
  width: 100%;
  height: auto;
  margin: auto;
}
.faq .set a {
  border-radius: 6px;
  display: block;
  padding: 16px;
  text-decoration: none;
  transition: all 0.2s linear;
  margin: 10px 0 0;
  font-size: 16px;
  color: #182020;
  line-height: 19px;
  background: #fafafa;
  font-weight: 600;
  position: relative;
  height: auto;
}
.faq .set a i {
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 100%;
  transition: transform 0.2s ease-in-out;
}
@media (max-width: 992px) {
  .faq .set a i {
    right: 18px;
  }
}
.faq .set a .icon-minus:after {
  width: 20px;
  height: 20px;
  background-image: url("../images/down.svg");
  background-repeat: no-repeat;
  top: 8px;
  position: absolute;
  content: "";
  top: 50%;
  transform: translate(-50%, -50%) rotate(180deg);
  text-align: center;
  transition: transform 0.2s ease-in-out;
  left: 50%;
}
.faq .set a .icon-plus:before {
  position: absolute;
  content: "";
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  left: 50%;
  background-image: url("../images/down.svg");
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  transition: transform 0.2s ease-in-out;
}
.faq .set .faqs_content {
  background-color: #fafafa;
  border-top: 1px solid rgba(128, 128, 128, 0.1803921569);
  padding: 20px;
}
.faq .set .faqs_content a {
  display: inline-block;
  padding: 0;
  margin: 0;
}
.faq .set .faqs_content ul {
  padding: 10px 20px;
}
.faq .set .faqs_content ul li {
  padding: 0 0 6px;
}
.faq .set .faqs_content ul li a {
  display: inline-block;
  padding: 0;
}
.faq .set .faqs_content ol {
  padding: 10px 20px;
}
.faq .set .faqs_content ol li {
  padding: 0 0 6px;
}
.faq .set .faqs_content ol li a {
  display: inline-block;
  padding: 0;
}

.webinar-registration .today {
  padding: 90px 0 40px;
}
@media (max-width: 992px) {
  .webinar-registration .today {
    padding: 90px 0 0;
  }
}
.webinar-registration .loyalty_first_fold {
  gap: 24px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
@media (max-width: 992px) {
  .webinar-registration .loyalty_first_fold {
    flex-wrap: wrap;
  }
}
.webinar-registration .loyalty_first_fold .loyalty_left_side {
  max-width: 620px;
  margin: -10px 0 0;
}
@media (max-width: 992px) {
  .webinar-registration .loyalty_first_fold .loyalty_left_side {
    margin: 0;
  }
}
.webinar-registration .loyalty_first_fold .loyalty_left_side .speakerFlex {
  display: flex;
  align-items: center;
  gap: 30px;
  margin: 37px 0;
}
@media (max-width: 992px) {
  .webinar-registration .loyalty_first_fold .loyalty_left_side .speakerFlex {
    flex-wrap: wrap;
  }
}
.webinar-registration .loyalty_first_fold .loyalty_left_side .speakerFlex .speaker_content {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  position: relative;
}
.webinar-registration .loyalty_first_fold .loyalty_left_side .speakerFlex .speaker_content .speakerImg {
  border-radius: 6px;
  background: #C7E1FF;
}
.webinar-registration .loyalty_first_fold .loyalty_left_side .speakerFlex .speaker_content .speakers .speaker_name {
  font-size: 20px;
  line-height: 26px;
  font-weight: 600;
}
.webinar-registration .loyalty_first_fold .loyalty_left_side .speakerFlex .speaker_content .speakers .speaker_dir {
  font-size: 16px;
  line-height: 18px;
}
.webinar-registration .loyalty_first_fold .loyalty_left_side .speakerFlex .speaker_content .speakers .speaker_desig {
  font-size: 10px;
  line-height: 16px;
  display: inline-block;
  padding: 4px 8px;
  border-radius: 90px;
  position: absolute;
  bottom: 0;
}
.webinar-registration .loyalty_first_fold .loyalty_left_side .webinar_patch {
  border-radius: 6px;
  display: flex;
  background: #DFF;
  align-items: center;
  justify-content: space-around;
}
@media (max-width: 992px) {
  .webinar-registration .loyalty_first_fold .loyalty_left_side .webinar_patch {
    flex-wrap: wrap;
    padding: 12px 0;
  }
}
.webinar-registration .loyalty_first_fold .loyalty_left_side .webinar_patch span {
  font-family: "Basier Circle medium";
  font-size: 20px;
  font-weight: 500;
  line-height: 50px;
  margin: 0 20px;
  color: #023342;
}
@media (max-width: 992px) {
  .webinar-registration .loyalty_first_fold .loyalty_left_side .webinar_patch span {
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    display: block;
  }
}
.webinar-registration .loyalty_first_fold .loyalty_left_side .webinar_patch span img {
  position: relative;
  top: 3px;
  width: 22px;
}
.webinar-registration .loyalty_first_fold .loyalty_left_side .agenda {
  padding-left: 20px;
}
.webinar-registration .loyalty_first_fold .loyalty_left_side .agenda li {
  padding: 0 0 4px;
  color: #374040;
  font-size: 18px;
  line-height: 28px;
}
@media (max-width: 992px) {
  .webinar-registration .loyalty_first_fold .loyalty_left_side .agenda li {
    font-size: 14px;
    line-height: 22px;
  }
}
.webinar-registration .loyalty_first_fold .loyalty_right_side {
  width: 100%;
  max-width: 571px;
}
.webinar-registration .loyalty_first_fold .loyalty_right_side .hurry {
  background: #BEF8F8;
  border-radius: 24px;
  padding: 23px 30px;
}
@media (max-width: 992px) {
  .webinar-registration .loyalty_first_fold .loyalty_right_side .hurry {
    padding: 20px;
    margin: 0;
  }
}
.webinar-registration .loyalty_first_fold .loyalty_right_side .hurry .sec_title {
  font-size: 28px;
  line-height: 38px;
  letter-spacing: -0.02em;
  font-weight: 500;
  font-family: "Basier Circle Semibold";
  margin: 0;
}
.webinar-registration .display_table {
  flex-direction: unset;
}
.webinar-registration .banner {
  padding: 0 0 80px;
}
@media (max-width: 992px) {
  .webinar-registration .banner {
    height: unset;
    padding: 20px 0 40px;
  }
  .webinar-registration .banner .common_title {
    font-size: 28px;
    line-height: 32px;
  }
  .webinar-registration .banner .common_desc {
    font-size: 14px;
    line-height: 22px;
  }
}
.webinar-registration .sec_title {
  font-size: 42px;
  margin: 0 0 16px;
  line-height: 51px;
  letter-spacing: -0.02em;
  font-weight: 500;
  font-family: "Basier Circle medium";
}
@media (max-width: 992px) {
  .webinar-registration .sec_title {
    font-size: 28px;
    line-height: 32px;
  }
}
.webinar-registration .sec_desc {
  font-size: 36px;
  font-weight: 400;
  line-height: 43px;
  letter-spacing: -0.36px;
  font-family: "Basier Circle";
  padding: 12px 0;
}
@media (max-width: 992px) {
  .webinar-registration .sec_desc {
    font-size: 20px;
    line-height: 30px;
  }
}
.webinar-registration .common_title {
  font-size: 56px;
  line-height: 68px;
  letter-spacing: -0.01em;
  font-family: "Basier Circle medium";
}
.webinar-registration .common_desc {
  font-size: 18px;
  margin: 0 0 28px;
  line-height: 28px;
  font-family: "Basier Circle";
}
.webinar-registration .webinar_agenda .loyalty_first_fold {
  gap: 24px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
@media (max-width: 992px) {
  .webinar-registration .webinar_agenda .loyalty_first_fold {
    flex-wrap: wrap;
  }
}
.webinar-registration .webinar_agenda .loyalty_first_fold .loyalty_left_side {
  max-width: 620px;
}
.webinar-registration .webinar_agenda .loyalty_first_fold .loyalty_left_side .agenda_div {
  padding: 0 0 25px;
}
.webinar-registration .webinar_agenda .loyalty_first_fold .loyalty_left_side .agenda_div .agenda_title {
  font-size: 24px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.24px;
  padding: 0 0 15px;
}
@media (max-width: 992px) {
  .webinar-registration .webinar_agenda .loyalty_first_fold .loyalty_left_side .agenda_div .agenda_title {
    font-size: 20px;
    line-height: 24px;
  }
}
.webinar-registration .webinar_agenda .loyalty_first_fold .loyalty_left_side .agenda_div .common_desc {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}
@media (max-width: 992px) {
  .webinar-registration .webinar_agenda .loyalty_first_fold .loyalty_left_side .agenda_div .common_desc {
    font-size: 16px;
    line-height: 24px;
  }
}
.webinar-registration .webinar_agenda .loyalty_first_fold .loyalty_left_side .agenda_div .agenda {
  padding-left: 20px;
}
.webinar-registration .webinar_agenda .loyalty_first_fold .loyalty_left_side .agenda_div .agenda li {
  padding: 0 0 4px;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
}
@media (max-width: 992px) {
  .webinar-registration .webinar_agenda .loyalty_first_fold .loyalty_left_side .agenda_div .agenda li {
    font-size: 16px;
    line-height: 24px;
  }
}
.webinar-registration .webinar_agenda .loyalty_first_fold .loyalty_right_side {
  width: 100%;
  max-width: 571px;
  border-radius: 24px;
  background: #BEF8F8;
  padding: 30px 43px;
}
@media (max-width: 992px) {
  .webinar-registration .webinar_agenda .loyalty_first_fold .loyalty_right_side {
    padding: 20px;
  }
}
.webinar-registration .webinar_agenda .loyalty_first_fold .loyalty_right_side .sec_title {
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.56px;
  color: #0D9298;
}
@media (max-width: 992px) {
  .webinar-registration .webinar_agenda .loyalty_first_fold .loyalty_right_side .sec_title {
    font-size: 20px;
    line-height: 24px;
  }
}
.webinar-registration .webinar_agenda .loyalty_first_fold .loyalty_right_side .what_learn {
  padding: 0 0 20px;
}
.webinar-registration .webinar_agenda .loyalty_first_fold .loyalty_right_side .what_learn p {
  color: #003838;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
}
@media (max-width: 992px) {
  .webinar-registration .webinar_agenda .loyalty_first_fold .loyalty_right_side .what_learn p {
    font-size: 16px;
    line-height: 24px;
  }
}
.webinar-registration .webinar_agenda .loyalty_first_fold .loyalty_right_side .what_learn p strong {
  color: #021B1B;
}
.webinar-registration .speaker .speaker_wrapper {
  border-radius: 24px;
  padding: 30px 42px;
  height: 545px;
  margin: 0 15px;
  z-index: 1;
}
@media (max-width: 992px) {
  .webinar-registration .speaker .speaker_wrapper {
    padding: 30px 20px;
    height: unset;
    margin: 0 0 30px;
  }
}
.webinar-registration .speaker .speaker_wrapper .speakerimght {
  height: 180px;
  padding: 40px 0;
}
@media (max-width: 992px) {
  .webinar-registration .speaker .speaker_wrapper .speakerimght {
    height: unset;
    padding: 0;
  }
}
.webinar-registration .speaker .speaker_wrapper .about_speaker {
  font-size: 42px;
  font-weight: 600;
  line-height: 60px;
  letter-spacing: -0.84px;
  padding: 15px 0;
}
@media (max-width: 992px) {
  .webinar-registration .speaker .speaker_wrapper .about_speaker {
    font-size: 30px;
    line-height: 40px;
  }
}
.webinar-registration .speaker .speaker_wrapper .about_speaker_text {
  color: #001124;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
}
@media (max-width: 992px) {
  .webinar-registration .speaker .speaker_wrapper .about_speaker_text {
    font-size: 16px;
    line-height: 24px;
  }
}
.webinar-registration .speaker .speaker_wrapper .union {
  position: absolute;
  right: 0;
  bottom: 0;
  mix-blend-mode: darken;
  z-index: -1;
  border-bottom-right-radius: 24px;
}
@media (max-width: 992px) {
  .webinar-registration .speaker .speaker_wrapper .union {
    border-bottom-right-radius: 24px;
  }
}
.webinar-registration #webinars .webinar-box {
  padding: 30px 10px !important;
}
.webinar-registration #webinars .webinar-box:hover .video-thumbnail .play-icon-wrapper img {
  transform: scale(1.2);
  transition-duration: 0.3s;
  transition-property: transform;
}
.webinar-registration #webinars .webinar-box .webinar_Img .video-thumbnail .videoimg {
  border-bottom-left-radius: unset;
  border-bottom-right-radius: unset;
}
@media only screen and (max-width: 992px) {
  .webinar-registration #webinars .webinar-box .webinar_Img .video-thumbnail .videoimg {
    height: 180px;
  }
}
.webinar-registration #webinars .webinar-box .webinar_Img .video-thumbnail .play-icon-wrapper {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  cursor: pointer;
}
.webinar-registration #webinars .webinar-box .webinar_Img .play-icon {
  height: unset;
}
.webinar-registration #webinars .webinar-box .webinar_Img .videoimg {
  height: 206px;
  transition-duration: unset;
  transition-property: unset;
  transform: unset;
}
@media only screen and (max-width: 992px) {
  .webinar-registration #webinars .webinar-box .webinar_Img .videoimg {
    height: unset;
  }
}
.webinar-registration #webinars .webinar-box .webinar_Img iframe {
  border-radius: 8px;
}
@media only screen and (max-width: 992px) {
  .webinar-registration #webinars .webinar-box .webinar_Img iframe {
    height: 180px;
  }
}

.webiContent {
  float: left;
  margin: -5px 0 0;
  padding: 0px 20px 20px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  height: 330px;
}
@media (max-width: 992px) {
  .webiContent {
    height: unset;
  }
}

.restautant-retargeting .banner .bg1 {
  z-index: -1;
}
.restautant-retargeting .banner .bg2 {
  position: absolute;
  right: 0;
  bottom: -110px;
  z-index: -1;
}
@media (max-width: 992px) {
  .restautant-retargeting .banner .bg2 {
    bottom: 0;
  }
}
.restautant-retargeting .banner .banner_wrapper {
  padding: 70px 0px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 992px) {
  .restautant-retargeting .banner .banner_wrapper {
    padding: 40px 0 0;
    flex-wrap: wrap;
    gap: 30px;
  }
}
.restautant-retargeting .banner .banner_wrapper .banner_left_fold {
  max-width: 669px;
}
.restautant-retargeting .banner .banner_wrapper .banner_right_fold {
  width: 100%;
  max-width: 538px;
  padding: 24px;
}
@media (max-width: 992px) {
  .restautant-retargeting .banner .banner_wrapper .banner_right_fold {
    padding: 0;
  }
}
.restautant-retargeting .banner .banner_wrapper .banner-container .rating p {
  color: #697777;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
}
@media (max-width: 992px) {
  .restautant-retargeting .banner .banner_wrapper .banner-container .rating p {
    font-size: 14px;
    line-height: 20px;
  }
}
.restautant-retargeting .banner .banner_wrapper .banner-container .banner-title {
  font-size: 64px;
  line-height: 70px;
  margin: 12px 0 22px;
  letter-spacing: -2.56px;
  font-family: "Basier Circle medium";
}
@media (max-width: 992px) {
  .restautant-retargeting .banner .banner_wrapper .banner-container .banner-title {
    margin: 12px auto 16px;
    font-size: 28px;
    line-height: 32px;
    text-align: center;
    letter-spacing: -1.36px;
  }
}
.restautant-retargeting .banner .banner_wrapper .banner-container .banner-text {
  font-size: 20px;
  margin: 0 0 40px;
  line-height: 30px;
}
@media (max-width: 992px) {
  .restautant-retargeting .banner .banner_wrapper .banner-container .banner-text {
    margin: 0 0 40px;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
  }
}
.restautant-retargeting .banner .banner_wrapper .banner-container .btn_wrapper {
  width: 330px;
  margin: 50px 0 40px;
}
.restautant-retargeting .banner .banner_wrapper .banner-container .btn_wrapper a {
  width: 100%;
}
.restautant-retargeting .banner .banner_wrapper .banner-container .btn_wrapper .text-small {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  display: block;
  margin: 8px 0 0;
}
@media (max-width: 992px) {
  .restautant-retargeting .banner .banner_wrapper .banner-container .btn_wrapper {
    width: unset;
    margin: 0 0 30px;
  }
}
.restautant-retargeting .banner .banner_wrapper .banner-container .flex_div {
  display: flex;
  align-items: center;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}
@media (max-width: 992px) {
  .restautant-retargeting .banner .banner_wrapper .banner-container .flex_div {
    gap: 11px;
    margin: 0 0 30px;
    justify-content: center;
  }
}
.restautant-retargeting .banner .banner_wrapper .banner-container .flex_div img {
  width: 75px;
}
@media (max-width: 992px) {
  .restautant-retargeting .banner .banner_wrapper .banner-container .flex_div img {
    width: 40px;
  }
}
.restautant-retargeting .banner .disFlex {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}
@media (max-width: 992px) {
  .restautant-retargeting .banner .disFlex {
    display: block;
  }
}
.restautant-retargeting .fold1 .sec-title {
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: 64px;
  letter-spacing: -0.56px;
  font-family: "Basier Circle medium";
}
@media (max-width: 992px) {
  .restautant-retargeting .fold1 .sec-title {
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 24px;
  }
}
.restautant-retargeting #section2 .sec-title {
  font-size: 58px;
  font-style: normal;
  color: #0e1b1b;
  line-height: 64px;
  text-align: center;
  margin: 0 auto 48px;
  letter-spacing: -0.02em;
  font-family: "Basier Circle Semibold";
}
@media (max-width: 992px) {
  .restautant-retargeting #section2 .sec-title {
    font-size: 28px;
    line-height: 32px;
    letter-spacing: -0.96px;
    width: 100%;
    margin-bottom: 24px;
  }
}
.restautant-retargeting #section2 .tooltabs {
  border-radius: 49px;
  background: #f5f5f5;
  padding: 10px;
  width: 43%;
  margin: 30px auto 60px;
}
@media (max-width: 992px) {
  .restautant-retargeting #section2 .tooltabs {
    margin: 20px auto 40px;
    width: 100%;
  }
}
.restautant-retargeting #section2 .tooltabs a {
  color: #697777;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.32px;
  font-family: "Basier Circle medium";
  padding: 3px 30px;
  cursor: pointer;
}
@media (max-width: 992px) {
  .restautant-retargeting #section2 .tooltabs a {
    font-size: 12px;
    line-height: 16px;
    padding: 3px 12px;
  }
}
.restautant-retargeting #section2 .tooltabs a.active {
  color: #212123;
  border-radius: 52px;
  background: #fff;
  -webkit-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.08);
  padding: 6px 30px;
}
@media (max-width: 992px) {
  .restautant-retargeting #section2 .tooltabs a.active {
    font-size: 12px;
    line-height: 16px;
    padding: 3px 12px;
  }
}
.restautant-retargeting #section2 .tools, .restautant-retargeting #section2 .tool {
  padding: 10px;
  border-radius: 16px;
  border: 1px solid #e9eded;
  background: #fff;
  -webkit-box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.05), 0 0 1px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.05), 0 0 1px 0 rgba(0, 0, 0, 0.25);
}
@media (max-width: 992px) {
  .restautant-retargeting #section2 .tools, .restautant-retargeting #section2 .tool {
    background: #f7f8f8;
  }
}
.restautant-retargeting #section2 .tools .card_img img, .restautant-retargeting #section2 .tool .card_img img {
  height: 443px;
  border-radius: 8px;
  object-fit: cover;
}
@media (max-width: 992px) {
  .restautant-retargeting #section2 .tools .card_img img, .restautant-retargeting #section2 .tool .card_img img {
    height: 100%;
  }
}
.restautant-retargeting #section2 .tools .tools_wrapper, .restautant-retargeting #section2 .tool .tools_wrapper {
  padding: 30px;
}
@media (max-width: 992px) {
  .restautant-retargeting #section2 .tools .tools_wrapper, .restautant-retargeting #section2 .tool .tools_wrapper {
    padding: 0;
  }
}
.restautant-retargeting #section2 .tools .tools_wrapper h3, .restautant-retargeting #section2 .tool .tools_wrapper h3 {
  color: #0e1b1b;
  font-size: 41px;
  font-style: normal;
  font-weight: 600;
  line-height: 49.2px;
  letter-spacing: -2.05px;
  margin-bottom: 0;
  margin: 15px 0 0;
}
@media (max-width: 992px) {
  .restautant-retargeting #section2 .tools .tools_wrapper h3, .restautant-retargeting #section2 .tool .tools_wrapper h3 {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.36px;
  }
}
.restautant-retargeting #section2 .tools .tools_wrapper p, .restautant-retargeting #section2 .tool .tools_wrapper p {
  margin: 16px 0;
  font-size: 16px;
  color: #697777;
  line-height: 22px;
  font-style: normal;
}
@media (max-width: 992px) {
  .restautant-retargeting #section2 .tools .tools_wrapper p, .restautant-retargeting #section2 .tool .tools_wrapper p {
    margin: 8px 0;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.14px;
  }
}
.restautant-retargeting #section2 .tools .tools_wrapper .learnmore a, .restautant-retargeting #section2 .tool .tools_wrapper .learnmore a {
  display: block;
  padding: 20px 0;
  text-decoration: underline !important;
  font-weight: 500;
  font-family: "Basier Circle medium";
  font-size: 16px;
  cursor: pointer;
}
.restautant-retargeting #section3 .bgcolor {
  background-color: #f1fafa;
  margin: 0 40px;
}
@media (max-width: 992px) {
  .restautant-retargeting #section3 .bgcolor {
    margin: 0;
    padding: 40px 0 30px;
  }
}
.restautant-retargeting #section3 .sec-title {
  font-size: 58px;
  font-style: normal;
  color: #0e1b1b;
  line-height: 64px;
  text-align: center;
  margin: 0 auto 48px;
  letter-spacing: -0.02em;
  font-family: "Basier Circle Semibold";
}
@media (max-width: 992px) {
  .restautant-retargeting #section3 .sec-title {
    font-size: 28px;
    line-height: 32px;
    letter-spacing: -0.96px;
    width: 100%;
    margin-bottom: 24px;
  }
}
.restautant-retargeting #section3 .counter_wrapper {
  padding: 30px 0;
}
@media (max-width: 992px) {
  .restautant-retargeting #section3 .counter_wrapper {
    padding: 0;
  }
}
.restautant-retargeting #section3 .counter_wrapper .blr::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 75px;
  background-color: #0e1b1b;
  opacity: 0.3;
}
@media (max-width: 992px) {
  .restautant-retargeting #section3 .counter_wrapper .blr::before {
    top: -22px;
    left: 50%;
    transform: translateX(-50%);
    width: 75px;
    height: 1px;
  }
}
.restautant-retargeting #section3 .counter_wrapper .blr::after {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 75px;
  background-color: #0e1b1b;
  opacity: 0.3;
}
@media (max-width: 992px) {
  .restautant-retargeting #section3 .counter_wrapper .blr::after {
    bottom: 22px;
    right: unset;
    left: 50%;
    transform: translateX(-50%);
    width: 75px;
    height: 1px;
    top: unset;
  }
}
@media (max-width: 992px) {
  .restautant-retargeting #section3 .counter_wrapper .counter_div {
    height: 147px;
  }
}
.restautant-retargeting #section3 .counter_wrapper .counter_div .counter_num p {
  color: #0d9298;
  text-align: center;
  font-size: 64px;
  line-height: normal;
  letter-spacing: -0.64px;
  margin-bottom: 0;
  font-family: "Basier Circle medium";
}
.restautant-retargeting #section3 .counter_wrapper .counter_div .counter_text {
  color: #0e1b1b;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.2px;
  padding: 15px 0 0;
}
@media (max-width: 992px) {
  .restautant-retargeting #section3 .counter_wrapper .counter_div .counter_text {
    font-size: 14px;
    line-height: 20px;
    padding: 10px 0 0;
  }
}
.restautant-retargeting #section4 .sec-title {
  font-size: 58px;
  font-style: normal;
  color: #0e1b1b;
  line-height: 64px;
  text-align: center;
  margin: 0 auto 48px;
  letter-spacing: -0.02em;
  font-family: "Basier Circle Semibold";
}
@media (max-width: 992px) {
  .restautant-retargeting #section4 .sec-title {
    font-size: 28px;
    line-height: 32px;
    letter-spacing: -0.96px;
    width: 100%;
    margin-bottom: 24px;
  }
}
.restautant-retargeting #section4 .slider {
  padding: 40px 0 60px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
@media (max-width: 767px) {
  .restautant-retargeting #section4 .slider {
    display: block;
    padding: 20px 0 0px;
  }
  .restautant-retargeting #section4 .slider .slider-prev {
    bottom: 0;
    left: calc(50% - 62px);
  }
  .restautant-retargeting #section4 .slider .slider-next {
    bottom: 0;
    right: calc(50% - 62px);
  }
}
.restautant-retargeting #section4 .slider .slide {
  border-radius: 12px;
  padding: 20px 24px;
  height: 440px;
  margin: 0 10px;
}
.restautant-retargeting #section4 .slider .slide .main_img {
  background-color: #fff;
  border-radius: 50%;
  width: 65px;
  height: 65px;
}
@media (max-width: 992px) {
  .restautant-retargeting #section4 .slider .slide {
    height: 460px;
    margin: 15px 0;
  }
}
.restautant-retargeting #section4 .slider .slide .slidetext {
  padding: 16px 0 28px;
  color: #0e1b1b;
  font-weight: 400;
  margin: 20px 0 28px;
  height: 210px;
  font-size: 16px;
  line-height: 22px;
}
@media (max-width: 992px) {
  .restautant-retargeting #section4 .slider .slide .slidetext {
    height: 250px;
  }
}
.restautant-retargeting #section4 .slider .slide1 {
  background-color: #FFECEB;
}
.restautant-retargeting #section4 .slider .slide2 {
  background-color: #FFF9F1;
}
.restautant-retargeting #section4 .slider .slide3 {
  background-color: #FFF9E5;
}
.restautant-retargeting #section4 .slider .slide_flex {
  display: flex;
  align-items: center;
  gap: 15px;
}
.restautant-retargeting #section4 .slider .slide_flex .alpha {
  width: 40px;
  height: 40px;
  background-color: #0d9298;
  color: #fff;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  line-height: 22px;
  font-family: "Basier Circle Semibold";
  padding: 8px 14px;
}
.restautant-retargeting #section4 .slider .slide_flex .author img {
  border-radius: 100%;
}
.restautant-retargeting #section4 .slider .slide_flex .author_desc .sec-desc {
  padding: 0;
}
.restautant-retargeting #section4 .slider .slide_flex .author_desc span {
  font-size: 14px;
  line-height: 18px;
}
.restautant-retargeting .qrcode_reviews .qr_wrapper {
  padding-right: 60px;
}
@media (max-width: 992px) {
  .restautant-retargeting .qrcode_reviews .qr_wrapper {
    padding-right: 0;
  }
}
.restautant-retargeting .qrcode_reviews .qr_wrapper .loyalty_tabs {
  cursor: pointer;
  margin: 0 0 16px;
  padding: 16px 24px;
  border-radius: 16px;
  transition: all 0.5s;
  background: #f2f2f2;
}
.restautant-retargeting .qrcode_reviews .qr_wrapper .loyalty_tabs h3 {
  font-size: 20px;
  color: #182020;
  line-height: 26px;
}
.restautant-retargeting .qrcode_reviews .qr_wrapper .loyalty_tabs p {
  display: none;
  margin: 8px 0 0;
  font-size: 16px;
  color: #fff;
  line-height: 22px;
  text-align: left;
}
.restautant-retargeting .qrcode_reviews .qr_wrapper .loyalty_tabs ul {
  display: none;
  padding: 10px 0 0 15px;
}
.restautant-retargeting .qrcode_reviews .qr_wrapper .loyalty_tabs ul li {
  font-size: 16px;
  color: #fff;
  line-height: 22px;
  text-align: left;
  padding: 0 0 5px 5px;
  position: relative;
  list-style: none;
}
.restautant-retargeting .qrcode_reviews .qr_wrapper .loyalty_tabs ul li:before {
  position: absolute;
  content: "✔";
  left: -13px;
}
.restautant-retargeting .qrcode_reviews .qr_wrapper .loyalty_tabs.active {
  background-color: #01999f;
}
.restautant-retargeting .qrcode_reviews .qr_wrapper .loyalty_tabs.active h3 {
  color: #fff;
}
.restautant-retargeting .qrcode_reviews .qr_wrapper .loyalty_tabs.active p, .restautant-retargeting .qrcode_reviews .qr_wrapper .loyalty_tabs.active ul {
  display: block;
}
.restautant-retargeting .qrcode_reviews .qr_slider .slick-dots {
  display: flex;
  margin: 0;
  list-style-type: none;
  position: absolute;
  bottom: -9px;
  left: 50%;
  transform: translateX(-50%);
}
.restautant-retargeting .qrcode_reviews .qr_slider .slick-dots button {
  display: block;
  width: 6px;
  height: 6px;
  padding: 0;
  background-color: grey;
  text-indent: -9999px;
  cursor: pointer;
  border-radius: 30px;
  border: unset;
  margin: 0 3px;
}
.restautant-retargeting .qrcode_reviews .qr_slider li.slick-active button {
  background-color: #000;
}
.restautant-retargeting .qrcode_reviews .qr_slider img {
  border-radius: 16px;
}
@media (max-width: 992px) {
  .restautant-retargeting .qrcode_reviews .qr_slider .qr_desc {
    text-align: left;
  }
}
.restautant-retargeting .qrcode_reviews .qr_slider .qr_desc h3 {
  font-size: 18px;
  color: #0e1b1b;
  line-height: 24px;
  margin: 32px auto 8px;
}
.restautant-retargeting .qrcode_reviews .qr_slider .qr_desc p {
  font-size: 14px;
  color: #5a6565;
  max-width: 400px;
  line-height: 20px;
  margin: 0 auto 8px;
}
.restautant-retargeting .qrcode_reviews .qr_slider .qr_desc ul {
  padding: 10px 14px 20px;
}
.restautant-retargeting .qrcode_reviews .qr_slider .qr_desc ul li {
  font-size: 14px;
  color: #5a6565;
  max-width: 400px;
  line-height: 20px;
  list-style: none;
  padding-bottom: 5px;
  position: relative;
}
.restautant-retargeting .qrcode_reviews .qr_slider .qr_desc ul li:before {
  position: absolute;
  content: "✔";
  margin-left: -14px;
}
.restautant-retargeting .what-reelo-offers .whatreelo_offers {
  display: flex;
  justify-content: center;
  gap: 30px;
  padding: 15px;
}
@media (max-width: 992px) {
  .restautant-retargeting .what-reelo-offers .whatreelo_offers {
    padding: 0;
    gap: 10px;
    margin: 5px 0;
  }
}
.restautant-retargeting .what-reelo-offers .whatreelo_offers .whatreelo_offers_div {
  width: 343px;
  height: 196px;
  padding: 32px 22px;
  border-radius: 17px;
}
@media (max-width: 992px) {
  .restautant-retargeting .what-reelo-offers .whatreelo_offers .whatreelo_offers_div {
    height: 130px;
    padding: 15px;
  }
  .restautant-retargeting .what-reelo-offers .whatreelo_offers .whatreelo_offers_div img {
    width: 35px;
  }
}
.restautant-retargeting .what-reelo-offers .whatreelo_offers .whatreelo_offers_div p {
  color: #171E59;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  padding: 15px 0 0;
}
@media (max-width: 992px) {
  .restautant-retargeting .what-reelo-offers .whatreelo_offers .whatreelo_offers_div p {
    font-size: 14px;
  }
}
.restautant-retargeting .what-reelo-offers .whatreelo_offers .offer_1 {
  background: #EDFCF2;
}
.restautant-retargeting .what-reelo-offers .whatreelo_offers .offer_2 {
  background: #FEF6EE;
}
.restautant-retargeting .what-reelo-offers .whatreelo_offers .offer_3 {
  background: #F5F8FF;
}
.restautant-retargeting .what-reelo-offers .whatreelo_offers .offer_4 {
  background: #FFE5EB;
}
.restautant-retargeting .what-reelo-offers .whatreelo_offers .offer_5 {
  background: #DDFEFF;
}
.restautant-retargeting .what-reelo-offers .whatreelo_offers .offer_6 {
  background: #FFF1D4;
}
.restautant-retargeting .pricing .sec-heading2 {
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: 64px;
  letter-spacing: -0.56px;
  font-family: "Basier Circle medium";
}
@media (max-width: 992px) {
  .restautant-retargeting .pricing .sec-heading2 {
    font-size: 28px;
    line-height: 32px;
  }
}
.restautant-retargeting .pricing_card-rows {
  display: flex;
  justify-content: center;
  gap: 25px;
}
@media (max-width: 992px) {
  .restautant-retargeting .pricing_card-rows {
    display: block;
  }
}
.restautant-retargeting .pricing_card-rows .cards {
  width: 370px;
  height: 100%;
  min-height: 380px;
  padding: 24px 32px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (max-width: 992px) {
  .restautant-retargeting .pricing_card-rows .cards {
    width: 100%;
  }
}
.restautant-retargeting .pricing_card-rows .cards .white-btn {
  color: #1A424F;
  border: 1px solid #1A424F;
  border-radius: 8px;
  background-color: transparent;
  width: 100%;
}
.restautant-retargeting .pricing_card-rows .cards .theme-btn {
  width: 100%;
}
.restautant-retargeting .pricing_card-rows .cards h3 {
  font-size: 24px;
  color: #354545;
  line-height: 30px;
  margin-bottom: 8px;
  font-weight: 700;
}
.restautant-retargeting .pricing_card-rows .cards h4 {
  font-size: 48px;
  line-height: 67px;
  color: #182020;
  font-weight: 600;
}
.restautant-retargeting .pricing_card-rows .cards h4 p {
  display: inline;
  font-size: 48px;
  line-height: 67px;
  color: #182020;
  font-weight: 400;
}
.restautant-retargeting .pricing_card-rows .cards h4 span {
  font-size: 14px;
  line-height: 18px;
}
.restautant-retargeting .pricing_card-rows .cards h6 {
  font-size: 16px;
  line-height: 30px;
  color: rgba(24, 32, 32, 0.75);
  font-weight: 400;
}
.restautant-retargeting .pricing_card-rows .cards .pricing_desc2 {
  font-size: 14px;
  color: #1a424f;
  line-height: 28px;
  text-align: center;
}
.restautant-retargeting .pricing_card-rows .cards .pricing_features {
  display: flex;
  margin: 24px 0;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}
.restautant-retargeting .pricing_card-rows .pricing_plan-card {
  background: #f5f5f5;
}
.restautant-retargeting .pricing_card-rows .pricing_plan-card__3SDlI {
  background: linear-gradient(157.35deg, #c4f2f4 14.72%, #9cffed 102.4%);
}
.restautant-retargeting .pricing_term {
  font-size: 12px;
  line-height: 17px;
  text-align: right;
  color: #838c8c;
}
@media (max-width: 992px) {
  .restautant-retargeting .pre-built .common_title {
    font-size: 28px;
    line-height: 32px;
  }
}
@media (max-width: 992px) {
  .restautant-retargeting .pre-built .common_desc {
    font-size: 18px;
  }
}
.restautant-retargeting .pre-built .campaigns_feature_card_root {
  gap: 24px;
  margin: 0 auto;
  padding: 0 16px;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 992px) {
  .restautant-retargeting .pre-built .campaigns_feature_card_root {
    display: block;
    border-radius: 16px;
    padding: 0;
  }
  .restautant-retargeting .pre-built .campaigns_feature_card_root .slick-dots {
    display: flex;
    margin: 0;
    list-style-type: none;
    position: absolute;
    bottom: -9px;
    left: 50%;
    transform: translateX(-50%);
  }
  .restautant-retargeting .pre-built .campaigns_feature_card_root .slick-dots button {
    display: block;
    width: 12px;
    height: 12px;
    padding: 0;
    background-color: #c6c6c6;
    text-indent: -9999px;
    cursor: pointer;
    border-radius: 30px;
    border: unset;
    margin: 0 3px;
  }
  .restautant-retargeting .pre-built .campaigns_feature_card_root li.slick-active button {
    background-color: #fff;
  }
}
.restautant-retargeting .pre-built .campaigns_feature_card_root .campaigns_feature_card {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  border-radius: 16px;
  background: #f3f1ee;
  width: calc(50% - 12px);
}
@media (max-width: 992px) {
  .restautant-retargeting .pre-built .campaigns_feature_card_root .campaigns_feature_card {
    display: block;
    padding: 0;
    width: 100%;
    margin: 0 0 30px;
  }
}
.restautant-retargeting .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_left_side {
  padding: 30px;
}
@media (max-width: 992px) {
  .restautant-retargeting .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_left_side {
    padding: 34px 4px 34px 28px;
  }
}
.restautant-retargeting .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_left_side .campaigns_feature_number {
  padding: 4px 9px;
  width: 28px;
  height: 28px;
  font-size: 16px;
  color: #fff;
  line-height: 22px;
  border-radius: 8px;
  background: #065151;
  font-family: "Basier Circle Semibold";
}
.restautant-retargeting .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_left_side h3 {
  margin: 8px 0;
  font-size: 28px;
  color: #0e1b1b;
  line-height: 34px;
  letter-spacing: -0.02em;
  font-family: "Basier Circle Semibold";
}
@media (max-width: 992px) {
  .restautant-retargeting .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_left_side h3 {
    font-size: 20px;
    line-height: 24px;
  }
}
.restautant-retargeting .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_left_side p {
  font-size: 18px;
  color: #232d2d;
  line-height: 130%;
}
.restautant-retargeting .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_right_side {
  width: 100%;
  position: relative;
  bottom: -6px;
}
@media (max-width: 992px) {
  .restautant-retargeting .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_right_side {
    text-align: center;
    padding: 0 15px;
    max-width: 100%;
    bottom: 0;
  }
  .restautant-retargeting .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_right_side img {
    height: 300px;
  }
}
.restautant-retargeting .pre-built .campaigns_feature_card_root .last_card {
  width: 100%;
  height: auto;
}
.restautant-retargeting .benifits_section .bene_div {
  padding: 32px 30px;
  border-radius: 16px;
  width: 31%;
  margin: 40px 14px 0;
}
@media (max-width: 992px) {
  .restautant-retargeting .benifits_section .bene_div {
    width: 100%;
    margin: 30px 0 0;
  }
}
.restautant-retargeting .benifits_section .bene_div .bene_inner {
  padding: 36px 22px;
  background: #fff;
  border-radius: 16.7273px;
}
.restautant-retargeting .benifits_section .bene_div .bene_inner p {
  font-size: 20px;
  color: #171e59;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.02em;
  font-family: "Basier Circle medium";
}
.restautant-retargeting .benifits_section .bene_div .bene_inner span {
  font-size: 48px;
  line-height: 62px;
  text-align: center;
  letter-spacing: -0.02em;
  font-family: "Basier Circle Semibold";
  display: block;
}
.restautant-retargeting .benifits_section .bene_div .bene_inner .green {
  color: #16b364;
}
.restautant-retargeting .benifits_section .bene_div .bene_inner .red {
  color: #ef6820;
}
.restautant-retargeting .benifits_section .bene_div .bene_inner .blue {
  color: #1570ef;
}
.restautant-retargeting .benifits_section .b1 {
  background: #edfcf2;
}
.restautant-retargeting .benifits_section .b2 {
  background: #fef6ee;
}
.restautant-retargeting .benifits_section .b3 {
  background: #f5f8ff;
}

.cafe-marketing .banner .bg1 {
  z-index: -1;
}
.cafe-marketing .banner .bg2 {
  position: absolute;
  right: 0;
  bottom: -110px;
  z-index: -1;
}
@media (max-width: 992px) {
  .cafe-marketing .banner .bg2 {
    bottom: 0;
  }
}
.cafe-marketing .banner .banner_wrapper {
  padding: 70px 0px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 992px) {
  .cafe-marketing .banner .banner_wrapper {
    padding: 40px 0 0;
    flex-wrap: wrap;
    gap: 30px;
  }
}
.cafe-marketing .banner .banner_wrapper .banner_left_fold {
  max-width: 669px;
}
.cafe-marketing .banner .banner_wrapper .banner_right_fold {
  width: 100%;
  max-width: 538px;
  padding: 24px;
}
@media (max-width: 992px) {
  .cafe-marketing .banner .banner_wrapper .banner_right_fold {
    padding: 0;
  }
}
.cafe-marketing .banner .banner_wrapper .banner-container .rating p {
  color: #697777;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
}
@media (max-width: 992px) {
  .cafe-marketing .banner .banner_wrapper .banner-container .rating p {
    font-size: 14px;
    line-height: 20px;
  }
}
.cafe-marketing .banner .banner_wrapper .banner-container .banner-title {
  font-size: 64px;
  line-height: 70px;
  margin: 12px 0 22px;
  letter-spacing: -2.56px;
  font-family: "Basier Circle medium";
}
@media (max-width: 992px) {
  .cafe-marketing .banner .banner_wrapper .banner-container .banner-title {
    margin: 12px auto 16px;
    font-size: 28px;
    line-height: 32px;
    text-align: center;
    letter-spacing: -1.36px;
  }
}
.cafe-marketing .banner .banner_wrapper .banner-container .banner-text {
  font-size: 20px;
  margin: 0 0 40px;
  line-height: 30px;
}
@media (max-width: 992px) {
  .cafe-marketing .banner .banner_wrapper .banner-container .banner-text {
    margin: 0 0 40px;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
  }
}
.cafe-marketing .banner .banner_wrapper .banner-container .btn_wrapper {
  width: 330px;
  margin: 50px 0 40px;
}
.cafe-marketing .banner .banner_wrapper .banner-container .btn_wrapper a {
  width: 100%;
}
.cafe-marketing .banner .banner_wrapper .banner-container .btn_wrapper .text-small {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  display: block;
  margin: 8px 0 0;
}
@media (max-width: 992px) {
  .cafe-marketing .banner .banner_wrapper .banner-container .btn_wrapper {
    width: unset;
    margin: 0 0 30px;
  }
}
.cafe-marketing .banner .banner_wrapper .banner-container .flex_div {
  display: flex;
  align-items: center;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}
@media (max-width: 992px) {
  .cafe-marketing .banner .banner_wrapper .banner-container .flex_div {
    margin: 0 0 30px;
    gap: 11px;
    justify-content: center;
  }
}
.cafe-marketing .banner .banner_wrapper .banner-container .flex_div img {
  width: 75px;
}
@media (max-width: 992px) {
  .cafe-marketing .banner .banner_wrapper .banner-container .flex_div img {
    width: 40px;
  }
}
.cafe-marketing .banner .disFlex {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}
@media (max-width: 992px) {
  .cafe-marketing .banner .disFlex {
    display: block;
  }
}
.cafe-marketing #section2 .sec-title {
  font-size: 58px;
  font-style: normal;
  color: #0e1b1b;
  line-height: 64px;
  text-align: center;
  margin: 0 auto 48px;
  letter-spacing: -0.02em;
  font-family: "Basier Circle Semibold";
}
@media (max-width: 992px) {
  .cafe-marketing #section2 .sec-title {
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.96px;
    width: 100%;
    margin-bottom: 24px;
  }
}
.cafe-marketing #section2 .tooltabs {
  border-radius: 49px;
  background: #f5f5f5;
  padding: 10px;
  width: 32%;
  margin: 30px auto 60px;
}
@media (max-width: 992px) {
  .cafe-marketing #section2 .tooltabs {
    margin: 20px auto 40px;
    width: 100%;
  }
}
.cafe-marketing #section2 .tooltabs a {
  color: #697777;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.32px;
  font-family: "Basier Circle medium";
  padding: 3px 30px;
  cursor: pointer;
}
@media (max-width: 992px) {
  .cafe-marketing #section2 .tooltabs a {
    font-size: 12px;
    line-height: 16px;
    padding: 3px 12px;
  }
}
.cafe-marketing #section2 .tooltabs a.active {
  color: #212123;
  border-radius: 52px;
  background: #fff;
  -webkit-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.08);
  padding: 6px 30px;
}
@media (max-width: 992px) {
  .cafe-marketing #section2 .tooltabs a.active {
    font-size: 12px;
    line-height: 16px;
    padding: 3px 12px;
  }
}
.cafe-marketing #section2 .tools {
  padding: 10px;
  border-radius: 16px;
  border: 1px solid #e9eded;
  background: #fff;
  -webkit-box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.05), 0 0 1px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.05), 0 0 1px 0 rgba(0, 0, 0, 0.25);
}
@media (max-width: 992px) {
  .cafe-marketing #section2 .tools {
    background: #f7f8f8;
  }
}
.cafe-marketing #section2 .tools .card_img img {
  height: 443px;
  border-radius: 8px;
  object-fit: cover;
}
@media (max-width: 992px) {
  .cafe-marketing #section2 .tools .card_img img {
    height: 100%;
  }
}
.cafe-marketing #section2 .tools .tools_wrapper {
  padding: 30px;
}
@media (max-width: 992px) {
  .cafe-marketing #section2 .tools .tools_wrapper {
    padding: 0;
  }
}
.cafe-marketing #section2 .tools .tools_wrapper h3 {
  color: #0e1b1b;
  font-size: 41px;
  font-style: normal;
  font-weight: 600;
  line-height: 49.2px;
  letter-spacing: -2.05px;
  margin-bottom: 0;
  margin: 15px 0 0;
}
@media (max-width: 992px) {
  .cafe-marketing #section2 .tools .tools_wrapper h3 {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.36px;
  }
}
.cafe-marketing #section2 .tools .tools_wrapper p {
  margin: 16px 0;
  font-size: 16px;
  color: #697777;
  line-height: 22px;
  font-style: normal;
}
@media (max-width: 992px) {
  .cafe-marketing #section2 .tools .tools_wrapper p {
    margin: 8px 0;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.14px;
  }
}
.cafe-marketing #section2 .tools .tools_wrapper .learnmore a {
  display: block;
  padding: 20px 0;
  text-decoration: underline !important;
  font-weight: 500;
  font-family: "Basier Circle medium";
  font-size: 16px;
  cursor: pointer;
}
.cafe-marketing #section3 .bgcolor {
  background-color: #f1fafa;
  margin: 0 40px;
  padding: 40px 0;
}
@media (max-width: 992px) {
  .cafe-marketing #section3 .bgcolor {
    margin: 0;
    padding: 40px 0 30px;
  }
}
.cafe-marketing #section3 .sec-title {
  font-size: 58px;
  font-style: normal;
  color: #0e1b1b;
  line-height: 64px;
  text-align: center;
  margin: 0 auto 48px;
  letter-spacing: -0.02em;
  font-family: "Basier Circle Semibold";
}
@media (max-width: 992px) {
  .cafe-marketing #section3 .sec-title {
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.96px;
    width: 100%;
    margin-bottom: 24px;
  }
}
.cafe-marketing #section3 .counter_wrapper {
  padding: 0px 0 30px;
}
@media (max-width: 992px) {
  .cafe-marketing #section3 .counter_wrapper {
    padding: 0;
  }
}
.cafe-marketing #section3 .counter_wrapper .blr::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 75px;
  background-color: #0e1b1b;
  opacity: 0.3;
}
@media (max-width: 992px) {
  .cafe-marketing #section3 .counter_wrapper .blr::before {
    top: -6px;
    left: 50%;
    transform: translateX(-50%);
    width: 75px;
    height: 1px;
  }
}
.cafe-marketing #section3 .counter_wrapper .blr::after {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 75px;
  background-color: #0e1b1b;
  opacity: 0.3;
}
@media (max-width: 992px) {
  .cafe-marketing #section3 .counter_wrapper .blr::after {
    bottom: 4px;
    right: unset;
    left: 50%;
    transform: translateX(-50%);
    width: 75px;
    height: 1px;
    top: unset;
  }
}
@media (max-width: 992px) {
  .cafe-marketing #section3 .counter_wrapper .counter_div {
    height: 147px;
  }
}
.cafe-marketing #section3 .counter_wrapper .counter_div .counter_num p {
  color: #0d9298;
  text-align: center;
  font-size: 64px;
  line-height: normal;
  letter-spacing: -0.64px;
  margin-bottom: 0;
  font-family: "Basier Circle medium";
}
.cafe-marketing #section3 .counter_wrapper .counter_div .counter_text {
  color: #0e1b1b;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.2px;
  padding: 15px 0 0;
}
@media (max-width: 992px) {
  .cafe-marketing #section3 .counter_wrapper .counter_div .counter_text {
    font-size: 14px;
    line-height: 20px;
    padding: 10px 0 0;
  }
}
.cafe-marketing #section4 .sec-title {
  font-size: 58px;
  font-style: normal;
  color: #0e1b1b;
  line-height: 64px;
  text-align: center;
  margin: 0 auto 48px;
  letter-spacing: -0.02em;
  font-family: "Basier Circle Semibold";
}
@media (max-width: 992px) {
  .cafe-marketing #section4 .sec-title {
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.96px;
    width: 100%;
    margin-bottom: 24px;
  }
}
.cafe-marketing #section4 .slider {
  padding: 40px 0 60px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
@media (max-width: 767px) {
  .cafe-marketing #section4 .slider {
    display: block;
    padding: 20px 0 0px;
  }
  .cafe-marketing #section4 .slider .slider-prev {
    bottom: 0;
    left: calc(50% - 62px);
  }
  .cafe-marketing #section4 .slider .slider-next {
    bottom: 0;
    right: calc(50% - 62px);
  }
}
.cafe-marketing #section4 .slider .slide {
  border-radius: 12px;
  padding: 20px 24px;
  height: 440px;
  margin: 0 10px;
}
.cafe-marketing #section4 .slider .slide .main_img {
  background-color: #fff;
  border-radius: 50%;
  width: 65px;
  height: 65px;
}
@media (max-width: 992px) {
  .cafe-marketing #section4 .slider .slide {
    height: 460px;
    margin: 15px 0;
  }
}
.cafe-marketing #section4 .slider .slide .slidetext {
  padding: 16px 0 28px;
  color: #0e1b1b;
  font-weight: 400;
  margin: 20px 0 28px;
  height: 210px;
  font-size: 16px;
  line-height: 22px;
}
@media (max-width: 992px) {
  .cafe-marketing #section4 .slider .slide .slidetext {
    height: 250px;
  }
}
.cafe-marketing #section4 .slider .slide1 {
  background-color: #FFECEB;
}
.cafe-marketing #section4 .slider .slide2 {
  background-color: #FFF9F1;
}
.cafe-marketing #section4 .slider .slide3 {
  background-color: #FFF9E5;
}
.cafe-marketing #section4 .slider .slide_flex {
  display: flex;
  align-items: center;
  gap: 15px;
}
.cafe-marketing #section4 .slider .slide_flex .alpha {
  width: 40px;
  height: 40px;
  background-color: #0d9298;
  color: #fff;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  line-height: 22px;
  font-family: "Basier Circle Semibold";
  padding: 8px 14px;
}
.cafe-marketing #section4 .slider .slide_flex .author img {
  border-radius: 100%;
}
.cafe-marketing #section4 .slider .slide_flex .author_desc .sec-desc {
  padding: 0;
}
.cafe-marketing #section4 .slider .slide_flex .author_desc span {
  font-size: 14px;
  line-height: 18px;
}

.petpooja .faq .sec_title {
  margin: 0 0 40px;
  font-size: 32px;
  color: #182020;
  line-height: 40px;
  font-weight: 700;
}
.petpooja .petpooja_first_fold {
  padding: 180px 0 90px;
}
@media (max-width: 992px) {
  .petpooja .petpooja_first_fold {
    flex-wrap: wrap;
    padding: 90px 0 50px;
  }
}
.petpooja .petpooja_first_fold .petpooja_right_side {
  width: 571px;
  min-width: 380px;
}
@media (max-width: 992px) {
  .petpooja .petpooja_first_fold .petpooja_right_side {
    all: unset;
    width: 100%;
  }
}
.petpooja .petpooja_first_fold .petpooja_right_side .quotes_Img {
  margin: 50px 0 0;
}
@media (max-width: 992px) {
  .petpooja .petpooja_first_fold .petpooja_right_side .quotes_Img {
    margin: 0;
  }
}
.petpooja .petpooja_first_fold .petpooja_right_side .quotes_Img .video-thumbnail .top-gradient {
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  width: 100%;
  height: 80px;
  padding: 24px;
  position: absolute;
  border-radius: 16px 16px 0 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.6), transparent);
}
.petpooja .petpooja_first_fold .petpooja_right_side .quotes_Img .video-thumbnail .top-gradient p {
  z-index: 1;
  font-size: 18px;
  text-align: center;
  line-height: 22px;
  overflow: hidden;
  white-space: nowrap;
}
.petpooja .petpooja_first_fold .petpooja_right_side .quotes_Img .video-thumbnail .videoimg {
  border-radius: 24px;
}
.petpooja .petpooja_first_fold .petpooja_right_side .quotes_Img .video-thumbnail .play-icon-wrapper {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%) scale(1);
  left: 50%;
  cursor: pointer;
  transition: all 0.3s;
}
.petpooja .petpooja_first_fold .petpooja_right_side .quotes_Img iframe {
  height: 321px;
  border: none;
  outline: none;
  border-radius: 24px;
}
@media (max-width: 992px) {
  .petpooja .petpooja_first_fold .petpooja_right_side .quotes_Img iframe {
    height: 189px;
  }
}
.petpooja .fold1 .sec-title {
  font-size: 32px;
  margin: 0 0 56px;
  line-height: 40px;
  text-align: center;
  letter-spacing: -0.01em;
  font-family: "Basier Circle Semibold";
}
@media (max-width: 992px) {
  .petpooja .fold1 .sec-title {
    font-size: 20px;
    line-height: 24px;
  }
}
.petpooja .fold1 .fold1_wrapper .reeloBrand_slider {
  padding: 0 0 100px;
}
@media (max-width: 992px) {
  .petpooja .fold1 .fold1_wrapper .reeloBrand_slider {
    padding: 0 0 30px;
  }
}
.petpooja .fold1 .fold1_wrapper .reeloBrand_slider .brandSlide {
  height: 120px;
  padding: 0 27px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 30px 24px;
}
@media (max-width: 992px) {
  .petpooja .fold1 .fold1_wrapper .reeloBrand_slider .brandSlide {
    padding: 10px 24px;
    height: 80px;
    width: 120px !important;
  }
}
.petpooja .fold1 .fold1_wrapper .reeloBrand_slider .brandSlide img {
  width: 120px;
  height: 120px;
  object-fit: contain;
}
@media (max-width: 992px) {
  .petpooja .fold1 .fold1_wrapper .reeloBrand_slider .brandSlide img {
    width: 80px;
    height: 80px;
  }
}
.petpooja .display_table {
  flex-direction: unset;
}
@media (max-width: 992px) {
  .petpooja .banner {
    text-align: center;
  }
  .petpooja .banner .common_title {
    font-size: 28px;
    line-height: 32px;
  }
  .petpooja .banner .common_desc {
    font-size: 14px;
    line-height: 22px;
    margin: 0;
  }
}
.petpooja .banner .common_subtitle {
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.18em;
  text-transform: uppercase;
  font-family: "Basier Circle Semibold";
}
@media (max-width: 992px) {
  .petpooja .sec-title {
    font-size: 28px;
    line-height: 42px;
  }
}
.petpooja .sec_title {
  font-size: 42px;
  margin: 0 0 16px;
  line-height: 51px;
  letter-spacing: -0.02em;
  font-weight: 500;
  font-family: "Basier Circle medium";
}
@media (max-width: 992px) {
  .petpooja .sec_title {
    font-size: 28px;
    line-height: 32px;
  }
}
.petpooja .sec_desc {
  font-size: 20px;
  margin: 0 0 24px;
  line-height: 30px;
  font-family: "Basier Circle";
}
.petpooja .common_title {
  font-size: 74px;
  line-height: 78px;
  margin: 16px 0 20px;
  letter-spacing: -0.01em;
  font-family: "Basier Circle medium";
}
.petpooja .common_desc {
  font-size: 20px;
  margin: 0 0 40px;
  line-height: 30px;
  font-family: "Basier Circle";
}
.petpooja .compare_fold_wrapper {
  background: #eaeefe;
}
.petpooja .compare_fold_wrapper .compare_features {
  gap: 40px;
  margin: 0 auto;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: center;
}
.petpooja .compare_fold_wrapper .compare_features .compare_feature_card {
  width: 385px;
  padding: 24px;
  background: #fff;
  border-radius: 12px;
}
.petpooja .compare_fold_wrapper .compare_features .compare_feature_card h3 {
  font-size: 20px;
  color: #182020;
  line-height: 32px;
  margin: 32px 0 8px;
}
.petpooja .compare_fold_wrapper .compare_features .compare_feature_card p {
  font-size: 18px;
  color: #182020;
  line-height: 24px;
  margin-bottom: 32px;
}
@media (max-width: 992px) {
  .petpooja .pre-built .common_title {
    font-size: 28px;
    line-height: 32px;
  }
}
@media (max-width: 992px) {
  .petpooja .pre-built .common_desc {
    font-size: 18px;
  }
}
.petpooja .pre-built .campaigns_feature_card_root {
  gap: 24px;
  margin: 0 auto;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 992px) {
  .petpooja .pre-built .campaigns_feature_card_root {
    display: block;
    border-radius: 16px;
    padding: 0;
  }
}
.petpooja .pre-built .campaigns_feature_card_root .flx {
  align-items: center !important;
}
.petpooja .pre-built .campaigns_feature_card_root .campaigns_feature_card {
  height: 350px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0 24px 0 0;
  border-radius: 16px;
  background: #f3f1ee;
  width: calc(50% - 12px);
}
@media (max-width: 992px) {
  .petpooja .pre-built .campaigns_feature_card_root .campaigns_feature_card {
    display: block;
    padding: 0;
    height: unset;
    width: 100%;
    margin: 0 0 30px;
  }
}
.petpooja .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_left_side {
  padding: 32px 8px 32px 22px;
}
@media (max-width: 992px) {
  .petpooja .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_left_side {
    padding: 34px 8px 34px 22px;
  }
}
.petpooja .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_left_side .campaigns_feature_number {
  padding: 4px 9px;
  width: 28px;
  height: 28px;
  font-size: 16px;
  color: #fff;
  line-height: 22px;
  border-radius: 8px;
  background: #065151;
  font-family: "Basier Circle Semibold";
}
.petpooja .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_left_side h3 {
  margin: 8px 0;
  font-size: 26px;
  color: #0e1b1b;
  line-height: 30px;
  letter-spacing: -0.02em;
  font-family: "Basier Circle Semibold";
}
@media (max-width: 992px) {
  .petpooja .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_left_side h3 {
    font-size: 20px;
    line-height: 24px;
  }
}
.petpooja .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_left_side p {
  font-size: 16px;
  color: #232d2d;
  line-height: 24px;
}
.petpooja .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_right_side {
  width: 100%;
  max-width: 270px;
  position: relative;
  bottom: -6px;
}
@media (max-width: 992px) {
  .petpooja .pre-built .campaigns_feature_card_root .campaigns_feature_card .campaigns_right_side {
    max-width: 250px;
    margin: 0 auto;
  }
}
.petpooja .pre-built .campaigns_feature_card_root .last_card {
  width: 100%;
  height: auto;
}
.petpooja .fold3 .marrows {
  margin: 30px 0 35px;
}
.petpooja .fold3 .marrows svg {
  width: 48px;
  height: 48px;
  background-color: #f3f5f5;
  border-radius: 100%;
  padding: 10px;
  margin: 0 10px;
}
.petpooja .fold3 .marrows svg:hover {
  background-color: #0d9298;
}
.petpooja .fold3 .marrows svg:hover path {
  stroke: #fff;
}
.petpooja .fold3 .marrows #cnext {
  position: relative;
  transform: rotate(180deg);
}
.petpooja .fold3 .reviewCarousel_parent .reviewCarousel {
  gap: 48px;
  padding: 24px;
  height: 381px;
  margin: 0 4px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  border-radius: 24px;
  align-items: stretch;
  background: #e0eaff;
}
@media (max-width: 992px) {
  .petpooja .fold3 .reviewCarousel_parent .reviewCarousel {
    gap: 12px;
    padding: 16px;
    margin: 0 4px;
    border-radius: 8px;
    flex-direction: column;
    height: unset;
  }
}
.petpooja .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_left {
  max-width: 665px;
  overflow: hidden;
  width: calc(50% - 24px);
}
@media (max-width: 992px) {
  .petpooja .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_left {
    max-width: 100%;
    width: 100%;
    overflow: unset;
  }
}
.petpooja .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_left iframe {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  border-radius: 12px;
}
.petpooja .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_right {
  gap: 24px;
  padding: 10px 0 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  max-width: -moz-calc(50% - 24px);
  max-width: calc(50% - 24px);
}
@media (max-width: 992px) {
  .petpooja .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_right {
    max-width: 100%;
    min-width: 100%;
    padding: 15px 0 0;
  }
}
.petpooja .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_right h3 {
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -1.28px;
  font-family: "Basier Circle medium";
  font-weight: 500;
}
@media (max-width: 992px) {
  .petpooja .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_right h3 {
    margin: 0 0 8px;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.24px;
  }
}
.petpooja .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_right .reviewCarousel_review_detail {
  gap: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.petpooja .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_right .reviewCarousel_review_detail .reviewImgs {
  border-radius: 50%;
  object-fit: contain;
  background-color: #fff;
}
.petpooja .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_right .reviewCarousel_review_detail .reviewer_name {
  font-size: 22px;
  line-height: 26px;
  font-family: "Basier Circle medium";
  padding: 0 0 6px;
}
@media (max-width: 992px) {
  .petpooja .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_right .reviewCarousel_review_detail .reviewer_name {
    font-size: 16px;
    line-height: 22px;
  }
}
.petpooja .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_right .reviewCarousel_review_detail .reviewer_post {
  font-size: 14px;
  color: #374040;
  line-height: 20px;
  letter-spacing: -0.14px;
}
@media (max-width: 992px) {
  .petpooja .fold3 .reviewCarousel_parent .reviewCarousel .reviewCarousel_right .reviewCarousel_review_detail .reviewer_post {
    font-size: 12px;
    line-height: 16px;
  }
}
.petpooja .lastsection {
  border-radius: 24px;
  padding: 40px 20px;
}
@media (max-width: 992px) {
  .petpooja .lastsection {
    margin: 0 20px;
  }
}

.comparision .theme-btn {
  padding: 14px 25px 12px;
}
.comparision .sec_title {
  font-size: 40px;
  color: #182020;
  line-height: 60px;
  max-width: 820px;
  margin: 0 auto 24px;
  font-family: "Basier Circle medium";
}
@media (max-width: 992px) {
  .comparision .sec_title {
    font-size: 32px;
    line-height: 48px;
  }
}
.comparision .common_desc {
  font-size: 20px;
  color: #182020;
  line-height: 26px;
  margin: 0 auto 24px;
}
.comparision .comparison_wrapper {
  background: #f5faff;
  padding: 60px 0 0;
}
.comparision .comparison_wrapper .compare_square {
  border-radius: 10px;
  background: #e6f2ff;
}
.comparision .comparison_wrapper .compare_square_1 {
  top: 20vh;
  left: 80px;
  width: 84px;
  height: 84px;
  position: absolute;
}
.comparision .comparison_wrapper .compare_square_2 {
  left: 200px;
  width: 150px;
  height: 150px;
  position: absolute;
  border-radius: 30px;
  top: calc(20vh + 124px);
}
.comparision .comparison_wrapper .compare_square_3 {
  top: 20vh;
  right: 80px;
  width: 40px;
  height: 40px;
  position: absolute;
}
.comparision .comparison_wrapper .compare_square_4 {
  right: 200px;
  width: 64px;
  height: 64px;
  position: absolute;
  top: calc(20vh + 100px);
}
.comparision .comparison_wrapper .compare_square_5 {
  right: 40px;
  width: 40px;
  height: 40px;
  position: absolute;
  top: calc(20vh + 300px);
}
.comparision .comparison_wrapper .compare_fold1 {
  gap: 40px;
  z-index: 1;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 992px) {
  .comparision .comparison_wrapper .compare_fold1 {
    flex-wrap: wrap;
    gap: 0;
  }
}
.comparision .comparison_wrapper .compare_fold1 .compare_left_side {
  max-width: 500px;
}
.comparision .comparison_wrapper .compare_fold1 .compare_left_side .common_title {
  font-size: 48px;
  color: #182020;
  line-height: 72px;
  position: relative;
  margin: 50px auto 40px;
  font-family: "Basier Circle medium";
}
@media (max-width: 992px) {
  .comparision .comparison_wrapper .compare_fold1 .compare_left_side .common_title {
    font-size: 32px;
    line-height: 48px;
  }
}
.comparision .comparison_wrapper .compare_fold1 .compare_right_side {
  margin: 40px 0;
  max-width: 540px;
}
.comparision .comparison_wrapper .compare_fold1 .compare_right_side .quotes_Img {
  margin: 50px 0 0;
}
@media (max-width: 992px) {
  .comparision .comparison_wrapper .compare_fold1 .compare_right_side .quotes_Img {
    margin: 0;
  }
}
.comparision .comparison_wrapper .compare_fold1 .compare_right_side .quotes_Img .video-thumbnail .top-gradient {
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  width: 100%;
  height: 80px;
  padding: 24px;
  position: absolute;
  border-radius: 16px 16px 0 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.6), transparent);
}
.comparision .comparison_wrapper .compare_fold1 .compare_right_side .quotes_Img .video-thumbnail .top-gradient p {
  z-index: 1;
  font-size: 18px;
  text-align: center;
  line-height: 22px;
  overflow: hidden;
  white-space: nowrap;
}
.comparision .comparison_wrapper .compare_fold1 .compare_right_side .quotes_Img .video-thumbnail .videoimg {
  border-radius: 24px;
}
.comparision .comparison_wrapper .compare_fold1 .compare_right_side .quotes_Img .video-thumbnail .play-icon-wrapper {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%) scale(1);
  left: 50%;
  cursor: pointer;
  transition: all 0.3s;
}
.comparision .comparison_wrapper .compare_fold1 .compare_right_side .quotes_Img iframe {
  height: 321px;
  width: 540px;
  border: none;
  outline: none;
  border-radius: 24px;
}
@media (max-width: 992px) {
  .comparision .comparison_wrapper .compare_fold1 .compare_right_side .quotes_Img iframe {
    height: 189px;
    width: 100%;
  }
}
.comparision .fold1 .sec-title {
  font-size: 32px;
  margin: 0 0 56px;
  line-height: 40px;
  text-align: center;
  letter-spacing: -0.01em;
  font-family: "Basier Circle Semibold";
}
.comparision .fold1 .compare_brands {
  gap: 56px;
  flex-wrap: wrap;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: center;
  justify-content: center;
  margin-bottom: 40px;
}
@media (max-width: 992px) {
  .comparision .fold1 .compare_brands {
    gap: 24px;
  }
  .comparision .fold1 .compare_brands img {
    width: 88px;
  }
}
.comparision .compare_fold_wrapper {
  background: #eaeefe;
}
@media (max-width: 992px) {
  .comparision .compare_fold_wrapper .sec-title {
    font-size: 32px;
    line-height: 48px;
  }
}
.comparision .compare_fold_wrapper .compare_features {
  gap: 40px;
  margin: 0 auto;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: center;
}
.comparision .compare_fold_wrapper .compare_features .compare_feature_card {
  width: 385px;
  padding: 24px;
  background: #fff;
  border-radius: 12px;
}
.comparision .compare_fold_wrapper .compare_features .compare_feature_card h3 {
  font-size: 20px;
  color: #182020;
  line-height: 32px;
  margin: 32px 0 8px;
  font-family: "Basier Circle medium";
}
.comparision .compare_fold_wrapper .compare_features .compare_feature_card p {
  font-size: 18px;
  color: #182020;
  line-height: 24px;
  margin-bottom: 32px;
}
.comparision .compare_customer_reviews {
  gap: 24px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
@media (max-width: 992px) {
  .comparision .compare_customer_reviews {
    flex-wrap: wrap;
  }
}
.comparision .compare_customer_reviews .compare_review_box {
  height: 100%;
  border-radius: 16px;
  background: #f5f5f5;
}
.comparision .compare_customer_reviews .compare_review_box .quotes_Img .video-thumbnail .top-gradient {
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  width: 100%;
  height: 80px;
  padding: 24px;
  position: absolute;
  border-radius: 16px 16px 0 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.6), transparent);
}
.comparision .compare_customer_reviews .compare_review_box .quotes_Img .video-thumbnail .top-gradient p {
  z-index: 1;
  font-size: 18px;
  text-align: center;
  line-height: 22px;
  overflow: hidden;
  white-space: nowrap;
}
.comparision .compare_customer_reviews .compare_review_box .quotes_Img .video-thumbnail .videoimg {
  border-radius: 14px;
  height: 195px;
  width: 347px;
}
.comparision .compare_customer_reviews .compare_review_box .quotes_Img .video-thumbnail .play-icon-wrapper {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%) scale(1);
  left: 50%;
  cursor: pointer;
  transition: all 0.3s;
}
.comparision .compare_customer_reviews .compare_review_box .quotes_Img iframe {
  height: 195px;
  width: 347px;
  border: none;
  outline: none;
  border-radius: 24px;
}
@media (max-width: 992px) {
  .comparision .compare_customer_reviews .compare_review_box .quotes_Img iframe {
    height: 189px;
    width: 100%;
  }
}
.comparision .compare_customer_reviews .compare_review_box .compare_video_dets {
  gap: 16px;
  padding: 16px;
  max-width: 347px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 117px;
}
.comparision .compare_customer_reviews .compare_review_box .compare_video_dets h3 {
  font-size: 20px;
  margin: 0 0 8px;
  color: #182020;
  line-height: 26px;
  font-family: "Basier Circle Semibold";
}
.comparision .compare_customer_reviews .compare_review_box .compare_video_dets p {
  margin: 0;
  font-size: 16px;
  color: #616d6d;
  line-height: 21px;
}
.comparision .compare_common_fold {
  gap: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 992px) {
  .comparision .compare_common_fold {
    flex-direction: column-reverse;
  }
}
.comparision .compare_common_fold .compare_left_side {
  max-width: 540px;
}
.comparision .compare_common_fold .compare_left_side .sec_title {
  font-size: 48px;
  color: #182020;
  line-height: 58px;
  margin: 8px 0 24px;
  font-family: "Basier Circle medium";
}
@media (max-width: 992px) {
  .comparision .compare_common_fold .compare_left_side .sec_title {
    font-size: 24px;
    line-height: 30px;
  }
}
.comparision .compare_common_fold .compare_left_side .p2 {
  padding-bottom: 20px;
}
.comparision .compare_common_fold .compare_right_side {
  max-width: 540px;
}
.comparision .compare_fold_2 {
  background: #fdf5ff;
}
.comparision .compare_fold_2 .compare_pos_row {
  gap: 24px;
  margin: 40px auto 0;
  flex-wrap: wrap;
  max-width: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 992px) {
  .comparision .compare_fold_2 .compare_pos_row {
    gap: 16px;
  }
}
.comparision .compare_fold_2 .compare_pos_row .compare_pos_div {
  width: 120px;
  height: 120px;
  padding: 10px;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 4px 34px rgba(0, 0, 0, 0.1);
  position: relative;
}
@media (max-width: 992px) {
  .comparision .compare_fold_2 .compare_pos_row .compare_pos_div {
    width: 90px;
    height: 90px;
  }
}
.comparision .compare_fold_2 .compare_pos_row .compare_pos_div img {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  padding: 10px;
}
.comparision .compare_fold_3 .compare_business_types {
  gap: 80px;
  flex-wrap: wrap;
  margin-bottom: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 90px 0 100px;
}
@media (max-width: 992px) {
  .comparision .compare_fold_3 .compare_business_types {
    gap: 40px;
  }
}
.comparision .compare_fold_3 .compare_business_types .compare_business h3 {
  margin: 24px 0 0;
  font-size: 24px;
  color: #182020;
  line-height: 26px;
}
.comparision .compare_fold_3 .cta-action-card {
  gap: 40px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 37px 40px;
  position: relative;
  border-radius: 16px;
  background: #1a424f;
}
@media (max-width: 992px) {
  .comparision .compare_fold_3 .cta-action-card {
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px;
  }
}
.comparision .compare_fold_3 .cta-action-card .ctaActionBg {
  position: absolute;
  right: 0;
  top: 0;
}
@media (max-width: 992px) {
  .comparision .compare_fold_3 .cta-action-card .ctaActionBg {
    bottom: 0;
    height: 100%;
  }
}
.comparision .compare_fold_3 .cta-action-card .content {
  z-index: 1;
  position: relative;
}
.comparision .compare_fold_3 .cta-action-card .content h3 {
  font-size: 32px;
  margin: 0 0 24px;
  line-height: 38px;
  font-family: "Basier Circle medium";
}
@media (max-width: 992px) {
  .comparision .compare_fold_3 .cta-action-card .content h3 {
    font-size: 24px;
    line-height: 29px;
  }
}
.comparision .compare_fold_3 .cta-action-card .content p {
  margin: 0;
  font-size: 18px;
  line-height: 24px;
}
.comparision .compare_fold_4 {
  background: #e1f3f4;
}
.comparision .compare_fold_4 .marrows #cprev {
  position: absolute;
  left: 20px;
  top: 40%;
  z-index: 1;
}
.comparision .compare_fold_4 .marrows #cnext {
  position: absolute;
  right: 20px;
  top: 40%;
  z-index: 1;
  transform: rotate(180deg);
}
.comparision .compare_fold_4 .compare_slick_slider .compare_template {
  height: 360px;
  margin: 0 24px;
}
.comparision .compare_fold_4 .compare_slick_slider .compare_template .compare_template_card {
  width: 264px;
  height: 264px;
  margin: 0 0 16px;
  border-radius: 8px;
  position: relative;
  transition: 0.1s linear;
}
@media (max-width: 992px) {
  .comparision .compare_fold_4 .compare_slick_slider .compare_template .compare_template_card {
    width: 100%;
  }
}
.comparision .compare_fold_4 .compare_slick_slider .compare_template .compare_template_card:after {
  top: 0;
  left: 0;
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  border-radius: 15px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0, hsla(0, 0%, 100%, 0) 80%);
}
.comparision .compare_fold_4 .compare_slick_slider .compare_template .compare_template_card .compare_template_img img {
  object-fit: cover;
  border-radius: 15px;
  vertical-align: middle;
  height: 264px;
  width: 100%;
}
.comparision .compare_fold_4 .compare_slick_slider .compare_template .compare_template_card .compare_template_title {
  left: 0;
  top: 16px;
  z-index: 2;
  height: 60%;
  color: #fff;
  font-size: 24px;
  padding: 0 16px;
  line-height: 29px;
  position: absolute;
  word-wrap: break-word;
  font-family: "Basier Circle medium";
  font-weight: 700;
}
.comparision .compare_fold_4 .compare_slick_slider .compare_template .compare_template_objective {
  margin: 0;
  font-size: 20px;
  color: #000;
  max-width: 220px;
  line-height: 24px;
  font-family: "Basier Circle medium";
  font-weight: 700;
}

.restaurant-trend .common_desc {
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.4px;
  padding: 0 0 20px;
}
@media (max-width: 992px) {
  .restaurant-trend .common_desc {
    font-size: 20px;
    padding: 15px 0 20px;
  }
}
.restaurant-trend .tagline_wrapper {
  padding: 70px 0 0;
}
.restaurant-trend .tagline_wrapper .common_title {
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: 121%;
  font-family: "Basier Circle medium";
  letter-spacing: -0.56px;
}
@media (max-width: 992px) {
  .restaurant-trend .tagline_wrapper .common_title {
    font-size: 36px;
  }
}
.restaurant-trend .benifits_section {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
  margin: 100px 0 0;
}
@media (max-width: 992px) {
  .restaurant-trend .benifits_section {
    flex-wrap: wrap;
    margin: 0;
    gap: 0;
  }
}
.restaurant-trend .benifits_section .bene_div {
  padding: 32px 30px;
  border-radius: 12px;
  width: 367px;
}
@media (max-width: 992px) {
  .restaurant-trend .benifits_section .bene_div {
    width: 100%;
    margin: 30px 0 0;
  }
}
.restaurant-trend .benifits_section .bene_div p {
  color: #171E59;
  font-size: 17.923px;
  font-style: normal;
  font-weight: 500;
  line-height: 110%;
  letter-spacing: -0.358px;
}
.restaurant-trend .benifits_section .bene_div span {
  font-size: 43.015px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.86px;
  font-family: "Basier Circle Semibold";
  display: block;
}
.restaurant-trend .benifits_section .bene_div .green {
  color: #16b364;
}
.restaurant-trend .benifits_section .bene_div .red {
  color: #ef6820;
}
.restaurant-trend .benifits_section .bene_div .blue {
  color: #1570ef;
}
.restaurant-trend .benifits_section .b1 {
  background: #EDFCF2;
}
.restaurant-trend .benifits_section .b2 {
  background: #FEF6EE;
}
.restaurant-trend .benifits_section .b3 {
  background: #F5F8FF;
}
.restaurant-trend #outer_div .Vector {
  position: absolute;
  top: -28%;
  z-index: -1;
  left: 0;
  width: 100%;
}
@media (max-width: 992px) {
  .restaurant-trend #outer_div .Vector {
    top: 0;
  }
}
.restaurant-trend #outer_div .survey_fold1 .fold_wrapper .fold {
  padding: 160px 0;
}
@media (max-width: 992px) {
  .restaurant-trend #outer_div .survey_fold1 .fold_wrapper .fold {
    padding: 0 0 40px;
  }
}
.restaurant-trend #outer_div .survey_fold1 .fold_wrapper .fold .common_title {
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
  line-height: 112.3%;
  letter-spacing: -1.12px;
  padding-bottom: 15px;
}
@media (max-width: 992px) {
  .restaurant-trend #outer_div .survey_fold1 .fold_wrapper .fold .common_title {
    font-size: 28px;
    line-height: 32px;
    padding: 15px 0 0;
  }
}
.restaurant-trend #outer_div .survey_fold1 .fold_wrapper .fold .fold1img {
  border-radius: 12px;
  position: absolute;
  right: 0;
  top: -4%;
}
@media (max-width: 992px) {
  .restaurant-trend #outer_div .survey_fold1 .fold_wrapper .fold .fold1img {
    position: unset;
  }
}
.restaurant-trend #outer_div .survey_fold1 .fold_wrapper .fold .fold2img {
  position: absolute;
  left: 0;
  top: -20%;
}
@media (max-width: 992px) {
  .restaurant-trend #outer_div .survey_fold1 .fold_wrapper .fold .fold2img {
    position: unset;
  }
}
.restaurant-trend #outer_div .survey_fold1 .fold_wrapper .fold .fold3img {
  position: absolute;
  right: 0;
  top: -20%;
}
@media (max-width: 992px) {
  .restaurant-trend #outer_div .survey_fold1 .fold_wrapper .fold .fold3img {
    position: unset;
  }
}
.restaurant-trend #outer_div .survey_fold1 .fold_wrapper .fold2 {
  text-align: right;
}
@media (max-width: 992px) {
  .restaurant-trend #outer_div .survey_fold1 .fold_wrapper .fold2 {
    text-align: center;
  }
}
.restaurant-trend .survey_fold2 .survey1 {
  border-radius: 40px 40px 0px 0px;
  background: #F7E49B;
  padding: 40px;
}
@media (max-width: 992px) {
  .restaurant-trend .survey_fold2 .survey1 {
    padding: 20px;
  }
}
.restaurant-trend .survey_fold2 .survey1 .tag {
  border-radius: 900px;
  background: #EBBD07;
  color: #5F4D07;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  padding: 12px 30px;
  display: inline-block;
}
.restaurant-trend .survey_fold2 .survey1 ol {
  padding: 40px 0 0 40px;
}
@media (max-width: 992px) {
  .restaurant-trend .survey_fold2 .survey1 ol {
    padding: 20px 0 0 20px;
  }
}
.restaurant-trend .survey_fold2 .survey1 ol li {
  color: #000;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
@media (max-width: 992px) {
  .restaurant-trend .survey_fold2 .survey1 ol li {
    font-size: 20px;
  }
}
.restaurant-trend .survey_fold2 .survey2 {
  border-radius: 0px 0px 40px 40px;
  background: #065151;
  padding: 10px 40px;
}
@media (max-width: 992px) {
  .restaurant-trend .survey_fold2 .survey2 {
    padding: 10px 20px;
  }
}
.restaurant-trend .survey_fold2 .survey2 .gift {
  position: absolute;
  right: -68px;
  top: -71px;
}
@media (max-width: 992px) {
  .restaurant-trend .survey_fold2 .survey2 .gift {
    right: -32px;
    top: unset;
    width: 159px;
    bottom: -21px;
  }
}
.restaurant-trend .survey_fold2 .survey2 p {
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 43.2px */
  letter-spacing: -0.36px;
  padding: 30px 0 0;
}
@media (max-width: 992px) {
  .restaurant-trend .survey_fold2 .survey2 p {
    font-size: 20px;
  }
}

.event_wrapper .event_firstFold {
  gap: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0 0;
}
@media (max-width: 1024px) {
  .event_wrapper .event_firstFold {
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
  }
}
.event_wrapper .event_firstFold .event_left_fold {
  max-width: 620px;
}
@media (max-width: 1024px) {
  .event_wrapper .event_firstFold .event_left_fold {
    max-width: 100%;
    text-align: center;
  }
}
.event_wrapper .event_firstFold .event_left_fold .venue_wrapper {
  display: flex;
  justify-content: center;
  gap: 15px;
  padding: 30px 0 0;
}
@media (max-width: 992px) {
  .event_wrapper .event_firstFold .event_left_fold .venue_wrapper {
    padding: 0 0 20px;
  }
}
.event_wrapper .event_firstFold .event_left_fold .venue_wrapper .venue {
  width: 198px;
  height: 146px;
  padding: 16px 10px;
  gap: 12px;
  border-radius: 24px;
  color: #fff;
  text-align: center;
}
@media (max-width: 992px) {
  .event_wrapper .event_firstFold .event_left_fold .venue_wrapper .venue {
    height: 100px;
    padding: 16px 5px;
    width: 102px;
  }
}
.event_wrapper .event_firstFold .event_left_fold .venue_wrapper .venue .venue_title {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  padding: 0 0 15px;
}
@media (max-width: 992px) {
  .event_wrapper .event_firstFold .event_left_fold .venue_wrapper .venue .venue_title {
    padding: 0 0 7px;
  }
}
.event_wrapper .event_firstFold .event_left_fold .venue_wrapper .venue .venue_title img {
  width: 14px;
}
.event_wrapper .event_firstFold .event_left_fold .venue_wrapper .venue .venue_title span {
  font-size: 14px;
  line-height: 110%;
}
@media (max-width: 992px) {
  .event_wrapper .event_firstFold .event_left_fold .venue_wrapper .venue .venue_title span {
    font-size: 9px;
  }
}
.event_wrapper .event_firstFold .event_left_fold .venue_wrapper .venue p {
  font-size: 20px;
  font-weight: 500;
  line-height: 120%;
}
@media (max-width: 992px) {
  .event_wrapper .event_firstFold .event_left_fold .venue_wrapper .venue p {
    font-size: 10px;
  }
}
.event_wrapper .event_firstFold .event_left_fold .venue_wrapper .v1 {
  background: #4d80bd;
}
.event_wrapper .event_firstFold .event_left_fold .venue_wrapper .v2 {
  background: #efba50;
}
.event_wrapper .event_firstFold .event_left_fold .venue_wrapper .v3 {
  background: #5fcfbe;
}
.event_wrapper .event_firstFold .event_right_fold {
  width: 100%;
  max-width: 571px;
}
@media (max-width: 1024px) {
  .event_wrapper .event_firstFold .event_right_fold {
    max-width: 100%;
  }
}
.event_wrapper .event_firstFold .event_right_fold .hurry {
  background: #BEF8F8;
  border-radius: 24px;
  padding: 23px 30px;
}
@media (max-width: 992px) {
  .event_wrapper .event_firstFold .event_right_fold .hurry {
    padding: 20px;
    margin: 0;
  }
}
.event_wrapper .event_firstFold .event_right_fold .hurry .sec_title {
  font-size: 28px;
  line-height: 38px;
  letter-spacing: -0.02em;
  font-weight: 500;
  font-family: "Basier Circle Semibold";
  margin: 0;
}
@media (max-width: 992px) {
  .event_wrapper .event_firstFold .event_right_fold .hurry .sec_title {
    font-size: 24px;
    line-height: 28px;
  }
}
.event_wrapper .display_table {
  flex-direction: unset;
}
@media (max-width: 992px) {
  .event_wrapper .banner {
    padding: 60px 0 0;
    text-align: center;
  }
  .event_wrapper .banner .common_title {
    font-size: 28px;
    line-height: 32px;
  }
  .event_wrapper .banner .common_desc {
    font-size: 14px;
    line-height: 22px;
  }
}
@media (max-width: 992px) {
  .event_wrapper .sec-light {
    letter-spacing: -0.02em;
    font-weight: 500;
    font-family: "Basier Circle medium";
    font-size: 24px;
    line-height: 28px;
  }
}
@media (max-width: 992px) {
  .event_wrapper .sec-title {
    font-size: 24px;
    line-height: 28px;
  }
}
.event_wrapper .sec_title {
  font-size: 42px;
  line-height: 51px;
  letter-spacing: -0.02em;
  font-weight: 500;
  font-family: "Basier Circle medium";
}
@media (max-width: 992px) {
  .event_wrapper .sec_title {
    font-size: 24px;
    line-height: 28px;
  }
}
.event_wrapper .sec_desc {
  font-size: 20px;
  margin: 0 0 24px;
  line-height: 30px;
  font-family: "Basier Circle";
}
.event_wrapper .common_title {
  font-size: 56px;
  margin: 0 0 16px;
  line-height: 62px;
  letter-spacing: -0.01em;
  font-family: "Basier Circle medium";
}
.event_wrapper .common_desc {
  font-size: 20px;
  margin: 0 0 40px;
  line-height: 30px;
  font-family: "Basier Circle";
}
.event_wrapper .loyaly_div {
  width: 557px;
  margin: 70px auto 0;
}
@media (max-width: 992px) {
  .event_wrapper .loyaly_div {
    width: 100%;
    margin: 0;
  }
}
.event_wrapper .fold1 .sec-title {
  font-size: 32px;
  margin: 0 0 56px;
  line-height: 40px;
  text-align: center;
  letter-spacing: -0.01em;
  font-family: "Basier Circle Semibold";
}
@media (max-width: 992px) {
  .event_wrapper .fold1 .sec-title {
    font-size: 20px;
    line-height: 24px;
  }
}
.event_wrapper .fold1 .fold1_wrapper .reeloBrand_slider {
  padding: 0 0 30px;
}
.event_wrapper .fold1 .fold1_wrapper .reeloBrand_slider .brandSlide {
  height: 120px;
  padding: 0 27px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 30px 24px;
}
@media (max-width: 992px) {
  .event_wrapper .fold1 .fold1_wrapper .reeloBrand_slider .brandSlide {
    padding: 10px 24px;
    height: 80px;
    width: 120px !important;
  }
}
.event_wrapper .fold1 .fold1_wrapper .reeloBrand_slider .brandSlide img {
  width: 120px;
  height: 120px;
  object-fit: contain;
}
@media (max-width: 992px) {
  .event_wrapper .fold1 .fold1_wrapper .reeloBrand_slider .brandSlide img {
    width: 80px;
    height: 80px;
  }
}
.event_wrapper .meet-us {
  border-radius: 40px;
  padding: 70px;
}
@media (max-width: 1024px) {
  .event_wrapper .meet-us {
    padding: 30px;
  }
}
@media (max-width: 767px) {
  .event_wrapper .meet-us {
    padding: 20px;
  }
}
.event_wrapper .meet-us .meet_us_wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
@media (max-width: 992px) {
  .event_wrapper .meet-us .meet_us_wrapper {
    display: block;
  }
}
.event_wrapper .meet-us .meet_us_wrapper .meet {
  border-radius: 16px;
  background: #F3F1EE;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  padding: 36px;
  margin: 10px;
}
@media (max-width: 992px) {
  .event_wrapper .meet-us .meet_us_wrapper .meet {
    padding: 10px;
    margin: 0 0 15px;
  }
  .event_wrapper .meet-us .meet_us_wrapper .meet img {
    width: 65px;
  }
}
.event_wrapper .meet-us .meet_us_wrapper .meet p {
  font-size: 28px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.56px;
}
@media (max-width: 992px) {
  .event_wrapper .meet-us .meet_us_wrapper .meet p {
    font-size: 20px;
    line-height: 30px;
  }
}
.event_wrapper .woh_meets .woh_steps {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  padding: 0 0 60px;
}
@media (max-width: 992px) {
  .event_wrapper .woh_meets .woh_steps {
    padding: 0 0 30px;
  }
}
.event_wrapper .woh_meets .woh_steps .bonus_left {
  max-width: 200px !important;
}
@media (max-width: 992px) {
  .event_wrapper .woh_meets .woh_steps .bonus_left {
    max-width: 110px !important;
  }
}
.event_wrapper .woh_meets .woh_steps .bonus_left:before {
  position: unset !important;
}
.event_wrapper .woh_meets .woh_steps .step_left {
  max-width: 175px;
  z-index: 1;
}
@media (max-width: 992px) {
  .event_wrapper .woh_meets .woh_steps .step_left {
    max-width: 110px;
  }
}
.event_wrapper .woh_meets .woh_steps .step_left:before {
  position: absolute;
  content: "";
  width: 3px;
  height: 94px;
  background-color: #7c0489;
  right: 40px;
  top: 69px;
}
@media (max-width: 992px) {
  .event_wrapper .woh_meets .woh_steps .step_left:before {
    height: 42px;
    right: 25px;
    top: 46px;
  }
}
.event_wrapper .woh_meets .woh_steps .step_left .steps {
  border-radius: 45px;
  background: #ECE6DC;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  gap: 20px;
}
@media (max-width: 992px) {
  .event_wrapper .woh_meets .woh_steps .step_left .steps {
    padding: 8px 11px;
    gap: 6px;
    justify-content: space-between;
  }
}
.event_wrapper .woh_meets .woh_steps .step_left .steps p {
  color: #570360;
  font-size: 34px;
  font-weight: 900;
  line-height: 130%;
  letter-spacing: -0.678px;
}
@media (max-width: 992px) {
  .event_wrapper .woh_meets .woh_steps .step_left .steps p {
    font-size: 16px;
  }
}
.event_wrapper .woh_meets .woh_steps .step_left .steps .bonus {
  color: #CD8810;
}
.event_wrapper .woh_meets .woh_steps .step_left .steps .number {
  width: 54.484px;
  height: 54.484px;
  border-radius: 100px;
  background-color: #C998CF;
  padding: 4px 0px;
}
@media (max-width: 992px) {
  .event_wrapper .woh_meets .woh_steps .step_left .steps .number {
    width: 30px;
    height: 30px;
  }
}
.event_wrapper .woh_meets .woh_steps .step_left .steps .number span {
  font-size: 34px;
  font-weight: 900;
  line-height: 130%;
  letter-spacing: -0.678px;
  padding: 18px;
}
@media (max-width: 992px) {
  .event_wrapper .woh_meets .woh_steps .step_left .steps .number span {
    font-size: 20px;
    padding: 10px;
  }
}
.event_wrapper .woh_meets .woh_steps .step_left .steps .number img {
  padding: 8px 11px;
}
@media (max-width: 992px) {
  .event_wrapper .woh_meets .woh_steps .step_left .steps .number img {
    padding: 2px 6px;
  }
}
.event_wrapper .woh_meets .woh_steps .step_left .steps .y {
  background-color: #CD8810;
}
.event_wrapper .woh_meets .woh_steps .step_right {
  max-width: 500px;
}
.event_wrapper .woh_meets .woh_steps .step_right p {
  font-size: 28px;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.56px;
}
@media (max-width: 992px) {
  .event_wrapper .woh_meets .woh_steps .step_right p {
    font-size: 16px;
  }
}
.event_wrapper .lastsection {
  border-radius: 24px;
  padding: 40px 20px;
}
@media (max-width: 992px) {
  .event_wrapper .lastsection {
    margin: 0 20px;
  }
}

.aahar .event_firstFold {
  gap: 24px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 20px 0 0;
}
@media (max-width: 1024px) {
  .aahar .event_firstFold {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 40px;
  }
}
.aahar .event_firstFold .event_left_fold {
  max-width: 620px;
  padding: 30px 0 0;
}
@media (max-width: 1024px) {
  .aahar .event_firstFold .event_left_fold {
    max-width: 100%;
    text-align: center;
    padding: 0;
  }
}
.aahar .event_firstFold .event_left_fold .venue_wrapper {
  display: flex;
  justify-content: center;
  gap: 15px;
  padding: 30px 0 0;
}
@media (max-width: 992px) {
  .aahar .event_firstFold .event_left_fold .venue_wrapper {
    padding: 0 0 20px;
  }
}
.aahar .event_firstFold .event_left_fold .venue_wrapper .venue {
  width: 198px;
  height: 146px;
  padding: 16px 9px;
  gap: 12px;
  border-radius: 24px;
  color: #fff;
  text-align: center;
}
@media (max-width: 992px) {
  .aahar .event_firstFold .event_left_fold .venue_wrapper .venue {
    height: 100px;
    padding: 16px 5px;
    width: 102px;
  }
}
.aahar .event_firstFold .event_left_fold .venue_wrapper .venue .venue_title {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  padding: 0 0 20px;
}
@media (max-width: 992px) {
  .aahar .event_firstFold .event_left_fold .venue_wrapper .venue .venue_title {
    padding: 0 0 7px;
  }
}
.aahar .event_firstFold .event_left_fold .venue_wrapper .venue .venue_title img {
  width: 14px;
}
.aahar .event_firstFold .event_left_fold .venue_wrapper .venue .venue_title span {
  font-size: 14px;
  line-height: 110%;
}
@media (max-width: 992px) {
  .aahar .event_firstFold .event_left_fold .venue_wrapper .venue .venue_title span {
    font-size: 9px;
  }
}
.aahar .event_firstFold .event_left_fold .venue_wrapper .venue p {
  font-size: 20px;
  font-weight: 500;
  line-height: 120%;
}
@media (max-width: 992px) {
  .aahar .event_firstFold .event_left_fold .venue_wrapper .venue p {
    font-size: 10px;
  }
}
.aahar .event_firstFold .event_left_fold .venue_wrapper .v1 {
  background: #4d80bd;
}
.aahar .event_firstFold .event_left_fold .venue_wrapper .v2 {
  background: #efba50;
}
.aahar .event_firstFold .event_left_fold .venue_wrapper .v3 {
  background: #5fcfbe;
}
.aahar .event_firstFold .event_right_fold {
  width: 100%;
  max-width: 571px;
}
@media (max-width: 1024px) {
  .aahar .event_firstFold .event_right_fold {
    max-width: 100%;
  }
}
.aahar .event_firstFold .event_right_fold .hurry {
  background: #BEF8F8;
  border-radius: 24px;
  padding: 23px 30px;
}
@media (max-width: 992px) {
  .aahar .event_firstFold .event_right_fold .hurry {
    padding: 20px;
    margin: 0;
  }
}
.aahar .event_firstFold .event_right_fold .hurry .sec_title {
  font-size: 28px;
  line-height: 38px;
  letter-spacing: -0.02em;
  font-weight: 500;
  font-family: "Basier Circle Semibold";
  margin: 0;
}
@media (max-width: 992px) {
  .aahar .event_firstFold .event_right_fold .hurry .sec_title {
    font-size: 24px;
    line-height: 28px;
  }
}
.aahar .display_table {
  flex-direction: unset;
}
@media (max-width: 992px) {
  .aahar .banner {
    padding: 60px 0 0;
    text-align: center;
  }
  .aahar .banner .common_title {
    font-size: 28px;
    line-height: 32px;
  }
  .aahar .banner .common_desc {
    font-size: 14px;
    line-height: 22px;
  }
}
@media (max-width: 992px) {
  .aahar .sec-light {
    letter-spacing: -0.02em;
    font-weight: 500;
    font-family: "Basier Circle medium";
    font-size: 24px;
    line-height: 28px;
  }
}
@media (max-width: 992px) {
  .aahar .sec-title {
    font-size: 24px;
    line-height: 28px;
  }
}
.aahar .sec_title {
  font-size: 42px;
  line-height: 51px;
  letter-spacing: -0.02em;
  font-weight: 500;
  font-family: "Basier Circle medium";
}
@media (max-width: 992px) {
  .aahar .sec_title {
    font-size: 24px;
    line-height: 28px;
  }
}
.aahar .sec_desc {
  font-size: 20px;
  margin: 0 0 24px;
  line-height: 30px;
  font-family: "Basier Circle";
}
.aahar .common_title {
  font-size: 56px;
  margin: 0 0 16px;
  line-height: 62px;
  letter-spacing: -0.01em;
  font-family: "Basier Circle medium";
}
.aahar .common_desc {
  font-size: 20px;
  line-height: 30px;
  margin: 0 0 30px;
  font-family: "Basier Circle";
}
.aahar .loyaly_div {
  width: 557px;
  margin: 70px auto 0;
}
@media (max-width: 992px) {
  .aahar .loyaly_div {
    width: 100%;
    margin: 0;
  }
}
.aahar .fold1 .container {
  padding: 0;
}
@media (max-width: 992px) {
  .aahar .fold1 .container {
    padding: 0 20px;
  }
}
.aahar .fold1 .sec-title {
  font-size: 32px;
  margin: 0 0 56px;
  line-height: 40px;
  text-align: center;
  letter-spacing: -0.01em;
  font-family: "Basier Circle Semibold";
}
@media (max-width: 992px) {
  .aahar .fold1 .sec-title {
    font-size: 20px;
    line-height: 24px;
  }
}
.aahar .fold1 .fold1_wrapper .reeloBrand_slider {
  padding: 0 0 30px;
}
.aahar .fold1 .fold1_wrapper .reeloBrand_slider .brandSlide {
  height: 120px;
  padding: 0 27px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 30px 24px;
}
@media (max-width: 992px) {
  .aahar .fold1 .fold1_wrapper .reeloBrand_slider .brandSlide {
    padding: 10px 24px;
    height: 80px;
    width: 120px !important;
  }
}
.aahar .fold1 .fold1_wrapper .reeloBrand_slider .brandSlide img {
  width: 120px;
  height: 120px;
  object-fit: contain;
}
@media (max-width: 992px) {
  .aahar .fold1 .fold1_wrapper .reeloBrand_slider .brandSlide img {
    width: 80px;
    height: 80px;
  }
}
.aahar .meet-us {
  border-radius: 40px;
  padding: 70px;
}
@media (max-width: 1024px) {
  .aahar .meet-us {
    padding: 30px;
  }
}
@media (max-width: 767px) {
  .aahar .meet-us {
    padding: 20px;
  }
}
.aahar .meet-us .meet_us_wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
@media (max-width: 992px) {
  .aahar .meet-us .meet_us_wrapper {
    display: block;
  }
}
.aahar .meet-us .meet_us_wrapper .meet {
  border-radius: 16px;
  background: #F3F1EE;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  padding: 36px;
  margin: 10px;
}
@media (max-width: 992px) {
  .aahar .meet-us .meet_us_wrapper .meet {
    padding: 10px;
    margin: 0 0 15px;
  }
  .aahar .meet-us .meet_us_wrapper .meet img {
    width: 65px;
  }
}
.aahar .meet-us .meet_us_wrapper .meet p {
  font-size: 28px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.56px;
}
@media (max-width: 992px) {
  .aahar .meet-us .meet_us_wrapper .meet p {
    font-size: 20px;
    line-height: 30px;
  }
}
.aahar .woh_meets .woh_steps {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  padding: 0 0 60px;
}
@media (max-width: 992px) {
  .aahar .woh_meets .woh_steps {
    padding: 0 0 30px;
  }
}
.aahar .woh_meets .woh_steps .bonus_left {
  max-width: 200px !important;
}
@media (max-width: 992px) {
  .aahar .woh_meets .woh_steps .bonus_left {
    max-width: 110px !important;
  }
}
.aahar .woh_meets .woh_steps .bonus_left:before {
  position: unset !important;
}
.aahar .woh_meets .woh_steps .step_left {
  max-width: 175px;
  z-index: 1;
}
@media (max-width: 992px) {
  .aahar .woh_meets .woh_steps .step_left {
    max-width: 110px;
  }
}
.aahar .woh_meets .woh_steps .step_left:before {
  position: absolute;
  content: "";
  width: 3px;
  height: 94px;
  background-color: #7c0489;
  right: 40px;
  top: 69px;
}
@media (max-width: 992px) {
  .aahar .woh_meets .woh_steps .step_left:before {
    height: 42px;
    right: 25px;
    top: 46px;
  }
}
.aahar .woh_meets .woh_steps .step_left .steps {
  border-radius: 45px;
  background: #ECE6DC;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  gap: 20px;
}
@media (max-width: 992px) {
  .aahar .woh_meets .woh_steps .step_left .steps {
    padding: 8px 11px;
    gap: 6px;
    justify-content: space-between;
  }
}
.aahar .woh_meets .woh_steps .step_left .steps p {
  color: #570360;
  font-size: 34px;
  font-weight: 900;
  line-height: 130%;
  letter-spacing: -0.678px;
}
@media (max-width: 992px) {
  .aahar .woh_meets .woh_steps .step_left .steps p {
    font-size: 16px;
  }
}
.aahar .woh_meets .woh_steps .step_left .steps .bonus {
  color: #CD8810;
}
.aahar .woh_meets .woh_steps .step_left .steps .number {
  width: 54.484px;
  height: 54.484px;
  border-radius: 100px;
  background-color: #C998CF;
  padding: 4px 0px;
}
@media (max-width: 992px) {
  .aahar .woh_meets .woh_steps .step_left .steps .number {
    width: 30px;
    height: 30px;
  }
}
.aahar .woh_meets .woh_steps .step_left .steps .number span {
  font-size: 34px;
  font-weight: 900;
  line-height: 130%;
  letter-spacing: -0.678px;
  padding: 18px;
}
@media (max-width: 992px) {
  .aahar .woh_meets .woh_steps .step_left .steps .number span {
    font-size: 20px;
    padding: 10px;
  }
}
.aahar .woh_meets .woh_steps .step_left .steps .number img {
  padding: 8px 11px;
}
@media (max-width: 992px) {
  .aahar .woh_meets .woh_steps .step_left .steps .number img {
    padding: 2px 6px;
  }
}
.aahar .woh_meets .woh_steps .step_left .steps .y {
  background-color: #CD8810;
}
.aahar .woh_meets .woh_steps .step_right {
  max-width: 500px;
}
.aahar .woh_meets .woh_steps .step_right p {
  font-size: 28px;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.56px;
}
@media (max-width: 992px) {
  .aahar .woh_meets .woh_steps .step_right p {
    font-size: 16px;
  }
}
.aahar .lastsection {
  border-radius: 24px;
  padding: 40px 20px;
}
@media (max-width: 992px) {
  .aahar .lastsection {
    margin: 0 20px;
  }
}

.icon-wrapper {
  display: inline-block;
  float: right;
  position: absolute;
  right: 0;
}

/* Tooltip styling */
.tooltip-text {
  background: #FFF;
  box-shadow: -2px 4px 10px 0px rgba(89, 60, 29, 0.12);
  padding: 12px;
  border-radius: 5px;
  white-space: nowrap;
  margin-top: 5px;
  color: #374040;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  display: none;
  position: absolute;
  right: -50px;
  top: -164px;
  text-wrap: auto;
  z-index: 1;
  width: 290px;
  text-transform: none;
}
@media (max-width: 992px) {
  .tooltip-text {
    right: 0;
    font-size: 16px;
  }
}

/* Tooltip arrow */
.tooltip-text::after {
  content: "";
  position: absolute;
  bottom: -10px;
  right: 18%;
  transform: translateX(-50%) rotate(180deg);
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #fff;
}
@media (max-width: 992px) {
  .tooltip-text::after {
    right: 0;
  }
}

/* Show tooltip on hover */
.icontip:hover + .tooltip-text {
  display: block;
}

.icontip {
  float: right;
}

.inspiration-reelo .banner {
  padding: 120px 70px 70px;
}
@media (max-width: 992px) {
  .inspiration-reelo .banner {
    padding: 70px 0 40px;
  }
}
.inspiration-reelo .banner .inspi1 {
  width: 380px;
}
@media (max-width: 992px) {
  .inspiration-reelo .banner .inspi1 {
    display: none;
  }
}
.inspiration-reelo .banner .inspired {
  width: 280px;
}
@media (max-width: 992px) {
  .inspiration-reelo .banner .inspired {
    width: 200px;
  }
}
.inspiration-reelo .banner .inspi2 {
  width: 380px;
}
@media (max-width: 992px) {
  .inspiration-reelo .banner .inspi2 {
    width: 100%;
    padding: 0 20px;
  }
}
.inspiration-reelo .banner .tag_wrapper {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  text-align: center;
}
@media (max-width: 992px) {
  .inspiration-reelo .banner .tag_wrapper {
    position: relative;
    transform: unset;
    left: unset;
    padding: 30px;
  }
}
.inspiration-reelo .all_inspiration {
  background: linear-gradient(0deg, #FFF 0.39%, #FFF9F0 15.98%, #FFF9F0 81.44%, #FFF 99.92%);
}
.inspiration-reelo .all_inspiration .dispFlex {
  display: flex;
}
@media (max-width: 992px) {
  .inspiration-reelo .all_inspiration .dispFlex {
    flex-wrap: wrap;
  }
}
.inspiration-reelo .all_inspiration .Inspiration_Title {
  font-size: 42px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.84px;
}
@media (max-width: 992px) {
  .inspiration-reelo .all_inspiration .Inspiration_Title {
    font-size: 24px;
  }
}
.inspiration-reelo .all_inspiration .sticky-fixed {
  position: fixed;
  top: 45px;
  width: 280px;
}
@media (max-width: 992px) {
  .inspiration-reelo .all_inspiration .sticky-fixed {
    width: 100%;
    position: unset;
  }
}
.inspiration-reelo .all_inspiration .sidebar {
  padding: 60px 30px 0 0;
  width: 280px;
}
@media (max-width: 992px) {
  .inspiration-reelo .all_inspiration .sidebar {
    padding: 0;
    width: 100%;
  }
}
.inspiration-reelo .all_inspiration .sidebar .filters {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px; /* 300% */
  letter-spacing: -0.4px;
  text-transform: uppercase;
}
.inspiration-reelo .all_inspiration .sidebar .search_div {
  padding: 0 !important;
}
.inspiration-reelo .all_inspiration .sidebar .search_div .search-container button {
  background: #fff;
  font-size: 0;
  border: none;
  cursor: pointer;
  position: absolute;
  left: 2px;
  bottom: 10px;
}
.inspiration-reelo .all_inspiration .sidebar .search_div .search-container input[type=text] {
  padding: 8px 6px 8px 32px;
  font-size: 17px;
  border-radius: 90px;
  margin: 10px 0 40px;
}
.inspiration-reelo .all_inspiration .sidebar .filter_wrapper .categorySlider li {
  display: block;
  padding-bottom: 30px;
  position: relative;
}
@media (max-width: 992px) {
  .inspiration-reelo .all_inspiration .sidebar .filter_wrapper .categorySlider li {
    padding-bottom: 24px;
  }
}
.inspiration-reelo .all_inspiration .sidebar .filter_wrapper .categorySlider li a {
  color: #023342;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.inspiration-reelo .all_inspiration .sidebar .filter_wrapper .categorySlider li a.active {
  color: #0d9298;
}
@media (max-width: 992px) {
  .inspiration-reelo .all_inspiration .sidebar .filter_wrapper .categorySlider li a {
    font-size: 18px;
  }
}
.inspiration-reelo .all_inspiration .sidebar .filter_wrapper .categorySlider li a .icon-wrapper {
  display: inline-block;
  float: right;
}
.inspiration-reelo .all_inspiration .sidebar .filter_wrapper .custom-dropdown .dropdown-button {
  color: #023342;
  font-family: "Basier Circle";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background-color: unset;
}
.inspiration-reelo .all_inspiration .sidebar .filter_wrapper .custom-dropdown .dropdown-menu {
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 20px;
  margin: 20px 0 0;
}
.inspiration-reelo .all_inspiration .sidebar .filter_wrapper .custom-dropdown .dropdown-menu li {
  list-style-type: none;
  padding: 0 0 5px;
}
.inspiration-reelo .all_inspiration .sidebar .filter_wrapper .custom-dropdown .dropdown-menu li .category-option {
  display: inline-block;
  width: unset;
  border: none;
  box-shadow: none;
}
.inspiration-reelo .all_inspiration .sidebar .filter_wrapper .custom-dropdown .dropdown-arrow img {
  transition: transform 0.3s ease; /* Smooth transition for rotation */
  border: 1px solid #01999F;
  padding: 5px;
  border-radius: 90px;
  vertical-align: bottom;
}
.inspiration-reelo .all_inspiration .sidebar .filter_wrapper .share_wrapper {
  padding: 60px 0 0;
}
.inspiration-reelo .all_inspiration .sidebar .filter_wrapper .share_wrapper .inspired {
  color: #374040;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  padding: 0 0 10px;
}
.inspiration-reelo .all_inspiration .sidebar .filter_wrapper .share_wrapper .inspired a {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}
.inspiration-reelo .all_inspiration .inspiration_wrapper {
  min-height: 600px;
}
.inspiration-reelo .all_inspiration .inspiration_wrapper .masonary-grid {
  display: flex;
  flex-wrap: wrap;
  margin-left: -30px;
  width: auto;
  padding: 40px 0;
}
@media (max-width: 992px) {
  .inspiration-reelo .all_inspiration .inspiration_wrapper .masonary-grid {
    margin-left: 0;
    padding: 40px 0;
  }
}
.inspiration-reelo .all_inspiration .inspiration_wrapper .masonary-grid .masonary-column {
  padding-left: 30px;
  background-clip: padding-box;
  width: 33.3%;
}
@media (max-width: 992px) {
  .inspiration-reelo .all_inspiration .inspiration_wrapper .masonary-grid .masonary-column {
    padding-left: 0;
    width: 100%;
  }
}
.inspiration-reelo .all_inspiration .inspiration_wrapper .masonary-grid .masonary-column .hover_wrapper {
  border-radius: 12px;
  background: #FFF;
  box-shadow: -2px 4px 10px 0px rgba(89, 60, 29, 0.12);
  display: block;
  margin: 0 0 20px;
  padding: 12px;
}
.inspiration-reelo .all_inspiration .inspiration_wrapper .masonary-grid .masonary-column .hover_wrapper:hover .inspi-box .inspi_Img:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 99%;
  background: rgba(0, 0, 0, 0.5019607843);
  background-blend-mode: multiply, normal;
  border-radius: 12px;
}
.inspiration-reelo .all_inspiration .inspiration_wrapper .masonary-grid .masonary-column .hover_wrapper:hover .inspi-box .inspi_Img .vstory {
  bottom: 20px;
}
.inspiration-reelo .all_inspiration .inspiration_wrapper .masonary-grid .masonary-column .hover_wrapper .inspi-box .inspi_Img {
  margin-bottom: 16px;
  overflow: hidden;
}
.inspiration-reelo .all_inspiration .inspiration_wrapper .masonary-grid .masonary-column .hover_wrapper .inspi-box .inspi_Img .vstory {
  color: #1A424F;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  padding: 12px;
  border-radius: 90px;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  position: absolute;
  bottom: -60px;
  left: 50%;
  transform: translateX(-50%);
  min-width: 212px;
  text-align: center;
  transition: all 0.4s ease;
}
.inspiration-reelo .all_inspiration .inspiration_wrapper .masonary-grid .masonary-column .hover_wrapper .inspi-box .inspi_Img .fimg {
  border-radius: 12px;
}
.inspiration-reelo .all_inspiration .inspiration_wrapper .masonary-grid .masonary-column .hover_wrapper .inspi-box .inspi-title {
  color: #171717;
  font-size: 16px;
  line-height: normal;
  text-transform: capitalize;
}
.inspiration-reelo .all_inspiration .inspiration_wrapper .masonary-grid .masonary-column .hover_wrapper .inspi-box .inspi-category {
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  margin: 10px 0;
  display: inline-block;
  border-radius: 90px;
  letter-spacing: 1px;
}
.inspiration-reelo .all_inspiration .inspiration_wrapper .masonary-grid .masonary-column .hover_wrapper .inspi-box .inspi-pos {
  padding: 6px 12px;
  color: #7B3700;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  display: inline-block;
  border-radius: 90px;
}
.inspiration-reelo .lastsection {
  border-radius: 24px;
  padding: 40px 20px;
}
@media (max-width: 992px) {
  .inspiration-reelo .lastsection {
    margin: 0 20px;
  }
}

.inspiration-single .banner {
  border-radius: 20px;
  background: #F4AA16;
  padding: 30px 40px;
}
@media (max-width: 992px) {
  .inspiration-single .banner {
    padding: 30px 20px;
  }
}
.inspiration-single .banner .displayFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 992px) {
  .inspiration-single .banner .displayFlex {
    flex-wrap: wrap;
    gap: 15px;
  }
}
.inspiration-single .banner .displayFlex .logoFold {
  display: flex;
  align-items: center;
  gap: 20px;
}
.inspiration-single .banner .displayFlex .logoFold .inspiTitle {
  font-size: 32px;
  font-weight: 600;
  line-height: 60px;
  letter-spacing: -0.64px;
}
@media (max-width: 992px) {
  .inspiration-single .banner .displayFlex .logoFold .inspiTitle {
    font-size: 22px;
  }
}
.inspiration-single .banner .displayFlex .tagsFold .category {
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  padding-bottom: 45px;
  text-align: right;
}
@media (max-width: 992px) {
  .inspiration-single .banner .displayFlex .tagsFold .category {
    font-size: 16px;
    padding-bottom: 25px;
    text-align: left;
  }
}
.inspiration-single .banner .displayFlex .tagsFold .inspi-brand, .inspiration-single .banner .displayFlex .tagsFold .inspi-pos {
  color: #A06A00;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  border-radius: 90px;
  background: #FACE77;
  padding: 6px 16px;
}
.inspiration-single .banner .displayFlex .tagsFold .inspi-brand {
  margin: 0 10px 0 0;
}
.inspiration-single .breadcrumbs a {
  color: #000;
}
.inspiration-single .breadcrumbs a:hover {
  color: #0d9298;
}
.inspiration-single .breadcrumbs .post_color {
  color: #0d9298 !important;
}
.inspiration-single .overview .overviewFold {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
@media (max-width: 992px) {
  .inspiration-single .overview .overviewFold {
    flex-wrap: wrap;
    gap: 30px;
    display: unset;
  }
}
.inspiration-single .overview .overviewFold .overviewLeft {
  max-width: 605px;
}
@media (max-width: 992px) {
  .inspiration-single .overview .overviewFold .overviewLeft {
    max-width: 100%;
  }
}
.inspiration-single .overview .overviewFold .overviewLeft .logoFold {
  display: flex;
  align-items: center;
  gap: 20px;
  padding-bottom: 20px;
}
.inspiration-single .overview .overviewFold .overviewLeft .logoFold .inspiTitle {
  font-size: 32px;
  font-weight: 600;
  line-height: 60px;
  letter-spacing: -0.64px;
}
@media (max-width: 992px) {
  .inspiration-single .overview .overviewFold .overviewLeft .logoFold .inspiTitle {
    font-size: 22px;
  }
}
.inspiration-single .overview .overviewFold .overviewLeft .overview-title {
  font-size: 42px;
  line-height: 120%;
  padding-bottom: 40px;
}
@media (max-width: 992px) {
  .inspiration-single .overview .overviewFold .overviewLeft .overview-title {
    font-size: 24px;
    padding-bottom: 20px;
  }
}
.inspiration-single .overview .overviewFold .overviewLeft .about {
  color: #0E1B1B;
  font-size: 20px;
  font-weight: 600;
  line-height: 60px; /* 300% */
  letter-spacing: -0.4px;
  text-transform: uppercase;
}
.inspiration-single .overview .overviewFold .overviewLeft .overview_desc {
  color: #374040;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 150% */
}
@media (max-width: 992px) {
  .inspiration-single .overview .overviewFold .overviewLeft .overview_desc {
    font-size: 16px;
    padding-bottom: 22px;
  }
}
.inspiration-single .overview .overviewFold .overviewLeft .btn_wrapper {
  display: flex;
  align-items: center;
  gap: 6px;
}
@media (max-width: 992px) {
  .inspiration-single .overview .overviewFold .overviewLeft .btn_wrapper {
    gap: 16px;
    flex-wrap: wrap;
  }
}
.inspiration-single .overview .overviewFold .overviewRight {
  max-width: 605px;
}
@media (max-width: 992px) {
  .inspiration-single .overview .overviewFold .overviewRight {
    max-width: 100%;
  }
}
.inspiration-single .overview .overviewFold .overviewRight .single-img-wrapper img {
  width: 463px;
  height: 489px;
  border-radius: 12px;
}
@media (max-width: 992px) {
  .inspiration-single .overview .overviewFold .overviewRight .single-img-wrapper img {
    height: 370px;
    width: 100%;
  }
}
.inspiration-single .overview .overviewFold .overviewRight .custom-gallery {
  width: 463px;
  height: 489px;
}
@media (max-width: 992px) {
  .inspiration-single .overview .overviewFold .overviewRight .custom-gallery {
    width: 100%;
    height: unset;
  }
}
.inspiration-single .overview .overviewFold .overviewRight .custom-gallery .slick-dots {
  display: flex;
  margin: 0;
  list-style-type: none;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}
.inspiration-single .overview .overviewFold .overviewRight .custom-gallery .slick-dots button {
  display: block;
  width: 13px;
  height: 13px;
  padding: 0;
  background-color: #000;
  text-indent: -9999px;
  cursor: pointer;
  border-radius: 30px;
  border: unset;
  margin: 0 6px;
}
.inspiration-single .overview .overviewFold .overviewRight .custom-gallery li.slick-active button {
  background-color: #d2cfcf;
}
.inspiration-single .overview .overviewFold .overviewRight .custom-gallery .imgSlide img {
  width: 100%;
  height: 489px;
  border-radius: 12px;
}
@media (max-width: 992px) {
  .inspiration-single .overview .overviewFold .overviewRight .custom-gallery .imgSlide img {
    height: 370px;
  }
}
.inspiration-single .category_section .categoryFold {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
@media (max-width: 992px) {
  .inspiration-single .category_section .categoryFold {
    flex-wrap: wrap;
    gap: 30px;
  }
}
.inspiration-single .category_section .categoryFold .categoryLeft {
  width: 705px;
  min-height: 367px;
  padding: 70px 0;
}
.inspiration-single .category_section .categoryFold .categoryLeft .categoryDiv {
  position: absolute;
  width: 80%;
  top: 44%;
  transform: translateY(-50%);
}
@media (max-width: 992px) {
  .inspiration-single .category_section .categoryFold .categoryLeft .categoryDiv {
    position: unset;
    transform: unset;
    width: 100%;
    top: unset;
  }
}
.inspiration-single .category_section .categoryFold .categoryLeft .categoryDiv p {
  font-size: 18px;
  line-height: 130%;
}
@media (max-width: 992px) {
  .inspiration-single .category_section .categoryFold .categoryLeft .categoryDiv p {
    font-size: 16px;
  }
}
@media (max-width: 992px) {
  .inspiration-single .category_section .categoryFold .categoryLeft {
    width: 100%;
    padding: 0;
    min-height: unset;
  }
}
.inspiration-single .category_section .categoryFold .categoryLeft .category {
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  padding-bottom: 16px;
}
@media (max-width: 992px) {
  .inspiration-single .category_section .categoryFold .categoryLeft .category {
    font-size: 16px;
    padding-bottom: 25px;
    text-align: left;
  }
  .inspiration-single .category_section .categoryFold .categoryLeft .category img {
    width: 18px;
  }
}
.inspiration-single .category_section .categoryFold .categoryRight {
  max-width: 605px;
}
.inspiration-single .category_section .categoryFold .categoryRight img {
  bottom: -5px;
}
@media (max-width: 992px) {
  .inspiration-single .category_section .categoryFold .categoryRight {
    max-width: 100%;
  }
}
.inspiration-single .testimonial .quote {
  color: #01999F;
  font-size: 56px;
  line-height: 120%; /* 67.2px */
  letter-spacing: -0.56px;
}
@media (max-width: 992px) {
  .inspiration-single .testimonial .quote {
    font-size: 30px;
  }
}
.inspiration-single .testimonial .quoteFlex {
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: 20px;
  padding: 50px 0 0;
}
.inspiration-single .testimonial .quoteFlex .sep:before {
  position: absolute;
  content: "";
  width: 1px;
  height: 50px;
  background-color: #01999F;
  top: -50px;
}
.inspiration-single .testimonial .quoteFlex .quoteDiv {
  display: flex;
  align-items: end;
}
.inspiration-single .testimonial .quoteFlex .quoteDiv img {
  border-radius: 90%;
  width: 52px;
}
.inspiration-single .testimonial .quoteFlex .quoteDiv .client {
  padding: 0 0 0 18px;
}
.inspiration-single .testimonial .quoteFlex .quoteDiv .client .name {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
}
.inspiration-single .testimonial .quoteFlex .quoteDiv .client .desig {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25.026px; /* 156.41% */
  letter-spacing: 0.104px;
}
.inspiration-single .about-section .about-inspi {
  border-radius: 20px;
  background: #E7F8F9;
}
.inspiration-single .about-section .about-inspi .aboutFold {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
@media (max-width: 992px) {
  .inspiration-single .about-section .about-inspi .aboutFold {
    flex-wrap: wrap;
  }
}
.inspiration-single .about-section .about-inspi .aboutFold .aboutLeft {
  padding: 60px 97px;
  width: 750px;
  height: 510px;
}
.inspiration-single .about-section .about-inspi .aboutFold .aboutLeft .about_block {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 80%;
}
@media (max-width: 992px) {
  .inspiration-single .about-section .about-inspi .aboutFold .aboutLeft .about_block {
    padding: 40px 20px;
    width: 100%;
    height: unset;
    position: unset;
    transform: unset;
  }
}
@media (max-width: 992px) {
  .inspiration-single .about-section .about-inspi .aboutFold .aboutLeft {
    width: 100%;
    height: unset;
    padding: 0;
  }
}
.inspiration-single .about-section .about-inspi .aboutFold .aboutLeft .title {
  color: #092D2C;
  font-size: 42px;
  font-style: normal;
  line-height: 120%; /* 50.4px */
  padding-bottom: 40px;
}
@media (max-width: 992px) {
  .inspiration-single .about-section .about-inspi .aboutFold .aboutLeft .title {
    font-size: 24px;
    padding-bottom: 20px;
  }
}
.inspiration-single .about-section .about-inspi .aboutFold .aboutLeft .desc {
  color: #374040;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}
@media (max-width: 992px) {
  .inspiration-single .about-section .about-inspi .aboutFold .aboutLeft .desc {
    font-size: 16px;
  }
}
.inspiration-single .about-section .about-inspi .aboutFold .aboutLeft .desc p {
  max-width: 560px;
}
.inspiration-single .about-section .about-inspi .aboutFold .aboutRight {
  max-width: 450px;
}
.inspiration-single .about-section .about-inspi .aboutFold .aboutRight .single-img-wrapper img {
  width: 100%;
  height: 510px;
  margin: 0 0 0 auto;
  border-radius: 0 12px 12px 0;
}
@media (max-width: 992px) {
  .inspiration-single .about-section .about-inspi .aboutFold .aboutRight .single-img-wrapper img {
    border-radius: 0 0 12px 12px;
    height: 370px;
  }
}
.inspiration-single .about-section .about-inspi .aboutFold .aboutRight .media_Slider .slick-dots {
  display: flex;
  margin: 0;
  list-style-type: none;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}
.inspiration-single .about-section .about-inspi .aboutFold .aboutRight .media_Slider .slick-dots button {
  display: block;
  width: 13px;
  height: 13px;
  padding: 0;
  background-color: #d2cfcf;
  text-indent: -9999px;
  cursor: pointer;
  border-radius: 30px;
  border: unset;
  margin: 0 6px;
}
.inspiration-single .about-section .about-inspi .aboutFold .aboutRight .media_Slider li.slick-active button {
  background-color: #fff;
}
.inspiration-single .about-section .about-inspi .aboutFold .aboutRight .media_Slider .imgSlide img {
  width: 100%;
  height: 510px;
  margin: 0 0 0 auto;
  border-radius: 0 12px 12px 0;
}
@media (max-width: 992px) {
  .inspiration-single .about-section .about-inspi .aboutFold .aboutRight .media_Slider .imgSlide img {
    border-radius: 0 0 12px 12px;
    height: 370px;
  }
}
@media (max-width: 992px) {
  .inspiration-single .about-section .about-inspi .aboutFold .aboutRight {
    max-width: 100%;
  }
}
.inspiration-single .recentBlogs .title {
  font-size: 42px;
  font-style: normal;
  line-height: 120%; /* 50.4px */
  padding-bottom: 30px;
}
@media (max-width: 992px) {
  .inspiration-single .recentBlogs .title {
    font-size: 24px;
    padding-bottom: 0;
  }
}
.inspiration-single .recentBlogs .blog_wrapper .inspi_wrapper {
  padding: 0 20px 0 0;
  min-height: 480px;
}
@media (max-width: 992px) {
  .inspiration-single .recentBlogs .blog_wrapper .inspi_wrapper {
    padding: 30px 0;
    min-height: unset;
  }
}
.inspiration-single .recentBlogs .blog_wrapper .inspi_wrapper .fimg {
  border-radius: 12px;
}
.inspiration-single .recentBlogs .blog_wrapper .inspi_wrapper .inspi-category {
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  margin: 10px 0;
  display: inline-block;
  border-radius: 90px;
  letter-spacing: 1px;
}
.inspiration-single .recentBlogs .blog_wrapper .inspi_wrapper .inspi-title {
  color: #1A424F;
  font-size: 20px;
  font-style: normal;
  line-height: 120%;
  padding: 16px 0 10px;
}
@media (max-width: 992px) {
  .inspiration-single .recentBlogs .blog_wrapper {
    padding: 0;
  }
}
.inspiration-single .lastsection {
  border-radius: 24px;
  padding: 40px 20px;
}
@media (max-width: 992px) {
  .inspiration-single .lastsection {
    margin: 0 20px;
  }
}

.error-page .error_wrapper {
  height: 100vh;
}
.error-page .error_wrapper .not-found-page {
  position: absolute;
  width: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
@media (max-width: 992px) {
  .error-page .error_wrapper .not-found-page {
    padding: 40px 20px;
  }
}
.error-page .error_wrapper .not-found-page .sec_title {
  font-size: 120px;
  line-height: 168px;
}
.error-page .error_wrapper .not-found-page .sec-title {
  padding: 30px 0 10px;
  font-family: "Basier Circle medium";
}
@media (max-width: 992px) {
  .error-page .error_wrapper .not-found-page .sec-title {
    font-size: 30px;
    line-height: 40px;
  }
}
@media (max-width: 992px) {
  .error-page .error_wrapper .not-found-page .sec-heading2 {
    font-size: 24px;
    line-height: 34px;
  }
}

.thankyou-page {
  padding: 20px 0 0;
}
@media (max-width: 992px) {
  .thankyou-page {
    padding: 40px 0 0;
  }
}
.thankyou-page section {
  background: #f2f9f6;
}
.thankyou-page .common_title {
  font-size: 48px;
  margin: 0 0 10px;
  color: #092d2c;
  line-height: 60px;
  letter-spacing: -0.05em;
  font-family: "Basier Circle Semibold";
}
@media (max-width: 992px) {
  .thankyou-page .common_title {
    font-size: 40px;
    line-height: 56px;
  }
}
.thankyou-page .common_desc {
  font-size: 20px;
  margin: 0 0 40px;
  color: #092d2c;
  line-height: 32px;
}
@media (max-width: 992px) {
  .thankyou-page .common_desc {
    font-size: 18px;
    line-height: 28px;
  }
}
.thankyou-page .ThankYou_fold {
  gap: 54px;
  display: flex;
  align-items: stretch;
  justify-content: center;
}
@media (max-width: 992px) {
  .thankyou-page .ThankYou_fold {
    flex-wrap: wrap;
  }
}
.thankyou-page .ThankYou_fold .ThankYou_fold_left_side {
  width: 100%;
  max-width: 628px;
  padding: 60px 30px;
  background: #fff;
  box-shadow: 0 1px 3px rgba(63, 63, 68, 0.15), 0 0 0 1px rgba(63, 63, 68, 0.05);
  border-bottom: 5px solid #26a0a0;
}
@media (max-width: 992px) {
  .thankyou-page .ThankYou_fold .ThankYou_fold_left_side {
    padding: 24px;
  }
}
.thankyou-page .ThankYou_fold .ThankYou_fold_left_side .ThankYou_fold_img img {
  height: 250px;
  margin: 0 auto;
  position: relative;
}
@media (max-width: 992px) {
  .thankyou-page .ThankYou_fold .ThankYou_fold_left_side .ThankYou_fold_img img {
    height: unset;
  }
}
.thankyou-page .ThankYou_fold .ThankYou_fold_left_side h2 {
  font-size: 30px;
  color: #092d2c;
  line-height: 38px;
  text-align: center;
  margin: 28px auto;
  letter-spacing: -0.05em;
  font-family: "Basier Circle Semibold";
}
@media (max-width: 992px) {
  .thankyou-page .ThankYou_fold .ThankYou_fold_left_side h2 {
    font-size: 24px;
    line-height: 32px;
  }
}
.thankyou-page .ThankYou_fold .ThankYou_fold_right_side {
  width: 100%;
  max-width: 412px;
  padding: 40px 32px;
  background: #fff;
  box-shadow: 0 1px 3px rgba(63, 63, 68, 0.15), 0 0 0 1px rgba(63, 63, 68, 0.05);
}
.thankyou-page .ThankYou_fold .ThankYou_fold_right_side .ThankYou_fold_img {
  width: fit-content;
  margin: 0 auto 40px;
}
.thankyou-page .ThankYou_fold .ThankYou_fold_right_side p {
  font-size: 24px;
  color: #092d2c;
  margin: 0 0 110px;
  line-height: 32px;
  text-align: center;
  letter-spacing: -0.05em;
  font-weight: 600;
}
@media (max-width: 992px) {
  .thankyou-page .ThankYou_fold .ThankYou_fold_right_side p {
    margin: 0 0 30px;
  }
}

.dynamic_cta_wrapper {
  border-radius: 16px;
  background-blend-mode: multiply, normal, normal;
  display: flex;
  padding: 30px 40px;
  margin: 20px 0 0;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  align-items: flex-end;
  justify-content: space-between;
}
@media (max-width: 992px) {
  .dynamic_cta_wrapper {
    flex-wrap: wrap;
    padding: 20px;
  }
}
.dynamic_cta_wrapper .content_wrapper .common-title {
  font-family: "Basier Circle medium";
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  text-align: left;
  line-height: 110%;
}
@media (max-width: 992px) {
  .dynamic_cta_wrapper .content_wrapper .common-title {
    font-size: 26px;
    line-height: 36px;
  }
}
.dynamic_cta_wrapper .content_wrapper .common-desc {
  font-size: 16px;
  text-align: left;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  padding: 10px 0 0;
}
@media (max-width: 992px) {
  .dynamic_cta_wrapper .content_wrapper .common-desc {
    font-size: 18px;
    line-height: 26px;
  }
}
.dynamic_cta_wrapper .btn_wrapper {
  margin: 30px 0 0;
}
.dynamic_cta_wrapper .btn_wrapper .yellow-btn {
  padding: 8px 12px;
}

#hubspot-conversations-iframe {
  display: initial !important;
  border: none !important;
  position: absolute !important;
  bottom: 0 !important;
  right: 0 !important;
  background: transparent !important;
  z-index: -1;
}

#close, #formclose {
  top: 5px;
  right: 10px;
  position: absolute;
  font-size: 30px;
  color: #0d9298;
  cursor: pointer;
  z-index: 9999;
}

#overlay, #formoverlay {
  overflow: auto;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 9999;
}

#popup {
  width: 60%;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid rgba(28, 58, 105, 0.2352941176);
  border-radius: 10px;
  opacity: 1;
  padding: 25px;
}
#popup .refund .refund_title {
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  text-decoration: underline;
  padding: 0 0 20px;
}
#popup .refund p {
  padding: 5px 0;
}
@media (max-width: 992px) {
  #popup {
    width: 90%;
  }
}

#formpopup {
  width: 60%;
  background: rgb(250, 254, 255);
  position: absolute;
  top: 52%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid rgba(28, 58, 105, 0.2352941176);
  border-radius: 10px;
  opacity: 1;
  padding: 25px 25px 0;
  height: 540px;
  overflow: auto;
}
@media (max-width: 992px) {
  #formpopup {
    width: 90%;
  }
}
#formpopup .formWrapper {
  display: flex;
  align-items: center;
}
@media (max-width: 992px) {
  #formpopup .formWrapper {
    display: block;
  }
  #formpopup .formWrapper .demoform {
    padding: 30px 0 0;
  }
}

/*** iPhone and iOS Form Input Zoom Fixes ***/
/* Fix Input Zoom on devices older than iPhone 5: */
@media screen and (device-aspect-ratio: 2/3) {
  select, textarea, input[type=text], input[type=password],
  input[type=datetime], input[type=datetime-local],
  input[type=date], input[type=month], input[type=time],
  input[type=week], input[type=number], input[type=email],
  input[type=url] {
    font-size: 16px;
  }
}
/* Fix Input Zoom on iPhone 5, 5C, 5S, iPod Touch 5g */
@media screen and (device-aspect-ratio: 40/71) {
  select, textarea, input[type=text], input[type=password],
  input[type=datetime], input[type=datetime-local],
  input[type=date], input[type=month], input[type=time],
  input[type=week], input[type=number], input[type=email],
  input[type=url] {
    font-size: 16px;
  }
}
/* Fix Input Zoom on iPhone 6, iPhone 6s, iPhone 7  */
@media screen and (device-aspect-ratio: 375/667) {
  select, textarea, input[type=text], input[type=password],
  input[type=datetime], input[type=datetime-local],
  input[type=date], input[type=month], input[type=time],
  input[type=week], input[type=number], input[type=email],
  input[type=url] {
    font-size: 16px;
  }
}
/* Fix Input Zoom on iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus, iPhone 8, iPhone X, XS, XS Max  */
@media screen and (device-aspect-ratio: 9/16) {
  select, textarea, input[type=text], input[type=password],
  input[type=datetime], input[type=datetime-local],
  input[type=date], input[type=month], input[type=time],
  input[type=week], input[type=number], input[type=email],
  input[type=url] {
    font-size: 16px;
  }
}
.blog_banner {
  margin: 70px 0 0;
}
@media (max-width: 992px) {
  .blog_banner {
    margin: 50px 0 0;
  }
}

.latest_blogWrapper {
  border-radius: 40px;
  background: #FAECDE;
  height: 422px;
}

.latest_caption {
  padding: 12px 30px;
  background-color: #f0dbc6;
  border-radius: 30px;
  display: inline-block;
  margin: 0 0 26px;
  color: #A58565;
  font-size: 16px;
  font-weight: 600;
  line-height: 125%;
  text-transform: uppercase;
}
@media (max-width: 992px) {
  .latest_caption {
    padding: 5px 10px;
    margin: 0 0 8px;
  }
}
.latest_caption img {
  vertical-align: text-bottom;
}

.blog_Title {
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  letter-spacing: -0.46px;
  margin-bottom: 26px;
}
@media (max-width: 992px) {
  .blog_Title {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 20px;
  }
}

@media (max-width: 992px) {
  .latest_blogContent {
    padding: 0 0 20px;
  }
}
.latest_blogContent .short_description {
  height: 100px;
}
@media (max-width: 992px) {
  .latest_blogContent .short_description {
    height: unset;
  }
}

.short_description {
  padding: 14px 0;
}

.blog_latestImg img {
  position: relative;
  transform: rotate(5.5deg);
  mix-blend-mode: darken;
}

.read_now {
  letter-spacing: -0.32px;
  font-weight: 600;
  display: inline-flex;
  transition-duration: 0.3s;
  transition-property: transform;
}
.read_now img {
  vertical-align: bottom;
}
.read_now:hover {
  transform: scale(0.9);
}

.see_more {
  color: #0d9298;
  font-weight: 600;
  float: right;
}
.see_more img {
  vertical-align: bottom;
}

.blog-category {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  padding: 0 0 10px;
}

.blog-title {
  font-size: 22px;
  line-height: 28px;
}

.webinar-title {
  font-size: 22px;
  line-height: 28px;
  padding: 15px 0 10px;
  height: 120px;
}
@media (max-width: 992px) {
  .webinar-title {
    height: unset;
  }
}

.resources-title {
  font-size: 18px;
  line-height: 24px;
  padding: 10px 0;
  height: 65px;
}

.blog_heading {
  border-top: 1px solid #D7DDDD;
  padding: 40px 0 0;
}

.blog_wrapper {
  padding: 40px 0;
}
@media (max-width: 992px) {
  .blog_wrapper {
    padding: 0 0 40px;
  }
}

#recent_blogs .blog-box {
  padding: 0 50px 0 0;
}
@media (max-width: 992px) {
  #recent_blogs .blog-box {
    padding: 20px 0 40px;
  }
}
#recent_blogs .blog-title {
  height: 60px;
}
#recent_blogs .short_description {
  height: 90px;
}

#all_blogs .search_div {
  padding: 60px 0 0;
}
@media (max-width: 992px) {
  #all_blogs .search_div {
    padding: 20px 0 0;
  }
}
#all_blogs .search_div .search-container button {
  background: #fff;
  font-size: 0;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 2px;
  bottom: 6px;
}
#all_blogs .search_div .search-container input[type=text] {
  padding: 8px 6px;
  margin-top: 8px;
  font-size: 17px;
}
#all_blogs .blog-box {
  padding: 0 10px;
  height: 405px;
}
@media (max-width: 992px) {
  #all_blogs .blog-box {
    padding: 20px 0;
    height: unset;
  }
}
#all_blogs .blog_Img img {
  height: 213px;
  transition-duration: 0.3s;
  transition-property: transform;
}
#all_blogs .blog_Img img:hover {
  transform: translateY(-8px);
}
@media (max-width: 992px) {
  #all_blogs .blog_Img img {
    height: 206px;
  }
}

#webinars .webinar-box {
  padding: 0 10px;
  height: 375px;
}
@media (max-width: 992px) {
  #webinars .webinar-box {
    padding: 20px 0;
    height: unset;
  }
}

.blog_Img img {
  border-radius: 8px;
  margin: 0 0 10px;
}

#free-resources {
  padding: 0 0 50px;
}
@media (max-width: 992px) {
  #free-resources {
    padding: 0;
  }
}

.resources-box {
  padding: 0 10px;
}
@media (max-width: 992px) {
  .resources-box {
    padding: 15px 0;
  }
}
.resources-box .resources_Img img {
  transition-duration: 0.3s;
  transition-property: transform;
}
.resources-box .resources_Img img:hover {
  transform: scale(0.9);
}

.csinner-box {
  padding: 0 20px 20px 0px;
}
@media (max-width: 992px) {
  .csinner-box {
    padding: 15px 0;
    height: unset;
  }
}
.csinner-box .cs_Img img {
  height: 206px;
  transition-duration: 0.3s;
  transition-property: transform;
}
.csinner-box .cs_Img img:hover {
  transform: scale(0.9);
}

.resources_Img img {
  border-radius: 8px;
}

.cs_Img img {
  border-radius: 8px;
}

.webinar_Img img {
  border-radius: 8px;
  height: 210px;
  transition-duration: 0.3s;
  transition-property: transform;
}
.webinar_Img img:hover {
  transform: translateY(-8px);
}

.click_to_connect {
  background-color: #C2F6F6;
  padding: 46px;
  border-radius: 8px;
}
@media (max-width: 992px) {
  .click_to_connect {
    padding: 0;
  }
  .click_to_connect .sec-padd {
    padding: 20px;
  }
}
.click_to_connect .click-title {
  font-size: 40px;
  line-height: 50px;
  font-weight: 300;
  letter-spacing: -1.9px;
}
@media (max-width: 992px) {
  .click_to_connect .click-title {
    font-size: 22px;
    line-height: 32px;
  }
}
.click_to_connect .click-title span {
  color: green;
  font-weight: 600;
}

.cs_wrapper {
  padding: 20px 0;
}

.cs-category {
  color: #0d9298;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 10px 0;
}

.csinner_Title {
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  height: 100px;
}
@media (max-width: 992px) {
  .csinner_Title {
    height: unset;
  }
}

.cs_Title {
  font-size: 28px;
  line-height: 38px;
  font-weight: 600;
}
@media (max-width: 992px) {
  .cs_Title {
    font-size: 20px;
    line-height: 28px;
  }
}

.CS_blogContent {
  padding: 40px 0 0 40px;
}
@media (max-width: 992px) {
  .CS_blogContent {
    padding: 0;
  }
}

.cs_author {
  font-size: 18px;
  line-height: 22px;
  color: #697777;
  font-weight: 500;
  text-transform: capitalize;
  padding: 8px 0 0;
}
.cs_author .userimg {
  width: 36px;
  height: 36px;
  border-radius: 100%;
  position: relative;
  top: 10px;
  left: -5px;
}

.girl {
  position: absolute;
  bottom: 0;
  right: 0;
}
@media (max-width: 992px) {
  .girl {
    position: relative;
  }
}

.category_slider li a {
  color: #fff;
  font-weight: 600;
  background-color: #0D9298;
  padding: 8px 12px;
  border-radius: 30px;
  margin: 0 3px;
  font-size: 14px;
}
.category_slider li a:hover {
  background-color: unset;
  color: #000;
}
.category_slider li.slick-current.slick-active a {
  background-color: #fff;
  border: 1px solid #0d9298;
  color: #0d9298;
}
.category_slider .slick-track {
  padding: 40px 0;
}

.see_all {
  color: #fff;
  background-color: #0D9298;
}

#blog-details .latest_blogContent {
  padding: 0;
}
@media (max-width: 992px) {
  #blog-details {
    margin: 30px 0 0;
  }
}
#blog-details .singlepost {
  background: #FAECDE;
}
@media (max-width: 992px) {
  #blog-details .singlepost {
    padding: 20px 10px;
  }
}
@media (max-width: 992px) {
  #blog-details .backblogs {
    border: 1px solid #E9EDED;
  }
}
#blog-details .backblogs .backtoblogs {
  color: #0d9298;
  font-weight: 600;
  padding: 30px 0;
  display: block;
}
@media (max-width: 992px) {
  #blog-details .backblogs .backtoblogs {
    padding: 15px 0;
  }
}
#blog-details .latest_blogWrapper {
  border-radius: 8px;
  background: #FAECDE;
  height: 422px;
}
@media (max-width: 992px) {
  #blog-details .latest_blogWrapper {
    height: unset;
  }
}
#blog-details .latest_blogWrapper .latest_blogContent .blog_Title {
  font-size: 40px;
  line-height: 50px;
}
@media (max-width: 992px) {
  #blog-details .latest_blogWrapper .latest_blogContent .blog_Title {
    font-size: 22px;
    line-height: 32px;
  }
}
#blog-details .latest_blogWrapper .publish_date {
  padding: 25px 0;
}
@media (max-width: 992px) {
  #blog-details .latest_blogWrapper .publish_date {
    padding: 0;
    font-size: 14px;
  }
}
#blog-details .latest_blogWrapper .publish_date span img {
  vertical-align: text-bottom;
}
#blog-details .latest_blogWrapper .publish_date span .userimg {
  width: 36px;
  height: 36px;
  border-radius: 100%;
  position: relative;
  top: 7px;
  left: -5px;
}
#blog-details .latest_blogWrapper .latest_blogImg {
  width: 562px;
  margin: 0 auto;
}
@media (max-width: 992px) {
  #blog-details .latest_blogWrapper .latest_blogImg .blog_Bigimage {
    padding: 20px 0 10px;
  }
}
#blog-details .latest_blogWrapper .latest_blogImg .blog_Bigimage img {
  mix-blend-mode: darken;
}
@media (max-width: 992px) {
  #blog-details .latest_blogWrapper .latest_blogImg .blog_Bigimage img {
    border-radius: 8px;
  }
}

.main_wrapper {
  border-top: 1px solid #D7DDDD;
}
.main_wrapper .detail_wrapper {
  display: flex;
}
@media (max-width: 992px) {
  .main_wrapper .detail_wrapper {
    display: block;
  }
}
.main_wrapper .navbar-fixed {
  position: fixed;
  top: 100px;
  width: 315px;
}
@media (max-width: 992px) {
  .main_wrapper .navbar-fixed {
    width: 100%;
    position: unset;
  }
}
.main_wrapper .demodiv-fixed {
  position: fixed;
  top: 120px;
  width: 210px;
}
@media (max-width: 992px) {
  .main_wrapper .demodiv-fixed {
    width: 100%;
    position: unset;
  }
}
.main_wrapper .stickySidebar {
  background-color: #fff;
  padding: 20px 8px;
  border-radius: 8px;
  box-shadow: 2px 2px 4px rgba(128, 128, 128, 0.2509803922);
}
@media (max-width: 992px) {
  .main_wrapper .stickySidebar {
    margin: 0 0 20px;
  }
}
.main_wrapper .stickySidebar .table_content {
  padding: 20px 4px 20px 20px;
}
.main_wrapper .stickySidebar .table_content li .sublist {
  padding: 5px 0 5px 14px;
}
.main_wrapper .stickySidebar .table_content li .sublist li {
  list-style-type: disc;
}
.main_wrapper .stickySidebar .table_content li .sublist li a {
  color: #000;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}
.main_wrapper .stickySidebar .table_content li .sublist li a.active {
  color: #60ACAC;
}
.main_wrapper .stickySidebar .table_content li a {
  color: #000;
  font-size: 16px;
  line-height: 28px;
  font-weight: 500;
}
.main_wrapper .stickySidebar .table_content li a.active {
  color: #60ACAC;
}
.main_wrapper .blogdetails_wrapper {
  padding: 0 35px;
  font-size: 18px;
  line-height: 26px;
  text-align: justify;
}
@media (max-width: 992px) {
  .main_wrapper .blogdetails_wrapper {
    padding: 0;
  }
}
.main_wrapper .blogdetails_wrapper a {
  color: #0d9298;
  text-align: justify;
}
.main_wrapper .blogdetails_wrapper h2 {
  font-size: 36px;
  line-height: 44px;
  padding: 24px 0;
  text-transform: capitalize;
  letter-spacing: -0.7px;
  text-align: left;
}
@media (max-width: 992px) {
  .main_wrapper .blogdetails_wrapper h2 {
    font-size: 22px;
    line-height: 32px;
    padding: 10px 0 15px;
  }
}
.main_wrapper .blogdetails_wrapper h3 {
  font-size: 28px;
  line-height: 36px;
  padding: 18px 0;
  text-transform: capitalize;
  letter-spacing: -0.56px;
  text-align: left;
}
@media (max-width: 992px) {
  .main_wrapper .blogdetails_wrapper h3 {
    font-size: 20px;
    line-height: 30px;
    padding: 10px 0 15px;
  }
}
.main_wrapper .blogdetails_wrapper h4 {
  font-size: 20px !important;
  line-height: 30px !important;
  padding: 18px 0;
  text-transform: capitalize;
  letter-spacing: -0.56px;
  text-align: left;
}
@media (max-width: 992px) {
  .main_wrapper .blogdetails_wrapper h4 {
    font-size: 18px !important;
    line-height: 28px !important;
    padding: 10px 0 15px;
  }
}
.main_wrapper .blogdetails_wrapper p {
  font-size: 18px;
  line-height: 26px;
  padding: 10px 0;
  text-align: justify;
}
@media (max-width: 992px) {
  .main_wrapper .blogdetails_wrapper p {
    padding: 0 0 10px;
  }
}
.main_wrapper .blogdetails_wrapper .quotes {
  background-color: #fff;
  box-shadow: 1px 3px 8px 2px rgba(128, 128, 128, 0.1607843137);
  padding: 20px;
  margin: 10px 0 20px;
  text-align: justify;
}
.main_wrapper .blogdetails_wrapper blockquote {
  background-color: #fff;
  box-shadow: 1px 3px 8px 2px rgba(128, 128, 128, 0.1607843137);
  padding: 20px;
  margin: 10px 0 20px;
  text-align: justify;
}
.main_wrapper .blogdetails_wrapper img {
  margin: 15px 0;
  border-radius: 8px;
  width: 100%;
  box-shadow: 1px 1px 1px 8px rgba(228, 228, 228, 0.6117647059);
}
.main_wrapper .blogdetails_wrapper iframe {
  margin: 25px 0 15px;
  border-radius: 8px;
  display: block;
  width: 100%;
  box-shadow: 1px 1px 1px 8px hsla(0, 0%, 89%, 0.6117647059);
  height: 400px;
}
@media (max-width: 992px) {
  .main_wrapper .blogdetails_wrapper iframe {
    height: 280px;
  }
}
.main_wrapper .blogdetails_wrapper ul {
  padding-left: 20px;
}
.main_wrapper .blogdetails_wrapper ul li {
  font-size: 18px;
  line-height: 26px;
  padding: 8px 0;
}
.main_wrapper .blogdetails_wrapper ul li img {
  margin: 30px 0 20px;
}
.main_wrapper .blogdetails_wrapper ul li ul {
  padding: 5px 0 0 15px;
}
.main_wrapper .blogdetails_wrapper ul li ul li {
  font-size: 18px;
  line-height: 26px;
  list-style-type: disc;
  font-weight: 500;
}
.main_wrapper .blogdetails_wrapper ol {
  padding-left: 20px;
}
.main_wrapper .blogdetails_wrapper ol li {
  font-size: 18px;
  line-height: 26px;
  padding: 8px 0;
}
.main_wrapper .blogdetails_wrapper ol li img {
  margin: 30px 0 20px;
}
.main_wrapper .blogdetails_wrapper ol li ul {
  padding: 5px 0 0 15px;
}
.main_wrapper .blogdetails_wrapper ol li ul li {
  font-size: 18px;
  line-height: 26px;
  list-style-type: disc;
  font-weight: 500;
}
.main_wrapper .blogdetails_wrapper .author_wrapper {
  background-color: #F7F8F8;
  padding: 20px;
  border-radius: 8px;
  margin: 20px 0;
}
@media (max-width: 992px) {
  .main_wrapper .blogdetails_wrapper .author_wrapper {
    margin: 20px 0 40px;
  }
}
.main_wrapper .blogdetails_wrapper .author_wrapper .author-bio img {
  width: 150px;
  height: 100%;
  border-radius: 100%;
}
@media (max-width: 992px) {
  .main_wrapper .blogdetails_wrapper .author_wrapper .author-bio img {
    width: 90px;
  }
}
.main_wrapper .blogdetails_wrapper .author_wrapper .author_content {
  padding: 20px;
}
@media (max-width: 992px) {
  .main_wrapper .blogdetails_wrapper .author_wrapper .author_content {
    padding: 0;
  }
}
.main_wrapper .blogdetails_wrapper .author_wrapper .author_content .user_name {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  padding: 0 0 10px;
}
.main_wrapper .rightSidebar {
  padding: 20px 0 0;
}
.main_wrapper .rightSidebar .social_links {
  padding: 20px 0 0;
}
.main_wrapper .rightSidebar .social_links a img {
  width: 25px;
}
.main_wrapper .rightSidebar .demo_div {
  background-color: #F4F3FF;
  border-radius: 12px;
  padding: 20px 15px;
  margin: 30px 0 0;
}
.main_wrapper .rightSidebar .demo_div .demo_title {
  font-size: 19px;
  line-height: 28px;
  font-weight: 600;
}
@media (max-width: 992px) {
  .main_wrapper .rightSidebar .demo_div .demo_title {
    font-size: 22px;
    line-height: 32px;
  }
}
.main_wrapper .rightSidebar .demo_div .demo_title span {
  color: blue;
}
.main_wrapper .rightSidebar .demo_div p {
  font-size: 14px;
  line-height: 20px;
  padding: 10px 0;
}
.main_wrapper .rightSidebar .demo_div .theme-btn, .main_wrapper .rightSidebar .demo_div .white-btn {
  display: block;
  margin: 0 0 10px;
}

#recentBlogs .blogBox .blog-box-wrapper .blog-box {
  padding: 0 15px;
}
@media (max-width: 992px) {
  #recentBlogs .blogBox .blog-box-wrapper .blog-box {
    padding: 15px 0;
  }
}
#recentBlogs .blogBox .blog-box-wrapper .blog-box .blog_Img img {
  height: 213px;
}

#subscribe .subscribe_wrapper {
  padding: 48px 50px;
  height: 390px;
  border-radius: 8px;
}
#subscribe .subscribe_wrapper .sec-padd {
  max-width: 500px;
}
@media (max-width: 992px) {
  #subscribe .subscribe_wrapper {
    padding: 0;
    height: unset;
  }
  #subscribe .subscribe_wrapper .sec-title {
    font-size: 24px;
    line-height: 34px;
  }
  #subscribe .subscribe_wrapper .sec-padd {
    padding: 20px;
  }
}
#subscribe .subscribe_wrapper .subscribe {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 390px;
}
@media (max-width: 992px) {
  #subscribe .subscribe_wrapper .subscribe {
    position: relative;
    height: unset;
  }
}

#lastsection {
  background-color: #0D9298;
  height: 600px;
}
@media (max-width: 992px) {
  #lastsection {
    height: 300px;
  }
  #lastsection .sheet {
    width: 98px;
  }
}
#lastsection .tagline_wraper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
@media (max-width: 992px) {
  #lastsection .tagline_wraper {
    position: relative;
    top: unset;
    transform: unset;
    left: unset;
  }
}
#lastsection .tagline_wraper .sec-title {
  font-size: 50px;
  line-height: 60px;
  letter-spacing: 0;
  padding-bottom: 20px;
}
@media (max-width: 992px) {
  #lastsection .tagline_wraper .sec-title {
    font-size: 24px;
    line-height: 34px;
  }
}
#lastsection .tagline_wraper .free-trial {
  font-size: 20px;
  margin: 20px 0 50px;
}
@media (max-width: 992px) {
  #lastsection .tagline_wraper .free-trial {
    font-size: 14px;
    line-height: 20px;
    margin: 0px 0 35px;
  }
}
#lastsection .tagline_wraper .theme-btn {
  background-color: #fff;
  color: #0d9298;
}

.pagination-links {
  text-align: center;
  margin: 20px 0;
}
.pagination-links a {
  margin: 0 5px;
  padding: 10px 15px;
  border: 1px solid #ddd;
  text-decoration: none;
  background-color: #f4f4f4;
  color: #0d9298;
  transition: background-color 0.3s ease, color 0.3s ease;
}
.pagination-links a:hover {
  background-color: #0d9298;
  color: #fff;
  border-color: #0d9298;
}
.pagination-links .current {
  font-weight: bold;
  pointer-events: none;
  background-color: #0d9298;
  color: #fff;
  border-color: #0d9298;
}
.pagination-links .page-number {
  cursor: pointer;
}
.pagination-links .prev_pagination, .pagination-links .next_pagination, .pagination-links .last-page, .pagination-links .first-page {
  background-color: rgba(235, 235, 235, 0.6588235294);
  padding: 11px;
  border-radius: 4px;
}