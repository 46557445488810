
@font-face {
    font-family: 'Basier Circle';
    src: url('../fonts/BasierCircle-Regular.eot');
    src: url('../fonts/BasierCircle-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/BasierCircle-Regular.woff2') format('woff2'),
        url('../fonts/BasierCircle-Regular.woff') format('woff'),
        url('../fonts/BasierCircle-Regular.ttf') format('truetype'),
        url('../fonts/BasierCircle-Regular.svg#BasierCircle-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Basier Circle Semibold';
    src: url('../fonts/BasierCircle-SemiBold.eot');
    src: url('../fonts/BasierCircle-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/BasierCircle-SemiBold.woff2') format('woff2'),
        url('../fonts/BasierCircle-SemiBold.woff') format('woff'),
        url('../fonts/BasierCircle-SemiBold.ttf') format('truetype'),
        url('../fonts/BasierCircle-SemiBold.svg#BasierCircle-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Basier Circle Medium';
    src: url('../fonts/BasierCircle-Medium.eot');
    src: url('../fonts/BasierCircle-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/BasierCircle-Medium.woff2') format('woff2'),
        url('../fonts/BasierCircle-Medium.woff') format('woff'),
        url('../fonts/BasierCircle-Medium.ttf') format('truetype'),
        url('../fonts/BasierCircle-Medium.svg#BasierCircle-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}




